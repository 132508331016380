/* eslint-disable */
import React from "react";
import CardView from "../Profile/CardView";
import { LOCAL_STORAGE_VAR } from "../../../constants/Common";

const CompanyCardView = (props) => {
  let dt = JSON.parse(
    window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)),
  );
  return (
    <CardView
      cDetails={dt}
      companyDetail
      onClose={props.onClose}
      onClick={props.onClick}
    />
  );
};
export default CompanyCardView;
