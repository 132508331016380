/* eslint-disable */
import React from "react";
import QuickRegisterForm from "./Auth/QuickRegisterForm";
import AuthPoster from "./Auth/AuthPoster";
import PopupBanner from "./PopupBanner";
import { Modal } from "antd";

const QuickRegister = (props) => {
  return (
    <>
      <PopupBanner />
      <div className="main-screen">
        <div className="loginBasicList">
          <ul>
            <li>
              <a href="news">News & Event</a>
            </li>
            <li>
              <a href="Finestar KYC FORM.xls" download>
                Download KYC Form
              </a>
            </li>
            <li>
              <a href="/book-appointment">Take Appointment</a>
            </li>
            <li>
              <a href="/terms-condition">Terms</a>
            </li>
            <li>
              <a href="/contact-us">Contact Us</a>
            </li>
            <li>
              <a href="/career">Career</a>
            </li>
            <li>
              <a href="/about-us">About Us</a>
            </li>
          </ul>
        </div>
        <div className="loginMainWrapper">
          <AuthPoster />
          <div className="LoginRightBlock">
            <QuickRegisterForm
              section_1="section_1"
              section_2="section_2"
              section_3="section_3"
            />
          </div>
        </div>
      </div>
    </>

  );
};

export default QuickRegister;