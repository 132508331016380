/* eslint-disable */
import React from "react";
import IntlMessages from "../util/IntlMessages";
const DetailView = {
  section1: {
    name: <IntlMessages id="app.basicDetail" />,
    data: [
      {
        part: 1,
        label: <IntlMessages id="app.PacketNo" />,
        key: "vStnId",
      },
      {
        part: 1,
        label: <IntlMessages id="app.Shade" />,
        key: "shdNm",
      },
      {
        part: 1,
        label: <IntlMessages id="app.ReportNo" />,
        key: "rptNo",
      },
      {
        part: 1,
        label: <IntlMessages id="app.cut" />,
        key: "cutNm",
      },
      {
        part: 1,
        label: <IntlMessages id="app.Lab" />,
        key: "lbNm",
      },
      {
        part: 1,
        label: <IntlMessages id="app.polish" />,
        key: "polNm",
      },

      {
        part: 2,
        label: <IntlMessages id="app.symm" />,
        key: "symNm",
      },
      {
        part: 2,
        label: <IntlMessages id="app.rapindlr" />,
        key: "rap",
      },
      {
        part: 2,
        label: <IntlMessages id="app.Shape" />,
        key: "shpNm",
      },
      {
        part: 2,
        label: <IntlMessages id="app.carat" />,
        key: "crt",
      },
      {
        part: 2,
        label: <IntlMessages id="app.Color" />,
        key: "colNm",
      },
      {
        part: 2,
        label: <IntlMessages id="app.Clarity" />,
        key: "clrNm",
      },
      {
        part: 2,
        label: <IntlMessages id="app.flor" />,
        key: "fluNm",
      },
    ],
  },
  section2: {
    name: <IntlMessages id="app.Measurements" />,
    data: [
      {
        label: <IntlMessages id="app.compare.tableper" />,
        key: "tblPer",
      },
      {
        label: <IntlMessages id="app.crHt" />,
        key: "cHgt",
      },
      {
        label: <IntlMessages id="app.total-depth" />,
        key: "height",
      },
      {
        label: <IntlMessages id="app.pavAn" />,
        key: "pAng",
      },
      {
        label: <IntlMessages id="app.length" />,
        key: "length",
      },
      {
        label: <IntlMessages id="app.pavHt" />,
        key: "pHgt",
      },
      {
        label: <IntlMessages id="app.width" />,
        key: "width",
      },
      {
        label: <IntlMessages id="app.Girdle" />,
        key: "girdleStr",
      },
      {
        label: <IntlMessages id="app.depth" />,
        key: "height",
      },
      {
        label: <IntlMessages id="app.culet" />,
        key: "cultNm",
      },
      {
        label: <IntlMessages id="app.ratio" />,
        key: "ratio",
      },
      {
        label: (
          <IntlMessages id="app.diamonddetails.Additionalinfo.laserinscription" />
        ),
        key: "lsrInc",
      },
      {
        label: <IntlMessages id="app.crAn" />,
        key: "cAng",
      },
    ],
  },
  section3: {
    name: <IntlMessages id="app.diamonddetails.Inclusion" />,
    data: [
      {
        label: <IntlMessages id="app.CenterNatts" />,
        key: "blkTblNm",
      },
      {
        label: <IntlMessages id="app.SideNatts" />,
        key: "blkSdNm",
      },
      {
        label: <IntlMessages id="app.CenterWhite" />,
        key: "wTblNm",
      },
      {
        label: <IntlMessages id="app.SideWhite" />,
        key: "wSdNm",
      },
      {
        label: <IntlMessages id="app.TableOpen" />,
        key: "opTblNm",
      },
      {
        label: <IntlMessages id="app.CrownOpen" />,
        key: "opCrwnNm",
      },
      {
        label: <IntlMessages id="app.PavilionOpen" />,
        key: "opPavNm",
      },
      {
        label: <IntlMessages id="app.eye-clean" />,
        key: "eClnNm",
      },
      {
        label: <IntlMessages id="app.heart-arrow" />,
        key: "hANm",
      },
      {
        label: <IntlMessages id="app.Brilliancy" />,
        key: "brlncyNm",
      },
      {
        label: <IntlMessages id="app.Type2Cert" />,
        key: "type2Nm",
      },
      {
        label: <IntlMessages id="app.CountryOfOrigin" />,
        key: "org",
      },
      {
        label: <IntlMessages id="app.RoughMine" />,
        key: "",
      },
    ],
  },
  section4: {
    name: <IntlMessages id="app.OtherInformation" />,
    data: [
      {
        label: <IntlMessages id="app.keyToS" />,
        key: "kToSStr",
      },
      {
        hide: true,
      },
      {
        label: <IntlMessages id="app.ReportComments" />,
        key: "lbCmt",
      },
    ],
  },
};

export default DetailView;
