/* eslint-disable */
import React, { useState, useEffect } from "react";

import StaticHeader from "../StaticHeader";
import Heading from "../common/Heading";
import "./news.less";
import { GET_EVENTS, GET_NEWS_DATA } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import OpenNotification from "../common/CommonButton/OpenNotification";

import moment from "moment";

import { BASE_URL } from "../../constants/Common";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Slider from "react-slick";

import { isEmpty } from "util/utils";

const NewsItem = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="newsBlockItem">
      <div className="newsItemTop">
        <span className="newsDate">
          {props.date ? props.date : props.toDate + " - " + props.fromDate}
        </span>
        <span className="newShortTitle">
          {props.smalltitle ? props.smalltitle : "-"}
        </span>
      </div>

      {isEmpty(props.images) ? (
        <div className="newsImage">
          <img src={`${BASE_URL}${props.image}`} alt="" />
        </div>
      ) : (
        <div className="newsAndEvent sliderEvent">
          <Slider {...settings}>
            {props.images.map((data, index) => {
              let url = BASE_URL + data;

              if (!data.startsWith("/")) {
                url = BASE_URL + "/" + data;
              }
              return (
                <div className="newsImage" key={index}>
                  <img src={url} alt="EventImage" />
                </div>
              );
            })}
          </Slider>
        </div>
      )}
      <div className="newsContent">
        <h2>{props.title}</h2>
        <p>{props.dec ? props.dec : "-"}</p>
      </div>
    </div>
  );
};

const News = () => {
  const [data, setData] = useState([]);
  const [event, setEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultTab, setDefaultTab] = useState(0);

  const getNewsData = () => {
    const obj = {
      ...GET_NEWS_DATA,
      request: {
        page: 1,
        limit: 10,
        filter: {
          isDeleted: false,
          isActive: true,
        },
      },
    };
    setIsLoading(true);

    UtilService.callApi(obj, (err, data) => {
      setIsLoading(false);

      if (data && data.code === "OK") {
        setData(data.data.list);
      } else if (data && data.code === "E_NOT_FOUND") {
        setData([]);
        // OpenNotification({
        //   type: "error",
        //   title: data.messsage || err.message,
        // });
      }
    });
  };

  const getEventsData = () => {
    const obj = {
      ...GET_EVENTS,
      request: {
        page: 1,
        limit: 10,
        filter: {
          isDeleted: false,
          isActive: true,
        },
      },
    };
    setIsLoading(true);

    UtilService.callApi(obj, (err, data) => {
      setIsLoading(false);

      if (data && data.code === "OK") {
        setEvent(data.data.list);
      } else if (data && data.code === "E_NOT_FOUND") {
        setEvent([]);
        // OpenNotification({
        //   type: "error",
        //   title: data.messsage || err.message,
        // });
      }
    });
  };

  useEffect(() => {
    getNewsData();
    getEventsData();
  }, []);

  return (
    <>
      <StaticHeader />
      <Tabs selectedIndex={defaultTab}>
        <TabList className="customerMaintab">
          <Tab onClick={() => setDefaultTab(0)}>Events</Tab>
          <Tab onClick={() => setDefaultTab(1)}>News</Tab>
        </TabList>
        <TabPanel>
          <div className={`staticBoxMain ${isLoading ? `loading-indicator` : ''}`}>
            <div className="statiContentBox">
              <Heading
                title="Event"
                className="staticMainTitle text-center mb-50"
              />
              <div className="newsBlockMainBox d-flex flex-wrap">
                {event.length >= 1
                  ? event.map((x, i) => {
                      return (
                        <NewsItem
                          key={i}
                          title={x.normalizeName}
                          dec={x.shortDescription}
                          toDate={moment(x.toDateTime).format(
                            "YYYY-MM-DD h:mm:ss a",
                          )}
                          fromDate={moment(x.fromdateTime).format(
                            "YYYY-MM-DD h:mm:ss a",
                          )}
                          // date={moment(x.updatedAt).format('YYYY-MM-DD h:mm:ss a')}
                          smalltitle={x.normalizeName}
                          image={x.image} //{require("../../assets/img/News/1.jpg")}
                          images={x.images} //{require("../../assets/img/News/1.jpg")}
                        />
                      );
                    })
                  : "No Latest Events"}
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className={`staticBoxMain ${isLoading ? `loading-indicator` : ''}`}>
            <div className="statiContentBox">
              <Heading
                title="News"
                className="staticMainTitle text-center mb-50"
              />
              <div className="newsBlockMainBox d-flex flex-wrap">
                {data.length >= 1
                  ? data.map((x, i) => {
                      return (
                        <NewsItem
                          key={i}
                          title={x.title}
                          dec={x.fullDescription}
                          date={moment(x.updatedAt).format(
                            "YYYY-MM-DD h:mm:ss a",
                          )}
                          smalltitle={x.shortDescription}
                          image={x.logoImage} //{require("../../assets/img/News/1.jpg")}
                        />
                      );
                    })
                  : "No Latest News"}
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default News;
