/* eslint-disable */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Drawer } from "antd";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import ConfirmStonePopup from "./ConfirmStonePopup";
import QuotePopup from "./QuotePopup";
import OfficeSidebar from "./OfficeSidebar";
import {
  handleInsertTrack,
  compareStones,
  LISTINGPAGES,
  handlePrint,
  handleMemoPrint,
  handleTrackDelete,
  handleUpsertTrack,
  getPath,
  handleBidDelete,
  handleNoteDelete,
  handleDeleteOffice,
  isMobile,
  handleDownloadExcel,
  getNewStatus,
  myAccountExcelTrack,
  myAccountExcelMemo,
  myAccountExcelOffice,
  myAccountNotesExcel,
  myBidExcel,
  myAccountPDFTrack,
  myAccountPDFMemo,
  myAccountPDFOffice,
  myAccountNotesPDF,
  myBidPDF,
  mySuggestedExcel,
  myExclusiveExcel,
  mySuggestedPDF,
  myExclusivePDF,
  dnaCompareStones,
} from "../../DiamondList/DiamondListFunctions";
import UtilService from "../../../services/util";
import Pagination from "../Pagination";
import OpenNotification from "../CommonButton/OpenNotification";
import isEmpty from "lodash/isEmpty";
import FinalCalculations from "./FinalCalculations";
import ShareDropdown from "./ShareDropdown";
import DownloadDropdown from "./DownloadDropdown";
import { trackTypeObj, LOCAL_STORAGE_VAR } from "../../../constants/Common";
import Reminder from "../Reminder";
import UpdateReminder from "../UpdateReminder";
import BidPopup from "./BidPopup";
import IntlMessages from "../../../util/IntlMessages";
import { setCount } from "../../../appRedux/actions/deleteReminder";
import CommonModal from "../CommonModal";
import WatchlistPopup from "./WatchlistPopup";
import ChequePopup from "./ChequePopup";
import MobileShareOption from "./MobileShareOption";
import NotePopup from "./NotePopup";
import { useSelector } from "react-redux";
import { GuestUserPopup } from "../../GuestUserPopup";
import MobileDownloadOption from "./MobileDownloadOption";
import map from "lodash/map";
import compact from "lodash/compact";
import groupBy from "lodash/groupBy";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import MemoPopup from "./MemoPopup";
import RapnetPopup from "./RapnetPopup";
import _isFunction from "lodash/isFunction";
import ConfirmStoneModal from "./ConfirmStoneModal";
import BidDisclaimerModal from "./BidDisclaimerModal";
import OfferDisclaimerModal from "./OfferDisclModal";
import BidValidationModal from "./bidValidationModal";
import Loader from "../../common/Loader";
import useRowSelect from "util/useRowSelect";
import HoldPopup from "./HoldPopup";
import { isNotEmpty } from "util/utils";
import { CLIPBOARD_CON_API } from "constants/apiConstant";
import axios from "axios";
/* eslint jsx-a11y/anchor-is-valid: 0 */

const DiamondListingAction = (props) => {
  const { loading, setLoading } = props;

  const selectedRows = useSelector(
    (state) => state.diamondData.selectedRows[props.currentType],
  );
  const checked = props.checkedData || selectedRows || [];

  const [visible, setVisible] = useState("");
  const [moreSearch, moreSearchOption] = useState(false);
  const [more, setMore] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const [showGuest, setShowGuest] = useState(false);
  const [validatePopup, setValidatePopup] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [bidModalShow, setBidModalShow] = useState(false);
  const [holdPopup, setHoldPopUp] = useState(false);
  const [whatsApp, setWhatsApp] = useState(false);

  const isGuest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);
  const isMobileView = isMobile();

  const { toggleRowSelection } = useRowSelect(props?.currentType);
  const [clipBoardRes, setClipBoradRes] = useState([]);
  const [clipArray, setClipArray] = useState([]);
  const [clipBoradResDNALink, setClipBoradResDNALink] = useState([]);
  const [clipBoradResWithoutPrice, setClipBoardResWithoutPrice] = useState([]);
  const [clipArrayWithoutPrice, setClipArrayWithoutPrice] = useState([]);
  const [clipArrayDNAlink, setClipArrayDNAlink] = useState([]);

  const moreSearchDrawer = () => {
    moreSearchOption(true);
  };
  const onClose = () => {
    setVisible("");
    setReminder("");
    typeof props.clearAll === "function" && props.clearAll();
    moreSearchOption(false);

    props.setCount(!props.deleteReminder.count);
  };

  const handleOk = () => {
    setBidModalShow(false);
  };

  const path = window.location.pathname.split("/")[1];
  const module = window.location.pathname.split("/")[2];

  const handleNewArrivalRedirect = () => {
    props.history.push("/new-arrival-bid-it", {
      stoneIds: checked
        ?.filter(({ wSts }) => wSts === "B")
        ?.map(({ id }) => id),
    });
  };

  const removeChecked = () => {
    void checked
      ?.filter(({ wSts, bidType }) => wSts === "B" && bidType !== 2)
      ?.forEach(async (item) => {
        await toggleRowSelection(item, false);
      });
  };

  const handleContinue = async () => {
    removeChecked();
    const stones =
      currentAction === "office"
        ? checked?.filter(
          ({ wSts, bidType }) =>
            wSts !== "B" || (wSts === "B" && bidType === 2),
        )
        : checked?.filter(({ wSts }) => wSts !== "B");
    if (!stones?.length)
      OpenNotification({
        type: "error",
        title: "All selected stones are in New Arrival",
      });
    setValidatePopup(false);

    stones?.length && handleValidateActions(currentAction);
  };

  //contiue action after validate new arrival stones
  const handleValidateActions = React.useCallback(
    (action) => {
      if (["quote"].includes(action)) return setDisclaimer(action);
      if (action === "cart")
        return handleInsertTrack(trackTypeObj.CART, checked, (status) => {
          props.clearAll();
          if (status) props.setCount(!props.deleteReminder.count);
        });
      else if (["watchlist", "office", "note", "confirm"].includes(action))
        return setVisible(action);
      else if (action === "compare") {
        compareStones(checked);
        props.clearAll();
      }
    },
    [checked, props],
  );

  // To prevent actions on new arrival stones in diamond listing page
  const validateNewArrivalStones = React.useCallback(
    (action) => {
      const notAllowActions = [
        "confirm",
        "quote",
        "cart",
        "watchlist",
        "office",
        "note",
        "compare",
      ];

      if (!notAllowActions.includes(action))
        return handleValidateActions(action);

      const newArrivalStones = checked?.filter(({ wSts }) => wSts === "B");
      if (!newArrivalStones?.length) return handleValidateActions(action);

      const newArrivalLookAndBid = newArrivalStones?.filter((l) => {
        return l?.bidType === 2 || l?.bidType === 1;
      });
      if (newArrivalStones?.length === newArrivalLookAndBid?.length)
        return handleValidateActions(action);

      setCurrentAction(action);
      setValidatePopup(true);
      return false;
    },
    [checked, handleValidateActions],
  );

  const validateStatus = (key) => {
    const data = checked.filter(
      ({ sSts }) => !UtilService.getLocalStorageItem(key).includes(sSts),
    );
    if (data.length) {
      OpenNotification({
        type: "error",
        title: `Stone No : ${data
          .map(({ vStnId }) => vStnId)
          .join(",")} not allow to perform this action`,
      });
      return false;
    }

    return true;
  };

  const whatsAppContentsApiWithPrice = async (t) => {
    const apiReq = {
      url: [
        {
          origUrl: `https://diamondmedia.s3.us-east-1.amazonaws.com/B2B/imaged/${t.vStnId}/still.jpg`,
          ref: "image",
          vStnId: t.vStnId,
        },
        {
          origUrl: `https://diamondmedia.s3.us-east-1.amazonaws.com/B2B/imaged/${t.vStnId}/video.mp4`,
          ref: "video",
          vStnId: t.vStnId,
        },
        {
          origUrl: `https://diamondmedia.s3.us-east-1.amazonaws.com/CERTPDF/${t.FSD_REPORT_NO}.pdf`,
          ref: "cert",
          vStnId: t.vStnId,
        },
        {
          origUrl: `https://views.diadna.com/dna/${t.vStnId}`,
          ref: "dna",
          vStnId: t.vStnId,
        },
      ],
    };

    const apiObj = {
      ...CLIPBOARD_CON_API,
      request: apiReq,
    };

    setLoading(true);
    await axios
      .post("https://tiny.diadna.com/api/shortarr", apiReq)
      .then((response) => {
        setClipBoradRes(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        OpenNotification({
          type: "error",
          title: "Something went wrong on the server. Please try again.",
        });
        setLoading(false);
      });
  };

  //Handles Clipboard Copied Text for With price selection
  useEffect(() => {
    let urlObjN = {};
    clipBoardRes.forEach((item) => {
      urlObjN = { ...urlObjN, [item.ref]: item.shortUrl, vStnId: item.vStnId };
    });

    if (!clipArray.includes(urlObjN) && isNotEmpty(urlObjN)) {
      setClipArray([...clipArray, urlObjN]);
    }
  }, [clipBoardRes]);

  useEffect(() => {
    let tempCopyCon = ``;
    const checkVstnID = checked.map((item) => item.vStnId);
    let tempClipArr = [];
    clipArray.forEach((item) => {
      if (checkVstnID.includes(item.vStnId)) {
        tempClipArr = [...tempClipArr, item];
      }
    });
    const uniqueArray = Array.from(
      new Set(tempClipArr.map(JSON.stringify)),
    ).map(JSON.parse);
    // const tempClipArr = clipArray.filter((item) => (checkVstnID.inlcudes(item.vStnId)));
    uniqueArray.forEach((item) => {
      tempCopyCon += `Stock# ${item.vStnId} \nImage ${item.image}\nVideo ${item.video}\nCert ${item.cert}\nDNA ${item.dna}`;
      checked.forEach((itemC, index) => {
        if (item.vStnId === itemC.vStnId) {
          if (itemC.shpNm) {
            tempCopyCon += `\n${itemC.shpNm}`;
          }
          if (itemC.colNm) {
            tempCopyCon += `-${itemC.colNm}`;
          }
          if (itemC.clrNm) {
            tempCopyCon += `-${itemC.clrNm}`;
          }
          if (itemC.cutNm) {
            tempCopyCon += `-${itemC.cutNm}`;
          }
          if (itemC.polNm) {
            tempCopyCon += `-${itemC.polNm}`;
          }
          if (itemC.symNm) {
            tempCopyCon += `-${itemC.symNm}`;
          }
          if (itemC.fluNm) {
            tempCopyCon += `-${itemC.fluNm}`;
          }
          if (itemC.WRAP_LASER_INC) {
            tempCopyCon += `-${itemC.WRAP_LASER_INC}\n`;
          }
          if (itemC.crt) {
            tempCopyCon += `${itemC.crt}`;
          }
          if (itemC.calcDiscount) {
            tempCopyCon += ` Ct  ${itemC.calcDiscount}% Off `;
          }
          if (itemC.ctPr) {
            tempCopyCon += ` P/Ct ${itemC.ctPr} `;
          }
          if (itemC.amt) {
            tempCopyCon += ` Total $${itemC.amt}\n\n`;
          }
        }
      });
    });
    const textarea = document.createElement("textarea");
    textarea.textContent = tempCopyCon;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }, [clipArray]);

  const whatsAppContentsApiWitoutPrice = async (t) => {
    const apiReq = {
      url: [
        {
          origUrl: `https://diamondmedia.s3.us-east-1.amazonaws.com/B2B/imaged/${t.vStnId}/still.jpg`,
          ref: "image",
          vStnId: t.vStnId,
        },
        {
          origUrl: `https://diamondmedia.s3.us-east-1.amazonaws.com/B2B/imaged/${t.vStnId}/video.mp4`,
          ref: "video",
          vStnId: t.vStnId,
        },
        {
          origUrl: `https://diamondmedia.s3.us-east-1.amazonaws.com/CERTPDF/${t.FSD_REPORT_NO}.pdf`,
          ref: "cert",
          vStnId: t.vStnId,
        },
        {
          origUrl: `https://views.diadna.com/dna/${t.vStnId}`,
          ref: "dna",
          vStnId: t.vStnId,
        },
      ],
    };

    const apiObj = {
      ...CLIPBOARD_CON_API,
      request: apiReq,
    };

    setLoading(true);
    await axios
      .post("https://tiny.diadna.com/api/shortarr", apiReq)
      .then((response) => {
        setClipBoardResWithoutPrice(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        OpenNotification({
          type: "error",
          title: "Something went wrong on the server. Please try again.",
        });
        setLoading(false);
      });
  };

  //Handles Clipboard Copied Text for Without price selection
  useEffect(() => {
    let urlObjNWithoutP = {};
    clipBoradResWithoutPrice.forEach((item) => {
      urlObjNWithoutP = {
        ...urlObjNWithoutP,
        [item.ref]: item.shortUrl,
        vStnId: item.vStnId,
      };
    });

    if (
      !clipArrayWithoutPrice.includes(urlObjNWithoutP) &&
      isNotEmpty(urlObjNWithoutP)
    ) {
      setClipArrayWithoutPrice([...clipArrayWithoutPrice, urlObjNWithoutP]);
    }
  }, [clipBoradResWithoutPrice]);

  useEffect(() => {
    let tempCopyConWithoutP = ``;
    const checkVstnID = checked.map((item) => item.vStnId);
    let tempClipArrWithoutP = [];
    clipArrayWithoutPrice.forEach((item) => {
      if (checkVstnID.includes(item.vStnId)) {
        tempClipArrWithoutP = [...tempClipArrWithoutP, item];
      }
    });
    const uniqueArrayWithoutP = Array.from(
      new Set(tempClipArrWithoutP.map(JSON.stringify)),
    ).map(JSON.parse);
    // const tempClipArr = clipArray.filter((item) => (checkVstnID.inlcudes(item.vStnId)));
    uniqueArrayWithoutP.forEach((item) => {
      tempCopyConWithoutP += `Stock# ${item.vStnId} \nImage ${item.image}\nVideo ${item.video}\nCert ${item.cert}\nDNA ${item.dna}`;
      checked.forEach((itemC, index) => {
        if (item.vStnId === itemC.vStnId) {
          if (itemC.shpNm) {
            tempCopyConWithoutP += `\n${itemC.shpNm}`;
          }
          if (itemC.colNm) {
            tempCopyConWithoutP += `-${itemC.colNm}`;
          }
          if (itemC.clrNm) {
            tempCopyConWithoutP += `-${itemC.clrNm}`;
          }
          if (itemC.cutNm) {
            tempCopyConWithoutP += `-${itemC.cutNm}`;
          }
          if (itemC.polNm) {
            tempCopyConWithoutP += `-${itemC.polNm}`;
          }
          if (itemC.symNm) {
            tempCopyConWithoutP += `-${itemC.symNm}`;
          }
          if (itemC.fluNm) {
            tempCopyConWithoutP += `-${itemC.fluNm}`;
          }
          if (itemC.WRAP_LASER_INC) {
            tempCopyConWithoutP += `-${itemC.WRAP_LASER_INC}\n`;
          }
          if (itemC.crt) {
            tempCopyConWithoutP += `${itemC.crt}\n\n`;
          }
          // if (itemC.calcDiscount) {
          //   tempCopyConWithoutP += ` Ct  ${itemC.calcDiscount}% Off `
          // }
          // if (itemC.ctPr) {
          //   tempCopyConWithoutP += ` P/Ct ${itemC.ctPr} `
          // }
          // if (itemC.amt) {
          //   tempCopyConWithoutP += ` Total $${itemC.amt}\n\n`
          // }
        }
      });
    });
    const textarea = document.createElement("textarea");
    // textarea.textContent = withoutstr;
    textarea.textContent = tempCopyConWithoutP;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }, [clipArrayWithoutPrice]);

  const whatsAppContentsApiDNAlink = async (t) => {
    const apiReq = {
      url: [
        {
          origUrl: `https://views.diadna.com/dna/${t.vStnId}`,
          ref: "dna",
          vStnId: t.vStnId,
        },
      ],
    };

    const apiObj = {
      ...CLIPBOARD_CON_API,
      request: apiReq,
    };

    setLoading(true);
    await axios
      .post("https://tiny.diadna.com/api/shortarr", apiReq)
      .then((response) => {
        setClipBoradResDNALink(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        OpenNotification({
          type: "error",
          title: "Something went wrong on the server. Please try again.",
        });
        setLoading(false);
      });
  };

  //Handles Clipboard Copied Text for DNA Link
  useEffect(() => {
    let urlObjDNA = {};
    clipBoradResDNALink.forEach((item) => {
      urlObjDNA = {
        ...urlObjDNA,
        [item.ref]: item.shortUrl,
        vStnId: item.vStnId,
      };
    });

    if (
      !clipArrayDNAlink.includes(urlObjDNA) &&
      isNotEmpty(urlObjDNA)
    ) {
      setClipArrayDNAlink([...clipArrayDNAlink, urlObjDNA]);
    }
  }, [clipBoradResDNALink]);

  useEffect(() => {
    let tempCopyConDNA = ``;
    const checkVstnID = checked.map((item) => item.vStnId);
    let tempClipArrDna = [];
    clipArrayDNAlink.forEach((item) => {
      if (checkVstnID.includes(item.vStnId)) {
        tempClipArrDna = [...tempClipArrDna, item];
      }
    });
    const uniqueArrayDNA = Array.from(
      new Set(tempClipArrDna.map(JSON.stringify)),
    ).map(JSON.parse);
    // const tempClipArr = clipArray.filter((item) => (checkVstnID.inlcudes(item.vStnId)));
    uniqueArrayDNA.forEach((item) => {
      tempCopyConDNA += `DNA ${item.dna}\n`;
    });

    const textarea = document.createElement("textarea");
    // textarea.textContent = withoutstr;
    textarea.textContent = tempCopyConDNA;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }, [clipArrayDNAlink]);

  const withpriceCopy = () => {
    let withstr = "";
    let length = checked.length;
    let PriceData = checked.map((t) => {
      whatsAppContentsApiWithPrice(t);
    });

    if (checked.length >= 1) {
      OpenNotification({
        type: "success",
        title:
          "Stone details have been copied Press Ctrl +V on your WhatsApp Web to send in a Chat!",
      });
    } else {
      OpenNotification({
        type: "error",
        title: "Please select stone",
      });
    }
  };

  const withoutpriceCopy = () => {
    let withoutstr = "";
    let withoutpriceData = checked.map((t) => {
      whatsAppContentsApiWitoutPrice(t);
      //
    });

    if (checked.length >= 1) {
      OpenNotification({
        type: "success",
        title:
          "Stone details have been copied Press Ctrl +V on your WhatsApp Web to send in a Chat!",
      });
    } else {
      OpenNotification({
        type: "error",
        title: "Please select stone.",
      });
    }
  };

  const DNAlink = () => {
    let Dnastr = "";
    let DnaData = checked.map((t) => {
      whatsAppContentsApiDNAlink(t);
      //
    });

    if (checked.length >= 1) {
      OpenNotification({
        type: "success",
        title:
          "DNA Link has been copied Press Ctrl +V on your WhatsApp Web to send in a Chat!",
      });
    } else {
      OpenNotification({
        type: "error",
        title: "Please select stone.",
      });
    }
  };
  let actions = [
    // !props.modify
    //   ? null
    //   : {
    //       name: <IntlMessages id="app.modify" />,
    //       key: "modify",
    //       img: require("../../../assets/svg/DiamondList/modifySearch.svg"),
    //       onClick: () => {
    //         props.onModify();
    //       },
    //     },

    !props.bidStone
      ? null
      : {
        name: <IntlMessages id="app.bidStone" />,
        key: "bid",
        img: require("../../../assets/svg/DiamondList/bid-stone.svg"),
        onClick: () => {
          if (!isGuest) {
            isEmpty(checked)
              ? OpenNotification({
                type: "error",
                title: "Please select stone(s) to bid.",
              })
              : checked.length > 1 && isMobileView === true
                ? OpenNotification({
                  type: "error",
                  title: "Please select only one stone(s) to bid.",
                })
                : localStorage.getItem("bidEndTime") === "true"
                  ? setBidModalShow(true)
                  : setDisclaimer("bid");
          } else {
            setShowGuest(true);
          }
        },
      },
    !props.bidStone
      ? null
      : {
        name: <IntlMessages id="app.myBid" />,
        key: "mybid",
        img: require("../../../assets/svg/DiamondList/my-bid.svg"),
        onClick: () => {
          if (!isGuest) {
            props.history.push("/my-bid");
          } else {
            setShowGuest(true);
          }
        },
      },
    props.delete
      ? {
        name:
          getPath() === LISTINGPAGES.MYBID ? (
            <IntlMessages id="app.deleteBid" />
          ) : (
            <IntlMessages id="app.removeStone" />
          ),
        img: require("../../../assets/svg/delete.svg"),
        key: "delete",
        onClick: () => {
          if (!isGuest) {
            if (isEmpty(checked)) {
              OpenNotification({
                type: "error",
                title: "Please select stone(s) to delete.",
              });
              return;
            }
            if (getPath() === LISTINGPAGES.MYBID) {
              setVisible("delete-confirm");
            } else if (getPath() === LISTINGPAGES.NOTE) {
              handleNoteDelete(
                checked.map((row) => row.trackId),
                (flag) => {
                  props.clearAll();
                  if (flag) {
                    props.fetch();
                    props.setCount(!props.deleteReminder.count);
                  }
                },
              );
            } else if (getPath() === LISTINGPAGES.OFFICE) {
              let _schedule = [];
              let schedule = [];
              checked.map((ck) => {
                schedule.push({
                  diamonds: [ck.id],
                  id: ck.slottId,
                });
                return true;
              });

              Object.keys(groupBy(schedule, "id")).map((g) => {
                _schedule.push({
                  id: g,
                  diamonds: flatten(
                    map(groupBy(schedule, "id")[g], "diamonds"),
                  ),
                });
              });

              handleDeleteOffice({ schedule: _schedule }, () => {
                props.clearAll();
                props.fetch();
                OpenNotification({
                  type: "success",
                  title: "Your stone(s) is removed from office successfully.",
                });
              });
            } else {
              handleTrackDelete(
                props.trackType,
                checked.map((row) => row.id),
                props.inTrackDiamonds,
                (flag) => {
                  props.clearAll();
                  if (flag) {
                    props.fetch();
                    props.setCount(!props.deleteReminder.count);
                  }
                },
              );
            }
          } else {
            setShowGuest(true);
          }
        },
      }
      : null,
    props.update
      ? {
        name:
          getPath() === LISTINGPAGES.MYBID ? (
            <IntlMessages id="app.editBid" />
          ) : (
            <IntlMessages id="app.updateStone" />
          ),
        img: require("../../../assets/svg/DiamondList/updatestone.svg"),
        key: "update",
        onClick: () => {
          if (!isGuest) {
            if (getPath() === LISTINGPAGES.REMINDER) {
              // isEmpty(checked)
              //   ? OpenNotification({
              //       type: "error",
              //       title: "Please select stone(s) to update reminder.",
              //     })
              //   : setReminder("update");
            } else if (getPath() === LISTINGPAGES.MYBID) {
              isEmpty(checked)
                ? OpenNotification({
                  type: "error",
                  title: "Please select stone(s) to update bid.",
                })
                : setVisible("bid");
            } else if (getPath() === LISTINGPAGES.NOTE) {
              isEmpty(checked)
                ? OpenNotification({
                  type: "error",
                  title: "Please select stone(s) to update note.",
                })
                : setVisible("note");
            }
          } else {
            setShowGuest(true);
          }
        },
      }
      : null,
    // remove confirm stone ===> button in website
    // props.noconfirm
    //   ? null
    //   : {
    //     name: <IntlMessages id="app.confirmStonex" />,
    //     key: "confirm",
    //     hightlight: true,
    //     disabled:
    //       checked.some((r) => r.wSts === "M" || r.wSts === "H") &&
    //       !compact(checked.map((c) => (c.wSts === "H" || c.wSts === "M") && c.id)).every((e) => {
    //         const allIds = [
    //           ...map(props.inBlockDiamonds && props.inBlockDiamonds[0] && props.inBlockDiamonds[0].diamonds, "id"),
    //           ...map(props.inBlockDiamonds && props.inBlockDiamonds[1] && props.inBlockDiamonds[1].diamonds, "id"),
    //         ];

    //         return allIds.includes(e);
    //       }),

    //     onClick: () => {
    //       if (!isGuest) {
    //         if (isEmpty(checked)) {
    //           OpenNotification({
    //             type: "error",
    //             title: "Please select stone(s) to confirm.",
    //           });
    //         } else {
    //           validateNewArrivalStones("confirm");
    //         }
    //       } else {
    //         setShowGuest(true);
    //       }
    //     },
    //     img: require("../../../assets/svg/DiamondSearch/confirm.svg"),
    //   },
    props.nocart
      ? null
      : {
        name: <IntlMessages id="app.Cart" />,
        key: "cart",
        onClick: () => {
          if (!isGuest) {
            isEmpty(checked)
              ? OpenNotification({
                type: "error",
                title: "Please select stone(s) to add in cart.",
              })
              : validateNewArrivalStones("cart");
          } else {
            setShowGuest(true);
          }
        },
        img: require("../../../assets/svg/DiamondList/cart.svg"),
      },

    props.noquote
      ? null
      : {
        name: <IntlMessages id="app.Quote" />,
        key: "quote",
        hightlight: true,
        disabled:
          //isEmpty(props.inBlockDiamonds) &&
          checked.some((r) => r.wSts === "H") &&
          !compact(checked.map((c) => c.wSts === "H" && c.id)).every((e) => {
            const allIds = [
              ...map(props.inBlockDiamonds?.[0]?.diamonds, "id"),
              ...map(props.inBlockDiamonds?.[1]?.diamonds, "id"),
            ];

            return allIds.includes(e);
          }),
        onClick: () => {
          if (!isGuest) {
            if (isEmpty(checked))
              OpenNotification({
                type: "error",
                title: "Please select stone(s) to apply quote.",
              });
            else if (checked.length > 1 && isMobileView === true) {
              OpenNotification({
                type: "error",
                title: "Please select only one stone(s) to apply quote.",
              });
            } else {
              // Project settings quote validation start
              const quoteNotAllowSts = UtilService.getLocalStorageItem(
                `${LOCAL_STORAGE_VAR}-quote-not-allow-status`,
              );
              const quoteNotAllowSameBlock = UtilService.getLocalStorageItem(
                `${LOCAL_STORAGE_VAR}-quote-not-allow-same-block`,
              );

              if (
                quoteNotAllowSts?.length ||
                quoteNotAllowSameBlock?.length
              ) {
                const diamondSsts = checked.map((d) => d.sSts);
                const notValidStatus = diamondSsts?.filter((item) =>
                  quoteNotAllowSts?.includes(item),
                );

                if (quoteNotAllowSts?.length && notValidStatus.length) {
                  return OpenNotification({
                    type: "error",
                    title:
                      "Sorry! Unable to perform action at this time. Not Allow to quote diamonds with status " +
                      quoteNotAllowSts.join("/"),
                  });
                }

                const loginUser = UtilService.getLocalStorageItem(
                  `${LOCAL_STORAGE_VAR}-user`,
                );
                // check block validation
                const isNotAllowToOffer = checked.filter(
                  (d) =>
                    quoteNotAllowSameBlock.includes(d.sSts) &&
                    d.sStsDesc?.memoPartyId === loginUser?.account?.id,
                );
                if (
                  isNotAllowToOffer?.length &&
                  quoteNotAllowSameBlock.length
                ) {
                  return OpenNotification({
                    type: "error",
                    title: "Can not quote the diamond",
                  });
                }
              }
              // Project settings quote validation end

              let fm = checked.filter((el) => getNewStatus(el));
              if (fm.length === checked?.length) {
                OpenNotification({
                  type: "error",
                  title:
                    "Packet No. " +
                    checked
                      .filter((el) => getNewStatus(el))
                      .map((x) => x.vStnId)
                      .join(", ") +
                    (fm.length === 1 ? " is" : " are") +
                    " not eligible for quote.",
                });
              } else {
                validateNewArrivalStones("quote");
              }
            }
          } else {
            setShowGuest(true);
          }
        },
        img: require("../../../assets/svg/DiamondList/quote.svg"),
      },
    props.nowatch
      ? null
      : {
        name: <IntlMessages id="app.Watch" />,
        key: "watch",
        onClick: () => {
          if (!isGuest) {
            isEmpty(checked)
              ? OpenNotification({
                type: "error",
                title: "Please select stone(s) to add in watch.",
              })
              : validateNewArrivalStones("watchlist");
          } else {
            setShowGuest(true);
          }
        },
        img: require("../../../assets/svg/DiamondList/watch.svg"),
      },
    // remove FinalCalculation ===> button in website
    // props.nofinalcalc
    //   ? null
    //   : {
    //     name: <IntlMessages id="app.FinalCalculation" />,
    //     hightlight: true,
    //     key: "calc",
    //     img: require("../../../assets/svg/DiamondList/cal.svg"),
    //     onClick: () => {
    //       // setLoading(true);
    //       if (!isGuest) {
    //         if (isEmpty(checked)) {
    //           // setLoading(false);
    //           OpenNotification({
    //             type: "error",
    //             title: "Please select stone(s) for final calculation.",
    //           });
    //         } else {
    //           setVisible("finalCalc");
    //           // setLoading(false);
    //           // let fm = checked.filter((el) => el.isFm === "ELIG");
    //           // if (fm.length === checked.length || fm.length === 0) setVisible("finalCalc");
    //           // else
    //           //   OpenNotification({
    //           //     type: "error",
    //           //     title: "Please select FM stones separately.",
    //           //   });
    //         }
    //       } else {
    //         // setLoading(false);
    //         setShowGuest(true);
    //       }
    //     },
    //   },
    props.nooffice
      ? null
      : {
        name: <IntlMessages id="app.Office" />,
        key: "office",
        onClick: () => {
          if (!isGuest) {
            isEmpty(checked)
              ? OpenNotification({
                type: "error",
                title: "Please select stone(s) for office.",
              })
              : validateNewArrivalStones("office");
          } else {
            setShowGuest(true);
          }
        },
      },
    !props.noHold
      ? null
      : {
        name: <IntlMessages id="app.HoldStone" />,
        key: "hold",
        onClick: () => {
          if (!isGuest) {
            isEmpty(checked)
              ? OpenNotification({
                type: "error",
                title: "Please select stone(s) to hold.",
              })
              : setHoldPopUp(true);
          } else {
            setShowGuest(true);
          }
        },
      },
    props.nonote
      ? null
      : {
        name: <IntlMessages id="app.comment" />,
        key: "note",
        onClick: () => {
          if (!isGuest) {
            isEmpty(checked)
              ? OpenNotification({
                type: "error",
                title: "Please select stone(s) to add note.",
              })
              : validateStatus("noteAllowStatus") &&
              validateNewArrivalStones("note");
          } else {
            setShowGuest(true);
          }
        },
      },
    props.nonocompare
      ? null
      : props.nocompare
        ? {
          name: (
            <span>
              <IntlMessages id="app.CompareStoneNow" />
              {" (" + props.data.length + ")"}
            </span>
          ),
          key: "compare",
          img: require("../../../assets/svg/DiamondList/compare.svg"),
          onClick: () => {
            if (!isGuest) {
              localStorage.setItem(`${LOCAL_STORAGE_VAR}-more`, true);
              props.history.push(
                JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)),
              );
            } else {
              setShowGuest(true);
            }
          },
        }
        : {
          name: <IntlMessages id="app.CompareStone" />,
          key: "compare",
          img: require("../../../assets/svg/DiamondList/compare.svg"),
          onClick: () => {
            if (!isGuest) {
              validateNewArrivalStones("compare");
            } else {
              setShowGuest(true);
            }
          },
        },
    props.noDnaCompare
      ? {
        name: <IntlMessages id="app.DnaCompareStone" />,
        key: "DnaCompare",
        img: require("../../../assets/svg/DiamondList/compare.svg"),
        onClick: () => {
          if (!isGuest) {
            dnaCompareStones(checked);
            props.clearAll();
          } else {
            setShowGuest(true);
          }
        },
      }
      : null,
    props.cheque
      ? {
        name: "Upload Label",
        key: "label",
        hightlight: true,
        onClick: () => {
          if (!isGuest) {
            if (isEmpty(checked)) {
              OpenNotification({
                type: "error",
                title: "Please select stone(s) to upload label.",
              });
            } else {
              setVisible("cheque");
            }
          } else {
            setShowGuest(true);
          }
        },
        img: require("../../../assets/svg/DiamondSearch/confirm.svg"),
      }
      : null,
    props.noshare
      ? null
      : {
        name: <IntlMessages id="app.Share" />,
        key: "share",
        img: require("../../../assets/svg/DiamondList/share.svg"),
        onClick: () => {
          if (!isGuest) {
            isEmpty(checked)
              ? OpenNotification({
                type: "error",
                title: "Please select stone(s) to share.",
              })
              : setVisible("share");
          } else {
            setShowGuest(true);
          }
          setMore(!more);
        },
      },
    props.nodownload
      ? null
      : {
        name: <IntlMessages id="app.Download" />,
        key: "download",
        img: require("../../../assets/svg/DiamondList/download.svg"),
        onClick: () => {
          if (!isGuest) {
            if (checked.length > 0) {
              setVisible("download");
            } else {
              if (props.data.length > 0) {
                if (path === "account") {
                  if (module === "watchlist") {
                    myAccountExcelTrack(2);
                  } else if (module === "cart") {
                    myAccountExcelTrack(1);
                  } else if (module === "quote-list") {
                    myAccountExcelTrack(4);
                  } else if (module === "confirm-list") {
                    myAccountExcelMemo();
                  } else if (module === "office-list") {
                    myAccountExcelOffice();
                  } else if (module === "notes") {
                    myAccountNotesExcel();
                  }
                } else if (path === "my-bid") {
                  myBidExcel();
                } else if (path === "suggested-stock") {
                  mySuggestedExcel();
                } else if (path === "belgium-exclusive") {
                  myExclusiveExcel();
                } else if (path === "upcoming") {
                  handleDownloadExcel(
                    props.seachFilterTag
                      ? props.seachFilterTag.searchData
                      : {},
                    checked.map((x) => x.id),
                    () => { },
                    true,
                  );
                } else if (path === "compare") {
                  // confirmCompare is to check whether the diamond is in /confirm-list or not
                  let confirmCompare = localStorage.getItem(
                    `${LOCAL_STORAGE_VAR}-confirm-compare`,
                  )
                    ? JSON.parse(
                      localStorage.getItem(
                        `${LOCAL_STORAGE_VAR}-confirm-compare`,
                      ),
                    )
                    : false;
                  if (confirmCompare) {
                    //
                    handleDownloadExcel(
                      {},
                      localStorage.getItem(
                        `${LOCAL_STORAGE_VAR}-compare-diamond`,
                      )
                        ? JSON.parse(
                          localStorage.getItem(
                            `${LOCAL_STORAGE_VAR}-compare-diamond`,
                          ),
                        )
                        : [],
                      (status) => {
                        if (status) {
                          //props.clearAll();
                        }
                      },
                      path === "upcoming" ? true : false,
                      [],
                      confirmCompare,
                    );
                  } else {
                    //
                    handleDownloadExcel(
                      {},
                      localStorage.getItem(
                        `${LOCAL_STORAGE_VAR}-compare-diamond`,
                      )
                        ? JSON.parse(
                          localStorage.getItem(
                            `${LOCAL_STORAGE_VAR}-compare-diamond`,
                          ),
                        )
                        : [],
                      (status) => {
                        if (status) {
                          //props.clearAll();
                        }
                      },
                      path === "upcoming" ? true : false,
                      [],
                      confirmCompare,
                    );
                  }
                } else {
                  _isFunction(props.handleLoader) && props.handleLoader(true);
                  handleDownloadExcel(
                    props.seachFilterTag
                      ? props.seachFilterTag.searchData
                      : {},
                    checked.map((x) => x.id),
                    () => {
                      _isFunction(props.handleLoader) &&
                        props.handleLoader(false);
                    },
                    null,
                    null,
                    false,
                    path,
                  );
                }
                // _isFunction(props.handleLoader) && props.handleLoader(false);
              } else {
                return OpenNotification({
                  type: "error",
                  title: "No Stones Found",
                });
              }
            }
          } else {
            setShowGuest(true);
          }
          setMore(!more);
        },
      },
    props.noprint
      ? null
      : {
        name: <IntlMessages id="app.PrintList" />,
        key: "print",
        img: require("../../../assets/svg/DiamondList/print-list.svg"),
        onClick: () => {
          if (!isGuest) {
            if (checked.length > 0) {
              if (module === "confirm-list") {
                handleMemoPrint(
                  {},
                  checked.map((chk) => chk.id),
                );
              } else if (path === "upcoming") {
                handlePrint(
                  {},
                  checked.map((chk) => chk.id),
                  true,
                );
              } else if (path === "compare") {
                // confirmCompare is to check whether the diamond is in /confirm-list or not
                let confirmCompare = localStorage.getItem(
                  `${LOCAL_STORAGE_VAR}-confirm-compare`,
                )
                  ? JSON.parse(
                    localStorage.getItem(
                      `${LOCAL_STORAGE_VAR}-confirm-compare`,
                    ),
                  )
                  : false;
                if (confirmCompare) {
                  handleMemoPrint(
                    {},
                    checked.map((chk) => chk.diamondId),
                  );
                } else {
                  handlePrint(
                    {},
                    checked.map((chk) => chk.id),
                  );
                }
              } else {
                handlePrint(
                  {},
                  checked.map((chk) => chk.id),
                );
              }
            } else {
              if (isEmpty(checked)) {
                OpenNotification({
                  type: "error",
                  title: "Please select stone(s).",
                });
              } else if (props.data.length > 0) {
                if (path === "account") {
                  if (module === "watchlist") {
                    myAccountPDFTrack(2);
                  } else if (module === "cart") {
                    myAccountPDFTrack(1);
                  } else if (module === "quote-list") {
                    myAccountPDFTrack(4);
                  } else if (module === "confirm-list") {
                    myAccountPDFMemo();
                  } else if (module === "office-list") {
                    myAccountPDFOffice();
                  } else if (module === "notes") {
                    myAccountNotesPDF();
                  }
                } else if (path === "my-bid") {
                  myBidPDF();
                } else if (path === "suggested-stock") {
                  mySuggestedPDF();
                } else if (path === "belgium-exclusive") {
                  myExclusivePDF();
                } else if (path === "upcoming") {
                  handlePrint(
                    props.seachFilterTag
                      ? props.seachFilterTag.searchData
                      : {},
                    {},
                    true,
                  );
                } else if (path === "compare") {
                  // confirmCompare is to check whether the diamond is in /confirm-list or not
                  let confirmCompare = localStorage.getItem(
                    `${LOCAL_STORAGE_VAR}-confirm-compare`,
                  )
                    ? JSON.parse(
                      localStorage.getItem(
                        `${LOCAL_STORAGE_VAR}-confirm-compare`,
                      ),
                    )
                    : false;
                  if (confirmCompare) {
                    handleMemoPrint(
                      {},
                      localStorage.getItem(
                        `${LOCAL_STORAGE_VAR}-compare-diamond`,
                      )
                        ? JSON.parse(
                          localStorage.getItem(
                            `${LOCAL_STORAGE_VAR}-compare-diamond`,
                          ),
                        )
                        : [],
                    );
                  } else {
                    handlePrint(
                      {},
                      localStorage.getItem(
                        `${LOCAL_STORAGE_VAR}-compare-diamond`,
                      )
                        ? JSON.parse(
                          localStorage.getItem(
                            `${LOCAL_STORAGE_VAR}-compare-diamond`,
                          ),
                        )
                        : [],
                    );
                  }
                } else {
                  handlePrint(
                    props.seachFilterTag
                      ? props.seachFilterTag.searchData
                      : {},
                    {},
                  );
                }
              } else {
                return OpenNotification({
                  type: "error",
                  title: "No Stones Found",
                });
              }
            }
            props.clearAll();
          } else {
            setShowGuest(true);
          }
          setMore(!more);
        },
      },
    // USER_PERMIT &&
    // USER_PERMIT.REMOVE_FROM_RAPNET &&
    // !USER_PERMIT.REMOVE_FROM_RAPNET.view
    //   ? null
    //   : {
    //       name: "Remove from Rapnet",
    //       key: "removeFromRapnet",
    //       img: require("../../../assets/svg/DiamondList/print-list.svg"),
    //       onClick: () => {
    //         if (!isGuest) {
    //           isEmpty(checked)
    //             ? OpenNotification({
    //                 type: "error",
    //                 title: "Please select stone(s) to remove from Rapnet.",
    //               })
    //             : setVisible("rapnet");
    //         } else {
    //           setShowGuest(true);
    //         }
    //       },
    //     },
    props.sharexray
      ? {
        name: <>Share X-Ray</>,
        key: "xray",
        onClick: () => {
          if (!isGuest) {
            props.shareXray();
          } else {
            setShowGuest(true);
          }
        },
        img: require("../../../assets/svg/DiamondList/certi.svg"),
      }
      : null,
    props.viewcerti
      ? {
        name: <>Verify Certificate</>,
        key: "certi",
        onClick: () => {
          if (!isGuest) {
            props.verifyCerti();
            onClose();
          } else {
            setShowGuest(true);
          }
        },
      }
      : null,
    props.downloadzip
      ? {
        name: <>Download Zip</>,
        key: "zip",
        onClick: () => {
          if (!isGuest) {
            props.downloadZip();
            onClose();
          } else {
            setShowGuest(true);
          }
        },
      }
      : null,
  ].filter((el) => el !== null);
  let ipad = window.innerWidth <= 991 ? true : false;
  let noConfirmButton =
    getPath() === LISTINGPAGES.MYBID ||
    props.fsNow === LISTINGPAGES.MYBID ||
    getPath() === LISTINGPAGES.CONFIRM ||
    getPath() === LISTINGPAGES.BID;

  const filterConfirmStones = () => {
    const inStones = !!checked.find((c) => c?.locNm?.toUpperCase() === "INDIA");
    const ninStones = !!checked.find(
      (c) => c?.locNm?.toUpperCase() !== "INDIA",
    );
    if (inStones && ninStones) {
      return checked.filter((c) => c?.locNm?.toUpperCase() === "INDIA");
    } else return checked;
  };

  return (
    <>
      {loading ? <Loader loading={true} fixed /> : null}
      <div className="mobileSearchBottom">
        {actions.slice(0, actions.length > 4 ? 3 : 4).map((x) => {
          return (
            <div className="mobileSearchActionItem" key={x.key}>
              <a onClick={x.onClick}>
                <img alt="" src={x.img} />
                <span>
                  {x.key === "calc" ? (
                    <IntlMessages id="app.FinalCalculation" />
                  ) : (
                    x.name
                  )}
                </span>
              </a>
            </div>
          );
        })}
        {actions.length > 4 && (
          <div className="mobileSearchActionItem">
            <a onClick={moreSearchDrawer}>
              <img
                alt=""
                src={require("../../../assets/svg/DiamondSearch/more.svg")}
              />
              <span>More</span>
            </a>
          </div>
        )}
      </div>
      {moreSearch && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={moreSearch}
          className="mobileSidebar mobileBottomSidebar"
        >
          <div className="MoreSearchOptionItem">
            <ul>
              {actions.slice(3, actions.length).map((x) => {
                return (
                  <li onClick={x.onClick} key={x.key}>
                    <a>{x.name}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </Drawer>
      )}
      <div
        className={
          "botoomStickyBar diamondListingAction" +
          (props.ActionFalse === false ? " justify-content-center" : "")
        }
      >
        <div className="d-flex listingAction diamond-list-btn">
          {props.modify && (
            <button className="bottomStickyButton" onClick={props.onModify}>
              <IntlMessages id="app.modify" />
            </button>
          )}
          {actions
            .slice(
              0,
              ipad
                ? 3
                : actions.length > 7
                  ? actions.length - 3
                  : actions.length,
            )
            .map((x) => {
              return (
                <a
                  key={x.key}
                  className={`bottomStickyButton ${x.hightlight && !x.disabled ? "buttonHlightlight" : ''
                    } ${x.disabled ? `disable` : ''}`}
                  onClick={x.onClick}
                >
                  <span>{x.name}</span>
                </a>
              );
            })}
          {((ipad && actions.length > 3) || actions.length > 7) && (
            <>
              {actions
                .slice(ipad ? 3 : actions.length - 3, actions.length)
                .map((x, i) => {
                  return (
                    <a key={i + "li"} onClick={x.onClick} className="bottomStickyButton">
                      <span>{x.name}</span>
                    </a>
                  );
                })}
            </>
          )}
          {/* More Option Buttons in Dropdown  */}
          {/* <Dropdown isOpen={more} toggle={() => setMore(!more)}>
            <DropdownToggle className="bottomStickyButton" caret>
              <span>
                <IntlMessages id="app.More" />
              </span>
            </DropdownToggle>
            <DropdownMenu className="diamondListBottomDropdown">
              <ul>
                {actions
                  .slice(ipad ? 3 : actions.length - 3, actions.length)
                  .map((x, i) => {
                    return (
                      <li key={i + "li"} onClick={x.onClick}>
                        <a>
                          <div className="dropdownBottomItem">
                            <span>{x.name}</span>
                          </div>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </DropdownMenu>
          </Dropdown> */}

          <Dropdown isOpen={whatsApp} toggle={() => setWhatsApp(!whatsApp)}>
            <DropdownToggle className="bottomStickyButton" caret>
              <span>
                <IntlMessages id="app.Whatsapp" />
              </span>
            </DropdownToggle>
            <DropdownMenu className="diamondListBottomDropdown">
              <ul>
                <li onClick={withpriceCopy}>
                  <a>
                    <div className="dropdownBottomItem">
                      <span>With Price</span>
                    </div>
                  </a>
                </li>
                <li onClick={withoutpriceCopy}>
                  <a>
                    <div className="dropdownBottomItem">
                      <span>Without Price</span>
                    </div>
                  </a>
                </li>
                <li onClick={DNAlink}>
                  <a>
                    <div className="dropdownBottomItem">
                      <span>DNA</span>
                    </div>
                  </a>
                </li>
              </ul>
            </DropdownMenu>
          </Dropdown>
        </div>
        {props.nopaginate ? null : <Pagination {...props} />}
      </div>
      {holdPopup && (
        <HoldPopup
          visible={holdPopup}
          handleCancel={() => setHoldPopUp(false)}
          {...props}
          checked={checked}
          chnageStatus={props?.fetch}
        // clearAllSelectedRows={clearAllSelectedRows}
        // userTerms={userTerms}
        />
      )}
      {/* {holdPopup && (
        <Drawer title={false} onClose={onClose} visible={true} className="diamondListinSidebar xl-size">
          <HoldStonePopup {...props} checked={checked} onClose={onClose} />
        </Drawer>
      )} */}
      {visible === "memo" && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={true}
          className="diamondListinSidebar xl-size"
        >
          <MemoPopup {...props} checked={checked} onClose={onClose} />
        </Drawer>
      )}
      {visible === "confirm" && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={true}
          className="diamondListinSidebar xl-size"
        >
          <ConfirmStonePopup
            {...props}
            checked={filterConfirmStones()}
            onClose={onClose}
          />
        </Drawer>
      )}
      {visible === "quote" || props.editOffer ? (
        <Drawer
          title={false}
          onClose={() => {
            onClose();
            if (props.editOffer) props.fetch();
          }}
          visible={true}
          className="diamondListinSidebar offerPopup xl-size"
        >
          <QuotePopup
            quoteDay={getPath() === LISTINGPAGES.QUOTEDAY ? true : false}
            {...props}
            checked={checked.filter((el) => !getNewStatus(el))}
            handleLoader={(val) => setLoading(val)}
            onClose={() => {
              onClose();
              if (props.editOffer) props.fetch();
            }}
          />
        </Drawer>
      ) : null}
      {visible === "bid" && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={localStorage.getItem("bidEndTime") === "true" ? false : true}
          className="diamondListinSidebar xl-size"
        >
          <BidPopup
            {...props}
            checked={checked}
            onClose={onClose}
            handleLoader={(val) => setLoading(val)}
          />
        </Drawer>
      )}
      {visible === "note" && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={true}
          className="diamondListinSidebar xl-size"
        >
          <NotePopup {...props} checked={checked} onClose={onClose} />
        </Drawer>
      )}
      {visible === "office" && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={true}
          className="diamondListinSidebar OfficePopup"
        >
          <OfficeSidebar
            bid={getPath() === LISTINGPAGES.BID ? true : false}
            quoteDay={getPath() === LISTINGPAGES.QUOTEDAY ? true : false}
            {...props}
            checked={checked}
            onClose={onClose}
            setLoading={setLoading}
          />
        </Drawer>
      )}

      {visible === "finalCalc" && (
        <Drawer
          className="diamondListinSidebar xl-size"
          visible={visible === "finalCalc"}
          onClose={onClose}
        >
          <FinalCalculations
            {...props}
            orderDiamond={
              window.location.pathname === "/account/confirm-list"
                ? true
                : false
            }
            checked={checked}
            onClose={onClose}
            noConfirmButton={noConfirmButton}
            setLoading={setLoading}
          />
        </Drawer>
      )}

      {visible === "cheque" && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={true}
          className="diamondListinSidebar xl-size"
        >
          <ChequePopup {...props} checked={checked} onClose={onClose} />
        </Drawer>
      )}
      {visible === "watchlist" && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={true}
          className="diamondListinSidebar xl-size"
        >
          <WatchlistPopup
            {...props}
            checked={checked}
            onClose={onClose}
            setLoading={setLoading}
          />
        </Drawer>
      )}
      {visible === "rapnet" && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={true}
          className="diamondListinSidebar xl-size"
        >
          <RapnetPopup {...props} checked={checked} onClose={onClose} />
        </Drawer>
      )}
      {visible === "share" && isMobileView === false && (
        <CommonModal
          modalSize="ListingModal"
          title="Share Stone"
          handleCancel={onClose}
          visible={true}

        >
          <ShareDropdown
            {...props}
            checked={checked}
            orderDiamond={
              window.location.pathname === "/account/confirm-list"
                ? true
                : false
            }
            onCloseSharePopup={onClose}
            handleLoader={(val) => setLoading(val)}
          />
        </CommonModal>
      )}
      {visible === "share" && isMobileView === true && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={true}
          className="diamondListinSidebar xl-size"
        >
          <MobileShareOption {...props} checked={checked} onClose={onClose} />
        </Drawer>
      )}
      {visible === "download" && isMobileView === false && (
        <CommonModal
          modalSize="ListingModal"
          title={<IntlMessages id="app.Download" />}
          handleCancel={onClose}
          visible={true}
        >
          <DownloadDropdown
            {...props}
            checked={checked}
            orderDiamond={
              window.location.pathname === "/account/confirm-list" ||
                (localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`)
                  ? JSON.parse(
                    localStorage.getItem(
                      `${LOCAL_STORAGE_VAR}-confirm-compare`,
                    ),
                  )
                  : false)
                ? true
                : false
            }
            onCloseDownloadPopup={onClose}
          />
        </CommonModal>
      )}
      {visible === "download" && isMobileView === true && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={true}
          className="diamondListinSidebar xl-size"
        >
          <MobileDownloadOption
            {...props}
            checked={checked}
            onClose={onClose}
          />
        </Drawer>
      )}

      {reminder === "insert" && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={true}
          className="diamondListinSidebar"
        >
          <Reminder
            handleCancel={() => {
              setReminder(false);
              props.clearAll();
            }}
            handleSetReminder={(e) =>
              handleInsertTrack(
                trackTypeObj.REMINDER,
                checked,
                () => {
                  props.clearAll();
                  setReminder(false);
                },
                e,
              )
            }
            checked={checked}
          />
        </Drawer>
      )}
      {reminder === "update" && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={true}
          className="diamondListinSidebar xl-size"
        >
          <UpdateReminder
            handleCancel={() => {
              setReminder(false);
              props.clearAll();
            }}
            date={checked && checked[0] ? checked[0].reminderDate : null}
            handleUpdateReminder={(e) =>
              handleUpsertTrack(
                trackTypeObj.REMINDER,
                checked,
                props.inTrackDiamonds,
                () => {
                  props.clearAll();
                  props.fetch();
                  setReminder(false);
                },
                e,
              )
            }
            checked={checked}
          />
        </Drawer>
      )}

      {bidModalShow ? (
        <BidValidationModal visible={bidModalShow} handleOk={handleOk} />
      ) : (
        ""
      )}

      {checked && checked.length ? (
        disclaimer === "confirm" ? (
          <ConfirmStoneModal
            checked={checked}
            handleCancel={() => setDisclaimer(false)}
            handleOk={() => {
              if (
                checked.length ===
                checked.filter((s) => s?.locNm.toUpperCase() !== "INDIA").length
              ) {
                OpenNotification({
                  type: "error",
                  title: `You can't confirm ${checked
                    .map((a) => a?.vStnId)
                    .join(",")} stone(s).`,
                });
              } else {
                setVisible(disclaimer);
              }
              setDisclaimer(false);
            }}
          />
        ) : disclaimer === "bid" ? (
          <BidDisclaimerModal
            checked={checked}
            handleCancel={() => setDisclaimer(false)}
            handleOk={() => {
              setVisible(disclaimer);
              setDisclaimer(false);
            }}
          />
        ) : disclaimer === "quote" ? (
          <OfferDisclaimerModal
            checked={checked}
            handleCancel={() => setDisclaimer(false)}
            handleOk={() => {
              setVisible(disclaimer);
              setDisclaimer(false);
            }}
          />
        ) : (
          <CommonModal
            handleCancel={() => setDisclaimer(false)}
            handleOk={() => {
              setVisible(disclaimer);
              setDisclaimer(false);
            }}
            visible={disclaimer}
            modalSize="xs-size"
            footerShow
            title={<IntlMessages id="app.Disclaimer" />}
            submitTitle={<IntlMessages id="app.IAgree" />}
            cancelTitle={<IntlMessages id="app.Quit" />}
          >
            {checked
              .map((x) => (x.locNm ? x.locNm.toUpperCase() : ""))
              .filter((el) => el !== "INDIA").length ? (
              <p>
                <IntlMessages id="app.PacketNo" />:
                {checked.map((x) => x.vStnId).join(", ")},
                <IntlMessages id="app.isLocatedIn" />{" "}
                {uniq(
                  checked.map((x) => (x.locNm ? x.locNm.toUpperCase() : "")),
                ).join("/")}{" "}
                <IntlMessages id="app.notAvail" />
                <br />
                <p className="mt-10">
                  <IntlMessages id="app.pandemicNotice" />
                </p>
              </p>
            ) : (
              <p>
                <IntlMessages id="app.PacketNo" />:
                {checked.map((x) => x.vStnId).join(", ")},{" "}
                <IntlMessages id="app.deliverIndia" />
              </p>
            )}
          </CommonModal>
        )
      ) : null}
      {showGuest && (
        <GuestUserPopup
          visible={showGuest}
          register={() => {
            window.open("/signup", "_self");
            window.localStorage.clear();
            window.sessionStorage.clear();
          }}
          handleCancel={() => {
            setShowGuest(false);
          }}
        />
      )}
      {visible === "delete-confirm" && (
        <CommonModal
          visible={true}
          handleOk={() => {
            handleBidDelete(
              checked.map((row) => row.trackId),
              (flag) => {
                props.fetch();
                props.clearAll();
                if (flag) props.setCount(!props.deleteReminder.count);
              },
            );
            setVisible("");
          }}
          handleCancel={() => setVisible("")}
          title="Delete Confirmation"
          footerShow
          submitTitle="Yes"
          cancelTitle="No"
        >
          <div className="searchPopupCommon">
            <div className="searchPopupTwoValue">
              <p className="text-center deleteMessage">
                Are you sure you want to remove bid stones?
              </p>
            </div>
          </div>
        </CommonModal>
      )}
      <CommonModal
        handleOk={handleNewArrivalRedirect}
        handleCancel={handleContinue}
        title="New Arrival Stones"
        submitTitle="Go to New Arrival"
        cancelTitle={
          currentAction === "office"
            ? "Continue with Look and Bid stones"
            : "Continue without New Arrival"
        }
        visible={validatePopup}
        footerShow
      >
        {currentAction === "office" ? (
          <div className="searchPopupCommon" style={{ padding: 15 }}>
            {checked
              ?.filter(({ wSts, bidType }) => wSts === "B" && bidType !== 2)
              ?.map(({ vStnId }) => vStnId)
              ?.join(", ")}{" "}
            stones are not new arrival (look and bid) stones, you can not
            performs this action.
          </div>
        ) : (
          <div className="searchPopupCommon" style={{ padding: 15 }}>
            {checked
              ?.filter(({ wSts }) => wSts === "B")
              ?.map(({ vStnId }) => vStnId)
              ?.join(", ")}{" "}
            stones are new arrival stones, you can not performs this action.
          </div>
        )}
      </CommonModal>
    </>
  );
};

export default connect(
  (props) => {
    return props;
  },
  { setCount },
)(DiamondListingAction);
