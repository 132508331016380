/* eslint-disable */
import React from "react";
import "./textareaa.less";

function TextArea({ label, required, error, row, ...props }) {
  return (
    <div className={"from-group textAreaWrapper " + (error ? "error-message" : '')}>
      <label className="textArealable">
        {label} {required && <span className="required-ast">*</span>}
      </label>
      <textarea
        className="commonTextArea"
        style={row ? { height: "auto" } : {}}
        rows={row || 2}
        {...props}
      />
      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default React.memo(TextArea);
