// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const WORKBOX_CACHE_KEY = `workbox-precache-v2-http://${window.location.hostname}/`;

const clearFromCache = async (requests = []) => {
	// if ('caches' in self) {
		const cache = await caches.open(WORKBOX_CACHE_KEY);
		if (cache) {
			await Promise.all(requests.map(r =>
				cache.delete(r)
			));

			requests.forEach(r => {
				// console.log(`Cache ForEach => delete[${r}]`);
			});

			const keys = await caches.keys();
			const cacheKey = keys.find(key => key === WORKBOX_CACHE_KEY);
			if (cacheKey) {
				await caches.delete(cacheKey);
			}
		}
	// }
}

export function register() {
	clearFromCache(['/index.html']);	
}