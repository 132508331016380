/* eslint-disable */
import React from "react";
import Slider from "react-slick";
import Image from "../common/Image";
import noImageSvg from "../../assets/svg/noimage.svg";

const setting = [
  {
    breakpoint: 1400,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 991,
    settings: {
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 2,
    },
  },
];

const VideoBlock = ({ ...props }) => {
  const slider1 = React.useRef(null);
  const slider2 = React.useRef(null);
  const { dnaData, setCurrentIndex, currentIndex, getMediaUrl } = props;

  React.useEffect(() => {
    slider1.current.slickGoTo(0);
    slider2.current.slickGoTo(0);
  }, []);

  React.useEffect(() => {
    slider1.current.slickGoTo(currentIndex);
    slider2.current.slickGoTo(currentIndex);
  }, [currentIndex]);

  return (
    <>
      <div>
        <Slider
          className="sliderMainImage"
          arrows={false}
          ref={slider1}
          beforeChange={(current, next) => setCurrentIndex(next)}
        >
          {dnaData.videoFile ? (
            <div className="imageSliderFullBlock">
              <iframe title="img1" src={getMediaUrl("videoFile")} />
            </div>
          ) : (
            <div className="imageSliderFullBlock no-msg">Video Coming Soon</div>
          )}

          {dnaData.b2cJsonVideo ? (
            <div className="imageSliderFullBlock">
              <iframe title="img2" src={getMediaUrl("b2cJsonVideo")} />
            </div>
          ) : (
            <div className="imageSliderFullBlock no-msg">Video Coming Soon</div>
          )}
        </Slider>
      </div>
      <Slider
        className="sliderSmallImage"
        ref={slider2}
        slidesToShow={2}
        infinite={true}
        arrows={false}
        centerMode={true}
        swipeToSlide={true}
        focusOnSelect={true}
        responsive={setting}
        beforeChange={(current, next) => setCurrentIndex(next)}
      >
        <div className="imageSliderSmallBlock">
          <div className="imageSliderSmallBox">
            {dnaData.videoFile ? (
              <>
                <div className="imageVideoIcon">
                  <Image src={require("../../assets/svg/play.svg")} alt="" />
                </div>
                <Image src={getMediaUrl("img")} />
              </>
            ) : (
              <img src={noImageSvg} alt="" />
            )}
          </div>
          <span className="imageVideoTitle">B2B Video</span>
        </div>

        <div className="imageSliderSmallBlock">
          <div className="imageSliderSmallBox">
            {dnaData.b2cJsonVideo ? (
              <>
                <div className="imageVideoIcon">
                  <Image src={require("../../assets/svg/play.svg")} alt="" />
                </div>
                <Image src={getMediaUrl("b2cRealImage")} />
              </>
            ) : (
              <img src={noImageSvg} alt="" />
            )}
          </div>
          <span className="imageVideoTitle">B2C Video</span>
        </div>
      </Slider>
    </>
  );
};

export default VideoBlock;
