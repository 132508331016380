/* eslint-disable */
import React, { Component } from "react";
import { BASE_URL, DASHBOARD_MEDIA_URL } from "../../../constants/Common";
import Slider from "react-slick";
import { browserName, isSafari } from "react-device-detect";
class TwoBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: undefined,
    };
  }
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 30000, //30 seconds
    };
    let left = this.props.leftBanner && this.props.leftBanner;
    let right = this.props.rightBanner && this.props.rightBanner;
    // let data = this.props && this.props.bannerData;
    // let leftImgPath = `${BASE_URL}${left.backgroundImage}`;
    // let rightImgPath = `${BASE_URL}${right.backgroundImage}`;

    return (
      <>
        {(left?.isActive || right?.isActive) && (
          <div className="homeContainer">
            <div className="homeTwoBanner">
              {left?.isActive && (
                <div className="hometwoBannerItem leftBlock">
                  <div className="homeTwoBannerContent homeBanner1Conetnt">
                    {left && left?.eventName && <h2>{left.eventName}</h2>}
                    {left && left?.description && <p>{left.description}</p>}
                  </div>
                  <Slider {...settings}>
                    {left?.images.map((f, index) => {
                      let ext =
                        typeof f === "string"
                          ? f?.split(".").pop().toLowerCase()
                          : f?.url?.split(".").pop().toLowerCase();
                      let fUrl = typeof f === "string" ? f : f?.url;

                      let url = "";
                      if (!fUrl.startsWith("/")) {
                        url = DASHBOARD_MEDIA_URL + "/" + fUrl;
                      } else {
                        url = DASHBOARD_MEDIA_URL + fUrl;
                      }
                      return (
                        <div key={index}>
                          {["mp4", "mkv", "wmv"].includes(ext) ? (
                            <div className="homeTwoBannerImage">
                              <video
                                autoPlay={!isSafari ? true : false}
                                loop
                                muted
                              >
                                <source src={url}></source>
                              </video>
                            </div>
                          ) : (
                            <div className="homeTwoBannerImage">
                              <img src={url} alt="banner" />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              )}
              {right?.isActive && (
                <div className="hometwoBannerItem rightBlock">
                  <Slider {...settings}>
                    {right?.images.map((f, index) => {
                      let ext =
                        typeof f === "string"
                          ? f?.split(".").pop().toLowerCase()
                          : f?.url?.split(".").pop().toLowerCase();
                      let fUrl = typeof f === "string" ? f : f?.url;
                      let url = "";
                      if (!fUrl.startsWith("/")) {
                        url = DASHBOARD_MEDIA_URL + "/" + fUrl;
                      } else {
                        url = DASHBOARD_MEDIA_URL + fUrl;
                      }
                      return (
                        <div key={index}>
                          {["mp4", "mkv", "wmv"].includes(ext) ? (
                            <div className="homeTwoBannerImage">
                              <video autoPlay loop muted>
                                <source src={url}></source>
                              </video>
                            </div>
                          ) : (
                            <div className="homeTwoBannerImage">
                              <img src={url} alt="banner" />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </Slider>
                  <div className="homeTwoBannerContent homeBanner1Conetnt">
                    {right && right?.eventName && <h2>{right.eventName}</h2>}
                    {right && right?.description && <p>{right.description}</p>}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default TwoBanner;
