/* eslint-disable */
import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import Image from "../common/Image";
import noImageSvg from "../../assets/svg/noimage.svg";
import MagnifierImage from "../DiamondDetail/MagnifierImage";

const setting = [
  {
    breakpoint: 1400,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 991,
    settings: {
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 2,
    },
  },
];

const ImageBlock = (props) => {
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  const { dnaData, setCurrentIndex, currentIndex, getMediaUrl } = props;

  useEffect(() => {
    slider1.current.slickGoTo(currentIndex);
    slider2.current.slickGoTo(currentIndex);
  }, [currentIndex]);

  return (
    <>
      <div>
        <Slider
          className="sliderMainImage sliderTwoImageBlock"
          arrows={false}
          ref={slider1}
          beforeChange={(current, next) => setCurrentIndex(next)}
        >
          {dnaData.img ? (
            <div className="imageSliderFullBlock">
              <MagnifierImage image={getMediaUrl("img")}>
                <div className="no-msg">Image Coming Soon</div>;
              </MagnifierImage>
            </div>
          ) : (
            <div className="imageSliderFullBlock no-msg">Image Coming Soon</div>
          )}
          {dnaData.b2cRealImage ? (
            <div className="imageSliderFullBlock">
              <MagnifierImage image={getMediaUrl("b2cRealImage")}>
                <div className="no-msg">Image Coming Soon</div>;
              </MagnifierImage>
            </div>
          ) : (
            <div className="imageSliderFullBlock no-msg">Image Coming Soon</div>
          )}
        </Slider>
      </div>
      <Slider
        className="sliderSmallImage"
        asNavFor={props.nav1}
        ref={slider2}
        slidesToShow={2}
        centerMode={true}
        infinite={true}
        arrows={false}
        swipeToSlide={true}
        focusOnSelect={true}
        responsive={setting}
        beforeChange={(current, next) => setCurrentIndex(next)}
      >
        {/* {dnaData.img && ( */}
        <div className="imageSliderSmallBlock">
          <div className="imageSliderSmallBox">
            {dnaData.img ? (
              <Image src={getMediaUrl("img")} />
            ) : (
              <img src={noImageSvg} alt="" />
            )}
          </div>
          <span className="imageVideoTitle">B2B Image</span>
        </div>
        {/* )} */}

        <div className="imageSliderSmallBlock">
          <div className="imageSliderSmallBox">
            {dnaData.b2cRealImage ? (
              <Image src={getMediaUrl("b2cRealImage")} />
            ) : (
              <img src={noImageSvg} alt="" />
            )}
          </div>
          <span className="imageVideoTitle">B2C Image</span>
        </div>
      </Slider>
    </>
  );
};

export default ImageBlock;
