/* eslint-disable */
import React from "react";
import Magnifier from "react-magnifier";
import { useBoolean } from "../../util/hooks";
import noImageSvg from "../../assets/svg/noimage.svg";

function MagnifierImage(props) {
  const {
    image,
    src,
    zoomFactor = 1,
    clickToOpen,
    children,
    ...restProps
  } = props;

  const ref = React.useRef();
  const url = React.useMemo(() => src || image, [src, image]);
  const [error, setError] = useBoolean();

  const handleClick = React.useCallback(
    () => void (clickToOpen && window.open(url, "_blank")),
    [clickToOpen, url],
  );

  React.useEffect(() => {
    if (!error || ref.current) {
      const image = ref.current.img;
      image.addEventListener("error", setError.true);
    } else if (error) {
      return () => {
        setError.false();
      };
    }
    // if (error || !ref.current) return;
  });

  if (error) {
    return (
      children || (
        <img {...props} src={noImageSvg} alt="" style={props?.style ?? {}} />
      )
    );
  } else
    return (
      <Magnifier
        ref={ref}
        src={url}
        zoomFactor={zoomFactor}
        onClick={handleClick}
        {...restProps}
      />
    );
}

export default MagnifierImage;
