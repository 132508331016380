/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Dropdown, Drawer } from "antd";
import Enquiry from "../../components/Enquiry";
import PersonalInformation from "./PersonalInformation";
import CompanyInformation from "./CompanyInformation";
import ManageUser from "./ManageUsers";
import ManageAddress from "./ManageAddress";
import ChangePassword from "./ChangePassword";
import DiamondColumnListing from "./ManageDiamondSequence";
import SavedSearch from "../SavedSearch/index";
import MyDemand from "../MyDemand/index";
import Offer from "../Offer";
import "../../components/common/Tab";
import DiamondList from "../../components/DiamondList/index";
import IntlMessages from "../../util/IntlMessages";
import NotificationSetting from "./NotificationSetting";
import Profile from "./Profile";
import {
  getPath,
  isMobile,
  LISTINGPAGES,
} from "../DiamondList/DiamondListFunctions";
import SelectStone from "../common/DiamondListing/SelectStone";
import Notifications from "../../components/Account/Notifications";
import UtilService from "../../services/util";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import Memo from "../Memo/Memo";
import MyPurchase from "components/MyPurchase/MyPurchase";

class Account extends Component {
  state = { visible: false, visible1: false, visible2: false, sum: {} };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  showDrawer1 = () => {
    this.setState({
      visible1: true,
    });
  };
  showDrawer2 = () => {
    this.setState({
      visible2: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
      visible1: false,
      visible2: false,
    });
  };

  render() {
    const { location } = this.props;
    const profilePath = location.pathname === "/account/profile";
    const manageuserPath = location.pathname === "/account/profile/manage-user";
    const manageAddressPath =
      location.pathname === "/account/profile/manage-address";
    const diamondsequencePath =
      location.pathname === "/account/profile/diamond-sequence";
    const myProfile = location.pathname === "/account/profile";
    const USER_PERMIT = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-userPermissions`,
    );

    let TABS = [
      USER_PERMIT &&
        USER_PERMIT?.WATCHLIST?.view && {
          path: "/watchlist",
          title: <IntlMessages id="app.my-watchlist" />,
          tab: (
            <DiamondList
              onSumChange={(sum, currentType) =>
                this.setState({ sum, currentType })
              }
            />
          ),
        },
      USER_PERMIT &&
        USER_PERMIT?.CART?.view && {
          path: "/cart",
          title: <IntlMessages id="app.my-cart" />,
          tab: (
            <DiamondList
              onSumChange={(sum, currentType) =>
                this.setState({ sum, currentType })
              }
            />
          ),
        },

      // USER_PERMIT &&
      //   USER_PERMIT?.OFFICE?.view && {
      //     path: "/office-list",
      //     title: <IntlMessages id="app.office-list" />,
      //     tab: (
      //       <DiamondList
      //         onSumChange={(sum, currentType) =>
      //           this.setState({ sum, currentType })
      //         }
      //       />
      //     ),
      //   },
      USER_PERMIT &&
        USER_PERMIT?.CONFIRM_STONE?.view && {
          path: "/confirm-list",
          title: <IntlMessages id="app.confirm-list" />,
          tab: (
            <DiamondList
              onSumChange={(sum, currentType) =>
                this.setState({ sum, currentType })
              }
            />
          ),
        },
      // USER_PERMIT &&
      //   USER_PERMIT?.QUOTE?.view && {
      //     path: "/quote-list",
      //     title: <IntlMessages id="app.quote-list" />,
      //     tab: (
      //       <Offer
      //         onSumChange={(sum, currentType) =>
      //           this.setState({ sum, currentType })
      //         }
      //       />
      //     ),
      //   },
      USER_PERMIT &&
        USER_PERMIT?.NOTES?.view && {
          path: "/notes",
          title: <IntlMessages id="app.my-comment" />,
          tab: (
            <DiamondList
              onSumChange={(sum, currentType) =>
                this.setState({ sum, currentType })
              }
            />
          ),
        },
      // {
      //   path: "/reminder",
      //   title: <IntlMessages id="app.my-reminder" />,
      //   tab: (
      //     <DiamondList onSumChange={(sum, currentType) => this.setState({ sum, currentType })} />
      //   ),
      // },
      // USER_PERMIT &&
      // USER_PERMIT?.HOLD?.view &&
      // {
      //   path: "/hold",
      //   title: <IntlMessages id="app.myHoldList" />,
      //   tab: (
      //     <DiamondList onSumChange={(sum, currentType) => this.setState({ sum, currentType })} />
      //   ),
      // },
      // USER_PERMIT &&
      // USER_PERMIT?.MEMO?.view &&
      // {
      //   path: "/memo",
      //   title: <IntlMessages id="app.myMemoList" />,
      //   tab: (
      //     <DiamondList onSumChange={(sum, currentType) => this.setState({ sum, currentType })} />
      //   ),
      // },

      USER_PERMIT &&
        USER_PERMIT?.SAVE_SEARCH?.view && {
          path: "/saved-search",
          title: <IntlMessages id="app.saved-search" />,
          tab: <SavedSearch />,
        },
      USER_PERMIT &&
        USER_PERMIT?.SAVE_SEARCH?.view && {
          path: "/purchase",
          title: "My Purchase",
          tab: <MyPurchase />,
        },
      USER_PERMIT &&
        USER_PERMIT?.SAVE_SEARCH?.view && {
          path: "/memo",
          title: "My Memo",
          tab: <Memo />,
        },
      USER_PERMIT &&
        USER_PERMIT?.DEMAND?.view && {
          path: "/my-demand",
          title: <IntlMessages id="app.my-demand-tab" />,
          tab: <MyDemand />,
        },
      USER_PERMIT &&
        USER_PERMIT?.NOTIFICATIONS?.view && {
          path: "/notifications",
          title: <IntlMessages id="app.Notification" />,
          tab: <Notifications />,
        },
    ];
    let isaccount = window.location.pathname.split("/")[1] === "account";

    return (
      <div>
        <div className="accountWrapper">
          <div className="accountTopBlock">
            {isaccount &&
            !isMobile() &&
            (getPath() === LISTINGPAGES.CONFIRM ||
              getPath() === LISTINGPAGES.QUOTE) ? (
              <SelectStone
                clearStyle={{ position: "inherit" }}
                blockStyle={{
                  position: "initial",
                  borderBottom: "none",
                  width: "fit-content",
                  paddingRight: "0px",
                }}
                sum={this.state.sum}
                currentType={this.props.location.pathname}
                profilListing={isaccount ? "profileStonSelect" : null}
              />
            ) : null}
          </div>
          <div className="customerSubTab">
            <ul className="accountTabBox">
              {TABS.map((x, i) => {
                return (
                  <li
                    key={i}
                    className={
                      location.pathname === "/account" + x.path
                        ? "react-tabs__tab--selected"
                        : null
                    }
                  >
                    <Link to={"/account" + x.path}>{x.title}</Link>
                  </li>
                );
              })}
              <li
                className={`${
                  manageuserPath ||
                  manageAddressPath ||
                  diamondsequencePath ||
                  profilePath
                    ? "react-tabs__tab--selected"
                    : ""
                }`}
              >
                <Link to="/account/profile">
                  <IntlMessages id="app.my-account" />
                </Link>
              </li>
            </ul>
            {TABS.map((x) => {
              return location.pathname === "/account" + x.path ? (
                <>{x.tab}</>
              ) : (
                <></>
              );
            })}
            {myProfile && <Profile />}
            <Drawer
              title={false}
              onClose={this.onClose}
              visible={this.state.visible}
              className="diamondListinSidebar profileSidebar"
              destroyOnClose
            >
              <PersonalInformation />
            </Drawer>
            <Drawer
              title={false}
              onClose={this.onClose}
              visible={this.state.visible1}
              className="diamondListinSidebar profileSidebar"
            >
              <CompanyInformation />
            </Drawer>
            {manageuserPath && <ManageUser />}
            <Drawer
              title={false}
              onClose={this.onClose}
              visible={this.state.visible2}
              className="diamondListinSidebar profileSidebar"
            >
              <ChangePassword />
            </Drawer>
            {manageAddressPath && <ManageAddress />}
            {diamondsequencePath && <DiamondColumnListing />}
          </div>
        </div>
      </div>
    );
  }
}

export default connect((props) => {
  return props;
}, null)(withRouter(Account));
