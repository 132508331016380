/* eslint-disable */
import React from "react";
import moment from "moment";

class Countdown extends React.Component {
  state = {
    time: moment(),
  };

  componentDidMount() {
    setInterval(() => this.forceUpdate(), 1000);
    if (window.shortPath() === "my-bid") {
      this.setTimer(this.props.currTime);
    }
  }

  setTimer = (tm) => {
    let time = tm;
    setInterval(async () => {
      if (time && moment(time)._isValid) {
        time = moment(time).add(1, "seconds");
        this.setState({ time: moment(time) });
      }
    }, 1000);
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.currTime !== nextProps.currTime)
      this.setTimer(nextProps.currTime);
  };

  leadingZero = (num) => {
    return num <= 0 ? "00" : num < 10 && num > 0 ? "0" + num : num;
  };

  getses = (num, label) => {
    if (num === 1 || num === "01") return label;
    else return label + " ";
  };

  render() {
    if (!this.props.date || !this.props.date.diff) return null;
    let diff = moment();
    //let current = this.props.currTime ? moment(this.props.currTime) : moment();
    //let current = moment();
    // if(window.shortPath() === "my-bid"){
    //   let current = this.state.time;
    //   diff = moment.duration(this.props.date.diff(current,'mm'))._data;
    //   // if (diff.days) diff.hours += diff.days * 24;
    //   if (diff.hours === 0 && diff.minutes  === 10 &&  diff.seconds  === 0  && this.props.bidEndTime){
    //     this.props.bidEndTime(diff);
    //     localStorage.setItem('bidEndTime',true);
    //   } else if(diff.minutes  >= 10 ){
    //     localStorage.setItem('bidEndTime',false);
    //   }
    // }else{
    diff = moment.duration(this.props.date.diff(moment().local()))._data;
    if (diff.days) diff.hours += diff.days * 24;
    if (diff.hours + diff.minutes + diff.seconds === 0 && this.props.fetch)
      this.props.fetch();
    // }
    return (
      <div className="App">
        <div className="countDownBlock">
          {window.shortPath() === "my-bid" &&
          diff.hours <= 0 &&
          diff.minutes <= 0 &&
          diff.seconds <= 0 ? (
            <div className="countdownItem">Bid Expire</div>
          ) : (
            <>
              <div className="countdownItem">
                <span>{this.leadingZero(diff.hours)}</span>
                <span style={{ marginRight: "10px" }}>
                  {this.getses(diff.hours, "Hrs")}
                </span>
              </div>
              <div className="countdownItem">
                <span>{this.leadingZero(diff.minutes)}</span>
                <span style={{ marginRight: "10px" }}>
                  {this.getses(diff.minutes, "Min")}
                </span>
              </div>
              <div className="countdownItem">
                <span>{this.leadingZero(diff.seconds)} </span>
                <span style={{ marginRight: "10px" }}>
                  {this.getses(diff.seconds, "Sec")}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Countdown;
