/* eslint-disable */
import React, { useEffect, useState } from "react";
import Datepicker from "../common/DatePicker";
import Heading from "../common/Heading";
import UtilService from "../../services/util";
import { GET_RAPPORT } from "../../constants/apiConstant";
import { BASE_URL } from "../../constants/Common";
const RapnetPrice = () => {
  const [date, setDate] = useState(null);
  const [path, setPath] = useState("");
  const handleChangeDate = (e) => {
    let da = null;
    if (e) {
      da = new Date(e._d).toISOString();
    }
    getlist(da);
    setDate(da);
  };
  useEffect(() => {
    getlist(date);
  }, []);
  const getlist = (dates) => {
    let objData = {
      ...GET_RAPPORT,
      request: {
        date: dates,
      },
    };
    UtilService.callApi(objData, function (err, data) {
      if (err) throw err;
      if (data && data.code === "OK") {
        if (data.data && data.data.filePaths && data.data.filePaths.length) {
          setPath(data.data.filePaths[0]);
        }
      }
    });
  };
  return (
    <div>
      <div className="staticBoxMain">
        <div className="statiContentBox">
          <Heading
            title="Rapnet Price List"
            className="staticMainTitle text-center mb-50"
          />

          <div className="staticContentWrapper">
            <p
              style={{
                width: "235px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Datepicker
                groupClass="SelectDateRange"
                label="Select Date:"
                // disabledDate={(current) => {
                //   return current && current <= moment().startOf("day");
                // }}
                expiryDate={date}
                placeholder="Date"
                format="ll"
                handleChange={(date) => handleChangeDate(date)}
                // hasError={formData.errors["date"] ? true : false}
              />
            </p>
            {/* <div className="diamondDetailImageBox"> */}
            {path ? (
              <iframe
                height="100%"
                // width='600px'
                src={BASE_URL + "/" + path}
              />
            ) : (
              "No Data Found"
            )}

            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RapnetPrice;
