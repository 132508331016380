/* eslint-disable */
import React from "react";
import SignUpForm from "./Auth/SignUpForm";
import AuthPoster from "./Auth/AuthPoster";
// import Logo from "./common/Logo";

const SignUp = (props) => {
  return (
    <div className="loginMainWrapper registerLogWrapper">
      <div className="LoginRightBlock" style={{ width: "100%" }}>
        <SignUpForm
          section_1="section_1"
          section_2="section_2"
          section_3="section_3"
          section_4="section_4"
        />
      </div>
    </div>
  );
};

export default SignUp;
