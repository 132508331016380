/* eslint-disable */
import React, { useState, useEffect } from "react";
import { isArray } from "lodash";
import Heading from "../common/Heading";
import MagnifierImage from "../DiamondDetail/MagnifierImage";
import last from "lodash/last";
import split from "lodash/split";
import { VIEW_DIAMOND } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import { isEmpty } from "util/utils";
import { getProjectSetting } from "components/DiamondList/DiamondListFunctions";
import { PROJECT_SETTINGS_TYPE } from "constants/Common";

const imageList = {
  HeartImages: "hAFile",
  ArrowImages: "arrowFile",
  PlottingImages: "pltFile",
  RoughImages: "roughImage",
  PlanImages: "planImg",
  AssetScopeImages: "assetFile",
  Dark_Field_White_BG: "darkFieldImage",
  IDEAL_White_BG: "idealWhiteImage",
  FlsImages: "flsImage",
  viewer3Image: "b2cRealImage",
};

const measurements = [
  { name: "Packet No", key: "vStnId" },
  { name: "Report No", key: "rptNo" },
  { name: "Lab", key: "lbNm" },
  { name: "Shape", key: "shpNm" },
  { name: "Carat", key: "crt" },
  { name: "Clarity", key: "clrNm" },
  { name: "Color", key: "colNm" },
  { name: "Cut", key: "cutNm" },
  { name: "Polish", key: "polNm" },
  { name: "Symmetry", key: "symNm" },
  { name: "Fluorescence", key: "fluNm" },
];

const ViewImage = (props) => {
  const [data, setData] = useState([]);
  const [images, setImages] = useState([]);
  const [mediaUrl, setMediaUrl] = useState(
    UtilService.getLocalStorageItem("mediaPathPattern") ?? {},
  );
  const path = split(props.location.pathname, "/");
  const id = path.pop();
  const imageType = path.pop();
  useEffect(() => {
    if (!isEmpty(mediaUrl)) return;

    getProjectSetting(PROJECT_SETTINGS_TYPE.MEDIA_PATH, (data) => {
      setMediaUrl(data?.MEDIA_PATH_PATTERN ?? {});
      UtilService.setLocalStorageItem(
        data?.MEDIA_PATH_PATTERN,
        "mediaPathPattern",
      );
    });
  }, [mediaUrl]);

  const getMediaUrl = React.useCallback(
    (type) => {
      const url = mediaUrl?.[type]?.FILE_PATH;
      return url?.PATH?.replaceAll("***", data?.[url?.KEY]);
    },
    [data, mediaUrl],
  );

  const setDiamondDetail = React.useCallback(() => {
    let objData = {
      method: VIEW_DIAMOND.method,
      url: `${VIEW_DIAMOND.url}/${id}`,
      showLoader: true,
    };
    UtilService.callApi(objData, async function (err, res) {
      if (err) throw err;
      const data = isArray(res?.data) ? res?.data[0] : res?.data;
      res?.code === "OK" && setData(data);
    });
  }, [id]);

  const setImageUrl = React.useCallback(() => {
    const img = [];
    if (imageType === "HeartImages" || imageType === "ArrowImages") {
      img.push(getMediaUrl(imageList?.["HeartImages"] ?? "img"));
      img.push(getMediaUrl(imageList?.["ArrowImages"] ?? "img"));
    } else {
      img.push(getMediaUrl(imageList?.[imageType] ?? "img"));
    }
    setImages(img);
  }, [getMediaUrl, imageType]);

  useEffect(() => {
    !isEmpty(data) && setImageUrl();
  }, [data, setImageUrl]);

  useEffect(() => {
    id !== "dna" && setDiamondDetail();
  }, [id, setDiamondDetail]);

  return (
    <div>
      <div
        className={`d-flex viewImageBlock ${
          !images[1] && "j-content-center centeBlock"
        }`}
      >
        <div className="viewImageBlockLeft">
          <div className="dnaWrapperBox">
            <div className="dnaImageTop">
              <span>Diamond Detail</span>
            </div>
            <div className="dnaDetailBlock">
              <div className=" d-flex flex-wrap align-items-normal">
                {measurements.map((m) => (
                  <div className="detail">
                    <span>{m.name} : </span>
                    <span>{data[m.key]}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {[
          "CertiImages",
          "viewer4Video",
          "viewer3Video",
          "TYPE_IIA",
          "RoughVideo",
        ].includes(imageType) ? (
          imageType === "CertiImages" ? (
            <iframe
              title=""
              width="100%"
              height="900px"
              src={getMediaUrl("certFile")}
            />
          ) : imageType === "viewer3Video" ? (
            <iframe
              title=""
              width="40%"
              height="600px"
              src={getMediaUrl("b2cJsonVideo")}
            />
          ) : imageType === "viewer4Video" ? (
            <iframe
              title=""
              width="40%"
              height="600px"
              src={getMediaUrl("videoFile")}
            />
          ) : imageType === "TYPE_IIA" ? (
            <iframe
              title=""
              width="100%"
              height="900px"
              src={getMediaUrl("type2")}
            />
          ) : (
            <iframe
              title=""
              width="40%"
              height="600px"
              src={getMediaUrl("roughVideo")}
            />
          )
        ) : (
          <>
            {images[0] && (
              <div
                className={`viewImageBlockRight ${
                  !images[1] && "halfBlock"
                } ${path}`}
              >
                <MagnifierImage image={images[0]} />
              </div>
            )}
            {images[1] && (
              <div className={`viewImageBlockRight ${path}`}>
                <MagnifierImage image={images[1]} />
              </div>
            )}
          </>
        )}
      </div>
      {(path === "HeartImages" || path === "ArrowImages") && (
        <div className="viewImageDetail">
          <span>Note : </span>
          <p>
            Heart & Arrow is a depiction of the diamond’s supreme symmetrical
            (due to its cut) features. It consists of eight hearts and eight
            arrows that can be seen in a diamond of superb cut quality, it's a
            ﬁrst in this segment. With this feature you don’t need to buy
            another system for taking H & A images of Diamond because it’s
            inbuilt feature in this system.
          </p>
        </div>
      )}
      {path === "AssetScopeImages" && (
        <div className="viewImageDetail">
          <span>Note : </span>
          <p>
            The ASET® Scope image works with the Angular Spectrum to display
            and you evaluate the optical performance of the diamonds. Only a
            diamond that reﬂects a lot of light back to you will be a diamond
            full of brilliance, ﬁre and scintillation.
          </p>
        </div>
      )}
      {path === "Office_Light_Black_BG" && (
        <div className="viewImageDetail">
          <span>Note : </span>
          <p>
            It is used to examine diamond’s “clarity characteristics” or
            ‘“inclusions” that either invisible in the exterior light, cannot be
            stained by standard methods, or are so distorted by staining that
            their characteristics then cannot be identiﬁed. This interior
            lighting arrangement makes it easier to see the stone’s interior &
            surface inclusions that can be very important for its
            characterisation of an inclusions.
          </p>
        </div>
      )}
      {path === "Dark_Field_White_BG" && (
        <div className="viewImageDetail">
          <span>Note : </span>
          <p>
            Face-up Image is the high-quality steady face-up photo of the
            diamond, which simulates to under ofﬁce light appearance of a
            diamond in which you can easily check diamond's overall cut
            symmetry, Inclusion location & light refraction in form of its
            brilliance.
          </p>
        </div>
      )}
      {path === "IDEAL_White_BG" && (
        <div className="viewImageDetail">
          <span>Note : </span>
          <p>
            IDEAL scope photo can tell you a lot about where the light is
            leaking in a diamond and how much light a diamond will actually
            reﬂect back to you. Only a diamond that reﬂects a lot of light back
            to you will be a diamond full of brilliance, ﬁre and scintillation.
          </p>
        </div>
      )}
    </div>
  );
};
export default ViewImage;
