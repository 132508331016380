/* eslint-disable */
import React, { Component } from "react";
import SortingFilterDropdown from "../../common/DiamondListing/SortingFilterDropdown";
import { sortingData } from "../../../constants/sorting";
import cloneDeep from "lodash/cloneDeep";
import Table from "../../DiamondList/TableBack";

import Heading from "../../common/Heading";
import _ from "lodash";
import UtilService from "../../../services/util";
import { useDrag, useDrop } from "react-dnd";
import OpenNotification from "../../common/CommonButton/OpenNotification";
import { MIME_TYPES, BASE_URL } from "../../../constants/Common";
import IntlMessages from "../../../util/IntlMessages";
import {
  GET_CHEQUE_DETAILS,
  CRETAE_CHEQUE_DETAILS,
  DELETE_CHEQUE_DETAILS,
  UPLOAD_FILE,
} from "../../../constants/apiConstant";
import UploadImage from "../../common/UploadImage/index";
import InputBlock from "../../common/InputBlock";
import moment from "moment";
import CommonModal from "../../common/CommonModal";
import Pagination from "../../common/Pagination";
import NoDataShow from "../../common/NoDataShow";
import Loader from "../../common/Loader";
const ColorMenuConst = (
  <SortingFilterDropdown
    OtherTable
    data={sortingData.colunmSetting}
    FilterOption={false}
  />
);
let gridHeight = 0;
export function setGridHeightFn() {
  let allContainers = {};
  let totalHeight = window.innerHeight - 95;
  allContainers.headerHeight =
    document.getElementsByClassName("headerTopMenu")[0];
  allContainers.pageHeadHeight =
    document.getElementsByClassName("accountTopBlock")[0];
  allContainers.pinHeight = document.getElementsByClassName("accountTabBox")[0];
  allContainers.actionBtnHeight =
    document.getElementsByClassName("userManageTop")[0];
  allContainers.diamondListingAction = document.getElementsByClassName(
    "diamondListingAction",
  )[0];
  allContainers.covidStrip = document.getElementsByClassName("covidStrip")[0];
  allContainers.smallHeaderWrapper =
    document.getElementsByClassName("smallHeaderWrapper")[0];
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }

    return true;
  });
  return totalHeight;
}
export function emptyData(loading, length) {
  if (loading) return <NoDataShow message={<Loader loading={true} fixed />} />;
  else if (length === 0) return <NoDataShow />;
  else return <></>;
}
export const OFFERSTATUS = {
  1: "Pending",
  2: "Accepted",
  3: "Rejected",
};

class ChequeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: false,
      gridHeight: 0,
      checked: false,
      settings: [],
      allChecked: null,
      visible: false,
      chequePath: "",
      remarks: "",
      page: 1,
      limit: 10,
      count: 0,
      deleteVisible: false,
      deleteId: "",
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchSettings();
  }

  fetchSettings = () => {
    let self = this;
    self.setState({ loading: true });
    let obj = {
      // url: "/admin/user-setting/paginate",
      // method: "post",
      ...GET_CHEQUE_DETAILS,
      request: {
        limit: self.state.limit,
        page: self.state.page,
        filter: {
          isDeleted: false,
          isActive: true,
        },
      },
    };

    UtilService.callApi(obj, function (err, data) {
      self.setState({ loading: false });
      if (err && err.data) {
      } else if (data && data.code === "OK") {
        if (data.data.list && data.data.list.length) {
          self.setState({ settings: data.data.list, count: data.data.count });
        }
      }
    });
  };

  uploadDocument = (ee, type) => {
    let { userKyc } = this.state;
    const e = cloneDeep(ee);
    let self = this;

    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = function (ev) {
      var arr = new Uint8Array(ev.target.result).subarray(0, 4);
      var header = "";
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (
        !MIME_TYPES[blob.type] ||
        (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))
      ) {
        return OpenNotification({
          type: "error",
          title:
            "File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.",
        });
      } else {
        const data = new FormData();
        data.append("folder", "user");
        data.append("file", e.target.files[0]);

        let objData = {
          ...UPLOAD_FILE,
          request: data,
        };
        UtilService.callApi(objData, function (err, data) {
          if (err) throw err;
          if (data && data.code === "OK") {
            if (type === "userKyc") {
              self.setState({ chequePath: data.data.files[0].absolutePath });
            }
          }
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };
  submitChequeDetails = () => {
    let self = this;
    if (!self.state.chequePath) {
      OpenNotification({ type: "error", title: "Please upload cheque." });
      return;
    }
    if (!self.state.remarks) {
      OpenNotification({ type: "error", title: "Please write remark" });
      return;
    }
    let objData = {
      ...CRETAE_CHEQUE_DETAILS,
      request: {
        filePaths: [self.state.chequePath],
        type: 1,
        remarks: self.state.remarks,
      },
    };
    UtilService.callApi(objData, function (err, data) {
      if (err) throw err;
      if (data && data.code === "CREATED") {
        OpenNotification({ type: "success", title: data.message });

        self.setState(
          {
            visible: false,
            chequePath: "",
            remarks: "",
          },
          () => {
            self.fetchSettings();
          },
        );
      }
    });
  };
  deleteChequeDetails = (id) => {
    let self = this;

    let objData = {
      ...DELETE_CHEQUE_DETAILS,
      request: {
        id: id,
      },
    };
    UtilService.callApi(objData, function (err, data) {
      if (err) throw err;
      if (data && data.code === "OK") {
        OpenNotification({ type: "success", title: data.message });
        let tempArray = self.state.settings.filter((a) => a.id !== id);

        self.setState({
          settings: tempArray,
          deleteVisible: false,
          deleteId: "",
        });
      } else {
        OpenNotification({ type: "error", title: data.message });
      }
    });
  };
  render() {
    let settings = this.state.settings;
    let { chequePath } = this.state;
    let self = this;
    gridHeight = setGridHeightFn();
    if (self.state.gridHeight !== gridHeight) self.setState({ gridHeight });
    const columns = [
      {
        Header: "Sr. No.",
        width: "50px",
        accessor: "srnu",
        totalDropdown: true,
        Cell: ({ row }) => <div>{row.index + 1}</div>,
      },
      {
        Header: "Date",
        width: "50px",
        accessor: "date",
        totalDropdown: true,
        Cell: ({ row }) => (
          <div>
            <span>{moment(row.original.createdAt).format("DD/MM/YYYY")}</span>
          </div>
        ),
      },
      {
        Header: "Image",
        width: "50px",
        accessor: "filePaths",
        totalDropdown: true,
        Cell: ({ row }) => (
          <div>
            <span>
              {row.original.filePaths && row.original.filePaths.length ? (
                <img
                  onClick={() => {
                    window.open(
                      BASE_URL + "/" + row.original.filePaths[0],
                      "_blank",
                    );
                  }}
                  style={{ width: "60px", height: "50px", cursor: "pointer" }}
                  src={BASE_URL + "/" + row.original.filePaths[0]}
                  alt=""
                />
              ) : (
                "No Image Found"
              )}
            </span>
          </div>
        ),
      },
      {
        Header: "Comment",
        accessor: "remarks",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => (
          <div>
            <div className="activeStatus pending">
              <span>{OFFERSTATUS[row.original.status]}</span>
            </div>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div
            onClick={() => {
              this.setState({ deleteVisible: true, deleteId: row.original.id });
              // this.deleteChequeDetails(row.original.id)
            }}
          >
            <span>
              <img src={require("../../../assets/svg/delete.svg")} alt="" />
            </span>
          </div>
        ),
      },
    ];

    const DND_ITEM_TYPE = "row";

    const Row = ({ row, index, moveRow }) => {
      const dropRef = React.useRef(null);
      const dragRef = React.useRef(null);

      const [, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        hover(item, monitor) {
          if (!dropRef.current) {
            return;
          }
          const dragIndex = item.index;
          const hoverIndex = index;
          // Don't replace items with themselves
          if (dragIndex === hoverIndex) {
            return;
          }
          const hoverBoundingRect = dropRef.current.getBoundingClientRect();
          const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
          const clientOffset = monitor.getClientOffset();
          const hoverClientY = clientOffset.y - hoverBoundingRect.top;

          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
          }
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
          }
          moveRow(dragIndex, hoverIndex);
          item.index = hoverIndex;
        },
      });

      const [{ isDragging }, drag, preview] = useDrag({
        item: { type: DND_ITEM_TYPE, index },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      });

      const opacity = isDragging ? 0 : 1;

      preview(drop(dropRef));
      drag(dragRef);

      return (
        <>
          {/* <div ref={dragRef} className="tableSortingTd">
            <img src={require("../../../assets/svg/DiamondList/sorting-light.svg")} />
          </div> */}
          <tr ref={dropRef} style={{ opacity }}>
            {row.cells.map((cell) => {
              return (
                <td width="100px" {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </td>
              );
            })}
          </tr>
        </>
      );
    };

    const isMobile = window.innerWidth <= 767;
    return (
      <div className="configurationInnerSubTb">
        <div className="searchResultListWrapper">
          <div className="editProfilePage">
            {isMobile && (
              <div className="d-flex justify-content-between align-items-center userManageTop">
                <Heading
                  title={<IntlMessages id="app.ManageDiamondSequence" />}
                  className="mainHeadingTitle popupInnerTitle m-0 p-10"
                />
              </div>
            )}
            <div className="searchInnerResult">
              <div
                className="searchResultTable columnSettingPage"
                style={{ height: gridHeight }}
              >
                <Table
                  cheque
                  nocheck
                  nostatus
                  noCheckBox
                  columns={columns}
                  data={settings}
                />
                {emptyData(this.state.loading, this.state.settings.length)}
              </div>
            </div>
            <div className="botoomStickyBar diamondListingAction justify-content-center">
              <button
                style={{ color: "white" }}
                className="bottomStickyButton"
                onClick={() => {
                  this.setState({ visible: true });
                }}
              >
                Add Cheque Details
              </button>
              <Pagination
                noLimitChange
                data={self.state.settings}
                onPaginationChange={(page, limit) => {
                  this.setState({ page, limit }, () => {
                    this.fetchSettings();
                  });
                }}
                {...this.state}
              />
            </div>
            {isMobile && (
              <div className="sideBarPopupButton">
                <button
                  className="commonButton"
                  onClick={() => {
                    this.setState({ visible: true });
                  }}
                >
                  Add Cheque Details
                </button>
              </div>
            )}
          </div>
        </div>
        {this.state.visible && (
          <CommonModal
            modalSize="ListingModal"
            title="Upload Cheque Details"
            handleCancel={() => {
              this.setState({ visible: false });
            }}
            handleOk={this.submitChequeDetails}
            footerShow
            cancelTitle="Cancel"
            submitTitle="Submit"
            visible={true}
           
          >
            <div className="d-flex j-content-center updateProfileItem mb-20 mt-20">
              <UploadImage
                right
                file={chequePath}
                // disabled={isEmpty(this.state.doc)}
                // leftAlign
                title="Upload Cheque"
                onUpload={(e) => this.uploadDocument(e, "userKyc")}
              />
              <IntlMessages id="app.remark">
                {(placeholder) => (
                  <InputBlock
                    value={this.state.remarks}
                    onChange={(e) => this.setState({ remarks: e.target.value })}
                    label={placeholder}
                    placeholder={placeholder}
                  />
                )}
              </IntlMessages>
            </div>
          </CommonModal>
        )}
        {this.state.deleteVisible && (
          <CommonModal
            modalSize="ListingModal"
            // title="Upload Cheque Details"
            handleCancel={() => {
              this.setState({ deleteVisible: false, deleteId: "" });
            }}
            handleOk={() => this.deleteChequeDetails(this.state.deleteId)}
            footerShow
            cancelTitle="Cancel"
            submitTitle="Yes"
            visible={true}
           
          >
            <div
              style={{ marginBottom: "15px" }}
              className="d-flex j-content-center updateProfileItem"
            >
              Are you sure you want to delete?
            </div>
          </CommonModal>
        )}
      </div>
    );
  }
}

export default ChequeDetail;
