/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { isSafari } from 'react-device-detect';
import Slider from 'react-slick';

import { DASHBOARD_MEDIA_URL } from '../../../constants/Common';

const LoginBanner = (props) => {
    const [banner, setBanner] = useState(undefined);

    useEffect(() => {
        setBanner(props.bannerData);
    }, [props.bannerData]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        arrowsBlock: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplaySpeed: 5000,
        autoplay: true,
    };

    const img = banner && banner.images;

    return (
        <Slider {...settings}>
            {img &&
                img.map((f, index) => {
                    let url = '';
                    let ext = typeof f === 'string' ? f?.split('.').pop().toLowerCase() : f?.url?.split('.').pop().toLowerCase();

                    if (typeof f === 'object') {
                        url = DASHBOARD_MEDIA_URL + f?.url;

                        if (!f?.url.startsWith('/')) {
                            url = DASHBOARD_MEDIA_URL + '/' + f?.url;
                        }
                    } else {
                        url = DASHBOARD_MEDIA_URL + f;

                        if (!f.startsWith('/')) {
                            url = DASHBOARD_MEDIA_URL + '/' + f;
                        }
                    }
                    return (
                        <React.Fragment key={index}>
                            {['mp4', 'mkv', 'wmv'].includes(ext) ? (
                                <video autoPlay={!isSafari} loop muted preload="none" >
                                    <source src={url}></source>
                                </video>
                            ) : (
                                <img src={url} alt="banner" loading="lazy" />
                            )}
                        </React.Fragment>
                    );
                })}
        </Slider>
    );
};

export default LoginBanner;
