/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import UtilService from "../services/util";
import find from "lodash/find";
import NoDataShow from "./common/NoDataShow";
import Heading from "./common/Heading";
import MagnifierImage from "./DiamondDetail/MagnifierImage";
import { handleAlter, LISTINGPAGES } from "./DiamondList/DiamondListFunctions";
import { LOCAL_STORAGE_VAR, FILE_URLS, DIAMOND_BID } from "../constants/Common";
import { DIAMOND_DATA_BY_ID } from "../constants/apiConstant";
import { floatkeys, roundkeys } from "./DiamondList/TableBack";
import DiamondListingAction from "./common/DiamondListing/DiamondListingAction";
import { getGridHeight } from "./DiamondList";
import { formatNumber } from "./common/DiamondListing/SelectStone";
import CheckBox from "./common/CheckBox";
import { getBidType } from "./NewArrivalBidIt";
import IntlMessages from "../util/IntlMessages";

const currentType = "DiamondCompare";

class DiamondCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      checked: [],
      inTrackDiamonds: [],
      mediaUrl: UtilService.getLocalStorageItem("mediaPathPattern"),
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.commonData.strip !== prevProps.commonData.strip)
      this.forceUpdate();
  }

  fetch = () => {
    let self = this;
    let ids = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
      ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
      : [];
    let confirmCompare = localStorage.getItem(
      `${LOCAL_STORAGE_VAR}-confirm-compare`,
    )
      ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`))
      : false;
    if (ids.length === 0 || ids.length === 1) {
      this.setState({ data: [] });
      return;
    }
    let obj = DIAMOND_DATA_BY_ID;

    let order = confirmCompare ? { orderDiamond: true } : {};
    obj.request = {
      id: ids,
      ...order,
      isReturnTotal: true,
      isReturnIntrack: true,
    };
    this.setState({ loading: true });
    UtilService.callApi(obj, function (err, data) {
      let rows = [];
      let inTrackDiamonds = [];
      if (data && data.code === "OK") {
        rows = data.data.list;
        inTrackDiamonds = data.data.inTrackDiamonds;
      }
      self.setState({ data: rows, inTrackDiamonds, loading: false });
    });
  };

  getMap = (name, key, extra = "") => {
    return (
      <tr>
        <td>{name}</td>
        {this.state.data.map((x, i) => {
          return (
            <td key={i}>
              {(!x[key] ||
                (x.prcStg === "UP" && key === "back") ||
                (x.prcStg === "UP" && key === "ctPr") ||
                (x.prcStg === "UP" && key === "amt")
                ? "-"
                : floatkeys.includes(key)
                ? formatNumber(parseFloat(x[key]).toFixed(2))
                : roundkeys.includes(key)
                ? formatNumber(Math.round(x[key]))
                : x[key]) + extra}
            </td>
          );
        })}
      </tr>
    );
  };

  setLoading = () => {
    this.setState({ loading: this.state.loading });
  };

  removeCompare = (id) => {
    let ids = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
      ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
      : [];
    ids = ids.filter((el) => el !== id);
    if (ids.length === 1) ids = [];
    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-compare-diamond`,
      JSON.stringify(ids),
    );
    this.setState({
      data:
        this.state.data.length === 2
          ? []
          : this.state.data.filter((el) => el.id !== id),
    });
  };
  getMediaUrl = (type, data) => {
    const url = this.state.mediaUrl?.[type]?.FILE_PATH;

    return url?.PATH?.replaceAll("***", data?.[url?.KEY]).replaceAll(
      "s=100&v=2&sv=0,1,2,3,4&z=1&btn=1,2,3,5&s=100",
      "btn=0",
    );
  };
  render() {
    let gridHeight = getGridHeight(
      ["HeaderSticky", "diamondListingAction", "comparPageHead"],
      25,
    );
    let fsNow = localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)
      ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)).split(
          "/",
        )[1]
      : "";

    return (
      <>
        <div className="compareMainPage">
          <div className="d-flex justify-content-between align-items-center mb-10 comparPageHead">
            <Heading
              title={<IntlMessages id="app.CompareStone" />}
              count={this.state.data.length}
              className="mb-0"
            />
          </div>
          {!this.state.loading && !this.state.data.length ? (
            <NoDataShow />
          ) : null}
          {!this.state.loading && this.state.data.length ? (
            <div
              className="compareTable"
              style={{ height: gridHeight, overflow: "auto" }}
            >
              <table>
                <tr>
                  <td className="compareHead">Diamond Video</td>
                  {this.state.data.map((x, i) => {
                    return (
                      <td key={i} className="compareHead compareImage">
                        {x.videoFile && (
                          // <MagnifierImage
                          //   image={FILE_URLS.img.replace("***", x.vStnId)}
                          //   className="diamondCompareImg"
                          // />
                          <div className="imageSliderFullBlock justify-center">
                            <iframe
                              style={
                                {
                                  // width: "90%",
                                  // height: "100%",
                                  // minHeight: "330px",
                                  // minHeight: "200px",
                                }
                              }
                              src={this.getMediaUrl("videoFile", x)}
                              scrolling="no"
                            />
                          </div>
                        )}
                        <div
                          className="closeImage"
                          onClick={() => this.removeCompare(x.id)}
                        >
                          <img
                            src={require("../assets/svg/Compare/close-red.svg")}
                            alt=""
                          />
                        </div>
                        <div className="checkImageBox">
                          <CheckBox
                            checked={find(this.state.checked, {
                              stoneId: x.stoneId,
                            })}
                            onChange={() => {
                              let checked = handleAlter(
                                this.state.checked,
                                x,
                                "stoneId",
                              );
                              this.setState({ checked });
                            }}
                          />
                        </div>
                      </td>
                    );
                  })}
                </tr>
                {this.getMap("Stone No", "vStnId")}
                {this.getMap("Lab", "lbNm")}
                {this.getMap("Shape", "shpNm")}
                {this.getMap("Carat", "crt")}
                {this.getMap("Color", "colNm")}
                {this.getMap("Clarity", "clrNm")}
                {this.getMap("Shade", "shdNm")}
                {this.getMap("Rap ($)", "rap")}
                {this.getMap("Discount%", "back")}
                {this.getMap("Price/Carat ($)", "ctPr")}
                {this.getMap("Amount ($)", "amt")}
                {this.getMap("Certificate No", "rptNo")}
                {this.getMap("Fluorescence", "fluNm")}
                {this.getMap("Cut", "cutNm")}
                {this.getMap("Polish", "polNm")}
                {this.getMap("Symmetry", "symNm")}
                {this.getMap("Eye Clean", "eClnNm")}
                {this.getMap("Depth %", "depPer")}
                {this.getMap("Table %", "tblPer")}
                {this.getMap("Length", "length")}
                {this.getMap("Width", "width")}
                {this.getMap("Depth", "height")}
                {this.getMap("Ratio", "ratio")}
                {this.getMap("Crown Angle", "cAng")}
                {this.getMap("Crown Height", "cHgt")}
                {this.getMap("Pav Angle", "pAng")}
                {this.getMap("Pav Height", "pHgt")}
                {this.getMap("Girdle", "girdleStr")}
                {this.getMap("Culet", "cultNm")}
                {this.getMap("H&A", "hANm")}
              </table>
            </div>
          ) : null}
        </div>
        <DiamondListingAction
          {...this.state}
          {...this.props}
          checkedData={this.state.checked}
          nocompare
          nopaginate
          currentType={currentType}
          clearAll={() => this.setState({ checked: [] })}
          fetch={this.fetch}
          // setLoading={this.setLoading}
          setLoading={(val) => this.setState({ ...this.state, loading: val })}
          handleLoader={this.setLoading}
          norelease
          noconfirm={
            fsNow === LISTINGPAGES.CONFIRM ||
            fsNow === LISTINGPAGES.BID ||
            fsNow === LISTINGPAGES.MYBID ||
            fsNow === LISTINGPAGES.UPCOMING
          }
          fsNow={fsNow}
          nocart={
            fsNow === LISTINGPAGES.CONFIRM ||
            fsNow === LISTINGPAGES.CART ||
            fsNow === LISTINGPAGES.BID ||
            fsNow === LISTINGPAGES.MYBID ||
            fsNow === LISTINGPAGES.UPCOMING
          }
          noquote
          nooffice
          nowatch={
            fsNow === LISTINGPAGES.CONFIRM ||
            fsNow === LISTINGPAGES.WATCHLIST ||
            fsNow === LISTINGPAGES.BID ||
            fsNow === LISTINGPAGES.MYBID ||
            fsNow === LISTINGPAGES.UPCOMING
          }
          noreminder={
            fsNow === LISTINGPAGES.CONFIRM ||
            fsNow === LISTINGPAGES.BID ||
            fsNow === LISTINGPAGES.MYBID ||
            fsNow === LISTINGPAGES.UPCOMING
          }
          nonote={
            fsNow === LISTINGPAGES.CONFIRM ||
            fsNow === LISTINGPAGES.BID ||
            fsNow === LISTINGPAGES.MYBID ||
            fsNow === LISTINGPAGES.UPCOMING
          }
          nofinalcalc={fsNow === LISTINGPAGES.UPCOMING}
        />
      </>
    );
  }
}

export default connect((props) => {
  return props;
}, {})(DiamondCompare);
