/* eslint-disable */
import React, { useState, useEffect } from "react";
import "../TermsOfUse/staticContent.less";
import { connect } from "react-redux";
import StaticHeader from "../StaticHeader";
import { withRouter } from "react-router-dom";
import Heading from "../common/Heading";
import CommonModal from "../common/CommonModal";

let gridHeight = 0;
export function setGridHeightFn() {
  let allContainers = {};
  let totalHeight = window.innerHeight - 25;
  allContainers.headerHeight =
    document.getElementsByClassName("headerTopMenu")[0];
  allContainers.covidStrip = document.getElementsByClassName("covidStrip")[0];
  allContainers.HeaderStripBlock =
    document.getElementsByClassName("HeaderStripBlock ")[0];
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}
const BiddingProcedure = () => {
  const [video, setVideo] = useState(false);
  const [play, setPlay] = useState(true);

  const handleVideoSetting = (status) => {
    let vid = document.getElementById("vid");
    if (!status) vid.pause();
    else vid.play();
    setPlay(status);
  };

  useEffect(() => {
    let vid = document.getElementById("vid");
    if (vid) vid.currentTime = 0;
  }, [video]);

  gridHeight = setGridHeightFn();

  return (
    <>
      <StaticHeader />
      <div
        className="staticBoxMain"
        style={{ minHeight: gridHeight, overflow: "auto" }}
      >
        <div className="statiContentBox">
          <Heading
            title="Bidding Procedure"
            className="staticMainTitle text-center mb-50"
          />
          <div className="staticContentWrapper">
            {/* <Heading title="Terms of Service" className="popupInnerTitle"/> */}
            <div className="staticList">
              <ul>
                <li>
                  We at Belgium Jewellery & Diamond Pvt. Ltd. are excited to
                  introduce a new feature 'JUST BID IT' which commenced on 2nd
                  April 2018, 3 PM IST.
                </li>
                <li>
                  All the details of the bidding procedure are given in the
                  Video & Disclaimer mentioned below. We request you to check
                  for the same.
                </li>
                <li>
                  Please feel free to get in touch with any of our respective
                  sales representative for the smallest of doubt you have, we
                  would be happy to help you!
                </li>
                <li>
                  Please Click <a onClick={() => setVideo(true)}>Here</a> For
                  Bidding Procedure video.
                </li>
                {/* <li>
                  Please Click <a href="#disclaimer">Here</a> For Terms &
                  Conditions.
                </li> */}
                <li>
                  {/* <a href="Fn_Terms.pdf" download> */}
                  <a href="" download>
                    Download
                  </a>
                  &nbsp;Terms & Conditions.
                </li>
              </ul>
            </div>
          </div>
          <Heading
            className="popupInnerTitle mt-20"
            title="Bidding & result timing for all 4.00 Carat & above stones (Blind Bid, Multiple Bid and Look & Bid)"
          />
          <ol type="a">
            <li>
              All stones 4.00 carat and above will get uploaded in Blind Bidding
              in New Arrival on Monday at 03:00 PM IST and end on Wednesday
              09:55 AM IST.
            </li>
            <li>
              If there is only a single bid on any stone(s) then the result for
              that particular stone will be declared on Wednesday at 10.00 AM
              IST and the bidder will get the stone at the price that he has
              bid.
            </li>
            <li>
              If there are more than 1 bidder on any stone(s), bidding will be
              extended to the next stage of multiple bidding. All clients will
              be notified about their current ranking through an email on
              Wednesday 10.00am IST
            </li>
            <li>
              The Multiple Bidding will start from Wednesday 10.00am IST and
              ends on Wednesday 10.55am IST and result will be declared at
              11.00am IST same day.
            </li>
            <li>
              Clients in multiple bidding section will be able to edit their
              bids from Wednesday 10.00am IST to 10.55am IST.
            </li>
            <li>
              Clients will not get the option to delete the bid once they have
              entered in the Multiple Bidding section.
            </li>
            <li>
              Clients last bid price in Blind bidding section (Which ends on
              Wednesday at 9.55 AM IST) will be considered as their opening bid
              in the Multiple bidding stage. In multiple bidding client can edit
              their bid multiple times but cannot bid lower than their opening
              bid.
            </li>
            <li>
              The result for multiple bidding will be declared on Wednesday at
              11.00 AM IST.
            </li>
            <li>
              The first bidder winner of the multiple bidding will get the
              stone(s) at the price of the second highest bidder.
            </li>
            <li>
              Look & Bid will start on Wednesday at 10:00 AM IST and End same
              day at 02:55 PM IST
            </li>
            <li>
              The results for Look & Bid will be declared on Wednesday at 03:00
              PM IST
            </li>
            <li>
              For <b>Blind Bid</b> we provide less 2% and additional 0.5%
              discount on the amount value and for <b>Look & Bid</b> we give
              less 2% discount on the amount value.
            </li>
          </ol>
          <Heading
            className="popupInnerTitle mt-20"
            title="Bidding & result timings for all stones below 4.00 Carat."
          />
          <div>
            <ol type="a">
              <li>
                Blind Bidding will start every day at 03:00 PM IST (Except
                Sundays) and end next day at 9:55 AM IST.
              </li>
              <li>
                Look & Bid will start every day at 10:00 AM IST and End same day
                at 02:55 PM IST (Except Sundays).
              </li>
              <li>
                The results for blind bidding will be declared daily at 10:00 AM
                IST. (Except Sundays).
              </li>
              <li>
                The results for Look & Bid will be declared daily at 03:00 PM
                IST (Except Sundays).
              </li>
              <li>
                For <b>Blind Bid</b> we provide less 2% and additional 0.5%
                discount on the amount value and for <b>Look & Bid</b> we give
                less 2% discount on the amount value.
              </li>
            </ol>
          </div>
          <Heading className="popupInnerTitle mt-20" title="Disclaimer:" />
          <p>
            Bidders will be able to bid at the Base Price or Higher Price. No
            bids will be accepted below the Base Price.
          </p>
          <p>
            Daily at 03:00 PM IST inventory under “New Arrivals” will be
            replaced with the new inventory and bidding process will start again
            (Except Sundays).
          </p>
          <p>
            Belgium Jewellery & Diamonds Pvt. Ltd. will notify the successful
            bidder via an e-mail and the respective sales person will contact
            you.
          </p>
          <p>
            Stone(s) will be available for bidding to multiple clients and will
            not be on Hold for any single client. If stone(s) have multiple
            bid(s) at the same price then the earliest/quickest bid will be
            considered.
          </p>
          <p>
            The identity of the Bidder and the amount of bid will remain
            confidential with Belgium Jewellery & Diamonds Pvt. Ltd.
          </p>
          <p>All the bids will be in US Dollar price per carat.</p>
          <p>
            Highest bid will be considered as Sale and bidder will have to make
            the payment as per the bidding terms and they will not have an
            option to deny the purchase.
          </p>
          <p>
            Belgium Jewellery & Diamonds Pvt. Ltd. holds the right to
            change/modify or eliminate any bid(s)/rule(s) at its own discretion.
          </p>
          <p>
            For any further queries/clarifications please contact your
            respective sales person.
          </p>
        </div>
      </div>

      <CommonModal
        modalSize="fixSize popupBanner"
        visible={video}
        handleCancel={() => setVideo(false)}
      >
        <div className="bannerpopupImg">
          <video
            id="vid"
            height="400px"
            width="100%"
            onClick={() => handleVideoSetting(!play)}
            autoPlay
            muted
            loop
          >
            <source src={`bidding_procedure.mp4`}></source>
          </video>
        </div>
      </CommonModal>
    </>
  );
};

export default connect((props) => {
  return props;
}, {})(withRouter(BiddingProcedure));
