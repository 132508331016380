/* eslint-disable */
import React, { Component } from "react";
import { Drawer } from "antd";
import AddressForm from "./AddressForm";
import ManageAddressList from "./AddressList";

class ManageAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  showDrawer1 = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <>
        <ManageAddressList onClick={this.showDrawer1} />
        <Drawer
          title={false}
          onClose={this.onClose}
          visible={this.state.visible}
          className="diamondListinSidebar"
        >
          <AddressForm />
        </Drawer>
      </>
    );
  }
}

export default ManageAddress;
