/* eslint-disable */
import React, { Component } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import OpenNotification from "../../components/common/CommonButton/OpenNotification";
import clone from "lodash/clone";
import has from "lodash/has";
import trim from "lodash/trim";
import isEmpty from "lodash/isEmpty";
import { Modal, notification } from "antd";
import UtilService from "../../services/util";
import cloneDeep from "lodash/cloneDeep";
import BrandLogo from "../common/BrandLogo";
import DownloadBlock from "../common/DownloadBlock";

import axios from "axios";
import InputBlock from "../common/InputBlock";
import {
  LOCAL_STORAGE_VAR,
  MIME_TYPES,
  PROJECT_SETTINGS_TYPE,
} from "../../constants/Common";
import { setLoader } from "../../appRedux/actions/Common";
import {
  LOGIN_USER,
  RESEND_VERIFY_MAIL,
  UPDATE_USER_TOKEN,
  UPLOAD_FILE,
  ADMIN_UPDATE_USER,
} from "../../constants/apiConstant";
import CheckBox from "../common/CheckBox";
import Logo from "../Header/Logo";
import UploadImage from "../common/UploadImage/index";
import SelectOption from "../common/SelectOption";
import { getProjectSettingList } from "components/DiamondList/DiamondListFunctions";
import { isNotEmpty } from "util/utils";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        username: "",
        password: "",
      },
      rememberMe: false,
      checked: true,
      isLoading: false,
      rememberCheckVal: false,
      loading: false,
      error: null,
      verifyEmail: false,
      showModal: false,
      QuickRegistershowModal: false,
      errors: {},
      userData: {},
      visible: false,
      Kyc: {
        imageOne: "",
        typeOne: "",
        imageTwo: "",
        typeTwo: "",
      },
      personalDocs: [],
      businessDocs: [],
    };
  }

  componentDidMount() {
    let search = window?.location?.search?.includes("?username=");
    if (search) {
      search = window?.location?.search?.split("?username=");
      let value1 = search[1]?.split("&password=");
      let value2 = value1[1]?.split("&source=");
      if (value2[1] === "oldwebsite") {
        let formData = {
          username: value1[0],
          password: value2[0],
        };
        this.setState({ formData });
        UtilService.setLocalStorageItem(
          {
            username: this.state.formData.username,
            password: this.state.formData.password,
          },
          `${LOCAL_STORAGE_VAR}-api-user`,
        );
      }
      this.props.history.push("/signin");
    } else {
      let rememberMe = JSON.parse(
        localStorage.getItem(`${LOCAL_STORAGE_VAR}-rememberMe`)
          ? localStorage.getItem(`${LOCAL_STORAGE_VAR}-rememberMe`)
          : localStorage.getItem(`${LOCAL_STORAGE_VAR}-api-user`),
      );
      localStorage.getItem(`${LOCAL_STORAGE_VAR}-api-user`) &&
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-api-user`);

      if (rememberMe) {
        let prevUser = localStorage.getItem(
          `${LOCAL_STORAGE_VAR}-remember-user`,
        );
        if (prevUser && prevUser.length && prevUser !== "undefined") {
          if (prevUser.indexOf("username") > 0) {
            localStorage.clear();
          } else {
            let user = UtilService.getLocalStorageItem(
              `${LOCAL_STORAGE_VAR}-remember-user`,
            );
            let formData = {
              username: user.username,
              password: user.password,
            };
            this.setState({ formData, rememberMe });
          }
        }
      }

      // let user = UtilService.getLocalStorageItem(
      //   `${LOCAL_STORAGE_VAR}-remember-user`
      // );
      // let formData = {
      //   username: user.username,
      //   password: user.password,
      // };
      // this.setState({ formData, rememberMe });
    }
  }

  getSetting = (data, settingType) =>
    data?.find(({ type }) => type === settingType);

  setQuoteSettings = (data) => {
    const quoteSetting = this.getSetting(
      data,
      PROJECT_SETTINGS_TYPE.QUOTE_VALIDATION,
    );
    UtilService.setLocalStorageItem(
      quoteSetting?.data?.notAllowedSSts,
      `${LOCAL_STORAGE_VAR}-quote-not-allow-status`,
    );
    UtilService.setLocalStorageItem(
      quoteSetting?.data?.notAllowedSameBlock,
      `${LOCAL_STORAGE_VAR}-quote-not-allow-same-block`,
    );
  };

  setPopupSetting = (data) =>
    UtilService.setLocalStorageItem(
      this.getSetting(data, PROJECT_SETTINGS_TYPE.POP_UP_VALIDATION_MESSAGES)
        ?.data,
      "popUpValidationMsg",
    );

  setBidPopupSetting = (data) =>
    UtilService.setLocalStorageItem(
      this.getSetting(data, PROJECT_SETTINGS_TYPE.BID_TIME_END_VALIDATION)
        ?.data,
      "bidPopUpValidationMsg",
    );

  setBlockValidData = (data) =>
    UtilService.setLocalStorageItem(
      this.getSetting(data, PROJECT_SETTINGS_TYPE.BLOCK_VALIDATION)?.data,
      "block-setting",
    );

  setMediaSetting = (data) =>
    UtilService.setLocalStorageItem(
      this.getSetting(data, PROJECT_SETTINGS_TYPE.MEDIA_PATH)?.data
        ?.MEDIA_PATH_PATTERN,
      "mediaPathPattern",
    );

  setCartSetting = (data) =>
    UtilService.setLocalStorageItem(
      this.getSetting(data, PROJECT_SETTINGS_TYPE.CART_ALLOW_STATUS)?.data
        ?.CART_ALLOW_STATUS,
      "cartAllowStatus",
    );

  setWatchListSetting = (data) =>
    UtilService.setLocalStorageItem(
      this.getSetting(data, PROJECT_SETTINGS_TYPE.WATCH_LIST_ALLOW_STATUS)?.data
        ?.WATCH_LIST_ALLOW_STATUS,
      "watchlistAllowStatus",
    );

  setNoteSetting = (data) =>
    UtilService.setLocalStorageItem(
      this.getSetting(data, PROJECT_SETTINGS_TYPE.NOTE_ALLOW_STATUS)?.data
        ?.NOTE_ALLOW_STATUS,
      "noteAllowStatus",
    );

  setProjectSettings = (data) => {
    this.setQuoteSettings(data);
    this.setPopupSetting(data);
    this.setMediaSetting(data);
    this.setCartSetting(data);
    this.setWatchListSetting(data);
    this.setNoteSetting(data);
    this.setBidPopupSetting(data);
    this.setBlockValidData(data);
  };

  handleSubmit = () => {
    let reqObj = clone(this.state.formData);
    if (this.handleValidation()) {
      // self.props.setLoader(true);
      let obj = {
        ...LOGIN_USER,
        request: {
          ...reqObj,
          masters: [
            "COLOR",
            "CLARITY",
            "SHAPE",
            "CUT",
            "FLUORESCENCE",
            "SHADE",
            "LAB",
            "POLISH",
            "SYMMETRY",
            "LOCATION",
            "BLACK_INCLUSION",
            "OPEN_INCLUSION_TABLE",
            "MILKEY",
            "WHITE_INCLUSION_CROWN",
            "OPEN_INCLUSION_CROWN",
            "EYECLEAN",
            "OPEN_INCLUSION_PAVILION",
            "ORIGIN",
            "BLACK_INCLUSION_CROWN",
            "H_AND_A",
            "WHITE_INCLUSION_TABLE",
            "FANCY_COLOR",
            "INTENSITY",
            "OVERTONE",
            "KEY_TO_SYMBOLS",
            "GIRDLE",
            "CULET",
            "GIRDLE_COND",
            "CULET_COND",
            "COMPANY_SIZE",
            "BLACK_INCLUSION_TABLE",
            "BLACK_INCLUSION_SIDE",
            "WHITE_INCLUSION_SIDE",
            "BRILLIANCY",
            "EYECLEAN",
            "BUSINESS_TYPE",
            "NATURE_OF_ORG",
            "DOC_TYPE_PERSONAL",
            "DOC_TYPE_BUSINESS",
          ],
        },
      };

      this.setState({ isLoading: true });

      UtilService.callApi(obj, (err, data) => {
        this.setState({ isLoading: false });

        // self.props.setLoader(false);
        // if (res === "EMAIL_NOT_VERIFIED") {
        //   this.setState({ verifyEmail: true });
        // }
        if (err)
          return OpenNotification({
            type: "error",
            title:
              err.data.message || err.data.data.message || "Failed to Login.",
          });

        if (data && data.code === "OK") {
          const permission =
            data?.data?.userPermissions?.data?.filter(isNotEmpty);

          if (isEmpty(permission))
            return OpenNotification({
              type: "error",
              title: `You don't have permission to access panel.`,
            });

          const value = [
            PROJECT_SETTINGS_TYPE.QUOTE_VALIDATION,
            PROJECT_SETTINGS_TYPE.MEDIA_PATH,
            PROJECT_SETTINGS_TYPE.POP_UP_VALIDATION_MESSAGES,
            PROJECT_SETTINGS_TYPE.CART_ALLOW_STATUS,
            PROJECT_SETTINGS_TYPE.WATCH_LIST_ALLOW_STATUS,
            PROJECT_SETTINGS_TYPE.NOTE_ALLOW_STATUS,
            PROJECT_SETTINGS_TYPE.BLOCK_VALIDATION,
            PROJECT_SETTINGS_TYPE.BID_TIME_END_VALIDATION,
          ];

          getProjectSettingList(value, (data) => {
            this.setProjectSettings(data?.data);
          });

          this.setState({ userData: data }, () => {
            if (data.data.user.kycRequired) {
              if (
                this.state.userData.data.user.account &&
                isEmpty(this.state.userData.data.user.account.kyc)
              ) {
                const { DOC_TYPE_PERSONAL, DOC_TYPE_BUSINESS } =
                  data.data.masters;

                this.setState({
                  personalDocs: DOC_TYPE_PERSONAL?.map(({ id, name }) => ({
                    id: id[0],
                    name,
                  })),
                  businessDocs: DOC_TYPE_BUSINESS?.map(({ id, name }) => ({
                    id: id[0],
                    name,
                  })),
                  visible: true,
                });
              } else {
                this.getRedirection(data);
              }
            } else {
              this.getRedirection(data);
            }
          });
        }
      });
    }
  };

  handleOnChange = (key, value) => {
    let { formData } = this.state;
    formData[key] = value;
    this.setState(formData);
  };

  handleVerifyEmail = () => {
    let self = this;
    let api = "/admin/user/resend-verification-email";
    let obj = {
      username: this.state.username,
    };
    let objData = {
      // method: "post",
      // url: api,
      ...RESEND_VERIFY_MAIL,
      request: obj,
    };

    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === "OK") {
        self.setState({ verifyEmail: false });
      }
    });
  };

  handleUpdateToken = () => {
    let self = this;
    let api = "/auth/update-token";
    let objData = {
      // method: "post",
      // url: api,
      ...UPDATE_USER_TOKEN,
      request: {
        masters: [
          "COLOR",
          "CLARITY",
          "SHAPE",
          "CUT",
          "FLUORESCENCE",
          "SHADE",
          "LAB",
          "POLISH",
          "SYMMETRY",
          "LOCATION",
          "BLACK_INCLUSION",
          "OPEN_TABLE",
          "MILKEY",
          "WHITE_INCLUSION_CROWN",
          "OPEN_CROWN",
          "EYECLEAN",
          "OPEN_PAVILION",
          "ORIGIN",
          "BLACK_INCLUSION_CROWN",
          "H_AND_A",
          "WHITE_INCLUSION_TABLE",
          "FANCY_COLOR",
          "INTENSITY",
          "OVERTONE",
          "KEY_TO_SYMBOLS",
          "GIRDLE",
          "CULET",
          "GIRDLE_COND",
          "CULET_COND",
          "COMPANY_SIZE",
        ],
      },
      updateToken: self.state.updateToken, //token
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === "OK") {
        if (
          !has(data.data.user, ["isTermsCondAgree"]) ||
          !data.data.user.isTermsCondAgree
        ) {
          self.setState({ showTCModal: true });
        } else {
          self.getRedirection(data);
        }
      }
    });
  };

  getRedirection = (data) => {
    const user = data.data.user;

    const masters = data.data.masters;
    const columns = data.data.columns;
    const userPermissions = data.data.userPermissions.data;
    const keywords = data.data.keywords;
    const token = data.data.token.jwt;
    const terms = data.data.user.accountTerm;

    UtilService.setLocalStorageItem(token, `${LOCAL_STORAGE_VAR}-token`);
    UtilService.setLocalStorageItem(user, `${LOCAL_STORAGE_VAR}-user`);

    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-master`,
      JSON.stringify(masters),
    );
    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-column`,
      JSON.stringify(columns),
    );
    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-keywords`,
      JSON.stringify(keywords),
    );
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-terms`, JSON.stringify(terms));
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-isTrusted`, data.data.isTrusted);

    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-rememberMe`,
      this.state.rememberMe,
    );
    if (this.state.rememberMe) {
      UtilService.setLocalStorageItem(
        {
          username: this.state.formData.username,
          password: this.state.formData.password,
        },
        `${LOCAL_STORAGE_VAR}-remember-user`,
      );

      // localStorage.setItem(
      //   `${LOCAL_STORAGE_VAR}-remember-user`,
      //   JSON.stringify({
      //     username: this.state.formData.username,
      //     password: window.btoa(this.state.formData.password),
      //   })
      // );
    } else {
      localStorage.removeItem(`${LOCAL_STORAGE_VAR}-remember-user`);
    }

    let setPermit = {};
    void userPermissions?.filter(isNotEmpty)?.map((x) => {
      setPermit[x.module] = x.permissions;
    });

    UtilService.setLocalStorageItem(
      setPermit,
      `${LOCAL_STORAGE_VAR}-userPermissions`,
    );

    axios.defaults.headers.common["access-token"] = "JWT " + token;
    if (this.props.lastLocation) {
      window.location.href = this.props.lastLocation;
      localStorage.removeItem(`${LOCAL_STORAGE_VAR}-last`);
    } else {
      if (window.location.href.split("=")[1]) {
        this.props.history.push(window.location.href.split("=")[1]);
      } else {
        this.props.history.push("/dashboard");
      }
    }
  };

  handleValidation = () => {
    let formData = this.state.formData;
    let errors = this.state.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFiels = [];
    if (!formData["username"] || isEmpty(formData["username"])) {
      blankField.push("User Name");
      errors["username"] = true;
      formIsValid = false;
    }
    if (!formData["password"] || isEmpty(formData["password"])) {
      blankField.push("Password");
      errors["password"] = true;
      formIsValid = false;
    }

    if (blankField.length > 0) {
      let arr = blankField;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    if (invalidFiels.length > 0) {
      let arr = invalidFiels;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleKycChange = (key, value) => {
    this.setState({ Kyc: { ...this.state.Kyc, [key]: value } });
  };

  uploadDocument = (ee, type, img) => {
    const e = cloneDeep(ee);
    let self = this;
    let errors = clone(this.state.errors);
    let formData = clone(this.state.data);

    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = function (ev) {
      var arr = new Uint8Array(ev.target.result).subarray(0, 4);
      var header = "";
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (
        !MIME_TYPES[blob.type] ||
        (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))
      ) {
        return OpenNotification({
          type: "error",
          title:
            "File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.",
        });
      } else {
        const data = new FormData();
        data.append("folder", "user");
        data.append("file", e.target.files[0]);
        let userToken = self.state.userData.data.token.jwt;
        let objData = {
          ...UPLOAD_FILE,
          auth: userToken,
          request: data,
        };
        UtilService.callApi(objData, function (err, data) {
          if (err) throw err;
          if (data && data.code === "OK") {
            if (data.data && data.data.files && data.data.files.length !== 0) {
              if (img === 1) {
                self.setState({
                  Kyc: {
                    ...self.state.Kyc,
                    imageOne: data.data.files[0].absolutePath,
                  },
                });
              } else {
                self.setState({
                  Kyc: {
                    ...self.state.Kyc,
                    imageTwo: data.data.files[0].absolutePath,
                  },
                });
              }
            }
          }
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };

  createKyc = () => {
    let self = this;
    if (
      self.state.Kyc &&
      self.state.Kyc.imageOne !== "" &&
      self.state.Kyc.typeOne !== "" &&
      self.state.Kyc.typeTwo !== "" &&
      self.state.Kyc.imageTwo !== ""
    ) {
      let user = self.state.userData;
      let objReq = {
        // url: ACCOUNT_UPDATE_KYC.url + user.data.user.account.id,
        // method: ACCOUNT_UPDATE_KYC.method,
        // showLoader: ACCOUNT_UPDATE_KYC.showLoader,
        ...ADMIN_UPDATE_USER,
        request: {
          kyc: [
            {
              path: self.state.Kyc.imageOne,
              docType: self.state.Kyc.typeOne,
              type: "personal",
            },
            {
              path: self.state.Kyc.imageTwo,
              docType: self.state.Kyc.typeTwo,
              type: "business",
            },
          ],
          isKycUploaded: true,
          isApproved: 1,
        },
        auth: user.data.token.jwt,
      };
      UtilService.callApi(objReq, function (err, data) {
        if (err) throw err;
        if (data && data.code === "OK") {
          user.data.user.account.isKycUploaded = true;
          user.data.user.account.kyc = objReq.request.kyc;
          // UtilService.setLocalStorageItem(user.data.user, `${LOCAL_STORAGE_VAR}-user`)
          self.getRedirection(user);

          OpenNotification({
            type: "success",
            title: "Your KYC documents are successfully submitted.",
          });
          self.onClose();
        }
      });
    } else {
      OpenNotification({
        type: "error",
        title: "Please upload both the images select types",
      });
    }
  };

  confirmationQuickRegister = () => {
    this.setState({ QuickRegistershowModal: false });
    this.props.history.push('/quick-signup');
  }

  render() {
    const { formData, rememberMe, errors } = this.state;
    return (
      <>
        <div
          className={`loginDetail ${this.state.isLoading ? "loading-indicator" : ""
            }`}
        >
          <div className="loginHead">
            <div className="loginLogo">
              <Logo />
            </div>
            <div className="wel_msg">
              {/* <h3>Welcome to</h3> */}
              <img src={require("../../assets/img/logo1.png")} alt="" />
              {/* <img src={require("../../assets/img/BNY-halloween-front-logo.png")} alt="" /> */}
            </div>
          </div>
          <div className="loginBox">
            {/* <Loader loading={this.state.loading} /> */}
            <InputBlock
              onChange={(e) =>
                this.handleOnChange("username", trim(e.target.value))
              }
              value={formData.username}
              type="text"
              label="USERNAME"
              placeholder="Username"
              hasError={errors["username"] ? true : false}
            />
            {/* <div className="form-control">
              <label htmlFor="Email-Address" className="d-block">
                <IntlMessages id="app.signin.username" />
              </label>
              <input
                onChange={this.handleOnChange.bind(this)}
                value={username}
                type="text"
                id="username"
                name="username"
                autoComplete="off"
              />
            </div> */}
            <InputBlock
              onChange={(e) => this.handleOnChange("password", e.target.value)}
              value={formData.password}
              type="Password"
              label="PASSWORD"
              hasError={errors["password"] ? true : false}
              placeholder="Password"
              loginKeyDown={() => {
                if (formData.username && formData.password) {
                  this.handleSubmit();
                }
              }}
            />

            {/* <div className="form-control">
              <label htmlFor="Password" className="d-block">
                <IntlMessages id="app.signin.password" />
              </label>
              <input
                onChange={this.handleOnChange.bind(this)}
                value={password}
                type="password"
                id="password"
                name="password"
                autoComplete="off"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    if (username && password) {
                      this.handleSubmit();
                    }
                  }
                }}
              />
            </div> */}
            <div className="d-flex j-space-between align-items-center loginLinkBlock">
              <div className="rememberCheckBox">
                <CheckBox
                  id="remember-check"
                  checked={this.state.rememberMe}
                  value={this.state.rememberMe}
                  onChange={(e) =>
                    this.setState({ rememberMe: e.target.checked })
                  }
                />
                <span className="rememberLabel">Remember Me</span>
              </div>
              <Link
                to="/forgot-password"
                className="primary-text forgot-password"
              >
                Forgot Password?
              </Link>
            </div>
            <div className="loginButton">
              <button
                className="commonButton"
                onClick={this.handleSubmit.bind(this)}
              >
                Log In
              </button>
              <button
                onClick={() => {
                  this.props.history.push("/guest-login");
                }}
                className="commonOutline"
              >
                Guest Login
              </button>
            </div>

            {/* <hr style={{ marginBottom: "10px", marginTop: "10px" }} className="login-shapret" /> */}

            {/* </form> */}
            {this.state.verifyEmail && (
              <button
                className="commonButton"
                id={"verifyEmailBtn"}
                onClick={this.handleVerifyEmail}
              >
                Verify Email
              </button>
            )}
          </div>
          <div className="registerLogLink">
            New to Belgium?
            <Link to="/signup">Register Here</Link>
            <div className="registerLogLink">
              <span className="child" onClick={() => this.setState({ QuickRegistershowModal: true })}>
                * Quick Register Here *
              </span>
            </div>
          </div>

          {this.state.QuickRegistershowModal && (
            <Modal
              visible={this.state.QuickRegistershowModal}
              title={[<h3 style={{ color: "rgba(0,0,0,0.70)" }}>Confirmation</h3>]}
              onCancel={() => this.setState({ QuickRegistershowModal: false })}
              footer={[
                <button className="commonButton" onClick={() => this.confirmationQuickRegister()}>
                  Agree
                </button>
              ]}
              width={720}
            >
              <span style={{ fontWeight: '600' }}>
                Please note that it limits your transaction capabilities (No Hold / Memo / Invoice). For full access complete detailed registration.
              </span>
            </Modal>
          )}
          <DownloadBlock />
          {/* <BrandLogo /> */}
        </div>
        <Modal
          visible={this.state.showModal}
          title={[
            <h2
              key="deletebuttonH2"
              className="modal__title primary-text"
              id="modal-1-title"
            >
              Confirmation
            </h2>,
          ]}
          onCancel={() => this.setState({ showModal: false })}
          footer={[
            <div key="deletebuttonReminder">
              <button
                className="modal__btn button primary-fill"
                style={{ padding: "9px 25px" }}
                onClick={this.handleUpdateToken}
              >
                Yes
              </button>
              <button
                className="button button-outline primary-outline"
                style={{ padding: "9px 25px" }}
                onClick={() => this.setState({ showModal: false })}
              >
                No
              </button>
            </div>,
          ]}
          width={620}
        >
          <h3 align="center" style={{ color: "rgba(0,0,0,0.70)" }}>
            It appears that, you are already Logged-In on other Device. Do you
            wish to Continue here and logout from earlier device?
          </h3>
        </Modal>
        <Modal
          visible={this.state.visible}
          title={[
            <h2
              key="deletebuttonH2"
              className="modal__title primary-text"
              id="modal-1-title"
            >
              Upload KYC - Identity Proof
            </h2>,
          ]}
          onCancel={() => this.setState({ visible: false })}
          footer={[
            <div key="deletebuttonReminder">
              <button
                className="modal__btn button primary-fill"
                style={{ padding: "9px 25px" }}
                onClick={this.createKyc}
              >
                Yes
              </button>
              <button
                className="button button-outline primary-outline"
                style={{ padding: "9px 25px" }}
                onClick={() => this.setState({ visible: false })}
              >
                No
              </button>
            </div>,
          ]}
          width={620}
        >
          <div
            style={{ marginBottom: "15px" }}
            className="d-flex j-content-center updateProfileItem"
          >
            <div className="d-flex flex-wrap kyc-block">
              <SelectOption
                className="width-full"
                placeholder="Personal Document"
                value={this.state.Kyc.typeOne}
                selectValue={this.state.personalDocs}
                onChange={(e) => {
                  this.handleKycChange("typeOne", e);
                }}
                label="Select Personal Document"
              />
              <UploadImage
                className="width-full"
                title="Personal Identity"
                file={this.state.Kyc.imageOne}
                noRightBorder
                onUpload={(e) => this.uploadDocument(e, "photoId", 1)}
              />
            </div>
            <div className="d-flex flex-wrap kyc-block">
              <SelectOption
                className="width-full"
                placeholder="Business Document"
                value={this.state.Kyc.typeTwo}
                selectValue={this.state.businessDocs}
                onChange={(e) => {
                  this.handleKycChange("typeTwo", e);
                }}
                label="Select Business Document"
              />
              <UploadImage
                className="width-full"
                title="Business Identity"
                file={this.state.Kyc.imageTwo}
                noRightBorder
                onUpload={(e) => this.uploadDocument(e, "photoId", 2)}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ auth, commonData }) => {
  const { token } = auth;
  // const { loading } = commonData;
  return { token };
};

export default withRouter(connect(mapStateToProps, { setLoader })(LoginForm));
