import antdRu from "antd/lib/locale-provider/ru_RU";
import appLocaleData from "react-intl/locale-data/ru";
import enMessages from "../locales/ru_RU.json";

const allMessages = { ...enMessages };
const RuLang = {
  messages: {
    ...allMessages,
  },
  antd: antdRu,
  locale: "ru-RU",
  data: appLocaleData,
};
export default RuLang;
