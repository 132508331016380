/* eslint-disable */
import React, { useState, useEffect } from "react";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import {
  handleInsertTrack,
  getColumn,
  isMobile,
  getDiamondBlockMaster,
  handleAddBlock,
} from "../../DiamondList/DiamondListFunctions";
import Table from "../../DiamondList/TableBack";
import IntlMessages from "../../../util/IntlMessages";
import { trackTypeObj } from "../../../constants/Common";
import OpenNotification from "../CommonButton/OpenNotification";
import { HeadingCalc } from "./ConfirmStonePopup";
import { useSelector } from "react-redux";
import WatchPopupMobile from "./WatchPopupMobile";
import InputBlock from "../InputBlock";
import TextArea from "../TextArea";
import { Select, Row, Col } from "antd";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
/* eslint jsx-a11y/anchor-is-valid: 0 */

const { Option } = Select;
const currentType = "MemoPopup";

const MemoPopup = (props) => {
  const [columns, setColumns] = useState([]);
  const [comment, setComment] = useState("");
  const [exp, setExp] = useState(true);
  const [data, setData] = useState(
    props.checked.map((x) => ({ ...x, expBack: x.back - 1 })),
  );
  const checked =
    useSelector((state) => state.diamondData.selectedRows[currentType]) || [];
  const [blockMaster, setBlockMaster] = useState({});
  const [time, setTime] = useState(undefined);

  useEffect(() => {
    let Columns = getColumn();
    let index = findIndex(Columns, { id: "back" });
    if (!index) index = 1;
    let columns = [...Columns.slice(0, index + 1)];
    columns = [
      ...columns,
      ...Columns.filter((el) => !find(columns, { id: el.id })),
    ];
    setColumns(columns);
  }, [exp]);

  useEffect(() => {
    getDiamondBlockMaster("M", (data) => {
      setBlockMaster(data);
    });
  }, []);

  const getHoldInput = () => {
    return (
      <Row gutter={16}>
        <Col md={12}>
          <div className="formSelect from-group">
            <label>Time Duration</label>
            <Select
              value={time}
              onChange={(value) => setTime(value)}
              getPopupContainer={(trigger) => {
                return trigger;
              }}
              placeholder="Select Time"
              dropdownClassName="selectDopdown"
            >
              {!isEmpty(blockMaster) &&
                blockMaster.blockTimes.map((t) => (
                  <Option value={t}>{t}</Option>
                ))}
            </Select>
          </div>
        </Col>
        <Col md={12}>
          <TextArea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            label="Comment"
            placeholder="Comment"
          />
        </Col>
      </Row>
    );
  };

  const btnAction = () => {
    return (
      <div className="sideBarPopupButton">
        <a
          onClick={() => {
            if (!checked.length) {
              return OpenNotification({
                type: "error",
                title: "Please select stone(s) to hold.",
              });
            }
            let diams = [];
            let _time = moment().add(time, "hours");

            checked.map((c) => {
              diams.push({
                diamond: c.id,
                blockPricePerCarat: c.ctPr,
                blockAmount: c.amt,
                vStnId: c.vStnId,
                // blockSetting: !isEmpty(blockMaster) ? blockMaster.id : "",
              });
            });

            handleAddBlock(2, diams, _time, comment, (cb) => {
              if (cb) {
                props.onClose();
                props.clearAll();
              }
            });
          }}
          className="commonButton"
        >
          <IntlMessages id="app.inMemo" />
        </a>
        <a className="commonButton" onClick={props.onClose}>
          <IntlMessages id="app.Cancel" />
        </a>
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <h2 className="popupInnerTitle mr-40">
          <IntlMessages id="app.inMemo" />
        </h2>
        {HeadingCalc(checked)}
      </div>
      <div className="searchPopupCommonTable">
        <div className="searchResultTable tabInnerTableScroll watchpopup">
          <Table
            data={data}
            columns={columns}
            areAllChecked={true}
            noGrp
            canSort={false}
            currentType={currentType}
          />
        </div>
        <div className="offerBottomBox flex-wrap mt-20">
          {/* <p className="offerNote">
            <b>
              <IntlMessages id="app.Note" />:
            </b>{" "}
            <IntlMessages id="app.offerNote" />
          </p> */}
          {getHoldInput()}
        </div>

        {btnAction()}
      </div>
    </div>
  );
};

export default MemoPopup;
