/* eslint-disable */
import React, { useState } from "react";
import { Checkbox } from "antd";
import {
  handleDownloadExcel,
  downloadZip,
} from "../../DiamondList/DiamondListFunctions";
import IntlMessages from "../../../util/IntlMessages";
import OpenNotification from "../../../components/common/CommonButton/OpenNotification";
import map from "lodash/map";
import _isFunction from "lodash/isFunction";
import _isBoolean from "lodash/isBoolean";

const DownloadDropdown = (props) => {
  //
  const [isExcel, setIsExcel] = useState(false);
  const [isCert, setIsCert] = useState(false);
  const [typeIIA, setTypeIIA] = useState(false);
  const [isImg, setIsImg] = useState(false);
  const [isImg2, setIsImg2] = useState(false);
  const [isFaceUp, setIsFaceUp] = useState(false);
  const [DarkField, setDarkField] = useState(false);
  const [Ideal, setIdeal] = useState(false);
  const [flourescence, setFlourescence] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isPlt, setIsPlt] = useState(false);
  const [isHa, setIsHa] = useState(false);
  const [isAsset, setIsAsset] = useState(false);
  const [isArrow, setIsArrow] = useState(false);
  const [flag, setFlag] = useState(0);
  const [isVideo2, setIsVideo2] = useState(false);
  const [roughImage, setRoughimage] = useState(false);
  const [roughVideo, setRoughVideo] = useState(false);
  const [DImage, setDImage] = useState(false);
  const [images, setImages] = useState(false);
  const [videos, setVideos] = useState(false);
  const [certificates, setCertificates] = useState(false);
  const [roughs, setRoughs] = useState(false);
  const path = window.location.pathname.split("/")[1];

  const ShareOption = (props) => {
    return (
      <li id={props.uiid} className={props.activeClass}>
        <div className="dropdownBottomItem">
          <div className="dropdownIconCheckBox">
            <Checkbox
              onChange={props.onChange}
              onClick={() => {
                if (props.checked === true) {
                  setFlag(flag - 1);
                } else if (props.checked === false) {
                  setFlag(flag + 1);
                }
              }}
              checked={props.checked}
            ></Checkbox>
          </div>
          <span className="shareOptionLabel">{props.title}</span>
        </div>
      </li>
    );
  };

  const handleDownloadClick = () => {
    if (
      isAsset ||
      isHa ||
      isCert ||
      isExcel ||
      isImg ||
      isPlt ||
      isVideo ||
      isArrow ||
      isImg2 ||
      typeIIA ||
      isFaceUp ||
      DarkField ||
      Ideal ||
      flourescence ||
      isVideo2 ||
      roughImage ||
      roughVideo ||
      DImage
    ) {
      if (props.checked.length === 1) {
        if (
          !isAsset &&
          !isHa &&
          !isCert &&
          isExcel &&
          !isImg &&
          !isPlt &&
          !isVideo &&
          !isArrow &&
          !isImg2 &&
          !typeIIA &&
          !isFaceUp &&
          !DarkField &&
          !Ideal &&
          !flourescence &&
          !isVideo2 &&
          !roughImage &&
          !roughVideo &&
          !DImage
        ) {
          if (path === "upcoming") {
            handleDownloadExcel(
              {},
              props.checked[0].id,
              (status) => {
                if (status) {
                  props.clearAll();
                  props.onCloseDownloadPopup();
                }
              },
              path === "upcoming" ? true : false,
            );
          } else {
            //
            props.handleLoader(true);
            handleDownloadExcel(
              {},
              props.orderDiamond
                ? props.checked[0].diamondId
                : props.checked[0].id,
              () => {
                props.handleLoader(false);
              },
              // path === "upcoming" ? true : false,
              props.checked[0].wSts === "U" ? true : false,
              [],
              props.orderDiamond,
            );
          }
        } else if (flag === 1) {
          let obj = {
            filter: {
              vStnId: map(props.checked, "vStnId"),
            },
            ...(props.orderDiamond && { orderDiamond: true }),
            isExcel: isExcel,
            img: isImg,
            certFile: isCert,
            videoFile: isVideo,
            hAFile: isHa,
            arrowFile: isArrow,
            assetFile: isAsset,
            pltFile: isPlt,
            mp4Video: isVideo2,
            type2: typeIIA,
            roughVideo: roughVideo,
            darkFieldImage: DarkField,
            flsImage: flourescence,
            idealWhiteImage: Ideal,
            roughImage: roughImage,
            planImg: DImage,
            faceUp: isFaceUp,
            b2cRealImage: isImg2,
          };
          downloadZip(obj, (res) => {
            if (res) {
              props.clearAll();
              props.onCloseDownloadPopup();

              OpenNotification({
                type: "success",
                title:
                  "Wait for sometime, your download is in process. File(s) will be downloaded soon.",
              });
            }
          });
        } else if (flag >= 2) {
          //

          let obj = {
            filter: {
              vStnId: map(props.checked, "vStnId"),
            },
            ...(props.orderDiamond && { orderDiamond: true }),
            isExcel: isExcel,
            img: isImg,
            certFile: isCert,
            videoFile: isVideo,
            hAFile: isHa,
            arrowFile: isArrow,
            assetFile: isAsset,
            pltFile: isPlt,
            mp4Video: isVideo2,
            type2: typeIIA,
            roughVideo: roughVideo,
            darkFieldImage: DarkField,
            flsImage: flourescence,
            idealWhiteImage: Ideal,
            roughImage: roughImage,
            planImg: DImage,
            faceUp: isFaceUp,
            b2cRealImage: isImg2,
            zip: true,
          };
          downloadZip(obj, (res) => {
            if (res) {
              props.clearAll();
              props.onCloseDownloadPopup();

              OpenNotification({
                type: "success",
                title:
                  "Wait for sometime, your download is in process. File(s) will be downloaded soon.",
              });
            }
          });
        }
        // else if (isCert) {
        //   if (props.checked[0].certFile) {
        //     let obj = {
        //       path: `${MEDIA_URL}/CertiImages/${props.checked[0].rptNo}.pdf`,
        //       fileName:  `certFile_${props.checked[0].rptNo}`,
        //       ext: ".pdf",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Certificate is not available !",
        //     });
        //   }
        // } else if (isVideo &&  path !== 'dna') {
        //   if (props.checked[0].videoFile) {
        //     let obj = {
        //       path: `${MEDIA_URL}/Mov/${props.checked[0].vStnId}.mp4`,
        //       fileName: `videoFile_${props.checked[0].vStnId}` ,
        //       ext: ".mp4",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };

        //     downloadVideo(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Video File is not available !",
        //     });
        //   }
        // } else if (isVideo2 &&  path !== 'dna') {
        //   if (props.checked[0].img) {
        //     let obj = {
        //       path: `${MEDIA_URL}/viewer3/MP4_Videos/${props.checked[0].vStnId}.mp4`,
        //       fileName: `video2File_${props.checked[0].vStnId}`,
        //       ext: ".mp4",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };

        //     downloadVideo(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Video 2 File is not available !",
        //     });
        //   }
        // } else if (isImg &&  path !== 'dna') {
        //   if (props.checked[0].img) {
        //     let obj = {
        //       path: `${MEDIA_URL}/RealImages/${props.checked[0].vStnId}.jpg`,
        //       fileName: `RealImage_${props.checked[0].vStnId}`,
        //       ext: ".jpg",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Image is not available !",
        //     });
        //   }
        // } else if (isArrow &&  path !== 'dna') {
        //   if (props.checked[0].arrowFile) {
        //     let obj = {
        //       path: `${MEDIA_URL}/ArrowImages/${props.checked[0].vStnId}.jpg`,
        //       fileName: `ArrowFile_${props.checked[0].vStnId}`,
        //       ext: ".jpg",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Arrow Image is not available !",
        //     });
        //   }
        // } else if (isAsset &&  path !== 'dna') {
        //   if (props.checked[0].assetFile) {
        //     let obj = {
        //       path: `${MEDIA_URL}/AssetScopeImages/${props.checked[0].vStnId}.jpg`,
        //       fileName: `AssetFile_${props.checked[0].vStnId}`,
        //       ext: ".jpg",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Asset Image is not available !",
        //     });
        //   }
        // } else if (isHa &&  path !== 'dna') {
        //   if (props.checked[0].hAFile) {
        //     let obj = {
        //       path: `${MEDIA_URL}/HeartImages/${props.checked[0].vStnId}.jpg`,
        //       fileName: `HeartImage_${props.checked[0].vStnId}`,
        //       ext: ".jpg",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Heart & Arrow File is not available !",
        //     });
        //   }
        // } else if (isPlt &&  path !== 'dna') {
        //   if (props.checked[0].pltFile) {
        //     let obj = {
        //       path: `${MEDIA_URL}/PlottingImages/${props.checked[0].vStnId}.jpg`,
        //       fileName: `PlottingImage_${props.checked[0].vStnId}`,
        //       ext: ".jpg",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Plotting Image is not available !",
        //     });
        //   }
        // } else if (isImg2 &&  path !== 'dna') {
        //   if (props.checked[0].img) {
        //     let obj = {
        //       path: `${MEDIA_URL}/viewer3/RealImages/${props.checked[0].vStnId}.jpg`,
        //       fileName: `RealImage2_${props.checked[0].vStnId}`,
        //       ext: ".jpg",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Real Image 2 is not available !",
        //     });
        //   }
        // } else if (typeIIA &&  path !== 'dna') {
        //   if (props.checked[0].img) {
        //     let obj = {
        //       path: `${MEDIA_URL}/TYPE_IIA/${props.checked[0].rptNo}.pdf`,
        //       fileName:  `TypeIIA_${props.checked[0].rptNo}`,
        //       ext: ".pdf",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "TypeIIA file is not available !",
        //     });
        //   }
        // } else if (DarkField &&  path !== 'dna') {
        //   if (props.checked[0].img) {
        //     let obj = {
        //       path: `${MEDIA_URL}/Dark_Field_White_BG/${props.checked[0].vStnId}.jpg`,
        //       fileName: `DarkField_${props.checked[0].vStnId}`,
        //       ext: ".jpg",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Dark-Field Image is not available !",
        //     });
        //   }
        // } else if (Ideal &&  path !== 'dna') {
        //   if (props.checked[0].img) {
        //     let obj = {
        //       path: `${MEDIA_URL}/IDEAL_White_BG/${props.checked[0].vStnId}.jpg`,
        //       fileName: `Ideal_${props.checked[0].vStnId}`,
        //       ext: ".jpg",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Ideal Scope Image is not available !",
        //     });
        //   }
        // } else if (flourescence &&  path !== 'dna') {
        //   if (props.checked[0].img) {
        //     let obj = {
        //       path: `${MEDIA_URL}/FlsImages/${props.checked[0].vStnId}.jpg`,
        //       fileName:  `Flourescence_${props.checked[0].vStnId}` ,
        //       ext: ".jpg",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Flourescence Image is not available !",
        //     });
        //   }
        // } else if (isFaceUp &&  path !== 'dna') {
        //   if (props.checked[0].img) {
        //     let obj = {
        //       path: `${MEDIA_URL}/Office_Light_Black_BG/${props.checked[0].vStnId}.jpg`,
        //       fileName: `Faceup_${props.checked[0].vStnId}` ,
        //       ext: ".jpg",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Face-up Image is not available !",
        //     });
        //   }
        // } else if (roughImage &&  path !== 'dna') {
        //   if (props.checked[0].img) {
        //     let obj = {
        //       path: `${MEDIA_URL}/MFG/RoughImages/${props.checked[0].vStnId}.jpg`,
        //       fileName: `RoughImage_${props.checked[0].vStnId}`,
        //       ext: ".jpg",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Rough Image is not available !",
        //     });
        //   }
        // } else if (roughVideo &&  path !== 'dna') {
        //   if (props.checked[0].img) {
        //     let obj = {
        //       path: `${MEDIA_URL}/MFG/RoughVideo//${props.checked[0].vStnId}.html`,
        //       fileName: `RoughVideo_${props.checked[0].vStnId}`,
        //       ext: ".html",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "Rough Video is not available !",
        //     });
        //   }
        // } else if (DImage &&  path !== 'dna') {
        //   if (props.checked[0].img) {
        //     let obj = {
        //       path: `${MEDIA_URL}/MFG/PlanImages//${props.checked[0].vStnId}.jpg`,
        //       fileName: `3DImage_${props.checked[0].vStnId}`,
        //       ext: ".jpg",
        //       ...(props.orderDiamond && { orderDiamond: true }),
        //     };
        //     downloadFile(obj);
        //   } else {
        //     OpenNotification({
        //       type: "error",
        //       title: "3D Image is not available !",
        //     });
        //   }
        // }
      } else {
        if (
          !isAsset &&
          !isHa &&
          !isCert &&
          isExcel &&
          !isImg &&
          !isPlt &&
          !isVideo &&
          !isArrow &&
          !isImg2 &&
          !typeIIA &&
          !isFaceUp &&
          !DarkField &&
          !Ideal &&
          !flourescence &&
          !isVideo2 &&
          !roughImage &&
          !roughVideo &&
          !DImage
        ) {
          handleDownloadExcel(
            {},
            props.orderDiamond
              ? map(props.checked, "diamondId")
              : map(props.checked, "id"),
            (status) => {
              if (status) {
                props.clearAll();
                props.onCloseDownloadPopup();
              }
            },
            // path === "upcoming" ? true : false,
            props.checked[0].wSts === "U" ? true : false,
            [],
            props.orderDiamond,
          );
        } else {
          //
          let obj = {
            filter: {
              vStnId: map(props.checked, "vStnId"),
            },
            ...(props.orderDiamond && { orderDiamond: true }),
            isExcel: isExcel,
            img: isImg,
            certFile: isCert,
            videoFile: isVideo,
            hAFile: isHa,
            arrowFile: isArrow,
            assetFile: isAsset,
            pltFile: isPlt,
            mp4Video: isVideo2,
            type2: typeIIA,
            roughVideo: roughVideo,
            darkFieldImage: DarkField,
            flsImage: flourescence,
            idealWhiteImage: Ideal,
            roughImage: roughImage,
            planImg: DImage,
            faceUp: isFaceUp,
          };
          downloadZip(obj, (res) => {
            if (res) {
              props.clearAll();
              props.onCloseDownloadPopup();

              OpenNotification({
                type: "success",
                title:
                  "Wait for sometime, your download is in process. File(s) will be downloaded soon.",
              });
            }
          });
        }
      }
      props.onCloseDownloadPopup();
    } else {
      OpenNotification({
        type: "error",
        title: "Please Select atleast 1 Option to Download.",
      });
    }
  };

  // handle images selection start
  const updateAllImage = React.useCallback((check) => {
    setIsImg(check);
    setIsImg2(check);
    setIsPlt(check);
    setIsHa(check);
    setIsAsset(check);
    setIsFaceUp(check);
    setDarkField(check);
    setIdeal(check);
    setFlourescence(check);
    setIsArrow(check);
  }, []);

  const checkAllImages = React.useCallback(
    (allImages = null) => {
      if (_isBoolean(allImages)) {
        setFlag(allImages ? 2 : 0);
        return updateAllImage(allImages);
      }
      if (
        isImg &&
        isImg2 &&
        isPlt &&
        isHa &&
        isAsset &&
        isArrow &&
        isFaceUp &&
        DarkField &&
        Ideal &&
        flourescence
      )
        setImages(true);
      else setImages(false);
    },
    [
      DarkField,
      Ideal,
      flourescence,
      isArrow,
      isAsset,
      isFaceUp,
      isHa,
      isImg,
      isImg2,
      isPlt,
      updateAllImage,
    ],
  );

  React.useEffect(() => {
    checkAllImages();
  }, [checkAllImages]);

  // handle video selection start
  const updateAllVideo = React.useCallback((allVideo) => {
    setIsVideo(allVideo);
    setIsVideo2(allVideo);
  }, []);

  const checkAllVideo = React.useCallback(
    (allVideo = null) => {
      if (_isBoolean(allVideo)) {
        setFlag(allVideo ? 2 : 0);
        return updateAllVideo(allVideo);
      }
      if (isVideo && isVideo2) setVideos(true);
      else setVideos(false);
    },
    [isVideo, isVideo2, updateAllVideo],
  );

  React.useEffect(() => {
    checkAllVideo();
  }, [checkAllVideo]);

  //certificate handle start
  const updateAllCertificate = React.useCallback((allCertificate) => {
    setIsCert(allCertificate);
    setTypeIIA(allCertificate);
  }, []);

  const checkAllCertificate = React.useCallback(
    (allCertificate = null) => {
      if (_isBoolean(allCertificate)) {
        setFlag(allCertificate ? 2 : 0);
        return updateAllCertificate(allCertificate);
      }
      if (isCert && typeIIA) setCertificates(true);
      else setCertificates(false);
    },
    [isCert, typeIIA, updateAllCertificate],
  );

  React.useEffect(() => {
    checkAllCertificate();
  }, [checkAllCertificate]);

  // Rough handle start
  const updateAllRough = React.useCallback((allRough) => {
    setRoughVideo(allRough);
    setRoughimage(allRough);
    setDImage(allRough);
  }, []);

  const checkAllRoughs = React.useCallback(
    (allRough = null) => {
      if (_isBoolean(allRough)) {
        setFlag(allRough ? 2 : 0);
        return updateAllRough(allRough);
      }
      if (roughImage && roughVideo && DImage) setRoughs(true);
      else setRoughs(false);
    },
    [DImage, roughImage, roughVideo, updateAllRough],
  );

  React.useEffect(() => {
    checkAllRoughs();
  }, [checkAllRoughs]);

  return (
    <>
      <div>
        <div className="searchPopupCommon">
          <div className="shareOptionSet d-flex">
            <div className="shareOptionSetItem">
              <ul>
                <div className="dropdownMainTitle">
                  <div className="dropdownIconCheckBox">
                    <Checkbox
                      checked={images}
                      onChange={() => checkAllImages(!images)}
                    ></Checkbox>
                  </div>
                  <span>Images : </span>
                </div>
                <div className="dropdownRightCheck">
                  <ShareOption
                    title="B2B Image"
                    checked={isImg}
                    onChange={() => {
                      setIsImg(!isImg);
                    }}
                  />
                  <ShareOption
                    title="B2C Image"
                    checked={isImg2}
                    onChange={() => {
                      setIsImg2(!isImg2);
                    }}
                  />
                  <ShareOption
                    title="Plotting"
                    checked={isPlt}
                    onChange={() => setIsPlt(!isPlt)}
                  />
                  <ShareOption
                    title="Heart & Arrow"
                    checked={isHa}
                    onChange={() => setIsHa(!isHa)}
                  />
                  <ShareOption
                    title="Asset Scope"
                    checked={isAsset}
                    onChange={() => setIsAsset(!isAsset)}
                  />
                  <ShareOption
                    title="Arrow Image"
                    checked={isArrow}
                    onChange={() => setIsArrow(!isArrow)}
                  />
                  <ShareOption
                    title="Face Up Image"
                    checked={isFaceUp}
                    onChange={() => setIsFaceUp(!isFaceUp)}
                  />
                  <ShareOption
                    title="Dark - Field Image"
                    checked={DarkField}
                    onChange={() => setDarkField(!DarkField)}
                  />
                  <ShareOption
                    title="Ideal Scope Image"
                    checked={Ideal}
                    onChange={() => setIdeal(!Ideal)}
                  />
                  <ShareOption
                    title="Flouresence Image"
                    checked={flourescence}
                    onChange={() => setFlourescence(!flourescence)}
                  />
                </div>
              </ul>
              <ul>
                <div className="dropdownMainTitle">
                  <div className="dropdownIconCheckBox">
                    <Checkbox
                      checked={videos}
                      onChange={() => checkAllVideo(!videos)}
                    ></Checkbox>
                  </div>
                  <span>Videos : </span>
                </div>
                <div className="dropdownRightCheck">
                  <ShareOption
                    title="B2B Video"
                    checked={isVideo}
                    onChange={() => setIsVideo(!isVideo)}
                  />
                  <ShareOption
                    title="B2C Video"
                    checked={isVideo2}
                    onChange={() => setIsVideo2(!isVideo2)}
                  />
                </div>
              </ul>
              <ul className="d-flex">
                <div className="width-50 d-flex align-items-center">
                  <div className="dropdownMainTitle">
                    <div className="dropdownIconCheckBox">
                      <Checkbox
                        checked={certificates}
                        onChange={() => checkAllCertificate(!certificates)}
                      ></Checkbox>
                    </div>
                    <span>Certificates : </span>
                  </div>
                  <div className="dropdownRightCheck">
                    <ShareOption
                      title="Certificate"
                      checked={isCert}
                      onChange={() => setIsCert(!isCert)}
                    />
                    <ShareOption
                      title="Type IIA"
                      checked={typeIIA}
                      onChange={() => setTypeIIA(!typeIIA)}
                    />
                  </div>
                </div>
                <div className="width-50 d-flex align-items-center excelShare">
                  <div className="dropdownMainTitle">
                    <span>Excel : </span>
                  </div>
                  <div className="dropdownRightCheck">
                    <div>
                      <ShareOption
                        checked={isExcel}
                        onChange={() => setIsExcel(!isExcel)}
                        title="Excel"
                      />
                    </div>
                  </div>
                </div>
              </ul>
              <ul>
                <div className="dropdownMainTitle">
                  <div className="dropdownIconCheckBox">
                    <Checkbox
                      checked={roughs}
                      onChange={() => checkAllRoughs(!roughs)}
                    ></Checkbox>
                  </div>
                  <span>Roughs : </span>
                </div>
                <div className="dropdownRightCheck">
                  <ShareOption
                    title="Rough Scope"
                    checked={roughImage}
                    onChange={() => setRoughimage(!roughImage)}
                  />
                  <ShareOption
                    title="3D Image"
                    checked={DImage}
                    onChange={() => setDImage(!DImage)}
                  />
                  <ShareOption
                    title="Rough Video"
                    checked={roughVideo}
                    onChange={() => setRoughVideo(!roughVideo)}
                  />
                </div>
              </ul>
            </div>
          </div>
          <div className="commonModalButton mt-50">
            <button
              className="fillButton"
              onClick={() => handleDownloadClick()}
            >
              <IntlMessages id="app.Download" />
            </button>
            <button
              onClick={() => props.onCloseDownloadPopup()}
              className="outLineButton ml-5"
            >
              <IntlMessages id="app.Cancel" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default DownloadDropdown;
