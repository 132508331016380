/* eslint-disable */
import React from "react";

const MainFilterTop = (props) => {
  return (
    <div className={`searchInnerFilterItem ${props.activeClass}`}>
      <span className="searchInnerFilterTitle">{props.title}</span>

      {props.closeOff ? null : (
        <span
          onClick={(e) => {
            props.handleRemoveFilter(props.id);
            e.stopPropagation();
          }}
          className="searchMainFilterIcon filterClose"
        >
          <img
            src={require("../../../assets/svg/DiamondList/close-theme.svg")}
            alt=""
          />
        </span>
      )}
    </div>
  );
};
export default MainFilterTop;
