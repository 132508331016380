/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import { BASE_URL, DASHBOARD_MEDIA_URL } from "../../../constants/Common";
import Slider from "react-slick";
import { browserName, isSafari } from "react-device-detect";
class LeftRightBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: undefined,
    };
  }
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 30000,
    };
    let data = this.props && this.props.bannerData;
    let img = this.props.bannerData.images;
    let getAllImages = img.filter((data) => {
      return typeof data === "object"
        ? ["jpeg", "jpg", "png"].includes(
            data.url.split(".").pop().toLowerCase(),
          )
        : ["jpeg", "jpg", "png"].includes(data.split(".").pop().toLowerCase());
    });
    let imgPath = `${BASE_URL}/${img}`;

    return (
      <>
        <div className="homeContainer">
          <div className="homeBanner1 leftRightBlock">
            <div className="homeBanner1Conetnt leftBlock">
              {data && data.eventName && <h2>{data.eventName}</h2>}
              {data && data.description && <p>{data.description}</p>}
              {/* <h2>{(data && data.eventName) || "-"}</h2>
              <p>{(data && data.description) || "-"}</p> */}
              {/* <Link to="/">
                <IntlMessages id="app.showMore" />
              </Link> */}
            </div>
            <div className="homeBanner1Img rightBlock">
              {browserName !== "Firefox" ? (
                <Slider {...settings}>
                  {img.map((f, index) => {
                    let url = "";
                    let ext =
                      typeof f === "string"
                        ? f?.split(".").pop().toLowerCase()
                        : f?.url?.split(".").pop().toLowerCase();

                    if (typeof f === "object") {
                      url = DASHBOARD_MEDIA_URL + f?.url;

                      if (!f?.url.startsWith("/")) {
                        url = DASHBOARD_MEDIA_URL + "/" + f?.url;
                      }
                    } else {
                      url = DASHBOARD_MEDIA_URL + f;

                      if (!f.startsWith("/")) {
                        url = DASHBOARD_MEDIA_URL + "/" + f;
                      }
                    }

                    return (
                      <div key={index}>
                        {["mp4", "mkv", "wmv"].includes(ext) ? (
                          <div className="homeBannerImageBlock">
                            <video
                              loop
                              muted
                              autoPlay={!isSafari ? true : false}
                            >
                              <source src={url}></source>
                            </video>
                          </div>
                        ) : (
                          <div className="homeBannerImageBlock">
                            <img src={url} alt="banner" />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Slider>
              ) : getAllImages.length > 1 ? (
                <Slider {...settings}>
                  {getAllImages.map((f, index) => {
                    let url = BASE_URL + f;
                    if (!f.startsWith("/")) {
                      url = BASE_URL + "/" + f;
                    }
                    return (
                      <div key={index}>
                        <div className="homeBannerImageBlock">
                          <img src={url} alt="banner" />
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              ) : getAllImages.length === 1 ? (
                <div>
                  <div className="homeBannerImageBlock">
                    <img
                      src={`${BASE_URL}/${
                        !getAllImages[0].startsWith("/")
                          ? "/" + getAllImages[0]
                          : getAllImages[0]
                      }`}
                      alt="banner"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LeftRightBlock;
