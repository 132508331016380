/* eslint-disable */
import React, { useEffect } from "react";
import CanvasJSReact from "./canvasjs.react";
import { isNotEmpty } from "util/utils";
import _ from "lodash";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class PieChart extends React.Component {
  getChartOptions = () => {
    const chartData = [];

    if (this.props && this.props?.value && this.props.value?.length) {
      let totalCarat = 0;

      totalCarat = _.sumBy(this.props.value, function (o) {
        return parseFloat(o?.carat);
      });
      this.props.value.forEach((key) => {
        let per = "0.00";
        per = parseFloat((100 * parseFloat(key.carat)) / totalCarat).toFixed(2);
        chartData.push({
          y: key?.piece,
          label: key?._id?.toUpperCase(),
          carat: key?.carat,
          per,
        });
      });
    }

    return {
      exportEnabled: false,
      animationEnabled: true,
      data: [
        {
          type: "pie",
          startAngle: 75,
          toolTipContent:
            "<b>{label}</b> <b>Piece: </b>{y} <b>Carat :{carat}</b>, <b>{per}%</b>",
          legendText: "<b>{label}</b> <b>Piece: </b>{y}",
          indexLabelFontSize: 12,
          indexLabel: `{label} - {carat}`,
          dataPoints: chartData,
        },
      ],
    };
  };

  render() {
    return (
      <>
        <div className="homeContainer">
          <div className="chartHead">
            <h2>{this.props.title}</h2>
          </div>
          {isNotEmpty(this.props?.value) && (
            <CanvasJSChart
              className="todayVisitiGraph"
              options={this.getChartOptions()}
            />
          )}
        </div>
      </>
    );
  }
}

export default PieChart;
