/* eslint-disable */
import React, { Component } from "react";
import SavedSearchCard from "./SavedSearchCard";

class HomeSavedSearch extends Component {
  render() {
    let { save, loading } = this.props;

    return (
      <div className="rightBlockItem">
        <div
          className={`sacedSearchBox ${
            loading ? "loading-indicator-footer" : ""
          }`}
        >
          {save && save.length !== 0
            ? save.map((save, ind) => {
                return <SavedSearchCard saveDetail={save} key={ind} />;
              })
            : "No Save Search"}
        </div>
      </div>
    );
  }
}
export default HomeSavedSearch;
