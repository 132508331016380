/* eslint-disable */
import React, { useEffect, useState, useReducer } from "react";
import IntlMessages from "../../../util/IntlMessages";
import {
  GET_NOTIFICATIONS,
  MARK_AS_READ,
  ADMIN_UPDATE_USER,
} from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
import moment from "moment";
import { LOCAL_STORAGE_VAR } from "../../../constants/Common";
import { Link } from "react-router-dom";

const NotificationItem = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={`notificationDropdownDetail ${!props.read ? "readMessage" : ''}`}
    >
      <div className={"norificationDropdownItem"}>
        <h2>{props.title}</h2>
        {props.pcv && (
          <div className="d-flex flex-wrap basicDetail">{props.pcv}</div>
        )}
        <span>{props.time}</span>
      </div>
    </div>
  );
};

const NotificationDropdown = (props) => {
  const [notifyData, setNotifyData] = useState([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const getNotifications = () => {
    let obj = {
      ...GET_NOTIFICATIONS,
      request: {
        page: 1,
        limit: 10,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) {
      } else if (data && data.code === "OK") {
        if (data.data) {
          setNotifyData([...data.data.list]);
          props.setUpdatedCount(data.data.unReadCount);

          if (data.data.isPermissionChanged) {
            let setPermit = {};
            data.data.userPermissions.data.map((x) => {
              setPermit[x.module] = x.permissions;
            });
            UtilService.setLocalStorageItem(
              setPermit,
              `${LOCAL_STORAGE_VAR}-userPermissions`,
            );
            updateUserPermission();
            forceUpdate();
          }
        }
      } else {
        setNotifyData([]);
      }
    });
  };

  const MarkAsRead = (id) => {
    let obj = {
      ...MARK_AS_READ,
      request: {
        id: id,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) {
      } else if (data && data.code === "OK") {
        getNotifications();
      } else {
      }
    });
  };

  const updateUserPermission = () => {
    let obj = {
      ...ADMIN_UPDATE_USER,
      request: {
        isPermissionChanged: false,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) throw err;
    });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => getNotifications(), 60000);
  }, []);

  return (
    //<div
    //   className="savedSearchWrapper"
    //   style={{ height: gridHeight, overflow: "auto" }}
    // >
    <div className="notificationDropdown savedSearchWrapper">
      <div className="notificationItem">
        <div className="notificationTitle">
          <IntlMessages id="app.New" />
          <Link
            to="/account/notifications"
            className="viewLink"
            style={{ position: "absolute", right: 10 }}
          >
            View All Notifications
          </Link>
        </div>
        <div className="notificationInner">
          {notifyData && notifyData.length >= 1
            ? notifyData.map((e, i) => {
                let time = moment(e.createdAt).fromNow();
                return (
                  <NotificationItem
                    key={i}
                    onClick={() => {
                      MarkAsRead(e.id);
                    }}
                    title={e.title}
                    pcv={e.message}
                    time={time}
                    read={e.isRead}
                  />
                );
              })
            : "No Notification Found"}
          {/* <NotificationItem
            pcv
            read
            title="Your offer is declined by kalpesh zalavadiya"
            time="2 Hours agos"
          /> */}
        </div>
      </div>
    </div>
  );
};
export default NotificationDropdown;
