/* eslint-disable */
import React from "react";
import Heading from "../Heading";
import { Radio } from "antd";
import { HOURS } from "../DiamondListing/QuotePopup";

const HoursSelect = (props) => {
  return (
    <div>
      <Heading title="Select Hours" className="popupInnerTitle" />
      <ul className="quoteSelectOption">
        <li>
          <Radio.Group
            value={props.quoteSelect.hours}
            onChange={(e) => {
              props.onHourChange(e.target.value, props.quoteSelect);
              props.onClose();
            }}
          >
            {HOURS.map((h) => {
              return <Radio value={h}>{h}</Radio>;
            })}
          </Radio.Group>
        </li>
      </ul>
    </div>
  );
};
export default HoursSelect;
