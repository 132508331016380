/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SendEmailPopup from "./SendEmail";
import { Checkbox } from "antd";
import {
  LISTINGPAGES,
  shareExcel,
} from "../../DiamondList/DiamondListFunctions";
import map from "lodash/map";
import OpenNotification from "../../../components/common/CommonButton/OpenNotification";
import join from "lodash/join";
import {
  BASE_DOWNLOAD_URL,
  LOCAL_STORAGE_VAR,
} from "../../../constants/Common";
import { VIEW_DNA_URL } from "../../../constants/Common";
import UtilService from "../../../services/util";
import { isBoolean, isEmpty } from "util/utils";
// const USER_PERMIT = UtilService.getLocalStorageItem(
//   `${LOCAL_STORAGE_VAR}-userPermissions`
// );

const ShareDropdown = (props) => {
  const [sendEmail, setSendEmail] = useState(false);
  const [isExcel, setIsExcel] = useState(false);
  const [isCert, setIsCert] = useState(false);
  const [typeIIA, setTypeIIA] = useState(false);
  const [isImg, setIsImg] = useState(false);
  const [isImg2, setIsImg2] = useState(false);
  const [isFaceUp, setIsFaceUp] = useState(false);
  const [DarkField, setDarkField] = useState(false);
  const [Ideal, setIdeal] = useState(false);
  const [flourescence, setFlourescence] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isPlt, setIsPlt] = useState(false);
  const [isHa, setIsHa] = useState(false);
  const [isAsset, setIsAsset] = useState(false);
  const [isArrow, setIsArrow] = useState(false);
  const [flag, setFlag] = useState(0);
  const [isVideo2, setIsVideo2] = useState(false);
  const [roughImage, setRoughimage] = useState(false);
  const [roughVideo, setRoughVideo] = useState(false);
  const [DImage, setDImage] = useState(false);
  const [images, setImages] = useState(false);
  const [videos, setVideos] = useState(false);
  const [certificates, setCertificates] = useState(false);
  const [roughs, setRoughs] = useState(false);
  const [excelPath, setExcelPath] = useState("");

  const ShareOption = (props) => {
    return (
      <li
        id={props.uiid}
        onClick={() => {
          props.onChange(!props.id);
        }}
        className={props.activeClass ? props.activeClass : ""}
      >
        <div className="dropdownBottomItem">
          <div className="dropdownIconCheckBox">
            <Checkbox
              onChange={props.onChange}
              checked={props.checked}
            ></Checkbox>
          </div>
          <span
            className="shareOptionLabel"
          // onChange={(e) => {}}
          >
            {props.title}
          </span>
        </div>
      </li>
    );
  };

  const handleExcel = React.useCallback(() => {
    if (!isExcel) {
      shareExcel(map(props.checked, "id"), (path) => {
        setExcelPath(`${BASE_DOWNLOAD_URL}${path}`);
      });
    } else {
      setExcelPath("");
    }
  }, [props.checked]);

  const WhatsAppShare = () => {
    const GET_IMAGES = [
      {
        key: "isImg",
        value: `${window.location.origin}/viewImage/RealImages/`,
      },
      {
        key: "isPlt",
        value: `${window.location.origin}/viewImage/PlottingImages/`,
      },
      {
        key: "isHa",
        value: `${window.location.origin}/viewImage/HeartImages/`,
      },
      {
        key: "isArrow",
        value: `${window.location.origin}/viewImage/HeartImages/`,
      },
      {
        key: "isAsset",
        value: `${window.location.origin}/viewImage/AssetScopeImages/`,
      },
      {
        key: "DarkField",
        value: `${window.location.origin}/viewImage/Dark_Field_White_BG/`,
      },
      {
        key: "Ideal",
        value: `${window.location.origin}/viewImage/IDEAL_White_BG/`,
      },
      {
        key: "flourescence",
        value: `${window.location.origin}/viewImage/FlsImages/`,
      },
      {
        key: "isFaceUp",
        value: `${window.location.origin}/viewImage/Office_Light_Black_BG/`,
      },
      {
        key: "isImg2",
        value: `${window.location.origin}/viewImage/viewer3Image/`,
      },
      {
        key: "isVideo",
        value: `${window.location.origin}/viewImage/viewer4Video/`,
      }, //viewer4Video
      {
        key: "isVideo2",
        value: `${window.location.origin}/viewImage/viewer3Video/`,
      }, //viewer3Video
      {
        key: "isCert",
        value: `${window.location.origin}/viewImage/CertiImages/`,
      }, //CertiImages
      {
        key: "typeIIA",
        value: `${window.location.origin}/viewImage/TYPE_IIA/`,
      }, //TYPE_IIA
      {
        key: "roughImage",
        value: `${window.location.origin}/viewImage/RoughImages/`,
      }, ///viewImage/RoughImages/${dnaData.id}
      {
        key: "roughVideo",
        value: `${window.location.origin}/viewImage/RoughVideo/`,
      },
      {
        key: "DImage",
        value: `${window.location.origin}/viewImage/PlanImages/`,
      },
    ];
    let links = [];

    // props.checked.map((i) => {
    //   GET_IMAGES.map((j) => {
    //     //
    //     if (isImg) {
    //
    //       links.push(j["value"] + i.id);
    //     }
    //   });
    // });

    props.checked.map((i) => {
      if (isImg) {
        links.push(`B2B Image : ${VIEW_DNA_URL}/viewImage/RealImages/${i.vStnId}`);
      }
      if (isPlt) {
        links.push(`Plotting : ${VIEW_DNA_URL}/viewImage/PlottingImages/${i.vStnId}`);
      }
      if (isHa || isArrow) {
        links.push(`Heart and Arrow : ${VIEW_DNA_URL}/viewImage/HeartImages/${i.vStnId}`);
      }
      if (isAsset) {
        links.push(`Asset Scope : ${VIEW_DNA_URL}/viewImage/AssetScopeImages/${i.vStnId}`);
      }
      if (DarkField) {
        links.push(`Dark - Field Image : ${VIEW_DNA_URL}/viewImage/Dark_Field_White_BG/${i.vStnId}`);
      }
      if (Ideal) {
        links.push(`Ideal Scope Image : ${VIEW_DNA_URL}/viewImage/IDEAL_White_BG/${i.vStnId}`);
      }
      if (flourescence) {
        links.push(`Flouresence Image : ${VIEW_DNA_URL}/viewImage/FlsImages/${i.vStnId}`);
      }
      if (isFaceUp) {
        links.push(
          `Face Up Image : ${VIEW_DNA_URL}/viewImage/Office_Light_Black_BG/${i.vStnId}`,
        );
      }
      if (isImg2) {
        links.push(`B2C Image : ${VIEW_DNA_URL}/viewImage/viewer3Image/${i.vStnId}`);
      }
      if (isVideo) {
        links.push(`B2B Video : ${VIEW_DNA_URL}/viewImage/viewer4Video/${i.vStnId}`);
      }
      if (isVideo2) {
        links.push(`B2C Video : ${VIEW_DNA_URL}/viewImage/viewer3Video/${i.vStnId}`);
      }
      if (isCert) {
        links.push(`Certificate : ${VIEW_DNA_URL}/viewImage/CertiImages/${i.vStnId}`);
      }
      if (typeIIA) {
        links.push(`Type IIA : ${VIEW_DNA_URL}/viewImage/TYPE_IIA/${i.vStnId}`);
      }
      if (roughImage) {
        links.push(`Rough Scope : ${VIEW_DNA_URL}/viewImage/RoughImages/${i.vStnId}`);
      }
      if (roughVideo) {
        links.push(`Rough Video : ${VIEW_DNA_URL}/viewImage/RoughVideo/${i.vStnId}`);
      }
      if (DImage) {
        links.push(`3D Image : ${VIEW_DNA_URL}/viewImage/PlanImages/${i.vStnId}`);
      }
    });
    if (isExcel) {
      links.push(excelPath);
    }
    window.open(`https://web.whatsapp.com/send?text=${join(links, "%0A%0A")}`);
  };

  const SkypeShare = () => {
    let links = [];
    props.checked.map((i) => {
      if (isImg) {
        links.push(`${VIEW_DNA_URL}/viewImage/RealImages/${i.vStnId}`);
      }
      if (isPlt) {
        links.push(`${VIEW_DNA_URL}/viewImage/PlottingImages/${i.vStnId}`);
      }
      if (isHa || isArrow) {
        links.push(`${VIEW_DNA_URL}/viewImage/HeartImages/${i.vStnId}`);
      }
      if (isAsset) {
        links.push(`${VIEW_DNA_URL}/viewImage/AssetScopeImages/${i.vStnId}`);
      }
      if (DarkField) {
        links.push(`${VIEW_DNA_URL}/viewImage/Dark_Field_White_BG/${i.vStnId}`);
      }
      if (Ideal) {
        links.push(`${VIEW_DNA_URL}/viewImage/IDEAL_White_BG/${i.vStnId}`);
      }
      if (flourescence) {
        links.push(`${VIEW_DNA_URL}/viewImage/FlsImages/${i.vStnId}`);
      }
      if (isFaceUp) {
        links.push(
          `${VIEW_DNA_URL}/viewImage/Office_Light_Black_BG/${i.vStnId}`,
        );
      }
      if (isImg2) {
        links.push(`${VIEW_DNA_URL}/viewImage/viewer3Image/${i.vStnId}`);
      }
      if (isVideo) {
        links.push(`${VIEW_DNA_URL}/viewImage/viewer4Video/${i.vStnId}`);
      }
      if (isVideo2) {
        links.push(`${VIEW_DNA_URL}/viewImage/viewer3Video/${i.vStnId}`);
      }
      if (isCert) {
        links.push(`${VIEW_DNA_URL}/viewImage/CertiImages/${i.vStnId}`);
      }
      if (typeIIA) {
        links.push(`${VIEW_DNA_URL}/viewImage/TYPE_IIA/${i.vStnId}`);
      }
      if (roughImage) {
        links.push(`${VIEW_DNA_URL}/viewImage/RoughImages/${i.vStnId}`);
      }
      if (roughVideo) {
        links.push(`${VIEW_DNA_URL}/viewImage/RoughVideo/${i.vStnId}`);
      }
      if (DImage) {
        links.push(`${VIEW_DNA_URL}/viewImage/PlanImages/${i.vStnId}`);
      }
    });
    if (isExcel) {
      links.push(excelPath);
    }
    window.open(`https://web.skype.com/share?url=${join(links, "%0A%0A")}`);
  };

  useEffect(() => {
    if (images) {
      setFlag(2);
    }
    if (!images) {
      setFlag(0);
    }
  }, [images]);

  useEffect(() => {
    if (videos) {
      setFlag(2);
    }
    if (!videos) {
      setFlag(0);
    }
  }, [videos]);

  useEffect(() => {
    if (certificates) {
      setFlag(2);
    }
    if (!certificates) {
      setFlag(0);
    }
  }, [certificates]);

  useEffect(() => {
    if (roughs) {
      setFlag(2);
    }
    if (!roughs) {
      setFlag(0);
    }
  }, [roughs]);

  useEffect(() => {
    handleExcel();
  }, [handleExcel]);

  //Update All Checks Handlers
  const updateAllImage = React.useCallback((check) => {
    setIsImg(check);
    setIsImg2(check);
    setIsPlt(check);
    setIsHa(check);
    setIsAsset(check);
    setIsFaceUp(check);
    setDarkField(check);
    setIdeal(check);
    setFlourescence(check);
    setIsArrow(check);
  }, []);

  const updateAllVideo = React.useCallback((allVideo) => {
    setIsVideo(allVideo);
    setIsVideo2(allVideo);
  }, []);

  const updateAllCertificate = React.useCallback((allCertificate) => {
    setIsCert(allCertificate);
    setTypeIIA(allCertificate);
  }, []);

  const updateAllRough = React.useCallback((allRough) => {
    setRoughVideo(allRough);
    setRoughimage(allRough);
    setDImage(allRough);
  }, []);


  //Selection Checks Handlers
  const checkAllImages = React.useCallback(
    (allImages = null) => {
      if (isBoolean(allImages)) {
        return updateAllImage(allImages);
      }

      if (
        isImg &&
        isImg2 &&
        isPlt &&
        isHa &&
        isAsset &&
        isArrow &&
        isFaceUp &&
        DarkField &&
        Ideal &&
        flourescence
      ) {
        setImages(true);
      }
      else setImages(false);
    },
    [
      DarkField,
      Ideal,
      flourescence,
      isArrow,
      isAsset,
      isFaceUp,
      isHa,
      isImg,
      isImg2,
      isPlt,
      updateAllImage,
    ],
  );

  const checkAllVideo = React.useCallback(
    (allVideo = null) => {
      if (isBoolean(allVideo)) return updateAllVideo(allVideo);
      if (isVideo && isVideo2) setVideos(true);
      else setVideos(false);
    },
    [isVideo, isVideo2, updateAllVideo],
  );

  const checkAllCertificate = React.useCallback(
    (allCertificate = null) => {
      if (isBoolean(allCertificate)) return updateAllCertificate(allCertificate);
      if (isCert && typeIIA) setCertificates(true);
      else setCertificates(false);
    },
    [isCert, typeIIA, updateAllCertificate],
  );

  const checkAllRoughs = React.useCallback(
    (allRough = null) => {
      if (isBoolean(allRough)) return updateAllRough(allRough);
      if (roughImage && roughVideo && DImage) setRoughs(true);
      else setRoughs(false);
    },
    [DImage, roughImage, roughVideo, updateAllRough],
  );

  React.useEffect(() => {
    checkAllImages();
  }, [checkAllImages]);

  React.useEffect(() => {
    checkAllVideo();
  }, [checkAllVideo]);

  React.useEffect(() => {
    checkAllCertificate();
  }, [checkAllCertificate]);

  React.useEffect(() => {
    checkAllRoughs();
  }, [checkAllRoughs]);

  React.useEffect(() => {
    // setDisableAll(isEmpty(props?.checked));
    if (isEmpty(props?.checked)) {
      checkAllRoughs(false);
      checkAllCertificate(false);
      checkAllVideo(false);
      checkAllImages(false);
    }
  }, [props?.checked]);

  return (
    <>
      <div>
        <div className="searchPopupCommon">
          <div className="shareOptionSet">
            <div className="shareOptionSetItem shareOptionImage">
              <ul>
                <div className="dropdownMainTitle">
                  <div className="dropdownIconCheckBox">
                    <Checkbox
                      checked={images}
                      onChange={() => checkAllImages(!images)}
                    ></Checkbox>
                  </div>
                  <span>Images : </span>
                </div>
                <div className="dropdownRightCheck">
                  <ShareOption
                    title="B2B Image"
                    checked={isImg}
                    //activeClass={isImg === true ? "active" : ""}
                    onChange={() => setIsImg(!isImg)}
                  />
                  <ShareOption
                    title="B2C Image"
                    checked={isImg2}
                    onChange={() => setIsImg2(!isImg2)}
                  />
                  <ShareOption
                    title="Plotting"
                    checked={isPlt}
                    onChange={() => setIsPlt(!isPlt)}
                  />
                  <ShareOption
                    title="Heart & Arrow"
                    checked={isHa}
                    onChange={() => setIsHa(!isHa)}
                  />
                  <ShareOption
                    title="Asset Scope"
                    checked={isAsset}
                    onChange={() => setIsAsset(!isAsset)}
                  />
                  <ShareOption
                    title="Arrow Image"
                    checked={isArrow}
                    onChange={() => setIsArrow(!isArrow)}
                  />
                  <ShareOption
                    title="Face Up Image"
                    checked={isFaceUp}
                    onChange={() => setIsFaceUp(!isFaceUp)}
                  />
                  <ShareOption
                    title="Dark - Field Image"
                    checked={DarkField}
                    onChange={() => setDarkField(!DarkField)}
                  />
                  <ShareOption
                    title="Ideal Scope Image"
                    checked={Ideal}
                    onChange={() => setIdeal(!Ideal)}
                  />
                  <ShareOption
                    title="Flouresence Image"
                    checked={flourescence}
                    onChange={() => setFlourescence(!flourescence)}
                  />
                </div>
              </ul>
              <ul>
                <div className="dropdownMainTitle">
                  <div className="dropdownIconCheckBox">
                    <Checkbox
                      checked={videos}
                      onChange={() => checkAllVideo(!videos)}
                    ></Checkbox>
                  </div>
                  <span>Video : </span>
                </div>
                <div className="dropdownRightCheck">
                  <ShareOption
                    title="B2B Video"
                    checked={isVideo}
                    onChange={() => setIsVideo(!isVideo)}
                  />
                  <ShareOption
                    title="B2C Video"
                    checked={isVideo2}
                    onChange={() => setIsVideo2(!isVideo2)}
                  />
                </div>
              </ul>
              <ul className="d-flex">
                <div className="width-50 d-flex align-items-center">
                  <div className="dropdownMainTitle">
                    <div className="dropdownIconCheckBox">
                      <Checkbox
                        checked={certificates}
                        onChange={() => checkAllCertificate(!certificates)}
                      ></Checkbox>
                    </div>
                    <span>Certificate : </span>
                  </div>
                  <div className="dropdownRightCheck">
                    <ShareOption
                      title="Certificate"
                      checked={isCert}
                      onChange={() => setIsCert(!isCert)}
                    />
                    <ShareOption
                      title="Type IIA"
                      checked={typeIIA}
                      onChange={() => setTypeIIA(!typeIIA)}
                    />
                  </div>
                </div>
                <div className="width-50 d-flex align-items-center excelShare">
                  <div className="dropdownMainTitle">
                    <span>Excel : </span>
                  </div>
                  <div className="dropdownRightCheck">
                    <div>
                      <ShareOption
                        checked={isExcel}
                        onChange={() => {
                          setIsExcel(!isExcel);
                        }}
                        // onChange={() => isExcel ? shareExcel(map(props.checked, 'id')) : ''}
                        //onChange={() => setIsExcel(!isExcel)}
                        title="Excel"
                      />
                    </div>
                  </div>
                </div>
              </ul>
              <ul>
                <div className="dropdownMainTitle">
                  <div className="dropdownIconCheckBox">
                    <Checkbox
                      checked={roughs}
                      onChange={() => checkAllRoughs(!roughs)}
                    ></Checkbox>
                  </div>
                  <span>Rough : </span>
                </div>
                <div className="dropdownRightCheck">
                  <ShareOption
                    title="Rough Scope"
                    checked={roughImage}
                    onChange={() => setRoughimage(!roughImage)}
                  />
                  <ShareOption
                    title="3D Image"
                    checked={DImage}
                    onChange={() => setDImage(!DImage)}
                  />
                  <ShareOption
                    title="Rough Video"
                    checked={roughVideo}
                    onChange={() => setRoughVideo(!roughVideo)}
                  />
                </div>
              </ul>
            </div>
          </div>
          <div className="commonModalButton mt-50">
            {/* {USER_PERMIT && USER_PERMIT.SHARE_VIA_MAIL.view && ( */}
            <button
              className="fillButton"
              onClick={() => {
                if (
                  isAsset ||
                  isHa ||
                  isCert ||
                  isExcel ||
                  isImg ||
                  isPlt ||
                  isVideo ||
                  isArrow ||
                  isImg2 ||
                  typeIIA ||
                  isFaceUp ||
                  DarkField ||
                  Ideal ||
                  flourescence ||
                  isVideo2 ||
                  roughImage ||
                  roughVideo ||
                  DImage
                ) {
                  setSendEmail(true);
                } else {
                  OpenNotification({
                    type: "error",
                    title: "Please Select atleast 1 Option to Share.",
                  });
                }
                //props.onCloseSharePopup();
              }}
            >
              Email
            </button>
            {/* )} */}
            {/* {USER_PERMIT && USER_PERMIT.SHARE_VIA_WHATSAPP.view && ( */}
            <button onClick={WhatsAppShare} className="fillButton ml-5">
              WhatsApp
            </button>
            {/* )} */}
            {/* {USER_PERMIT && USER_PERMIT.SHARE_VIA_SKYPE.view && ( */}
            <button onClick={SkypeShare} className="fillButton ml-5">
              Skype
            </button>
            {/* )} */}
          </div>
        </div>
      </div>
      <SendEmailPopup
        sendEmail={sendEmail}
        onCancel={() => setSendEmail(!sendEmail)}
        ids={
          props.orderDiamond
            ? map(props.checked, "diamondId")
            : map(props.checked, "id")
        }
        certFile={isCert}
        hAFile={isHa}
        img={isImg}
        isPlt={isPlt}
        isReal={isImg}
        assetFile={isAsset}
        videoFile={isVideo}
        isExcel={isExcel}
        arrowFile={isArrow}
        mp4Video={isVideo2}
        type2={typeIIA}
        roughVideo={roughVideo}
        darkFieldImage={DarkField}
        flsImage={flourescence}
        idealWhiteImage={Ideal}
        roughImage={roughImage}
        planImg={DImage}
        faceUp={isFaceUp}
        b2cRealImage={isImg2}
        checked={props.checked}
        orderDiamond={
          window.location.pathname === "/account/confirm-list" ? true : false
        }
        handleLoader={props?.handleLoader}
      />
    </>
  );
};
export default connect((props) => {
  return props;
}, {})(withRouter(ShareDropdown));
