/* eslint-disable */
import React from "react";
import GuestLoginFrom from "./Auth/GuestLoginForm";
import AuthPoster from "./Auth/AuthPoster";

const GuestLogin = () => {
  return (
    <>
      <div className="loginMainWrapper guest-login">
        <AuthPoster />
        <div className="LoginRightBlock">
          <GuestLoginFrom />
        </div>
      </div>
    </>
  );
};

export default GuestLogin;
