/* eslint-disable */
import React from "react";
import CommonModal from "../CommonModal";
import UtilService from "../../../services/util";
import _ from "lodash";
import { getProjectSettingList } from "components/DiamondList/DiamondListFunctions";
import { PROJECT_SETTINGS_TYPE } from "constants/Common";
import isEmpty from "lodash/isEmpty";

function ConfirmStoneModal({ checked, handleCancel, handleOk }) {
  let bs = "";
  let blockSettings = UtilService.getLocalStorageItem("popUpValidationMsg");
  if (isEmpty(blockSettings)) {
    getProjectSettingList(
      PROJECT_SETTINGS_TYPE.POP_UP_VALIDATION_MESSAGES,
      (data) => {
        UtilService.setLocalStorageItem(
          data?.data.find(
            ({ type }) =>
              type === PROJECT_SETTINGS_TYPE.POP_UP_VALIDATION_MESSAGES,
          )?.data,
          "popUpValidationMsg",
        );
      },
    );
    bs = UtilService.getLocalStorageItem("popUpValidationMsg");
  }
  let CONFIRM = blockSettings?.["CONFIRM"];
  let NOTE = blockSettings?.["NOTE"];
  let indStn = "";
  let usaStn = "";
  let hkStn = "";
  let otherStn = "";
  const otherCountry = [];

  checked.forEach((x) => {
    if (x.locNm === "INDIA") indStn += ` ${x.vStnId}, `;
    // if (x.locNm === 'USA') usaStn += `${x.vStnId}, `;
    // if (x.locNm === 'HONGKONG') hkStn += `${x.vStnId}, `;
    if (x.locNm !== "INDIA") {
      otherStn += `${x.vStnId}, `;
      otherCountry.push(x.locNm);
    }
  });
  let msgs = [];
  let notes = [];
  const comStn = usaStn + ", " + hkStn;
  if (indStn) {
    msgs.push(
      CONFIRM?.INDIA?.replaceAll("$$[vStnId]$$", indStn)
        .replaceAll("$$[locNm]$$", "India")
        .replaceAll(", ,", ", "),
    );
    notes.push(
      NOTE?.INDIA?.replaceAll("$$[vStnId]$$", indStn)
        .replaceAll("$$[locNm]$$", "India")
        .replaceAll(", ,", ", "),
    );
  }
  if (otherStn) {
    msgs.push(
      CONFIRM?.HK?.replaceAll("$$[vStnId]$$", otherStn)
        .replaceAll("$$[locNm]$$", _.uniq(otherCountry).join("/"))
        .replaceAll(", ,", ", "),
    );
    notes.push(
      NOTE?.HK?.replaceAll("$$[vStnId]$$", otherStn)
        .replaceAll("$$[locNm]$$", _.uniq(otherCountry).join("/"))
        .replaceAll(", ,", ", "),
    );
  }

  const inStones = !!checked.find((c) => c?.locNm.toUpperCase() === "INDIA");
  const ninStones = !!checked.find((c) => c?.locNm.toUpperCase() !== "INDIA");
  const mixedStones = inStones && ninStones;

  bs && window.location.reload();

  return (
    <CommonModal
      title="Disclaimer"
      submitTitle={mixedStones ? "Proceed with India Stone(s)" : "I Agree"}
      cancelTitle="Quit"
      toggle={handleOk}
      visible={true}
      closable={false}
      handleOk={handleOk}
      handleCancel={handleCancel}
      footerShow
    >
      <div className="detailShow d-flex flex-wrap">
        <div className="detail w-100">
          <span>
            {msgs?.map((x) => (
              <p dangerouslySetInnerHTML={{ __html: x }}></p>
            ))}
            {notes?.map((x) => (
              <p dangerouslySetInnerHTML={{ __html: x }}></p>
            ))}
          </span>
        </div>
      </div>
    </CommonModal>
  );
}

export default ConfirmStoneModal;
