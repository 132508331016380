/* eslint-disable */
import React, { Component } from "react";
import Heading from "../Heading";
import { HeadingCalc } from "./ConfirmStonePopup";
import { ListingCards } from "./MobileComfirmStone";
import IntlMessages from "../../../util/IntlMessages";

class BIDStoneMobile extends Component {
  render() {
    return (
      <div className="offerWrapper">
        <div className="d-flex align-items-center justify-space-between offerTopBlock">
          <div className="d-flex align-items-center">
            <Heading
              className="popupInnerTitle"
              title={<IntlMessages id="app.bidStone" />}
            ></Heading>
          </div>
          {HeadingCalc(
            { display: "flex", flexDirection: "row-reverse", width: "48%" },
            this.props.VALUES,
          )}
        </div>
        <div className="searchInnerResult tabInnerTable">
          <div className="diamondListMobile mobileOfferScroll">
            <ListingCards
              bidPopup={this.props.parent}
              checked={this.props.checked}
              data={this.props.data}
              currentType={this.props.currentType}
            />
          </div>
          <div className="d-flex justify-content-between offerBottomBox flex-wrap"></div>
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={this.props.parent.submit}>
            <IntlMessages id="app.bidStone" />
          </a>
          <a className="commonButton" onClick={this.props.onClose}>
            <IntlMessages id="app.CancelBid" />
          </a>
        </div>
      </div>
    );
  }
}

export default BIDStoneMobile;
