/* eslint-disable */
import React, { Component } from "react";
import IntlMessages from "../../util/IntlMessages";
import { connect } from "react-redux";
import { Radio } from "antd";
import _ from "lodash";

class KeyToSymbol extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      selected: [],
      key: "nin",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.commonData.reset && nextProps.commonData.reset.searchReset) {
      this.setState({ selected: [], key: "in" });
    }

    if (
      nextProps.commonData.modifySearch &&
      this.props.commonData.modifySearch !== nextProps.commonData.modifySearch
    ) {
      if (nextProps.commonData.modifySearch["kToSArr"]) {
        let modifyData = nextProps.commonData.modifySearch["kToSArr"];
        let key = Object.keys(modifyData)[0];
        this.handleSelection(modifyData[key], true);
        this.setState({ selected: modifyData[key], key: key });
      }
    }
  }

  componentDidMount() {
    if (this.props.commonData.modifySearch) {
      if (this.props.commonData.modifySearch["kToSArr"]) {
        let modifyData = this.props.commonData.modifySearch["kToSArr"];
        let key = Object.keys(modifyData)[0];
        this.handleSelection(modifyData[key], true);
        this.setState({ selected: modifyData[key], key: key });
      }
    }
  }

  handleSelection(selValues, checked) {
    let selected = [];
    if (selValues === "") {
      this.setState({ selectAll: checked });
      _.each(this.props.data, function (data) {
        selected = _.concat(selected, data.id);
      });
    } else {
      selected = this.state.selected;
      selected = _.concat(selected, selValues);
    }

    if (!checked) {
      if (selValues !== "") {
        selected = selValues;
      }
      selected = _.filter(this.state.selected, function (val) {
        return selected.indexOf(val) === -1;
      });
    }

    this.setState(
      {
        selected: selected,
        selectAll: selected?.length === this.props?.data?.length,
      },
      () => {
        this.props.selectedKeyToSymbol(this.state.selected, this.state.key);
      },
    );
  }

  render() {
    const self = this;
    const data = this.props.data;

    return (
      <div className="diamondSearchItem b-b-0">
        <div className="diamondSearchDetail width-100">
          <Radio.Group
            onChange={(e) => {
              this.setState({ key: e.target.value }, () => {
                this.props.selectedKeyToSymbol(
                  this.state.selected,
                  this.state.key,
                );
              });
            }}
          >
            <Radio value="in" checked={this.state.key === "in" ? true : false}>
              <IntlMessages id="app.Contains" />
            </Radio>
            <Radio
              value="nin"
              checked={this.state.key === "nin" ? true : false}
            >
              <IntlMessages id="app.Doesnotcontains" />
            </Radio>
          </Radio.Group>
          <ul className="diamondSearchList mt-10">
            <li
              className={self.state.selectAll ? `active` : ''}
              onClick={() => self.handleSelection("", !self.state.selectAll)}
            >
              <strong>Select All</strong>
            </li>
            {data &&
              data.map((k, index) => {
                k.checked = false;
                _.each(k.id, function (id) {
                  if (self.state.selected && self.state.selected.includes(id)) {
                    k.checked = true;
                  }
                });
                return (
                  <li
                    onClick={() => self.handleSelection(k.id, !k.checked)}
                    className={k.checked ? `active` : ''}
                  >
                    {k.webDisplay}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (props) => {
  return props;
};
export default connect(mapStateToProps)(KeyToSymbol);
