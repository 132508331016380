import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import Auth from "./Auth";
import Commonm from "./Common";
import Diamond from "./diamond";
import DeleteReminder from "./deleteReminder";
import setTimer from "./timer";
import DashData from "./DashData";

const reducers = combineReducers({
  routing: routerReducer,
  auth: Auth,
  commonData: Commonm,
  diamondData: Diamond,
  deleteReminder: DeleteReminder,
  setTimer: setTimer,
  sales: DashData,
});

export default reducers;
