/* eslint-disable */
import React, { Component } from "react";
import { HeadingCalc } from "./ConfirmStonePopup";
import Heading from "../Heading";
import { formatNumber } from "./SelectStone";
import {
  getParams,
  LISTINGPAGES,
} from "../../DiamondList/DiamondListFunctions";
import { STATUS_COLOR } from "../DiamondListing/Status";

class NotePopupMobile extends Component {
  render() {
    return (
      <div className="offerWrapper">
        <div className="d-flex align-items-center justify-space-between offerTopBlock">
          <div className="d-flex align-items-center">
            <Heading className="popupInnerTitle" title="Add Note"></Heading>
          </div>
          {HeadingCalc(this.props.checked)}
        </div>
        <div className="searchInnerResult tabInnerTable">
          <div className="diamondListMobile mobileOfferScroll">
            {this.props.checked.map((data, key) => {
              return (
                <div
                  key={key}
                  className={`diamodListItemWrapper ${
                    STATUS_COLOR[data?.wSts]
                      ? STATUS_COLOR[data?.wSts]
                      : "green"
                  } ${
                    this.props?.activeClass ? this.props?.activeClass : null
                  } ${this.props?.quotePage ? "quotePageWrapper" : null}`}
                >
                  <div className="mobileListHeade" onClick={this.props.onClick}>
                    <div className="mobileListHeadeBlock">
                      <a
                        className="mobileListItem"
                        href={"/" + LISTINGPAGES.DETAIL + "/" + data?.id}
                      >
                        <span>{data?.vStnId}</span>
                      </a>
                      <div className="mobileListItem">
                        <span>{data?.shpNm}</span>
                      </div>
                      <div className="mobileListItem">
                        <span>
                          {formatNumber(parseFloat(data?.back).toFixed(2))}%
                        </span>
                      </div>
                      <div className="mobileListItem themeColor">
                        <span>
                          {formatNumber(parseFloat(data?.ctPr).toFixed(2))}$/Cts
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mobileDiamondListBottom"
                    onClick={this.props.onClick}
                  >
                    <div className="mobileListHeadeBlock">
                      <div className="mobileListItem basicDetail">
                        <span>
                          {getParams(data).join(" ")} {data?.lbNm}
                        </span>
                      </div>
                      <div className="mobileListItem">
                        <span>
                          {formatNumber(parseFloat(data?.crt).toFixed(2))}
                        </span>
                      </div>
                      <div className="mobileListItem">
                        <span>{data?.msrmnt}</span>
                      </div>
                      <div className="mobileListItem totalPrice">
                        <span>
                          {formatNumber(parseFloat(data?.amt).toFixed(2))} $/Amt
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex j-space-between quoteExtraDetail">
                    <div className="quoteExtraItem">
                      <div className="offerInputBox width-100">
                        <span>Comment:</span>
                        <input
                          className="width-10 height-10"
                          label="Comment"
                          placeholder="Comment"
                          value={data.note}
                          onChange={(e) =>
                            this.props.parent.ipValChange(
                              e.target.value,
                              data.id,
                            )
                          }
                        />
                      </div>{" "}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={this.props.parent.submit}>
            Add Note
          </a>
          <a className="commonOutline" onClick={this.props.onClose}>
            Cancel
          </a>
        </div>
      </div>
    );
  }
}

export default NotePopupMobile;
