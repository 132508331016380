/* eslint-disable */
import React, { useEffect, useState } from "react";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import _ from "lodash";

import "../common/Tab/tab.less";
import "./FTC.less";
import CommonModal from "../common/CommonModal";
import DownloadOption from "./Download";
import UtilService from "../../services/util";
import { DIAMOND_FTC, GET_SHIPMENT_DETAILS } from "../../constants/apiConstant";
import { MEDIA_URL, LOCAL_STORAGE_VAR } from "../../constants/Common";

const measurements = [
  { name: "Table %", key: "tblPer" },
  { name: "Depth %", key: "depPer" },
  { name: "Length", key: "length" },
  { name: "Width", key: "width" },
  { name: "Depth", key: "height" },
  { name: "Ratio", key: "ratio" },
  { name: "Crown Angle", key: "cAng" },
  { name: "Crown Height", key: "cHgt" },
  { name: "Pav Angle", key: "pAng" },
  { name: "Pav Height", key: "pHgt" },
  { name: "Girdle", key: "girdleStr" },
  { name: "Culet", key: "cultNm" },
];

const others = [
  { name: "Center Natts", key: "blkTblNm" },
  { name: "Side Natts", key: "blkSdNm" },
  { name: "Center White", key: "wTblNm" },
  { name: "Side White", key: "wSdNm" },
  { name: "Table Open", key: "opTblNm" },
  { name: "Crown Open", key: "opCrwnNm" },
  { name: "Pavillion Open", key: "opPavNm" },
  { name: "Eye Clean", key: "eClnNm" },
  { name: "Heart & Arrow", key: "hANm" },
  { name: "Brilliancy", key: "brlncyNm" },
  { name: "Type2 Cert", key: "type2Nm" },
  { name: "Laser Ins.", key: "lsrInc" },
];

const FTC = (props) => {
  const [visible, setVisible] = useState("");
  const [ftcData, setFTCData] = useState({});
  const [dwnld, setDownload] = useState(false);
  const [defaultTab, setDefaultTab] = useState(0);
  const [cert, setCert] = useState(false);
  const [polVideo, setPolVideo] = useState(false);
  const [rVideo, setRoughVideo] = useState(false);

  let polParams = `${ftcData.mfgWgt ? `${ftcData.mfgWgt}ct` : `-` || "-"} ${
    ftcData.mfgColor || "-"
  } ${ftcData.mfgPurity || "-"} ${ftcData.mfgCut || "-"} ${
    ftcData.mfgPolish || "-"
  } ${ftcData.mfgSymm || "-"} ${ftcData.mfgFls || "-"}`;
  let gradParams = `${ftcData.qcWgt ? `${ftcData.qcWgt}ct` : `-` || "-"} ${
    ftcData.qcColor || "-"
  } ${ftcData.qcPurity || "-"} ${ftcData.qcCut || "-"} ${
    ftcData.qcPolish || "-"
  } ${ftcData.qcSymm || "-"} ${ftcData.qcFls || "-"}`;
  let certParams = `${ftcData.crt ? `${ftcData.crt}ct` : `-` || "-"} ${
    ftcData.colNm || "-"
  } ${ftcData.clrNm || "-"} ${ftcData.cutNm || "-"} ${ftcData.polNm || "-"} ${
    ftcData.symNm || "-"
  } ${ftcData.fluNm || "-"}`;
  let roughImgId = ftcData.vStnId && ftcData.vStnId.slice(0, -1) + "0";
  let polishVideo = `${MEDIA_URL}/Mov/${ftcData.vStnId}.mp4`;

  const polishedTable = () => {
    return (
      <table>
        <tr>
          <td colSpan="4">
            <div>GIA Report No : {ftcData.rptNo}</div>
          </td>
        </tr>
        <tr>
          <td>LAB</td>
          <td>{ftcData.lbNm}</td>
          <td>CUT</td>
          <td>{ftcData.cutNm}</td>
        </tr>
        <tr>
          <td>SHAPE</td>
          <td>{ftcData.shpNm}</td>
          <td>POLISH</td>
          <td>{ftcData.polNm}</td>
        </tr>
        <tr>
          <td>CARAT</td>
          <td>{ftcData.crt}</td>
          <td>SYMMETRY</td>
          <td>{ftcData.symNm}</td>
        </tr>
        <tr>
          <td>COLOR</td>
          <td>{ftcData.colNm}</td>
          <td>FLUORESCENCE</td>
          <td>{ftcData.fluNm}</td>
        </tr>
        <tr>
          <td>CLARITY</td>
          <td>{ftcData.clrNm}</td>
          <td>SHADE</td>
          <td>{ftcData.shdNm}</td>
        </tr>
        <tr>
          <td>KEY TO SYMBOLS</td>
          <td colSpan="3">{ftcData.kToSStr || "-"}</td>
        </tr>
        <tr>
          <td>REPORT COMMENTS</td>
          <td colSpan="3">{ftcData.lbCmt || "-"}</td>
        </tr>
      </table>
    );
  };

  const otherDetailsTable = () => {
    return (
      <table>
        <tr>
          <td style={{ border: "none" }}>
            <table>
              <tr>
                <td colSpan="2" className="tableHead">
                  Measurements
                </td>
              </tr>
              {measurements.map((m) => {
                return (
                  <tr>
                    <td>{m.name}</td>
                    <td>{ftcData[m.key]}</td>
                  </tr>
                );
              })}
            </table>
          </td>
          <td style={{ border: "none" }}>
            <table>
              <tr>
                <td colSpan="2" className="tableHead">
                  Inclusion & Other Details
                </td>
              </tr>
              {others.map((o) => {
                return (
                  <tr>
                    <td>{o.name}</td>
                    <td>{ftcData[o.key]}</td>
                  </tr>
                );
              })}
            </table>
          </td>
        </tr>
      </table>
    );
  };

  const getImageBox = (img, visi, title) => {
    let exDetails = visi === "iScope" || visi === "aScope";
    return (
      <div className="imgBox">
        <img
          onClick={() => setVisible(visi)}
          src={img}
          alt=""
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = require("../../assets/svg/noimage.svg");
          }}
        />
        <span>{title}</span>
        {visible === visi && (
          <div className="modalPopup">
            <ReactImageVideoLightbox
              data={[
                {
                  url: img,
                  type: "photo",
                  altTag: "some image",
                },
              ]}
              startIndex={0}
              showResourceCount={false}
              onCloseCallback={() => setVisible("")}
            />
          </div>
        )}
        {exDetails && (
          <div className="ftcColorBox">
            <div className="ftcColorBoxItem">
              <span
                className="bottomline"
                style={{
                  backgroundColor: "#d8173d",
                }}
              ></span>
              <span className="ftcColorTitle">Brightness</span>
            </div>
            <div className="ftcColorBoxItem">
              <span className="bottomline"></span>
              <span className="ftcColorTitle">Light Leakage</span>
            </div>
            <div className="ftcColorBoxItem">
              <span
                className="bottomline"
                style={{
                  backgroundColor: "#685c5c",
                }}
              ></span>
              <span className="ftcColorTitle">Contrast</span>
            </div>
            {visi === "aScope" && (
              <div className="ftcColorBoxItem">
                <span
                  style={{
                    backgroundColor: "#008000",
                  }}
                  className="bottomline"
                ></span>
                <span className="ftcColorTitle">Less Bright</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const getAdvanceImageBox = (img, visi) => {
    return (
      <>
        <img
          onClick={() => setVisible(visi)}
          style={{ maxWidth: "112px" }}
          src={img}
          alt=""
        />
        {visible === visi && (
          <div className="modalPopup">
            <ReactImageVideoLightbox
              data={[
                {
                  url: img,
                  type: "photo",
                  altTag: "some image",
                },
              ]}
              startIndex={0}
              showResourceCount={false}
              onCloseCallback={() => setVisible("")}
            />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    let id = _.last(_.split(window.location.pathname, "/"));
    let self = this;
    let obj = {
      filter: {
        vStnId: id,
      },
    };

    let objData = {
      ...DIAMOND_FTC,
      request: obj,
    };

    UtilService.callApi(objData, function (err, data) {
      if (err) throw err;
      else if (data && data.code === "OK") {
        setFTCData(data.data);
      }
    });
  }, []);

  return (
    <>
      {ftcData.length > 0 && (
        <div className="ftcBoxWrapper">
          <Tabs selectedIndex={defaultTab}>
            <TabList className="customerMaintab">
              <Tab onClick={() => setDefaultTab(0)}>Advance Certificate</Tab>
              <Tab onClick={() => setDefaultTab(1)}>Basic Certificate</Tab>
              <div
                onClick={() => {
                  const isLoggedInUser = UtilService.getLocalStorageItem(
                    `${LOCAL_STORAGE_VAR}-token`,
                  );
                  if (!isLoggedInUser || !isLoggedInUser.length) {
                    window.location.href = "/signin";
                  } else setDownload(true);
                }}
                className="ftcDownloadIcon"
              >
                <div className="ftcIconBox">
                  <img
                    src={require("../../assets/svg/download-white.svg")}
                    alt=""
                  />
                  <span>Download</span>
                </div>
              </div>
            </TabList>
            <TabPanel>
              <div className="certificateBox">
                <div className="certificateBoxWrapper">
                  {/* <div className="certificateBoxTop d-flex align-items-center j-space-between">
                    <div className="certificatImage">
                      <img
                        src={require("../../assets/img/FTC/logo1.png")}
                        alt=""
                      />
                    </div>
                    <div className="certificatImageLogo">
                      <img
                        src={require("../../assets/img/FTC/logo.png")}
                        alt=""
                      />
                    </div>
                  </div> */}
                  <div className="advanceBlock d-flex">
                    <div className="advanceBlockThree w-100-m">
                      <div className="xrayDetailInnerItem ftcBlockDetail mt-20">
                        <div className="tableHighlight">
                          <div className="diamondDetailMoreHalf">
                            <h2 className="tableHead">
                              Polished Properties (As per {`${ftcData.lbNm}`})
                            </h2>
                            <ul className="ftcTableBlock">{polishedTable()}</ul>
                          </div>
                          <div className="xrayDetailInnerItem tableinTableBlock ftcBlockDetail">
                            <div className="diamondDetailMoreHalf  d-flex j-space-between">
                              <ul className="ftcTableBlock">
                                {otherDetailsTable()}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="diamondDetailMoreHalf">
                            <h2 className="tableHead">Dimensions</h2>
                            <div className="ftcTableBlock">
                              <table className="tableRaduis">
                                <tr>
                                  <td>
                                    <img
                                      className="giaImag"
                                      src={`${MEDIA_URL}/DiagramImages/${ftcData.rptNo}.jpg`}
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = require("../../assets/svg/noimage.svg");
                                      }}
                                      alt=""
                                    />
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="diamondDetailMoreHalf">
                            <h2 className="tableHead">plotting</h2>
                            <div className="ftcTableBlock">
                              <table className="tableRaduis">
                                <tr>
                                  <td>
                                    <img
                                      className="giaImag"
                                      src={`${MEDIA_URL}/PlottingImages/${ftcData.rptNo}.gif`}
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = require("../../assets/svg/noimage.svg");
                                      }}
                                      alt=""
                                    />
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="diamondDetailMoreHalf">
                            <h2 className="tableHead">Other Images</h2>
                            <div className="ftcTableBlock">
                              <table className="tableRaduis">
                                <tr>
                                  <td>
                                    <ul className="ftcImageBlock d-flex">
                                      {getImageBox(
                                        `${MEDIA_URL}/HeartImages/${ftcData.vStnId}.jpg`,
                                        "hrt",
                                        "Heart",
                                      )}

                                      {getImageBox(
                                        `${MEDIA_URL}/ArrowImages/${ftcData.vStnId}.jpg`,
                                        "arr",
                                        "ARROW",
                                      )}
                                    </ul>
                                    <ul className="ftcImageBlock">
                                      <div
                                        className="d-flex"
                                        style={{ justifyContent: "center" }}
                                      >
                                        {getImageBox(
                                          `${MEDIA_URL}/IDEAL_White_BG/${ftcData.vStnId}.jpg`,
                                          "iScope",
                                          "IDEAL SCOPE",
                                        )}

                                        {getImageBox(
                                          `${MEDIA_URL}/AssetScopeImages/${ftcData.vStnId}.jpg`,
                                          "aScope",
                                          "ASET SCOPE",
                                        )}
                                      </div>
                                      <p>
                                        IDEAL scope and ASSET Scope are simple
                                        tools used to evaluate the light
                                        Performance of a diamond. A diamond that
                                        reflect a lot of light back to the
                                        viewer will be full of brilliance, fire
                                        and scintillation.
                                      </p>
                                      {getImageBox(
                                        `${MEDIA_URL}/FlsImages/${ftcData.vStnId}.jpg`,
                                        "flr",
                                        "Fluorescence",
                                      )}
                                    </ul>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="advanceBlockThree advanceStipBlock">
                      <table id="tbl2">
                        <tbody>
                          <tr>
                            <td colSpan="2" className="imgpaddingbott">
                              <div className="imageheight">
                                <img
                                  src={require("../../assets/img/FTC/MINING.png")}
                                  alt=""
                                />
                              </div>
                              <div className="vertical1"></div>
                              <div className="advacneStripBlock">
                                <div className="advacneStripBlockItem">
                                  <h4>MINING</h4>
                                </div>
                                <div className="advacneStripBlockItem">
                                  <div>
                                    Country of Origin :{" "}
                                    <span>{ftcData.org}</span>
                                  </div>
                                </div>

                                <div className="advacneStripBlockItem">
                                  <div>
                                    Mine of Origin :{" "}
                                    <span>{ftcData.mines}</span>
                                  </div>
                                </div>
                                <div className="advacneStripBlockItem">
                                  {ftcData.roughWgt && (
                                    <div>
                                      WEIGHT : <span>{ftcData.roughWgt}ct</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="imgpaddingbott">
                              <div className="imageheight">
                                <img
                                  src={require("../../assets/img/FTC/MANUFACTURING.png")}
                                  alt=""
                                />
                              </div>
                              <div className="vertical1"></div>
                              <div className="advacneStripBlock">
                                <div className="advacneStripBlockItem">
                                  <h4>MANUFACTURING</h4>
                                </div>
                                <div className="advacneStripBlockItem">
                                  {ftcData.roughWgt && (
                                    <div>
                                      WEIGHT : <span>{ftcData.roughWgt}ct</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="imgpaddingbott">
                              <div className="imageheight">
                                <img
                                  src={require("../../assets/img/FTC/ASSORTING.png")}
                                  alt=""
                                />
                              </div>
                              <div className="vertical1"></div>
                              <div className="advacneStripBlock">
                                <div className="advacneStripBlockItem">
                                  <h4>ASSORTING</h4>
                                </div>
                                <p>
                                  Analysis and scanning by Rough Assortment
                                  Specialist
                                </p>
                                <div className="advacneStripBlockItem">
                                  {ftcData.roughWgt && (
                                    <div>
                                      WEIGHT : <span>{ftcData.roughWgt}ct</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="imgpaddingbott">
                              <div className="imageheight">
                                <img
                                  src={require("../../assets/img/FTC/MAPPING.png")}
                                  alt=""
                                />
                              </div>
                              <div className="vertical1"></div>
                              <div className="advacneStripBlock">
                                <div className="advacneStripBlockItem">
                                  <h4>MAPPING</h4>
                                </div>
                                <p>
                                  Shape & Size is mapped with Advanced
                                  Technology by planning Technician
                                </p>
                                <div className="advacneStripBlockItem">
                                  {ftcData.mfgWgt && (
                                    <div>
                                      WEIGHT : <span>{ftcData.mfgWgt}ct</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="imgpaddingbott">
                              <div className="imageheight">
                                <img
                                  src={require("../../assets/img/FTC/LASER-CUTTING.png")}
                                  alt=""
                                />
                              </div>
                              <div className="vertical1"></div>
                              <div className="advacneStripBlock">
                                <div className="advacneStripBlockItem">
                                  <h4>LASER CUTTING</h4>
                                </div>
                                <p>Splitting by Laser Cutting Technician</p>
                                <div className="advacneStripBlockItem">
                                  {ftcData.laserWgt && (
                                    <div>
                                      WEIGHT AFTER SPLITTING :{" "}
                                      <span>{ftcData.laserWgt}ct</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="imgpaddingbott">
                              <div className="imageheight">
                                <img
                                  src={require("../../assets/img/FTC/LASER-CONNING.png")}
                                  alt=""
                                />
                              </div>
                              <div className="vertical1"></div>
                              <div className="advacneStripBlock">
                                <div className="advacneStripBlockItem">
                                  <h4>LASER CONNING</h4>
                                </div>
                                <p>Conning by Laser Cutting Technician</p>
                                <div className="advacneStripBlockItem">
                                  {ftcData.coningWgt && (
                                    <div>
                                      WEIGHT AFTER CONNING:{" "}
                                      <span>{ftcData.coningWgt}ct</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="imgpaddingbott">
                              <div className="imageheight">
                                <img
                                  src={require("../../assets/img/FTC/POLISHING.png")}
                                  alt=""
                                />
                              </div>
                              <div className="vertical1"></div>
                              <div className="advacneStripBlock">
                                <div className="advacneStripBlockItem">
                                  <h4>POLISHING</h4>
                                </div>
                                <p>
                                  The Rough diamond is faceted by a Skilled
                                  Polishing Artisan
                                </p>
                                <div className="advacneStripBlockItem">
                                  <div>{polParams}</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="imgpaddingbott">
                              <div className="imageheight">
                                <img
                                  src={require("../../assets/img/FTC/GRADING.png")}
                                  alt=""
                                />
                              </div>
                              <div className="vertical1"></div>
                              <div className="advacneStripBlock">
                                <div className="advacneStripBlockItem">
                                  <h4>GRADING</h4>
                                </div>
                                <p>
                                  Multiple Parameters beyond the 4C's are
                                  accessed to grade the diamond by an
                                  experienced Grading Specialist
                                </p>
                                <div className="advacneStripBlockItem">
                                  <div>{gradParams}</div> {/* gradParams */}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="imgpaddingbott">
                              <div className="imageheight">
                                <img
                                  src={require("../../assets/img/FTC/CERTIFYING.png")}
                                  alt=""
                                />
                              </div>
                              <div className="vertical1"></div>
                              <div className="advacneStripBlock">
                                <div className="advacneStripBlockItem">
                                  <h4>CERTIFYING</h4>
                                </div>
                                <p>
                                  Laboratory certification by GIA provides an
                                  impartial judgement of the quality and
                                  characteristics of the diamond.
                                </p>
                                <div className="advacneStripBlockItem">
                                  <div>{certParams}</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="advanceBlockThree last-map-m">
                      <table className="advanceImageBlock">
                        <tbody>
                          <tr>
                            <td>
                              <img
                                src={require("../../assets/img/FTC/mapIMG.png")}
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={require("../../assets/img/FTC/CountryOfOrigin.jpg")}
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                style={{ maxWidth: "79px" }}
                                src={require("../../assets/img/FTC/GujaratMapHighLight.png")}
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={require("../../assets/img/FTC/MFEQUL.png")}
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {getAdvanceImageBox(
                                `${MEDIA_URL}/MFG/RoughImages/${roughImgId}.jpg`,
                                "asort",
                              )}
                            </td>
                            <td>
                              <div
                                onClick={() => setRoughVideo(true)}
                                className="videoImageBlock"
                              >
                                <div className="xrayVideoIcon">
                                  <img
                                    src={require("../../assets/svg/DNA/play-black.svg")}
                                    alt=""
                                  />
                                </div>
                                <img
                                  style={{ maxWidth: "112px" }}
                                  src={`${MEDIA_URL}/MFG/RoughImages/${roughImgId}.jpg`}
                                  alt=""
                                />
                              </div>

                              {/* <video
                              onClick={() => setRoughVideo(true)}
                              style={{ maxWidth: "120px", height: "94px" }}
                              controls
                              autoPlay
                            >
                              <source
                                src='https://s3.ap-south-1.amazonaws.com/finestargroup/MFG/RoughImages/130031390.jpg'
                              ></source>
                            </video> */}

                              {rVideo && (
                                <div className="modalPopup customPopup">
                                  <iframe
                                    src={`${MEDIA_URL}/MFG/RoughVideo/${roughImgId}.html`}
                                  />
                                  <img
                                    className="CloseButton"
                                    onClick={() => setRoughVideo(false)}
                                    src={require("../../assets/svg/Header/close-white.svg")}
                                    alt=""
                                  />
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {getAdvanceImageBox(
                                `${MEDIA_URL}/MFG/PlanImages/${ftcData.vStnId}.png`,
                                "planImg",
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {getAdvanceImageBox(
                                `${MEDIA_URL}/LaserSawingImages/${ftcData.vStnId}.png`,
                                "lsr",
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {getAdvanceImageBox(
                                `${MEDIA_URL}/ConingImages/${ftcData.vStnId}.png`,
                                "coning",
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {getAdvanceImageBox(
                                `${MEDIA_URL}/RealImages/${ftcData.vStnId}.jpg`,
                                "real",
                              )}
                            </td>
                            <td>
                              {ftcData.vStnId ? (
                                <video
                                  onClick={() => setPolVideo(true)}
                                  style={{ maxWidth: "120px", height: "94px" }}
                                  loop
                                  autoPlay
                                >
                                  <source src={polishVideo}></source>
                                  {/* <source src="https://s3.ap-south-1.amazonaws.com/finestargroup/Mov/130031391.mp4"></source> */}
                                  {/* <source src={`${MEDIA_URL}/Mov/${ftcData.vStnId}.mp4`}></source> */}
                                </video>
                              ) : null}
                              {polVideo && (
                                <div className="modalPopup">
                                  <ReactImageVideoLightbox
                                    data={[
                                      {
                                        url: `${MEDIA_URL}/Mov/${ftcData.vStnId}.mp4`,
                                        type: "video",
                                        altTag: "some image",
                                      },
                                    ]}
                                    startIndex={0}
                                    showResourceCount={false}
                                    //onCloseCallback={this.callbackFunction}
                                    onCloseCallback={() => setPolVideo(false)}
                                  />
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                style={{
                                  maxWidth: "95px",
                                  height: "94px",
                                  borderRadius: "14px",
                                }}
                                src={require("../../assets/img/FTC/GRADING11.png")}
                                alt=""
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <img
                                onClick={() => setCert(true)}
                                style={{ maxWidth: "120px", height: "94px" }}
                                src={require("../../assets/img/FTC/CERTIFYING1.png")}
                                alt=""
                              />
                              {cert && (
                                <div className="modalPopup customPopup">
                                  <iframe
                                    className="pdfShow"
                                    src={`${MEDIA_URL}/CertiImages/${ftcData.rptNo}.pdf`}
                                  />
                                  <img
                                    className="CloseButton"
                                    onClick={() => setCert(false)}
                                    src={require("../../assets/svg/Header/close-white.svg")}
                                    alt=""
                                  />
                                </div>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p>
                    Each stage is recorded with a timestamp and stored with
                    documents, photographs and/ or videos on a secure cloud
                    based server which can be accessed as required.
                  </p>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="certificateBox">
                <div className="certificateBoxWrapper">
                  <div className="certificateBoxTop d-flex align-items-center j-space-between">
                    <div className="certificatImage">
                      <img
                        src={require("../../assets/img/FTC/logo1.png")}
                        alt=""
                      />
                    </div>
                    <div className="certificatImageLogo">
                      <img
                        src={require("../../assets/img/FTC/logo.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="diamondId">
                    <span>Diamond ID : {ftcData.vStnId}</span>
                  </div>
                  <div className="xrayDetailInnerItem ftcBlockDetail mt-20">
                    <h2 className="detailSmallTitle">Rough Details</h2>
                    <div className="diamondDetailMoreHalf d-flex j-space-between">
                      <ul>
                        <li>
                          <span>Country of Origin : </span>
                          <span>{ftcData.org}</span>
                        </li>
                        <li>
                          <span>Rough Mine : </span>
                          <span>{ftcData.mines}</span>
                        </li>
                        {ftcData.roughWgt && (
                          <li>
                            <span>Rough weight : </span>
                            <span>{ftcData.roughWgt}ct</span>
                          </li>
                        )}
                        <li>
                          <span>Manufacturing Location : </span>
                          <span>SURAT</span>
                        </li>
                      </ul>
                      <ul className="ftcImageBlock d-flex">
                        {getImageBox(
                          `${MEDIA_URL}/MFG/RoughImages/${roughImgId}.jpg`,
                          "rough",
                          "Rough",
                        )}

                        {getImageBox(
                          `${MEDIA_URL}/MFG/PlanImages/${ftcData.vStnId}.png`,
                          "srn",
                          "SARINE",
                        )}

                        {getImageBox(
                          `${MEDIA_URL}/RealImages/${ftcData.vStnId}.jpg`,
                          "pol",
                          "POLISH",
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="xrayDetailInnerItem ftcBlockDetail mt-20">
                    <h2 className="detailSmallTitle">Polished Properties</h2>
                    <div className="diamondDetailMoreHalf d-flex j-space-between">
                      <ul className="ftcTableBlock">{polishedTable()}</ul>
                      <ul className="ftcImageBlock d-flex">
                        {getImageBox(
                          `${MEDIA_URL}/HeartImages/${ftcData.vStnId}.jpg`,
                          "hrt",
                          "Heart",
                        )}

                        {getImageBox(
                          `${MEDIA_URL}/ArrowImages/${ftcData.vStnId}.jpg`,
                          "arr",
                          "ARROW",
                        )}

                        {getImageBox(
                          `${MEDIA_URL}/FlsImages/${ftcData.vStnId}.jpg`,
                          "fluo",
                          "Fluorescence",
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="xrayDetailInnerItem ftcBlockDetail mt-20">
                    <h2 className="detailSmallTitle">Additional Details</h2>
                    <div className="diamondDetailMoreHalf d-flex j-space-between">
                      <ul className="ftcTableBlock">{otherDetailsTable()}</ul>
                      <ul className="ftcImageBlock">
                        <div className="d-flex">
                          {getImageBox(
                            `${MEDIA_URL}/IDEAL_White_BG/${ftcData.vStnId}.jpg`,
                            "iScope",
                            "IDEAL SCOPE",
                          )}

                          {getImageBox(
                            `${MEDIA_URL}/AssetScopeImages/${ftcData.vStnId}.jpg`,
                            "aScope",
                            "ASET SCOPE",
                          )}
                        </div>
                        <p>
                          IDEAL scope and ASSET Scope are simple tools used to
                          evaluate the light Performance of a diamond. A diamond
                          that reflect a lot of light back to the viewer will be
                          full of brilliance, fire and scintillation.
                        </p>
                      </ul>
                    </div>
                  </div>
                  <div className="xrayDetailInnerItem ftcBlockDetail mt-20">
                    <h2 className="detailSmallTitle">GIA Information</h2>
                    <div className="diamondDetailMoreHalf d-flex j-space-between f-d-r-n">
                      <ul>
                        <div className="ftcGIABlock">
                          <p>PROPORTIONS</p>
                          <img
                            className="giaImag"
                            src={`${MEDIA_URL}/DiagramImages/${ftcData.rptNo}.jpg`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = require("../../assets/svg/noimage.svg");
                            }}
                            alt="No Image Available"
                          />
                        </div>
                      </ul>
                      <ul>
                        <div className="ftcGIABlock">
                          <p>CLARITY CHARACTERISTICS</p>
                          <img
                            className="giaImag"
                            src={`${MEDIA_URL}/PlottingImages/${ftcData.rptNo}.gif`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = require("../../assets/svg/noimage.svg");
                            }}
                            alt="No Image Available"
                          />
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
          <CommonModal
            handleCancel={() => setDownload(false)}
            title="Download"
            visible={dwnld}
          >
            <DownloadOption
              handleCancelDownload={() => setDownload(false)}
              defaulttab={defaultTab}
            />
          </CommonModal>
        </div>
      )}
    </>
  );
};

export default FTC;
