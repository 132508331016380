/* eslint-disable */
import React from "react";
import { Select } from "antd";
import "./selectOption.less";
import _ from "lodash";
const { Option } = Select;

const SelectOption = (props) => {
  let selectValue = props.selectValue;
  let groupClass = "formSelect from-group";
  if (props.hasError) {
    groupClass = groupClass + " erroroMess";
  }
  return (
    <div className={groupClass}>
      <label>{props.label}</label>
      <Select
        getPopupContainer={(trigger) => {
          return trigger;
        }}
        dropdownClassName="selectDopdown"
        className={props.className ? props.className : null}
        mode={props.mode ? props.mode : null}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder ? props.placeholder : null}
        onChange={props.onChange ? props.onChange : null}
        value={props.value}
        disabled={props.disabled}
        showSearch={props.showSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {selectValue.map((d, index) => (
          <Option value={d.id}>
            {props.capitalize ? _.upperCase(d.name) : d.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};
export default SelectOption;
