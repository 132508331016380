export const Messages = {
  CommentRequire: "Please Enter Note.",
  FilterSelect: "Please Select Filter.",

  // HoldPopUp
  clientNameSelect: "Please Select Client Name.",
  typeSelect: "Please Select Type.",
  timeSelect: "Please Enter time between 0 to max.",
  highEndClient: "Please Select HighEnd Client.",

  //confirm stone
  billTypeSelect: "Please Select Bill Type.",
  sourceOfSaleSelect: "Please Select Source Of Sale.",
  courierNameSelect: "Please Select Courier Name.",
  invoiceDateSelect: "Please Select Invoice Date",
  partyNameSelect: "Please Enter Party Name",
  buyerNameSelect: "Please Enter Buyer Name",

  Todo: {
    Title: "Please enter title.",
    successMessage: "To do is added successfully.",
  },

  Error: {
    Required: {
      Company: "Please Select Company Name.",
      Stones: "Please Select Diamond.",
      MoreThanOneStone: "Please select minimum two diamonds to compare.",
      MemoAndHoldStone:
        "Sorry! Unable to perform action at this time. The selected diamond(s) is in hold/memo.",
      confirmAllowError:
        "Sorry! Unable to perform action at this time. The selected diamond(s) is in ",
      officeAllowError:
        "Sorry! Unable to perform action at this time. Selected diamond(s) must be in ",
      officeAllowLocError:
        "Sorry! Unable to perform action at this time. Diamond(s) location must be in ",
      holdLimitError:
        "Sorry! Unable to perform action at this time. Diamond(s) Carat must be greater than ",
      offerAllowError:
        "Sorry! Unable to perform action at this time. Not Allow to quote diamonds with status ",
      offerBlockValidate: "Can not quote the diamond",
    },
    NotFound: {
      Stones: "No Stones Found",
    },
  },
};

export default Messages;
