/* eslint-disable */
import React, { useEffect, useState } from "react";
import { GET_BANNERS } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import "../../styles/SignIn.less";

const AuthPoster = () => {
  const [poster, setPoster] = useState("");

  useEffect(() => {
    let obj = {
      ...GET_BANNERS,
      request: { page: 1, limit: 100, filter: { type: 7, isActive: true } },
    };

    UtilService.callApi(obj, async function (err, data) {
      if (err) throw err;
      if (data && data.code === "OK" && data.data && data.data.list) {
        setPoster(data.data.list[0].images[0]);
      }
    });
  }, []);
  return (
    <>
      <div className="loginLeftBlock">
        {/* <div className="belgium-img"> */}
        <video autoPlay loop muted className="belgium-video">
          <source
            src={require("../../assets/img/login/belgium-logo.mp4")}
          ></source>
        </video>
        {/* <img src={require('../../assets/img/signin/logo.svg')} alt="belgium logo" className='belgium-logo-media' /> */}
        {/* </div> */}
        <div className="loginLeftBlockInnerPlate">
          <div className="d-flex main-box-img">
            <img
              src={require("../../assets/img/signin/member.png")}
              alt="member logo"
              className="logos-media"
            />
            <img
              src={require("../../assets/img/signin/register.png")}
              alt="register logo"
              className="logos-media"
            />
            <img
              src={require("../../assets/img/signin/removebg-preview.png")}
              alt="dog logo"
              className="logos-media"
            />
            <img
              src={require("../../assets/img/signin/thumb.jpg")}
              alt="responsible logo"
              className="logos-media"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AuthPoster;
