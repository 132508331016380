/* eslint-disable */
import React from "react";
import { diamondParameters, LOCAL_STORAGE_VAR } from "../../constants/Common";
import _join from "lodash/join";
import _find from "lodash/find";
import _isObject from "lodash/isObject";
import _isArray from "lodash/isArray";
import { Tag } from "antd";
import { formatCarat, isNotEmpty } from "util/utils";

const master = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));

const MasterTags = (props) => {
  let { filterData } = props;
  delete filterData.isFm;
  return Object.keys(filterData).map((filter, ind) => {
    let param = _find(diamondParameters, { key: filter });

    if (param) {
      if (param.key === "or") {
        let orData = {};

        if (
          filterData["or"] &&
          filterData["or"].findIndex((d) => Object.keys(d).includes("crt")) >= 0
        ) {
          let caratData = filterData["or"]
            ?.map((d) => (Object.keys(d)?.includes("crt") ? d?.crt : ""))
            ?.filter(isNotEmpty);

          orData.Carat = caratData.map((carat) => {
            return (
              formatCarat(carat[">="]) +
              " to " +
              (carat["<="] ? formatCarat(carat["<="]) : "...")
            );
          });
        }
        // if (filterData["or"].findIndex((d) => Object.keys(d).includes("lbIds")) >= 0) {
        //   orData["Lab"] = [];
        //   let lb = filterData["or"]?.find((d) => Object.keys(d).includes("lbIds"))?.lbIds;

        //   lb.forEach((masterId) => {
        //     orData["Lab"].push(master?.LAB?.find((d) => d.id?.[0] === masterId)?.webDisplay);
        //   });
        // }
        return Object.entries(orData).map(([key, data]) => (
          <Tag className="listTagList" key={key}>
            <span className="listLabel">{key} :</span>
            <span className="listValue">{_join(data, ", ")}</span>
          </Tag>
        ));
      } else {
        if (_isObject(filterData[filter]) && !_isArray(filterData[filter])) {
          if (filter === "kToSArr") {
            let arr = Object.values(filterData["kToSArr"])[0];
            let key = Object.keys(filterData["kToSArr"])[0];

            return (
              <div className="savedSearchValue" key={ind}>
                <span>
                  {param.name}&nbsp;:&nbsp;
                  <span>{key === "in" ? "Contains" : "Not contains"}</span>
                </span>
                <span>{_join(arr, ", ")}</span>
              </div>
            );
          } else {
            if (filter === "back") {
              return (
                <Tag className="listTagList">
                  <span className="listLabel">{param.name} :</span>
                  <span>
                    {filterData[filter]["<="] * -1 +
                      "  to  " +
                      filterData[filter][">="] * -1}
                  </span>
                </Tag>
              );
            } else if (filter === "type2") {
              return (
                <Tag className="listTagList">
                  <span className="listLabel">{param.name} :</span>
                  <span>YES</span>
                </Tag>
              );
            } else {
              return (
                <Tag className="listTagList">
                  <span className="listLabel">{param.name} :</span>
                  <span>
                    {filterData[filter][">="] +
                      "  to  " +
                      filterData[filter]["<="]}
                  </span>
                </Tag>
              );
            }
          }
        } else if (typeof filterData[param.key] === "boolean") {
          return (
            <Tag className="listTagList">
              <span className="listLabel">{param.name} :</span>
              <span>{filterData[param.key] === true ? "YES" : "NO"}</span>
            </Tag>
          );
        } else {
          let disp = filterData[param.key];

          return (
            <Tag className="listTagList">
              <span className="listLabel">{param.name} :</span>
              <span>{_join(disp, ", ")}</span>
            </Tag>
          );
        }
      }
    }
  });
};
export default MasterTags;
