/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Heading from "../Heading";
import { Drawer } from "antd";
import { ListingCards } from "./MobileComfirmStone";
import QuoteSelect from "../MobileDiamondList/QuoteSelect";
import HoursSelect from "../MobileDiamondList/HoursSelect";
import InputBlock from "../InputBlock";
import IntlMessages from "../../../util/IntlMessages";

const currentType = "QuotePopup";
class MobileOfferPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: "",
      quoteSelect: null,
      apply: false,
    };
  }

  onClose = () => {
    this.setState({ quote: "", quoteSelect: null, apply: false });
  };

  render() {
    return (
      <div className="offerWrapper">
        <div className="d-flex align-items-center justify-space-between offerTopBlock">
          <div className="d-flex align-items-center">
            <Heading className="popupInnerTitle" title="Apply Quote" />
          </div>
          {this.props.parent.offerHeading()}
        </div>
        <div className="searchInnerResult tabInnerTable">
          <div className="diamondListMobile mobileOfferScroll">
            <ListingCards
              data={this.props.data}
              quote={true}
              quoteClick={() => {}}
              currentType={currentType}
              checked={this.props.checked}
              parent={this.props.parent}
              quoteClick={(e, dt) =>
                this.setState({ quote: e, quoteSelect: dt })
              }
            />
          </div>
          <div className="d-flex justify-content-between offerBottomBox flex-wrap"></div>
        </div>
        <div className="sideBarPopupButton">
          <a
            className="commonButton"
            onClick={() => {
              if (!this.props.parent.checkCheck()) return;
              this.setState({ apply: true });
            }}
          >
            Apply Quote
          </a>
        </div>
        {this.state.apply && (
          <Drawer
            title={false}
            onClose={this.onClose}
            visible={true}
            className="diamondListinSidebar smallSidebarMobilePopup"
          >
            <div>
              {this.props.parent.getOfferInput()}
              <div className="sideBarPopupButton">
                <a className="commonButton" onClick={this.props.parent.submit}>
                  Apply Quote
                </a>
                <a className="commonOutline" onClick={this.onClose}>
                  Cancel
                </a>
              </div>
            </div>
          </Drawer>
        )}
        {this.state.quote && (
          <Drawer
            title={false}
            onClose={this.onClose}
            visible={true}
            className="diamondListinSidebar smallSidebarMobilePopup onlySelectMobileOption"
          >
            {this.state.quote === "quote" && (
              <QuoteSelect
                onQuoteChange={this.props.parent.onQuoteChange}
                quoteSelect={this.state.quoteSelect}
                onClose={this.onClose}
              />
            )}
            {this.state.quote === "hours" && (
              <HoursSelect
                onHourChange={this.props.parent.onHourChange}
                quoteSelect={this.state.quoteSelect}
                onClose={this.onClose}
              />
            )}
          </Drawer>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ diamondData }) => {
  return { checkedData: diamondData.selectedRows[currentType] || [] };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleSelect: (setValue, row) =>
      dispatch({
        type: setValue ? "SELECT_ROW" : "UNSELECT_ROW",
        payload: row,
        currentType,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileOfferPopup);
