/* eslint-disable */
import React, { Component } from "react";
import SavedSearchCard from "../SavedSearch/SavedSearchCard";

class RecentSearch extends Component {
  render() {
    let { recent, loading } = this.props;
    return (
      <div className="rightBlockItem">
        <div
          className={`sacedSearchBox ${
            loading ? "loading-indicator-footer" : ""
          }`}
        >
          {recent && recent.length !== 0
            ? recent.map((recent, ind) => {
                return (
                  <SavedSearchCard recentSearch saveDetail={recent} key={ind} />
                );
              })
            : "No Recent Search"}
        </div>
      </div>
    );
  }
}
export default RecentSearch;
