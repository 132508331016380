/* eslint-disable */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import getData from "./common/svgSidebar";
import _ from "lodash";
import { connect } from "react-redux";
import "react-tippy/dist/tippy.css";
import IntlMessages from "../util/IntlMessages";
import { handleSearch } from "../containers/APIFunctions";
import { LOCAL_STORAGE_VAR } from "../constants/Common";
import { GuestUserPopup } from "./GuestUserPopup";
import UtilService from "../services/util";
/* eslint jsx-a11y/anchor-is-valid: 0 */

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`),
      showGuest: false,
    };
  }

  render() {
    const crnt_url = window.location.pathname;
    let USER_PERMIT = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-userPermissions`,
    );

    let sidebarSvgFiles = getData(USER_PERMIT);
    let cuIndex = _.findIndex(sidebarSvgFiles, { url: crnt_url });
    let isFmUserAccess =
      JSON.parse(
        window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)),
      ) &&
      JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)))
        .account &&
      JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)))
        .account.isFm;

    return (
      <div className="headerMenuBox">
        <div className="headerMeniInner">
          <ul id="links">
            <li
              className={
                crnt_url === "/dashboard"
                  ? "d-flex align-items-center active-sidebar"
                  : "d-flex align-items-center"
              }
            >
              <Link to="/dashboard" className="menuItem">
                <IntlMessages id="app.home" />
              </Link>
            </li>
            {sidebarSvgFiles.map((dataSvg, index) => {
              return (
                <li
                  key={index}
                  id={`icon_click${index}`}
                  className={
                    index === cuIndex
                      ? "d-flex align-items-center active-sidebar"
                      : "d-flex align-items-center"
                  }
                  //className="d-flex align-items-center active-sidebar"
                  // onClick={this.activeMenu.bind(this, index)}
                >
                  <div>
                    {!this.state.isGuest ||
                    dataSvg.url === "/diamond-search" ? (
                      <Link to={dataSvg.url} className="menuItem">
                        {dataSvg.name}
                      </Link>
                    ) : (
                      <Link
                        to=""
                        onClick={() => {
                          this.setState({
                            showGuest: true,
                          });
                        }}
                        className="menuItem"
                      >
                        {dataSvg.name}
                      </Link>
                    )}
                  </div>
                  {dataSvg.subMenu && !this.state.isGuest && (
                    <div className="menuDropdown">
                      <div className="submenuWrapper">
                        <div className="submenuItem">
                          <h3>
                            <IntlMessages id="app.white-diamonds" />
                          </h3>
                          <div className="d-flex j-space-between">
                            <ul>
                              <li>
                                <Link to="/new-arrival-bid-it">
                                  <IntlMessages id="app.newArrival" />
                                </Link>
                              </li>
                              <li>
                                <Link to="/match-pair">
                                  <IntlMessages id="app.matchpairDashboard" />
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to=""
                                  onClick={() => {
                                    const labs = JSON.parse(
                                      localStorage.getItem(
                                        `${LOCAL_STORAGE_VAR}-master`,
                                      ),
                                    ).LAB;
                                    let labId = _.find(labs, {
                                      code: "OTHER",
                                    }).id;

                                    handleSearch(
                                      {
                                        lb: labId,
                                      },
                                      (cb) => {
                                        window.location.assign(
                                          `/diamond-list?` + cb.id,
                                        );
                                      },
                                    );
                                  }}
                                >
                                  <IntlMessages id="app.non-certified" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="submenuItem">
                          <h3>
                            <IntlMessages id="app.Discover" />
                          </h3>
                          <ul>
                            <li>
                              <Link
                                to=""
                                onClick={() => {
                                  handleSearch({ isDor: ["CERT"] }, (cb) => {
                                    window.location.assign(
                                      `/diamond-list?` + cb.id,
                                    );
                                  });
                                }}
                              >
                                DOR
                              </Link>
                            </li>
                            {isFmUserAccess && (
                              <li>
                                <Link
                                  to=""
                                  onClick={() => {
                                    handleSearch({ isFm: ["CERT"] }, (cb) => {
                                      window.location.assign(
                                        `/diamond-list?` + cb.id,
                                      );
                                    });
                                  }}
                                >
                                  Forevermark
                                </Link>
                              </li>
                            )}

                            <li>
                              <Link
                                to=""
                                onClick={() => {
                                  handleSearch(
                                    { isCm: ["CERT", "ELIG"] },
                                    (cb) => {
                                      window.location.assign(
                                        `/diamond-list?` + cb.id,
                                      );
                                    },
                                  );
                                }}
                              >
                                Canadamark
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="subMenuImage">
                          <div className="submenuImageContent">
                            <div className="sibmenuImg">
                              <img
                                src={require("../assets/img/header/1.jpg")}
                                alt="img"
                              />
                            </div>
                            {/* <div className="submenuContent">
                                                        <div className="submenudropTitle">Round Diamond For Her</div>
                                                        <a className="submenuLink">Shop Now <img src={require("../assets/svg/Header/right.svg")} alt="image"/></a>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {dataSvg.subMenu1 && !this.state.isGuest && (
                    <div className="menuDropdown">
                      <div className="submenuWrapper">
                        <div className="submenuItem">
                          <h3>Fancy Diamond</h3>
                          <div className="d-flex j-space-between">
                            <ul>
                              <li>
                                <a>
                                  <IntlMessages id="app.newArrival" />
                                </a>
                              </li>
                              <li>
                                <a>
                                  <IntlMessages id="app.match-pair" />
                                </a>
                              </li>
                              <li>
                                <a>
                                  <IntlMessages id="app.non-certified" />
                                </a>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <a>
                                  <IntlMessages id="app.parcel" />
                                </a>
                              </li>
                              <li>
                                <a>DOR</a>
                              </li>
                              {isFmUserAccess && (
                                <li>
                                  <a>Forevermark</a>
                                </li>
                              )}
                            </ul>
                            <ul>
                              <li>
                                <a>Canadamark</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="submenuItem">
                          <h3>
                            <IntlMessages id="app.Discover" />
                          </h3>
                          <ul>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                          </ul>
                        </div>
                        <div className="subMenuImage">
                          <div className="submenuImageContent">
                            <div className="sibmenuImg">
                              <img
                                src={require("../assets/img/header/Fancy-diamond.jpg")}
                                alt="img"
                              />
                            </div>
                            {/* <div className="submenuContent">
                                                        <div className="submenudropTitle">Fancy Diamond For Her</div>
                                                        <a className="submenuLink">Shop Now <img src={require("../assets/svg/Header/right.svg")} alt="image"/></a>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {dataSvg.subMenu2 && !this.state.isGuest && (
                    <div className="menuDropdown">
                      <div className="submenuWrapper">
                        <div className="submenuItem">
                          <h3>
                            <IntlMessages id="app.Exclusive" />
                          </h3>
                          <ul>
                            <li>
                              <Link to="/meet-the-artisan">
                                <IntlMessages id="app.meettheartisan" />
                              </Link>
                            </li>
                            <li>
                              <a>
                                <IntlMessages id="app.FinestarEmerald" />
                              </a>
                            </li>
                            <li>
                              <a>CEO's suggestion</a>
                            </li>
                          </ul>
                        </div>
                        <div className="submenuItem">
                          <h3>
                            <IntlMessages id="app.Discover" />
                          </h3>
                          <ul>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                          </ul>
                        </div>
                        <div className="subMenuImage">
                          <div className="submenuImageContent">
                            <div className="sibmenuImg">
                              <img
                                src={require("../assets/img/header/2.jpg")}
                                alt="img"
                              />
                            </div>
                            {/* <div className="submenuContent">
                                                        <div className="submenudropTitle">Exclusive Diamond For Her</div>
                                                        <a className="submenuLink">Shop Now <img src={require("../assets/svg/Header/right.svg")} alt="image"/></a>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {dataSvg.subMenu3 && !this.state.isGuest && (
                    <div className="menuDropdown">
                      <div className="submenuWrapper">
                        <div className="submenuItem">
                          <h3>
                            <IntlMessages id="app.newArrival" />
                          </h3>
                          <ul>
                            <li>
                              <a href="/new-arrival-bid-it">
                                <IntlMessages id="app.LookNBid" />
                              </a>
                            </li>
                            <li>
                              <a href="/new-arrival-bid-it">
                                <IntlMessages id="app.BlindBid" />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="submenuItem">
                          <h3>Discover</h3>
                          <ul>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                          </ul>
                        </div>
                        <div className="subMenuImage">
                          <div className="submenuImageContent">
                            <div className="sibmenuImg">
                              <img
                                src={require("../assets/img/header/dropdown.jpg")}
                                alt="image"
                              />
                            </div>
                            {/* <div className="submenuContent">
                                                        <div className="submenudropTitle">Live Bid For Her</div>
                                                        <a className="submenuLink">Shop Now <img src={require("../assets/svg/Header/right.svg")} alt="image"/></a>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {dataSvg.subMenu4 && !this.state.isGuest && (
                    <div className="menuDropdown">
                      <div className="submenuWrapper">
                        <div className="submenuItem">
                          <h3>
                            <IntlMessages id="app.Upcoming" />
                          </h3>
                          <ul>
                            <li>
                              <a>
                                <IntlMessages id="app.InManufacturing" />
                              </a>
                            </li>
                            <li>
                              <a>
                                <IntlMessages id="app.InLab" />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="submenuItem">
                          <h3>
                            <IntlMessages id="app.Discover" />
                          </h3>
                          <ul>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                            <li>
                              <a>Lorem Ipsum is simply dummy</a>
                            </li>
                          </ul>
                        </div>
                        <div className="subMenuImage">
                          <div className="submenuImageContent">
                            <div className="sibmenuImg">
                              <img
                                src={require("../assets/img/header/3.jpg")}
                                alt="image"
                              />
                            </div>
                            {/* <div className="submenuContent">
                                                        <div className="submenudropTitle">UpComing Diamond For Her</div>
                                                        <a className="submenuLink">Shop Now <img src={require("../assets/svg/Header/right.svg")} alt="image"/></a>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        {this.state.showGuest && (
          <GuestUserPopup
            visible={this.state.showGuest}
            register={() => {
              window.open("/signup", "_self");
              window.localStorage.clear();
              window.sessionStorage.clear();
            }}
            handleCancel={() => {
              this.setState({ showGuest: false });
            }}
          />
        )}
      </div>
    );
  }
}

// export default withRouter(Sidebar);
const mapStateToProps = function (props) {
  return props;
};

export default connect(mapStateToProps)(withRouter(Sidebar));
