/* eslint-disable */
import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import queryString from "query-string";
import Dashboard from "../components/Dashboard/index";
import Compare from "../components/DiamondCompare";
import DiamondSearch from "../containers/DiamondSearch";
import MainApp from "./../App";
import {
  LOCAL_STORAGE_VAR,
  commonLogout,
  IS_QUICK_SEARCH,
  WHITE_LISTED_ROUTES,
} from "../constants/Common";
import QuickSearch from "../components/QuickSearch";
import DiamondList from "../components/DiamondList";
import ComingSoonDiamondList from "../components/DiamondList/ComingSoon";
import Account from "../components/Account";
import MadeInBotswana from "../components/MadeInBotswana";
import PrivacyNotice from "../components/PrivacyNotice";
import NewArrivalBidIt from "../components/NewArrivalBidIt";
import NotificationDropdown from "../components/common/Header/NotificationDropdown";
import SavedSearch from "../components/SavedSearch";
import MyDemand from "../components/MyDemand/index";
import PersonalInformation from "../components/Account/PersonalInformation";
import CompanyInformation from "../components/Account/CompanyInformation";
import ChangePassword from "../components/Account/ChangePassword";
import MobileAccount from "../components/Account/MobileAccount";
import DiamondDetail from "../components/DiamondDetail";
import UtilService from "../services/util";
import DiamondColumnListing from "../components/Account/ManageDiamondSequence";
import TrackShipment from "../components/TrackShipment";
import RapnetPrice from "../components/RapnetPrice";
import { compose } from "redux";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Notifications from "../components/Account/Notifications";

import AccountingList from "../components/Accounting";

class FrontRoute extends Component {
  redirectPage = () => {
    const currentUrl = this.props.location.pathname.split("/");
    const path = currentUrl[1];

    const USER_PERMIT = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-userPermissions`,
    );
    if (isEmpty(USER_PERMIT)) commonLogout();

    if (path && path === "device") {
      const values = queryString.parse(this.props.location.search);
      if (values && values.token)
        UtilService.setLocalStorageItem(
          values.token,
          `${LOCAL_STORAGE_VAR}-token`,
        );
    }

    let isTrusted = localStorage.getItem(`${LOCAL_STORAGE_VAR}-isTrusted`);
    const isLoggedInUser = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-token`,
    );

    if (isLoggedInUser && isLoggedInUser.length && isTrusted === "false") {
      return <Redirect to="/verify-otp" />;
    } else if (!isLoggedInUser || !isLoggedInUser.length) {
      window.location.assign("/signin");
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (!WHITE_LISTED_ROUTES.includes(this.props.location.pathname)) {
        this.redirectPage();
      }
    }
  }

  render() {
    const isMobile = window.innerWidth <= 767;
    const USER_PERMIT = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-userPermissions`,
    );

    return (
      <MainApp loading={this.props.commonData.loading}>
        <Switch>
          <Route path="/diamond-search" component={DiamondSearch} />
          {USER_PERMIT && USER_PERMIT?.DASHBOARD?.view && (
            <Route exact path="/dashboard" component={Dashboard} />
          )}
          {USER_PERMIT && USER_PERMIT?.SEARCH_LIST?.view && (
            <Route exact path="/diamond-list" component={DiamondList} />
          )}
          {USER_PERMIT && USER_PERMIT?.SAVE_SEARCH?.view && isMobile && (
            <Route exact path="/account/saved-search" component={SavedSearch} />
          )}
          {USER_PERMIT && USER_PERMIT?.DEMAND?.view && isMobile && (
            <Route exact path="/account/my-demand" component={MyDemand} />
          )}
          {USER_PERMIT && USER_PERMIT?.NOTIFICATIONS?.view && isMobile && (
            <Route
              exact
              path="/account/notifications"
              component={Notifications}
            />
          )}
          {isMobile && (
            <Route
              exact
              path="/account/personal-information"
              component={PersonalInformation}
            />
          )}
          {isMobile && (
            <Route
              exact
              path="/account/company-profile"
              component={CompanyInformation}
            />
          )}
          {isMobile && (
            <Route
              exact
              path="/account/change-password"
              component={ChangePassword}
            />
          )}
          {USER_PERMIT && USER_PERMIT?.COMPARE?.view && (
            <Route exact path="/compare" component={Compare} />
          )}
          {isMobile ? (
            <Route exact path="/account" component={MobileAccount} />
          ) : (
            <Route exact path="/account" component={Account} />
          )}
          <Route exact path="/diamond-detail/:id" component={DiamondDetail} />

          {USER_PERMIT &&
            USER_PERMIT?.WATCHLIST?.view &&
            (isMobile ? (
              <Route exact path="/account/watchlist" component={DiamondList} />
            ) : (
              <Route exact path="/account/watchlist" component={Account} />
            ))}
          {USER_PERMIT &&
            USER_PERMIT?.CART?.view &&
            (isMobile ? (
              <Route exact path="/account/cart" component={DiamondList} />
            ) : (
              <Route exact path="/account/cart" component={Account} />
            ))}
          {/* {USER_PERMIT &&
            USER_PERMIT?.OFFICE?.view &&
            (isMobile ? (
              <Route exact path="/account/office-list" component={DiamondList} />
            ) : (
              <Route exact path="/account/office-list" component={Account} />
            ))} */}
          {/* {USER_PERMIT &&
            USER_PERMIT?.REMINDER?.view && (*/}
          {isMobile ? (
            <Route exact path="/account/reminder" component={DiamondList} />
          ) : (
            <Route exact path="/account/reminder" component={Account} />
          )}
          {/* )} */}
          {USER_PERMIT &&
            USER_PERMIT?.NOTES?.view &&
            (isMobile ? (
              <Route exact path="/account/notes" component={DiamondList} />
            ) : (
              <Route exact path="/account/notes" component={Account} />
            ))}
          {/* {USER_PERMIT &&
            USER_PERMIT?.QUOTE?.view &&
            (isMobile ? (
              <Route exact path="/account/quote-list" component={Offer} />
            ) : (
              <Route exact path="/account/quote-list" component={Account} />
            ))} */}
          {USER_PERMIT &&
            USER_PERMIT?.CONFIRM_STONE?.view &&
            (isMobile ? (
              <Route
                exact
                path="/account/confirm-list"
                component={DiamondList}
              />
            ) : (
              <Route exact path="/account/confirm-list" component={Account} />
            ))}
          {USER_PERMIT && USER_PERMIT?.NOTIFICATIONS?.view && (
            <Route exact path="/account/notifications" component={Account} />
          )}
          {USER_PERMIT && USER_PERMIT?.SAVE_SEARCH?.view && (
            <Route exact path="/account/saved-search" component={Account} />
          )}
          {USER_PERMIT && USER_PERMIT?.DEMAND?.view && (
            <Route exact path="/account/my-demand" component={Account} />
          )}
          <Route exact path="/account/profile" component={Account} />
          <Route exact path="/account/hold" component={Account} />
          <Route exact path="/account/memo" component={Account} />
          <Route exact path="/account/purchase" component={Account} />
          {isMobile ? (
            <Route
              exact
              path="/account/profile/diamond-sequence"
              component={DiamondColumnListing}
            />
          ) : (
            <Route
              exact
              path="/account/profile/diamond-sequence"
              component={Account}
            />
          )}
          {USER_PERMIT && USER_PERMIT?.MATCH_PAIR?.view && (
            <Route exact path="/match-pair" component={DiamondList} />
          )}
          {USER_PERMIT && USER_PERMIT?.UPCOMING?.view && (
            <Route exact path="/upcoming" component={DiamondList} />
          )}
          {USER_PERMIT &&
            USER_PERMIT?.COMING_SOON?.view &&
            (isMobile ? (
              <Route exact path="/coming-soon" component={DiamondList} />
            ) : (
              <Route
                exact
                path="/coming-soon"
                component={ComingSoonDiamondList}
              />
            ))}
          {USER_PERMIT && USER_PERMIT?.EXCLUSIVE?.view && (
            <Route exact path="/belgium-exclusive" component={DiamondList} />
          )}
          {USER_PERMIT && USER_PERMIT?.QUOTE_DAY?.view && (
            <Route exact path="/quote-day" component={DiamondList} />
          )}
          <Route exact path="/privacy-notice" component={PrivacyNotice} />
          <Route exact path="/rapnet-price" component={RapnetPrice} />
          <Route exact path="/track-diamond" component={TrackShipment} />
          {USER_PERMIT && USER_PERMIT?.MADE_IN_BOTSWANA?.view && (
            <Route exact path="/meet-the-artisan" component={MadeInBotswana} />
          )}
          {isMobile && (
            <Route
              exact
              path="/notification"
              component={NotificationDropdown}
            />
          )}
          {USER_PERMIT && USER_PERMIT?.NEW_ARRIVAL_BID_IT?.view && (
            <Route
              exact
              path="/new-arrival-bid-it"
              component={NewArrivalBidIt}
            />
          )}
          {/* {USER_PERMIT && USER_PERMIT?.MY_BID?.view && <Route exact path="/my-bid" component={BidList} />} */}
          {USER_PERMIT && USER_PERMIT?.SUGGESTED_STOCK?.view && (
            <Route exact path="/suggested-stock" component={DiamondList} />
          )}
          {USER_PERMIT && USER_PERMIT?.TRENDING?.view && (
            <Route exact path="/trending" component={DiamondList} />
          )}
          {IS_QUICK_SEARCH &&
            USER_PERMIT &&
            USER_PERMIT?.QUICK_SEARCH?.view && (
              <Route path="/quick-search" component={QuickSearch} />
            )}
          {/* for new page accounting change permission */}
          {USER_PERMIT && USER_PERMIT?.UPCOMING?.view && (
            <Route exact path="/accounting" component={AccountingList} />
          )}
          <Route path="/fm-diamond-list" component={DiamondList} />
          <Redirect from="*" to="/not-found" />
        </Switch>
      </MainApp>
    );
  }
}

export default compose(
  connect((state) => ({ commonData: state.commonData })),
  withRouter,
)(FrontRoute);
