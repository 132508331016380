/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./memo.less";
import CommonModal from "../common/CommonModal/index";
import Table from "../../components/DiamondList/TableBack";
import { Radio } from "antd";
import { getColumn } from "components/DiamondList/DiamondListFunctions";
import DatePickerRange from "components/common/DateRangePicker/DatePickerRange";
import moment from "moment";
import { GET_MEMO_DETAILS } from "../../constants/apiConstant";
import UtilService from "services/util";
import OpenNotification from "components/common/CommonButton/OpenNotification";
import { isEmpty, isNotEmpty } from "util/utils";
import { capitalize, lowerCase, startCase } from "lodash";
import { useHistory } from "react-router-dom";
import InputBlock from "components/common/InputBlock";
import { sendOTPAndVerification } from "containers/APIFunctions";
import Storage from "services/storage";

function Memo() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [memoRadio, setMemoRadio] = useState(`open_memo_packet`);
  const [memoPocketColumns, setMemoPocketColumns] = useState([]);
  const [isMemoHistoryOpen, setIsMemoHistoryOpen] = useState(false);
  const [memoHisPopupCols, setMemoHisPopupCols] = useState([]);
  const [memoModalCols, setMemoModalCols] = useState([]);
  const [memoModalData, setMemoModalData] = useState([]);
  const [memoDataAll, setMemoDataAll] = useState([]);
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [memoMainDataArray, setMemoMainDataArray] = useState([]);
  const [memoOpenPacketData, setMemoOpenPacketData] = useState([]);
  const [memoHistoryDataArray, setMemoHistoryDataArray] = useState([]);
  const [memoHistoryPopUpData, setMemoHistoryPopUpData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState([]);
  let history = useHistory();
  const [oTPModel, setOTPModel] = useState({
    visible: true,
    value: "",
    verify: false,
  });

  let tempCol = getColumn();

  const TOTAL_COLUMNS = [
    "amt",
    "back",
    "crt",
    "ctPr",
    "rap",
    "fnlAmt",
    "status",
    "BalPcs",
    "BalCts",
    "BalValue",
    "TotPcs",
    "Totwt",
    "TotValue",
  ];

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (e) => {
    const tempMemo =
      e?.currentTarget.parentNode.parentNode.parentNode.firstElementChild
        .nextElementSibling.firstChild.innerHTML;
    let tempMemoData = memoDataAll.filter((item) => {
      if (item.Memono === tempMemo) {
        return item;
      }
    });
    setMemoModalData(tempMemoData[0]?.DiamondDetail);
    setIsModalOpen(true);
  };

  const handleHistoryModalOpen = (e) => {
    const tempMemoHis =
      e?.currentTarget.parentNode.parentNode.firstChild.nextElementSibling
        .firstChild.innerHTML;
    let tempMemoHisData = memoHistoryDataArray.filter((item) => {
      if (item.Memono === tempMemoHis) {
        return item;
      }
    });
    setMemoHistoryPopUpData(tempMemoHisData[0]?.DiamondDetail);
    setIsMemoHistoryOpen(true);
  };

  const handleHistoryModalClose = () => {
    setIsMemoHistoryOpen(false);
  };

  const createMemoModalCols = () => {
    const reqMemoAccessors = [
      "srNo",
      "vStnId",
      "rptNo",
      "shpNm",
      "crt",
      "colNm",
      "clrNm",
      "shdNm",
      "rap",
      "back",
      "ctPr",
      "amt",
      "cutNm",
      "polNm",
      "symNm",
      "fluNm",
      "tblPer",
      "depPer",
      "length",
      "width",
      "height",
    ];
    let tempMemoCol = getColumn();
    tempMemoCol = tempMemoCol.filter((item) => {
      return reqMemoAccessors.includes(item.accessor);
    });
    const tempEleMemo = tempMemoCol.splice(1, 1)[0];
    tempMemoCol.splice(0, 0, tempEleMemo);
    tempMemoCol[0].Header = "Sr.";
    setMemoModalCols(tempMemoCol);
  };

  const memoDetailsData = () => {
    const memoDetailApi = GET_MEMO_DETAILS;
    memoDetailApi.request = {
      limit: 500,
      filter: {},
      trans_type:
        memoRadio === "open" || memoRadio === "open_memo_packet"
          ? "memo"
          : "history",
      ...(memoRadio === "open_memo_packet" ? { openPacket: true } : {}),
      from: fromDate,
      to: toDate,
    };

    setIsLoading(true);
    UtilService.callApi(memoDetailApi, function (err, data) {
      if (data && data?.code === "OK") {
        setIsLoading(false);
        setMemoDataAll(data?.data);
        setMemoModalData(data?.data[0]?.DiamondDetail);
        setMemoMainDataArray(data?.data);
        setMemoOpenPacketData(data?.data);
        setMemoHistoryDataArray(data?.data);
        // setMemoHistoryPopUpData(data?.data[0]?.DiamondDetail);
      } else {
        setIsLoading(false);
        OpenNotification({
          type: "error",
          title: err?.data?.message || data?.message,
        });
      }
    });
  };

  // useEffect(() => {
  //     memoDetailsData();
  // }, [memoRadio]);

  const createMemoPocketColumns = () => {
    const reqAccessors = [
      "srNo",
      "vStnId",
      "rptNo",
      "shpNm",
      "crt",
      "colNm",
      "clrNm",
      "shdNm",
      "rap",
      "back",
      "ctPr",
      "amt",
    ];
    tempCol = tempCol.filter((item) => {
      return reqAccessors.includes(item.accessor);
    });
    const tempEle = tempCol.splice(1, 1)[0];
    tempCol.splice(0, 0, tempEle);
    tempCol = [
      ...tempCol,
      {
        Header: "Memo No",
        accessor: "memoNo",
        Cell: ({ cell }) => {
          return cell?.row?.original?.memoNo
            ? cell?.row?.original?.memoNo
            : "-";
        },
      },
      {
        Header: "Contact",
        accessor: "ContactName",
        Cell: ({ cell }) => {
          return cell?.row?.original?.ContactName
            ? capitalize(cell?.row?.original?.ContactName)
            : "-";
        },
      },
      {
        Header: "Sales Rep",
        accessor: "salesManName",
        Cell: ({ cell }) => {
          return cell?.row?.original?.salesManName
            ? capitalize(cell?.row?.original?.salesManName)
            : "-";
        },
      },
      {
        Header: "Comment",
        accessor: "cmmt",
        Cell: ({ cell }) => {
          return cell?.row?.original?.cmmt ? cell?.row?.original?.cmmt : "-";
        },
      },
      {
        Header: "Due Date",
        accessor: "DueDate",
        Cell: ({ cell }) => {
          return cell?.row?.original?.DueDate
            ? moment(cell?.row?.original?.DueDate).format("MM-DD-YYYY")
            : "-";
        },
      },
      {
        Header: "Memo Date",
        accessor: "memoIDt",
        Cell: ({ cell }) => {
          return cell?.row?.original?.memoIDt
            ? moment(cell?.row?.original?.memoIDt).format("MM-DD-YYYY")
            : "-";
        },
      },
      {
        Header: "Logistic",
        accessor: "lgts",
        Cell: ({ cell }) => {
          return cell?.row?.original?.lgts ? cell?.row?.original?.lgts : "-";
        },
      },
    ];
    tempCol[0].Header = "Sr.";
    tempCol[9].Cell = (cell) => {
      return cell?.row?.original?.back
        ? "-" + String(cell?.row?.original?.back)
        : "-";
    };
    setMemoPocketColumns(tempCol);
  };

  const createMemoHistoryColumns = () => {
    const reqHisAccessors = [
      "srNo",
      "vStnId",
      "rptNo",
      "shpNm",
      "crt",
      "colNm",
      "clrNm",
      "shdNm",
      "rap",
      "back",
      "ctPr",
      "amt",
      "cutNm",
      "polNm",
      "symNm",
      "fluNm",
      "tblPer",
      "depPer",
      "length",
      "width",
      "height",
    ];
    let tempHistCol = getColumn();
    tempHistCol = tempHistCol.filter((item) => {
      return reqHisAccessors.includes(item.accessor);
    });
    const tempEleHis = tempHistCol.splice(1, 1)[0];
    tempHistCol.splice(0, 0, tempEleHis);
    tempHistCol[0].Header = "Sr.";
    setMemoHisPopupCols(tempHistCol);
  };

  const handleDateRange = (val) => {
    if (isNotEmpty(val)) {
      setFromDate(moment(val[0]["_d"]).format("YYYY-MM-DD"));
      setToDate(moment(val[1]["_d"]).format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    const getOtp = Storage.get("otp-verify");
    let otp = getOtp ? getOtp?.singlePageVerify : false;
    if (otp) {
      setOTPModel({ ...oTPModel, visible: false });
      memoDetailsData();
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setOTPModel({ ...oTPModel, visible: true });
    }
  }, []);

  const sendOTP = () => {
    setIsLoading(true);
    let obj = {
      callType: "send otp",
    };
    sendOTPAndVerification(obj, (cb) => setIsLoading(cb ? false : true));
  };

  const verifyOTP = () => {
    setIsLoading(true);
    let obj = {
      otp: oTPModel.value,
      callType: "verify OTP",
    };

    sendOTPAndVerification(obj, (cb) => {
      if (cb === "OTP verified successfully") {
        const getOtp = Storage.get("otp-verify");
        const otp = Storage.set("otp-verify", {
          ...getOtp,
          singlePageVerify: true,
        });
        if (otp) {
          setOTPModel({ ...oTPModel, visible: false });
          memoDetailsData();
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        OpenNotification({ type: "error", title: cb });
      }
    });
  };

  useEffect(() => {
    if (!oTPModel.visible) memoDetailsData();
  }, [fromDate, toDate, memoRadio]);

  useEffect(() => {
    createMemoModalCols();
    createMemoPocketColumns();
    createMemoHistoryColumns();
  }, []);

  {
    /* Total row UseEffect*/
  }
  useEffect(() => {
    let tot_crt = 0;
    let tot_pcs = 0;
    let tot_val = 0;
    let tot_balcts = 0;
    let tot_balpcs = 0;
    let tot_balval = 0;
    let dataArray =
      memoRadio === "open"
        ? memoMainDataArray
        : memoRadio === "history"
        ? memoHistoryDataArray
        : [];

    if (isNotEmpty(dataArray)) {
      dataArray.map((item) => {
        tot_crt += item?.Totwt && parseFloat(item?.Totwt) ? item?.Totwt : 0;
        tot_pcs += item?.TotPcs ? item?.TotPcs : 0;
        tot_val +=
          item?.TotValue && parseFloat(item?.TotValue) ? item?.TotValue : 0;
        tot_balcts +=
          item?.BalCts && parseFloat(item?.BalCts) ? item?.BalCts : 0;
        tot_balpcs += item?.BalPcs ? item?.BalPcs : 0;
        tot_balval +=
          item?.BalValue && parseFloat(item?.BalValue) ? item?.BalValue : 0;
      });
    }
    let allTotal = {
      total_pieces: tot_pcs,
      total_carat: tot_crt,
      total_value: tot_val,
      total_balpieces: tot_balpcs,
      total_balcarat: tot_balcts,
      total_balvalue: tot_balval,
    };
    setTotal(allTotal);
  }, [memoMainDataArray, memoHistoryDataArray]);

  const handleMemoRadio = (e) => {
    setMemoRadio(e?.target?.value);
  };

  useEffect(() => {
    if (isEmpty(memoOpenPacketData)) return;
    let recordData = memoOpenPacketData;

    recordData = recordData.forEach((records) => {
      records.groupData = {};
      records.isMemoFooter = true;
      records.isMatchFooter = false;
      records.isMatchHeader = false;

      TOTAL_COLUMNS.forEach((columnId) => {
        records.groupData[columnId] = [];
        memoOpenPacketData.forEach((record) => {
          if (!record[columnId]) return;
          records.groupData[columnId].push(record[columnId]);
        });
      });
    });
  }, [memoOpenPacketData]);

  return (
    <div className={`container-memo ${isLoading ? "loading-indicator" : ""}`}>
      <div className="top-menu">
        <DatePickerRange
          date={[moment().subtract(30, "days"), moment()]}
          title={"Filter By Date :"}
          onChange={(val) => handleDateRange(val)}
          dateFormat="MM/DD/YYYY"
        />
        <div className="radio-btn-container">
          <Radio.Group value={memoRadio} onChange={(e) => handleMemoRadio(e)}>
            <Radio defaultChecked={true} value={`open_memo_packet`}>
              {`Open Memo Packet`}
            </Radio>

            <Radio value={`open`}>{`Open`}</Radio>

            <Radio value={`history`}>{`History`}</Radio>
          </Radio.Group>
        </div>
      </div>

      {memoRadio === `open` && isNotEmpty(memoMainDataArray) && (
        <div className="main-section open open-new">
          <div className="memo-info-container info-container-new">
            <div className="memo-info1 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Date</p>
            </div>

            <div className="memo-info2 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Memo No.</p>
            </div>

            <div className="memo-info3 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Days</p>
            </div>

            <div className="memo-info6 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Tot Pcs</p>
            </div>

            <div className="memo-info7 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Tot Cts</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Tot Amount</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Ret Pcs</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Ret Cts</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Ret Amount</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Bal Pcs</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Bal Cts</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Bal Amount</p>
            </div>

            <div className="memo-info4 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Due Date</p>
            </div>

            <div className="memo-info4 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Contact</p>
            </div>

            <div className="memo-info4 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Sales Rep</p>
            </div>

            <div className="memo-info4 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Logistics</p>
            </div>

            <div className="memo-info5 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Tracking ID</p>
            </div>
          </div>

          {memoRadio === `open` &&
            isNotEmpty(memoMainDataArray) &&
            memoMainDataArray.map((item) => {
              return (
                <div className="memo-info-container info-container-new2">
                  <div className="memo-info1 memoBox">
                    <p>
                      {item.MemoDate
                        ? moment(item.MemoDate).format("MM-DD-YYYY")
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info2 memoBox">
                    <p>{item.Memono ? item.Memono : "-"}</p>
                  </div>

                  <div className="memo-info3 memoBox">
                    <p>{item.days ? item.days : "-"}</p>
                  </div>

                  <div className="memo-info6 memoBox">
                    <p>{item.TotPcs ? item.TotPcs : "-"}</p>
                  </div>

                  <div className="memo-info7 memoBox">
                    <p>{item.Totwt ? item.Totwt : "-"}</p>
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>{item.TotValue ? item.TotValue.toFixed(2) : "-"}</p>
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>
                      {item.RetPcs !== undefined && item.RetPcs !== null
                        ? item.RetPcs
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>
                      {item.RetCts !== undefined && item.RetCts !== null
                        ? item.RetCts.toFixed(2)
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>
                      {item.RetValue !== undefined && item.RetValue !== null
                        ? item.RetValue.toFixed(2)
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>
                      {item.BalPcs !== undefined && item.BalPcs !== null
                        ? item.BalPcs
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>
                      {item.BalCts !== undefined && item.BalCts !== null
                        ? item.BalCts.toFixed(2)
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>
                      {item.BalValue !== undefined && item.BalValue !== null
                        ? item.BalValue.toFixed(2)
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>
                      {item.DueDate
                        ? moment(item.DueDate).format("MM-DD-YYYY")
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info4 memoBox">
                    <p>
                      {item.ContactName
                        ? startCase(lowerCase(item.ContactName))
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info4 memoBox">
                    <p>
                      {item.SalesmanName
                        ? startCase(lowerCase(item.SalesmanName))
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info4 memoBox">
                    <p>{item.Logistic ? item.Logistic : "-"}</p>
                  </div>

                  <div className="memo-info5 memoBox">
                    <p>{item.Trackingid ? item.Trackingid : "-"}</p>
                  </div>

                  <CommonModal
                    modalSize="md-size"
                    title="Memo Detail"
                    handleCancel={handleModalClose}
                    visible={isModalOpen}
                   
                  >
                    <div className="container-memo-modal">
                      <div className="memo-modal-top">
                        <div className="memo-modal-infoBox">
                          <p>
                            Date:{" "}
                            <span>
                              {item.MemoDate
                                ? moment(item.MemoDate).format("MM-DD-YYYY")
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Memo No.:{" "}
                            <span>{item.Memono ? item.Memono : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Days: <span>{item.days ? item.days : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Total Pcs:{" "}
                            <span>{item.TotPcs ? item.TotPcs : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Total Cts:{" "}
                            <span>{item.Totwt ? item.Totwt : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Total Amount:{" "}
                            <span>{item.TotValue ? item.TotValue : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Ret Pcs:{" "}
                            <span>
                              {item.RetPcs !== undefined && item.RetPcs !== null
                                ? item.RetPcs
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Ret Cts:{" "}
                            <span>
                              {item.RetCts !== undefined && item.RetCts !== null
                                ? item.RetCts.toFixed(2)
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Ret Amount:{" "}
                            <span>
                              {item.RetValue !== undefined &&
                              item.RetValue !== null
                                ? item.RetValue.toFixed(2)
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Bal Pcs:{" "}
                            <span>
                              {item.BalPcs !== undefined && item.BalPcs !== null
                                ? item.BalPcs
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Bal Cts:{" "}
                            <span>
                              {item.BalCts !== undefined && item.BalCts !== null
                                ? item.BalCts.toFixed(2)
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Bal Amount:{" "}
                            <span>
                              {item.BalValue !== undefined &&
                              item.BalValue !== null
                                ? item.BalValue.toFixed(2)
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Logistics:{" "}
                            <span>{item.Logistic ? item.Logistic : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Tracking ID:{" "}
                            <span>
                              {item.Trackingid ? item.Trackingid : "-"}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="memo-modal-table searchResultTable">
                        <Table
                          data={memoModalData}
                          columns={memoModalCols}
                          areAllChecked={false}
                          // noGrp
                          canSort={false}
                          nostatus={true}
                          nocheck={true}
                          noCheckBox={true}
                          nodots={true}
                          // currentType={currentType}
                        />
                      </div>
                    </div>
                  </CommonModal>

                  <div
                    id="action-container"
                    className="tracking-details-container action-container"
                  >
                    <div className="FedEx-img">
                      <a
                        href={`https://tracking.malca-amit.com/${item.Trackingid}`}
                        target="_blank"
                      >
                        <img
                          src={require("../../assets/MemoSvg/trackIcon.svg")}
                          alt="FedEx Icon"
                        />
                      </a>
                    </div>

                    <div className="memo-info-popup">
                      <img
                        src={require("../../assets/MemoSvg/eyeIcon.svg")}
                        alt="Memo Info Icon"
                        onClick={(e) => handleModalOpen(e)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

          <div className="memo-info-container info-container-new">
            <div className="memo-info1 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info2 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info3 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info6 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info7 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>
                {total.total_balpieces ? total.total_balpieces : 0}
              </p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>
                {total.total_balcarat
                  ? parseFloat(total.total_balcarat).toFixed(2)
                  : 0}
              </p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>
                {total.total_balvalue
                  ? parseFloat(total.total_balvalue).toFixed(2)
                  : 0}
              </p>
            </div>

            <div className="memo-info4 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info4 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info4 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info4 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info5 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>
          </div>
        </div>
      )}

      {memoRadio === `open` && !isNotEmpty(memoMainDataArray) && (
        <div className="No-data-message">No Data to show.</div>
      )}

      {memoRadio === `open_memo_packet` && (
        <div className={`open_memo_packet`}>
          <div
            className={`searchResultTable ${
              isEmpty(memoOpenPacketData) ? "memo-modal-table" : ""
            }`}
          >
            <Table
              data={memoOpenPacketData}
              columns={memoPocketColumns}
              areAllChecked={false}
              // noGrp
              canSort={false}
              nostatus={true}
              nocheck={true}
              noCheckBox={true}
              nodots={true}
              memoType={`open_memo_packet`}
              // currentType={currentType}
            />
          </div>
        </div>
      )}

      {memoRadio === `open_memo_packet` && !isNotEmpty(memoOpenPacketData) && (
        <div style={{ paddingTop: "10px" }} className="No-data-message">
          No Data to show.
        </div>
      )}

      {memoRadio === `history` && isNotEmpty(memoHistoryDataArray) && (
        <div className="main-section open open-new">
          <div className="memo-info-container info-container-new">
            <div className="memo-info1 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Date</p>
            </div>

            <div className="memo-info3 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Memo No.</p>
            </div>

            <div className="memo-info7 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Tot Pcs</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Tot Cts</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Tot Amount</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Contact</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Sales Rep</p>
            </div>

            <div className="memo-info2 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Close Date</p>
            </div>

            <div className="memo-info5 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Logistics</p>
            </div>

            <div className="memo-info6 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Tracking ID</p>
            </div>
          </div>

          {memoRadio === `history` &&
            isNotEmpty(memoHistoryDataArray) &&
            memoHistoryDataArray.map((item) => {
              return (
                <div className="memo-info-container info-container-new2">
                  <div className="memo-info1 memoBox">
                    <p>
                      {item.MemoDate
                        ? moment(item.MemoDate).format("MM-DD-YYYY")
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info3 memoBox">
                    <p>{item.Memono ? item.Memono : "-"}</p>
                  </div>

                  <div className="memo-info7 memoBox">
                    <p>{item.TotPcs ? item.TotPcs : "-"}</p>
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>{item.Totwt ? item.Totwt.toFixed(2) : "-"}</p>
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>{item.TotValue ? item.TotValue.toFixed(2) : "-"}</p>
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>
                      {item.ContactName
                        ? startCase(lowerCase(item.ContactName))
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>
                      {item.SalesmanName
                        ? startCase(lowerCase(item.SalesmanName))
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info2 memoBox">
                    <p>
                      {item.MemoCloseDate
                        ? moment(item.MemoCloseDate).format("MM-DD-YYYY")
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info5 memoBox">
                    <p>{item.Logistic ? item.Logistic : "-"}</p>
                  </div>

                  <div className="memo-info6 memoBox">
                    <p>{item.Trackingid ? item.Trackingid : "-"}</p>
                  </div>

                  <CommonModal
                    modalSize="md-size"
                    title="Memo History"
                    handleCancel={handleHistoryModalClose}
                    visible={isMemoHistoryOpen}
                   
                  >
                    <div className="container-memo-modal">
                      <div className="memo-modal-top">
                        <div className="memo-modal-infoBox">
                          <p>
                            Date:{" "}
                            <span>
                              {item.MemoDate
                                ? moment(item.MemoDate).format("MM-DD-YYYY")
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Memo No.:{" "}
                            <span>{item.Memono ? item.Memono : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Total Pcs:{" "}
                            <span>{item.TotPcs ? item.TotPcs : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Total Cts:{" "}
                            <span>
                              {item.Totwt ? item.Totwt.toFixed(2) : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Total Amount:{" "}
                            <span>
                              {item.TotValue ? item.TotValue.toFixed(2) : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Contact:{" "}
                            <span>
                              {item.ContactName
                                ? startCase(lowerCase(item.ContactName))
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Sales Rep:{" "}
                            <span>
                              {item.SalesmanName
                                ? startCase(lowerCase(item.SalesmanName))
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Close Date:{" "}
                            <span>
                              {item.MemoCloseDate
                                ? moment(item.MemoCloseDate).format(
                                    "MM-DD-YYYY",
                                  )
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Logistics:{" "}
                            <span>{item.Logistic ? item.Logistic : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Tracking ID:{" "}
                            <span>
                              {item.Trackingid ? item.Trackingid : "-"}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="memo-modal-table searchResultTable">
                        <Table
                          data={memoHistoryPopUpData}
                          columns={memoHisPopupCols}
                          areAllChecked={false}
                          canSort={false}
                          nostatus={true}
                          nocheck={true}
                          noCheckBox={true}
                          nodots={true}
                        />
                      </div>
                    </div>
                  </CommonModal>

                  <div className="open-pocket-popup">
                    <img
                      src={require("../../assets/MemoSvg/eyeIcon.svg")}
                      alt="Memo Info Icon"
                      onClick={(e) => handleHistoryModalOpen(e)}
                    />
                  </div>
                </div>
              );
            })}

          <div className="memo-info-container info-container-new">
            <div className="memo-info1 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info3 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info7 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>
                {total.total_pieces ? total.total_pieces : 0}
              </p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>
                {total.total_carat
                  ? parseFloat(total.total_carat).toFixed(2)
                  : 0}
              </p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>
                {total.total_value
                  ? parseFloat(total.total_value).toFixed(2)
                  : 0}
              </p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info2 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info5 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>

            <div className="memo-info6 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}></p>
            </div>
          </div>
        </div>
      )}

      {memoRadio === `history` && !isNotEmpty(memoHistoryDataArray) && (
        <div className="No-data-message">No Data to show.</div>
      )}
      {oTPModel.visible && (
        <CommonModal
          visible={oTPModel.visible}
          handleCancel={() => history.goBack()}
          title={"OTP Verification"}
        >
          <div className="otpModal">
            <div className="searchPopupCommon">
              <div className="searchPopupTwoValue">
                <InputBlock
                  type="number"
                  label={"OTP :"}
                  placeholder={"Enter OTP here.."}
                  value={oTPModel.value}
                  onChange={(e) =>
                    setOTPModel({ ...oTPModel, value: e.target.value })
                  }
                />
                <div className="commonModalButton">
                  <button className="fillButton" onClick={() => verifyOTP()}>
                    Submit
                  </button>
                  <button
                    className="outLineButton"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                </div>
                <div className="resend_otp text-center">
                  <a onClick={() => sendOTP()}>Resend OTP click here.</a>
                </div>
              </div>
            </div>
          </div>
        </CommonModal>
      )}
    </div>
  );
}

export default Memo;
