/* eslint-disable */
import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Router, Route, Switch, useLocation } from "react-router-dom";
import AppLocale from "../lngProvider";
import { IntlProvider } from "react-intl";

import SignIn from "../components/SignIn";
import SignUp from "../components/SignUp";
import QuickRegister from "components/QuickRegister";
import GuestLogin from "../components/GuestLogin";
import ForgotPassword from "../components/Auth/ForgotPassword";
import ResetPassword from "../components/Auth/ResetPassword";
import VerifyOtp from "../components/VerifyOtp";
import ThankYouAfterSignup from "../components/ThankYou";
import EmailVerify from "../components/EmailVerify";
import VerifyDevice from "../components/VerifyDevice";
import DiamondDetail from "../components/DiamondDetail";
import DNA from "../components/DNA";
import ViewImage from "../components/DNA/ViewImage";
import Career from "../components/Career";
import CareerDetail from "../components/Career/CareerDetail";
import News from "../components/News";
import BiddingProcedure from "../components/BiddingProcedure";
import BookAppointment from "../components/BookAppointment";
import TermsOfUse from "../components/TermsOfUse";
import Feedback from "../components/Feedback";
import DiamondFullView from "../components/DiamondFullView";
import DiamondVideoView from "../components/DiamondVideoView";
import "../styles/App.less";
import "../components/auth.less";
import FrontRoute from "./Routes";
import ContactUs from "../components/ContactUs";
import OnlineKYC from "../components/OnlineKYC";
import AboutUs from "../components/AboutUs";
import XRAY from "../components/XRAY";
import FTC from "../components/FTC";
import AdminRedirect from "../components/AdminRedirect";
import PrivacyNotice from "../components/PrivacyNotice";
import PageNotFound from "../components/PageNotFound";
import isEmpty from "lodash/isEmpty";

// new css
import "../styles/Header.less";
import "../styles/DiamondList.less";
import "../styles/DiamondDetail.less";
import "../styles/Account.less";
import "../styles/QuickSearch.less";
import "../styles/Compare.less";
import "../styles/DiamondListingSidePopup.less";
import "../styles/OfficePopup.less";
import "../styles/savedSeach.less";
import "../styles/DiamondSearch.less";
import "../styles/mobileHeader.less";
import "../styles/MadeInBotswana.less";
import "../styles/Dashboard.less";

import "../styles/DiamondListMobile.less";
import "../styles/DiamondDetailMobile.less";
import "../styles/DiamondSearchMobile.less";
import "../styles/mobileAccount.less";
import "../styles/notificationSetting.less";
import { LOCAL_STORAGE_VAR } from "../constants/Common";
import UtilService from "../services/util";
import { history } from "util/history";

const RestrictedRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`) ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/dashboard"} />
        )
      }
    />
  );
};

function App() {
  const locale = useSelector((state) => state.commonData.locale);

  const token = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`);

  React.useEffect(() => {
    if (!window.drift) return;
    window.drift.on("campaign:dismiss", () => {});
    if (window.drift && window.drift.api) window.drift.api.widget.hide();
  }, []);

  const currentAppLocale = React.useMemo(() => {
    return AppLocale[locale?.locale ?? "en"];
  }, [locale]);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <Router history={history}>
        <Switch>
          <Route path="/admin-redirect" component={AdminRedirect} />
          <Route exact path="/verify-otp" component={VerifyOtp} />
          <RestrictedRoutes path="/" component={SignIn} exact />
          <RestrictedRoutes path="/signin" component={SignIn} />
          <RestrictedRoutes path="/signup" component={SignUp} />
          <RestrictedRoutes path="/device/signup" component={SignUp} />
          <RestrictedRoutes path="/guest-login" component={GuestLogin} />
          <RestrictedRoutes path="/quick-signup" component={QuickRegister} />
          <RestrictedRoutes path="/device/quick-signup" component={QuickRegister} />
          <RestrictedRoutes
            path="/forgot-password"
            component={ForgotPassword}
          />
          <RestrictedRoutes
            path="/reset-password/:token"
            component={ResetPassword}
          />
          <Route path="/thank-you" component={ThankYouAfterSignup} />
          <Route path="/verify-email" component={EmailVerify} />
          <Route path="/verify-device" component={VerifyDevice} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/not-found" component={PageNotFound} />
          <Route path="/xrays/:stoneId" component={XRAY} />
          <Route path="/DiamFTC" component={FTC} />
          <Route path="/diamond-details/" component={DiamondDetail} />
          <Route path="/dna" component={DNA} />
          <Route path="/viewImage" component={ViewImage} />
          <Route path="/career" component={Career} />
          <Route path="/career-detail" component={CareerDetail} />
          <Route exact path="/news" component={News} />
          <Route exact path="/terms-condition" component={TermsOfUse} />
          {/* <Route exact path="/bidding-procedure" component={BiddingProcedure} /> */}
          <Route exact path="/book-appointment" component={BookAppointment} />
          <Route exact path="/feedback" component={Feedback} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/kyc-form" component={OnlineKYC} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route path="/diamondfullview" component={DiamondFullView} />
          <Route path="/diamondVideoview" component={DiamondVideoView} />
          <Route
            path="/device/contact-us"
            component={() => <ContactUs isDevice={true} />}
          />
          <Route
            exact
            path="/device/terms-condition"
            component={() => <TermsOfUse isDevice={true} />}
          />
          <Route exact path="/privacy-notice" component={PrivacyNotice} />
          <Route
            exact
            path="/device/privacy-notice"
            component={() => <PrivacyNotice isDevice={true} />}
          />
          <FrontRoute />
          {isEmpty(token) && <Redirect from="*" to="/signin" />}
        </Switch>
      </Router>
    </IntlProvider>
  );
}

export default React.memo(App);
