/* eslint-disable */
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { cloneDeep, isEmpty, isEqual, xorWith, sum, parseInt } from "lodash";
import { Popover, Button, Modal } from "antd";
import Table, { LIMIT } from "../TableBack";
import { isIpade } from "../DiamondListFunctions";
import { LOCAL_STORAGE_VAR } from "../../../constants/Common";
import NoDataShow from "../../common/NoDataShow";
import UtilService from "../../../services/util";
import Loader from "../../../components/common/Loader";
import moment from "moment";
import _ from "lodash";
import { formatNumber } from "../../common/DiamondListing/SelectStone";
import {
  COMING_SOON_DIAMONDS,
  COMING_SOON_DIAMONDS_INTERESTED,
} from "../../../constants/apiConstant";
import "./comingSoon.less";
const { confirm } = Modal;

function getElementByClass(name) {
  if (
    document.getElementsByClassName(name) &&
    document.getElementsByClassName(name)[0]
  )
    return document.getElementsByClassName(name)[0];
  else return null;
}

export function getGridHeight(allClasses, initial) {
  let allContainers = {};
  allClasses.map((classname) => {
    allContainers[classname] = getElementByClass(classname)
      ? getElementByClass(classname)
      : 0;
    return true;
  });
  let totalHeight = window.innerHeight - initial;
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}

export function emptyData(loading, length) {
  if (loading) return <NoDataShow message={<Loader loading={true} fixed />} />;
  else if (length === 0) return <NoDataShow />;
  else return <></>;
}

export function getState() {
  const stateVar = {
    listview: true,
    data: [],
    page: 1,
    limit: LIMIT,
    sum: {},
    columns: [],
    count: 0,
    loading: false,
    inTrackDiamonds: [],
    defaultChecked: [],
    inBlockDiamonds: [],
  };
  return stateVar;
}

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

const ComingSoonDiamondList = (props) => {
  const { location } = props;
  const tableRef = useRef();
  const currentType = useRef(location.pathname);
  const [state, setState] = useState(() => cloneDeep(getState()));
  const sortRef = useRef([]);
  const [gridHeight, setGridHeight] = useState(0);

  let isaccount = useMemo(
    () => location.pathname.split("/")[1] === "account",
    [location],
  );
  const USER_PERMIT = UtilService.getLocalStorageItem(
    `${LOCAL_STORAGE_VAR}-userPermissions`,
  );
  const user = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

  useEffect(() => {
    setState({ ...state, loading: true, data: [] });
    const timeout = setTimeout(() => {
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [state.page, state.limit]);

  const setResponse = (res) => {
    let columns = res.gridColumns?.map((s) => {
      s["accessor"] = s?.accessor || s?.field;
      s["Header"] = s?.Header || s?.title;
      delete s?.width;
      s =
        s.accessor === "vStnId"
          ? {
              ...s,
              Cell({ cell }) {
                // return row.original.selectionKey;
                return <span className="line-height">{cell.value}</span>;
              },
            }
          : _.includes(
              [
                "crt",
                "length",
                "width",
                "height",
                "depPer",
                "tblPer",
                "cAng",
                "cHgt",
                "pAng",
                "pHgt",
                "ratio",
              ],
              s.accessor,
            )
          ? {
              ...s,
              Cell({ cell }) {
                // return row.original.selectionKey;
                return cell.value && parseFloat(cell.value || 0).toFixed(2);
              },
            }
          : _.includes(["rap"], s.accessor)
          ? {
              ...s,
              Cell({ cell }) {
                // return row.original.selectionKey;
                return cell.value && formatNumber(cell.value || 0);
              },
            }
          : s.accessor === "action"
          ? {
              ...s,
              Cell({ row }) {
                // return row.original.selectionKey;
                let srNo = row.id || row.index;
                srNo = sum([parseInt(srNo), 1]);
                if (isEmpty(row.original.alrInt)) {
                  return (
                    <>
                      <b>{srNo || row.original.sr}</b> &nbsp;{" "}
                      <Button
                        size="small"
                        style={{ width: "110px" }}
                        type="primary"
                        className="bottomStickyButton"
                        onClick={() => interestedAction(row.original)}
                      >
                        I am Interested
                      </Button>
                    </>
                  );
                } else {
                  return (
                    <>
                      <b>{row.original.sr}</b> &nbsp;{" "}
                      <Popover
                        placement="right"
                        content={
                          <div>
                            You already make your interest for this stone at{" "}
                            {moment(row.original.alrInt[0].intDate).format(
                              "YYYY-MM-DD",
                            )}
                          </div>
                        }
                      >
                        <Button
                          size="small"
                          style={{ width: "110px" }}
                          type="danger"
                          className="bottomStickyButton"
                        >
                          Interested
                        </Button>
                      </Popover>
                    </>
                  );
                }
              },
            }
          : {
              ...s,
              Cell({ cell }) {
                if (cell.value) {
                  return cell.value;
                } else {
                  return <span>-</span>;
                }
              },
            };
      return s;
    });

    if (res) {
      let diamondData = res.list || [],
        defaultChecked = [];
      setState({
        ...state,
        ...res,
        data: diamondData,
        defaultChecked,
        columns,
        loading: false,
      });
      // document.body.classList.remove("loading-indicator");
    } else {
      setState({ ...state, loading: false, defaultChecked: [] });
    }
  };

  const fetch = useCallback(() => {
    setState({ ...state, loading: true, data: [] });
    new Promise((resolve, reject) => {
      let obj = {
        ...COMING_SOON_DIAMONDS,
        request: {
          gridColumnName: "GRID_COMING_SOON_DIAMOND_LIST",
          user: user,
        },
      };
      UtilService.callApi(obj, function (err, data) {
        if (data && data.code === "OK") {
          setResponse(data?.data);
          // this.setState({ data: data?.data?.list || null })
        } else {
          setState({
            ...state,
            data: [],
            loading: false,
          });
        }
        resolve(true);
      });
    });
  }, [state.page, state.limit, sortRef.current]);

  const interestedAction = useCallback(
    (row) => {
      confirm({
        title: `Do you Want to show your interest for Stone ${row.vStnId}?`,
        style: { top: 0 },
        content: (
          <div className="modelTableView">
            <ul>
              <li>
                <span>Shape</span>
                <span>{row.shpNm}</span>
              </li>
              <li>
                <span>Carat</span>
                <span>{row.crt}</span>
              </li>
              <li>
                <span>Color</span>
                <span>{row.colNm}</span>
              </li>
              <li>
                <span>Clarity</span>
                <span>{row.clrNm}</span>
              </li>
              <li>
                <span>Shade</span>
                <span>{row.shdNm}</span>
              </li>
            </ul>
          </div>
        ),
        onOk() {
          setState({ ...state, loading: true });
          new Promise((resolve, reject) => {
            let obj = {
              ...COMING_SOON_DIAMONDS_INTERESTED,
              request: {
                diamonds: row,
                user: user,
              },
            };
            UtilService.callApi(obj, function (err, data) {
              if (data && data.code === "OK") {
              }
              fetch();
              // setState({ ...state, loading: false });
              resolve(true);
            });
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    [fetch],
  );

  useEffect(() => {
    let gridHeight = isaccount
      ? isIpade
        ? getGridHeight(
            [
              "headerTopMenu",
              "accountTopBlock",
              "accountTabBox",
              "filterTopMainBlock",
              "diamondListingAction",
              "covidStrip",
              "HeaderStripBlock",
              "smallHeaderWrapper",
            ],
            20,
          )
        : getGridHeight(
            [
              "headerTopMenu",
              "accountTopBlock",
              "accountTabBox",
              "filterTopMainBlock",
              "diamondListingAction",
              "covidStrip",
              "HeaderStripBlock",
              "smallHeaderWrapper",
            ],
            22,
          )
      : getGridHeight(
          [
            "diamondListingAction",
            "headerTopMenu",
            "selectStonHeader",
            "covidStrip",
            "HeaderStripBlock",
          ],
          12,
        );
    setGridHeight(gridHeight);
  }, [state, window.innerHeight]);

  return (
    <>
      <div className="searchResultListWrapper profilePage">
        <div className="selectStonHeader">
          <div className="d-flex justify-content-between align-items-center width-100 listingTopBlock">
            <h2 class="mainHeadingTitle ">
              <span>Coming Soon</span> ({state.count})
            </h2>
          </div>
        </div>
        <div className="searchInnerResult">
          <div
            className="searchResultTable profilePageTable"
            style={{ height: gridHeight, overflow: "auto" }}
          >
            <Table
              data={state.data}
              columns={state.columns}
              loading={state.loading}
              ref={tableRef}
              currentType={currentType.current}
              nostatus
              nocheck
              noCheckBox
              nodots
              canSort
              // useSortBy={false}
            />
            {emptyData(state.loading, state.data.length)}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((props) => {
  return props;
}, {})(React.memo(withRouter(ComingSoonDiamondList)));
