import antdSA from "antd/lib/locale-provider/de_DE";
import appLocaleData from "react-intl/locale-data/de";
import daMessages from "../locales/de_DE.json";

const deLang = {
  messages: {
    ...daMessages,
  },
  antd: antdSA,
  locale: "de",
  data: appLocaleData,
};
export default deLang;
