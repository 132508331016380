/* eslint-disable */
import React from "react";
import "./staticContent.less";
import Heading from "../common/Heading";
import StaticHeader from "../StaticHeader";
import Header from "../Header";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";

const TermsOfUse = (props) => {
  return (
    <>
      {!props.isDevice && (
        <>
          {
            // localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) !== null ? (
            //   <Header />
            // ) :
            <StaticHeader />
          }
        </>
      )}
      <div className="staticBoxMain">
        <div className="statiContentBox">
          <Heading
            title="Terms & Conditions"
            className="staticMainTitle text-center mb-50"
          />

          <div className="staticContentWrapper">
            <Heading
              title="Terms and Conditions (T&C)"
              className="popupInnerTitle"
            />
            <div className="staticList">
              <ul className="mb-0">
                <li>
                  {/* BELGIUM NY LLC is the solitary proprietor of the website belgiumny.com.
                  The entire content of the website together with text, site design, logos and data
                  provided in the software, graphics and audios, Videos, icons, images etc. are
                  entirely owned by the company.{" "} */}
                  Please ensure that the following Terms & Conditions have been
                  read and understood, prior to using our website. It is
                  important to review the below mentioned Terms & Condition
                  prior to purchasing or selecting stones on belgiumny.com.
                </li>
                {/* <li>
                  By registering on the website belgiumny.com you are granted permission to
                  browse, download and print the content for your private reference only. However,
                  you do not hold the right to change/modify/alter any content you have downloaded.
                  Registration does not grant any other right on you.{" "}
                </li>
                <li>
                  If you want to use any content of this website for commercial purposes, a prior
                  written permission needs to be obtained from the company. This document will
                  specify the scope and extent up to which the downloaded content may be used.
                </li>
                <li>
                  The purchased stones will be shipped to be delivered at your doorstep via courier
                  services such as Brinks, MalcaAmit, BVC Express, PS, or Fed EX on C&F basis{" "}
                </li>
                <li>First Come First Serve basis is used for online purchasing.</li>
                <li>
                  You are committed and bound to complete the sale once stones have been purchased.
                </li>
                <li>
                  The company will verify details once it receives an order from the client, and if
                  all conditions are met, only then will the order be processed.
                </li>
                <li>Courier agents will cover door to door insurance.</li>
                <li>
                  The company reserves the right to refuse shipments to certain international
                  destinations.
                </li> */}
                {/* <ul>
                  <p>
                    <b>Shipping and Handling Charges;</b>
                  </p>
                  <li>For Invoices below US$15000 = US$125</li>
                  <li>For Invoices above US$15000 = Free</li>
                </ul> */}
                {/* <li>
                  Shipping charges borne by us for invoices above US$15000 cover shipment from our
                  Mumbai office to our affiliate office in (Hong Kong, New York, Dubai, Antwerp &
                  Japan) & for remaining location to their nearest International Airport. Shipment
                  cost from the nearest International Airport to the buyer's residence will be borne
                  by the buyer.
                </li>
                <li>
                  For shipments to destinations in India, additional charges will apply in lieu of
                  local taxes, if any.
                </li> */}
                {/* <ul>
                  <p>
                    <b>Cancellation of orders</b>
                  </p>
                  <li>
                    Our company representative will reconfirm the order details once an order is
                    placed by you.
                  </li>
                  <li>
                    The order is deemed to have been completed the moment the stones are shipped.
                    Cancellation of the order thereafter is not possible.
                  </li>
                  <li>Exchanges and returns are not permitted.</li>
                  <li>
                    The order expires on the 5th working day of confirmation of order, if the
                    advance has not been received.
                  </li>
                </ul>
                <li>
                  BELGIUM NY LLC reserves right to modify/change/alter/delete/add to any of the
                  conditions listed above without prior notice.
                </li> */}
              </ul>
            </div>
          </div>
          <div className="staticContentWrapper">
            <Heading title="Terms of Service" className="popupInnerTitle" />
            <div className="staticList">
              <ul>
                <li>
                  Welcome to the e-commerce site of BELGIUM NY LLC. You
                  can search, select and purchase the diamond/diamonds you want,
                  all within this site. To permit access to this site, it is
                  necessary for you to agree with aforementioned Terms &
                  Conditions.
                </li>
                <li>
                  Once access is granted after you agree to the terms and
                  conditions (T&C), the T&C govern your relationship with
                  BELGIUM NY LLC, to the exclusion of any other
                  agreement/document you may have signed with the company
                  previously and currently at the time of your accessing and
                  using the website. Not only your purchases at the site, but
                  your future visits and use of our company's services or the
                  services of other affiliated companies will also fall under,
                  and be governed by the same T&C; unless they are found
                  contrary the guidelines and conditions already followed by
                  them, in which case those conditions only will prevail. As far
                  as the access to the website and purchases made on it are
                  concerned, T&C will have precedence over any other document.
                </li>
                <li>
                  Your order for purchase of goods chosen by you subsequent to
                  browsing the site amounts to an offer for purchase subject to
                  standard site restrictions, merchant specific restrictions and
                  T&C.
                </li>
                <li>
                  BELGIUM NY LLC reserves the right to amend/modify/remove any
                  of the clauses in T&C at its own discretion without any prior
                  notice to you. It is therefore recommended that you go through
                  the T&C from time to time to keep yourself updated with any
                  changes. It is understood that by agreeing to T&C, you have
                  agreed to the modification in T&C that may take place from
                  time to time.
                </li>
                <li>
                  BELGIUM NY LLC is the solitary proprietor of the website
                  www.belgiumny.com. The entire content of the website together
                  with text, site design, logos and data provided in the
                  software, graphics and audios, videos, icons, images etc. are
                  entirely owned by the company.
                </li>
                <li>
                  By registering on the website belgiumny.com, you are granted
                  permission to browse, download and print the content for your
                  private reference only. However, you do not hold the right to
                  change/modify/alter any content you have downloaded.
                  Registration does not grant any other right to you.
                </li>
                <li>
                  If you want to use any content of this website for commercial
                  purposes, a prior written permission needs to be obtained from
                  the company. This document will specify the scope and extent
                  up to which the downloaded content may be used.
                </li>
                <li>
                  The purchased stones will be shipped to be delivered at your
                  doorstep via courier services such as FedEX, Malca-Amit, UPS,
                  Brinks, etc.
                </li>
                <li>
                  First Come First Serve basis is used for online purchasing.
                </li>
                <li>
                  You are committed and bound to complete the sale once stones
                  have been purchased.
                </li>
                <li>
                  The company will verify details once it receives an order from
                  the client, and if all the conditions are met, only then, will
                  the order be processed.
                </li>
                <li>
                  Courier agents will cover door to door insurance. The company
                  reserves the right to refuse shipments to certain
                  international destinations.
                </li>
                <ul>
                  <p>
                    <b>Shipping and Handling Charges;</b>
                  </p>
                  <li>For Invoices below US$15000 = US$125</li>
                  <li>For Invoices above US$15000 = Free</li>
                </ul>
                <li>
                  Shipping charges borne by us for invoices above US$15000 cover
                  shipment from our Mumbai office to our affiliate office in
                  (Hong Kong, New York, Dubai, Antwerp & Japan) & for remaining
                  location to their nearest International Airport. Shipment cost
                  from the nearest International Airport to the buyer's
                  residence will be borne by the buyer.
                </li>
                <li>
                  For shipments to destinations in India, additional charges
                  will apply in lieu of local taxes, if any.
                </li>
                <ul>
                  <p>
                    <b>Cancellation of orders</b>
                  </p>
                  <li>
                    Our company representative will reconfirm the order details
                    once an order is placed by you.
                  </li>
                  <li>
                    The order is deemed to have been completed the moment the
                    stones are shipped. Cancellation of the order thereafter is
                    not possible.
                  </li>
                  <li>Exchanges and returns are not permitted.</li>
                  <li>
                    The order expires on the 5th working day of confirmation of
                    order, if the advance has not been received.
                  </li>
                </ul>
                <li>
                  BELGIUM NY LLC reserves the right to
                  modify/change/alter/delete/add to any of the conditions listed
                  above without prior notice.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
