/* eslint-disable */
import React, { Component } from "react";
import TopBanner from "./TopBanner";
import QuickMenu from "./QuickMenu";
import OneBanner from "./OneBanner";
import TwoBanner from "./TwoBanner";
import FooterSticky from "./FooterSticky";
import LeftRightBlock from "./LeftRightBlock";
import OneBannerGif from "./OneBannerGif";
import Footer from "./Footer";
import {
  GET_DASHBOARD_DATA,
  GET_BANNERS,
  GET_TIME,
  USER_PERFORMANCE,
  DASHBOARD_DETAILS,
  GET_STOCK_ANNALYSIS_DATA,
} from "../../constants/apiConstant";
import UtilService from "../../services/util";
import { connect } from "react-redux";
import { salesPersonAction } from "../../appRedux/actions/DashActions";
import { BANNERS_TYPES } from "../../constants/Common";
import _ from "lodash";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import sortBy from "lodash/sortBy";
import BrandLogo from "../common/BrandLogo";
import Loader from "../common/Loader";
import isEmpty from "lodash/isEmpty";
import Chart from "./Chart";
import PieChart from "./Chart/PieChart";
import moment from "moment";
import { isNotEmpty } from "util/utils";
import { Modal } from "antd";
import LoginBanner from "./Banner/LoginBanner";
import OpenNotification from "components/common/CommonButton/OpenNotification";
import './loginbanner.less';

class Dashoard extends Component {
  state = {
    dashData: undefined,
    bannerData: undefined,
    isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`),
    isLoading: true,
    quarterlyPerformance: null,
    inventoryShapeWiseCount: [],
    reportDate: 1,
    poster: null,
    showModal: true,
  };
  componentDidMount() {
    Promise.all([
      this.getDashboardDetails(),
      this.getDashBoardData(),
      this.getBannerData(),
      this.getPerformanceData(),
      this.getStockAnnalysisData(),
    ]).then(() => {
      //hide loader
      this.setState({ isLoading: false });
    });
  }

  componentDidUpdate(prevprops, prevState) {
    if (prevState.reportDate !== this.state.reportDate) {
      this.getDashboardDetails();
    }
  }
  //get dashboard data for chart
  getDashboardDetails = () => {
    return new Promise((resolve, reject) => {
      let self = this;
      let obj = {
        ...DASHBOARD_DETAILS,
        request: {
          quarterlyPerformance: {
            date:
              this.state.reportDate === 1
                ? moment().add(-3, "months").toISOString()
                : this.state.reportDate === 2
                  ? moment().add(-6, "months").toISOString()
                  : this.state.reportDate === 3
                    ? moment().add(-12, "months").toISOString()
                    : moment().toISOString(),
            // 'to': moment().toISOString(),
          },
        },
      };
      UtilService.callApi(obj, function (err, data) {
        if (data && data.code === "OK") {
          self.setState({
            quarterlyPerformance: data?.data?.quarterlyPerformance || null,
          });
        }
        resolve(true);
      });
    });
  };
  //get stock Annalisys data for chart
  getStockAnnalysisData = () => {
    return new Promise((resolve, reject) => {
      let self = this;
      let obj = {
        ...GET_STOCK_ANNALYSIS_DATA,
        request: {},
      };
      UtilService.callApi(obj, function (err, data) {
        if (data && data.code === "OK") {
          self.setState({
            inventoryShapeWiseCount: data?.data?.inventoryShapeWiseCount || [],
          });
        }
        resolve(true);
      });
    });
  };
  //get performance data for chart
  getPerformanceData = () => {
    let obj = {
      ...USER_PERFORMANCE,
      request: {
        date: moment()
          .add(-12 + 1, "months")
          .toISOString(),
      },
    };
    UtilService.callApi(obj, function (err, data) { });
  };
  // Get dashboard data
  getDashBoardData = () => {
    return new Promise((resolve, reject) => {
      let self = this;
      let obj = {
        ...GET_DASHBOARD_DATA,
        request: {
          // savedSearch: true,
          // recentSearch: true,
          // recentActivity: true,
          // track: true,
          // dashboardCount: true,
          shippingCount: true,
          seller: true,
          account: true,
          quoteDay: true,
          rates: true,
        },
      };
      UtilService.callApi(obj, function (err, data) {
        if (err) {
          self.setState({ dashData: undefined });
        } else if (data && data.code === "OK") {
          if (data.data) {
            if (data.data.quoteDay) {
              localStorage.setItem(
                `${LOCAL_STORAGE_VAR}-quoteDay`,
                JSON.stringify(data.data.quoteDay),
              );
            }
            self.setState({ dashData: data.data });
            self.props.throwSalesData(data.data.seller);
            UtilService.setLocalStorageItem(
              data.data.seller,
              `${LOCAL_STORAGE_VAR}-sales`,
            );
          }
        } else {
          localStorage.remove(`${LOCAL_STORAGE_VAR}-quoteDay`);
          self.setState({ dashData: undefined });
        }
        resolve(true);
      });
    });
  };

  // Get dashboard data
  getOtherData = (activity) => {
    return new Promise((resolve, reject) => {
      let obj = {
        ...GET_DASHBOARD_DATA,
        request: {
          ...(activity === "savedSearch" ? { savedSearch: true } : {}),
          ...(activity === "recentSearch" ? { recentSearch: true } : {}),
          ...(activity === "recentActivity" ? { recentActivity: true } : {}),
        },
      };
      this.setState({ footerloading: true });
      UtilService.callApi(obj, (err, data) => {
        if (err) {
          this.setState({ dashData: undefined, footerloading: false });
        } else if (data && data.code === "OK") {
          if (data.data) {
            const keyToUpdate =
              activity === "savedSearch"
                ? "savedSearch"
                : activity === "recentSearch"
                  ? "recentSearch"
                  : activity === "recentActivity"
                    ? "recentActivity"
                    : "";
            this.setState({
              dashData: {
                ...this.state.dashData,
                [keyToUpdate]: data && data.data && data.data[keyToUpdate],
              },
            });
          }
          this.setState({ footerloading: false });
        } else {
          localStorage.remove(`${LOCAL_STORAGE_VAR}-quoteDay`);
          this.setState({ dashData: undefined });
        }
        resolve(true);
      });
    });
  };

  getBannerData = () => {
    return new Promise((resolve, reject) => {
      // Define the API request object
      const apiRequest = {
        ...GET_BANNERS,
        request: { page: 1, limit: 100, filter: { bannerType: 1 } },
      };

      // Make the API call using UtilService.callApi
      UtilService.callApi(apiRequest, (error, responseData) => {
        // Set loading state to true while waiting for the API response
        this.setState({ loading: true });

        if (error) {
          // Handle API error
          OpenNotification({
            type: 'error',
            title: error?.message,
          });
          this.setState({ bannerData: undefined });
          reject(error);
        } else if (responseData && responseData?.code === 'OK') {
          // Process the successful response
          if (responseData?.data) {
            // Sort the banner data by type
            const sortedData = sortBy(responseData?.data?.list, 'type');
            // Update the component state with the sorted data
            this.setState({ bannerData: sortedData, poster: sortedData });
          }
        } else {
          // Handle unexpected response format or other issues
          OpenNotification({
            type: 'error',
            title: responseData?.message,
          });
          this.setState({ bannerData: undefined });
          reject(responseData);
        }

        // Set loading back to false after the API call is complete
        this.setState({ loading: false });

        // Resolve the Promise
        resolve(true);
      });
    });
  };

  getBanner = (type) => {
    return this.state?.bannerData?.find(
      (item) => parseInt(item?.type) === parseInt(type),
    );
  };

  handleModalClose = () => {
    // const isFirstLogin = localStorage.getItem("loginPopup") !== "true";

    // Set loginPopup flag to "true" for the first login, and "false" otherwise
    localStorage.setItem("loginPopup", true);

    this.setState({ showModal: false });
  };

  render() {
    let {
      dashData,
      isLoading,
      bannerData,
      quarterlyPerformance,
      inventoryShapeWiseCount,
    } = this.state;
    let isGuest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);
    const topBanner = this.getBanner(BANNERS_TYPES?.["CLIENT_PAGE_BANNER"]);
    const thirdLeftBanner = this.getBanner(
      BANNERS_TYPES?.["CLIENT_PAGE_POPUP_1"],
    );
    const thirdRightBanner = this.getBanner(
      BANNERS_TYPES?.["CLIENT_PAGE_POPUP_2"],
    );
    const bottomLeftBanner = this.getBanner(
      BANNERS_TYPES?.["CLIENT_PAGE_POPUP_3"],
    );
    const bottomRightBanner = this.getBanner(
      BANNERS_TYPES?.["CLIENT_PAGE_POPUP_4"],
    );
    const loginBanner = this.getBanner(BANNERS_TYPES?.['ADMIN_LOGIN_BANNER']);
    return (
      <>
        <div className={`dashboardWrapper`}>
          {!isGuest && <QuickMenu dashData={dashData} isLoading={isLoading} />}
          {/* {bannerData?.[BANNERS_TYPES?.["HOMEPAGE_TOP"]]?.isActive && (
            <TopBanner bannerData={bannerData[BANNERS_TYPES["HOMEPAGE_TOP"]]} />
          )} */}
          <BrandLogo />
          {/* {bannerData?.[BANNERS_TYPES?.["HOMEPAGE_SECOND"]]?.isActive && (
            <OneBanner
              bannerData={bannerData?.[BANNERS_TYPES?.["HOMEPAGE_SECOND"]]}
            />
          )} */}
          {topBanner?.isActive && <LeftRightBlock bannerData={topBanner} />}
          <div
            className="dashboardItem"
            style={{
              display: "flex",
              flexDirection: window.innerWidth <= 625 ? "column" : "none",
            }}
          >
            <div
              className="halfBlock"
              style={{
                width:
                  window.innerWidth <= 625
                    ? "100%"
                    : isEmpty(inventoryShapeWiseCount)
                      ? "100%"
                      : "60%",
              }}
            >
              <Chart
                data={quarterlyPerformance}
                update={(e) => this.setState({ reportDate: e })}
              />
            </div>
            {!isEmpty(inventoryShapeWiseCount) && (
              <div
                className="halfBlock"
                style={{ width: window.innerWidth <= 625 ? "100%" : "40%" }}
              >
                <PieChart
                  value={inventoryShapeWiseCount}
                  title={`Shapewise Purchase`}
                />
              </div>
            )}
          </div>

          <TwoBanner
            leftBanner={thirdLeftBanner?.isActive ? thirdLeftBanner : null}
            rightBanner={thirdRightBanner?.isActive ? thirdRightBanner : null}
          />

          <TwoBanner
            leftBanner={bottomLeftBanner?.isActive ? bottomLeftBanner : null}
            rightBanner={bottomRightBanner?.isActive ? bottomRightBanner : null}
          />

          {/* {bannerData?.[BANNERS_TYPES?.["HOMEPAGE_BOTTOM"]]?.isActive && (
            <OneBannerGif
              bannerData={bannerData?.[BANNERS_TYPES?.["HOMEPAGE_BOTTOM"]]}
            />
          )} */}

          <Footer />
          <FooterSticky
            getDashBoardData={this.getOtherData}
            isGuest={this.state.isGuest}
            footerloading={this.state.footerloading}
            dashData={dashData}
          />

          {
            (isNotEmpty(this.state?.poster) && loginBanner?.isActive && !localStorage.getItem('loginPopup')) ? (
              <div className='loginContainerBanner'>
                <Modal
                  visible={this.state?.showModal}
                  onCancel={this.handleModalClose}
                  footer={null}
                  className="login-bannerModal"
                >
                  {this.state?.poster ? (
                    <React.Fragment>
                      {loginBanner?.isActive ? <LoginBanner bannerData={loginBanner} /> : null}
                      {/* COOL BANNER */}
                    </React.Fragment>
                  ) : (
                    null
                  )}
                </Modal>
              </div>

            ) : null
          }
        </div>
      </>
    );
  }
}

// export default Dashoard;

const mapDispatchToProps = (dispatch) => {
  return {
    throwSalesData: (data) => dispatch(salesPersonAction(data)),
  };
};
export default connect(null, mapDispatchToProps)(Dashoard);
