/* eslint-disable */
import React from "react";
import { BASE_URL_ATTACH, LOCAL_STORAGE_VAR } from "../../../constants/Common";
import FileUploaded from "../../common/FileUploaded";
import DefaultUser from "../../common/UserProfileDefault";
import find from "lodash/find";
import moment from "moment";

const DetailItem = (props) => {
  return (
    <div className="detail">
      <span>
        <img src={props.image} alt="" />
      </span>
      <span>{props.value}</span>
    </div>
  );
};

const CardView = (props) => {
  const personalDetail = (props && props.pDetails) || {};
  const companyDetail = (props && props.cDetails) || {};

  const { BUSINESS_TYPE, DOC_TYPE_PERSONAL, DOC_TYPE_BUSINESS } = JSON.parse(
    localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`),
  );

  return (
    <div className="KycCardBlock">
      <div className="kycDetailBox d-flex flex-wrap">
        <div className="kycUploadDoc">
          {props.companyDetail &&
            (companyDetail.profileImage ? (
              <img
                src={`${BASE_URL_ATTACH}/${companyDetail.profileImage}`}
                alt=""
              />
            ) : (
              <DefaultUser size="md-size" />
            ))}
          {props.personalDetails &&
            (personalDetail.profileImage ? (
              <img
                src={`${BASE_URL_ATTACH}/${personalDetail.profileImage}`}
                alt=""
              />
            ) : (
              <DefaultUser size="md-size" />
            ))}
        </div>
        <div className="kycDetailItem">
          {props.companyDetail && (
            <>
              <div>
                <div className="companyNameCode">
                  {companyDetail.account &&
                    companyDetail.account.companyName && (
                      <span>{companyDetail.account.companyName}</span>
                    )}
                  {companyDetail.account &&
                    companyDetail.account.vendorCode && (
                      <span>({companyDetail.account.vendorCode})</span>
                    )}
                </div>
                {props.companyDetail &&
                  companyDetail.account &&
                  companyDetail.account.businessType && (
                    <div className="detail mt-5">
                      <span>Business Type: </span>
                      <span>
                        {
                          find(BUSINESS_TYPE, {
                            id: [companyDetail.account.businessType],
                          })?.name
                        }
                      </span>
                    </div>
                  )}
                {companyDetail.account && companyDetail.account.companyName ? (
                  <div className="detail mt-5">
                    <span>Company Name: </span>
                    <span>{companyDetail.account.companyName}</span>
                  </div>
                ) : (
                  companyDetail.companyName && (
                    <div className="detail mt-5">
                      <span>Company Name: </span>
                      <span>{companyDetail.companyName}</span>
                    </div>
                  )
                )}
              </div>
              {companyDetail.account && companyDetail.account.address && (
                <>
                  <div className="detail mt-5">
                    <span>Company Address: </span>
                    {companyDetail.account.address &&
                      `${companyDetail.account.address}
                    ${companyDetail.account.landMark}`}
                  </div>
                </>
              )}
              {companyDetail.account && companyDetail.account.zipCode && (
                <>
                  <div className="detail mt-5">
                    <span>ZipCode: </span>
                    {companyDetail.account.zipCode &&
                      companyDetail.account.zipCode}
                  </div>
                </>
              )}
              {/* {cd.account.vendorCode && (
                <>
                  <div className="detail mt-5">
                    <span>Company Code: </span>
                    {cd.account.vendorCode && <p>{cd.account.vendorCode}</p>}
                  </div>
                </>
              )} */}
            </>
          )}
          {props.companyDetail ? null : (
            <>
              <DetailItem
                image={require("../../../assets/svg/Profile/user.svg")}
                value={`${
                  personalDetail.firstName
                    ? personalDetail.firstName
                    : personalDetail.name
                } ${personalDetail.lastName ? personalDetail.lastName : ""} `} //`${personalDetail.firstName} ${personalDetail.lastName}`
              />
              <div className="d-flex">
                {(personalDetail.mobile || personalDetail.whatsapp) && (
                  <div className="mr-20">
                    {personalDetail.mobile && (
                      <DetailItem
                        image={require("../../../assets/svg/Profile/call.svg")}
                        value={
                          personalDetail.countryCode
                            ? `+${personalDetail.countryCode} ${personalDetail.mobile}`
                            : personalDetail.mobile
                        }
                      />
                    )}
                    {personalDetail.whatsapp && (
                      <DetailItem
                        image={require("../../../assets/svg/Profile/whatsapp.svg")}
                        value={personalDetail.whatsapp}
                      />
                    )}
                  </div>
                )}
                {(personalDetail.email || personalDetail.skype) && (
                  <div>
                    {personalDetail.email && (
                      <DetailItem
                        image={require("../../../assets/svg/Profile/email.svg")}
                        value={personalDetail.email}
                      />
                    )}
                    {personalDetail.skype && (
                      <DetailItem
                        image={require("../../../assets/svg/Profile/skype.svg")}
                        value={personalDetail.skype}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="d-flex mt-10">
                {personalDetail.createdAt && (
                  <div className="mr-20">
                    <div className="detail">
                      <span>Register Date:</span>
                      <span>
                        {moment(personalDetail.createdAt).format("DD-MM-YYYY")}
                      </span>
                    </div>
                  </div>
                )}
                {personalDetail.dateOfJoin && (
                  <div className="mr-20">
                    <div className="detail">
                      <span>Date Of Join :</span>
                      <span>
                        {moment(personalDetail.dateOfJoin).format("DD-MM-YYYY")}
                      </span>
                    </div>
                  </div>
                )}
                {personalDetail.account &&
                  personalDetail.account.verifyDate && (
                    <div>
                      <div className="detail">
                        <span>Activation Date:</span>
                        <span>
                          {moment(personalDetail.account.verifyDate).format(
                            "DD-MM-YYYY",
                          )}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </>
          )}
        </div>
        <div className="detailEditBloxk" onClick={props.onClick}>
          <img src={require("../../../assets/svg/Profile/edit.svg")} alt="" />
        </div>
      </div>
      {props.companyDetail ? null : (
        <>
          {personalDetail.companyAddress && (
            <div className="kycDetailBox mt-10 addressBlock">
              <div className="kycDetailItem">
                <div className="detail">
                  <span>
                    <img
                      src={require("../../../assets/svg/Profile/location.svg")}
                      alt=""
                    />
                  </span>
                  <span>
                    <p>{personalDetail.companyAddress}</p>
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div className="d-flex">
        {props.personalDetails &&
          personalDetail?.userKyc
            ?.filter(({ type }) => type === "personal")
            ?.map(({ path, docType }) => (
              <FileUploaded
                title={
                  DOC_TYPE_PERSONAL?.find(({ id }) => id?.[0] === docType)?.name
                }
                file={`${BASE_URL_ATTACH}/${path}`}
                className="mt-20"
              />
            ))}
        {props.companyDetail &&
          companyDetail?.account?.kyc
            ?.filter(({ type }) => type === "business")
            ?.map(({ path, docType }) => (
              <FileUploaded
                title={
                  DOC_TYPE_BUSINESS?.find(({ id }) => id?.[0] === docType)?.name
                }
                file={`${BASE_URL_ATTACH}/${path}`}
                className="mt-20"
              />
            ))}
      </div>
    </div>
  );
};
export default CardView;
