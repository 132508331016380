/* eslint-disable */
import React from "react";
import DiamondTotalCard from "./DiamondTotalCard";
import { getPath, LISTINGPAGES } from "../../DiamondList/DiamondListFunctions";
import moment from "moment";
import CheckBox from "../../common/CheckBox";
import useRowSelect from "./../../../util/useRowSelect";

const TableGrouping = (props) => {
  return (
    <>
      {!props.row.isMatchFooter && (
        <div className="tableGropupingBox">
          <div className="tableGroupTitle">
            {!window.location.pathname.includes("account/memo") &&
              !props.row.memoNo && (
                <span className="tableGroupTitleInner">
                  {props.Collapse && (
                    <div className="tableCollapse" onClick={props.onClick}>
                      <img src={props.img} alt="" />
                    </div>
                  )}
                  {props.multipleCheckBox && (
                    <div className="checkBoxSelect">{props.children}</div>
                  )}
                  {props.row && props.row.groupingTitle && (
                    <span>{props.row.groupingTitle}</span>
                  )}
                  {props.row && props.row.memoNo && (
                    <span>{"-" + props.row.memoNo}</span>
                  )}
                  {props.row &&
                    props.row.isOfferHeader &&
                    props.row.offerValidDate && (
                      <span>
                        {moment(props.row.offerValidDate).format("DD/MM/YYYY")}
                      </span>
                    )}
                  {props.row && props.row.isUpcomingHeader && (
                    <span>
                      {moment(props.row.inDt).format("DD/MM/YYYY")} 3 PM IST
                    </span>
                  )}
                  {props.row && props.row.isOfficeHeader && (
                    <span>
                      {moment(props.row.date).format("DD/MM/YYYY")} - [
                      {moment(props.row.cabinSlot[0].start).format("hh:mm A")} -{" "}
                      {moment(props.row.cabinSlot[0].end).format("hh:mm A")}]
                    </span>
                  )}
                </span>
              )}
            <div className="tableHeaderMain">
              {/* {props.row ? props.row.totalDiamonds : 0} Diamond */}
            </div>
          </div>
        </div>
      )}
      <tr className="tableGroupingTr">
        {!props.columns && (
          <td className="groupingTableAmount" style={{ color: "transparent" }}>
            0
          </td>
        )}
        {props.columns
          ? props.columns.map((x) => {
              return (
                <td className="groupingTableAmount">
                  {props.displayTotal.includes(x.id) && (
                    <DiamondTotalCard row={props.row} col={x.id} />
                  )}
                </td>
              );
            })
          : null}
      </tr>
    </>
  );
};
export default TableGrouping;
