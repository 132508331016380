/* eslint-disable */
import React, { useState } from "react";
import { Tooltip, Radio, Select } from "antd";
const { Option } = Select;

const NotifyListItem = (props) => {
  const [email, emailSelect] = useState(true);
  const [push, pushSelect] = useState(true);
  const [sms, smsSelect] = useState(false);
  const [volum, volumSelect] = useState(false);
  const emailClick = () => {
    emailSelect(!email);
  };
  const pushClick = () => {
    pushSelect(!push);
  };
  const smsClick = () => {
    smsSelect(!sms);
  };
  const volumClick = () => {
    volumSelect(!volum);
  };
  return (
    <div className="notifiDetails">
      <div className="notifiDetailItem">
        <div className="notificationtooltipBlock">
          <span>{props.title}</span>
        </div>
      </div>
      {props.dueDates ? (
        <div className="notifiDetailItem">
          <div className="notifiSelect">
            <Select defaultValue="1" style={{ width: 200 }}>
              <Option value="1">5 Days</Option>
              <Option value="2">15 Days</Option>
              <Option value="3">20 Days</Option>
              <Option value="4">30 Days</Option>
              <Option value="5">2 Month</Option>
            </Select>
          </div>
        </div>
      ) : (
        <div className="notifiDetailItem">-</div>
      )}
      <div className="notifiDetailItem">
        <div className="notificationMTwoImg">
          {email ? (
            <img
              src={require("../../../assets/svg/setting/check-off.svg")}
              onClick={emailClick}
              alt=""
            />
          ) : (
            <img
              src={require("../../../assets/svg/setting/check.svg")}
              onClick={emailClick}
              alt=""
            />
          )}
        </div>
      </div>
      <div className="notifiDetailItem">
        <div className="notificationMTwoImg">
          {push ? (
            <img
              src={require("../../../assets/svg/setting/check-off.svg")}
              onClick={pushClick}
              alt=""
            />
          ) : (
            <img
              src={require("../../../assets/svg/setting/check.svg")}
              onClick={pushClick}
              alt=""
            />
          )}
          {volum ? (
            <img
              src={require("../../../assets/svg/setting/volum.svg")}
              className="volumeImg"
              onClick={volumClick}
              alt=""
            />
          ) : (
            <img
              src={require("../../../assets/svg/setting/volum-off.svg")}
              className="volumeImg"
              onClick={volumClick}
              alt=""
            />
          )}
          {/* <span className="requireFile">*</span> */}
        </div>
      </div>
      <div className="notifiDetailItem">
        {sms ? (
          <img
            src={require("../../../assets/svg/setting/check-off.svg")}
            onClick={smsClick}
            alt=""
          />
        ) : (
          <img
            src={require("../../../assets/svg/setting/check.svg")}
            onClick={smsClick}
            alt=""
          />
        )}
      </div>
    </div>
  );
};
export default NotifyListItem;
