/* eslint-disable */
import React from "react";

const SortingFilterDropdownItem = (props) => {
  return (
    <div className="llSortingTitleItem">
      <span className="sqvanceImage">
        <img
          src={require("../../../assets/svg/DiamondList/sorting-light.svg")}
          alt="icon"
        />
      </span>
      <span className="selectSortingTitle">{props.title}</span>
      <div className="shortingRoundInner">
        <img
          src={require("../../../assets/svg/DiamondList/sortingup-theme.svg")}
          alt="icon"
        />
        <img
          src={require("../../../assets/svg/DiamondList/sortingdown-theme.svg")}
          alt="icon"
        />
      </div>
    </div>
  );
};
export default SortingFilterDropdownItem;
