/* eslint-disable */
import React, { Component } from "react";
import "./headerstrip.less";
import LangDropdown from "./LangDropdown";
import { Select, Dropdown } from "antd";
import IntlMessages from "../../../../util/IntlMessages";
import SalesPerson from "../../../common/SalesPerson";
import {
  LOCAL_STORAGE_VAR,
  BASE_URL_ATTACH,
  PRICE_CALCULATOR,
} from "../../../../constants/Common";
import { connect } from "react-redux";
import OpenNotification from "../../../common/CommonButton/OpenNotification";
import DefaultUser from "../../UserProfileDefault";
import isEmpty from "lodash/isEmpty";
import PriceCalculator from "../../../PriceCalculator";
import UtilService from "services/util";
import { GET_COUNTRY_LIST, FETCH_USER } from "constants/apiConstant";
import ProfilePopup from "./ProfilePopup";
import { Clock } from "../Clock";

const { Option } = Select;

class HeaderStrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      rates: {},
      visible: false,
      salesOption: false,
      isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`) || false,
      currentLocale: "UTC-4",
      country: null,
      zoomPhoto: false,
      countries: [],
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
    this.getCountries();
    this.fetchUser();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  salesDropdown = () => {
    this.setState({
      salesOption: !this.state.salesOption,
    });
  };

  tick() {
    this.setState({
      date: new Date(),
    });
  }

  HeaderStirpItem = (props) => {
    return (
      <div className="headerStripItem">
        <span>{props.title}</span>
        {props.country && (
          <Select
            value={this.state.currentLocale}
            dropdownClassName="timeLagSelectDropdown"
            className="timeLagSelect"
            onChange={(value) => this.setState({ currentLocale: value })}
          >
            <Option value="Asia/Kolkata">India</Option>
            <Option value="Asia/Hong_Kong">HK</Option>
            <Option value="America/New_York">USA</Option>
            <Option value="Asia/Dubai">Dubai</Option>
            <Option value="Asia/Jerusalem">Israel</Option>
            <Option value="Australia/Sydney">Australia</Option>
            <Option value="Asia/Tokyo">Japan</Option>
            {/* <Option value="America/New_York">Vitenam</Option> */}
          </Select>
        )}
        <span className="headerStripValue">{props.value}</span>
      </div>
    );
  };
  HeaderStirpItem1 = (props) => {
    return (
      <div className="headerStripItem">
        {/* <span>{props.title}</span> */}
        <div className="headerStripItem">
          <span>
            {/* {props.title} */}
            Location, Day & Time
          </span>
          <div className="mobile_date">
            {props.country && (
              <Select
                value={this.state.currentLocale}
                dropdownClassName="timeLagSelectDropdown"
                className="timeLagSelect"
                onChange={(value) => this.setState({ currentLocale: value })}
              >
                <Select.Option value="UTC-4">EST</Select.Option>
                <Select.Option value="UTC-7">PST</Select.Option>
                <Select.Option value="UTC-6">MST</Select.Option>
                <Select.Option value="UTC-4:00">EDT</Select.Option>
                <Select.Option value="UTC-7:00">PDT</Select.Option>
                <Select.Option value="UTC-6:00">MDT</Select.Option>

                {/* <Option value="Asia/Tokyo">Japan</Option> */}
                {/* <Option value="America/New_York">Vitenam</Option> */}
              </Select>
            )}
            {/* <span className="headerStripValue"> */}
            <Clock
              zone={this.state.currentLocale}
              fmt="ccc/HH:mm:ss"
              className="headerStripValue"
            />
            {/* </span> */}
          </div>
          {/* <span className="headerStripValue">{props.value}</span> */}
        </div>
      </div>
    );
  };
  getCountries = () => {
    let self = this;
    let obj = {
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === "OK") {
        self.setState({ countries: data.data });
      }
    });
  };
  fetchUser() {
    let self = this;
    let obj = {
      ...FETCH_USER,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err?.data?.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data?.code === "OK") {
        self.setState({ country: data?.data?.country });
      }
    });
  }

  countryIsIndia = (id) => {
    if (this.state?.countries?.length && id) {
      const country = this.state.countries.find((c) => c.id === id);
      return country && country?.code === "IND" ? true : false;
    } else return false;
  };

  render() {
    let salesData = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-sales`,
    );
    return (
      <div>
        <div className={`HeaderStripBlock ${this.props.className}`}>
          <div className="HeaderStripLeft">
            {!isEmpty(salesData) && !this.state.isGuest && (
              <Dropdown
                onVisibleChange={(trigger) => {
                  return trigger;
                }}
                overlay={
                  <div className="salesPersonDropdown">
                    <SalesPerson
                      email={
                        salesData && salesData?.email ? salesData?.email : "--"
                      }
                      phone={
                        salesData && salesData?.mobile
                          ? salesData?.mobile
                          : "--"
                      }
                      skype={
                        salesData && salesData?.skype ? salesData?.skype : "--"
                      }
                      salesData={salesData}
                    />
                  </div>
                }
                trigger={["click"]}
              >
                <div
                  className="SalesPersonWrapper"
                  onClick={(e) => e.preventDefault()}
                >
                  <>
                    <div className="salesPersonProfile">
                      {salesData && salesData?.photoId ? (
                        <img
                          className="profilePicImg"
                          src={`${BASE_URL_ATTACH}/${salesData?.photoId}`}
                          alt="profile"
                        />
                      ) : (
                        <DefaultUser />
                      )}
                    </div>
                    <div
                      className="salesPersonDetail"
                      onClick={this.salesDropdown}
                    >
                      {salesData && (
                        <span>
                          {salesData?.firstName} {salesData?.lastName}
                        </span>
                      )}
                      {this.state.salesOption ? (
                        <>
                          <img
                            className="buttonArrow"
                            src={require("../../../../assets/svg/Dashboard/up-arrow-black.svg")}
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          <img
                            className="buttonArrow"
                            src={require("../../../../assets/svg/Dashboard/down-arrow.svg")}
                            alt=""
                          />
                        </>
                      )}
                    </div>
                  </>
                </div>
              </Dropdown>
            )}
          </div>

          <div className="HeaderStripRight">
            {this.HeaderStirpItem1({
              country: true,
              title: <IntlMessages id="app.Time" />,
            })}
            {PRICE_CALCULATOR && (
              <div className="priceClaWrap">
                <a
                  className="headerStipIcon"
                  onClick={() => this.setState({ visible: true })}
                >
                  <img
                    src={require("../../../../assets/svg/PriceCalc/calculator.svg")}
                  />
                </a>
                {this.state.visible && (
                  <PriceCalculator
                    onClose={() => {
                      this.setState({ visible: false });
                    }}
                  />
                )}
              </div>
            )}
            <div className="SelectLanguage">
              <LangDropdown />
            </div>
          </div>
        </div>
        <ProfilePopup
          zoomPhoto={this.state.zoomPhoto}
          imgSrc={`${BASE_URL_ATTACH}/${salesData?.photoId}`}
          closePhoto={(e) => this.setState({ zoomPhoto: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ sales }) => {
  return {
    salesData: sales.salesPerson,
  };
};

export default connect(mapStateToProps, null)(HeaderStrip);
