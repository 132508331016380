/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import { AppContainer } from "react-hot-loader";
import { Provider } from "react-redux";

import Root from "./config/Root";
import { store } from "./appRedux/store/index";

import * as registerServiceWorker from "./registerServiceWorker";

/* eslint-disable-next-line no-extend-native */
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

ReactDOM.render(
  <Provider store={store}>
    <AppContainer>
      <Root />
    </AppContainer>
  </Provider>,
  document.getElementById("root"),
);

registerServiceWorker.register();
