/* eslint-disable */
import React from "react";
import Heading from "../Heading";
import { Radio } from "antd";
import { QUOTE } from "../DiamondListing/QuotePopup";

const QuoteSelect = (props) => {
  return (
    <div>
      <Heading title="Select Quote" className="popupInnerTitle" />
      <ul className="quoteSelectOption">
        <li>
          <Radio.Group
            value={props.quoteSelect.quote}
            onChange={(e) => {
              props.onQuoteChange(e.target.value, props.quoteSelect);
              props.onClose();
            }}
          >
            {QUOTE.map((q) => {
              return <Radio value={q}>{q}</Radio>;
            })}
          </Radio.Group>
        </li>
      </ul>
    </div>
  );
};
export default QuoteSelect;
