/* eslint-disable */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { guestUserLogin } from "../../appRedux/actions/Auth";
import IntlMessages from "../../util/IntlMessages";
import Loader from "../common/Loader";
import UtilService from "../../services/util";
import { Form, Checkbox, notification, Drawer } from "antd";
import clone from "lodash/clone";
import isEmpty from "lodash/isEmpty";
import InputBlock from "../common/InputBlock";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import axios from "axios";
import { GUEST_LOGIN_USER } from "../../constants/apiConstant";
import OpenNotification from "../common/CommonButton/OpenNotification";
import DownloadBlock from "../common/DownloadBlock";
import TermsConditionPopup from "../TermsConditionPopup";
import { handleMobileNumber } from "../../services/Commonfunction";

/* eslint jsx-a11y/anchor-is-valid: 0 */

class GuestLoginFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guestFields: {
        firstName: "",
        lastName: "",
        companyName: "",
        mobile: "",
        email: "",
        isLoading: false,
        Address: "",
      },
      checked: false,
      errors: {},
      terms: false,
    };
    this.handleGuestLogin = this.handleGuestLogin.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleGuestLogin = (e) => {
    let self = this;
    e.preventDefault();
    let reqObj = clone(self.state.guestFields);
    if (self.state.checked) {
      if (self.handleValidation()) {
        self.setState({ isLoading: true });
        let objData = {
          ...GUEST_LOGIN_USER,
          request: {
            ...reqObj,
            masters: [
              "COLOR",
              "CLARITY",
              "SHAPE",
              "CUT",
              "FLUORESCENCE",
              "SHADE",
              "LAB",
              "POLISH",
              "SYMMETRY",
              "LOCATION",
              "BLACK_INCLUSION",
              "BLACK_INCLUSION_SIDE",
              "BLACK_INCLUSION_TABLE",
              "OPEN_TABLE",
              "MILKEY",
              "WHITE_INCLUSION_CROWN",
              "WHITE_INCLUSION_SIDE",
              "OPEN_CROWN",
              "EYECLEAN",
              "OPEN_PAVILION",
              "ORIGIN",
              "BLACK_INCLUSION_CROWN",
              "H_AND_A",
              "WHITE_INCLUSION_TABLE",
              "FANCY_COLOR",
              "INTENSITY",
              "OVERTONE",
              "KEY_TO_SYMBOLS",
              "GIRDLE",
              "CULET",
              "GIRDLE_COND",
              "CULET_COND",
              "COMPANY_SIZE",
              "MIX_TINT",
              "NATURAL",
              "OPEN_INCLUSION",
            ],
          },
        };
        UtilService.callApi(objData, async function (err, data) {
          self.setState({ isLoading: false });

          if (err) {
            return OpenNotification({
              type: "error",
              title:
                err.data.message || err.data.data.message || "Failed to Login.",
            });
          }
          if (data && data.code === "OK") {
            self.getRedirection(data);
          }
        });
      }
    } else {
      OpenNotification({
        type: "error",
        title: "You must be agree to terms and condition to create an account",
      });
    }
  };

  getRedirection = (data) => {
    const user = data.data.user;
    // if (
    //   data.data.userPermissions &&
    //   (!data.data.userPermissions.data || !data.data.userPermissions.data.length)
    // ) {
    //   return OpenNotification({
    //     type: "error",
    //     title: "Failed to Login.",
    //   });
    // }
    let stepDisplay = {
      dashboard: !user.isIntoHide,
      diamondSearch: !user.isIntoHide,
      searchResult: !user.isIntoHide,
      diamondDetails: !user.isIntoHide,
    };

    const masters = data.data.masters;
    const columns = data.data.columns;
    const userPermissions = data.data.userPermissions;
    const keywords = data.data.keywords;
    const token = data.data.token.jwt;

    // localStorage.setItem(`${LOCAL_STORAGE_VAR}-token`, JSON.stringify(token));
    if (user.type === 5) {
      localStorage.setItem(`${LOCAL_STORAGE_VAR}-guest`, JSON.stringify(true));
    }

    UtilService.setLocalStorageItem(token, `${LOCAL_STORAGE_VAR}-token`);
    UtilService.setLocalStorageItem(user, `${LOCAL_STORAGE_VAR}-user`);

    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-master`,
      JSON.stringify(masters),
    );
    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-column`,
      JSON.stringify(columns),
    );
    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-keywords`,
      JSON.stringify(keywords),
    );
    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-tooltip-steps`,
      JSON.stringify(stepDisplay),
    );

    localStorage.setItem(`${LOCAL_STORAGE_VAR}-isTrusted`, data.data.isTrusted);
    let setPermit = {};
    userPermissions.data.map((x) => {
      if (x.module === "order" && user.Setting) {
        x.permissions.insert = user.Setting.Is_PlaceOrder
          ? user.Setting.Is_PlaceOrder
          : false;
      }

      if (
        x.module === "pricing" &&
        user.Setting &&
        user.Setting.Is_NoPrice !== true
      ) {
        x.permissions.view = user.Setting.Is_NoPrice !== true ? true : false;
        x.permissions.all = user.Setting.Is_NoPrice !== true ? true : false;
      }

      setPermit[x.module] = x.permissions;
      return true;
    });

    if (setPermit && !setPermit.pricing) {
      if (user.Setting && user.Setting.Is_NoPrice !== true) {
        setPermit.pricing = {};
        setPermit.pricing.view =
          user.Setting.Is_NoPrice !== true ? true : false;
        setPermit.pricing.all = user.Setting.Is_NoPrice !== true ? true : false;
      }
    }

    // localStorage.setItem(
    //   `${LOCAL_STORAGE_VAR}-userPermissions`,
    //   JSON.stringify(setPermit)
    // );
    UtilService.setLocalStorageItem(
      setPermit,
      `${LOCAL_STORAGE_VAR}-userPermissions`,
    );

    axios.defaults.headers.common["access-token"] = "JWT " + token;
    this.props.history.push("/dashboard");
  };

  handleValidation = () => {
    let formData = this.state.guestFields;
    let errors = this.state.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFields = [];

    if (!formData["firstName"] || isEmpty(formData["firstName"])) {
      blankField.push("First Name");
      errors["firstName"] = true;
      formIsValid = false;
    } else if (formData["firstName"]) {
      if (!formData["firstName"].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push("firstName");
        formIsValid = false;
        errors["firstName"] = true; //"Please enter valid First Name.";
      } else {
        errors["firstName"] = undefined;
      }
    }

    if (formData["lastName"] && !formData["lastName"].match(/^[a-zA-Z ]+$/)) {
      invalidFields.push("lastName");
      formIsValid = false;
      errors["lastName"] = true; //"Please enter valid First Name.";
    } else if (!formData["lastName"] || isEmpty(formData["lastName"])) {
      blankField.push("Last Name");
      errors["lastName"] = true;
      formIsValid = false;
    } else {
      errors["lastName"] = undefined;
    }

    if (!formData["email"] || isEmpty(formData["email"])) {
      blankField.push("Email Address");
      errors["email"] = true;
      formIsValid = false;
    } else if (formData["email"]) {
      if (
        !formData["email"].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        invalidFields.push("Email Address");
        formIsValid = false;
        errors["email"] = true; //"Please enter valid First Name.";
      } else {
        errors["email"] = undefined;
      }
    }
    if (!formData["mobile"] || isEmpty(formData["mobile"])) {
      blankField.push("Mobile Number");
      errors["mobile"] = true;
      formIsValid = false;
    } else if (!formData["mobile"].match(/^[0-9]{10,15}$/)) {
      invalidFields.push("Mobile Number");
      formIsValid = false;
      errors["mobile"] = true;
    } else {
      errors["mobile"] = undefined;
    }

    if (!formData["Address"] || isEmpty(formData["Address"])) {
      blankField.push("Address");
      errors["Address"] = true;
      formIsValid = false;
    } else {
      errors["Address"] = undefined;
    }

    if (!formData["companyName"] || isEmpty(formData["companyName"])) {
      blankField.push("Company Name");
      errors["companyName"] = true;
      formIsValid = false;
    } else if (formData["companyName"]) {
      if (!formData["companyName"].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push("Company Name");
        formIsValid = false;
        errors["companyName"] = true; //"Please enter valid First Name.";
      } else {
        errors["companyName"] = undefined;
      }
    }

    if (blankField.length > 0) {
      let arr = blankField;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Required Fields",
        placement: "topRight",
        description: outStr,
      });
    }
    if (invalidFields.length > 0) {
      let arr = invalidFields;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Invalid Fields",
        placement: "topRight",
        description: outStr,
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleOnChange = (key, value) => {
    let { guestFields } = this.state;
    guestFields[key] = value;
    this.setState(guestFields);
  };

  render() {
    const { guestFields, errors } = this.state;

    return (
      <div
        className={`loginDetail ${
          this.state.isLoading ? "loading-indicator" : ""
        } `}
      >
        <div className="loginHead">
          <h3>
            <IntlMessages id="app.Guest.login" />
          </h3>
        </div>
        {/* <div className="page-til mb-25 d-flex align-items-center">
            <h3>
              <IntlMessages id="app.Guest.login" />
            </h3>
          </div> */}
        <Loader loading={this.props.loading} />
        <Form
          autoComplete="off"
          className="signup-form px-40"
          onSubmit={this.handleGuestLogin}
        >
          <InputBlock
            onChange={(e) => this.handleOnChange("firstName", e.target.value)}
            value={guestFields.firstName}
            type="text"
            label="First Name"
            placeholder="First Name"
            hasError={errors["firstName"] ? true : false}
          />
          <InputBlock
            onChange={(e) => this.handleOnChange("lastName", e.target.value)}
            value={guestFields.lastName}
            type="text"
            label="Last Name"
            placeholder="Last Name"
            hasError={errors["lastName"] ? true : false}
          />
          <InputBlock
            onChange={(e) => this.handleOnChange("email", e.target.value)}
            value={guestFields.email}
            type="text"
            label="Email"
            placeholder="Email"
            hasError={errors["email"] ? true : false}
          />
          <InputBlock
            onChange={(e) => this.handleOnChange("mobile", e.target.value)}
            value={guestFields.mobile}
            type="number"
            label="Mobile Number"
            placeholder="Mobile Number"
            onKeyPress={handleMobileNumber}
            hasError={errors["mobile"] ? true : false}
          />
          <InputBlock
            onChange={(e) => this.handleOnChange("Address", e.target.value)}
            value={guestFields.Address}
            type="text"
            label="Address"
            placeholder="Address"
            hasError={errors["Address"] ? true : false}
          />
          <InputBlock
            onChange={(e) => this.handleOnChange("companyName", e.target.value)}
            value={guestFields.companyName}
            type="text"
            label="Company Name"
            placeholder="Company Name"
            hasError={errors["companyName"] ? true : false}
          />
          <div>
            <Checkbox
              checked={this.state.checked}
              onChange={() => {
                this.setState({ checked: !this.state.checked });
              }}
            >
              I have read and accept
            </Checkbox>
            <a
              onClick={() => this.setState({ terms: !this.state.terms })}
              className="linkTag"
            >
              <b>Terms and Conditions.</b>
            </a>
          </div>
          <Drawer
            title={false}
            onClose={() => this.setState({ terms: !this.state.terms })}
            visible={this.state.terms}
            className="diamondListinSidebar sm-size"
          >
            <TermsConditionPopup />
          </Drawer>
          {/* <div className="mt-10">
            <Checkbox>
              Promotional offers, newsletters and stock updates
            </Checkbox>
          </div> */}
          <div className="mt-30 loginButton">
            <button type="submit" className="commonButton">
              Log In
            </button>
          </div>
        </Form>

        <div className="registerLogLink">
          <IntlMessages id="app.already-have-acc" />
          &nbsp;
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (this.state.isDeviceUrl) {
                if (navigator.appVersion.includes("iPhone")) {
                  window.webkit.messageHandlers.LoginCallBack.postMessage(
                    "LoginCallBack",
                  );
                } else if (navigator.appVersion.includes("Android")) {
                  window.webkit.LoginCallBack("LoginCallBack");
                }
              } else {
                window.location.href = "/signin";
              }
            }}
          >
            <IntlMessages id="app.sign-In" />
          </a>
        </div>
        <div className="registerLogLink">
          Don’t have log in details?
          <Link to="/signup">Register</Link>
        </div>
        <DownloadBlock />
      </div>
    );
  }
}

const WrappedLoginForm = Form.create()(GuestLoginFrom);

const mapStateToProps = ({ auth, commonData }) => {
  const { token } = auth;
  const { loading } = commonData;
  return { token, loading };
};

export default connect(mapStateToProps, { guestUserLogin })(
  withRouter(WrappedLoginForm),
);
