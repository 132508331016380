/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PersonalCardView from "../PersonalInformation/PersonalCardView";
import PersonalInformation from "../PersonalInformation";
import CompanyCardView from "../CompanyInformation/CompanyCardView";
import CompanyInformation from "../CompanyInformation";
import ChangePassword from "../ChangePassword";
import DiamondColumnListing from "../ManageDiamondSequence";
import "../../common/Tab/tab.less";
import { Drawer } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import { LOCAL_STORAGE_VAR } from "../../../constants/Common";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import ChequeDetail from "../ChequeDetail";

const PERSONAL_DOCS =
  (JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)) &&
    JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`))
      .DOC_TYPE_PERSONAL) ||
  [];

const BUSINESS_DOCS =
  (JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)) &&
    JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`))
      .DOC_TYPE_BUSINESS) ||
  [];
class Profile extends Component {
  state = {
    visible: false,
    visible1: false,
    kyc: [],
    showCompanyDrawer: false,
  };

  handleKyc = (type, path, doc) => {
    const user = JSON.parse(
      window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)),
    );
    let kyc = !isEmpty(this.state.kyc)
      ? this.state.kyc
      : (user && user.account && user.account.kyc) || [];

    let dt =
      type === "personal"
        ? find(PERSONAL_DOCS, { id: doc })
        : find(BUSINESS_DOCS, { id: doc });

    if (!isEmpty(kyc)) {
      kyc = kyc.map((item) => {
        if (item.type === type) {
          return {
            ...item,
            path: !isEmpty(path) ? path : item.path,
            docType: dt.id[0],
          };
        }
        return item;
      });
    }

    this.setState({ kyc });
  };

  render() {
    let isGuest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);
    return (
      <div>
        <div>
          <Tabs className="accountTabBox">
            <TabList className="customerMaintab accountSubTab">
              <Tab>
                <IntlMessages id="app.PersonalInformation" />
              </Tab>
              <Tab>
                <IntlMessages id="app.CompanyInformation" />
              </Tab>
              {!isGuest && (
                <Tab>
                  <IntlMessages id="app.ChangePassword" />
                </Tab>
              )}
              <Tab>
                <IntlMessages id="app.ManageDiamondSequence" />
              </Tab>
              {/* {!isGuest && <Tab>Cheque Details</Tab>} */}
            </TabList>
            <TabPanel>
              <PersonalCardView
                onClose={() => this.setState({ showDrawer: false })}
                onClick={() => this.setState({ showDrawer: true })}
              />
            </TabPanel>
            <TabPanel>
              <CompanyCardView
                onClose={() => this.setState({ showCompanyDrawer: false })}
                onClick={() => this.setState({ showCompanyDrawer: true })}
              />
            </TabPanel>
            {!isGuest && (
              <TabPanel>
                <ChangePassword />
              </TabPanel>
            )}

            <TabPanel>
              <DiamondColumnListing />
            </TabPanel>
            {/* <TabPanel>
              <ChequeDetail />
            </TabPanel> */}
          </Tabs>
        </div>
        <Drawer
          title={false}
          onClose={() => this.setState({ showDrawer: false })}
          visible={!!this.state.showDrawer}
          className="diamondListinSidebar profileSidebar"
          destroyOnClose
        >
          <PersonalInformation
            kyc={this.state.kyc}
            handleKyc={this.handleKyc}
            onClose={() => this.setState({ showDrawer: false })}
          />
        </Drawer>
        <Drawer
          title={false}
          onClose={() => this.setState({ showCompanyDrawer: false })}
          visible={!!this.state.showCompanyDrawer}
          className="diamondListinSidebar profileSidebar"
          destroyOnClose
        >
          <CompanyInformation
            kyc={this.state.kyc}
            handleKyc={this.handleKyc}
            onClose={() => this.setState({ showCompanyDrawer: false })}
          />
        </Drawer>
      </div>
    );
  }
}
export default withRouter(Profile);
