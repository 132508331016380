/* eslint-disable */
import React, { Component } from "react";

class Logo extends Component {
  render() {
    return (
      <>
        <img
          width="100%"
          height="80px"
          style={{ objectFit: "contain" }}
          src={require("../../assets/img/logo.png")}
          alt="Belgium"
        />
      </>
    );
  }
}

export default Logo;
