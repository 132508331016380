/* eslint-disable */
import React from "react";

export const useBoolean = (initialValue = false) => {
  const [state, _setState] = React.useState(Boolean(initialValue));
  const setState = React.useMemo(
    () => ({
      true: () => _setState(true),
      false: () => _setState(false),
      toggle: () => _setState((v) => !v),
    }),
    [],
  );

  return [state, setState];
};
