/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import Table from "../DiamondList/TableBack";
import { getPath, LISTINGPAGES } from "../DiamondList/DiamondListFunctions";
import DiamondListingAction from "../common/DiamondListing/DiamondListingAction";

function AccountingTable(props) {
  const {
    tableData,
    tableColumn,
    dateFormat,
    loading,
    setLoading,
    fetchData,
    state,
    setState,
  } = props;
  const tableRef = useRef();
  const sortRef = useRef([]);
  const dispatch = useDispatch();
  const currentType = useRef(window.location.pathname);
  const [tableColumnNew, setTableColumnNew] = useState(null);
  // const [state, setState] = useState(() => _.cloneDeep(getState()));

  useEffect(() => {
    if (tableColumn) {
      setTableColumnNew(
        tableColumn
          .filter(({ isActive }) => isActive)
          .map((value, index) => ({
            id: value.field,
            Header: value.title,
            accessor: value.field,
            desc: value.desc,
            link: value.link,
            sort: value.sort,
            Cell: ({ row, cell }) => (cell.value ? cell.value : "-"),
            Filter: <></>,
          })),
      );
    }
  }, [tableColumn]);

  const onPaginationChange = useCallback(
    (page, limit) => {
      setLoading(true);
      setState({ ...state, page, limit });
      fetchData(page);
    },
    [state.sum],
  );

  const getAction = () => {
    return (
      <DiamondListingAction
        // cheque={getPath() === LISTINGPAGES.CONFIRM}
        handleLoader={(loading) => setLoading(loading)}
        onPaginationChange={onPaginationChange}
        {...state}
        {...props}
        currentType={currentType.current}
      />
    );
  };

  // const handleSort = (currentSort) => {
  //   if (
  //     currentSort.length !== sortRef.current.length ||
  //     JSON.stringify(currentSort) !== JSON.stringify(sortRef.current)
  //   ) {
  //     sortRef.current = currentSort;
  //     fetch();
  //   }
  // };

  const dateMomentFormat = (date) =>
    date ? moment(date).format(dateFormat) : "-";

  return (
    <div className="tableContent">
      <div
        className="TopValue"
        style={{ display: props.filterData ? "block" : "none" }}
      >
        <div className="topValueItem">
          <span>
            <strong>Total Due Amount($): </strong>
            {"0"}
          </span>
          <span>
            <strong>Total Due Amount(Rs): </strong>
            {"0"}
          </span>
        </div>
        <div className="topValueItem">
          <span>
            <strong>Pending Reconcile Amount($): </strong>
            {"0"}
          </span>
          <span>
            <strong>Pending Reconcile Amount(Rs): </strong>
            {"0"}
          </span>
        </div>
      </div>
      <div className="filterTableView">
        {tableData && tableData.length > 0 && (
          <>
            <div className="searchResultTable profilePageTable">
              <Table
                data={
                  tableData
                    ? tableData.length > 0
                      ? tableData.map((val, ind) => ({
                          ...val,
                          TRANS_DATE: dateMomentFormat(val.TRANS_DATE),
                          DUE_DATE: dateMomentFormat(val.DUE_DATE),
                          ENTRY_DATE: dateMomentFormat(val.ENTRY_DATE),
                          RECD_DATE: dateMomentFormat(val.RECD_DATE),
                          PAY_INT_DATE: dateMomentFormat(val.PAY_INT_DATE),
                          PAY_INT_ENTRY_TIME: val.PAY_INT_ENTRY_TIME
                            ? moment(val.PAY_INT_ENTRY_TIME).format(
                                "hh:mm:ss A",
                              )
                            : "-",
                        }))
                      : null
                    : {}
                }
                loading={loading}
                ref={tableRef}
                columns={tableColumnNew}
                currentType={currentType.current}
                nostatus={getPath() === LISTINGPAGES.ACCOUNTING_LIST}
                // handleSort={handleSort}
              />
            </div>
            {getAction()}
          </>
        )}
      </div>
    </div>
  );
}

export default AccountingTable;
