/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import { BASE_URL } from "../../../constants/Common";
import Slider from "react-slick";
import { browserName, isSafari } from "react-device-detect";

class TopBanner extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    };

    let data = this.props.bannerData;
    let img = this.props.bannerData.images;
    let imgPath = `${BASE_URL}${img}`;

    return (
      <>
        <div className="homeContainer">
          <div className="topBannerBlock">
            <Slider {...settings}>
              {img.map((f, index) => {
                let url = BASE_URL + f;
                if (!f.startsWith("/")) {
                  url = BASE_URL + "/" + f;
                }
                return (
                  <div key={index}>
                    {["mp4", "mkv", "wmv"].includes(
                      f.split(".").pop().toLowerCase(),
                    ) ? (
                      browserName !== "Firefox" && (
                        <div className="topbannerImage">
                          <video loop autoPlay={!isSafari ? true : false}>
                            <source src={url}></source>
                          </video>
                        </div>
                      )
                    ) : (
                      <div className="topbannerImage">
                        <img src={url} alt="banner" />
                      </div>
                    )}
                  </div>
                );
              })}
            </Slider>

            <div className="topBannerContent">
              <div>
                {data && data.eventName && <h2>{data.eventName}</h2>}
                {data && data.description && <p>{data.description}</p>}
                {/* <Link to="/">
                  <IntlMessages id="app.showMore" />
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TopBanner;
