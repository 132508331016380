import { getColumn } from "components/DiamondList/DiamondListFunctions";
import Table from "components/DiamondList/TableBack";
import CommonModal from "components/common/CommonModal";
import DatePickerRange from "components/common/DateRangePicker/DatePickerRange";
import moment from "moment";
/* eslint-disable */
import React, { useEffect, useState } from "react";
import { GET_MEMO_DETAILS } from "../../constants/apiConstant";
import UtilService from "services/util";
import OpenNotification from "components/common/CommonButton/OpenNotification";
import { isNotEmpty } from "util/utils";
import { useHistory } from "react-router-dom";
import { sendOTPAndVerification } from "containers/APIFunctions";
import InputBlock from "components/common/InputBlock";
import Storage from "services/storage";
import { Tooltip } from "antd";

function MyPurchase() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purModalCols, setPurModalCols] = useState([]);
  const [fromDate, setFromDate] = useState(moment().subtract(30, "days"));
  const [toDate, setToDate] = useState(moment());
  const [purchaseDataArray, setPurchaseDataArray] = useState([]);
  const [purchasePopUpData, setPurchasePopUpData] = useState([]);
  const [total, setTotal] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();
  const [oTPModel, setOTPModel] = useState({
    visible: true,
    value: "",
    verify: false,
  });

  useEffect(() => {
    createPurchaseColumns();
  }, []);

  useEffect(() => {
    const getOtp = Storage.get("otp-verify");
    let otp = getOtp ? getOtp?.singlePageVerify : false;
    if (otp) {
      setOTPModel({ ...oTPModel, visible: false });
      otp && purchaseData();
      setIsLoading(false);
    } else {
      setOTPModel({ ...oTPModel, visible: true });
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!oTPModel.visible) purchaseData();
  }, [fromDate, toDate]);

  {
    /* Total row UseEffect*/
  }
  useEffect(() => {
    let tot_crt = 0;
    let tot_pcs = 0;
    let tot_val = 0;

    if (isNotEmpty(purchaseDataArray)) {
      purchaseDataArray.map((item) => {
        tot_crt += item?.Totwt && parseFloat(item?.Totwt) ? item?.Totwt : 0;
        tot_pcs += item?.TotPcs ? item?.TotPcs : 0;
        tot_val +=
          item?.TotValue && parseFloat(item?.TotValue) ? item?.TotValue : 0;
      });
    }
    let allTotal = {
      total_pieces: tot_pcs,
      total_carat: tot_crt,
      total_value: tot_val,
    };
    setTotal(allTotal);
  }, [purchaseDataArray]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (e) => {
    const tempPurMemo =
      e.currentTarget.parentNode.parentNode.parentNode.lastChild.previousSibling
        .previousSibling.firstChild.innerHTML;
    let purPopUpData = purchaseDataArray.filter((item) => {
      if (item.Memono === tempPurMemo) {
        return item;
      }
    });
    setPurchasePopUpData(purPopUpData[0]?.DiamondDetail);
    setIsModalOpen(true);
  };

  const createPurchaseColumns = () => {
    const reqPurAccessors = [
      "srNo",
      "vStnId",
      "rptNo",
      "shpNm",
      "crt",
      "colNm",
      "clrNm",
      "shdNm",
      "rap",
      "back",
      "ctPr",
      "amt",
      "cutNm",
      "polNm",
      "symNm",
      "fluNm",
      "tblPer",
      "depPer",
      "length",
      "width",
      "height",
    ];
    let tempPurCol = getColumn();
    tempPurCol = tempPurCol.filter((item) => {
      return reqPurAccessors.includes(item.accessor);
    });
    const tempElePur = tempPurCol.splice(1, 1)[0];
    tempPurCol.splice(0, 0, tempElePur);
    tempPurCol[0].Header = "Sr.";
    setPurModalCols(tempPurCol);
  };

  const purchaseData = () => {
    const purchaseDetailApi = GET_MEMO_DETAILS;
    purchaseDetailApi.request = {
      limit: 500,
      filter: {},
      trans_type: "purchase",
      from: fromDate,
      to: toDate,
    };
    setIsLoading(true);
    UtilService.callApi(purchaseDetailApi, function (err, data) {
      if (data && data?.code === "OK") {
        setIsLoading(false);
        setPurchaseDataArray(data?.data);
      } else {
        setIsLoading(false);
        OpenNotification({
          type: "error",
          title: err?.data?.message || data?.message,
        });
      }
    });
  };

  const handleDateRange = (val) => {
    if (isNotEmpty(val)) {
      setFromDate(moment(val[0]["_d"]).format("YYYY-MM-DD"));
      setToDate(moment(val[1]["_d"]).format("YYYY-MM-DD"));
    }
  };

  const sendOTP = () => {
    setIsLoading(true);
    let obj = {
      callType: "send otp",
    };
    sendOTPAndVerification(obj, (cb) => setIsLoading(cb ? false : true));
  };

  const verifyOTP = () => {
    setIsLoading(true);
    let obj = {
      otp: oTPModel.value,
      callType: "verify OTP",
    };

    sendOTPAndVerification(obj, (cb) => {
      if (cb === "OTP verified successfully") {
        const getOtp = Storage.get("otp-verify");
        const otp = Storage.set("otp-verify", {
          ...getOtp,
          singlePageVerify: true,
        });
        if (otp) {
          setOTPModel({ ...oTPModel, visible: false });
          setIsLoading(false);
          purchaseData();
        }
      } else {
        setIsLoading(false);
        OpenNotification({ type: "error", title: cb });
      }
    });
  };

  return (
    <div
      className={`container-my-purchase container-memo ${
        isLoading ? "loading-indicator" : ""
      }`}
    >
      <div className="top-menu">
        <DatePickerRange
          date={[fromDate, toDate]}
          title={"Filter By Date :"}
          onChange={(val) => handleDateRange(val)}
        />
      </div>

      {isNotEmpty(purchaseDataArray) && (
        <div className="main-section open open-new">
          <div className="memo-info-container info-container-new">
            <div className="memo-info1 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Date</p>
            </div>

            <div className="memo-info2 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Invoice No</p>
            </div>

            <div className="memo-info5 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Tot Pcs</p>
            </div>

            <div className="memo-info6 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Tot Cts</p>
            </div>

            <div className="memo-info7 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Tot Amount</p>
            </div>

            <div className="memo-info7 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Due Date</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Payment</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Contact</p>
            </div>

            <div className="memo-info8 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Sales Rep</p>
            </div>

            <div className="memo-info3 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Logistics</p>
            </div>

            <div className="memo-info4 memoBox memoBox-new">
              <p style={{ color: "#555", fontWeight: "700" }}>Tracking ID</p>
            </div>
          </div>

          {isNotEmpty(purchaseDataArray) &&
            purchaseDataArray.map((item) => {
              return (
                <div className="memo-info-container info-container-new2">
                  <div className="memo-info1 memoBox">
                    <p>
                      {item.MemoDate
                        ? moment(item.MemoDate).format("MM-DD-YYYY")
                        : "-"}
                    </p>
                  </div>

                  <div className="memo-info2 memoBox">
                    <p>{item.Memono ? item.Memono : "-"}</p>
                  </div>

                  <div className="memo-info5 memoBox">
                    <p>{item.TotPcs ? item.TotPcs : "-"}</p>
                  </div>

                  <div className="memo-info6 memoBox">
                    <p>{item.Totwt ? item.Totwt.toFixed(2) : "-"}</p>
                  </div>

                  <div className="memo-info7 memoBox">
                    <p>{item.TotValue ? item.TotValue.toFixed(2) : "-"}</p>
                  </div>

                  <div className="memo-info1 memoBox">
                    {item.PaymentDue && item.PaymentDue === true ? (
                      <p style={{ color: "red" }}>
                        {item.DueDate
                          ? moment(item.DueDate).format("MM-DD-YYYY")
                          : "-"}
                      </p>
                    ) : (
                      <p>
                        {item.DueDate
                          ? moment(item.DueDate).format("MM-DD-YYYY")
                          : "-"}
                      </p>
                    )}
                  </div>

                  <div className="memo-info8 memoBox">
                    {item.PaymentDue && item.PaymentDue === true ? (
                      <p style={{ color: "red" }}>
                        {item.Payment ? item.Payment : "-"}
                      </p>
                    ) : (
                      <p>{item.Payment ? item.Payment : "-"}</p>
                    )}
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>{item.ContactName ? item.ContactName : "-"}</p>
                  </div>

                  <div className="memo-info8 memoBox">
                    <p>{item.SalesmanName ? item.SalesmanName : "-"}</p>
                  </div>

                  <div className="memo-info3 memoBox">
                    <p>{item.Logistic ? item.Logistic : "-"}</p>
                  </div>

                  <div className="memo-info4 memoBox">
                    <p>{item.Trackingid ? item.Trackingid : "-"}</p>
                  </div>

                  <div
                    style={{ display: "none" }}
                    className="memo-info8 memoBox"
                  >
                    <p>{item.Memono ? item.Memono : "-"}</p>
                  </div>

                  <CommonModal
                    modalSize="md-size"
                    title="Purchase"
                    handleCancel={handleModalClose}
                    visible={isModalOpen}
                   
                  >
                    <div className="container-memo-modal">
                      <div className="memo-modal-top">
                        <div className="memo-modal-infoBox">
                          <p>
                            Date:{" "}
                            <span>
                              {item.MemoDate
                                ? moment(item.MemoDate).format("MM-DD-YYYY")
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Invoice No
                            <span>{item.Memono ? item.Memono : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Tot Pcs:{" "}
                            <span>{item.TotPcs ? item.TotPcs : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Tot Cts:{" "}
                            <span>{item.Totwt ? item.Totwt : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Tot Amount:{" "}
                            <span>{item.TotValue ? item.TotValue : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Due Date:{" "}
                            <span>
                              {item.DueDate
                                ? moment(item.DueDate).format("MM-DD-YYYY")
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Payment:{" "}
                            <span>{item.Payment ? item.Payment : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Contact:{" "}
                            <span>
                              {item.ContactName ? item.ContactName : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Sales Rep:{" "}
                            <span>
                              {item.SalesmanName ? item.SalesmanName : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Logistics:{" "}
                            <span>{item.Logistic ? item.Logistic : "-"}</span>
                          </p>
                        </div>

                        <div className="memo-modal-infoBox">
                          <p>
                            Tracking ID:{" "}
                            <span>
                              {item.Trackingid ? item.Trackingid : "-"}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="memo-modal-table searchResultTable">
                        <Table
                          data={purchasePopUpData}
                          columns={purModalCols}
                          areAllChecked={false}
                          canSort={false}
                          nostatus={true}
                          nocheck={true}
                          noCheckBox={true}
                          nodots={true}
                        />
                      </div>
                    </div>
                  </CommonModal>

                  <div
                    style={{ justifyContent: "flex-start" }}
                    id="action-container"
                    className="tracking-details-container"
                  >
                    {(item.TrackingLink && item.TrackingLink != '') &&
                      <Tooltip title="Tracking Link">
                        <div className="FedEx-img">
                          <a href={`${item.TrackingLink}`} target='_blank'>
                            <img src={require('../../assets/MemoSvg/trackIcon.svg')} alt='Icon' />
                          </a>
                        </div>
                      </Tooltip>
                    }

                    <Tooltip title="Purchase Details">
                      <div className="memo-info-popup">
                        <img
                          src={require("../../assets/MemoSvg/eyeIcon.svg")}
                          alt="Memo Info Icon"
                          onClick={(e) => handleModalOpen(e)}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              );
            })
          }

          {/* Total row start*/}
          {
            <div className="memo-info-container info-container-new">
              <div className="memo-info1 memoBox memoBox-new">
                <p style={{ color: "#555", fontWeight: "700" }}></p>
              </div>

              <div className="memo-info2 memoBox memoBox-new">
                <p style={{ color: "#555", fontWeight: "700" }}></p>
              </div>

              <div className="memo-info5 memoBox memoBox-new">
                <p style={{ color: "#555", fontWeight: "700" }}>
                  {total.total_pieces ? total.total_pieces : 0}
                </p>
              </div>

              <div className="memo-info6 memoBox memoBox-new">
                <p style={{ color: "#555", fontWeight: "700" }}>
                  {total.total_carat
                    ? parseFloat(total.total_carat).toFixed(2)
                    : 0}
                </p>
              </div>

              <div className="memo-info7 memoBox memoBox-new">
                <p style={{ color: "#555", fontWeight: "700" }}>
                  {total.total_value
                    ? parseFloat(total.total_value).toFixed(2)
                    : 0}
                </p>
              </div>

              <div className="memo-info7 memoBox memoBox-new">
                <p style={{ color: "#555", fontWeight: "700" }}></p>
              </div>

              <div className="memo-info8 memoBox memoBox-new">
                <p style={{ color: "#555", fontWeight: "700" }}></p>
              </div>

              <div className="memo-info8 memoBox memoBox-new">
                <p style={{ color: "#555", fontWeight: "700" }}></p>
              </div>

              <div className="memo-info8 memoBox memoBox-new">
                <p style={{ color: "#555", fontWeight: "700" }}></p>
              </div>

              <div className="memo-info3 memoBox memoBox-new">
                <p style={{ color: "#555", fontWeight: "700" }}></p>
              </div>

              <div className="memo-info4 memoBox memoBox-new">
                <p style={{ color: "#555", fontWeight: "700" }}></p>
              </div>
            </div>
          }
          {/* Total row end*/}
        </div>
      )}

      {!isNotEmpty(purchaseDataArray) && (
        <div className="No-data-message">No Data to show.</div>
      )}
      {oTPModel.visible && (
        <CommonModal
          visible={oTPModel.visible}
          handleCancel={() => history.goBack()}
          title={"OTP Verification"}
        >
          <div className="otpModal">
            <div className="searchPopupCommon">
              <div className="searchPopupTwoValue">
                <InputBlock
                  type="number"
                  label={"OTP :"}
                  placeholder={"Enter OTP here.."}
                  value={oTPModel.value}
                  onChange={(e) =>
                    setOTPModel({ ...oTPModel, value: e.target.value })
                  }
                />
                <div className="commonModalButton">
                  <button className="fillButton" onClick={() => verifyOTP()}>
                    Submit
                  </button>
                  <button
                    className="outLineButton"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                </div>
                <div className="resend_otp text-center">
                  <a onClick={() => sendOTP()}>Resend OTP click here.</a>
                </div>
              </div>
            </div>
          </div>
        </CommonModal>
      )}
    </div>
  );
}

export default MyPurchase;
