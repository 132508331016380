import * as _ from "lodash";
/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { notification } from "antd";
import moment from "moment";
import { withRouter } from "react-router-dom";
import axios from "../util/Api";
import { userSignOut } from "../appRedux/actions/Auth";
import {
  BASE_URL,
  LOCAL_STORAGE_VAR,
  FILE_TYPES_PDF,
  FILE_TYPES,
  DOWNLOAD_URL,
} from "../constants/Common";
import "../assets/scss/global.css";

import {
  commonLogout,
  fields,
  requiredFields,
  fieldsrequiredGuest,
  fieldsGuest,
} from "../constants/Common";
import {
  CREATE_ANALYTICS,
  GET_TIME,
  USER_LOGOUT,
  NEXT_BID,
} from "../constants/apiConstant";
const myUploadProgress = (data) => {
  let showProgressDoc = document.querySelectorAll(
    "#loader.APILoader .showProgress span",
  )[0];
  let showProgressMain = document.querySelectorAll(
    "#loader.APILoader .showProgress",
  )[0];
  if (showProgressDoc) {
    if (
      showProgressMain &&
      showProgressMain.classList &&
      showProgressMain.classList.contains("display-none")
    ) {
      showProgressMain.classList.remove("display-none");
    }
    showProgressDoc.innerText = `${data}%`;
    showProgressDoc.style.width = `${data}%`;
  }
};
const UtilService = {
  // roundNumer(number, place) {
  //     return +(Math.round(number + `e+${place}`) + `e-${place}`)
  // },
  x(string, start, end) {
    return string.substring(start, end);
  },
  displayDate: (date) => {
    return date ? moment(date).format("MMM DD YYYY, HH:mm:ss a") : "-";
  },
  callApi(object, cb) {
    // let header_token = JSON.parse(
    //   localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`)
    // );
    let header_token = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-token`,
    );

    let headers = {};
    if (object.isMultipart) {
      headers.transformRequest = {};
      headers.headers = {
        "Content-Type": undefined,
      };
    } else {
      headers.headers = {
        "Content-Type": "application/json",
      };
    }
    if (object.updateToken) {
      header_token = object.updateToken;
    }
    if (object.auth) {
      header_token = object.auth;
    }

    if (header_token) headers.headers.Authorization = "JWT " + header_token;

    headers.headers.devicetype = 1;

    if (object.deviceType) {
      headers.headers.devicetype = object.deviceType;
    }

    if (object.isMultipart) {
      headers.onUploadProgress = function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        myUploadProgress(percentCompleted);
      };
    }
    const URL = object?.downloadUrl ? DOWNLOAD_URL : BASE_URL;
    axios[object.method](URL + object.url, object.request, headers)
      .then(({ data }) => {
        if (data.code === "E_UNAUTHORIZED") {
          commonLogout();
          cb(null, data);
        } else {
          cb(null, data);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.code === "E_UNAUTHORIZED") {
          // commonLogout();
          cb(error.response);
        }
        if (error?.response?.data) {
          cb(error.response);
        }
      });
  },

  getFullName(user) {
    if (!user || _.isEmpty(user)) {
      return null;
    }
    user.firstName = user.firstName ? user.firstName : "";
    user.lastName = user.lastName ? user.lastName : "";
    let fullName =
      user.firstName.charAt(0).toUpperCase() +
      user.firstName.slice(1).toLowerCase() +
      " " +
      user.lastName.charAt(0).toUpperCase() +
      user.lastName.slice(1).toLowerCase();
    return fullName;
  },
  getLocalStorageItem(name) {
    let localItem = localStorage.getItem(name);
    if (localItem && localItem?.length && localItem !== "undefined") {
      let decode = decodeURIComponent(escape(window.atob(localItem)));
      try {
        return JSON.parse(decode);
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  },
  setLocalStorageItem(userString, name) {
    let encryptedString = window.btoa(
      unescape(encodeURIComponent(JSON.stringify(userString))),
    );
    localStorage.setItem(name, encryptedString);
  },
};

export const getTrackData = (str) => {
  let [page, section, action] = str.split("/");
  return {
    page,
    section,
    action,
  };
};

export const track = (data) => {
  const token = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`);
  if (token.length) {
    UtilService.callApi({
      ...CREATE_ANALYTICS,
      request: getTrackData(data),
    });
  }
};

window.track = track;

export const getShortPath = () => window.location.pathname.split("/")[1];

export const loaderActivityFn = (showLoader) => {
  let getLoaderEle = document.querySelectorAll("#loader.APILoader")[0];
  let getProgressBar = document.querySelectorAll(
    "#loader.APILoader .showProgress",
  )[0];
  if (showLoader) {
    getLoaderEle.classList.add("loading");
  } else if (
    getLoaderEle &&
    getLoaderEle.classList &&
    getLoaderEle.classList.contains("loading")
  ) {
    getLoaderEle.classList.remove("loading");
    if (
      getProgressBar &&
      getProgressBar.classList &&
      !getProgressBar.classList.contains("display-none")
    ) {
      getProgressBar.classList.add("display-none");
    }
  }
};

window.shortPath = getShortPath;

export const logoutAPICall = async (token) => {
  try {
    return axios.request({
      ...USER_LOGOUT,
      headers: { Authorization: `JWT ${token}` },
    });
  } catch (error) {}
};

export const getCurrentTime = () => {
  return new Promise((resolve, reject) => {
    let objData = { ...GET_TIME, url: `${GET_TIME.url}?${Math.random()}` };
    UtilService.callApi(objData, (err, data) => {
      if (err) reject(err);
      if (data && data.code === "OK") {
        return resolve(data.data);
      }
    });
  });
};

export const getNextBidData = () => {
  return new Promise((resolve, reject) => {
    let objData = {
      ...NEXT_BID,
    };
    UtilService.callApi(objData, (err, data) => {
      if (err) reject(err);
      if (data && data.code === "OK") {
        return resolve(data.data);
      }
    });
  });
};

export class EventManager {
  static _handleUpdate(key, callback) {
    return (event) => {
      callback(event.detail);
    };
  }

  static emit(name, detail = {}) {
    const event = new CustomEvent(name, { detail });
    window.dispatchEvent(event);
  }

  static listen(key, callback) {
    window.addEventListener(key, EventManager._handleUpdate(key, callback));
    return () => {
      window.removeEventListener(
        key,
        EventManager._handleUpdate(key, callback),
      );
    };
  }
}

export const twoDigitsWithoutRounding = (theform) => {
  var num = theform;
  var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  return with2Decimals;
};

export default UtilService;
