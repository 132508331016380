import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  MASTER_DATA_LOGIN,
  KEYWORDS_DATA,
  OTP_VERIFICATION,
  RESEND_OTP,
} from "../../constants/ActionTypes";
import Cookies from "js-cookie";
import axios from "../../util/Api";
import OpenNotification from "../../components/common/CommonButton/OpenNotification";
import UtilService from "../../services/util";
import { user } from "../../constants/Traker";
import findIndex from "lodash/findIndex";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import {
  VERIFY_FINGER_PRINT,
  RESENT_FP_OTP,
} from "../../constants/apiConstant";

export const userSignUp = (obj, cb) => {
  return (dispatch) => {
    axios
      .post("front/register", obj)
      .then(({ data }) => {
        if (data.code === "OK") {
          cb("success", data);
        } else {
          OpenNotification({
            type: "error",
            title: "Network Error",
          });
        }
      })
      .catch(function (error) {
        cb("error");
        OpenNotification({
          type: "error",
          title:
            (error.response && error.response.data.message) ||
            error.message ||
            "Something went wrong, Please try again.",
        });
      });
  };
};

export const otpVerification = ({ values, cb }) => {
  return (dispatch) => {
    var otp = Number(values.OTP);
    var trusted = Number(values.trusted);
    dispatch({ type: OTP_VERIFICATION });
    let obj = {
      // method: "post",
      // url: "/verifyfingerprint",
      ...VERIFY_FINGER_PRINT,
      request: {
        otp: otp,
        trusted: trusted,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        localStorage.setItem(`${LOCAL_STORAGE_VAR}-isTrusted`, false); //Change the value to FALSE
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        localStorage.setItem(`${LOCAL_STORAGE_VAR}-isTrusted`, true);
        window.location = "/dashboard";
        return OpenNotification({
          type: "success",
          title: "OTP verified Successfully",
        });
      }
    });
  };
};

export const resendOTP = () => {
  return (dispatch) => {
    dispatch({ type: RESEND_OTP });
    let obj = {
      ...RESENT_FP_OTP,
      // method: "post",
      // url: "/resend-fp-otp",
      request: {},
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: `Error while sending otp, ${err.data.message} `,
        });
      } else if (data && data.code === "OK") {
        window.location = "/verify-otp";
        return OpenNotification({
          type: "Success",
          title: `A new otp has been sent to your regitered e-mail address`,
        });
      }
    });
  };
};

let setPermissionUsingSettingFn = (setting, permission) => {
  setting.Is_PlaceOrder = false;
  if (setting) {
    if (setting.Is_PlaceOrder && setting.Is_PlaceOrder === false) {
      let getIndxOfOrder = findIndex(permission, { module: "order" });
      if (getIndxOfOrder >= 0) {
        permission[getIndxOfOrder].permissions.insert = false;
      }
    }
  }
  return permission;
};

export const guestUserLogin = ({ mobile, name, email, cb }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/guest/auth/login", {
        mobile: mobile,
        firstName: name,
        email: email,
        masters: [
          "COLOR",
          "CLARITY",
          "SHAPE",
          "CUT",
          "FLUORESCENCE",
          "SHADE",
          "LAB",
          "POLISH",
          "SYMMETRY",
          "LOCATION",
          "BLACK_INCLUSION",
          "OPEN_TABLE",
          "MILKEY",
          "WHITE_INCLUSION_CROWN",
          "OPEN_CROWN",
          "EYECLEAN",
          "OPEN_PAVILION",
          "ORIGIN",
          "BLACK_INCLUSION_CROWN",
          "H_AND_A",
          "WHITE_INCLUSION_TABLE",
          "WHITE_INCLUSION_TABLE",
          "FANCY_COLOR",
          "INTENSITY",
          "OVERTONE",
          "KEY_TO_SYMBOLS",
          "SIZE",
        ],
      })
      .then(({ data }) => {
        if (data.code === "OK") {
          const user = data.data.user;
          const masters = data.data.masters;
          const columns = data.data.columns;
          const userPermissions = setPermissionUsingSettingFn(
            data.data.user.Setting,
            data.data.userPermissions,
          );
          const keywords = data.data.keywords;
          const token = data.data.token.jwt;
          // const rememberMe  = data.data.rememberMe
          // localStorage.setItem(`${LOCAL_STORAGE_VAR}-token`, JSON.stringify(token));
          UtilService.setLocalStorageItem(token, `${LOCAL_STORAGE_VAR}-token`);
          // localStorage.setItem(`${LOCAL_STORAGE_VAR}-user`, JSON.stringify(user));
          UtilService.setLocalStorageItem(user, `${LOCAL_STORAGE_VAR}-user`);

          localStorage.setItem(
            `${LOCAL_STORAGE_VAR}-master`,
            JSON.stringify(masters),
          );
          localStorage.setItem(
            `${LOCAL_STORAGE_VAR}-column`,
            JSON.stringify(columns),
          );
          localStorage.setItem(
            `${LOCAL_STORAGE_VAR}-keywords`,
            JSON.stringify(keywords),
          );
          let setPermit = {};
          // eslint-disable-next-line array-callback-return
          userPermissions.data.map((x) => {
            // if (x.module === "order" && user.Setting) {
            //     x.permissions.insert = user.Setting.Is_PlaceOrder
            //         ? user.Setting.Is_PlaceOrder
            //         : false;
            // }
            // if (x.module === "order" && user.setting && user.setting.allowedOperations) {
            //     x.permissions.insert = (user.setting.allowedOperations.placeOrder) ? user.setting.allowedOperations.placeOrder : false;
            // }
            // if (x.module === "cart" && user.setting && user.setting.allowedOperations) {
            //     x.permissions.insert = (user.setting.allowedOperations.addToCart) ? user.setting.allowedOperations.addToCart : false;
            // }

            setPermit[x.module] = x.permissions;
          });
          // localStorage.setItem(`${LOCAL_STORAGE_VAR}-userPermissions`, JSON.stringify(setPermit));
          UtilService.setLocalStorageItem(
            setPermit,
            `${LOCAL_STORAGE_VAR}-userPermissions`,
          );

          axios.defaults.headers.common["access-token"] = "JWT " + token;

          dispatch({ type: USER_TOKEN_SET, payload: token });
          dispatch({ type: USER_DATA, payload: user });
          dispatch({ type: MASTER_DATA_LOGIN, payload: masters });
          dispatch({ type: KEYWORDS_DATA, payload: keywords });
          OpenNotification({
            type: "success",
            title: data.message,
          });
          Cookies.set("user", user);
          dispatch({ type: FETCH_SUCCESS });
          cb.props.history.push("/dashboard");
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        OpenNotification({
          type: "error",
          title:
            (error.response && error.response.data.message) ||
            "Something went wrong, Please try again.",
        });
        dispatch({
          type: FETCH_ERROR,
          payload:
            error.response && error.response.data
              ? error.response.data.message
              : "Something went wrong",
        });
      });
  };
};

export const getUser = (userDetails) => {
  return (dispatch) => {
    if (!userDetails) {
      // let getLoggedInUser = localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`);
      let getLoggedInUser = UtilService.getLocalStorageItem(
        `${LOCAL_STORAGE_VAR}-user`,
      );

      userDetails = getLoggedInUser ? JSON.parse(getLoggedInUser) : "";
    }
    dispatch({ type: USER_DATA, payload: userDetails });
  };
};

export const userSignOut = () => {
  // logoutAPICall();
  window.track(user.logout.complete);
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    localStorage.removeItem(`${LOCAL_STORAGE_VAR}-token`);
    localStorage.removeItem(`${LOCAL_STORAGE_VAR}-master`);
    localStorage.removeItem(`${LOCAL_STORAGE_VAR}-column`);
    localStorage.removeItem(`${LOCAL_STORAGE_VAR}-keywords`);
    localStorage.removeItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    localStorage.removeItem(`${LOCAL_STORAGE_VAR}-Cart-Counter`);
    localStorage.removeItem(`${LOCAL_STORAGE_VAR}-user`);
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
  };
};
