/* eslint-disable */
import _ from "lodash";
import UtilService from "../services/util";
import OpenNotification from "../components/common/CommonButton/OpenNotification";
import {
  SEARCH_DIAMOND_LIST,
  SEARCH_LIST,
  CREATE_SAVED_SEARCH,
  CREATE_MATCH_PAIR,
  SELECT_SEARCH,
  GET_ACCOUNTING_LIST,
  GET_ACCOUNTING_LIST_COLUMN,
  SEND_OTP,
  OTP_VERIFICATION,
} from "../constants/apiConstant";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import { LOCAL_STORAGE_VAR } from "../constants/Common";

export const handleSearch = async (filter, cb) => {
  let extraTerms = localStorage.getItem(`${LOCAL_STORAGE_VAR}-extraTerms`)
    ? JSON.parse(
        window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-extraTerms`)),
      )
    : {};
  let apiObj = {
    ...filter,
    isCert: "CERT",
    wSts: filter?.wSts ?? [
      "A",
      "M",
      "H",
      "E",
      "S",
      "A2",
      "M3",
      "M1",
      "A3",
      "T",
      "V",
      "R",
      "M2",
      "L",
      "A1",
    ],

    prcStg: {
      in: ["PRC", "UC"],
    },
    lbNm: {
      nin: ["FM"],
    },
  };
  if (!isEmpty(filter.isFm) && Object.values(filter.isFm).includes("CERT")) {
    apiObj = {
      ...apiObj,
      lbNm: "FM",
    };
  }

  if (
    !isEmpty(filter.isFm) &&
    JSON.stringify(filter.isFm) === JSON.stringify(["CERT", "ELIG"])
  )
    delete apiObj.lbNm;

  if (includes(filter.wSts, "B")) {
    delete apiObj["prcStg"];
    delete apiObj["isCert"];

    apiObj = {
      ...apiObj,
      prcStg: "NEW",
    };
  }

  if (!isEmpty(apiObj.lb)) {
    // let or = [
    //   {
    //     lbIds: apiObj.lb,
    //   },
    //   // {
    //   //   lb: apiObj.lb,
    //   // },
    // ];
    let lb = apiObj.lb;
    // let lbIds = apiObj.lb ;
    delete apiObj.lb;
    apiObj = {
      ...apiObj,
      // lbIds,
      lb,
      // or: apiObj.or ? concat(or, apiObj.or) : or,
    };
  }

  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = val;
  });

  let obj = {
    filters: [apiObj],
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };

  if (includes(apiObj.wSts, "U")) {
    apiObj.isCert && delete apiObj.isCert;
    apiObj.prcStg && delete apiObj.prcStg;
    apiObj.sSts && delete apiObj.sSts;
    obj["filters"] = [apiObj];
    obj.isUpcoming = true;
  }

  if (!isEmpty(extraTerms)) {
    obj.termType = extraTerms.termType;
    obj.termValue = extraTerms.termValue;
  }

  let objData = {
    ...SEARCH_DIAMOND_LIST,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) {
      if (err.data && err.data.message) {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      }
    } else if (data && data.code === "OK") {
      if (data.data[0].count === 0) {
        cb({ NOSTONE: "NOSTONE", id: data.data[0].filter.id });
      } else {
        cb({
          NOSTONE: "",
          id: data.data[0].filter.id,
          isNewArrival: data.data[0].newArrival,
        });
      }
    }
  });
};

export const handleMultiSearch = (filters, cb) => {
  let masterApiObj = [];
  filters.map((filter) => {
    let apiObj = {
      ...filter,
      isCert: "CERT",
      wSts: filter?.wSts ?? [
        "A",
        "M",
        "H",
        "E",
        "S",
        "A2",
        "M3",
        "M1",
        "A3",
        "T",
        "V",
        "R",
        "M2",
        "L",
        "A1",
      ],

      // prcStg: {
      //   in: ["PRC", "UC"],
      // },
      lbNm: {
        nin: ["FM"],
      },
    };

    if (!isEmpty(filter.isFm)) {
      apiObj = {
        ...apiObj,
        lbNm: "FM",
      };
    }

    if (includes(filter.wSts, "B")) {
      delete apiObj["prcStg"];
      delete apiObj["isCert"];
      apiObj = {
        ...apiObj,
        prcStg: "NEW",
      };
    }

    if (!isEmpty(apiObj.lb)) {
      // let or = [
      //   {
      //     lbIds: apiObj.lb,
      //   },
      //   // {
      //   //   lb: apiObj.lb,
      //   // },
      // ];
      let lb = apiObj.lb;
      delete apiObj.lb;
      apiObj = {
        ...apiObj,
        lb,
        // or: apiObj.or ? concat(or, apiObj.or) : or,
      };
    }

    _.each(apiObj, (val, key) => {
      if (_.isArray(val) && val.length === 0) delete apiObj[key];
      else if (_.isArray(val)) apiObj[key] = val;
    });
    masterApiObj.push(apiObj);
  });

  let obj = {
    filters: masterApiObj,
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };

  let objData = {
    ...SEARCH_DIAMOND_LIST,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) {
      if (err.data && err.data.message) {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      }
    } else if (data && data.code === "OK") {
      let ids = [];
      data.data.map((d) => ids.push(d.filter.id));
      cb({ ids });
    }
  });
};

export const getSearchList = (cb, type, params) => {
  let obj = {
    type: type ?? 2,
    isAppendMasters: true,
    ...params,
  };
  let api = SEARCH_LIST?.url;
  let objData = {
    method: SEARCH_LIST?.method,
    url: api,
    request: obj,
  };
  UtilService.callApi(objData, function (err, data) {
    if (err) throw err;
    if (data && data.code === "OK") {
      cb(data.data.list);
    }
  });
};

export const handleSavedSearch = (filter, title, id, cb) => {
  let apiObj = {
    ...filter,
    isCert: "CERT",
    wSts: filter?.wSts ?? [
      "A",
      "M",
      "H",
      "E",
      "S",
      "A2",
      "M3",
      "M1",
      "A3",
      "T",
      "V",
      "R",
      "M2",
      "L",
      "A1",
    ],

    // prcStg: {
    //   in: ["PRC", "UC"],
    // },
    lbNm: {
      nin: ["FM"],
    },
  };

  if (!isEmpty(filter.isFm)) {
    apiObj = {
      ...apiObj,
      lbNm: "FM",
    };
  }

  if (includes(filter.wSts, "B")) {
    delete apiObj["prcStg"];
    delete apiObj["isCert"];

    apiObj = {
      ...apiObj,
      prcStg: "NEW",
    };
  }

  if (!isEmpty(apiObj.lb)) {
    // let or = [
    //   {
    //     lbIds: apiObj.lb,
    //   },
    //   // {
    //   //   lb: apiObj.lb,
    //   // },
    // ];
    let lb = apiObj.lb;
    delete apiObj.lb;
    apiObj = {
      ...apiObj,
      lb,
      // or: apiObj.or ? concat(or, apiObj.or) : or,
    };
  }

  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = _.uniq(val);
  });

  if (_.trim(title) === "") {
    return OpenNotification({
      type: "error",
      title: "Please enter search title.",
    });
  }

  let obj = {
    filter: apiObj,
    name: title,
    searchType: 2,
    sort: [{ createdAt: "DESC" }],
    id: id,
  };

  let api = CREATE_SAVED_SEARCH.url;
  let objData = {
    method: CREATE_SAVED_SEARCH.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) {
      OpenNotification({
        type: "error",
        title: err.data.message || "Something went wrong.",
      });
    } else if (data && data.code === "OK") {
      cb(data.data.id);
    }
  });
};

export const handleMatchPair = (filter, cb) => {
  let apiObj = filter;
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = val;
  });
  let obj = {
    filter: apiObj,
    page: 1,
    limit: 1000,
    // isSkipSave: true,
  };
  let api = CREATE_MATCH_PAIR.url;
  let objData = {
    method: CREATE_MATCH_PAIR.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err && err.data && err.data.message) {
      return OpenNotification({
        type: "error",
        title: err.data.message,
      });
    } else if (data && data.code === "OK") {
      cb(data.data.filter.id);
    }
  });
};

export const handleSelectSearch = (searchId, cb) => {
  let obj = {
    id: searchId,
    isAppendMasters: true,
  };
  let api = SELECT_SEARCH.url;
  let objData = {
    method: SELECT_SEARCH.method,
    url: api,
    request: obj,
  };
  UtilService.callApi(objData, function (err, data) {
    if (err) throw err;
    if (data && data.code === "OK") {
      cb(
        data.data.list[0].searchData,
        data.data.list[0].name,
        data.data.list[0].type,
      );
    }
  });
};

export const getCount = (filter, cb) => {
  const userDetail = UtilService.getLocalStorageItem(
    `${LOCAL_STORAGE_VAR}-user`,
  );

  let apiObj = {
    ...filter,

    isCert: "CERT",
    wSts: filter?.wSts ?? [
      "A",
      "M",
      "H",
      "E",
      "S",
      "A2",
      "M3",
      "M1",
      "A3",
      "T",
      "V",
      "R",
      "M2",
      "L",
      "A1",
    ],

    // prcStg: {
    //   in: ["PRC", "UC"],
    // },
    lbNm: {
      nin: ["FM"],
    },
  };

  const isFm = userDetail
    ? userDetail.isFm
      ? true
      : userDetail.account
      ? userDetail.account.isFm
        ? true
        : false
      : false
    : false;
  if (isFm) {
    if (isEmpty(filter.isFm)) {
      delete apiObj.lbNm;
    } else {
      if (!isEmpty(filter.isFm) && filter.isFm.includes("CERT")) {
        apiObj = {
          ...apiObj,
          lbNm: "FM",
        };
      }

      if (
        !isEmpty(filter.isFm) &&
        JSON.stringify(filter.isFm) === JSON.stringify(["CERT", "ELIG"])
      )
        delete apiObj.lbNm;
    }
  }

  if (!isEmpty(apiObj.lb)) {
    const masterData = JSON.parse(
      localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`),
    );

    const otherLab = masterData.LAB.find((item) => item.code === "OTHER");
    if (apiObj.lb?.includes(otherLab?.id?.[0])) delete apiObj.isCert;
  }

  if (includes(filter.wSts, "B")) {
    delete apiObj["prcStg"];
    delete apiObj["isCert"];

    apiObj = {
      ...apiObj,
      prcStg: "NEW",
    };
  }

  if (!isEmpty(apiObj.lb)) {
    // let or = [
    //   {
    //     lbIds: apiObj.lb,
    //   },
    // {
    //   lb: apiObj.lb,
    // },
    // ];
    let lb = apiObj.lb;
    delete apiObj.lb;
    apiObj = {
      ...apiObj,
      lb,
      // or: apiObj.or ? concat(or, apiObj.or) : or,
    };
  }

  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = _.uniq(val);
  });

  let obj = {
    isNotReturnTotal: true,
    isReturnCountOnly: true,
    isSkipSave: true,
    filters: [apiObj],
  };

  if (includes(apiObj.wSts, "U")) {
    delete apiObj["isCert"];
    obj["filters"] = [{ wSts: ["U"] }];
    obj.isUpcoming = true;
  }

  let api = SEARCH_DIAMOND_LIST.url;
  let objData = {
    method: SEARCH_DIAMOND_LIST.method,
    url: api,
    request: obj,
  };
  UtilService.callApi(objData, function (err, data) {
    if (err) throw err;
    if (data && data.code === "OK") {
      cb(data.data[0].count);
    }
  });
};

export const handleAddDemand = (filter, title, date, cb) => {
  let apiObj = {
    ...filter,
    isCert: "CERT",
    wSts: filter?.wSts ?? [
      "A",
      "M",
      "H",
      "E",
      "S",
      "A2",
      "M3",
      "M1",
      "A3",
      "T",
      "V",
      "R",
      "M2",
      "L",
      "A1",
    ],

    // prcStg: {
    //   in: ["PRC", "UC"],
    // },
    lbNm: {
      nin: ["FM"],
    },
  };

  if (!isEmpty(filter.isFm)) {
    apiObj = {
      ...apiObj,
      lbNm: "FM",
    };
  }

  if (includes(filter.wSts, "B")) {
    delete apiObj["prcStg"];
    delete apiObj["isCert"];

    apiObj = {
      ...apiObj,
      prcStg: "NEW",
    };
  }

  if (!isEmpty(apiObj.lb)) {
    // let or = [
    //   {
    //     lbIds: apiObj.lb,
    //   },
    // {
    //   lb: apiObj.lb,
    // },
    // ];
    let lb = apiObj.lb;
    delete apiObj.lb;
    apiObj = {
      ...apiObj,
      lb,
      // or: apiObj.or ? concat(or, apiObj.or) : or,
    };
  }

  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = _.uniq(val);
  });

  if (_.trim(title) === "") {
    return OpenNotification({
      type: "error",
      title: "Please enter demand title.",
    });
  }

  if (!date) {
    return OpenNotification({
      type: "error",
      title: "Please select date for adding demand.",
    });
  }

  let obj = {
    filter: apiObj,
    name: title,
    searchType: 3,
    expiryDate: date,
  };

  let api = CREATE_SAVED_SEARCH.url;
  let objData = {
    method: CREATE_SAVED_SEARCH.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) {
      OpenNotification({
        type: "error",
        title: err.data.message || "Something went wrong.",
      });
    } else if (data && data.code === "OK") {
      cb(true);
      OpenNotification({
        type: "success",
        title: "Your demand is added successfully.",
      });
    }
  });
};

// for accounting listexport
export const getAccountingList = (filter, cb) => {
  let user = JSON.parse(
    window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)),
  );

  let obj = {
    ...filter,
    account: user?.account?.id ?? null,
    user: user?.id ?? null,
    seller: user?.account?.seller ?? null,
    isAdmin: false,
    // page: 1,
    // limit: 100,
  };
  let api = GET_ACCOUNTING_LIST.url;
  let objData = {
    method: GET_ACCOUNTING_LIST.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) throw err;
    if (data && data.code === "OK") {
      cb(data.data.list);
    }
  });
};

export const getAccountingListColumn = (cb) => {
  let api = GET_ACCOUNTING_LIST_COLUMN.url;
  let objData = {
    method: GET_ACCOUNTING_LIST_COLUMN.method,
    url: api,
    request: { name: "GRID_DIAMOND_LIST_ADMIN_ACCOUNT" },
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) throw err;
    if (data && data.code === "OK") {
      cb(data.data.columns);
    }
  });
};
export const sendOTPAndVerification = (filter, cb) => {
  const apiVariable =
    filter.callType === "verify OTP" ? OTP_VERIFICATION : SEND_OTP;
  let api = apiVariable.url;
  let objData = {
    method: apiVariable.method,
    url: api,
    request: filter.callType === "verify OTP" ? { otp: filter.otp } : null,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) {
      cb(err.data.message);
    }

    if (data && data.code === "OK") {
      cb(data.message);
    }
  });
};
