/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SpeechRecognition from "react-speech-recognition";
import { LOCAL_STORAGE_VAR } from "../../../constants/Common";
import isEmpty from "lodash/isEmpty";
import split from "lodash/split";
import last from "lodash/last";
import concat from "lodash/concat";
import { Input } from "antd";
import { SEARCH_DIAMOND_LIST } from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
import { LISTINGPAGES } from "../../../components/DiamondList/DiamondListFunctions";

const { Search } = Input;

const HeaderSearch = (props) => {
  let history = useHistory();
  const masterData = JSON.parse(
    localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`),
  );
  const [searchData, setSearchData] = useState([]);
  const [searchInput, setSearchInput] = useState({});
  const [recordOn, setRecordOn] = useState(false);
  let support = true;

  const handleSearchChange = (e) => {
    let value = e.target.value;
    let searched = [];
    let splitted = value ? split(value, " ") : [];

    if (!isEmpty(splitted)) value = last(splitted);

    if (value) {
      let others = [];
      let savedSearch = [];
      Object.keys(masterData).map((m) => {
        masterData[m].map((dt) => {
          if (dt.name.toLowerCase().includes(value.toLowerCase())) {
            others.push({ label: dt.name, type: "OTHER" });
          }
        });
      });

      props.searchList.map((sl) => {
        if (sl.name.toLowerCase().includes(value.toLowerCase())) {
          savedSearch.push({ label: sl.name, type: "SAVEDSEARCH", id: sl.id });
        }
      });

      searched = concat(others.slice(0, 5), savedSearch.slice(0, 5));
    } else {
      searched = [];
    }

    setSearchInput({ str: e.target.value, type: "OTHER" });
    setSearchData(searched);
  };

  const handleSearch = () => {
    if (searchInput.type === "OTHER") {
      let obj = {
        search: searchInput.str,
        isReturnCountOnly: true,
        isNotReturnTotal: true,
        page: 1,
        limit: 100,
        isDirectSearch: true,
      };
      let objData = {
        ...SEARCH_DIAMOND_LIST,
        request: obj,
      };
      UtilService.callApi(objData, function (err, data) {
        if (err) throw err;
        if (data && data.code === "OK") {
          history.push(`/${LISTINGPAGES.LIST}?${data.data[0].filter.id}`);
          setSearchInput({});
          props.onClick();
        }
      });
    }
    if (searchInput.type === "SAVEDSEARCH") {
      history.push(`/${LISTINGPAGES.LIST}?${searchInput.id}`);
      setSearchInput({});
      props.onClick();
    }
  };

  const stop = (func, reset) => {
    setRecordOn(false);
    catchFunc(func, reset);
    if (!isEmpty(searchInput)) handleSearch();
  };

  const catchFunc = (func, reset) => {
    setSearchInput({ str: props.transcript, type: "OTHER" });
    props.abortListening();
    func();
    // reset();
  };
  const start = (func) => {
    setRecordOn(true);
    setSearchInput({ str: "", type: "OTHER" });
    func();
  };
  const {
    resetTranscript,
    browserSupportsSpeechRecognition,
    startListening,
    stopListening,
  } = props;
  if (!browserSupportsSpeechRecognition) {
    support = false;
  }

  useEffect(() => {
    if (props.transcript !== "") {
      setSearchInput({ str: props.transcript, type: "OTHER" });
    }
  }, [props.transcript]);

  return (
    <div className={`searchWrapper ${props.className}`}>
      <div className="searchWrapperInner">
        <div className="searchBox d-flex align-items-center">
          <span className="searchIcon">
            <img
              src={require("../../../assets/svg/Header/search-white.svg")}
              alt=""
            />
          </span>
          <Search
            value={searchInput.str}
            placeholder="Search"
            onChange={handleSearchChange}
            suffix={false}
            onPressEnter={handleSearch}
          />
          {support && (
            <>
              {!recordOn ? (
                <span
                  className="searchIcon"
                  onClick={() => {
                    // resetTranscript();
                    start(startListening);
                  }}
                >
                  <img
                    src={require("../../../assets/svg/Header/voice.svg")}
                    className="voiceSearchImg"
                  />
                </span>
              ) : (
                <span
                  className="searchIcon"
                  onClick={() => {
                    props.abortListening();
                    stop(stopListening, resetTranscript);
                  }}
                >
                  <img
                    src={require("../../../assets/svg/Header/mute.svg")}
                    className="voiceSearchImg"
                  />
                </span>
              )}
            </>
          )}

          <span
            className="searchIconClose"
            onClick={() => {
              setSearchInput({});
              setSearchData([]);
              setRecordOn(false);
              props.onClick();
            }}
          >
            <img
              src={require("../../../assets/svg/Header/close-white.svg")}
              alt=""
            />
          </span>
        </div>
        {searchInput.str && (
          <div className="searchDropdown">
            <ul>
              {searchData.map((s, i) => (
                <li
                  key={i}
                  onClick={() => {
                    var str = searchInput.str;
                    var res = str ? str.split(" ") : [];
                    res.pop();
                    res.push(s.label);
                    res = res.join(" ");
                    setSearchInput({ str: res, type: s.type, id: s.id });
                  }}
                >
                  {s.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const options = {
  autoStart: false,
  continuous: false,
};
export default SpeechRecognition(options)(HeaderSearch);
