/* eslint-disable */
import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import MobileHeader from "../src/components/MobileHeader/index";
import Header from "../src/components/Header";
import { connect } from "react-redux";
import Loader from "./components/common/Loader";
import APILoader from "./components/common/APILoader";
import UtilService from "./services/util";
import { LOCAL_STORAGE_VAR } from "./constants/Common";

class MainApp extends Component {
  state = {
    visible: false,
    isDeviceUrl: false,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    if (window.drift) {
      window.drift.on("campaign:dismiss", function (data) {});

      if (window.drift && window.drift.api) {
        window.drift.api.widget.hide();
      }
    }

    const isMobile = window.innerWidth <= 767;
    const { loading } = this.props;

    const check_user = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-user`,
    );
    if (!check_user) {
      if (
        this.props.location.pathname !== "/signin" ||
        this.props.location.pathname !== "/"
      ) {
        UtilService.setLocalStorageItem(
          window.location.href,
          `${LOCAL_STORAGE_VAR}-last`,
        );
        return <Redirect from="*" to="/" />;
      }
    } else {
      return (
        <div>
          {/* <OnlineChat/> */}
          {loading && <Loader loading={loading} fixed />}
          <APILoader />

          {isMobile ? <MobileHeader /> : <Header />}

          <div className="content-area pageHeight without-footer">
            {this.props.children}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = function (props) {
  return props;
};

export default connect(mapStateToProps)(withRouter(MainApp));
