/* eslint-disable */
import React, { useState, useEffect } from "react";
import "../TermsOfUse/staticContent.less";
import Heading from "../common/Heading";
import InputBlock from "../common/InputBlock";
import SelectOption from "../common/SelectOption";
import DatePicker from "../common/DatePicker";
import StaticHeader from "../StaticHeader";
import "./appointmentbook.less";
import OpenNotification from "../common/CommonButton/OpenNotification";
import {
  GET_COUNTRY_LIST,
  FETCH_SELLERS,
  GET_STATE_LIST,
  GET_CITY_LIST,
  GET_MASTERS,
  UPLOAD_FILE,
} from "../../constants/apiConstant";
import UtilService from "../../services/util";
import { isEmpty, cloneDeep, clone, isNull } from "lodash";
import useStateWithCallback from "../../hooks/useStateWithCallback";
import moment from "moment";
import { Drawer, notification, Radio } from "antd";
import FileUpload from "../common/UploadImageSquare/index";
import { BASE_URL, MIME_TYPES } from "../../constants/Common";
import { Row, Col } from "antd";
import { CREATE_KYC } from "../../constants/apiConstant";

const PDFICON = require("../../../src/assets/svg/pdf-icon.svg");

export const companyDetail = {
  Country: [
    { value: "Afghanistan " },
    { value: "Albania " },
    { value: "Algeria " },
    { value: "American Samoa" },
    { value: "Andorra" },
    { value: "Angola" },
    { value: "India" },
  ],
  Appointcountry: [
    { name: "Hong Kong", value: "Hong Kong", id: "Hong Kong" }, //"5cf0a19de6799a22f0cd81dc"
    { name: "Israel", value: "Israel ", id: "Israel" }, //"5cf0a19de6799a22f0cd828b"
    { name: "Mumbai", value: "Mumbai", id: "Mumbai" }, //"5cf0a19de6799a22f0cd825a"
    { name: "New York", value: "New York ", id: "New York" }, //"5cf0a19de6799a22f0cd8226"
  ],
  State: [
    { value: "Maharashtra " },
    { value: "Andhra Pradesh" },
    { value: "Arunachal Pradesh" },
    { value: "Assam " },
    { value: "Bihar" },
    { value: "Dadra and Nagar Haveli " },
    { value: "Daman and Diu" },
  ],
  City: [
    { value: "Achalpur" },
    { value: "Ahmadnagar" },
    { value: "Akola" },
    { value: "Amravati" },
    { value: "AURANGABAD" },
    { value: "Miraj" },
    { value: "MUMBAI" },
  ],
  hearAboutUs: [
    { name: "Rapnet", value: "Rapnet", id: "Rapnet" },
    { name: "VDB", value: "VDB", id: "VDB" },
    { name: "Polygon", value: "Polygon", id: "Polygon" },
    {
      name: "Market.Diamonds",
      value: "Market.Diamonds",
      id: "Market.Diamonds",
    },
    { name: "Google", value: "Google", id: "Google" },
    { name: "Facebook", value: "Facebook", id: "Facebook" },
    { name: "Instagram", value: "Instagram", id: "Instagram" },
  ],
  companyType: [
    { name: "Retailer", value: "Retailer", id: "Retailer" },
    { name: "Wholesaler", value: "Wholesaler", id: "Wholesaler" },
    { name: "ETailer", value: "ETailer", id: "ETailer" },
  ],
  legalStatusOfOrg: [
    { name: "PRIVATE CORP", value: "PRIVATE CORP", id: "PRIVATE CORP" },
    { name: "PARTNERSHIP", value: "PARTNERSHIP", id: "PARTNERSHIP" },
    { name: "INDIVIDUAL", value: "INDIVIDUAL", id: "INDIVIDUAL" },
  ],
  seller: [{ value: "ABHISHEK KARNAVAT" }, { value: "AKSHIT BHALAWAT" }],
};

const OnlineKYC = () => {
  const [formData, setFormData] = useStateWithCallback({
    registeredLegel: "",
    name: "",
    mobileNumber: "",
    phoneNumber: "",
    web: "",
    fax: "",
    tradeStyle: "",
    zipCode: "",
    email: "",
    fedTaxId: "",
    reSaleTax: "",
    jbtId: "",
    laundering: true,
    country: {},
    hearAboutUs: undefined,
    yearsAtLocation: "",
    companyType: undefined,
    legalStatusOfOrg: undefined,
    signDoc: "",
    printName: "",
    state: {},
    city: {},
    physicalAddress: "",
    mailingAddress: "",
    date: new Date().toISOString(),
    allCountries: [],
    allCities: [],
    countries: [],
    states: [],
    facebookUsername: "",
    instagramHandle: "",
    cities: [],
    seller: "",
    sellers: [],
    allSellers: [],
    errors: {},
  });

  const [visible, setVisible] = useState(false);
  const [inputListOne, setInputListOne] = useStateWithCallback([
    { firstName: "", lastName: "", designation: "", mob: "" },
  ]);
  const [inputListTwo, setInputListTwo] = useStateWithCallback([
    { companyName: "", phoneNum: "", email: "", businessEntityName: "" },
  ]);

  //check if all properties of an object are not null.
  const checkProperties = (array) => {
    let valid = [];
    array.forEach((obj) => {
      for (var key in obj) {
        if (obj[key] !== null && obj[key] !== "") {
          if (["firstName", "lastName", "designation"].includes(key)) {
            let isRecordValid = /^[A-Za-z]+$/.test(obj[key]);
            valid.push(isRecordValid);
          } else if (key === "mob") {
            let isRecordValid = obj[key].length < 10 || obj[key].length > 15;
            valid.push(!isRecordValid);
          }
        } else {
          valid.push(false);
        }
      }
    });
    return valid.some((x) => x === false);
  };

  const checkPropertiesTwo = (array) => {
    let valid = [];
    array.forEach((obj) => {
      for (var key in obj) {
        if (obj[key] !== null && obj[key] !== "") {
          if (["companyName", "businessEntityName"].includes(key)) {
            let isRecordValid = /^[A-Za-z]+$/.test(obj[key]);
            valid.push(isRecordValid);
          } else if (key === "phoneNum") {
            let isRecordValid = obj[key].length < 10 || obj[key].length > 15;
            valid.push(!isRecordValid);
          } else if (key === "email") {
            let isRecordValid =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                obj[key],
              );
            valid.push(isRecordValid);
          }
        } else {
          valid.push(false);
        }
      }
    });
    return valid.some((x) => x === false);
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListOne];
    list[index][name] = value;
    setInputListOne(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputListOne];
    list.splice(index, 1);
    setInputListOne(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    // if (checkProperties(inputListOne)) {
    // }
    setInputListOne([
      ...inputListOne,
      { firstName: "", lastName: "", designation: "", mob: "" },
    ]);
    // } else {
    //   OpenNotification({
    //     type: "error",
    //     title: "All fields are required to add another record !",
    //   });
    // }
  };

  // handle input change2
  const handleInputChangeTwo = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListTwo];
    list[index][name] = value;
    setInputListTwo(list);
  };

  // handle click2 event of the Remove button
  const handleRemoveClickTwo = (index) => {
    const list = [...inputListTwo];
    list.splice(index, 1);
    setInputListTwo(list);
  };

  // handle click2 event of the Add button
  const handleAddClickTwo = () => {
    setInputListTwo([
      ...inputListTwo,
      { companyName: "", phoneNum: "", email: "", businessEntityName: "" },
    ]);
  };

  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const fetchCountries = () => {
    let obj = {
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        setFormData({
          ...formData,
          allCountries: data.data || [],
          countries: data.data || [],
        });
      }
    });
  };

  const fetchSellers = () => {
    let obj = {
      ...FETCH_SELLERS,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        data.data = data.data.map((d) => {
          d.name = UtilService.getFullName(d);
          return d;
        });
        formData.sellers = data.data || [];
        formData.allSellers = data.data || [];
      }
    });
  };

  const fetchShows = () => {
    let obj = {
      ...GET_MASTERS,
      request: { masters: ["SHOW_TYPE"] },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        formData.shows = data.data.SHOW_TYPE || [];
      }
    });
  };

  const uploadDoc = (type, e) => {
    const ee = cloneDeep(e);

    var blob = ee.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = function (ev) {
      var arr = new Uint8Array(ev.target.result).subarray(0, 4);
      var header = "";
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (
        !MIME_TYPES[blob.type] ||
        (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))
      ) {
        return OpenNotification({
          type: "error",
          title:
            "File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.",
        });
      } else {
        const data = new FormData();
        data.append("folder", "user");
        data.append("file", ee.target.files[0]);
        // let userToken = self.state.userData.data.token.jwt;
        let objData = {
          ...UPLOAD_FILE,
          //   auth: userToken,
          request: data,
        };
        UtilService.callApi(objData, function (err, data) {
          if (err) {
            OpenNotification({
              type: "error",
              title: "Please Try Again !",
            });
          }
          if (data && data.code === "OK") {
            if (data.data && data.data.files && data.data.files.length !== 0) {
              OpenNotification({
                type: "success",
                title: "Signature Uploaded !",
              });
              setFormData({
                ...formData,
                [type]: data.data.files[0].absolutePath,
                fileName: data.data.files[0].name,
              });
            }
          }
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };

  const fetchStates = (countryId) => {
    let obj = {
      ...GET_STATE_LIST,
      request: {
        country: countryId,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        formData.states = data.data || [];
      }
    });
  };

  const fetchCities = (stateId, countryId) => {
    let obj = {
      ...GET_CITY_LIST,
      request: {
        state: stateId,
        country: countryId,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        if (stateId) {
          setFormData({
            ...formData,
            cities: data.data || [],
          });
        } else if (countryId) {
          setFormData({
            ...formData,
            cities: data.data || [],
          });
        }
      }
    });
  };

  const handleCountryChange = (e) => {
    let country = formData.countries.filter((c) => c.id === e)[0];
    if (country) {
      formData.country = country ? country : {};
      formData.state = {};
      formData.city = {};
      setFormData(
        {
          ...formData,
          states: [],
          cities: [],
          allCities: [],
        },
        () => {
          fetchStates(e);
          fetchCities(null, e);
        },
      );
    }
  };

  const handleStateChange = (e) => {
    let state = formData.states.filter((c) => c.id === e)[0];
    if (state) {
      formData.city = "";
      formData.state = state ? state : {};
      setFormData(
        {
          ...formData,
          cities: [],
        },
        () => {
          fetchCities(e);
        },
      );
    }
  };

  const handleCityChange = (e) => {
    let city = formData.cities.filter((c) => c.id === e)[0];
    if (city) {
      formData.city = city ? city : {};
      setFormData({ ...formData });
    }
  };

  const handleSignup = (e, isPdf) => {
    e.preventDefault();
    // if (handleValidation()) {
    // OpenNotification({
    //   type: "error",
    //   title: "I can register",
    // });
    // }

    let reqObj = clone(formData);

    delete reqObj["allCountries"];
    delete reqObj["allSellers"];
    delete reqObj["allCities"];
    delete reqObj["cities"];
    delete reqObj["countries"];
    delete reqObj["errors"];
    delete reqObj["sellers"];
    delete reqObj["states"];
    delete reqObj["time"];

    reqObj.city = isEmpty(reqObj.city) ? null : reqObj.city.name;
    reqObj.state = isEmpty(reqObj.state) ? null : reqObj.state.name;
    reqObj.country = isEmpty(reqObj.country) ? null : reqObj.country.name;
    reqObj.email = isEmpty(reqObj.email) ? null : [reqObj.email];
    reqObj.mobileNumber = isEmpty(reqObj.mobileNumber)
      ? null
      : reqObj.mobileNumber;

    let obj = {
      ...CREATE_KYC,
      request: {
        city: reqObj.city,
        zipCode: reqObj.zipCode,
        fax: reqObj.fax,
        state: reqObj.state,
        country: reqObj.country,
        email: reqObj.email ? reqObj.email.toString() : "",
        fName: reqObj.name,
        fedTaxId: reqObj.fedTaxId,
        resaleTax: reqObj.reSaleTax,
        jbtId: reqObj.jbtId,
        compName: reqObj.registeredLegel,
        companyType: reqObj.companyType,
        in: reqObj.timeString,
        compAddress: reqObj.appointmentFor,
        demand: reqObj.demand,
        salesExecutive: reqObj.seller,
        companyName: reqObj.registeredLegel,
        tradeStyle: reqObj.tradeStyle,
        phyAdd: reqObj.physicalAddress,
        mailingAdd: reqObj.mailingAddress,
        phone: reqObj.phoneNumber,
        mobileNo: reqObj.mobileNumber,
        web: reqObj.web,
        hasLaunderingPrg: reqObj.laundering,
        howKnow: reqObj.hearAboutUs,
        socials: [
          { type: "facebook", url: reqObj.facebookUsername },
          { type: "instagram", url: reqObj.instagramHandle },
        ],
        natureOfOrg: reqObj.legalStatusOfOrg,
        dateOfJoin: reqObj.date,
        yrsAtPrsntLoc: reqObj.yearsAtLocation,
        stateOfReg: "",
        listOfOwners: inputListOne,
        tradeRefs: inputListTwo,
        isPdf,
      },
    };

    if (handleValidation()) {
      setIsLoading(true);
      UtilService.callApi(obj, function (err, data) {
        if (err && err.data) {
          if (err) {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
          if (
            [
              "E_DUPLICATE_EMAIL",
              "E_DUPLICATE",
              "E_DUPLICATE_MOBILE",
              "E_DUPLICATE_USERNAME",
            ].includes(err.data.code)
          ) {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
        }

        if (data && (data.code === "OK" || data.code === "CREATED")) {
          OpenNotification({
            type: "success",
            title: data.message,
          });
          if (isPdf) {
            window.open(`${BASE_URL}/data${data.data}`, "download");
          }
          handleCancel(e);
        }
      });
      setIsLoading(false);
    }
  };

  const handleValidation = () => {
    let errors = formData.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFields = [];

    if (!formData["registeredLegel"] || isEmpty(formData["registeredLegel"])) {
      blankField.push("Registered Legel Name");
      formIsValid = false;
      errors["registeredLegel"] = true;
    }
    if (formData["registeredLegel"]) {
      if (!formData["registeredLegel"].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push("Registered Legel Name");
        formIsValid = false;
        errors["registeredLegel"] = true;
      } else {
        errors["registeredLegel"] = undefined;
      }
    }

    if (!formData["tradeStyle"] || isEmpty(formData["tradeStyle"])) {
      blankField.push("DBA/ Trade Style");
      formIsValid = false;
      errors["tradeStyle"] = true;
    }
    if (formData["tradeStyle"]) {
      if (!formData["tradeStyle"].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push("DBA/ Trade Style");
        formIsValid = false;
        errors["tradeStyle"] = true;
      } else {
        errors["tradeStyle"] = undefined;
      }
    }

    if (!formData["physicalAddress"] || isEmpty(formData["physicalAddress"])) {
      blankField.push("Physical Address");
      formIsValid = false;
      errors["physicalAddress"] = true;
    }
    if (formData["physicalAddress"]) {
      if (!formData["physicalAddress"].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push("Physical Address");
        formIsValid = false;
        errors["physicalAddress"] = true;
      } else {
        errors["physicalAddress"] = undefined;
      }
    }

    if (!formData["country"] || isEmpty(formData["country"])) {
      blankField.push("Country");
      formIsValid = false;
      errors["country"] = true;
    } else {
      errors["country"] = false;
    }

    if (!formData["state"] || isEmpty(formData["state"])) {
      blankField.push("State");
      errors["state"] = true;
      formIsValid = false;
    } else {
      errors["state"] = false;
    }

    if (!formData["city"] || isEmpty(formData["city"])) {
      blankField.push("City");
      formIsValid = false;
      errors["city"] = true;
    } else {
      errors["city"] = false;
    }

    if (!formData["zipCode"] || isEmpty(formData["zipCode"])) {
      blankField.push("Zip Code");
      formIsValid = false;
      errors["zipCode"] = true;
    } else {
      errors["zipCode"] = false;
    }

    if (!formData["companyType"] || isEmpty(formData["companyType"])) {
      blankField.push("Type Of Business");
      formIsValid = false;
      errors["companyType"] = true;
    } else {
      errors["companyType"] = false;
    }

    if (!formData["mailingAddress"] || isEmpty(formData["mailingAddress"])) {
      blankField.push("Mailing Address");
      formIsValid = false;
      errors["mailingAddress"] = true;
    } else {
      errors["mailingAddress"] = false;
    }

    if (!formData["phoneNumber"] || isEmpty(formData["phoneNumber"])) {
      blankField.push("Phone Number");
      formIsValid = false;
      errors["phoneNumber"] = true;
    } else {
      errors["phoneNumber"] = false;
    }

    if (!formData["fax"] || isEmpty(formData["fax"])) {
      blankField.push("Fax Number");
      formIsValid = false;
      errors["fax"] = true;
    } else {
      errors["fax"] = false;
    }

    if (!formData["fedTaxId"] || isEmpty(formData["fedTaxId"])) {
      blankField.push("Fed Tax Number");
      formIsValid = false;
      errors["fedTaxId"] = true;
    } else {
      errors["fedTaxId"] = false;
    }

    if (!formData["reSaleTax"] || isEmpty(formData["reSaleTax"])) {
      blankField.push("Resale Tax Number");
      formIsValid = false;
      errors["reSaleTax"] = true;
    } else {
      errors["reSaleTax"] = false;
    }

    if (!formData["jbtId"] || isEmpty(formData["jbtId"])) {
      blankField.push("JBT ID");
      formIsValid = false;
      errors["jbtId"] = true;
    } else {
      errors["jbtId"] = false;
    }

    if (
      !formData["facebookUsername"] ||
      isEmpty(formData["facebookUsername"])
    ) {
      blankField.push("Facebook Username");
      formIsValid = false;
      errors["facebookUsername"] = true;
    } else {
      errors["facebookUsername"] = false;
    }

    if (!formData["web"] || isEmpty(formData["web"])) {
      blankField.push("website");
      formIsValid = false;
      errors["web"] = true;
    } else if (formData["web"]) {
      if (
        !formData["web"].match(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
        )
      ) {
        invalidFields.push("Web site (www is required)");
        formIsValid = false;
        errors["web"] = true;
      } else {
        errors["web"] = undefined;
      }
    }

    if (!formData["instagramHandle"] || isEmpty(formData["instagramHandle"])) {
      blankField.push("Instagram Handle");
      formIsValid = false;
      errors["instagramHandle"] = true;
    } else {
      errors["instagramHandle"] = false;
    }

    if (!formData["hearAboutUs"] || isEmpty(formData["hearAboutUs"])) {
      blankField.push("Hear About Us");
      formIsValid = false;
      errors["hearAboutUs"] = true;
    } else {
      errors["hearAboutUs"] = false;
    }

    if (!formData["yearsAtLocation"] || isEmpty(formData["yearsAtLocation"])) {
      blankField.push("Years At Location");
      formIsValid = false;
      errors["yearsAtLocation"] = true;
    } else {
      errors["yearsAtLocation"] = false;
    }

    if (
      !formData["legalStatusOfOrg"] ||
      isEmpty(formData["legalStatusOfOrg"])
    ) {
      blankField.push("Legal Status Of Organization");
      formIsValid = false;
      errors["legalStatusOfOrg"] = true;
    } else {
      errors["legalStatusOfOrg"] = false;
    }

    if (!formData["signDoc"] || isEmpty(formData["signDoc"])) {
      blankField.push("Signature");
      formIsValid = false;
      errors["signDoc"] = true;
    } else {
      errors["signDoc"] = false;
    }

    if (!formData["printName"] || isEmpty(formData["printName"])) {
      blankField.push("Print Name");
      formIsValid = false;
      errors["printName"] = true;
    } else {
      errors["printName"] = false;
    }

    if (!formData.mobileNumber || isEmpty(formData.mobileNumber)) {
      blankField.push("Mobile Number");
      formIsValid = false;
      errors["mobileNumber"] = true;
    }
    if (
      formData.mobileNumber &&
      (formData.mobileNumber.length < 10 || formData.mobileNumber.length > 15)
    ) {
      invalidFields.push("Mobile Number");
      formIsValid = false;
      errors["mobileNumber"] = true;
    }

    if (!formData["email"] || isEmpty(formData["email"])) {
      blankField.push("Email Address");
      formIsValid = false;
      errors["email"] = true;
    }
    if (formData["email"]) {
      if (
        !formData["email"].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        invalidFields.push("Email");
        formIsValid = false;
        errors["email"] = true;
      } else {
        errors["email"] = undefined;
      }
    }

    if (inputListOne.length < 2 || checkProperties(inputListOne)) {
      blankField.push("Atleast 2 Authorized Persons must be added & valid");
      formIsValid = false;
      errors["authPersons"] = true;
    } else {
      errors["authPersons"] = false;
    }
    if (inputListTwo.length < 5 || checkPropertiesTwo(inputListTwo)) {
      blankField.push("Atleast 5 Trade References must be added & valid");
      formIsValid = false;
      errors["tradeReferences"] = true;
    } else {
      errors["tradeReferences"] = false;
    }

    if (blankField.length > 0) {
      let arr = blankField;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    if (invalidFields.length > 0) {
      let arr = invalidFields;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    setFormData({ ...formData, errors: errors });
    return formIsValid;
  };

  const handleCancel = (e) => {
    e.preventDefault();
    formData.registeredLegel = "";
    formData.name = "";
    formData.mobileNumber = "";
    formData.phoneNumber = "";
    formData.web = "";
    formData.fax = "";
    formData.tradeStyle = "";
    formData.zipCode = "";
    formData.email = "";
    formData.fedTaxId = "";
    formData.reSaleTax = "";
    formData.jbtId = "";
    formData.laundering = true;
    formData.country = {};
    formData.hearAboutUs = undefined;
    formData.yearsAtLocation = "";
    formData.legalStatusOfOrg = "";
    formData.signDoc = "";
    formData.printName = "";
    formData.state = {};
    formData.city = {};
    formData.physicalAddress = "";
    formData.mailingAddress = "";
    formData.date = new Date().toISOString();
    formData.allCountries = [];
    formData.allCities = [];
    formData.countries = [];
    formData.states = [];
    formData.cities = [];
    formData.seller = "";
    formData.sellers = [];
    formData.facebookUsername = "";
    formData.instagramHandle = "";
    setInputListOne([
      { firstName: "", lastName: "", designation: "", mob: "" },
    ]);
    setInputListTwo([
      { companyName: "", phoneNum: "", email: "", businessEntityName: "" },
    ]);
    formData.allSellers = [];
    formData.errors = {};
    fetchCountries();
    fetchSellers();
    fetchShows();
  };

  useEffect(() => {
    fetchCountries();
    fetchSellers();
    fetchShows();
  }, [fetchCountries, fetchSellers, fetchShows]);

  useEffect(() => {
    fetchSellers();
  }, [fetchSellers, formData.seller]);

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <StaticHeader />
      <div className={`staticBoxMain ${isLoading ? `loading-indicator` : ''}`}>
        <div className="statiContentBox">
          {/* <p>
            DE-6010, Bharat Diamond Bourse, Bandra-Kurla Complex, Bandra (E),
            Mumbai - 400 051 Tel - 91-22-66639999 / Fax - 91-22-66639910 /
            email- chirag@vkjewel.com
          </p> */}
          <Heading
            title="KNOW YOUR CUSTOMER 'KYC' FORM"
            className="staticMainTitle text-center mb-20"
          />
          <div>
            {/* <div className="appointmentDetail d-flex">
              <div className="appointmentDetailItem">
                <div className="appointmentDetailImg">
                  <img
                    src={require("../../assets/svg/AppointmentBook/location.svg")}
                    alt=""
                  />
                </div>
                <div className="appointmentContent">
                  <span>
                    Diamond Dealers Club, 50 West 47th Street, Floor 11, New
                    York, NY 10036
                  </span>
                </div>
              </div>
              <div className="appointmentDetailItem">
                <div className="appointmentDetailImg">
                  <img
                    src={require("../../assets/svg/AppointmentBook/call.svg")}
                    alt=""
                  />
                </div>
                <div className="appointmentContent">
                  <span>Ashutosh Patel</span>
                  <span>+1 646 736 8383</span>
                </div>
              </div>
              <div className="appointmentDetailItem">
                <div className="appointmentDetailImg">
                  <img
                    src={require("../../assets/svg/AppointmentBook/clock.svg")}
                    alt=""
                  />
                </div>
                <div className="appointmentContent">
                  <span>From : 8/19/2019 9:00:00 AM</span>
                  <span>To : 8/22/2019 5:00:00 PM</span>
                </div>
              </div>
            </div> */}
            <div className="onlineKYC">
              {/* <Heading
                title="For New Clients"
                className="popupInnerTitle mb-50 text-center"
              /> */}
              <form onSubmit={handleSignup}>
                <Row gutter={16}>
                  <Col md={12}>
                    <InputBlock
                      label="Registered Legal Name*"
                      placeholder="Registered Legal Name"
                      value={formData.registeredLegel}
                      onChange={(e) =>
                        handleChange("registeredLegel", e.target.value)
                      }
                      hasError={
                        formData.errors["registeredLegel"] ? true : false
                      }
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="D.B.A / Trade Style*"
                      placeholder="D.B.A / Trade Style"
                      value={formData.tradeStyle}
                      onChange={(e) =>
                        handleChange("tradeStyle", e.target.value)
                      }
                      hasError={formData.errors["tradeStyle"] ? true : false}
                    />
                  </Col>
                  <Col md={12}>
                    <SelectOption
                      defaultValue="Country"
                      value={
                        !isEmpty(formData.country)
                          ? formData.country.name
                          : "Country"
                      }
                      selectValue={formData.countries}
                      onChange={(e) => handleCountryChange(e)}
                      label="Select Country*"
                      showSearch
                      hasError={formData.errors["country"] ? true : false}
                    />
                  </Col>
                  <Col md={12}>
                    <SelectOption
                      defaultValue="State"
                      value={
                        !isEmpty(formData.state) ? formData.state.name : "State"
                      }
                      selectValue={formData.states}
                      onChange={(e) => handleStateChange(e)}
                      label="Select State*"
                      showSearch
                      hasError={formData.errors["state"] ? true : false}
                    />
                  </Col>
                  <Col md={12}>
                    <SelectOption
                      defaultValue="City"
                      value={
                        !isEmpty(formData.city) ? formData.city.name : "City"
                      }
                      selectValue={formData.cities}
                      onChange={(e) => handleCityChange(e)}
                      showSearch
                      label="Select City*"
                      hasError={formData.errors["city"] ? true : false}
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="Zip Code*"
                      placeholder="Zip Code"
                      type="text"
                      maxLength={10}
                      minLength={4}
                      value={formData.zipCode}
                      onChange={(e) => handleChange("zipCode", e.target.value)}
                      hasError={formData.errors["zipCode"] ? true : false}
                    />
                  </Col>
                  <Col md={12}>
                    <SelectOption
                      mode="multiple"
                      className="multiSelect"
                      placeholder="Type Of Business"
                      value={formData.companyType}
                      selectValue={companyDetail.companyType}
                      onChange={(e) => {
                        handleChange("companyType", e);
                      }}
                      label="Type Of Business *"
                      hasError={formData.errors["companyType"] ? true : false}
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="Physical Address*"
                      placeholder="Physical Address"
                      value={formData.physicalAddress}
                      onChange={(e) =>
                        handleChange("physicalAddress", e.target.value)
                      }
                      hasError={
                        formData.errors["physicalAddress"] ? true : false
                      }
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="Mailing Address*"
                      placeholder="Mailing Address"
                      value={formData.mailingAddress}
                      onChange={(e) =>
                        handleChange("mailingAddress", e.target.value)
                      }
                      hasError={
                        formData.errors["mailingAddress"] ? true : false
                      }
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="Phone Number*"
                      placeholder="Phone Number"
                      type="number"
                      value={formData.phoneNumber}
                      onChange={(e) =>
                        handleChange("phoneNumber", e.target.value)
                      }
                      hasError={formData.errors["phoneNumber"] ? true : false}
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      mobileISD
                      mobileSTD
                      ISDplaceholder="ISD"
                      STDplaceholder="STD"
                      onChange={(e) => handleChange("fax", e.target.value)}
                      value={formData.fax}
                      hasError={formData.errors["fax"] ? true : false}
                      type="number"
                      label="Fax No*"
                      placeholder="Fax No"
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="Mobile Number*"
                      placeholder="Mobile Number"
                      type="number"
                      value={formData.mobileNumber}
                      onChange={(e) =>
                        handleChange("mobileNumber", e.target.value)
                      }
                      hasError={formData.errors["mobileNumber"] ? true : false}
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="Email Address*"
                      placeholder="Email"
                      value={formData.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                      hasError={formData.errors["email"] ? true : false}
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="Fed Tax ID*"
                      placeholder="Fed Tax ID"
                      type="number"
                      value={formData.fedTaxId}
                      onChange={(e) => handleChange("fedTaxId", e.target.value)}
                      hasError={formData.errors["fedTaxId"] ? true : false}
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="Resale Tax# *"
                      placeholder="Resale Tax"
                      type="number"
                      value={formData.reSaleTax}
                      onChange={(e) =>
                        handleChange("reSaleTax", e.target.value)
                      }
                      hasError={formData.errors["reSaleTax"] ? true : false}
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="JBT ID*"
                      placeholder="JBT ID"
                      type="text"
                      value={formData.jbtId}
                      onChange={(e) => handleChange("jbtId", e.target.value)}
                      hasError={formData.errors["jbtId"] ? true : false}
                    />
                  </Col>
                  <Col md={12}>
                    <div className="from-group">
                      <label className="commonLabel">
                        Have you instituted an anti-money laundering program?
                      </label>
                      <Radio.Group
                        value={formData.laundering}
                        defaultValue={true}
                        onChange={(e) =>
                          handleChange("laundering", e.target.value)
                        }
                        className="mt-10"
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </div>
                  </Col>
                  <Col md={12}>
                    <SelectOption
                      mode="multiple"
                      className="multiSelect"
                      placeholder="How did you hear about us?"
                      value={formData.hearAboutUs}
                      selectValue={companyDetail.hearAboutUs}
                      onChange={(e) => {
                        handleChange("hearAboutUs", e);
                      }}
                      label="How did you hear about us? *"
                      hasError={formData.errors["hearAboutUs"] ? true : false}
                    />
                  </Col>

                  <Col md={12}>
                    <InputBlock
                      label="Facebook Username*"
                      maxLength={50}
                      placeholder="Facebook Username"
                      value={formData.facebookUsername}
                      onChange={(e) =>
                        handleChange("facebookUsername", e.target.value)
                      }
                      hasError={
                        formData.errors["facebookUsername"] ? true : false
                      }
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="Instagram Handle*"
                      maxLength={30}
                      placeholder="Instagram Handle"
                      value={formData.instagramHandle}
                      onChange={(e) =>
                        handleChange("instagramHandle", e.target.value)
                      }
                      hasError={
                        formData.errors["instagramHandle"] ? true : false
                      }
                    />
                  </Col>
                  <Col md={12}>
                    <DatePicker
                      label="Year Business Started*"
                      disabledDate={(current) => {
                        return current && current >= moment().startOf("day");
                      }}
                      expiryDate={formData.date}
                      placeholder="Date"
                      format="ll"
                      handleChange={(date) =>
                        setFormData({
                          ...formData,
                          date: date && date.toISOString(),
                        })
                      }
                      hasError={formData.errors["date"] ? true : false}
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="Years At Present Location*"
                      placeholder="Years At Present Location"
                      type="number"
                      min={0}
                      maxLength={3}
                      value={formData.yearsAtLocation}
                      onChange={(e) =>
                        handleChange("yearsAtLocation", e.target.value)
                      }
                      hasError={
                        formData.errors["yearsAtLocation"] ? true : false
                      }
                    />
                  </Col>
                  <Col md={12}>
                    <SelectOption
                      mode="multiple"
                      className="multiSelect"
                      placeholder="Legal Status Of Organization"
                      value={formData.legalStatusOfOrg}
                      selectValue={companyDetail.legalStatusOfOrg}
                      onChange={(e) => {
                        handleChange("legalStatusOfOrg", e);
                      }}
                      label="Legal Status Of Organization *"
                      hasError={
                        formData.errors["legalStatusOfOrg"] ? true : false
                      }
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="Web site*"
                      maxLength={50}
                      placeholder="Web site"
                      value={formData.web}
                      onChange={(e) => handleChange("web", e.target.value)}
                      hasError={formData.errors["web"] ? true : false}
                    />
                  </Col>
                </Row>
                <>
                  <Heading
                    title="LIST OF OWNERS OR AUTHORIZED PERSONS TO PLACE ORDERS "
                    className="popupInnerTitle mb-20 mt-20 text-center"
                  />

                  {inputListOne.map((x, i) => {
                    return (
                      <Row key={i} className="tradeDetails" gutter={16}>
                        <Col md={6}>
                          <InputBlock
                            name="firstName"
                            required={true}
                            placeholder="Enter First Name"
                            value={x.firstName}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </Col>
                        <Col md={6}>
                          <InputBlock
                            className="ml10"
                            required={true}
                            name="lastName"
                            placeholder="Enter Last Name"
                            value={x.lastName}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </Col>
                        <Col md={6}>
                          <InputBlock
                            className="ml10"
                            required={true}
                            name="designation"
                            placeholder="Enter Designation"
                            value={x.designation}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </Col>
                        <Col md={6}>
                          <InputBlock
                            className="ml10"
                            name="mob"
                            required={true}
                            type="number"
                            placeholder="Enter Mobile Number"
                            value={x.mob}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </Col>
                        <Col md={24}>
                          <div className="btn-box">
                            {inputListOne.length !== 1 && (
                              <button
                                className="commonButton"
                                onClick={() => handleRemoveClick(i)}
                              >
                                Remove
                              </button>
                            )}
                            {inputListOne.length - 1 === i && (
                              <button
                                className="commonButton"
                                onClick={handleAddClick}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </>
                <>
                  <Heading
                    title="TRADE REFERENCES: (LIST AT LEAST 5 REFERENCES PLEASE) "
                    className="popupInnerTitle mb-20 mt-20 text-center"
                  />

                  {inputListTwo.map((x, i) => {
                    return (
                      <Row key={i} className="tradeDetails" gutter={16}>
                        <Col md={6}>
                          <InputBlock
                            name="companyName"
                            required={true}
                            placeholder="Enter Company Name"
                            value={x.companyName}
                            onChange={(e) => handleInputChangeTwo(e, i)}
                          />
                        </Col>
                        <Col md={6}>
                          <InputBlock
                            className="ml10"
                            name="phoneNum"
                            required={true}
                            type="number"
                            min={0}
                            placeholder="Enter Phone Number"
                            value={x.phoneNum}
                            onChange={(e) => handleInputChangeTwo(e, i)}
                          />
                        </Col>
                        <Col md={6}>
                          <InputBlock
                            className="ml10"
                            required={true}
                            name="email"
                            type="email"
                            placeholder="Enter Email"
                            value={x.email}
                            onChange={(e) => handleInputChangeTwo(e, i)}
                          />
                        </Col>
                        <Col md={6}>
                          <InputBlock
                            className="ml10"
                            required={true}
                            name="businessEntityName"
                            type="text"
                            placeholder="Enter Business Entity Name"
                            value={x.businessEntityName}
                            onChange={(e) => handleInputChangeTwo(e, i)}
                          />
                        </Col>
                        <Col md={24}>
                          <div className="btn-box">
                            {inputListTwo.length !== 1 && (
                              <button
                                className="commonButton"
                                onClick={() => handleRemoveClickTwo(i)}
                              >
                                Remove
                              </button>
                            )}
                            {inputListTwo.length - 1 === i && (
                              <button
                                className="commonButton"
                                onClick={handleAddClickTwo}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </>
                <Row gutter={16}>
                  <Col md={12}>
                    <FileUpload
                      title="Sign Of Authorized Signator"
                      onUpload={(e) => uploadDoc("signDoc", e)}
                    />
                    ( Only .png, .jpg, .jpeg & .pdf are allowed !)
                    <br />
                    {formData.fileName && <b>{formData.fileName}</b>}
                    {formData.signDoc && (
                      <img
                        crossOrigin="anonymous"
                        height="100px"
                        width="100px"
                        src={
                          formData.signDoc.includes(".pdf")
                            ? PDFICON
                            : BASE_URL + "/" + formData.signDoc
                        }
                        alt=""
                      />
                    )}
                  </Col>
                  <Col md={12}>
                    <DatePicker
                      expiryDate={moment(new Date(), "YYYY-MM-DD")}
                      defaultValue={moment(new Date(), "YYYY-MM-DD")}
                      disabled
                    />
                  </Col>
                  <Col md={12}>
                    <InputBlock
                      label="Print Name*"
                      placeholder="Print Name"
                      value={formData.printName}
                      onChange={(e) =>
                        handleChange("printName", e.target.value)
                      }
                      hasError={formData.errors["printName"] ? true : false}
                    />
                  </Col>
                </Row>

                <div className="d-flex appointmentButton mt-20">
                  By signing, I confirm that the above details are true and
                  correct along with the &nbsp;
                  <a onClick={() => setVisible(!visible)}> Credit Agreement.</a>
                  <Drawer
                    title={false}
                    onClose={() => setVisible(!visible)}
                    visible={visible}
                    className="diamondListinSidebar sm-size"
                  >
                    <Heading
                      title="Credit Agreement"
                      className="staticMainTitle text-center mb-20"
                    />
                    <p>
                      Applicant warrants that the foregoing information provided
                      is current and accurate and authorize Belgium Jewellery &
                      Diamond Pvt. Ltd. to check credit, employment history,
                      bank and trade references and grants permission to Belgium
                      Jewellery & Diamond Pvt. Ltd. to answer questions about
                      its credit experience with applicant.
                    </p>
                    <p>
                      By signing this application, the signor represents he is a
                      principal of the corporation and agrees to be personally
                      liable to Belgium Jewellery & Diamond Pvt. Ltd. for the
                      unpaid debts of the corporation.
                    </p>
                    <p>
                      All the past due accounts accrue interest at the rate of
                      1.5% per month or the maximum interest rate permitted by
                      law.
                    </p>
                    <p>
                      Applicant further agrees to pay all costs, NSF returned
                      check fee, court costs and attorney’s fees.
                    </p>
                    <p>
                      Applicant grants Belgium Jewellery & Diamond Pvt. Ltd. a
                      limited power of attorney to make and file a financing
                      statement in any jurisdiction if account is delinquent.
                    </p>
                    <p>
                      Applicant acknowledges that by signing for any delivery by
                      whatever method, constitutes acceptance of the
                      invoice/memo terms.
                    </p>
                    <p>
                      Applicant agrees that venue and jurisdiction shall be in
                      New York, NY.
                    </p>
                    <p>
                      No sales persons representing Belgium Jewellery & Diamond
                      Pvt. Ltd. are authorized to sign for any merchandise even
                      for return goods.
                    </p>
                    <p>
                      Any goods to be delivered to Belgium Jewellery & Diamond
                      Pvt. Ltd. should be shipped to the office address as
                      mentioned above.
                    </p>
                    <p>
                      Belgium Jewellery & Diamond Pvt. Ltd. will not be
                      responsible for any return goods/memos/cash handed over to
                      sales people whatsoever the reason.
                    </p>
                    <p>
                      Applicant further represents that he is not now in the us
                      military and will notify Belgium Jewellery & Diamond Pvt.
                      Ltd., in writing, if such status changes.
                    </p>
                    <p>
                      The buyer agrees that venue and jurisdiction shall be in
                      New York, NY. All reference to gender is to be construed
                      as neutral.
                    </p>
                    <p>
                      Any dispute, controversy, or claim between you and/or your
                      company and shalibhadra kothari and/or Belgium Jewellery &
                      Diamond Pvt. Ltd. , arising out of or relating to any past
                      or future transactions in which shalibhadra kothari and/or
                      Belgium Jewellery & Diamond Pvt. Ltd. has sold,
                      transferred, or delivered goods to you or your company,
                      shall be exclusively determined by arbitration
                      administered by the diamond dealers club, inc. (“DDC”) in
                      New York city under its by-laws and rules and regulations.
                    </p>
                    <p>
                      The parties submit themselves to the jurisdiction of the
                      ddc, and judgment on any award rendered by the arbitrators
                      may be entered in any court having jurisdiction thereof.
                    </p>
                    <p>
                      You agree to be personally responsible for any awards
                      rendered by the arbitrators. You hereby waive any claim or
                      objection relating to forum non convenience.
                    </p>
                  </Drawer>
                </div>
                <div className="d-flex appointmentButton mt-20">
                  <button
                    onClick={(e) => handleSignup(e, false)}
                    className="commonButton"
                  >
                    Submit
                  </button>
                  <button
                    onClick={(e) => handleSignup(e, true)}
                    className="commonButton"
                  >
                    Submit & Download
                  </button>
                  <button onClick={handleCancel} className="commonOutline">
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineKYC;
