/* eslint-disable */
import React, { Component } from "react";

import SelectOption from "containers/SelectOption";
import TextArea from "containers/TextArea";
import { isArray, isEmpty, logWarn } from "util/utils";
import { timeConvert } from "util/utils";
import Messages from "constants/Messages";

import {
  getDiamondBlockMasterHold,
  handleHold,
} from "../../DiamondList/DiamondListFunctions";
import { cloneDeep, find, groupBy, uniqBy } from "lodash";
import moment from "moment";
import InputField from "../../common/InputBlock";
import { connect } from "react-redux";

import { Row, Col } from "antd";
import {
  DIAMOND_BID,
  DIAMOND_BLOCK,
  LOCAL_STORAGE_VAR,
} from "constants/Common";
import UtilService from "services/util";
import HoldCommonModal from "../HoldCommonModal";
import InputBlock from "../../common/InputBlock";
import OpenNotification from "../CommonButton/OpenNotification";
import { newDiamondPrice, newDiamondPriceWithType } from "./FinalCalculations";

export const holdPopup = {
  type: [{ value: "Sales Rep. Hold" }, { value: "Client Hold" }],
  Duration: [{ value: "20 Minutes" }, { value: "30 Minutes" }],
};
const HoldMasterCodes = {
  B: {
    C: "CB",
    S: "SB",
    E: "EB",
    A: "B",
  },
  J: {
    C: "CJ",
    S: "SJ",
    E: "EJ",
    A: "J",
  },
  I: {
    C: "CX",
    S: "SX",
    E: "EX",
    A: "I",
  },
  H: {
    C: "CH",
    A: "H",
    S: "SH",
    E: "EH",
  },
  N: {
    A: "N",
  },
  H1: {
    A: "H1",
  },
  M: {
    A: "M",
    C: "CM",
    S: "SM",
    E: "EM",
  },
};
const revHold = {
  CB: "B",
  SB: "B",
  EB: "B",
  CJ: "J",
  SJ: "J",
  EJ: "J",
  CX: "I",
  SX: "I",
  EX: "I",
};

class HoldPopup extends Component {
  state = {
    clientList: [],
    client: null,
    error: {},
    remark: null,
    typeList: [],
    type: null,
    holdDurationList: [],
    holdDuration: null,
    checked: cloneDeep(this.props.checked),
    userTerms: this.props.userTerms,
    isTimeEditable: false,
    isClientShow: false,
    isTimeShow: false,
    codeMaster: [],
    initialHoldDuration: null,
    highEndClientList: [],
    highEndClient: null,
    holdTypeList: [],
    user: [],
    userName: "",
  };
  initialState = cloneDeep(this.state);
  componentDidMount() {
    const user =
      JSON?.parse(
        window?.atob(localStorage?.getItem(`${LOCAL_STORAGE_VAR}-user`)),
      ) ?? {};

    this.setState({
      userName: user && user.account ? user.account.companyName : "-",
      user: user,
      client: user,
    });

    if (!isEmpty(this.props.client)) {
      let clientData = this.setInitialClientData();
      this.setState({ ...clientData }, () => {
        this.calculateDiamond();
      });
    }
    this.getStageBlockData();
  }

  userTermsDiamondCal = () => {
    let newPricedRows = this.state.checked.map((x) =>
      newDiamondPrice(
        x,
        this.state.userTerms !== null ? this.state.userTerms : {},
      ),
    );
    this.setState({ checked: newPricedRows });
  };

  setInitialClientData = () => {
    let client = this.props.client;
    return {
      clientList: client?.id
        ? [
            {
              value: `${client?.companyName} | ${client?.user?.firstName} ${client?.user?.lastName}`,
              key: this?.props?.client?.user?.id,
              ...client,
            },
          ]
        : [],
      client: client?.user?.id ? client?.user?.id : null,
      account: client?.id ? client?.id : null,
    };
  };
  // getHighEndClientData = async () => {
  //   this.setState({ loading: !this.state.loading });

  //   const loginUser = getLoginUser();
  //   const [, res] = await getHighEndClient({ seller: loginUser?.id });
  //   if (res?.code === "OK" && res?.data) {
  //     this.setState({ loading: !this.state.loading });
  //     let highEndClientList = res.data.map((d) => ({
  //       value: `${d.companyName} | ${d?.user?.firstName ? d?.user?.firstName : ""} ${
  //         d?.user?.lastName ? d?.user?.lastName : ""
  //       }`,
  //       key: d?.user?.id,
  //       ...d,
  //     }));
  //     this.setState({ highEndClientList });
  //   }
  // };

  removeError = (filed) => {
    let error = this.state.error;
    delete error[filed];
    this.setState({ error });
  };

  isValue = (value) => {
    //for set place holder value
    if (value) {
      return value;
    } else {
      return undefined;
    }
  };

  getStageBlockData = async () => {
    const loginUser = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-user`,
    );

    await getDiamondBlockMasterHold(
      { filter: { wSts: "H", blockCode: ["LH", "SHO"] } },
      (data) => {
        if (data?.code === "OK" && data?.data.length) {
          const diamondwSts = groupBy(this.props.checked, "sSts");
          const isBlindnAboveCaratLimit = this.props.checked.filter(
            (d) =>
              d.crt > (this.props?.minCaratHoldLimit || 4) &&
              d.bidType === DIAMOND_BID.TYPE.BLIND,
          );
          const isLookNBid = this.props.checked.filter(
            (d) => d.bidType === DIAMOND_BID.TYPE.OPEN,
          );

          let typeList = [];
          data?.data?.length &&
            data.data
              .filter(
                (item) =>
                  item?.isWebVisible &&
                  loginUser?.blockSettings?.includes(item?.id),
              )
              .forEach((type) => {
                Object.keys(diamondwSts).forEach((key) => {
                  if (
                    this.props?.currentType === "NewArrivalDiamondList" &&
                    isArray(type?.allowSts) &&
                    ["B", ...type.allowSts].includes(key)
                  ) {
                    typeList.push({
                      value: type?.webDisplay,
                      key: type?.id,
                      ...type,
                    });
                  } else if (
                    isArray(type?.allowSts) &&
                    type.allowSts.includes(key)
                  ) {
                    typeList.push({
                      value: type?.webDisplay,
                      key: type?.id,
                      ...type,
                    });
                  }
                });
              });

          if (
            this.props?.currentType === "NewArrivalDiamondList" &&
            ((isArray(isBlindnAboveCaratLimit) &&
              isBlindnAboveCaratLimit.length > 0) ||
              (isArray(isLookNBid) && isLookNBid.length > 0))
          ) {
            typeList = typeList?.filter((tl) => tl?.blockCode === "H1");
            typeList = typeList?.map((t) => {
              t.timeInMinutes = 30;
              return t;
            });
          }

          typeList = uniqBy(typeList, "value");

          const internalHold = typeList.find((d) => d?.blockCode === "I");
          const type = typeList?.[0]?.key;

          if (internalHold?.id === type) this.getHighEndClientData();
          const timeDuration = typeList?.[0]?.blockTimes?.map((item) => ({
            key: item,
            value: item > 60 ? timeConvert(item) : `${item} minutes`,
          }));
          this.setState(
            {
              typeList,
              type,
              blockTimes: timeDuration,
              codeMaster: data.data,
              internalHold,
            },
            this.onSelectType,
          );
        } else {
          logWarn(data);
        }
      },
    );
  };

  onSelectType = () => {
    let type = find(this.state.typeList, (d) => {
      return d.key === this.state.type;
    });
    let data = {};

    if (type?.isAutoRelease) {
      data.isTimeShow = true;
      data.initialHoldDuration = data.holdDuration = type.timeInMinutes;

      data.isTimeEditable = !!type?.isTimeEditable;
    } else {
      data.isTimeShow = false;
      data.isTimeEditable = false;
      data.holdDuration = null;
    }
    data.isClientShow = !!type?.isClientRequired;
    data.blockTimes = type?.blockTimes?.map((item) => ({
      key: item,
      value: item > 60 ? timeConvert(item) : `${item} minutes`,
    }));

    data.defaultAccount = type?.defaultAccount;
    data.defaultUser = type?.defaultUser;

    this.setState({ ...this.state, ...data });
  };

  onSaveHold = async () => {
    let error = {};
    let {
      client,
      remark,
      type,
      holdDuration,
      isClientShow,
      isTimeShow,
      initialHoldDuration,
      user,
    } = this.state;

    // if (client === null) {
    //   error.client = Messages.clientNameSelect;
    // }
    // if (
    //   typeList.find((d) => d?.key === type)?.blockCode === "I" &&
    //   highEndClient === null
    //   //&& Storage.get('user')?.type === USERS_TYPE.SELLER
    // ) {
    //   error.highEndClient = Messages.highEndClient;
    // }
    if (isTimeShow && !holdDuration) {
      error.holdDuration = "The time duration field is required";
    } else if (isTimeShow && holdDuration < initialHoldDuration) {
      error.holdDuration =
        "Time duration must be greater than initialHoldDuration";
    }
    if (type === null) {
      error.type = Messages.typeSelect;
    }

    this.setState({ error });
    if (isEmpty(error)) {
      const blockSettings = UtilService.getLocalStorageItem(`block-setting`);

      const holdItem = Object.entries(blockSettings?.["HOLD"] || {}).map(
        ([key, value]) => {
          return this.state.checked.filter((x) => value.includes(x.sSts));
        },
      );
      const value = holdItem.flat();
      let diamonds = value.map((d) => {
        let avCode = find(this.state.codeMaster, { id: type }).blockCode;
        if (revHold[avCode]) avCode = revHold[avCode];

        let bCode = HoldMasterCodes[avCode] ? HoldMasterCodes[avCode] : null;

        let interCode = bCode && bCode[d.sSts] ? bCode[d.sSts] : null;
        // let findCode = interCode ? find(this.state.codeMaster, { blockCode: interCode })?.id : null;

        return {
          diamond: d.id,
          blockPricePerCarat: d.calcPricePerCarat ? d.calcPricePerCarat : 0,
          blockAmount: d.calcAmount ? d.calcAmount : 0,
          vnd: d.vnd,
          vStnId: d.vStnId,
          blockSetting: type,
          blockCode: interCode,
        };
      });

      if (isEmpty(diamonds)) {
        this.props.handleCancel();
        return OpenNotification({
          type: "error",
          title: "Please select Diamond.",
        });
      }

      let request = {
        remark: remark ? remark : undefined,
        blockType: DIAMOND_BLOCK.TYPE.HOLD,
        diamonds,
      };
      request.user = user?.id ?? "";
      request.userAccount = user?.account?.id ?? "";
      request.seller = user?.seller ?? "";

      if (isTimeShow) {
        request.days = holdDuration;
        request.timeInMin = holdDuration;
        request.validTillDate = moment()
          .add("m", Number(holdDuration))
          .toISOString();
      }

      await handleHold(request, (res) => {
        if (res) {
          this.props.handleCancel();
          this.props.chnageStatus();
        } else {
          this.props.handleCancel();
        }
      });
    }
  };
  calculateDiamond = (client) => {
    // let client = find(this.state.clientList, (d) => d.key === this.state.client);
    let newPricedRows = this.state.checked.map((x) =>
      newDiamondPriceWithType(
        x,
        client?.user?.accountTerm || client?.accountTerm,
      ),
    );
    this.setState({ checked: newPricedRows });
  };

  render() {
    return (
      <>
        <HoldCommonModal
          handleOk={this.onSaveHold}
          handleCancel={this.props.handleCancel}
          title="Hold Stone"
          submitTitle="Submit"
          cancelTitle="Cancel"
          footerShow
          visible
        >
          <div className="searchPopupCommonHold">
            {/* <div className="holdPopupBlock"> */}
            <Row>
              <Col md={12}>
                <SelectOption
                  placeholder="Select Type"
                  value={this.isValue(this.state.type)}
                  selectValue={this.state.typeList}
                  label="Type *"
                  onChange={(type = null) => {
                    this.setState({ type }, () => {
                      this.removeError("type");
                      this.onSelectType();
                      // if (this.state.typeList.find((d) => d?.key === type)?.blockCode === "I") {
                      //   this.getHighEndClientData();
                      // }
                      // this.getHoldDuration();
                    });
                  }}
                  error={this.state.error.type}
                />
              </Col>
              <Col md={12}>
                {/* <InputField
                  // inputClassName={`${this.state.isTimeShow && !this.state.isTimeEditable ? "disableBlock" : ""}`}
                  holdLabel="Time Duration"
                  value={this.state.holdDuration}
                  disabled={true}
                /> */}
                {isArray(this.state.blockTimes) &&
                this.state.blockTimes?.length ? (
                  <SelectOption
                    placeholder="Select Time Duration"
                    value={`${this.state.holdDuration}`}
                    selectValue={this.state.blockTimes}
                    label="Time Duration *"
                    onChange={(holdDuration = null) =>
                      this.setState({ holdDuration })
                    }
                    error={this.state.error.holdDuration}
                  />
                ) : (
                  <InputField
                    className="width-100"
                    holdLabel="Time Duration"
                    value={this.state.holdDuration}
                    max={this.state.initialHoldDuration}
                    type="number"
                    min="0"
                    disabled
                    error={this.state.error?.holdDuration}
                  />
                )}
              </Col>

              {/* {this.state.isTimeShow && !this.state.isTimeEditable && (
                <Col md={12}>
                  {isArray(this.state.blockTimes) && this.state.blockTimes?.length ? (
                    <SelectOption
                      placeholder="Select Time Duration"
                      value={`${this.state.holdDuration}`}
                      selectValue={this.state.blockTimes}
                      label="Time Duration *"
                      onChange={(holdDuration = null) => this.setState({ holdDuration })}
                      error={this.state.error.holdDuration}
                    />
                  ) : (
                    <InputField
                      className="width-100"
                      holdLabel="Time Duration"
                      value={this.state.holdDuration}
                      max={this.state.initialHoldDuration}
                      type="number"
                      min="0"
                      onChange={(e) => {
                        this.setState({ holdDuration: e.target.value });
                        if (this.state.initialHoldDuration < e.target.value) {
                          let error = {
                            ...this.state.error,
                            holdDuration: Messages.timeSelect.replace("max", this.state.initialHoldDuration),
                          };
                          this.setState({ error });
                        } else {
                          this.removeError("holdDuration");
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === ".") e.preventDefault();
                      }}
                      error={this.state.error?.holdDuration}
                    />
                  )}
                </Col>
              )} */}

              <Col md={12}>
                <InputBlock
                  holdLabel={"Client Name"}
                  placeholder={"Client Name"}
                  value={this.state?.userName}
                  disabled
                />
              </Col>

              {/**FIXME:For temp set admin login conditon */}
              {this.state.internalHold?.id === this.state.type && (
                <Col md={12}>
                  <SelectOption
                    placeholder="Select HighEnd Client"
                    value={this.isValue(this.state.highEndClient)}
                    selectValue={this.state.highEndClientList}
                    label="HighEnd Client *"
                    onChange={(highEndClient = null) => {
                      this.removeError("highEndClient");
                      let hightEnfDetails = this.state.highEndClientList?.find(
                        (d) => d.key === highEndClient,
                      );
                      this.setState({
                        account: hightEnfDetails?.id,
                        highEndClient,
                      });
                    }}
                    error={this.state.error.highEndClient}
                  />
                </Col>
              )}
              {/* </div> */}
              <Col md={12}>
                <TextArea
                  label="Comment"
                  onChange={(e) => {
                    this.setState({ remark: e.target.value });
                  }}
                  value={this.state.remark}
                  placeholder="Comment"
                />
              </Col>
            </Row>
          </div>
        </HoldCommonModal>
      </>
    );
  }
}

export default connect(({ auth }) => ({ loginUser: auth }))(HoldPopup);
