/* eslint-disable */
import React, { Component } from "react";
import IntlMessages from "../../util/IntlMessages";

class NoDataShow extends Component {
  render() {
    return (
      <div className="empty-data" style={this.props.style && this.props.style}>
        <h3>
          {this.props.message ? (
            this.props.message
          ) : (
            <IntlMessages id="app.noData" />
          )}
        </h3>
      </div>
    );
  }
}

export default NoDataShow;
