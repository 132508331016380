/* eslint-disable */
import React from "react";
import { STATUS_COLOR } from "../common/DiamondListing/Status";
import { formatNumber } from "../common/DiamondListing/SelectStone";
import { getParams, LISTINGPAGES, getStoneInfo } from "./DiamondListFunctions";
import { DIAMOND_BID } from "../../constants/Common";
import Countdown from "../Offer/Timer";
import moment from "moment";
import { OFFERSTATUS } from "../Offer";
import { QUOTE, HOURS } from "../common/DiamondListing/QuotePopup";

const MobileViewListCard = (props) => {
  const hoursList = new Date().getHours() >= 15 ? [24, 48] : HOURS;
  return (
    <div
      className={`diamondListMobile as_app ${
        props.activeClass ? props.activeClass : null
      } `}
      onClick={props.onClick}
    >
      <div
        className={`diamodListItemWrapper ${
          STATUS_COLOR[props.data.wSts]
            ? STATUS_COLOR[props.data.wSts]
            : "green"
        }
`}
      >
        {props.trending ? (
          <>
            <div className="caratStatus">
              <p>
                <span className="cVal">
                  {props.data.SIZE
                    ? props.data.SIZE
                    : formatNumber(parseFloat(props.data.carat).toFixed(2))}
                </span>
                <small className="cName">Carat</small>
              </p>
              <p
                className={`sStatus ${
                  STATUS_COLOR[props.data.wSts]
                    ? STATUS_COLOR[props.data.wSts]
                    : "green"
                }`}
              >
                <span className="lDot"></span>
                <span className="lName">{props.data?.wSts}</span>
              </p>
            </div>
            <div className="diamodListItemContent">
              <div className="priceCaratContainer">
                <p className="sId">
                  {/* <a href={"/" + LISTINGPAGES.DETAIL + "/" + props.data.id}> */}
                  {props.data?.vStnId}
                  {/* </a> */}
                </p>
                <p className="sShape">
                  {props.data.SHAPE ? props.data.SHAPE : props.data.shpNm}
                </p>
                {!props.data.PURITY && (
                  <p className="sDis">
                    {" "}
                    {!props.data.isFcCol ? (
                      props.data?.back ? (
                        <span>
                          {formatNumber(parseFloat(props.data.back).toFixed(2))}
                          %
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </p>
                )}
                <p className="priceCarat">
                  {!!props.data.PURITY && props.data.ctPr ? (
                    <span>
                      {formatNumber(parseFloat(props.data.ctPr).toFixed(2))}{" "}
                      $/Cts
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div
                className="stoneInfo"
                style={{ justifyContent: "space-around" }}
              >
                <p>
                  <span className="sColor">
                    {props.data.COLOR
                      ? props.data.COLOR
                      : props.data.isFcCol
                      ? props.data?.fcColNm
                          ?.split(" ")
                          ?.map((x) => x.charAt(0))
                          ?.join()
                          .replaceAll(",", "")
                      : props.data.colNm}
                  </span>
                  <span className="sFlu">
                    {props.data.PURITY ? props.data.PURITY : props.data.clrNm}
                  </span>
                  <span className="sc">
                    {props.data.CUT ? props.data.CUT : props.data.cutNm ?? "-"}
                  </span>
                  <span className="sc">
                    {props.data.POLISH
                      ? props.data.POLISH
                      : props.data.polNm ?? "-"}
                  </span>
                  <span className="sc">
                    {props.data.SYMM
                      ? props.data.SYMM
                      : props.data.symNm ?? "-"}
                  </span>
                  <span className="in">
                    {props.data.FLS ? props.data.FLS : props.data.fls ?? "-"}
                  </span>
                  {!props.data.PURITY && (
                    <span className="lab">{props.data?.lbNm ?? "-"}</span>
                  )}
                </p>
                {!props.data.PURITY && (
                  <p className="sAmt">
                    {props.data.amt ? (
                      <span>
                        {formatNumber(parseFloat(props.data.amt).toFixed(2))}{" "}
                        $/Amt
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                )}
              </div>
              {!props.data.PURITY && (
                <div className="smeasure">
                  <p>
                    <span className="title">M : </span>
                    <span className="val">{props.data.msrmnt ?? "-"}</span>
                  </p>
                  <p>
                    <span className="title">R : </span>
                    <span className="val">{props.data.ratio ?? "-"}</span>
                  </p>
                  <p>
                    <span className="title">TD% : </span>
                    <span className="val">{props.data.depPer ?? "-"}</span>
                  </p>
                  <p>
                    <span className="title">TB% : </span>
                    <span className="val">{props.data.tblPer ?? "-"}</span>
                  </p>
                </div>
              )}
            </div>
          </>
        ) : props.quotePage ? (
          <div className="diamodListItemContent">
            <div className="mobileListItem activeStatus pending">
              <span>{OFFERSTATUS[props.data.offerStatus]}</span>
              {moment(props.data.offerValidDate) - moment() < 0 ? (
                <span>Offer Expired</span>
              ) : (
                <Countdown
                  date={
                    props.data.offerStatus === 1
                      ? moment(props.data.offerValidDate)
                      : moment()
                  }
                  currTime={moment()}
                />
              )}
            </div>
            <div className="priceCaratContainer">
              <p className="sId">
                {/* <a href={"/" + LISTINGPAGES.DETAIL + "/" + props.data.id}> */}
                {props.data?.vStnId}
                {/* </a> */}
              </p>
              <p className="sShape">{props.data.shpNm}</p>
              <p className="sDis">
                {" "}
                {(props.data.trackDiscount
                  ? parseFloat(props.data.trackDiscount).toFixed(2) + " / "
                  : "") + parseFloat(props.data.trackPricePerCarat).toFixed(0)}
              </p>
              <p className="priceCarat">
                {" "}
                {(props.data.newDiscount
                  ? parseFloat(props.data.newDiscount).toFixed(2) + " / "
                  : "") + parseFloat(props.data.newPricePerCarat).toFixed(0)}
              </p>
            </div>
            <div className="stoneInfo">
              <p>
                <span className="sColor">
                  {props.data.isFcCol
                    ? props.data?.fcColNm
                        ?.split(" ")
                        ?.map((x) => x.charAt(0))
                        ?.join()
                        .replaceAll(",", "")
                    : props.data.colNm}
                </span>
                <span className="sFlu">{props.data?.clrNm}</span>
                <span className="sc">{props.data?.cutNm ?? "-"}</span>
                <span className="sc">{props.data?.polNm ?? "-"}</span>
                <span className="sc">{props.data?.symNm ?? "-"}</span>
                <span className="in">{props.data?.fluNm ?? "-"}</span>
                <span className="lab">{props.data?.lbNm ?? "-"}</span>
              </p>
              <p className="sAmt">
                {props.data.newDiscount ? (
                  <span>
                    {formatNumber(
                      parseFloat(props.data.newDiscount).toFixed(2),
                    )}{" "}
                    Bargain(%)
                  </span>
                ) : (
                  ""
                )}
              </p>
            </div>
            <div className="smeasure">
              <p>
                <span className="title">M : </span>
                <span className="val">{props.data.msrmnt ?? "-"}</span>
              </p>
              <p>
                <span className="title">R : </span>
                <span className="val">{props.data.ratio ?? "-"}</span>
              </p>
              <p>
                <span className="title">TD% : </span>
                <span className="val">{props.data.depPer ?? "-"}</span>
              </p>
              <p>
                <span className="title">TB% : </span>
                <span className="val">{props.data.tblPer ?? "-"}</span>
              </p>
            </div>
          </div>
        ) : props.bidPopup ? (
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              {props.bidPopup.getBidPrCell(props.data)}
            </div>
            <div className="quoteExtraItem">
              <span>Bid Disc(%): </span>
              <span>{parseFloat(props.data.bidDiscount || 0).toFixed(2)}</span>
            </div>
            <div className="quoteExtraItem">
              <span>Bid Value: </span>
              <span>${parseFloat(props.data.bidAmount || 0).toFixed(2)}</span>
            </div>
          </div>
        ) : props.QuotePopup ? (
          <>
            <div className="caratStatus">
              <p>
                <span className="cVal">
                  {parseFloat(props.data?.crt).toFixed(2)}
                </span>
                <small className="cName">Carat</small>
              </p>
              <p
                className={`sStatus ${
                  STATUS_COLOR[props.data.wSts]
                    ? STATUS_COLOR[props.data.wSts]
                    : "green"
                }`}
              >
                <span className="lDot"></span>
                <span className="lName">{props.data?.wSts}</span>
              </p>
            </div>
            <div className="diamodListItemContent">
              <div className="priceCaratContainer">
                <p className="sId">
                  {/* <a href={"/" + LISTINGPAGES.DETAIL + "/" + props.data.id}> */}
                  {props.data?.vStnId}
                  {/* </a> */}
                </p>
                <p className="sShape">{props.data.shpNm}</p>
                <p className="sDis">
                  {" "}
                  {!props.data.isFcCol ? (
                    props.data?.back ? (
                      <span>
                        {formatNumber(parseFloat(props.data.back).toFixed(2))}%
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </p>
                <p className="priceCarat">
                  {props.data.ctPr ? (
                    <span>
                      {formatNumber(parseFloat(props.data.ctPr).toFixed(2))}{" "}
                      $/Cts
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="stoneInfo">
                <p>
                  <span className="sColor">
                    {props.data.isFcCol
                      ? props.data?.fcColNm
                          ?.split(" ")
                          ?.map((x) => x.charAt(0))
                          ?.join()
                          .replaceAll(",", "")
                      : props.data.colNm}
                  </span>
                  <span className="sFlu">{props.data?.clrNm}</span>
                  <span className="sc">{props.data?.cutNm ?? "-"}</span>
                  <span className="sc">{props.data?.polNm ?? "-"}</span>
                  <span className="sc">{props.data?.symNm ?? "-"}</span>
                  <span className="in">{props.data?.fluNm ?? "-"}</span>
                  <span className="lab">{props.data?.lbNm ?? "-"}</span>
                </p>
                <p className="sAmt">
                  {props.data.amt ? (
                    <span>
                      {formatNumber(parseFloat(props.data.amt).toFixed(2))}{" "}
                      $/Amt
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="stoneInfo">
                <span className="tableInput">
                  <select
                    onChange={(e) => props.parent.onQuoteChange(e.target.value)}
                  >
                    <option value="">Select</option>
                    {QUOTE.map((x, i) => (
                      <option key={i} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </span>
                <span className="tableInput">
                  <select
                    onChange={(e) => props.parent.onHourChange(e.target.value)}
                  >
                    <option value="">Select</option>
                    {hoursList.map((x, i) => (
                      <option key={i} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              <div className="smeasure">
                <p>
                  <span className="title">M : </span>
                  <span className="val">{props.data.msrmnt ?? "-"}</span>
                </p>
                <p>
                  <span className="title">R : </span>
                  <span className="val">{props.data.ratio ?? "-"}</span>
                </p>
                <p>
                  <span className="title">TD% : </span>
                  <span className="val">{props.data.depPer ?? "-"}</span>
                </p>
                <p>
                  <span className="title">TB% : </span>
                  <span className="val">{props.data.tblPer ?? "-"}</span>
                </p>
              </div>
            </div>
          </>
        ) : props.finalCalc ? (
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              {props.data.calcDiscount ? <span>Final Disc%: </span> : ""}
              {!props.data.isFcCol ? (
                props.data.calcDiscount ? (
                  <span>{parseFloat(props.data.calcDiscount).toFixed(2)}</span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <div className="quoteExtraItem">
              {props.data.calcPricePerCarat ? <span>Final Rate: </span> : ""}
              {props.data.calcPricePerCarat ? (
                <span>
                  ${parseFloat(props.data.calcPricePerCarat).toFixed(2)}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="quoteExtraItem">
              {props.data.calcAmount ? <span>Final Value: </span> : ""}
              {props.data.calcAmount ? (
                <span>${parseFloat(props.data.calcAmount).toFixed(2)}</span>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : props.commingSoon ? (
          <>
            <div className="caratStatus">
              <p>
                <span className="cVal">
                  {parseFloat(props.data?.crt).toFixed(2)}
                </span>
                <small className="cName">Carat</small>
              </p>
              <p
                className={`sStatus ${
                  STATUS_COLOR[props.data.wSts]
                    ? STATUS_COLOR[props.data.wSts]
                    : "green"
                }`}
              >
                <span className="lDot"></span>
                <span className="lName">{props.data?.wSts}</span>
              </p>
            </div>
            <div className="diamodListItemContent">
              <div className="priceCaratContainer">
                <p className="sId">
                  <span className="title">Stock ID : {props.data?.vStnId}</span>
                </p>
                <p className="sShape">{props.data.shpNm}</p>
                <p className="sShape">{props.data.locNm}</p>
                <p className="sDis">
                  {" "}
                  {!props.data.isFcCol ? (
                    props.data?.back ? (
                      <span>
                        {formatNumber(parseFloat(props.data.back).toFixed(2))}%
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </p>
                <p className="priceCarat">
                  {props.data.ctPr ? (
                    <span>
                      {formatNumber(parseFloat(props.data.ctPr).toFixed(2))}{" "}
                      $/Cts
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="stoneInfo">
                <p>
                  <span className="sColor">
                    {props.data.isFcCol
                      ? props.data?.fcColNm
                          ?.split(" ")
                          ?.map((x) => x.charAt(0))
                          ?.join()
                          .replaceAll(",", "")
                      : props.data.colNm}
                  </span>
                  <span className="sFlu">{props.data?.clrNm}</span>
                  <span className="sc">{props.data?.cutNm ?? "-"}</span>
                  <span className="sc">{props.data?.polNm ?? "-"}</span>
                  <span className="sc">{props.data?.symNm ?? "-"}</span>
                  <span className="in">{props.data?.fluNm ?? "-"}</span>
                  <span className="lab">{props.data?.lbNm ?? "-"}</span>
                </p>
                <p className="sAmt">
                  {props.data.amt ? (
                    <span>
                      {formatNumber(parseFloat(props.data.amt).toFixed(2))}{" "}
                      $/Amt
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="smeasure">
                <p>
                  <span className="title">TD% : </span>
                  <span className="val">{props.data.depPer ?? "-"}</span>
                </p>
                <p>
                  <span className="title">TB% : </span>
                  <span className="val">{props.data.tblPer ?? "-"}</span>
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="caratStatus">
              <p>
                <span className="cVal">
                  {parseFloat(props.data?.crt).toFixed(2)}
                </span>
                <small className="cName">Carat</small>
              </p>
              <p
                className={`sStatus ${
                  STATUS_COLOR[props.data.wSts]
                    ? STATUS_COLOR[props.data.wSts]
                    : "green"
                }`}
              >
                <span className="lDot"></span>
                <span className="lName">{props.data?.wSts}</span>
              </p>
            </div>
            <div className="diamodListItemContent">
              <div className="priceCaratContainer">
                <p className="sId">
                  {/* <a href={"/" + LISTINGPAGES.DETAIL + "/" + props.data.id}> */}
                  {props.data?.vStnId}
                  {/* </a> */}
                </p>
                <p className="sShape">{props.data.shpNm}</p>
                <p className="sDis">
                  {" "}
                  {!props.data.isFcCol ? (
                    props.data?.back ? (
                      <span>
                        {formatNumber(parseFloat(props.data.back).toFixed(2))}%
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </p>
                <p className="priceCarat">
                  {props.data.ctPr ? (
                    <span>
                      {formatNumber(parseFloat(props.data.ctPr).toFixed(2))}{" "}
                      $/Cts
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="stoneInfo">
                <p>
                  <span className="sColor">
                    {props.data.isFcCol
                      ? props.data?.fcColNm
                          ?.split(" ")
                          ?.map((x) => x.charAt(0))
                          ?.join()
                          .replaceAll(",", "")
                      : props.data.colNm}
                  </span>
                  <span className="sFlu">{props.data?.clrNm}</span>
                  <span className="sc">{props.data?.cutNm ?? "-"}</span>
                  <span className="sc">{props.data?.polNm ?? "-"}</span>
                  <span className="sc">{props.data?.symNm ?? "-"}</span>
                  <span className="in">{props.data?.fluNm ?? "-"}</span>
                  <span className="lab">{props.data?.lbNm ?? "-"}</span>
                </p>
                <p className="sAmt">
                  {props.data.amt ? (
                    <span>
                      {formatNumber(parseFloat(props.data.amt).toFixed(2))}{" "}
                      $/Amt
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="smeasure">
                <p>
                  <span className="title">M : </span>
                  <span className="val">{props.data.msrmnt ?? "-"}</span>
                </p>
                <p>
                  <span className="title">R : </span>
                  <span className="val">{props.data.ratio ?? "-"}</span>
                </p>
                <p>
                  <span className="title">TD% : </span>
                  <span className="val">{props.data.depPer ?? "-"}</span>
                </p>
                <p>
                  <span className="title">TB% : </span>
                  <span className="val">{props.data.tblPer ?? "-"}</span>
                </p>
              </div>
            </div>
          </>
        )}
        {/* {props.upcoming && (
          <>
            <div className="mobileListHeade" onClick={props.onClick}>
              <div className="mobileListHeadeBlock">
                <div className="mobileListItem">
                  <span>{props.data.lbNm}</span>
                </div>
                <div className="mobileListItem">
                  <span>{props.data.vStnId}</span>
                </div>
              </div>
            </div>
            <div className="mobileDiamondListBottom" onClick={props.onClick}>
              <div className="mobileListHeadeBlock">
                <div className="mobileListItem basicDetail">
                  <span>
                    {getParams(props.data).join(" ")} {props.data.lbNm}
                  </span>
                </div>
                <div className="mobileListItem">
                  <span>
                    {formatNumber(parseFloat(props.data.crt).toFixed(2))}
                  </span>
                </div>
                <div className="mobileListItem">
                  <span>{props.data.msrmnt}</span>
                </div>
                <div className="mobileListItem totalPrice">
                  <span>{formatNumber(props.data.rap)} $</span>
                </div>
              </div>
            </div>
          </>
        )} */}
        {/* {!props.trending && !props.quotePage && !props.upcoming && (
          <>
            <div className="mobileListHeade" onClick={props.onClick}>
              <div className="mobileListHeadeBlock">
                <a
                  className="mobileListItem"
                  href={"/" + LISTINGPAGES.DETAIL + "/" + props.data.id}
                >
                  <span>{props.data.vStnId}</span>
                </a>
                <div className="mobileListItem">
                  <span>{props.data.shpNm}</span>
                </div>
                <div className="mobileListItem">
                  <span>
                    {formatNumber(parseFloat(props.data.back).toFixed(2))}%
                  </span>
                </div>
                <div className="mobileListItem themeColor">
                  <span>
                    {formatNumber(parseFloat(props.data.ctPr).toFixed(2))} $/Cts
                  </span>
                </div>
              </div>
            </div>
            <div className="mobileDiamondListBottom" onClick={props.onClick}>
              <div className="mobileListHeadeBlock">
                <div className="mobileListItem basicDetail">
                  <span>
                    {getParams(props.data).join(" ")} {props.data.lbNm}
                  </span>
                </div>
                <div className="mobileListItem">
                  <span>
                    {formatNumber(parseFloat(props.data.crt).toFixed(2))}
                  </span>
                </div>
                <div className="mobileListItem">
                  <span>{props.data.msrmnt}</span>
                </div>
                <div className="mobileListItem totalPrice">
                  <span>
                    {formatNumber(parseFloat(props.data.amt).toFixed(2))} $/Amt
                  </span>
                </div>
              </div>
            </div>
          </>
        )} */}
        {/* {props.quote && (
          <div className="d-flex j-space-between quoteExtraDetail">
            <div
              className="quoteExtraItem"
              onClick={() => props.quoteClick("quote", props.data)}
            >
              <span>Quote: </span>
              <span>{props.data.quote}</span>
            </div>
            <div
              className="quoteExtraItem"
              onClick={() => props.quoteClick("hours", props.data)}
            >
              <span>Hours: </span>
              <span>{props.data.hours}</span>
            </div>
            <div className="quoteExtraItem">
              <span>Final Quote: </span>
              <span>{parseFloat(props.data.finalquote).toFixed(2)}</span>
            </div>
          </div>
        )}
        {props.bid && (
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              <span>Type: </span>
              <span>
                {props.data.bidType === DIAMOND_BID.TYPE.OPEN
                  ? "Look"
                  : "Blind"}
              </span>
            </div>
            <div className="quoteExtraItem">
              <span>Status: </span>
              <span>
                {props.data.status === DIAMOND_BID.STATUS.WIN ? "Win" : "Loss"}
              </span>
            </div>
          </div>
        )}
        {props.finalCalc && (
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              <span>Final Disc%: </span>
              <span>{parseFloat(props.data.calcDiscount).toFixed(2)}</span>
            </div>
            <div className="quoteExtraItem">
              <span>Final Rate: </span>
              <span>
                ${parseFloat(props.data.calcPricePerCarat).toFixed(2)}
              </span>
            </div>
            <div className="quoteExtraItem">
              <span>Final Value: </span>
              <span>${parseFloat(props.data.calcAmount).toFixed(2)}</span>
            </div>
          </div>
        )}
        {props.bidPopup && (
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              {props.bidPopup.getBidPrCell(props.data)}
            </div>
            <div className="quoteExtraItem">
              <span>Bid Disc(%): </span>
              <span>{parseFloat(props.data.bidDiscount || 0).toFixed(2)}</span>
            </div>
            <div className="quoteExtraItem">
              <span>Bid Value: </span>
              <span>${parseFloat(props.data.bidAmount || 0).toFixed(2)}</span>
            </div>
          </div>
        )}
        {props.watchpopup && (
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              <span>Exp. Disc(%): </span>
              <span>{props.watchpopup(props.data)}</span>
            </div>
          </div>
        )}
        {props.note && (
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              <span>Notes: </span>
              <span>{props.data.remarks || "-"}</span>
            </div>
          </div>
        )}
        {props.quotePage && (
          <>
            <div className="mobileListHeade" onClick={props.onClick}>
              <div className="mobileListHeadeBlock">
                <div className="mobileListItem">
                  <Countdown date={moment(props.data.offerValidDate)} />
                </div>
                <div className="mobileListItem">
                  <span>
                    {(props.data.trackDiscount
                      ? parseFloat(props.data.trackDiscount).toFixed(2) + " / "
                      : "") +
                      parseFloat(props.data.trackPricePerCarat).toFixed(0)}
                  </span>
                </div>
                <div className="mobileListItem themeColor">
                  <span>
                    {(props.data.newDiscount
                      ? parseFloat(props.data.newDiscount).toFixed(2) + " / "
                      : "") +
                      parseFloat(props.data.newPricePerCarat).toFixed(0)}
                  </span>
                </div>
              </div>
            </div>
            <div className="mobileDiamondListBottom" onClick={props.onClick}>
              <div className="mobileListHeadeBlock">
                <div className="mobileListItem basicDetail">
                  <span>{getStoneInfo(props.data).join(" ")}</span>
                </div>
                <div className="mobileListItem activeStatus pending">
                  <span>{OFFERSTATUS[props.data.offerStatus]}</span>
                </div>
              </div>
            </div>
          </>
        )} */}
      </div>
    </div>
  );
};
export default MobileViewListCard;
