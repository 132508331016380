/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./diamondfullview.less";
import last from "lodash/last";
import split from "lodash/split";
import { VIEW_DIAMOND } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import { isEmpty } from "util/utils";
import { getProjectSetting } from "components/DiamondList/DiamondListFunctions";
import { PROJECT_SETTINGS_TYPE } from "constants/Common";
import Image from "components/common/Image";

const DiamondFullView = (props) => {
  const [lbNm, setLbNm] = useState(false);
  const toggle = () => setLbNm(!lbNm);
  const [mediaUrl, setMediaUrl] = useState(
    UtilService.getLocalStorageItem("mediaPathPattern") ?? {},
  );
  const [data, setData] = useState({});
  const [videoUrl, setVideoUrl] = useState("");

  const getDiamondDetail = React.useCallback((id) => {
    const objData = {
      method: VIEW_DIAMOND.method,
      url: `${VIEW_DIAMOND.url}/${id}`,
      showLoader: true,
    };

    UtilService.callApi(objData, async function (err, res) {
      if (err) throw err;
      if (res && res.code === "OK") {
        setData(Array.isArray(res.data) ? res.data?.[0] : res.data);
      }
    });
  }, []);

  useEffect(() => {
    const id = split(window.location.pathname, "/")?.pop();
    id !== "diamondfullview" && getDiamondDetail(id);
  }, [getDiamondDetail]);

  useEffect(() => {
    if (!isEmpty(mediaUrl)) return;

    getProjectSetting(PROJECT_SETTINGS_TYPE.MEDIA_PATH, (data) => {
      setMediaUrl(data?.MEDIA_PATH_PATTERN ?? {});
      UtilService.setLocalStorageItem(
        data?.MEDIA_PATH_PATTERN,
        "mediaPathPattern",
      );
    });
  }, [mediaUrl]);

  const getMediaUrl = React.useCallback(
    (type) => {
      const url = mediaUrl?.[type]?.FILE_PATH;
      return url?.PATH?.replaceAll("***", data?.[url?.KEY]);
    },
    [data, mediaUrl],
  );

  const setImageUrl = React.useCallback(() => {
    setVideoUrl(
      data.videoFile
        ? getMediaUrl("videoFile")
        : data.crt < 1.0 && !data.videoFile && data?.b2cJsonVideo
        ? getMediaUrl("b2cJsonVideo")
        : "",
    );
  }, [data.b2cJsonVideo, data.crt, data.videoFile, getMediaUrl]);

  useEffect(() => {
    !isEmpty(data) && setImageUrl();
  }, [data, setImageUrl]);

  const handleDNA = () => window.open(`/dna/${data.vStnId}`);
  return (
    <div className="viewdiamond viewdiamond-mobile">
      {videoUrl ? (
        <iframe
          src={videoUrl}
          title="video"
          width="100%"
          height="100%"
          id="videoimage"
          name="videoimage"
          class="videoimage"
          frameborder="0"
        ></iframe>
      ) : (
        <div className="imageSliderFullBlock no-msg"></div>
      )}
      <div className="dnaDetailBlock">
        <table className="table">
          <thead>
            <tr>
              <th className="dnaImageTop" colSpan="13">
                <span>Report No : {data.rptNo}</span>
              </th>
            </tr>
            <tr>
              <th>Lab</th>
              <th>Shape</th>
              <th>Carat</th>
              <th>Clarity</th>
              <th>Color</th>
              <th>Cut</th>
              <th>Pol</th>
              <th>Symm</th>
              <th>Fls</th>
              <th>Depth%</th>
              <th>Table%</th>
              <th>LWD</th>
              <th>DNA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="viewCerti" onClick={toggle}>
                  {data.lbNm}
                </span>
              </td>
              <td>{data.shpNm}</td>
              <td>{data.crt}</td>
              <td>{data.clrNm}</td>
              <td>{data.colNm}</td>
              <td>{data.cutNm}</td>
              <td>{data.polNm}</td>
              <td>{data.symNm}</td>
              <td>{data.fluNm}</td>
              <td>{data.depPer}</td>
              <td>{data.tblPer}</td>
              <td>{data.msrmnt}</td>
              <td>
                <span className="viewCerti" onClick={handleDNA}>
                  DNA
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {data.certFile ? (
        <div
          className="certificate mt-30"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <iframe
            src={getMediaUrl("certFile")}
            alt=""
            style={{ width: "80%" }}
          />
        </div>
      ) : null}
    </div>
  );
};
export default DiamondFullView;
