/* eslint-disable */
import React, { Component } from "react";
import "./thankyou.less";

class ThankYou extends Component {
  render() {
    return (
      <div className="thankYouWrapper">
        <div className="thankYouInnerBox">
          <div className="thankYouMainBox">
            <div className="thankYouImgBlock">
              <img src={require("../../assets/svg/ThankYou/1.svg")} alt="" />
            </div>
            <div className="thankYouPageContent">
              <h2>Thank you for Registration!</h2>
              <p>
                Your Belgium account is under review. Our support team will
                process your application soon and get in touch with you if
                needed.
              </p>
              <a href="/signin">
                <button className="commonButton">Login</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ThankYou;
