/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Heading from "../../common/Heading";
import InputBlock from "../../common/InputBlock";
import SelectOption from "../../common/SelectOption";
import OpenNotification from "../../common/CommonButton/OpenNotification";
import UtilService from "../../../services/util";
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { notification, Tag } from "antd";
import {
  BASE_URL,
  MIME_TYPES,
  LOCAL_STORAGE_VAR,
} from "../../../constants/Common";
import ProfileUpload from "../../common/ProfileUpload";
import IntlMessages from "../../../util/IntlMessages";
import {
  GET_COUNTRY_LIST,
  GET_STATE_LIST,
  GET_CITY_LIST,
  FETCH_USER,
  ADMIN_UPDATE_USER,
  UPLOAD_FILE,
} from "../../../constants/apiConstant";
import DefaultUser from "../../common/UserProfileDefault";
import UploadImage from "../../common/UploadImage/index";
import find from "lodash/find";
import join from "lodash/join";
import split from "lodash/split";

/* eslint jsx-a11y/anchor-is-valid: 0 */

class PersonalInformation extends Component {
  state = {
    countries: [],
    states: [],
    cities: [],
    errors: [],
    data: {},
    doc: undefined,
    userKyc: [],
  };

  personalDocs =
    JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`))
      ?.DOC_TYPE_PERSONAL || [];

  componentDidMount() {
    this.fetchUser();
    this.getCountries();
    this.personalDocs =
      JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`))
        ?.DOC_TYPE_PERSONAL || [];
  }

  getCountries = () => {
    let self = this;
    let obj = {
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === "OK") {
        self.setState({ countries: data.data });
      }
    });
  };

  getStates = (countryId) => {
    let self = this;
    let obj = {
      ...GET_STATE_LIST,
      request: { country: countryId },
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === "OK") {
        self.setState({ states: data.data });
      }
    });
  };

  getCities = (stateId) => {
    let self = this;
    let obj = {
      ...GET_CITY_LIST,
      request: { state: stateId },
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === "OK") {
        self.setState({
          cities: [...data.data, { name: "Others", id: "-" }] || [],
        });
      }
    });
  };

  fetchUser() {
    let self = this;
    let obj = {
      ...FETCH_USER,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        // !isEmpty(data.data.kyc) &&
        //   data.data.kyc.map((k) => {
        //     if (k.type === "personal") self.setState({ doc: [k.docType] });
        //   });

        self.setState({
          data: data.data,
          userKyc: data?.data?.account?.kyc?.filter(
            ({ type }) => type === "personal",
          ),
          businessKyc: data?.data?.account?.kyc?.filter(
            ({ type }) => type === "business",
          ),
        });
        self.getStates(data.data.country);
        self.getCities(data.data.state);
      }
    });
  }

  updateUser() {
    let self = this;
    let reqObj = clone(self.state.data);

    reqObj.name = `${reqObj.firstName} ${reqObj.lastName}`;
    //reqObj.kyc = [{ ...reqObj.kyc, path: reqObj.photoId }];
    reqObj.userKyc = self.state.userKyc;
    reqObj.kyc = [
      ...(self.state.businessKyc ?? []),
      ...(self.state.userKyc ?? []),
    ];

    reqObj.address = join(
      [reqObj.address, reqObj.address2, reqObj.address3],
      ",",
    );

    if (!isEmpty(reqObj.userKyc)) reqObj.isKycUploaded = true;

    reqObj.city === "-" && delete reqObj["city"];
    let obj = {
      ...ADMIN_UPDATE_USER,
      request: reqObj,
    };

    if (self.handleValidation()) {
      self.setState({ loading: true });
      UtilService.callApi(obj, function (err, data) {
        self.setState({ loading: false });
        if (err && err.data) {
          if (err.data.code === "E_USER_NOT_FOUND") {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
          if (err.data.code === "E_DUPLICATE") {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
        }
        if (data && data.code === "OK") {
          //self.props.onClose();
          let storageObj = reqObj;
          storageObj.account = UtilService.getLocalStorageItem(
            `${LOCAL_STORAGE_VAR}-user`,
          ).account;
          UtilService.setLocalStorageItem(
            storageObj,
            `${LOCAL_STORAGE_VAR}-user`,
          );

          self.props.onClose();
          return OpenNotification({
            type: "success",
            title: "Personal details are updated successfully.",
          });
        }
      });
    }
  }

  uploadDocument = (ee, type) => {
    const e = cloneDeep(ee);
    let self = this;
    let errors = clone(this.state.errors);
    let formData = clone(this.state.data);

    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = function (ev) {
      var arr = new Uint8Array(ev.target.result).subarray(0, 4);
      var header = "";
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (
        !MIME_TYPES[blob.type] ||
        (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))
      ) {
        return OpenNotification({
          type: "error",
          title:
            "File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.",
        });
      } else {
        const data = new FormData();
        data.append("folder", "user");
        data.append("file", e.target.files[0]);

        let objData = {
          ...UPLOAD_FILE,
          request: data,
        };
        UtilService.callApi(objData, function (err, data) {
          if (err) throw err;
          if (data && data.code === "OK") {
            errors[type] = undefined;
            formData[type] = data.data.files[0].absolutePath;
            self.setState({ data: formData, errors });
          }
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };

  handleInputChange = (key, value) => {
    let { data } = this.state;
    data[key] = value;
    this.setState({ data: data });
  };

  removeDocument = (removeIndex) => {
    let { userKyc } = this.state;

    this.setState({
      userKyc: userKyc?.filter((data, index) => index !== removeIndex),
    });
  };

  handleValidation = () => {
    let formData = this.state.data;
    let errors = this.state.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFields = [];
    if (!formData["firstName"]) {
      blankField.push("First Name");
      formIsValid = false;
    }
    if (formData["firstName"]) {
      if (!formData["firstName"].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push("firstName");
        formIsValid = false;
        errors["firstName"] = "Please enter valid First Name.";
      } else {
        errors["firstName"] = undefined;
      }
    }

    // if (!formData["middleName"]) {
    //   blankField.push("Middle Name");
    //   formIsValid = false;
    // }
    if (formData["middleName"]) {
      if (!formData["middleName"].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push("middleName");
        formIsValid = false;
        errors["middleName"] = "Please enter valid First Name.";
      } else {
        errors["middleName"] = undefined;
      }
    }

    if (!formData["lastName"]) {
      blankField.push("Last Name");
      formIsValid = false;
    }
    if (formData["lastName"]) {
      if (!formData["lastName"].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push("lastName");
        formIsValid = false;
        errors["lastName"] = "Please enter valid First Name.";
      } else {
        errors["lastName"] = undefined;
      }
    }

    // lastName
    if (!formData["address"]) {
      blankField.push("Address 1");
      formIsValid = false;
    } else {
      errors["address"] = undefined;
    }
    if (!formData["address2"]) {
      blankField.push("Address 2");
      formIsValid = false;
    } else {
      errors["address2"] = undefined;
    }

    if (!formData["country"] || isEmpty(formData["country"])) {
      blankField.push("Country");
      formIsValid = false;
      errors["country"] = true;
    } else {
      errors["country"] = undefined;
    }

    if (!formData["state"] || isEmpty(formData["state"])) {
      blankField.push("State");
      formIsValid = false;
      errors["state"] = true;
    } else {
      errors["state"] = undefined;
    }

    if (!formData["city"] || isEmpty(formData["city"])) {
      blankField.push("City");
      formIsValid = false;
      errors["city"] = true;
    } else {
      errors["city"] = undefined;
    }

    if (formData.city === "-") {
      if (!formData["cityText"] || isEmpty(formData["cityText"])) {
        blankField.push("City");
        formIsValid = false;
        errors["cityText"] = true;
      } else if (
        formData["cityText"] &&
        !formData["cityText"].match(/^[a-zA-Z ]+$/)
      ) {
        invalidFields.push("City");
        errors["cityText"] = true;
        formIsValid = false;
      } else {
        errors["cityText"] = undefined;
      }
    }
    if (!formData["email"]) {
      blankField.push("Email Address");
      formIsValid = false;
    }
    if (formData["email"]) {
      if (
        formData["email"] &&
        !formData["email"].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        invalidFields.push("Email");
        formIsValid = false;
        errors["email"] = "Please enter valid Email.";
      } else {
        errors["email"] = undefined;
      }
    }
    if (!formData["mobile"]) {
      blankField.push("Mobile No.");
      formIsValid = false;
    }
    if (formData["mobile"] && errors["mobile"]) {
      invalidFields.push("Mobile No.");
      formIsValid = false;
    }

    if (!formData["pinCode"] || isEmpty(formData["pinCode"])) {
      blankField.push("Pincode");
      formIsValid = false;
      errors["pinCode"] = true;
    } else if (
      formData["pinCode"] &&
      !formData["pinCode"].match(/^[0-9]{4,6}$/)
      // !formData["pinCode"].match(/^(\d{3}|\d{{7})$/)
    ) {
      invalidFields.push("Pincode");
      formIsValid = false;
      errors["pinCode"] = true;
    }

    if (blankField.length > 0) {
      let arr = blankField;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    if (invalidFields.length > 0) {
      let arr = invalidFields;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleDocument = (value) => {
    const docExist = this.state?.userKyc?.find(
      ({ docType }) => docType === value,
    );
    if (docExist)
      return OpenNotification({
        type: "error",
        title: "Document already exist",
      });

    this.setState({ doc: value });
  };

  addDocument = () => {
    const { doc, data } = this.state;
    if (isEmpty(data?.photoId) || isEmpty(doc))
      return OpenNotification({
        type: "error",
        title: "Please select Document Type and Image",
      });
    this.setState(
      {
        userKyc: [
          ...this.state.userKyc,
          {
            docType: doc,
            path: data?.photoId,
            type: "personal",
          },
        ],
      },
      () =>
        this.setState({
          doc: undefined,
          data: { ...this.state.data, photoId: "" },
        }),
    );
  };

  render() {
    let { countries, data, states, cities } = this.state;

    return (
      <div>
        <div
          className={`profileHead ${
            this.state.loading ? "loading-indicator" : ""
          }`}
        >
          <Heading
            title={<IntlMessages id="app.PersonalInformation" />}
            className="popupInnerTitle"
          ></Heading>
          <div className="personalProfileWrapper">
            <div className="editProfileLogo">
              {/* <img
                alt=""
                src={
                  isEmpty(data.profileImage)
                    ? require("../../../assets/img/user-default.png")
                    : BASE_URL + "/" + data.profileImage
                }
              /> */}
              {data.profileImage ? (
                <img src={`${BASE_URL}/${data.profileImage}`} alt="" />
              ) : (
                <DefaultUser size="lg-size" />
              )}
            </div>
            <div className="profileAction">
              <ProfileUpload
                label={<IntlMessages id="app.EditProfile" />}
                accept="jpg, png,jpeg,PNG,JPEG,JPG"
                onChange={(e) => this.uploadDocument(e, "profileImage")}
              />
              <a onClick={() => this.handleInputChange("profileImage", "")}>
                <IntlMessages id="app.RemoveProfile" />
              </a>
            </div>
          </div>
        </div>
        <div className="profileDetailSpace">
          <IntlMessages id="app.FirstNameLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("firstName", e.target.value)
                }
                placeholder={placeholder}
                value={data.firstName ? data.firstName : data.name}
                label={
                  <>
                    <IntlMessages id="app.FirstNameLabel" />
                  </>
                }
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.MiddleNameLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("middleName", e.target.value)
                }
                placeholder={placeholder}
                value={data.middleName}
                label={
                  <>
                    <IntlMessages id="app.MiddleNameLabel" />
                  </>
                }
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.LastNameLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("lastName", e.target.value)
                }
                placeholder={placeholder}
                value={data.lastName}
                label={<IntlMessages id="app.LastNameLabel" />}
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.Address1Label">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("address", e.target.value)
                }
                placeholder={placeholder}
                value={data.address}
                label={<IntlMessages id="app.Address1Label" />}
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.Address2Label">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("address2", e.target.value)
                }
                placeholder={placeholder}
                value={data.address2}
                label={<IntlMessages id="app.Address2Label" />}
              />
            )}
          </IntlMessages>
          {/* <IntlMessages id="app.Address3Label">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("address3", e.target.value)
                }
                value={
                  data.address &&
                  split(data.address, ",") &&
                  split(data.address, ",")[2]
                }
                placeholder={placeholder}
                label={<IntlMessages id="app.Address3Label" />}
              />
            )}
          </IntlMessages> */}
          <IntlMessages id="app.EmailLabel">
            {(placeholder) => (
              <InputBlock
                disabled
                onChange={(e) =>
                  this.handleInputChange("email", e.target.value)
                }
                placeholder={placeholder}
                value={data.email}
                label={
                  <>
                    <IntlMessages id="app.EmailLabel" />
                  </>
                }
              />
            )}
          </IntlMessages>
          <SelectOption
            showSearch={true}
            value={data.country}
            selectValue={countries}
            onChange={(value) => {
              let data = clone(this.state.data);
              data["state"] = undefined;
              data["city"] = undefined;

              data["country"] = value;
              this.getStates(value);
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessages id="app.CountryLabel" />
              </>
            }
          />
          <SelectOption
            showSearch={true}
            value={data.state}
            selectValue={states}
            onChange={(value) => {
              let data = clone(this.state.data);
              data["city"] = undefined;

              data["state"] = value;
              this.getCities(value);
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessages id="app.StateLabel" />
              </>
            }
          />
          <SelectOption
            showSearch={true}
            value={data.city}
            selectValue={cities}
            onChange={(value) => {
              let data = clone(this.state.data);
              data["city"] = value;
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessages id="app.CityLabel" />
              </>
            }
          />
          {data && data.city === "-" && (
            <IntlMessages id="app.City">
              {(placeholder) => (
                <InputBlock
                  onChange={(e) =>
                    this.handleInputChange("cityText", e.target.value)
                  }
                  hasError={this.state.errors["cityText"] ? true : false}
                  type="text"
                  value={data.cityText}
                  label={
                    <>
                      <IntlMessages id="app.CityLabel" />
                    </>
                  }
                  placeholder={placeholder}
                />
              )}
            </IntlMessages>
          )}
          <IntlMessages id="app.pinCodeLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("pinCode", e.target.value)
                }
                type="number"
                placeholder={placeholder}
                value={data.pinCode}
                label={
                  <>
                    <IntlMessages id="app.pinCodeLabel" />
                  </>
                }
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.WhatsAppLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("whatsapp", e.target.value)
                }
                type="number"
                placeholder={placeholder}
                value={data.whatsapp}
                label={<IntlMessages id="app.WhatsAppLabel" />}
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.MobileNoLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("mobile", e.target.value)
                }
                type="number"
                placeholder={placeholder}
                value={data.mobile}
                label={
                  <>
                    <IntlMessages id="app.MobileNoLabel" />
                  </>
                }
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.SkypeLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("skype", e.target.value)
                }
                placeholder={placeholder}
                value={data.skype}
                label={<IntlMessages id="app.SkypeLabel" />}
              />
            )}
          </IntlMessages>
          <InputBlock
            onChange={(e) => this.handleInputChange("weChat", e.target.value)}
            placeholder={"Wechat"}
            value={data.weChat}
            label={"Wechat"}
          />
          <div className="inputBlock">
            <label>KYC Documents</label>
            <div className="docTag">
              {!isEmpty(this.state.userKyc) ? (
                this.state.userKyc.map((u, index) => (
                  <Tag
                    key={Math.random()}
                    onClose={() => this.removeDocument(index)}
                    closable
                  >
                    {
                      this.personalDocs?.find(({ id }) => id?.[0] === u.docType)
                        ?.name
                    }
                  </Tag>
                ))
              ) : (
                <p>Documents Not Exist</p>
              )}
            </div>
          </div>
          <SelectOption
            value={this.state?.doc ?? undefined}
            placeholder={"Select Personal Document"}
            selectValue={this.personalDocs?.map((item) => ({
              ...item,
              id: item?.id?.[0],
            }))}
            onChange={this.handleDocument}
            label="Document Type*"
          />
          <div className="width-50" style={{ display: "-webkit-box" }}>
            <UploadImage
              file={data.photoId}
              disabled={isEmpty(this.state.doc)}
              leftAlign
              noRightBorder
              onUpload={(e) => this.uploadDocument(e, "photoId")}
            />{" "}
            <a className="commonButton" onClick={this.addDocument}>
              Add Document
            </a>
          </div>
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={() => this.updateUser()}>
            <IntlMessages id="app.my-account.SavePersonalDetail" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              this.props.onClose();
              this.fetchUser();
            }}
          >
            <IntlMessages id="app.Cancel" />
          </a>
        </div>
      </div>
    );
  }
}
export default withRouter(PersonalInformation);
