/* eslint-disable */
import React, { Component } from "react";
import { isEmpty } from "lodash";
import IntlMessages from "../../../util/IntlMessages";
import { trackTypeObj, PROJECT_SETTINGS_TYPE } from "../../../constants/Common";
import { getProjectSetting } from "../../DiamondList/DiamondListFunctions";
import TrackStatusModal from "../FooterSticky/TrackList/TrackStatusModal";
import { GuestUserPopup } from "../../GuestUserPopup";
import { LOCAL_STORAGE_VAR } from "../../../constants/Common";
import { withRouter } from "react-router-dom";
import { GET_DASHBOARD_SUMMARY } from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
class QuickMenu extends Component {
  state = {
    listview: true,
    checked: [],
    data: [],
    sort: [],
    page: 1,
    limit: 100,
    sum: {},
    columns: [],
    count: 0,
    filterArray: {},
    selectedFilterArray: {},
    loading: false,
    searchId: null,
    inTrackDiamonds: [],
    trackPopup: false,
    trackid: null,
    isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`),
    showGuest: false,
    dashData: undefined,
    isLoading: false,
  };

  componentDidMount() {
    this.getDashboardData();

    this.getDataIntervalId = setInterval(() => {
      this.getDashboardData();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.getDataIntervalId);
  }

  handleOk = () => {
    if (this.state.trackid && this.state.trackid.match(/^[0-9]*$/)) {
      this.setState({
        shipment: false,
      });
      window.open(`/track-diamond?${this.state.trackid}`, "_self");
    } else {
      this.setState({
        trackid: null,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      trackid: e,
    });
  };

  getDashboardData = () => {
    getProjectSetting(PROJECT_SETTINGS_TYPE.MATCH_PAIR_COUNT, (res) => {
      this.setState({ count: res.count });
    });

    let obj = {
      ...GET_DASHBOARD_SUMMARY,
      request: {},
    };

    UtilService.callApi(obj, (err, data) => {
      if (err) {
        this.setState({ dashData: undefined, isLoading: false });
      } else if (data && data.code === "OK") {
        if (data.data) {
          if (data.data.quoteDay) {
            localStorage.setItem(
              `${LOCAL_STORAGE_VAR}-quoteDay`,
              JSON.stringify(data.data.quoteDay),
            );
          }
          this.setState({ dashData: data && data.data, isLoading: false });
        }
      } else {
        localStorage.remove(`${LOCAL_STORAGE_VAR}-quoteDay`);
        this.setState({ dashData: undefined, isLoading: false });
      }
    });
  };

  renderQuickItem = (e, i, matchPair) => {
    return e.visible === true && e.track === false ? (
      e.name === "matchPair" ? (
        <div
          onClick={() => {
            if (!this.state.isGuest) {
              // window.open(e.link, "_self");
              this.props.history.push(e.link);
            } else {
              this.setState({ showGuest: true });
            }
          }}
          key={i}
          className="quickMenuItem"
        >
          <div className="count">
            <span>{matchPair || 0}</span>
          </div>
          <div className="quickMenuTitle">{e.title}</div>
        </div>
      ) : (
        <div
          onClick={() => {
            if (!this.state.isGuest || e.name === "Search Total") {
              // window.open(e.link, "_self");
              this.props.history.push(e.link);
            } else {
              this.setState({ showGuest: true });
            }
          }}
          key={i}
          className="quickMenuItem"
        >
          <div className="count">
            <span>
              {/* {e.icon === true ? (
                    <div
                      onClick={
                        () => {
                          window.open(`/track-diamond`, "_self");
                        }
                        //this.setState({ trackPopup: true })
                      }
                    >
                      0
                    </div>
                    ) : */}
              {e.count || 0}
            </span>
          </div>
          <div className="quickMenuTitle">{e.title}</div>
        </div>
      )
    ) : e.visible === true && e.track === true ? (
      <div
        onClick={() => {
          if (!this.state.isGuest) {
            // window.open(e.link, "_self");
            this.props.history.push(e.link);
          } else {
            this.setState({ showGuest: true });
          }
        }}
        key={i}
        className="quickMenuItem"
      >
        <div className="count">
          <span>{e.count || 0}</span>
        </div>
        <div className="quickMenuTitle">{e.title}</div>
      </div>
    ) : (
      <div key={i} className="quickMenuItem blackQuickMenu" />
    );
  };

  render() {
    let { dashData } = this.state;

    let USER_PERMIT = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-userPermissions`,
    );
    let matchPair = this.state.count && this.state.count;

    var DashBoard = [
      {
        title: <IntlMessages id="app.StockSearch" />,
        name: "Search Total",
        count:
          dashData?.dashboardCount?.find((a) => a.name === "search_total")
            ?.count ?? 0,
        link: "/diamond-search",
        visible: true,
        track: false,
      },
      {
        title: <IntlMessages id="app.newArrival" />,
        name: "New Arrival",
        count:
          dashData?.dashboardCount?.find((a) => a.name === "new_arrival")
            ?.count ?? 0,
        link: "/new-arrival-bid-it",
        visible: true,
        track: false,
      },
      {
        title: <IntlMessages id="app.Upcoming" />,
        name: "Upcoming",
        count:
          dashData?.dashboardCount?.find((a) => a.name === "upcoming")?.count ??
          0,
        link: "/upcoming",
        visible: true,
        track: false,
      },
      {
        title: <IntlMessages id="app.BSN" />,
        name: "bsn",
        count:
          dashData?.dashboardCount?.find((a) => a?.name === "bsn")?.count ?? 0,
        link: `/diamond-list?${
          dashData?.dashboardCount?.find((a) => a?.name === "bsn")?.id
        }`,
        visible: true,
        track: false,
      },
      {
        title: <IntlMessages id="app.FinestarExclusive" />,
        name: "finestar_exclusive",
        count:
          dashData?.dashboardCount?.find((a) => a.name === "finestar_exclusive")
            ?.count ?? 0,
        link: `/diamond-list?${
          dashData?.dashboardCount?.find((a) => a.name === "finestar_exclusive")
            ?.id
        }`,
        visible: true,
        track: false,
      },

      {
        title: <IntlMessages id="app.COOInventory" />,
        name: "coo",
        count:
          dashData?.dashboardCount?.find((a) => a.name === "coo")?.count ?? 0,
        link: `/diamond-list?${
          dashData?.dashboardCount?.find((a) => a.name === "coo")?.id
        }`,
        visible: true,
        track: false,
      },
      // {
      //   title: <IntlMessages id="app.MatchingPair" />,
      //   name: "matchPair",
      //   count: matchPair,
      //   link: "/match-pair",
      //   visible: true,
      //   track: false,
      // },
      {
        title: <IntlMessages id="app.CanadamarkEligible" />,
        name: 'canadamark_inventory',
        count: dashData?.dashboardCount?.find((a) => a.name === 'canadamark_inventory')?.count ?? 0,
        link: `/diamond-list?${dashData?.dashboardCount?.find((a) => a.name === 'canadamark_inventory')?.id}`,
        visible: true,
        track: false,
      },
      {
        title: <IntlMessages id="app.Svs" />,
        name: "svs",
        count:
          dashData?.dashboardCount?.find((a) => a?.name === "svs")?.count ?? 0,
        link: `/diamond-list?${
          dashData?.dashboardCount?.find((a) => a?.name === "svs")?.id
        }`,
        visible: true,
        track: false,
      },
      {
        title: <IntlMessages id="app.Diavik" />,
        name: "dvk",
        count:
          dashData?.dashboardCount?.find((a) => a?.name === "dvk")?.count ?? 0,
        link: `/diamond-list?${
          dashData?.dashboardCount?.find((a) => a?.name === "dvk")?.id
        }`,
        visible: true,
        track: false,
      },
      {
        title: <IntlMessages id="app.Tracr" />,
        name: "tracer",
        count:
          dashData?.dashboardCount?.find((a) => a?.name === "tracer")?.count ??
          0,
        link: `/diamond-list?${
          dashData?.dashboardCount?.find((a) => a?.name === "tracer")?.id
        }`,
        visible: true,
        track: false,
      },
      {
        title: <IntlMessages id="app.CartList" />,
        trackName: trackTypeObj.CART,
        count: dashData && dashData.tracks[trackTypeObj.CART].pieces,
        link: "/account/cart",
        visible: true,
        track: true,
      },
      {
        title: <IntlMessages id="app.WatchList" />,
        trackName: trackTypeObj.WATCHLIST,
        count: dashData && dashData.tracks[trackTypeObj.WATCHLIST].pieces,
        link: "/account/watchlist",
        visible: true,
        track: true,
      },
      // {
      //   title: "Coming Soon",
      //   name: "coming_soon",
      //   count:
      //     dashData?.dashboardCount?.find((a) => a.name === "coming_soon")
      //       ?.count ?? 0,
      //   link: "/coming-soon",
      //   visible:
      //     USER_PERMIT && USER_PERMIT.COMING_SOON
      //       ? USER_PERMIT.COMING_SOON.view
      //         ? true
      //         : false
      //       : false,
      //   track: false,
      // },
      // {
      //   title: <IntlMessages id="app.NonCertifiedStones" />,
      //   name: "non_cert",
      //   count: dashData?.dashboardCount?.find((a) => a.name === "non_cert")?.count ?? 0,
      //   link: `/diamond-list?${dashData?.dashboardCount?.find((a) => a.name === "non_cert")?.id}`,
      //   visible: true,
      //   track: false,
      // },
      // {
      //   title: <IntlMessages id="app.ForevermarkEligible" />,
      //   name: "foreevermark_eligible",
      //   count: dashData?.dashboardCount?.find((a) => a.name === "foreevermark_eligible")?.count ?? 0,
      //   link:
      //     dashData?.dashboardCount?.find((a) => a.name === "foreevermark_eligible") === undefined
      //       ? "/dashboard"
      //       : `/diamond-list?${dashData?.dashboardCount?.find((a) => a.name === "foreevermark_eligible")?.id}`,
      //   visible: dashData?.dashboardCount?.find((a) => a.name === "foreevermark_eligible") === undefined ? false : true,
      //   track: false,
      // },
      // {
      //   title: <IntlMessages id="app.TrackYourShipment" />,
      //   trackName: trackTypeObj.SHIPMENT,
      //   link: "/track-diamond",
      //   count: dashData?.shippingCount ?? 0,
      //   visible: true,
      //   track: true,
      // },
      // {
      //   title: <IntlMessages id="app.ForevermarkInventory" />,
      //   name: "foreevermark_inventory",
      //   count: dashData?.dashboardCount?.find((a) => a.name === "foreevermark_inventory")?.count ?? 0,
      //   visible:
      //     dashData?.dashboardCount?.find((a) => a.name === "foreevermark_inventory") === undefined ? false : true,
      //   link:
      //     dashData?.dashboardCount?.find((a) => a.name === "foreevermark_inventory") === undefined
      //       ? "/dashboard"
      //       : `/fm-diamond-list?${dashData?.dashboardCount?.find((a) => a.name === "foreevermark_inventory")?.id}`,
      //   track: false,
      // },
    ];

    DashBoard = DashBoard.filter((item) => !isEmpty(item));
    const halfCount = Math.ceil((DashBoard?.length || 0) / 2);

    return (
      <>
        <div
          className={`quickMenuBox ${
            this.props.isLoading ? "loading-indicator" : ""
          }`}
        >
          <span className="quickMenuLogo"></span>
          <div className="homeContainer">
            <div className="quickMenuBlock">
              {DashBoard.slice(0, halfCount).map((e, i) =>
                this.renderQuickItem(e, i, matchPair),
              )}
            </div>
            <div className="quickMenuBlock">
              {DashBoard.slice(halfCount, DashBoard?.length).map((e, i) =>
                this.renderQuickItem(e, i, matchPair),
              )}
            </div>
          </div>
        </div>
        <TrackStatusModal
          trackVisible={this.state.trackPopup}
          handleCancel={() => this.setState({ trackPopup: false })}
          handleChange={this.handleChange}
          handleOk={this.handleOk}
        />
        {this.state.showGuest && (
          <GuestUserPopup
            visible={this.state.showGuest}
            register={() => {
              window.open("/signup", "_self");
              window.localStorage.clear();
              window.sessionStorage.clear();
            }}
            handleCancel={() => {
              this.setState({ showGuest: false });
            }}
          />
        )}
      </>
    );
  }
}

export default withRouter(QuickMenu);
