/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IntlMessages from "../../util/IntlMessages";
import { Form, notification, Radio, Drawer, DatePicker } from "antd";
import { v4 as uuid } from "uuid";
import clone from "lodash/clone";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import {
  LOCAL_STORAGE_VAR,
  REGISTRATION_TYPES_DATA,
} from "../../constants/Common";
import UtilService from "../../services/util";
import Loader from "../../components/common/Loader";
import OpenNotification from "../common/CommonButton/OpenNotification";
import "react-intl-tel-input/dist/main.css";
import InputBlock from "../common/InputBlock";
import SelectOption from "../common/SelectOption";
import Heading from "../common/Heading";
import TermsCondition from "../TermsConditionPopup";
import {
  GET_COUNTRY_LIST,
  FETCH_SELLERS,
  GET_STATE_LIST,
  GET_CITY_LIST,
  SIGNUP_USER_FRONT,
  GET_MASTERS,
  DUPLICATE_EMAIL,
} from "../../constants/apiConstant";
import CheckBox from "../common/CheckBox";
import Logo from "../Header/Logo";
import DownloadBlock from "../common/DownloadBlock";
import orderBy from "lodash/orderBy";
import { upperCase, isBoolean } from "lodash";
import { handleMobileNumber } from "../../services/Commonfunction";
import { Popconfirm } from "antd";
import deleteSvg from "assets/svg/delet.svg";
import editSvg from "assets/svg/edit.svg";

export const companyDetail = {
  Country: [
    { value: "Afghanistan " },
    { value: "Albania " },
    { value: "Algeria " },
    { value: "American Samoa" },
    { value: "Andorra" },
    { value: "Angola" },
    { value: "India" },
  ],
  State: [
    { value: "Maharashtra " },
    { value: "Andhra Pradesh" },
    { value: "Arunachal Pradesh" },
    { value: "Assam " },
    { value: "Bihar" },
    { value: "Dadra and Nagar Haveli " },
    { value: "Daman and Diu" },
  ],
  City: [
    { value: "Achalpur" },
    { value: "Ahmadnagar" },
    { value: "Akola" },
    { value: "Amravati" },
    { value: "AURANGABAD" },
    { value: "Miraj" },
    { value: "MUMBAI" },
  ],
  companyType: [
    { id: "Partnership", name: "Partnership" },
    { id: "Individual", name: "Individual" },
    { id: "Private Limited / Limited", name: "Private Limited / Limited" },
    { id: "Proprietor", name: "Proprietor" },
  ],
  seller: [
    { id: "Abhishek Karnavat", name: "Abhishek Karnavat" },
    { id: "Elza Vaz", name: "Elza Vaz" },
  ],
  CompanyBest: [
    { id: "Wholesaler", name: "Wholesaler" },
    { id: "Retailer", name: "Retailer" },
    { id: "Jewellery Manufacturer", name: "Jewellery Manufacturer" },
    { id: "Polished Distributor", name: "Polished Distributor" },
    { id: "End User", name: "End User" },
    { id: "Other", name: "Other" },
    { id: "FM Retailers", name: "FM Retailers" },
    { id: "FM Diamantaire", name: "FM Diamantaire" },
  ],
};

export function PrintTable({ data, onDelete, onUpdate }) {
  return (
    <table width="100%">
      <thead>
        <tr>
          <th>Company Name</th>
          <th>Address</th>
          <th>Country</th>
          <th>State</th>
          <th>City</th>
          <th>Mobile No.</th>
          <th>Email</th>
          <th>Contact Name</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item) => {
          return (
            <tr key={item.tradeRefID}>
              <td>{item?.tradeRefCompanyName}</td>
              <td>{item?.tradeRefAddress}</td>
              <td>{item?.tradeRefCountry?.name ?? "-"}</td>
              <td>{item?.tradeRefState?.name ?? "-"}</td>
              <td>
                {item?.tradeRefCity?.name ?? item.tradeRefCityText ?? "-"}
              </td>
              <td>{item?.tradeRefMobile ?? "-"}</td>
              <td>{item?.tradeRefEmail ?? "-"}</td>
              <td>{item?.tradeRefContactName ?? "-"}</td>
              <td>
                <div className="actionButton">
                  <Popconfirm
                    placement="left"
                    title={`Are you sure you want to delete this Document?`}
                    okText={`Delete`}
                    onConfirm={() => onDelete(item)}
                  >
                    <div className="actionAction">
                      <img src={deleteSvg} alt="delete" width="10" />
                    </div>
                  </Popconfirm>
                  <div className="actionAction" onClick={() => onUpdate(item)}>
                    <img src={editSvg} alt="Edit" width="10" />
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        registrationType: REGISTRATION_TYPES_DATA.INTERNATIONAL,
        firstName: "",
        lastName: "",
        // email: "",
        username: "",
        password: "",
        confirmPassword: "",
        countryCode: "",
        companyName: "",
        address: "",
        country: {},
        state: {},
        city: {},
        cityText: "",
        pinCode: "",
        fax: "",
        phone1: "",
        phone2: "",
        mobile: "",

        broker: false,
        brokerName: "",
        brokerEmail: "",
        brokerMobile: "",
        brokerPhoneNo: "",
        selectedPersonalDocType: "",
        isTermsCondAgree: false,
        updateRequired: false,
        businessType: "",
        seller: "",

        businessEmail: "",
        stateOfRegistration: "",
        businessPinCode: "",
        businessShippingPinCode: "",
        businessFax: "",
        businessPhone1: "",
        Website: "",
        legalName: "",
        owner: "",
        billingAddress: "",
        shippingAddress: "",
        facebookUserName: "",
        instagramId: "",
        linkedInId: "",
        businessMobile: "",
        legalStatusOfOrg: "",
        yearEstablished: "",
        isIncorporated: true,
        yearsAtPresentLocation: "",
        noOfEmployee: "",
        fedTaxId: "",
        resaleTax: "",
        JBTId: "",
        isAML: false,
        amlExplaination: "",
        isCreditTime: false,
        underWhatName: "",
        businessCountry: {},
        businessState: {},
        businessCity: {},
        businessCityText: "",
        businessShippingCountry: {},
        businessShippingState: {},
        businessShippingCity: {},
        businessShippingCityText: "",

        ownerName: "",
        ownerDesignation: "",
        ownerMobile: "",
        ownerEmail: "",
        ownerSocialSecurity: "",
        isCredit: true,

        tradeRefCompanyName: "",
        tradeRefAddress: "",
        tradeRefCityText: "",
        tradeRefCountry: {},
        tradeRefState: {},
        tradeRefCity: {},
        tradeRefMobile: "",
        tradeRefEmail: "",
        tradeRefContactName: "",

        bankName: "",
        account: "",
        branchAddress: "",
        branchCountry: {},
        branchState: {},
        branchCity: {},
        branchCityText: "",
        contactPerson: "",
        bankRefPinCode: "",
        bankRefPhone: "",
        bankRefFax: "",
        isBankruptcy: false,
        bankruptcyDate: "",
      },
      registrationType: REGISTRATION_TYPES_DATA.INTERNATIONAL,
      tradeRefInfo: [],
      allCountries: [],
      allCities: [],
      countries: [],
      states: [],
      cities: [],
      // photoPdf: "",
      // bIdPdf: "",
      // userKyc: [],
      // orgKyc: [],
      // mobileValid: true,
      // registerButton: false,
      isDeviceUrl: false,
      // roleType: "",
      // accountError: false,
      visible: false,
      sellers: [],
      allSellers: [],
      errors: {},
      // exists: [],
      BUSINESS_TYPE: [],
      DOC_TYPE_BUSINESS: [],
      DOC_TYPE_PERSONAL: [],
      NATURE_OF_ORG: [],
    };
  }

  componentDidMount() {
    let urlQuery = Object.fromEntries(
      new URLSearchParams(window.location.search),
    );
    if (!isEmpty(urlQuery))
      this.setState({ formData: { ...this.state.formData, ...urlQuery } });
    let isDeviceUrl = window.location.pathname.includes("/device/signup");
    this.setState({ isDeviceUrl: isDeviceUrl });
    this.fetchCountries();
    this.fetchSellers();
    this.getMasters();
  }

  getMasters = () => {
    let self = this;
    let obj = {
      ...GET_MASTERS,
      request: {
        masters: ["BUSINESS_TYPE", "NATURE_OF_ORG"],
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) throw err;
      else if (data && data.code === "OK") {
        self.setState({
          BUSINESS_TYPE: data.data.BUSINESS_TYPE || [],
          NATURE_OF_ORG: data.data.NATURE_OF_ORG || [],
        });
      }
    });
  };

  fetchCountries() {
    let self = this;

    let obj = {
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        if (
          self.state.formData.registrationType ===
          REGISTRATION_TYPES_DATA.INTERNATIONAL
        ) {
          let countries = data.data;
          self.setState({
            allCountries: countries || [],
            countries: countries || [],
          });
        } else {
          self.setState({
            allCountries: data.data || [],
            countries: data.data || [],
          });
        }
      }
    });
  }

  fetchSellers() {
    let self = this;

    let obj = {
      // method: "post",
      // url: "/front/sellers",
      ...FETCH_SELLERS,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        data.data = data.data.map((d) => {
          d.name = UtilService.getFullName(d);
          return d;
        });
        self.setState({
          //orderBy(users, ['user', 'age'], ['asc', 'desc'])
          sellers: orderBy(data.data, "firstName", "asc") || [],
          allSellers: data.data || [],
        });
      }
    });
  }

  fetchStates(countryId, cb) {
    let self = this;

    let obj = {
      ...GET_STATE_LIST,
      request: {
        country: countryId,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        self.setState({
          states: data.data || [],
        });
        cb();
      }
    });
  }

  fetchCities(stateId, countryId) {
    let self = this;

    let obj = {
      ...GET_CITY_LIST,
      request: {
        state: stateId,
        country: countryId,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        if (stateId) {
          self.setState({
            cities: [...data.data, { name: "Others", id: "-" }] || [],
          });
        } else if (countryId) {
          self.setState({
            cities: [...data.data, { name: "Others", id: "-" }] || [],
          });
        }
      }
    });
  }

  handleOnChange(key, value) {
    let { formData, errors } = this.state;
    formData[key] = value;
    delete errors[key];
    this.setState({ formData, errors });
  }

  handleCheckDuplicate(key, value) {
    let { formData, errors } = this.state;
    formData[key] = value;
    // delete errors[key];
    var err_message = `Please enter valid ${key}`;
    // const validField = ["email", "brokerEmail", "mobile", "brokerMobile"]
    if (key === "username") {
      if (isEmpty(formData["username"])) {
        errors["username"] = true;
      } else {
        const obj = {
          ...DUPLICATE_EMAIL,
          request: { email: formData["username"] },
        };

        UtilService.callApi(obj, (err, res) => {
          if (res?.code === "OK") {
            OpenNotification({ type: "error", title: res?.message });
            this.handleOnChange("username", "");
            errors["username"] = true;
          }
        });
      }
    }
    errors["username"] &&
      OpenNotification({
        type: "error",
        title: err_message,
      });
    this.setState({ errors });
  }

  handleRequiredError(fieldName, value) {
    let { formData, errors } = this.state;
    formData[fieldName] = value;
    const err_message = `Please enter valid ${fieldName}`;

    if (!formData[fieldName] || isEmpty(formData[fieldName])) {
      errors[fieldName] = true;
    }

    if (errors[fieldName]) {
      OpenNotification({
        type: "error",
        title: err_message,
      });
    }
    this.setState({ errors });
  }

  handleErrorForEmailPhone(key, value) {
    let { formData, errors } = this.state;
    formData[key] = value;
    delete errors[key];
    var err_message = "Please enter valid input.";
    // const validField = ["email", "brokerEmail", "mobile", "brokerMobile"]
    if (
      key === "email" ||
      key === "businessEmail" ||
      key === "brokerEmail" ||
      key === "tradeRefEmail"
    ) {
      if (
        !formData[key].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        errors[key] = true; //"Please enter valid Email.";
        err_message = "Please enter valid email id.";
      } else {
        errors[key] = undefined;
      }
    }

    if (
      key === "mobile" ||
      key === "brokerMobile" ||
      key === "businessMobile" ||
      key === "businessPhone1" ||
      key === "tradeRefMobile"
    ) {
      if (!formData[key] || isEmpty(formData[key])) {
        errors[key] = true;
      } else if (formData[key].length > 5) {
        if (
          formData["registrationType"] ===
            REGISTRATION_TYPES_DATA.NORTH_AMERICA &&
          formData[key] &&
          !formData[key].match(/^[0-9]{10}$/)
        ) {
          errors[key] = true;
        } else if (
          formData["registrationType"] !==
            REGISTRATION_TYPES_DATA.NORTH_AMERICA &&
          formData[key] &&
          !formData[key].match(/^[0-9]{6,17}$/)
        ) {
          errors[key] = true;
        } else {
          errors[key] = undefined;
        }
      } else {
        errors[key] = true;
      }
      if (errors[key]) {
        err_message = "Please enter valid mobile number.";
      }
    }
    errors[key] &&
      OpenNotification({
        type: "error",
        title: err_message,
      });
    this.setState({ errors });
  }

  handleCountryChange(
    e,
    countryValue,
    belongsTostate = "state",
    belongsTocity = "city",
  ) {
    let country = this.state.countries.filter((c) => c.id === e)[0];
    if (country) {
      let formData = this.state.formData;
      formData[countryValue] = country ? country : {};
      // if(belongsTostate === 'state' && belongsTocity === 'city'){
      //   formData.state = {};
      //   formData.city = {};
      // }else{
      formData[belongsTostate] = {};
      formData[belongsTocity] = {};
      // }
      this.setState(
        {
          formData: formData,
          states: [],
          cities: [],
          allCities: [],
        },
        () => {
          this.fetchStates(e);
          this.fetchCities(null, e);
        },
      );
    }
  }

  handleStateChange(e, satateValue, belongsTocity = "city") {
    const { errors } = this.state;
    let state = this.state.states.filter((c) => c.id === e)[0];
    if (state) {
      let formData = this.state.formData;
      formData[belongsTocity] = {};
      formData[satateValue] = state ? state : {};
      delete errors[`${satateValue}`];
      this.setState(
        {
          formData: formData,
          cities: [],
          errors,
        },
        () => {
          this.fetchCities(e);
        },
      );
    }
  }

  handleCityChange(e, cityValue) {
    const { errors } = this.state;

    let city = this.state.cities.filter((c) => c.id === e)[0];
    if (city) {
      let formData = this.state.formData;
      formData[cityValue] = city ? city : {};
      delete errors[`${cityValue}`];

      this.setState({ formData: formData, errors });
    }
  }

  handleTradeRef(tradeRefInfo) {
    let tradeReferences = [];
    tradeRefInfo.map((trade) =>
      tradeReferences.push({
        companyName: isEmpty(trade.tradeRefCompanyName)
          ? null
          : trade.tradeRefCompanyName,
        address: isEmpty(trade.tradeRefAddress) ? null : trade.tradeRefAddress,
        country: isEmpty(trade.tradeRefCountry.id)
          ? null
          : trade.tradeRefCountry.id,
        state: isEmpty(trade.tradeRefState.id) ? null : trade.tradeRefState.id,
        city: isEmpty(trade.tradeRefCity.id) ? null : trade.tradeRefCity.id,
        cityText: isEmpty(trade.tradeRefCityText)
          ? undefined
          : trade.tradeRefCityText,
        mobile: isEmpty(trade.tradeRefMobile)
          ? undefined
          : trade.tradeRefMobile,
        email: isEmpty(trade.tradeRefEmail) ? undefined : trade.tradeRefEmail,
        contactName: isEmpty(trade.tradeRefContactName)
          ? null
          : trade.tradeRefContactName,
      }),
    );
    return tradeReferences;
  }

  handleSignup = (e) => {
    e.preventDefault();
    let self = this;
    self.setState({ isLoading: true });
    let formData = clone(this.state.formData);

    let commonInfo = {
      registrationType:
        this.state?.registrationType ??
        this.state.formData?.registrationType ??
        formData.registrationType,
      firstName: isEmpty(formData.firstName) ? null : formData.firstName,
      lastName: isEmpty(formData.lastName) ? null : formData.lastName,
      username: isEmpty(formData.username) ? null : formData.username,
      password: isEmpty(formData.password) ? null : formData.password,
      confirmPassword: isEmpty(formData.confirmPassword)
        ? null
        : formData.confirmPassword,
      seller: isEmpty(formData.seller) ? null : formData.seller,
      country: isEmpty(formData.country) ? null : formData.country.id,
      state: isEmpty(formData.state) ? null : formData.state.id,
      city: isEmpty(formData.city) ? null : formData.city.id,
      cityText: isEmpty(formData.cityText) ? undefined : formData.cityText,
      address: isEmpty(formData.address) ? null : formData.address,
      pinCode: isEmpty(formData.pinCode) ? "" : formData.pinCode, //null
      phone1: isEmpty(formData.phone1) ? "" : formData.phone1, //null
      phone2: isEmpty(formData.phone2) ? "" : formData.phone2, //null
      mobile: isEmpty(formData.mobile) ? null : formData.mobile,
      companyName: isEmpty(formData.companyName) ? null : formData.companyName,
    };
    let businessInfo = {
      nameOfBusiness: isEmpty(formData.companyName)
        ? null
        : formData.companyName,
      Website: isEmpty(formData.Website) ? null : formData.Website,
      legalName: isEmpty(formData.legalName) ? null : formData.legalName,
      owner: isEmpty(formData.owner) ? null : formData.owner,
      billingAddress: isEmpty(formData.billingAddress)
        ? null
        : formData.billingAddress,
      shippingAddress: isEmpty(formData.shippingAddress)
        ? null
        : formData.shippingAddress,
      country: isEmpty(formData.businessCountry)
        ? null
        : formData.businessCountry.id,
      state: isEmpty(formData.businessState) ? null : formData.businessState.id,
      city: isEmpty(formData.businessCity) ? null : formData.businessCity.id,
      cityText: isEmpty(formData.businessCityText)
        ? undefined
        : formData.businessCityText,
      shippingCountry: isEmpty(formData.businessShippingCountry)
        ? null
        : formData.businessShippingCountry.id,
      shippingState: isEmpty(formData.businessShippingState)
        ? null
        : formData.businessShippingState.id,
      shippingCity: isEmpty(formData.businessShippingCity)
        ? null
        : formData.businessShippingCity.id,
      shippingCityText: isEmpty(formData.businessShippingCityText)
        ? undefined
        : formData.businessShippingCityText,
      shippingPinCode: isEmpty(formData.businessShippingPinCode)
        ? null
        : formData.businessShippingPinCode,
      pinCode: isEmpty(formData.pinCode) ? "" : formData.pinCode, //null
      phone1: isEmpty(formData.businessPhone1) ? null : formData.businessPhone1,
      faxNo: isEmpty(formData.fax) ? null : formData.fax,
      mobile: isEmpty(formData.businessMobile) ? null : formData.businessMobile,
      email: isEmpty(formData.businessEmail) ? null : formData.businessEmail,
      facebookUserName: isEmpty(formData.facebookUserName)
        ? null
        : formData.facebookUserName,
      instagramId: isEmpty(formData.instagramId) ? null : formData.instagramId,
      linkedInId: isEmpty(formData.linkedInId) ? null : formData.linkedInId,
      businessType: isEmpty(formData.businessType) ? "" : formData.businessType,
    };
    let groupAffiliations = {
      legalStatusOfOrg: isEmpty(formData.legalStatusOfOrg)
        ? null
        : formData.legalStatusOfOrg,
      yearEstablished: isEmpty(formData.yearEstablished)
        ? null
        : formData.yearEstablished,
      yearsAtPresentLocation: isEmpty(formData.yearsAtPresentLocation)
        ? null
        : formData.yearsAtPresentLocation,
      noOfEmployee: isEmpty(formData.noOfEmployee)
        ? null
        : formData.noOfEmployee,
      fedTaxId: isEmpty(formData.fedTaxId) ? null : formData.fedTaxId,
      why: isEmpty(formData.amlExplaination)
        ? undefined
        : formData.amlExplaination,
      isIncorporated: !isBoolean(formData.isIncorporated)
        ? null
        : formData.isIncorporated,
      isAML: !isBoolean(formData.isAML) ? null : formData.isAML,
      isCreditTime: formData.isCreditTime,
      stateOfRegistration: isEmpty(formData.stateOfRegistration)
        ? null
        : formData.stateOfRegistration,
      resaleTax: isEmpty(formData.resaleTax) ? undefined : formData.resaleTax,
      JBTId: isEmpty(formData.JBTId) ? undefined : formData.JBTId,
      underWhatName: isEmpty(formData.underWhatName)
        ? undefined
        : formData.underWhatName,
    };
    let ownerDetails = {
      name: isEmpty(formData.ownerName) ? null : formData.ownerName,
      designation: isEmpty(formData.ownerDesignation)
        ? null
        : formData.ownerDesignation,
      mobiles: isEmpty(formData.ownerMobile) ? null : formData.ownerMobile,
      email: isEmpty(formData.ownerEmail) ? null : formData.ownerEmail,
      socialSecurity: isEmpty(formData.ownerSocialSecurity)
        ? null
        : formData.ownerSocialSecurity,
      isCredit: !formData.isCredit,
    };

    let tradeReferences = this.handleTradeRef(this.state.tradeRefInfo);
    let bankReferences = {
      bankName: isEmpty(formData.bankName) ? null : formData.bankName,
      account: isEmpty(formData.account) ? null : formData.account,
      branchAddress: isEmpty(formData.branchAddress)
        ? null
        : formData.branchAddress,
      country: isEmpty(formData.branchCountry)
        ? null
        : formData.branchCountry.id,
      state: isEmpty(formData.branchState) ? null : formData.branchState.id,
      city: isEmpty(formData.branchCity) ? null : formData.branchCity.id,
      cityText: isEmpty(formData.branchCityText)
        ? undefined
        : formData.branchCityText,
      contactPerson: isEmpty(formData.contactPerson)
        ? null
        : formData.contactPerson,
      pincode: isEmpty(formData.bankRefPinCode)
        ? null
        : formData.bankRefPinCode,
      phoneNo: isEmpty(formData.bankRefPhone) ? null : formData.bankRefPhone,
      faxNo: isEmpty(formData.bankRefFax) ? null : formData.bankRefFax,
      isBankruptcy: formData.isBankruptcy ?? false,
      bankruptcyDate: isEmpty(formData.bankruptcyDate)
        ? undefined
        : formData.bankruptcyDate,
    };

    let brokerDetails = {
      brokerName: isEmpty(formData.brokerName) ? null : formData.brokerName,
      brokerEmail: isEmpty(formData.brokerEmail) ? null : formData.brokerEmail,
      brokerMobile: isEmpty(formData.brokerMobile)
        ? null
        : formData.brokerMobile,
      brokerPhoneNo: isEmpty(formData.brokerPhoneNo)
        ? null
        : formData.brokerPhoneNo,
    };
    let SellerId = (window.location.search) && window.location.search.substring(1)
    // formData.city = isEmpty(formData.city) ? null : formData.city.id;
    // formData.state = isEmpty(formData.state) ? null : formData.state.id;
    // formData.country = isEmpty(formData.country) ? null : formData.country.id;
    // formData.emails = isEmpty(formData.email) ? null : [formData.email];
    // formData.mobiles = isEmpty(formData.mobile) ? null : [formData.mobile];

    let payload = {
      ...commonInfo,
      registrationType: self.registrationType ?? formData?.registrationType,
      businessInfo: businessInfo,
      groupAffiliations: groupAffiliations,
      ownerDetails: ownerDetails,
      tradeReferences: tradeReferences,
      bankReferences: bankReferences,
      brokerDetails: brokerDetails,
      addedBySeller: SellerId
    };

    let obj = {
      // method: "post",
      // url: "/front/register",
      ...SIGNUP_USER_FRONT,
      request: payload,
    };
    if (self.handleValidation()) {
      UtilService.callApi(obj, function (err, data) {
        self.setState({ isLoading: false });
        if (err && err.data) {
          if (err.data.code === "E_USER_NOT_FOUND") {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
          if (
            [
              "E_DUPLICATE_EMAIL",
              "E_DUPLICATE",
              "E_DUPLICATE_MOBILE",
              "E_DUPLICATE_USERNAME",
            ].includes(err.data.code)
          ) {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
        }
        if (data && data.code === "OK") {
          OpenNotification({
            type: "success",
            title: data.message,
          });
          // self.setState({ registerButton: true });
          // if (self.state.isDeviceUrl) {
          //   window.RegisterCallBack.postMessage(data.message);
          // }
          localStorage.setItem(
            `${LOCAL_STORAGE_VAR}-thank-you`,
            JSON.stringify(true),
          );
          self.props.history.push("/thank-you");
          // OpenNotification({
          //   type: "success",
          //   title: "Registered successfully !",
          // });
        }
      });
    } else {
      self.setState({ isLoading: false });
    }
  };

  handleValidation() {
    let self = this;
    let formData = self.state.formData;
    let errors = self.state.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFields = [];

    if (!formData["businessEmail"] || isEmpty(formData["businessEmail"])) {
      blankField.push("Business Email Address");
      formIsValid = false;
      errors["businessEmail"] = true;
    } else if (formData["businessEmail"]) {
      if (
        !formData["businessEmail"].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        invalidFields.push("Business Email Address");
        formIsValid = false;
        errors["businessEmail"] = true; //"Please enter valid Email.";
      } else {
        errors["businessEmail"] = undefined;
      }
    }

    // if (!formData["username"] || isEmpty(formData["username"])) {
    //   blankField.push("Username");
    //   formIsValid = false;
    //   errors["username"] = true;
    // }
    if (!formData["firstName"] || isEmpty(formData["firstName"])) {
      blankField.push("First Name");
      formIsValid = false;
      errors["firstName"] = true;
    }
    if (!formData["lastName"] || isEmpty(formData["lastName"])) {
      blankField.push("Last Name");
      formIsValid = false;
      errors["lastName"] = true;
    }
    if (formData["isBankruptcy"]) {
      if (!formData["bankruptcyDate"] || isEmpty(formData["bankruptcyDate"])) {
        blankField.push("bankruptcyDate");
        formIsValid = false;
        errors["bankruptcyDate"] = true;
      }
    }

    if (!formData["contactPerson"] || isEmpty(formData["contactPerson"])) {
      blankField.push("Contact Person");
      formIsValid = false;
      errors["contactPerson"] = true;
    }

    if (!formData["bankName"] || isEmpty(formData["bankName"])) {
      blankField.push("Bank Name");
      formIsValid = false;
      errors["bankName"] = true;
    }
    if (!formData["account"] || isEmpty(formData["account"])) {
      blankField.push("Account");
      formIsValid = false;
      errors["account"] = true;
    }
    if (!formData["branchAddress"] || isEmpty(formData["branchAddress"])) {
      blankField.push("Branch Address");
      formIsValid = false;
      errors["branchAddress"] = true;
    }
    if (
      !formData["ownerDesignation" || isEmpty(formData["ownerDesignation"])]
    ) {
      blankField.push("Designation");
      formIsValid = false;
      errors["ownerDesignation"] = true;
    }
    if (formData["username"]) {
      if (
        formData["username"] &&
        !formData["username"].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        invalidFields.push("User Name(Email)");
        formIsValid = false;
        errors["username"] = true;
      }
    }

    if (!formData["password"]) {
      blankField.push(" Password");
      formIsValid = false;
      errors["password"] = true;
    } else if (
      formData["password"] &&
      // (!value.match(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/) || value.length > 15 || value.length < 6)
      formData["password"].length < 4
    ) {
      invalidFields.push("Password");
      formIsValid = false;
      errors["password"] = true; //"Password must be 3 digit long.";
    }

    if (!formData["confirmPassword"]) {
      blankField.push("Confirm Password");
      formIsValid = false;
      errors["confirmPassword"] = true; //"Please enter confirm password";
    } else if (
      formData &&
      formData["confirmPassword"] &&
      formData["confirmPassword"].length &&
      formData["confirmPassword"] !== formData.password
    ) {
      invalidFields.push("Confirm Password");
      formIsValid = false;
      errors["confirmPassword"] = true; //"Please make sure your passwords match.";
    }

    if (!formData["address"] || isEmpty(formData["address"])) {
      blankField.push("Address");
      formIsValid = false;
      errors["address"] = true;
    }

    if (!formData["country"] || isEmpty(formData["country"])) {
      blankField.push("Country");
      formIsValid = false;
      errors["country"] = true;
    }
    if (!formData["businessCountry"] || isEmpty(formData["businessCountry"])) {
      blankField.push("Country");
      formIsValid = false;
      errors["businessCountry"] = true;
    }
    if (
      !formData["businessShippingCountry"] ||
      isEmpty(formData["businessShippingCountry"])
    ) {
      blankField.push("SHipping Country");
      formIsValid = false;
      errors["businessShippingCountry"] = true;
    }
    if (!formData["branchCountry"] || isEmpty(formData["branchCountry"])) {
      blankField.push("Branch Country");
      formIsValid = false;
      errors["branchCountry"] = true;
    }

    if (!formData["state"] || isEmpty(formData["state"])) {
      blankField.push("State");
      formIsValid = false;
      errors["state"] = true;
    }
    if (!formData["businessState"] || isEmpty(formData["businessState"])) {
      blankField.push("State");
      formIsValid = false;
      errors["businessState"] = true;
    }
    if (!formData["branchState"] || isEmpty(formData["branchState"])) {
      blankField.push("Branch State");
      formIsValid = false;
      errors["branchState"] = true;
    }
    if (
      !formData["businessShippingState"] ||
      isEmpty(formData["businessShippingState"])
    ) {
      blankField.push("State");
      formIsValid = false;
      errors["businessShippingState"] = true;
    }

    if (!formData["city"] || isEmpty(formData["city"])) {
      blankField.push("City");
      formIsValid = false;
      errors["city"] = true;
    }
    if (!formData["businessCity"] || isEmpty(formData["businessCity"])) {
      blankField.push("City");
      formIsValid = false;
      errors["businessCity"] = true;
    }
    if (
      !formData["businessShippingCity"] ||
      isEmpty(formData["businessShippingCity"])
    ) {
      blankField.push("City");
      formIsValid = false;
      errors["businessShippingCity"] = true;
    }
    if (!formData["branchCity"] || isEmpty(formData["branchCity"])) {
      blankField.push("Branch City");
      formIsValid = false;
      errors["branchCity"] = true;
    }

    if (!formData["businessPinCode"] || isEmpty(formData["businessPinCode"])) {
      blankField.push("Pincode");
      formIsValid = false;
      errors["businessPinCode"] = true;
    } else if (
      formData["businessPinCode"] &&
      !formData["businessPinCode"].match(/^[0-9]{4,10}$/)
    ) {
      invalidFields.push("Pincode");
      formIsValid = false;
      errors["businessPinCode"] = true;
    }
    if (
      !formData["businessShippingPinCode"] ||
      isEmpty(formData["businessShippingPinCode"])
    ) {
      blankField.push("Pincode");
      formIsValid = false;
      errors["businessShippingPinCode"] = true;
    } else if (
      formData["businessShippingPinCode"] &&
      !formData["businessShippingPinCode"].match(/^[0-9]{4,10}$/)
    ) {
      invalidFields.push("Pincode");
      formIsValid = false;
      errors["businessShippingPinCode"] = true;
    }
    if (!formData["bankRefPinCode"] || isEmpty(formData["bankRefPinCode"])) {
      blankField.push("Pincode");
      formIsValid = false;
      errors["bankRefPinCode"] = true;
    } else if (
      formData["bankRefPinCode"] &&
      !formData["bankRefPinCode"].match(/^[0-9]{4,10}$/)
    ) {
      invalidFields.push("Pincode");
      formIsValid = false;
      errors["bankRefPinCode"] = true;
    }

    if (!formData["mobile"] || isEmpty(formData["mobile"])) {
      blankField.push("Mobile No.");
      formIsValid = false;
      errors["mobile"] = true;
    } else if (
      formData["mobile"] &&
      !formData["mobile"].match(/^[0-9]{6,17}$/)
    ) {
      invalidFields.push("Mobile No.");
      formIsValid = false;
      errors["mobile"] = true;
    } else {
      errors["mobile"] = undefined;
    }

    if (!formData["businessMobile"] || isEmpty(formData["businessMobile"])) {
      blankField.push("Mobile No.");
      formIsValid = false;
      errors["businessMobile"] = true;
    } else if (
      formData["businessMobile"] &&
      !formData["businessMobile"].match(/^[0-9]{6,17}$/)
    ) {
      invalidFields.push("Mobile No.");
      formIsValid = false;
      errors["businessMobile"] = true;
    } else {
      errors["businessMobile"] = undefined;
    }
    // if (!formData.phone1 || !isEmpty(formData["phone1"])) {
    //   blankField.push("Phone 1");
    //   formIsValid = false;
    //   errors["phone1"] = true;
    // } else
    if (
      formData["businessPhone1"] &&
      (formData["businessPhone1"].length < 7 ||
        formData["businessPhone1"].length > 15)
    ) {
      invalidFields.push("Phone");
      formIsValid = false;
      errors["businessPhone1"] = true;
    }
    if (
      formData["bankRefPhone"] &&
      (formData["bankRefPhone"].length < 7 ||
        formData["bankRefPhone"].length > 15)
    ) {
      invalidFields.push("Phone");
      formIsValid = false;
      errors["bankRefPhone"] = true;
    }
    // if (!formData["phone2"] || !isEmpty(formData["phone2"])) {
    //   blankField.push("Phone 2");
    //   formIsValid = false;
    //   errors["phone2"] = true;
    // } else
    // if (
    //   formData["phone2"] &&
    //   (formData["phone2"].length < 7 || formData["phone2"].length > 15)
    // ) {
    //   invalidFields.push("Phone 2");
    //   formIsValid = false;
    //   errors["phone2"] = true;
    // }

    // if (!formData.fax || !isEmpty(formData["fax"])) {
    //   blankField.push("Fax No");
    //   formIsValid = false;
    //   errors["fax"] = true;
    // } else
    // if (formData["fax"] && !formData["fax"].match(/^\+?[0-9]{6,}$/)) {
    //   invalidFields.push("Phone 2");
    //   formIsValid = false;
    //   errors["fax"] = errors["fax"];
    // }

    if (!formData.companyName || isEmpty(formData.companyName)) {
      blankField.push("Company Name");
      formIsValid = false;
      errors["companyName"] = true;
    } else if (
      formData["companyName"] &&
      !formData["companyName"].match(/^[a-zA-Z0-9 ]+$/)
    ) {
      invalidFields.push("Company Name");
      formIsValid = false;
      errors["companyName"] = true; //"Please enter valid Last Name.";
    }

    if (!formData.legalName || isEmpty(formData.legalName)) {
      blankField.push("Legal Name");
      formIsValid = false;
      errors["legalName"] = true;
    }
    if (!formData.owner || isEmpty(formData.owner)) {
      blankField.push("Owner");
      formIsValid = false;
      errors["owner"] = true;
    }
    if (!formData.billingAddress || isEmpty(formData.billingAddress)) {
      blankField.push("Billing Address");
      formIsValid = false;
      errors["billingAddress"] = true;
    }
    if (!formData.shippingAddress || isEmpty(formData.shippingAddress)) {
      blankField.push("Shipping Address");
      formIsValid = false;
      errors["shippingAddress"] = true;
    }

    if (
      formData.registrationType !== REGISTRATION_TYPES_DATA.INTERNATIONAL &&
      (!formData.stateOfRegistration || isEmpty(formData.stateOfRegistration))
    ) {
      blankField.push("State Of Registration");
      formIsValid = false;
      errors["stateOfRegistration"] = true;
    }
    if (!formData.yearEstablished || isEmpty(formData.yearEstablished)) {
      blankField.push("Year Established");
      formIsValid = false;
      errors["yearEstablished"] = true;
    }
    // if (!formData.isIncorporated) {
    //   blankField.push("Incorporated");
    //   formIsValid = false;
    //   errors["isIncorporated"] = true;
    // }
    if (
      !formData.yearsAtPresentLocation ||
      isEmpty(formData.yearsAtPresentLocation)
    ) {
      blankField.push("Years At Present Location");
      formIsValid = false;
      errors["yearsAtPresentLocation"] = true;
    }
    if (!formData.noOfEmployee || isEmpty(formData.noOfEmployee)) {
      blankField.push("Number Of Employees");
      formIsValid = false;
      errors["noOfEmployee"] = true;
    }
    if (!formData.fedTaxId || isEmpty(formData.fedTaxId)) {
      blankField.push("Fed Tax ID");
      formIsValid = false;
      errors["fedTaxId"] = true;
    }
    if (
      formData.registrationType !== REGISTRATION_TYPES_DATA.INTERNATIONAL &&
      (!formData.resaleTax || isEmpty(formData.resaleTax))
    ) {
      blankField.push("Resale Tax");
      formIsValid = false;
      errors["resaleTax"] = true;
    }
    if (
      formData.registrationType !== REGISTRATION_TYPES_DATA.INTERNATIONAL &&
      (!formData.JBTId || isEmpty(formData.JBTId))
    ) {
      blankField.push("JBT Id");
      formIsValid = false;
      errors["JBTId "] = true;
    }
    if (
      formData.registrationType !== REGISTRATION_TYPES_DATA.INTERNATIONAL &&
      (!formData.amlExplaination || isEmpty(formData.amlExplaination))
    ) {
      blankField.push("AML Explaination");
      formIsValid = false;
      errors["amlExplaination"] = true;
    }
    if (
      formData.registrationType !== REGISTRATION_TYPES_DATA.INTERNATIONAL &&
      formData.isCreditTime === true &&
      (!formData.underWhatName || isEmpty(formData.underWhatName))
    ) {
      blankField.push("Under What Name");
      formIsValid = false;
      errors["underWhatName"] = true;
    }

    if (isEmpty(formData["businessType"])) {
      blankField.push("Type of Business");
      formIsValid = false;
      errors["businessType"] = true;
    }
    if (isEmpty(formData["legalStatusOfOrg"])) {
      blankField.push("Legal Status Of Organization");
      formIsValid = false;
      errors["legalStatusOfOrg"] = true;
    }

    if (isEmpty(formData["seller"])) {
      blankField.push("Sales Person");
      formIsValid = false;
      errors["seller"] = true;
    }

    if (formData.city.id === "-") {
      if (!formData["cityText"] || isEmpty(formData["cityText"])) {
        blankField.push("City");
        formIsValid = false;
        errors["cityText"] = true;
      } else if (
        formData["cityText"] &&
        !formData["cityText"].match(/^[a-zA-Z ]+$/)
      ) {
        invalidFields.push("City");
        errors["cityText"] = true;
        formIsValid = false;
      } else {
        errors["cityText"] = undefined;
      }
    }
    if (formData.businessCity.id === "-") {
      if (
        !formData["businessCityText"] ||
        isEmpty(formData["businessCityText"])
      ) {
        blankField.push("City");
        formIsValid = false;
        errors["businessCityText"] = true;
      } else if (
        formData["businessCityText"] &&
        !formData["businessCityText"].match(/^[a-zA-Z ]+$/)
      ) {
        invalidFields.push("City");
        errors["businessCityText"] = true;
        formIsValid = false;
      } else {
        errors["businessCityText"] = undefined;
      }
    }
    if (formData.branchCity.id === "-") {
      if (!formData["branchCityText"] || isEmpty(formData["branchCityText"])) {
        blankField.push("City");
        formIsValid = false;
        errors["branchCityText"] = true;
      } else if (
        formData["branchCityText"] &&
        !formData["branchCityText"].match(/^[a-zA-Z ]+$/)
      ) {
        invalidFields.push("City");
        errors["branchCityText"] = true;
        formIsValid = false;
      } else {
        errors["branchCityText"] = undefined;
      }
    }

    if (formData["broker"]) {
      if (formData["broker"] && isEmpty(formData["brokerName"])) {
        blankField.push("Broker Name");
        formIsValid = false;
        errors["brokerName"] = true;
      } else if (!formData["brokerName"].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push("Broker Name");
        formIsValid = false;
        errors["brokerName"] = true;
      } else {
        errors["brokerName"] = undefined;
      }
    }

    if (formData["brokerEmail"]) {
      if (
        formData["brokerEmail"] &&
        !formData["brokerEmail"].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        invalidFields.push("Broker Email");
        formIsValid = false;
        errors["brokerEmail"] = true; //"Please enter valid brokerEmail.";
      } else {
        errors["brokerEmail"] = undefined;
      }
    }

    if (formData["broker"] && isEmpty(formData["brokerMobile"])) {
      blankField.push("Broker Mobile No.");
      formIsValid = false;
      errors["brokerMobile"] = true;
    } else if (
      formData["brokerMobile"] &&
      !formData["brokerMobile"].match(/^[0-9]{7,15}$/)
    ) {
      invalidFields.push("Broker Mobile No.");
      formIsValid = false;
      errors["brokerMobile"] = true;
    }

    if (formData["broker"]) {
      if (
        formData["brokerPhoneNo"] &&
        !formData["brokerPhoneNo"].match(/^[0-9]{10,15}$/)
      ) {
        invalidFields.push("Broker Phone No.");
        formIsValid = false;
        errors["brokerPhoneNo"] = true;
      }
    }

    if (self.state.tradeRefInfo.length < 3) {
      notification.error({
        message: "Trade Reference",
        placement: "bottomLeft",
        description: "Atleast three Records are needed",
      });
      formIsValid = false;
    }

    if (blankField.length > 0) {
      let arr = blankField;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    if (invalidFields.length > 0) {
      let arr = invalidFields;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    self.setState({ errors: errors });
    return formIsValid;
  }

  handleCancel(e) {
    e.preventDefault();
    this.setState({
      formData: {
        registrationType: REGISTRATION_TYPES_DATA.INTERNATIONAL,
        firstName: "",
        lastName: "",
        username: "",
        password: "",
        confirmPassword: "",
        countryCode: "",
        companyName: "",
        address: "",
        country: {},
        state: {},
        city: {},
        cityText: "",
        pinCode: "",
        fax: "",
        phone1: "",
        phone2: "",
        mobile: "",

        broker: false,
        brokerName: "",
        brokerEmail: "",
        brokerMobile: "",
        brokerPhoneNo: "",
        selectedPersonalDocType: "",
        isTermsCondAgree: false,
        updateRequired: false,
        businessType: "",
        seller: "",

        businessEmail: "",
        stateOfRegistration: "",
        businessPinCode: "",
        businessFax: "",
        businessPhone1: "",
        Website: "",
        legalName: "",
        owner: "",
        billingAddress: "",
        shippingAddress: "",
        facebookUserName: "",
        instagramId: "",
        linkedInId: "",
        businessMobile: "",
        legalStatusOfOrg: "",
        yearEstablished: "",
        isIncorporated: undefined,
        yearsAtPresentLocation: "",
        noOfEmployee: "",
        fedTaxId: "",
        resaleTax: "",
        JBTId: "",
        isAML: false,
        amlExplaination: "",
        isCreditTime: false,
        underWhatName: "",
        businessCountry: {},
        businessState: {},
        businessCity: {},
        businessCityText: "",

        ownerName: "",
        ownerDesignation: "",
        ownerMobile: "",
        ownerEmail: "",
        ownerSocialSecurity: "",
        isCredit: true,

        tradeRefCompanyName: "",
        tradeRefAddress: "",
        tradeRefCityText: "",
        tradeRefCountry: {},
        tradeRefState: {},
        tradeRefCity: {},
        tradeRefMobile: "",
        tradeRefEmail: "",
        tradeRefContactName: "",

        bankName: "",
        account: "",
        branchAddress: "",
        branchCountry: {},
        branchState: {},
        branchCity: {},
        branchCityText: "",
        contactPerson: "",
        bankRefPinCode: "",
        bankRefPhone: "",
        bankRefFax: "",
        isBankruptcy: false,
      },
      tradeRefInfo: [],
      errors: {},
    });
  }

  TermPopup = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleTraderefState(e) {
    e.preventDefault();
    this.setState({
      formData: {
        tradeRefCompanyName: "",
        tradeRefAddress: "",
        tradeRefCityText: "",
        tradeRefCountry: {},
        tradeRefState: {},
        tradeRefCity: {},
        tradeRefMobile: "",
        tradeRefEmail: "",
        tradeRefContactName: "",
      },
    });
  }

  handleTradeRefValidation() {
    let self = this;
    let formData = self.state.formData;
    let errors = self.state.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFields = [];

    if (formData.registrationType === REGISTRATION_TYPES_DATA.NORTH_AMERICA) {
      if (
        !formData["tradeRefCompanyName"] ||
        isEmpty(formData["tradeRefCompanyName"])
      ) {
        blankField.push("Company Name");
        formIsValid = false;
        errors["tradeRefCompanyName"] = true;
      }
      if (
        !formData["tradeRefAddress"] ||
        isEmpty(formData["tradeRefAddress"])
      ) {
        blankField.push("Address");
        formIsValid = false;
        errors["tradeRefAddress"] = true;
      }

      if (
        !formData["tradeRefCountry"] ||
        isEmpty(formData["tradeRefCountry"])
      ) {
        blankField.push("Country");
        formIsValid = false;
        errors["tradeRefCountry"] = true;
      }
      if (!formData["tradeRefState"] || isEmpty(formData["tradeRefState"])) {
        blankField.push("State");
        formIsValid = false;
        errors["tradeRefState"] = true;
      }
      if (!formData["tradeRefCity"] || isEmpty(formData["tradeRefCity"])) {
        blankField.push("City");
        formIsValid = false;
        errors["tradeRefCity"] = true;
      }
      if (formData.tradeRefCity.id === "-") {
        if (
          !formData["tradeRefCityText"] ||
          isEmpty(formData["tradeRefCityText"])
        ) {
          blankField.push("City");
          formIsValid = false;
          errors["tradeRefCityText"] = true;
        } else if (
          formData["tradeRefCityText"] &&
          !formData["tradeRefCityText"].match(/^[a-zA-Z ]+$/)
        ) {
          invalidFields.push("City");
          errors["tradeRefCityText"] = true;
          formIsValid = false;
        } else {
          errors["tradeRefCityText"] = undefined;
        }
      }

      if (!formData["tradeRefMobile"] || isEmpty(formData["tradeRefMobile"])) {
        blankField.push("Mobile");
        formIsValid = false;
        errors["tradeRefMobile"] = true;
      }
      if (!formData["tradeRefEmail"] || isEmpty(formData["tradeRefEmail"])) {
        blankField.push("Email");
        formIsValid = false;
        errors["tradeRefEmail"] = true;
      }
      if (
        !formData["tradeRefContactName"] ||
        isEmpty(formData["tradeRefContactName"])
      ) {
        blankField.push("Contact Name");
        formIsValid = false;
        errors["tradeRefContactName"] = true;
      }
    } else if (
      !formData["tradeRefCompanyName"] ||
      isEmpty(formData["tradeRefCompanyName"]) ||
      !formData["tradeRefAddress"] ||
      isEmpty(formData["tradeRefAddress"]) ||
      !formData["tradeRefMobile"] ||
      isEmpty(formData["tradeRefMobile"]) ||
      !formData["tradeRefEmail"] ||
      isEmpty(formData["tradeRefEmail"]) ||
      !formData["tradeRefContactName"] ||
      isEmpty(formData["tradeRefContactName"])
    ) {
      formIsValid = false;
      notification.error({
        message: "Trade Reference",
        placement: "bottomLeft",
        description: "Please enter details",
      });
    }

    if (blankField.length > 0) {
      let arr = blankField;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    if (invalidFields.length > 0) {
      let arr = invalidFields;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    self.setState({ errors: errors });
    return formIsValid;
  }

  handleTraderef = (e) => {
    e.preventDefault();
    let formData = this.state.formData;
    let tradeInfo = {
      tradeRefCompanyName: formData.tradeRefCompanyName,
      tradeRefAddress: formData.tradeRefAddress,
      tradeRefCityText: formData.tradeRefCityText,
      tradeRefCountry: formData.tradeRefCountry,
      tradeRefState: formData.tradeRefState,
      tradeRefCity: formData.tradeRefCity,
      tradeRefMobile: formData.tradeRefMobile,
      tradeRefEmail: formData.tradeRefEmail,
      tradeRefContactName: formData.tradeRefContactName,
      tradeRefID: uuid(),
    };
    if (this.handleTradeRefValidation()) {
      this.setState(
        { tradeRefInfo: [...this.state.tradeRefInfo, tradeInfo] },
        () => {
          this.setState({
            formData: {
              ...formData,
              tradeRefCompanyName: "",
              tradeRefAddress: "",
              tradeRefCityText: "",
              tradeRefCountry: {},
              tradeRefState: {},
              tradeRefCity: {},
              tradeRefMobile: "",
              tradeRefEmail: "",
              tradeRefContactName: "",
            },
          });
        },
      );
    }
  };

  deleteDocument = (document) => {
    let tradeInfo = this.state?.tradeRefInfo.filter(
      (data) => data?.tradeRefID !== document?.tradeRefID,
    );

    this.setState({
      tradeRefInfo: tradeInfo,
    });
  };

  updateDocument = (data) => {
    let formData = this.state.formData;
    this.setState(
      {
        formData: {
          ...formData,
          tradeRefCompanyName: data.tradeRefCompanyName,
          tradeRefAddress: data.tradeRefAddress,
          tradeRefCityText: data.tradeRefCityText,
          tradeRefCountry: data.tradeRefCountry,
          tradeRefState: data.tradeRefState,
          tradeRefCity: data.tradeRefCity,
          tradeRefMobile: data.tradeRefMobile,
          tradeRefEmail: data.tradeRefEmail,
          tradeRefContactName: data.tradeRefContactName,
        },
      },
      () => this.deleteDocument(data),
    );
  };

  render() {
    const { formData, countries, states, cities, errors, sellers } = this.state;
    return (
      <div
        className={`loginDetail registerForm ${
          this.state.isLoading ? "loading-indicator" : ""
        } `}
      >
        <div className="registerMainScroll">
          <Loader loading={this.props.loading} />
          <div className="loginHead">
            <div className="loginLogo">
              <Logo />
            </div>
            <h3>
              <IntlMessages id="app.signup.registration" />
            </h3>
          </div>
          <div className="accountType">
            <Heading title="Registration For" className="popupInnerTitle p-0" />
            <div className="d-flex registrationSelect">
              <div
                className={
                  formData.registrationType ===
                  REGISTRATION_TYPES_DATA.INTERNATIONAL
                    ? "registrationSelectItem active"
                    : "registrationSelectItem"
                }
                onClick={() => {
                  this.setState({
                    registrationType: REGISTRATION_TYPES_DATA.INTERNATIONAL,
                  });
                  this.handleOnChange(
                    "registrationType",
                    REGISTRATION_TYPES_DATA.INTERNATIONAL,
                  );
                  this.fetchCountries();
                  this.fetchStates(formData.country?.id);
                }}
              >
                <div className="registrationSelectImg">
                  <img
                    src={require("../../assets/svg/Registration/international.svg")}
                    alt=""
                  />
                </div>
                <span>International</span>
              </div>
              <div
                className={
                  formData.registrationType ===
                  REGISTRATION_TYPES_DATA.NORTH_AMERICA
                    ? "registrationSelectItem active"
                    : "registrationSelectItem"
                }
                onClick={() => {
                  this.setState({
                    registrationType: REGISTRATION_TYPES_DATA.NORTH_AMERICA,
                  });
                  this.handleOnChange(
                    "registrationType",
                    REGISTRATION_TYPES_DATA.NORTH_AMERICA,
                  );
                  this.fetchCountries();
                  this.fetchStates(formData.country?.id);
                }}
              >
                <div className="registrationSelectImg">
                  <img
                    src={require("../../assets/svg/Registration/international.svg")}
                    // src={require("../../assets/svg/Registration/india.svg")}
                    alt=""
                  />
                </div>
                <span>North America</span>
              </div>
            </div>
          </div>
          <div className="registerMainBlock">
            <Form autoComplete="off" onSubmit={this.handleSignup}>
              <div id="section_1">
                <Heading
                  title="Login Information"
                  className="popupInnerTitle p-0"
                />
                <div className="registerFiledBlock">
                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("firstName", e.target.value.trim())
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("firstName", e.target.value)
                    }
                    hasError={errors["firstName"] ? true : false}
                    value={formData.firstName}
                    type="text"
                    label="First Name *"
                    placeholder="First Name"
                  />
                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("lastName", e.target.value.trim())
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("lastName", e.target.value)
                    }
                    hasError={errors["lastName"] ? true : false}
                    value={formData.lastName}
                    type="text"
                    label="Last Name *"
                    placeholder="Last Name"
                  />
                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("username", e.target.value.trim())
                    }
                    hasError={errors["username"] ? true : false}
                    value={formData.username}
                    onBlur={(e) =>
                      this.handleCheckDuplicate(
                        "username",
                        e.target.value.trim(),
                      )
                    }
                    type="text"
                    label="Username (Email) *"
                    placeholder="Username"
                  />

                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("password", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("password", e.target.value)
                    }
                    hasError={errors["password"] ? true : false}
                    value={formData.password}
                    type="password"
                    label="Password *"
                    placeholder="Password"
                  />

                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("confirmPassword", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError(
                        "confirmPassword",
                        e.target.value,
                      )
                    }
                    hasError={errors["confirmPassword"] ? true : false}
                    value={formData.confirmPassword}
                    type="password"
                    label="Confirm Password *"
                    placeholder="Confirm Password"
                  />

                  <SelectOption
                    defaultValue="Sales Person"
                    onChange={(e) => this.handleOnChange("seller", e)}
                    value={formData.seller ? formData.seller : "Sales Person"}
                    selectValue={sellers}
                    showSearch
                    label="Select Sales Person *"
                    hasError={errors["seller"] ? true : false}
                  />
                </div>
              </div>
              <div id="section_2">
                <Heading
                  title="Contact Information"
                  className="popupInnerTitle p-0"
                />
                <div className="registerFiledBlock">
                  <SelectOption
                    defaultValue="Country"
                    capitalize
                    value={
                      !isEmpty(formData.country)
                        ? upperCase(formData.country.name)
                        : "Country"
                    }
                    selectValue={countries}
                    // disabled={formData.registrationType === 2 ? true : false}
                    onChange={(e) => this.handleCountryChange(e, "country")}
                    label="Select Country*"
                    showSearch
                    hasError={errors["country"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="State"
                    capitalize
                    value={
                      !isEmpty(formData.state)
                        ? upperCase(formData.state.name)
                        : "State"
                    }
                    selectValue={states}
                    onChange={(e) => this.handleStateChange(e, "state")}
                    label="Select State *"
                    showSearch
                    hasError={errors["state"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="City"
                    capitalize
                    value={
                      !isEmpty(formData.city)
                        ? upperCase(formData.city.name)
                        : "City"
                    }
                    selectValue={cities}
                    onChange={(e) => this.handleCityChange(e, "city")}
                    showSearch
                    label="Select City*"
                    hasError={errors["city"] ? true : false}
                  />
                  {this.state.formData?.city?.id === "-" && (
                    <InputBlock
                      onChange={(e) =>
                        this.handleOnChange("cityText", e.target.value)
                      }
                      hasError={errors["cityText"] ? true : false}
                      type="text"
                      value={formData.cityText}
                      label="City*"
                      placeholder="City"
                    />
                  )}
                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("address", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("address", e.target.value)
                    }
                    hasError={errors["address"] ? true : false}
                    value={formData.address}
                    type="text"
                    label="Address *"
                    placeholder="Address"
                  />

                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("pinCode", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("pinCode", e.target.value)
                    }
                    hasError={errors["pinCode"] ? true : false}
                    type="text"
                    value={formData.pinCode}
                    label="Zip Code"
                    placeholder="Zip Code"
                  />
                  <InputBlock
                    //mobileISD
                    //ISDplaceholder="ISD"
                    onChange={(e) =>
                      this.handleOnChange("mobile", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleErrorForEmailPhone("mobile", e.target.value)
                    }
                    onKeyPress={handleMobileNumber}
                    value={formData.mobile}
                    hasError={errors["mobile"] ? true : false}
                    type="number"
                    // min="6"
                    label="Mobile No *"
                    placeholder="Mobile No "
                  />
                  <InputBlock
                    //mobileISD
                    //mobileSTD
                    //ISDplaceholder="ISD"
                    //STDplaceholder="STD"
                    onChange={(e) =>
                      this.handleOnChange("phone1", e.target.value)
                    }
                    onKeyPress={handleMobileNumber}
                    value={formData.phone1}
                    hasError={errors["phone1"] ? true : false}
                    type="number"
                    label="Phone No 1"
                    placeholder="Phone No 1"
                  />
                  <InputBlock
                    //mobileISD
                    //mobileSTD
                    //ISDplaceholder="ISD"
                    //STDplaceholder="STD"
                    onChange={(e) =>
                      this.handleOnChange("phone2", e.target.value)
                    }
                    onKeyPress={handleMobileNumber}
                    value={formData.phone2}
                    hasError={errors["phone2"] ? true : false}
                    type="number"
                    label="Phone No 2"
                    placeholder="Phone No 2"
                  />
                </div>
              </div>
              <div id="section_3">
                <Heading
                  title="Business Information"
                  className="popupInnerTitle p-0"
                />
                <div className="registerFiledBlock">
                  <InputBlock
                    type="text"
                    label="Name Of Business *"
                    placeholder="Name Of Business"
                    onChange={(e) =>
                      this.handleOnChange("companyName", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("companyName", e.target.value)
                    }
                    value={formData.companyName}
                    hasError={errors["companyName"] ? true : false}
                  />
                  <InputBlock
                    type="text"
                    label="Website"
                    placeholder="Website"
                    onChange={(e) =>
                      this.handleOnChange("Website", e.target.value)
                    }
                    value={formData.Website}
                    hasError={errors["Website"] ? true : false}
                  />
                  <InputBlock
                    type="text"
                    label="Legal Name *"
                    placeholder="Legal Name"
                    onChange={(e) =>
                      this.handleOnChange("legalName", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("legalName", e.target.value)
                    }
                    value={formData.legalName}
                    hasError={errors["legalName"] ? true : false}
                  />
                  <InputBlock
                    type="text"
                    label="Owner *"
                    placeholder="Owner"
                    onChange={(e) =>
                      this.handleOnChange("owner", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("owner", e.target.value)
                    }
                    value={formData.owner}
                    hasError={errors["owner"] ? true : false}
                  />
                  <InputBlock
                    type="text"
                    label="Address [Billing] *"
                    placeholder="Billing Address"
                    onChange={(e) =>
                      this.handleOnChange("billingAddress", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("billingAddress", e.target.value)
                    }
                    value={formData.billingAddress}
                    hasError={errors["billingAddress"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="Country"
                    capitalize
                    value={
                      !isEmpty(formData.businessCountry)
                        ? upperCase(formData.businessCountry.name)
                        : "Country"
                    }
                    selectValue={countries}
                    // disabled={formData.registrationType === 2 ? true : false}
                    onChange={(e) =>
                      this.handleCountryChange(
                        e,
                        "businessCountry",
                        "businessState",
                        "businessCity",
                      )
                    }
                    label="Select Country*"
                    showSearch
                    hasError={errors["businessCountry"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="State"
                    capitalize
                    value={
                      !isEmpty(formData.businessState)
                        ? upperCase(formData.businessState.name)
                        : "State"
                    }
                    selectValue={states}
                    onChange={(e) =>
                      this.handleStateChange(e, "businessState", "businessCity")
                    }
                    label="Select State *"
                    showSearch
                    hasError={errors["businessState"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="City"
                    capitalize
                    value={
                      !isEmpty(formData.businessCity)
                        ? upperCase(formData.businessCity.name)
                        : "City"
                    }
                    selectValue={cities}
                    onChange={(e) => this.handleCityChange(e, "businessCity")}
                    showSearch
                    label="Select City*"
                    hasError={errors["businessCity"] ? true : false}
                  />
                  {this.state.formData?.businessCity?.id === "-" && (
                    <InputBlock
                      onChange={(e) =>
                        this.handleOnChange("businessCityText", e.target.value)
                      }
                      hasError={errors["businessCityText"] ? true : false}
                      type="text"
                      value={formData.businessCityText}
                      label="City*"
                      placeholder="City"
                    />
                  )}
                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("businessPinCode", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError(
                        "businessPinCode",
                        e.target.value,
                      )
                    }
                    hasError={errors["businessPinCode"] ? true : false}
                    type="text"
                    value={formData.businessPinCode}
                    label="Zip Code *"
                    placeholder="Zip Code"
                  />

                  <InputBlock
                    type="text"
                    label="Address [Shipping] *"
                    placeholder="Shipping Address"
                    onChange={(e) =>
                      this.handleOnChange("shippingAddress", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError(
                        "shippingAddress",
                        e.target.value,
                      )
                    }
                    value={formData.shippingAddress}
                    hasError={errors["shippingAddress"] ? true : false}
                  />

                  <SelectOption
                    defaultValue="Shipping Country"
                    capitalize
                    value={
                      !isEmpty(formData.businessShippingCountry)
                        ? upperCase(formData.businessShippingCountry.name)
                        : "Shipping Country"
                    }
                    selectValue={countries}
                    // disabled={formData.registrationType === 2 ? true : false}
                    onChange={(e) =>
                      this.handleCountryChange(
                        e,
                        "businessShippingCountry",
                        "businessShippingState",
                        "businessShippingCity",
                      )
                    }
                    label="Select Shipping Country*"
                    showSearch
                    hasError={errors["businessShippingCountry"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="Shipping State"
                    capitalize
                    value={
                      !isEmpty(formData.businessShippingState)
                        ? upperCase(formData.businessShippingState.name)
                        : "Shipping State"
                    }
                    selectValue={states}
                    onChange={(e) =>
                      this.handleStateChange(
                        e,
                        "businessShippingState",
                        "businessShippingCity",
                      )
                    }
                    label="Select Shipping State *"
                    showSearch
                    hasError={errors["businessShippingState"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="Shipping City"
                    capitalize
                    value={
                      !isEmpty(formData.businessShippingCity)
                        ? upperCase(formData.businessShippingCity.name)
                        : "City"
                    }
                    selectValue={cities}
                    onChange={(e) =>
                      this.handleCityChange(e, "businessShippingCity")
                    }
                    showSearch
                    label="Select Shipping City*"
                    hasError={errors["businessShippingCity"] ? true : false}
                  />
                  {this.state.formData?.businessShippingCity?.id === "-" && (
                    <InputBlock
                      onChange={(e) =>
                        this.handleOnChange(
                          "businessShippingCityText",
                          e.target.value,
                        )
                      }
                      hasError={
                        errors["businessShippingCityText"] ? true : false
                      }
                      type="text"
                      value={formData.businessShippingCityText}
                      label="Shipping City*"
                      placeholder="Shipping City"
                    />
                  )}
                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange(
                        "businessShippingPinCode",
                        e.target.value,
                      )
                    }
                    onBlur={(e) =>
                      this.handleRequiredError(
                        "businessShippingPinCode",
                        e.target.value,
                      )
                    }
                    hasError={errors["businessShippingPinCode"] ? true : false}
                    type="text"
                    value={formData.businessShippingPinCode}
                    label="Shipping Zip Code *"
                    placeholder="Shipping Zip Code"
                  />
                  <InputBlock
                    //mobileISD
                    //mobileSTD
                    //ISDplaceholder="ISD"
                    //STDplaceholder="STD"
                    onChange={(e) =>
                      this.handleOnChange("businessPhone1", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleErrorForEmailPhone(
                        "businessPhone1",
                        e.target.value,
                      )
                    }
                    onKeyPress={handleMobileNumber}
                    value={formData.businessPhone1}
                    hasError={errors["businessPhone1"] ? true : false}
                    type="number"
                    label="Phone *"
                    placeholder="Phone"
                  />
                  <InputBlock
                    //mobileISD
                    //mobileSTD
                    //ISDplaceholder="ISD"
                    //STDplaceholder="STD"
                    onChange={(e) => this.handleOnChange("fax", e.target.value)}
                    value={formData.fax}
                    type="number"
                    label="Fax No"
                    placeholder="Fax No"
                    hasError={errors["fax"] ? true : false}
                  />
                  <InputBlock
                    //mobileISD
                    //ISDplaceholder="ISD"
                    onChange={(e) =>
                      this.handleOnChange("businessMobile", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleErrorForEmailPhone(
                        "businessMobile",
                        e.target.value,
                      )
                    }
                    onKeyPress={handleMobileNumber}
                    value={formData.businessMobile}
                    hasError={errors["businessMobile"] ? true : false}
                    type="number"
                    min="6"
                    label="Mobile No *"
                    placeholder="Mobile No "
                  />
                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("businessEmail", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleErrorForEmailPhone(
                        "businessEmail",
                        e.target.value,
                      )
                    }
                    hasError={errors["businessEmail"] ? true : false}
                    value={formData.businessEmail}
                    type="email"
                    label="Email *"
                    placeholder="Email"
                  />
                  <InputBlock
                    type="text"
                    label="Facebook Username"
                    placeholder="Facebook Username"
                    onChange={(e) =>
                      this.handleOnChange("facebookUserName", e.target.value)
                    }
                    value={formData.facebookUserName}
                    hasError={errors["facebookUserName"] ? true : false}
                  />
                  <InputBlock
                    type="text"
                    label="Instagram Handle"
                    placeholder="Instagram Handle"
                    onChange={(e) =>
                      this.handleOnChange("instagramId", e.target.value)
                    }
                    value={formData.instagramId}
                    hasError={errors["instagramId"] ? true : false}
                  />
                  <InputBlock
                    type="text"
                    label="LinkedIn ID"
                    placeholder="LinkedIn ID"
                    onChange={(e) =>
                      this.handleOnChange("linkedInId", e.target.value)
                    }
                    value={formData.linkedInId}
                    hasError={errors["linkedInId"] ? true : false}
                  />
                  <SelectOption
                    // mode="multiple"
                    // className="multiSelect"
                    placeholder="Type Of Business"
                    value={
                      isEmpty(formData.businessType)
                        ? undefined
                        : find(this.state.NATURE_OF_ORG, {
                            id: formData.businessType,
                          }).name
                    }
                    selectValue={this.state.NATURE_OF_ORG}
                    onChange={(e) => {
                      this.handleOnChange("businessType", e);
                    }}
                    label="Type Of Business *"
                    hasError={errors["businessType"] ? true : false}
                  />
                </div>
              </div>
              <div id="section_4">
                <Heading
                  title="Group Affiliations"
                  className="popupInnerTitle p-0"
                />
                <div className="registerFiledBlock">
                  <SelectOption
                    placeholder="Legal Status Of Organization"
                    // defaultValue={<IntlMessages id="app.BusinessType" />}
                    value={
                      isEmpty(formData.legalStatusOfOrg)
                        ? undefined
                        : find(this.state.BUSINESS_TYPE, {
                            id: formData.legalStatusOfOrg,
                          }).name
                    }
                    selectValue={this.state.BUSINESS_TYPE}
                    onChange={(e) => {
                      this.handleOnChange("legalStatusOfOrg", e);
                    }}
                    label={"Legal Status Of Organization *"} //{<IntlMessages id="app.SelectBusinessType" />}
                    hasError={errors["legalStatusOfOrg"] ? true : false}
                  />
                  {formData.registrationType !==
                    REGISTRATION_TYPES_DATA.INTERNATIONAL && (
                    <InputBlock
                      type="text"
                      label="State Of Registration/Incorporation *"
                      placeholder="State Of Registration/Incorporation"
                      onChange={(e) =>
                        this.handleOnChange(
                          "stateOfRegistration",
                          e.target.value,
                        )
                      }
                      value={formData.stateOfRegistration}
                      hasError={errors["stateOfRegistration"] ? true : false}
                    />
                  )}
                  <InputBlock
                    type="text"
                    label="Year Established *"
                    placeholder="Year Established"
                    onChange={(e) =>
                      this.handleOnChange("yearEstablished", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError(
                        "yearEstablished",
                        e.target.value,
                      )
                    }
                    value={formData.yearEstablished}
                    hasError={errors["yearEstablished"] ? true : false}
                  />
                  <div className="from-group radio-group">
                    <label className="commonLabel">Incorporated</label>
                    <Radio.Group
                      value={formData.isIncorporated}
                      onChange={(e) =>
                        this.handleOnChange("isIncorporated", e.target.value)
                      }
                      className="mt-10"
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </div>
                  <InputBlock
                    type="text"
                    label="Years at Present Location *"
                    placeholder="Years at Present Location"
                    onChange={(e) =>
                      this.handleOnChange(
                        "yearsAtPresentLocation",
                        e.target.value,
                      )
                    }
                    onBlur={(e) =>
                      this.handleRequiredError(
                        "yearsAtPresentLocation",
                        e.target.value,
                      )
                    }
                    value={formData.yearsAtPresentLocation}
                    hasError={errors["yearsAtPresentLocation"] ? true : false}
                  />
                  <InputBlock
                    type="text"
                    label="Number of Employees *"
                    placeholder="Number of Employees"
                    onChange={(e) =>
                      this.handleOnChange("noOfEmployee", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("noOfEmployee", e.target.value)
                    }
                    value={formData.noOfEmployee}
                    hasError={errors["noOfEmployee"] ? true : false}
                  />
                  <InputBlock
                    type="text"
                    label="Fed Tax ID/Social Security *"
                    placeholder="Fed Tax ID/Social Security"
                    onChange={(e) =>
                      this.handleOnChange("fedTaxId", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("fedTaxId", e.target.value)
                    }
                    value={formData.fedTaxId}
                    hasError={errors["fedTaxId"] ? true : false}
                  />
                  {formData.registrationType !==
                    REGISTRATION_TYPES_DATA.INTERNATIONAL && (
                    <InputBlock
                      type="text"
                      label="Resale Tax *"
                      placeholder="Resale Tax"
                      onChange={(e) =>
                        this.handleOnChange("resaleTax", e.target.value)
                      }
                      value={formData.resaleTax}
                      hasError={errors["resaleTax"] ? true : false}
                    />
                  )}
                  {formData.registrationType !==
                    REGISTRATION_TYPES_DATA.INTERNATIONAL && (
                    <InputBlock
                      type="text"
                      label="JBT ID"
                      placeholder="JBT ID"
                      onChange={(e) =>
                        this.handleOnChange("JBTId", e.target.value)
                      }
                      value={formData.JBTId}
                      hasError={errors["JBTId"] ? true : false}
                    />
                  )}
                  <div className="from-group radio-group">
                    <label className="commonLabel">
                      Have you established an Anti-Money Laundering [AML]
                      Program, in accordance to USA Patrior Act?
                      {formData.registrationType ===
                      REGISTRATION_TYPES_DATA.NORTH_AMERICA
                        ? "*"
                        : ""}
                    </label>
                    <Radio.Group
                      value={formData.isAML}
                      onChange={(e) =>
                        this.handleOnChange("isAML", e.target.value)
                      }
                      className="mt-10"
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </div>
                  {!formData.isAML && (
                    <InputBlock
                      type="text"
                      label={`Why? (Please Explain) ${
                        formData.registrationType !==
                        REGISTRATION_TYPES_DATA.INTERNATIONAL
                          ? "*"
                          : ""
                      }`}
                      placeholder="Why? (Please Explain)"
                      onChange={(e) =>
                        this.handleOnChange("amlExplaination", e.target.value)
                      }
                      value={formData.amlExplaination}
                      hasError={errors["amlExplaination"] ? true : false}
                    />
                  )}
                  <div className="from-group radio-group">
                    <label className="commonLabel">
                      Have you or any of your affiliates ever had credit with us
                      before or purchased from us before?
                    </label>
                    <Radio.Group
                      value={formData.isCreditTime}
                      onChange={(e) =>
                        this.handleOnChange("isCreditTime", e.target.value)
                      }
                      className="mt-10"
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </div>
                  {formData.isCreditTime && (
                    <InputBlock
                      type="text"
                      label={`Under What name? ${
                        formData.isCreditTime ? "*" : ""
                      }`}
                      placeholder="Under What name?"
                      onChange={(e) =>
                        this.handleOnChange("underWhatName", e.target.value)
                      }
                      value={formData.underWhatName}
                      hasError={errors["underWhatName"] ? true : false}
                    />
                  )}
                </div>
              </div>
              <div id="section_5">
                <Heading
                  title="Owners Details"
                  className="popupInnerTitle p-0"
                />
                <div className="registerFiledBlock">
                  <InputBlock
                    type="text"
                    label="Name"
                    placeholder="Name"
                    onChange={(e) =>
                      this.handleOnChange("ownerName", e.target.value)
                    }
                    value={formData.ownerName}
                    hasError={errors["ownerName"] ? true : false}
                  />
                  <InputBlock
                    type="text"
                    label="Designation *"
                    placeholder="Designation"
                    onChange={(e) =>
                      this.handleOnChange("ownerDesignation", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError(
                        "ownerDesignation",
                        e.target.value,
                      )
                    }
                    value={formData.ownerDesignation}
                    hasError={errors["ownerDesignation"] ? true : false}
                  />
                  <InputBlock
                    //mobileISD
                    //ISDplaceholder="ISD"
                    onChange={(e) =>
                      this.handleOnChange("ownerMobile", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleErrorForEmailPhone(
                        "ownerMobile",
                        e.target.value,
                      )
                    }
                    onKeyPress={handleMobileNumber}
                    value={formData.ownerMobile}
                    hasError={errors["ownerMobile"] ? true : false}
                    type="number"
                    min="6"
                    label="Mobile No"
                    placeholder="Mobile No "
                  />
                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("ownerEmail", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleErrorForEmailPhone(
                        "ownerEmail",
                        e.target.value,
                      )
                    }
                    hasError={errors["ownerEmail"] ? true : false}
                    value={formData.ownerEmail}
                    type="email"
                    label="Email"
                    placeholder="Email"
                  />
                  <InputBlock
                    type="text"
                    label="Social Security"
                    placeholder="Social Security"
                    onChange={(e) =>
                      this.handleOnChange("ownerSocialSecurity", e.target.value)
                    }
                    value={formData.ownerSocialSecurity}
                    hasError={errors["ownerSocialSecurity"] ? true : false}
                  />
                  {/* one field is remain for credit */}
                  <div>
                    <CheckBox
                      label={
                        <span>
                          <span>Credit </span>
                        </span>
                      }
                      onChange={() =>
                        this.handleOnChange("isCredit", !formData?.isCredit)
                      }
                      checked={formData?.isCredit}
                    />
                    {/* <CheckBox
                      label={
                        <span>
                          <span>No Credit </span>
                        </span>
                      }
                      checked={formData.registrationType === 2 ? true : false}
                    /> */}
                  </div>
                </div>
              </div>
              <div id="section_6">
                <Heading
                  title="Trade References"
                  className="popupInnerTitle p-0"
                />
                {!isEmpty(this.state.tradeRefInfo) && (
                  <PrintTable
                    data={this.state.tradeRefInfo}
                    onDelete={this.deleteDocument}
                    onUpdate={(data) => this.updateDocument(data)}
                  />
                )}
                <div className="registerFiledBlock">
                  <InputBlock
                    type="text"
                    label={`Company Name ${
                      formData.registrationType ===
                      REGISTRATION_TYPES_DATA.NORTH_AMERICA
                        ? "*"
                        : ""
                    } `}
                    placeholder="Company Name"
                    onChange={(e) =>
                      this.handleOnChange("tradeRefCompanyName", e.target.value)
                    }
                    value={formData.tradeRefCompanyName}
                    hasError={errors["tradeRefCompanyName"] ? true : false}
                  />
                  <InputBlock
                    type="text"
                    label={`Address ${
                      formData.registrationType ===
                      REGISTRATION_TYPES_DATA.NORTH_AMERICA
                        ? "*"
                        : ""
                    }`}
                    placeholder="Address"
                    onChange={(e) =>
                      this.handleOnChange("tradeRefAddress", e.target.value)
                    }
                    value={formData.tradeRefAddress}
                    hasError={errors["tradeRefAddress"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="Country"
                    capitalize
                    value={
                      !isEmpty(formData.tradeRefCountry)
                        ? upperCase(formData.tradeRefCountry.name)
                        : "Country"
                    }
                    selectValue={countries}
                    // disabled={formData.registrationType === 2 ? true : false}
                    onChange={(e) =>
                      this.handleCountryChange(
                        e,
                        "tradeRefCountry",
                        "tradeRefState",
                        "tradeRefCity",
                      )
                    }
                    label={`Select Country ${
                      formData.registrationType ===
                      REGISTRATION_TYPES_DATA.NORTH_AMERICA
                        ? "*"
                        : ""
                    }`}
                    showSearch
                    hasError={errors["tradeRefCountry"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="State"
                    capitalize
                    value={
                      !isEmpty(formData.tradeRefState)
                        ? upperCase(formData.tradeRefState.name)
                        : "State"
                    }
                    selectValue={states}
                    onChange={(e) =>
                      this.handleStateChange(e, "tradeRefState", "tradeRefCity")
                    }
                    label={`Select State ${
                      formData.registrationType ===
                      REGISTRATION_TYPES_DATA.NORTH_AMERICA
                        ? "*"
                        : ""
                    }`}
                    showSearch
                    hasError={errors["tradeRefState"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="City"
                    capitalize
                    value={
                      !isEmpty(formData.tradeRefCity)
                        ? upperCase(formData.tradeRefCity.name)
                        : "City"
                    }
                    selectValue={cities}
                    onChange={(e) => this.handleCityChange(e, "tradeRefCity")}
                    showSearch
                    label={`Select City ${
                      formData.registrationType ===
                      REGISTRATION_TYPES_DATA.NORTH_AMERICA
                        ? "*"
                        : ""
                    }`}
                    hasError={errors["tradeRefCity"] ? true : false}
                  />
                  {/* change of city is remain */}
                  {formData?.tradeRefCity?.id === "-" && (
                    <InputBlock
                      onChange={(e) =>
                        this.handleOnChange("tradeRefCityText", e.target.value)
                      }
                      hasError={errors["tradeRefCityText"] ? true : false}
                      type="text"
                      value={formData.tradeRefCityText}
                      label="City*"
                      placeholder="City"
                    />
                  )}
                  <InputBlock
                    //mobileISD
                    //ISDplaceholder="ISD"
                    onChange={(e) =>
                      this.handleOnChange("tradeRefMobile", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleErrorForEmailPhone(
                        "tradeRefMobile",
                        e.target.value,
                      )
                    }
                    onKeyPress={handleMobileNumber}
                    value={formData.tradeRefMobile}
                    hasError={errors["tradeRefMobile"] ? true : false}
                    type="number"
                    min="6"
                    label={`Mobile No. *`}
                    placeholder="Mobile No *"
                  />
                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("tradeRefEmail", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleErrorForEmailPhone(
                        "tradeRefEmail",
                        e.target.value,
                      )
                    }
                    hasError={errors["tradeRefEmail"] ? true : false}
                    value={formData.tradeRefEmail}
                    type="email"
                    label={`Email *`}
                    placeholder="Email *"
                  />
                  <InputBlock
                    type="text"
                    label={`Contact Name *`}
                    placeholder="Contact Name *"
                    onChange={(e) =>
                      this.handleOnChange("tradeRefContactName", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError(
                        "tradeRefContactName",
                        e.target.value,
                      )
                    }
                    hasError={errors["tradeRefContactName"] ? true : false}
                    value={formData.tradeRefContactName}
                  />
                  <div
                    className="from-group"
                    style={{ textAlign: "start", alignSelf: "center" }}
                  >
                    <button
                      className="commonButton"
                      onClick={(e) => this.handleTraderef(e)}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
              <div id="section_7">
                <Heading
                  title="Bank References"
                  className="popupInnerTitle p-0"
                />
                <div className="registerFiledBlock">
                  <InputBlock
                    type="text"
                    label="Bank Name *"
                    placeholder="Bank Name"
                    onChange={(e) =>
                      this.handleOnChange("bankName", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("bankName", e.target.value)
                    }
                    value={formData.bankName}
                    hasError={errors["bankName"] ? true : false}
                  />
                  <InputBlock
                    type="number"
                    label="Account *"
                    placeholder="Account"
                    onChange={(e) =>
                      this.handleOnChange("account", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("account", e.target.value)
                    }
                    value={formData.account}
                    hasError={errors["account"] ? true : false}
                  />
                  <InputBlock
                    type="text"
                    label="Branch Address *"
                    placeholder="Branch Address"
                    onChange={(e) =>
                      this.handleOnChange("branchAddress", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("branchAddress", e.target.value)
                    }
                    value={formData.branchAddress}
                    hasError={errors["branchAddress"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="Country"
                    capitalize
                    value={
                      !isEmpty(formData.branchCountry)
                        ? upperCase(formData.branchCountry.name)
                        : "Country"
                    }
                    selectValue={countries}
                    // disabled={formData.registrationType === 2 ? true : false}
                    onChange={(e) =>
                      this.handleCountryChange(
                        e,
                        "branchCountry",
                        "branchState",
                        "branchCity",
                      )
                    }
                    label="Select Country*"
                    showSearch
                    hasError={errors["branchCountry"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="State"
                    capitalize
                    value={
                      !isEmpty(formData.branchState)
                        ? upperCase(formData.branchState.name)
                        : "State"
                    }
                    selectValue={states}
                    onChange={(e) =>
                      this.handleStateChange(e, "branchState", "branchCity")
                    }
                    label="Select State *"
                    showSearch
                    hasError={errors["branchState"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="City"
                    capitalize
                    value={
                      !isEmpty(formData.branchCity)
                        ? upperCase(formData.branchCity.name)
                        : "City"
                    }
                    selectValue={cities}
                    onChange={(e) => this.handleCityChange(e, "branchCity")}
                    showSearch
                    label="Select City*"
                    hasError={errors["branchCity"] ? true : false}
                  />
                  {this.state.formData?.branchCity?.id === "-" && (
                    <InputBlock
                      onChange={(e) =>
                        this.handleOnChange("branchCityText", e.target.value)
                      }
                      hasError={errors["branchCityText"] ? true : false}
                      type="text"
                      value={formData.branchCityText}
                      label="City*"
                      placeholder="City"
                    />
                  )}

                  <InputBlock
                    type="text"
                    label="Contact Person *"
                    placeholder="Contact Person"
                    onChange={(e) =>
                      this.handleOnChange("contactPerson", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("contactPerson", e.target.value)
                    }
                    value={formData.contactPerson}
                    hasError={errors["contactPerson"] ? true : false}
                  />
                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("bankRefPinCode", e.target.value)
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("bankRefPinCode", e.target.value)
                    }
                    hasError={errors["bankRefPinCode"] ? true : false}
                    type="text"
                    value={formData.bankRefPinCode}
                    label="Zip Code *"
                    placeholder="Zip Code"
                  />
                  <InputBlock
                    //mobileISD
                    //mobileSTD
                    //ISDplaceholder="ISD"
                    //STDplaceholder="STD"
                    onChange={(e) =>
                      this.handleOnChange("bankRefPhone", e.target.value)
                    }
                    onKeyPress={handleMobileNumber}
                    value={formData.bankRefPhone}
                    hasError={errors["bankRefPhone"] ? true : false}
                    type="number"
                    label="Phone"
                    placeholder="Phone"
                  />
                  <InputBlock
                    //mobileISD
                    //mobileSTD
                    //ISDplaceholder="ISD"
                    //STDplaceholder="STD"
                    onChange={(e) =>
                      this.handleOnChange("bankRefFax", e.target.value)
                    }
                    value={formData.bankRefFax}
                    type="number"
                    label="Fax No"
                    placeholder="Fax No"
                    hasError={errors["bankRefFax"] ? true : false}
                  />
                  <div className="from-group radio-group">
                    <label className="commonLabel">
                      Have you ever filed a petition in bankruptcy? *
                    </label>
                    <Radio.Group
                      value={formData.isBankruptcy}
                      onChange={(e) =>
                        this.handleOnChange("isBankruptcy", e.target.value)
                      }
                      className="mt-10"
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </div>
                  {formData.isBankruptcy && (
                    <div className="from-group">
                      <InputBlock
                        label="When ? *"
                        placeholder="When ? "
                        type="Date"
                        value={formData.bankruptcyDate}
                        onChange={(e) =>
                          this.handleOnChange("bankruptcyDate", e.target.value)
                        }
                        // onBlur={handleBlur}
                        hasError={errors["bankruptcyDate"] ? true : false}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div id="section_8">
                <Heading
                  title="Broker Information"
                  className="popupInnerTitle p-0"
                />
                <div className="registerFiledBlock">
                  <div className="from-group radio-group">
                    <label className="commonLabel">Broker</label>
                    <Radio.Group
                      value={formData.broker}
                      onChange={(e) =>
                        this.handleOnChange("broker", e.target.value)
                      }
                      className="mt-10"
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </div>
                  {formData.broker && (
                    <>
                      <InputBlock
                        type="text"
                        label="Broker Name *"
                        placeholder="Broker Name"
                        onChange={(e) =>
                          this.handleOnChange("brokerName", e.target.value)
                        }
                        hasError={errors["brokerName"] ? true : false}
                        value={formData.brokerName}
                      />
                      <InputBlock
                        //mobileISD
                        //ISDplaceholder="ISD"
                        type="number"
                        label="Mobile No *"
                        placeholder="Mobile No "
                        onChange={(e) =>
                          this.handleOnChange("brokerMobile", e.target.value)
                        }
                        onBlur={(e) =>
                          this.handleErrorForEmailPhone(
                            "brokerMobile",
                            e.target.value,
                          )
                        }
                        onKeyPress={handleMobileNumber}
                        hasError={errors["brokerMobile"] ? true : false}
                        value={formData.brokerMobile}
                      />
                      <InputBlock
                        //mobileISD
                        //mobileSTD
                        //ISDplaceholder="ISD"
                        //STDplaceholder="STD"
                        type="number"
                        label="Phone No"
                        placeholder="Phone No"
                        onKeyPress={handleMobileNumber}
                        onChange={(e) =>
                          this.handleOnChange("brokerPhoneNo", e.target.value)
                        }
                        hasError={errors["brokerPhoneNo"] ? true : false}
                        value={formData.brokerPhoneNo}
                      />
                      <InputBlock
                        type="email"
                        label="Email *"
                        placeholder="Email"
                        onChange={(e) =>
                          this.handleOnChange("brokerEmail", e.target.value)
                        }
                        onBlur={(e) =>
                          this.handleErrorForEmailPhone(
                            "brokerEmail",
                            e.target.value,
                          )
                        }
                        hasError={errors["brokerEmail"] ? true : false}
                        value={formData.brokerEmail}
                      />
                    </>
                  )}
                </div>
              </div>
              <div>
                <CheckBox
                  checked={formData.isTermsCondAgree}
                  onChange={(e) =>
                    this.handleOnChange("isTermsCondAgree", e.target.checked)
                  }
                  label={
                    <span>
                      <span>I have read and accept </span>
                      <a className="linkTag" onClick={this.TermPopup}>
                        Terms and Conditions.
                      </a>
                    </span>
                  }
                />
              </div>
              <Drawer
                title={false}
                onClose={this.onClose}
                visible={this.state.visible}
                className="diamondListinSidebar"
              >
                <TermsCondition />
              </Drawer>
              {/* Register and Reset buttons */}
              <div className="mt-30 loginButton">
                <button
                  style={
                    !formData.isTermsCondAgree ? { cursor: "not-allowed" } : {}
                  }
                  // disabled={this.state.registerButton}
                  // type="submit"
                  onClick={(e) => this.handleSignup(e)}
                  disabled={!formData.isTermsCondAgree}
                  className="commonButton"
                >
                  <IntlMessages id="app.signup.registerButton" />
                </button>
                <button
                  onClick={(e) => this.handleCancel(e)}
                  className="commonOutline"
                >
                  Reset
                </button>
              </div>
            </Form>
            <div className="registerLogLink">
              <IntlMessages id="app.already-have-acc" />
              &nbsp;
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (this.state.isDeviceUrl) {
                    // if (navigator.appVersion.includes("iPhone")) {
                    window.LoginCallBack.postMessage("LoginCallBack");
                    // window.webkit.messageHandlers.LoginCallBack.postMessage("LoginCallBack");
                    // } else if (navigator.appVersion.includes("Android")) {
                    // window.webkit.LoginCallBack("LoginCallBack");
                    // }
                  } else {
                    window.location.href = "/";
                  }
                }}
              >
                <IntlMessages id="app.sign-In" />
              </a>
              {/* |<a href="/kyc-form">Join Belgium Group</a> */}
            </div>
          </div>
          <DownloadBlock />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (props) => {
  return {
    ...props,
    loading: props.commonData.loading,
  };
};

export default withRouter(connect(mapStateToProps)(SignUpForm));
