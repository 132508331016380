/* eslint-disable */
import React from "react";
import CommonModal from "../CommonModal";
import UtilService from "../../../services/util";
import _ from "lodash";
import { getProjectSettingList } from "components/DiamondList/DiamondListFunctions";
import { PROJECT_SETTINGS_TYPE } from "constants/Common";
import isEmpty from "lodash/isEmpty";

const bidModal = (props) => {
  let bs = "";
  const blockSettings = UtilService.getLocalStorageItem("popUpValidationMsg");
  if (isEmpty(blockSettings)) {
    getProjectSettingList(
      PROJECT_SETTINGS_TYPE.POP_UP_VALIDATION_MESSAGES,
      (data) => {
        UtilService.setLocalStorageItem(
          data?.data.find(
            ({ type }) =>
              type === PROJECT_SETTINGS_TYPE.POP_UP_VALIDATION_MESSAGES,
          )?.data,
          "bidPopUpValidationMsg",
        );
      },
    );
    bs = UtilService.getLocalStorageItem("bidPopUpValidationMsg");
  }
  bs && window.location.reload();

  let CONFIRM = blockSettings?.["DEFAULT"];

  return (
    <CommonModal
      title="Bid"
      submitTitle="OK"
      visible={props.visible}
      closable={false}
      handleOk={props.handleOk}
      handleCancel={false}
      footerShow
    >
      <div className="detailShow d-flex flex-wrap">
        <div className="detail w-100">
          <span>
            <p dangerouslySetInnerHTML={{ __html: CONFIRM }}></p>
          </span>
        </div>
      </div>
    </CommonModal>
  );
};

export default bidModal;
