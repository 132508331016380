/* eslint-disable */
import React, { useEffect, useState } from "react";
import HeaderAccountList from "../../common/HeaderAccountList";
import { withRouter, Link, useHistory } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import {
  trackTypeObj,
  commonLogout,
  LOCAL_STORAGE_VAR,
  BASE_URL,
} from "../../../constants/Common";
import UtilService from "../../../services/util";
import omit from "lodash/omit";
import { GET_DASHBOARD_DATA } from "../../../constants/apiConstant";
import DefaultUser from "../../common/UserProfileDefault";
import HeaderAccountDetails from "../../common/HeaderAccountList/HeaderAccountDetails";
import { get } from "lodash";

const MobileAccount = (props) => {
  let history = useHistory();
  const [details, setDetails] = useState({});
  const [counts, setCounts] = useState({});
  const { name, mobile, countryCode, profileImage } =
    UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
  const USER_PERMIT = UtilService.getLocalStorageItem(
    `${LOCAL_STORAGE_VAR}-userPermissions`,
  );
  const HeaderLinks = [
    get(USER_PERMIT, "CART.view", false) && {
      link: "/account/cart",
      title: <IntlMessages id="app.my-cart" />,
      details: details[trackTypeObj["CART"]],
      basicDetailnull: true,
    },
    get(USER_PERMIT, "WATCHLIST.view", false) && {
      link: "/account/watchlist",
      title: <IntlMessages id="app.my-watchlist" />,
      details: details[trackTypeObj["WATCHLIST"]],
      basicDetailnull: true,
    },
    // get(USER_PERMIT, 'OFFICE.view', false) && {
    //   link: '/account/office-list',
    //   title: <IntlMessages id="app.office-list" />,
    //   basicDetailnull: true,
    // },
    // get(USER_PERMIT, 'QUOTE.view', false) && {
    //   link: '/account/quote-list',
    //   title: <IntlMessages id="app.quote-list" />,
    //   details: details[trackTypeObj['QUOTE']],
    //   basicDetailnull: true,
    // },
    get(USER_PERMIT, "NOTES.view", false) && {
      link: "/account/notes",
      title: <IntlMessages id="app.my-comment" />,
      details: details[trackTypeObj.COMMENT],
      basicDetailnull: true,
    },
    get(USER_PERMIT, "CONFIRM_STONE.view", false) && {
      link: "/account/confirm-list",
      title: <IntlMessages id="app.confirm-list" />,
      details:
        counts.orderData && counts.orderData.order
          ? counts.orderData.order
          : {},
      basicDetailnull: true,
    },
    // get(USER_PERMIT, 'MY_BID.view', false) && {
    //   link: '/my-bid',
    //   title: <IntlMessages id="app.myBid" />,
    //   basicDetailnull: true,
    // },
    get(USER_PERMIT, "SAVE_SEARCH.view", false) && {
      link: "/account/saved-search",
      title: <IntlMessages id="app.saved-search" />,
      basicDetailnull: true,
    },
    get(USER_PERMIT, "DEMAND.view", false) && {
      link: "/account/my-demand",
      title: <IntlMessages id="app.my-demand-tab" />,
      basicDetailnull: true,
    },
    get(USER_PERMIT, "NOTIFICATIONS.view", false) && {
      link: "/account/notifications",
      title: <IntlMessages id="app.Notification" />,
      basicDetailnull: true,
    },
  ];

  useEffect(() => {
    let obj = {
      savedSearch: true,
      recentSearch: true,
      track: true,
      orderData: true,
      seller: true,
      account: true,
      shippingCount: true,
      dashboardCount: true,
    };
    let api = GET_DASHBOARD_DATA.url;
    let objData = {
      method: GET_DASHBOARD_DATA.method,
      url: api,
      request: obj,
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === "OK") {
        setDetails(data.data.tracks);
        setCounts(omit(data.data, "tracks"));
      }
    });
  }, []);

  return (
    <div>
      <div className="accountWrapper">
        <Link className="accountTopBlock" to="/account/personal-information">
          <div className="accountUser">
            {profileImage ? (
              <img src={`${BASE_URL}/${profileImage}`} alt="" />
            ) : (
              <DefaultUser />
            )}
            <div className="userRightDetail">
              <h2 className="mobileUserTitle">{name}</h2>
              <p>
                +{countryCode} {mobile}
              </p>
            </div>
          </div>
        </Link>
        <div className="mobileAccountBlock">
          <div className="mobileAccountList userDetailList">
            <h2>
              <IntlMessages id="app.YourAccount" />
            </h2>
            <ul>
              {HeaderLinks.map((x) => {
                if (x) {
                  return <HeaderAccountList history={history} {...x} />;
                }
              })}
              {/* <HeaderAccountDetails isHovered={true} headerLinks={HeaderLinks} history={history} /> */}
            </ul>
          </div>
          <div className="mobileAccountList">
            <h2>
              <IntlMessages id="app.ManageAccount" />
            </h2>
            <ul>
              <li>
                <Link to="/account/personal-information">
                  <IntlMessages id="app.PersonalInformation" />
                  <img
                    src={require("../../../assets/svg/right-arrow.svg")}
                    alt="icon"
                  />
                </Link>
              </li>
              <li>
                <Link to="/account/company-profile">
                  <IntlMessages id="app.CompanyInformation" />
                  <img
                    src={require("../../../assets/svg/right-arrow.svg")}
                    alt="icon"
                  />
                </Link>
              </li>

              <li>
                <Link to="/account/change-password">
                  <IntlMessages id="app.ChangePassword" />
                  <img
                    src={require("../../../assets/svg/right-arrow.svg")}
                    alt="icon"
                  />
                </Link>
              </li>
              <li>
                <Link to="/account/profile/diamond-sequence">
                  <IntlMessages id="app.ManageDiamondSequence" />
                  <img
                    src={require("../../../assets/svg/right-arrow.svg")}
                    alt="icon"
                  />
                </Link>
              </li>
            </ul>
          </div>

          <div
            className="mobileLogOutOption"
            onClick={() => {
              commonLogout();
            }}
          >
            <IntlMessages id="app.Logout" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(MobileAccount);
