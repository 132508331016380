/* eslint-disable */
import React, { useState, useEffect } from "react";
import findIndex from "lodash/findIndex";
import find from "lodash/find";
import groupBy from "lodash/groupBy";
import { calculate, formatNumber } from "./SelectStone";
import {
  handleDownloadExcel,
  getColumn,
  handleConfirmStone,
  getProjectSetting,
  isMobile,
  getPath,
  LISTINGPAGES,
} from "../../DiamondList/DiamondListFunctions";
import Loader from "../Loader";
import Table from "../../DiamondList/TableBack";
import IntlMessages from "../../../util/IntlMessages";
import {
  LOCAL_STORAGE_VAR,
  DIAMOND_BID,
  PROJECT_SETTINGS_TYPE,
  TERMS,
} from "../../../constants/Common";
import CheckBox from "../CheckBox";
import moment from "moment";
import { useSelector } from "react-redux";
import { getBidType } from "../../NewArrivalBidIt";
import SendEmailPopup from "./SendEmail";
import OpenNotification from "../CommonButton/OpenNotification";
import SelectOption from "../SelectOption";
import FinalCalcMobile from "./FinalCalcMobile";
import compact from "lodash/compact";
import map from "lodash/map";
import { isArray, isEmpty, isNotEmpty } from "util/utils";
import { SEARCH_DIAMOND_LIST } from "constants/apiConstant";
import UtilService from "services/util";

/* eslint jsx-a11y/anchor-is-valid: 0 */

const currentType = "FinalCalculation";

const FMTERMS = [
  { id: "COD", name: "COD Fixed", charge: 1 },
  { id: "THIRTY", name: "30 Days Fixed", charge: 1.01 },
  { id: "SIXTY", name: "60 Days Fixed", charge: 1.02 },
];

export function getExtraPer() {
  const terms = localStorage.getItem(`${LOCAL_STORAGE_VAR}-terms`)
    ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-terms`))
    : {};
  let extraPer = terms && terms.addPer ? terms.addPer : 0;
  // if (extraPer < 0)
  extraPer = extraPer ? extraPer : 0;
  return extraPer;
}

const getFancyDiscount = (calcPricePerCarat = 0, discounts = []) => {
  if (isEmpty(discounts) || !isArray(discounts)) return calcPricePerCarat;
  const actualCtpr = calcPricePerCarat;

  discounts.filter(isNotEmpty).forEach((discount) => {
    calcPricePerCarat -= (calcPricePerCarat * discount) / 100;
  });
  const calcDiscount = (calcPricePerCarat * 100) / actualCtpr - 100;

  return { calcPricePerCarat, calcDiscount };
};

export async function newDiamondPrice(diamond, qt = false, bidType = null) {
  if (diamond.isBid) diamond.fmCharge = 0;

  let extraPer = getExtraPer();

  const isNotExtra = !extraPer && !qt;

  const quotePer = qt === true ? diamond.finalquote * -1 : 0;

  const currentBidType = bidType ? bidType : await getBidType();

  const bidPer =
    (qt === "bid" || diamond.isBid) && currentBidType === DIAMOND_BID.TYPE.BLIND
      ? 0.5
      : 0;
  let pricePerCarat =
    qt === "bid"
      ? diamond.bidPricePerCarat
      : qt === true
      ? diamond.rap - (diamond.rap * quotePer) / 100
      : diamond.ctPr;
  if (
    qt &&
    typeof qt === "string" &&
    qt.includes("charge") &&
    diamond.fmCharge &&
    diamond.isFm === "CERT" &&
    diamond.lbNm === "FM"
  ) {
    extraPer = 0;

    const selectedFM = qt.slice(6, qt.length);

    const newFMamt = pricePerCarat * diamond.crt + diamond.fmCharge;

    const FMcharge = newFMamt * find(FMTERMS, { id: selectedFM }).charge;
    pricePerCarat = FMcharge / diamond.crt;
  }
  // if (bidPer) pricePerCarat = pricePerCarat - (pricePerCarat * bidPer) / 100;

  if (!diamond.rap) {
    //fancy diamond calculation
    const { calcPricePerCarat, calcDiscount } = getFancyDiscount(diamond.ctPr, [
      quotePer,
      extraPer,
      bidPer,
    ]);
    diamond.calcPricePerCarat = calcPricePerCarat;
    diamond.calcDiscount = calcDiscount;
  } else {
    diamond.calcPricePerCarat = isNotExtra
      ? diamond.ctPr
      : pricePerCarat - (pricePerCarat * extraPer) / 100;
    diamond.calcDiscount = isNotExtra
      ? diamond.back
      : (1 - diamond.calcPricePerCarat / diamond.rap) * -100;
  }
  diamond.calcAmount = isNotExtra
    ? diamond.amt
    : diamond.calcPricePerCarat * diamond.crt;

  if (bidPer && diamond.rap) {
    diamond.calcPricePerCarat = isNotExtra
      ? diamond.ctPr
      : diamond.calcPricePerCarat - (diamond.calcPricePerCarat * bidPer) / 100;

    diamond.calcDiscount = isNotExtra
      ? diamond.back
      : (1 - diamond.calcPricePerCarat / diamond.rap) * -100;
    diamond.calcAmount = isNotExtra
      ? diamond.amt
      : diamond.calcPricePerCarat * diamond.crt;
  }

  return diamond;
}

function getBidBack(data) {
  const currentDateTime = moment().toISOString();
  if (
    currentDateTime >= data.blndBidStrTm &&
    currentDateTime <= data.blndBidEndTm
  ) {
    return data?.blndBidBack;
  } else if (
    currentDateTime >= data.lABidStrTm &&
    currentDateTime <= data.lABidEndTm
  ) {
    return data?.lABidBack;
  } else if (
    currentDateTime >= data.mulBidStrTm &&
    currentDateTime <= data.mulBidEndTm
  ) {
    return data?.mulBidBack;
  } else return "";
}

export function newDiamondPriceWithType(
  diamond,
  qt = false,
  currentBidType = null,
  slabCharges = null,
) {
  const currentDateTime = moment().toISOString();
  if (currentType === "/my-bid") {
    if (
      currentDateTime >= diamond?.extraValue?.blndBidStrTm &&
      currentDateTime <= diamond?.extraValue?.blndBidEndTm
    ) {
      diamond.bidTType = DIAMOND_BID.TYPE.BLIND;
    } else if (
      diamond?.bidTType === 3 &&
      currentDateTime >= diamond?.extraValue?.mulBidStrTm &&
      currentDateTime <= diamond?.extraValue?.mulBidEndTm
    ) {
      diamond.bidTType = DIAMOND_BID.TYPE.MULTIPLE;
    } else if (
      diamond?.bidTType === 2 &&
      currentDateTime >= diamond?.extraValue?.lABidStrTm &&
      currentDateTime <= diamond?.extraValue?.lABidEndTm
    ) {
      diamond.bidTType = DIAMOND_BID.TYPE.OPEN;
    }
  }

  if (diamond.isBid) diamond.fmCharge = 0;

  let extraPer = getExtraPer();
  const quotePer = qt === true ? diamond.finalquote * -1 : 0;

  const bidBack = getBidBack(diamond);
  const isBlindBid = (bidBack || []).includes(0.5 || "0.5");

  const bidPer = (qt === "bid" || diamond.isBid) && isBlindBid ? 0.5 : 0;

  let pricePerCarat =
    qt === "bid"
      ? diamond.bidPricePerCarat
      : qt === true
      ? diamond.rap - (diamond.rap * quotePer) / 100
      : diamond.ctPr;
  if (
    qt &&
    typeof qt === "string" &&
    qt.includes("charge") &&
    diamond.fmCharge &&
    diamond.isFm === "CERT" &&
    diamond.lbNm === "FM"
  ) {
    extraPer = 0;

    const selectedFM = qt.slice(6, qt.length);

    const newFMamt = pricePerCarat * diamond.crt + diamond.fmCharge;

    const FMcharge = newFMamt * find(FMTERMS, { id: selectedFM }).charge;
    pricePerCarat = FMcharge / diamond.crt;
  }

  if (slabCharges) {
    extraPer = slabCharges;
  }
  const isNotExtra = !extraPer && !qt;

  // if (bidPer) pricePerCarat = pricePerCarat - (pricePerCarat * bidPer) / 100;
  // if (bidPer) extraPer = extraPer < 0 ? extraPer - bidPer : extraPer + bidPer;
  if (!diamond.rap) {
    //fancy diamond calculation
    const { calcPricePerCarat, calcDiscount } = getFancyDiscount(diamond.ctPr, [
      quotePer,
      extraPer,
      bidPer,
    ]);
    diamond.calcPricePerCarat = calcPricePerCarat;
    diamond.calcDiscount = calcDiscount;
  } else if (slabCharges) {
    diamond.calcDiscount = Number(
      parseFloat(diamond.back + extraPer).toFixed(2),
    );
    diamond.calcPricePerCarat = Number(
      parseFloat(
        (diamond.rap * (100 + (diamond.back + extraPer))) / 100,
      ).toFixed(2),
    );
  } else {
    diamond.calcPricePerCarat = isNotExtra
      ? diamond.ctPr
      : pricePerCarat - (pricePerCarat * extraPer) / 100;
    diamond.calcDiscount = isNotExtra
      ? diamond.back
      : (1 - diamond.calcPricePerCarat / diamond.rap) * -100;
  }
  diamond.calcAmount = isNotExtra
    ? diamond.amt
    : diamond.calcPricePerCarat * diamond.crt;

  if (bidPer && diamond.rap) {
    diamond.calcPricePerCarat = isNotExtra
      ? diamond.ctPr
      : diamond.calcPricePerCarat - (diamond.calcPricePerCarat * bidPer) / 100;

    diamond.calcDiscount = isNotExtra
      ? diamond.back
      : (1 - diamond.calcPricePerCarat / diamond.rap) * -100;
    diamond.calcAmount = isNotExtra
      ? diamond.amt
      : diamond.calcPricePerCarat * diamond.crt;
  }

  return diamond;
}

const FinalCalculations = (props) => {
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const checked =
    useSelector((state) => state.diamondData.selectedRows[currentType]) || [];

  const [cm, setCm] = useState(false);

  const [fm, setFm] = useState("COD");

  const [terms, setTerms] = useState(TERMS[0].id);

  const [settings, setSettings] = useState([]);

  const [email, sendEmail] = useState("");

  const [currentBidType, setCurrentBidType] = useState(0);

  const [newData, setNewData] = useState([]);

  const path = window.location.pathname.split("/")[1];

  const [slabList, setSlabList] = useState([]);

  const [memoDataList, setMemoDataList] = useState([]);

  const [memoDataListClone, setmemoDataListClone] = useState([]);

  const [payDate, setPayDate] = useState(moment().format("MM-DD-YYYY"));

  const [slabCharges, setslabCharges] = useState(null);

  const confirmButtonDisabled =
    props.checked.some((r) => r.wSts === "M" || r.wSts === "H") &&
    !compact(
      props.checked.map((c) => (c.wSts === "H" || c.wSts === "M") && c.id),
    ).every((e) => {
      const allIds = [
        ...map(
          props.inBlockDiamonds &&
            props.inBlockDiamonds[0] &&
            props.inBlockDiamonds[0].diamonds,
          "id",
        ),
        ...map(
          props.inBlockDiamonds &&
            props.inBlockDiamonds[1] &&
            props.inBlockDiamonds[1].diamonds,
          "id",
        ),
      ];

      return allIds.includes(e);
    });

  const getFMCharge = ({ shpNm, crt, clrNm, colNm }) => {
    let charge = 0;
    if (settings && settings.length) {
      const grp = groupBy(settings, "shp");

      const shaped = shpNm === "ROUND" ? grp["ROUND"] : grp["FANCY"];

      const carated =
        shaped &&
        shaped.filter(
          (el) =>
            (el.from ? el.from <= crt : true) && (el.to ? el.to >= crt : true),
        );
      if (carated && carated.length) {
        let found = carated && carated.filter((el) => !el.clr && !el.col);
        if (!found.length)
          found =
            carated &&
            carated.filter(
              (el) =>
                el.col &&
                el.col.split(",").includes(colNm) &&
                el.clr &&
                el.clr.split(",").includes(clrNm),
            );
        if (found.length) charge = found[0].charges;
      }
    }
    return charge;
  };

  const getCmCharge = React.useCallback((arr, settings) => {
    let charge = 0;
    arr.forEach((line) => {
      if (line.isCm && line.isCm === "ELIG") {
        const found =
          settings &&
          settings.filter(
            (el) => el.from <= line.crt && (el.to ? el.to >= line.crt : true),
          );
        if (found.length) charge += found[0].fee;
      }
    });
    return charge;
  }, []);

  let isCmVisible = 0;

  const fsNow = localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)
    ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)).split("/")[1]
    : "";
  let VALUES = isMobile() ? {} : [];

  isCmVisible = newData.filter((el) => el.isCm && el.isCm === "ELIG").length;

  const newPricedRows = [];
  newData.forEach((n) => {
    checked.forEach((c) => {
      if (n.id === c.id) newPricedRows.push(n);
    });
  });

  const summation = calculate(newPricedRows);

  const totalCarat = parseFloat(summation.total_carat).toFixed(2);

  const netValue =
    "$" + formatNumber(parseFloat(summation.final_value).toFixed(2));

  const avgDisc = parseFloat(summation.final_discount).toFixed(2);

  const finalAvgDisc = parseFloat(summation.final_term_discount).toFixed(2);

  const finalNetRate =
    "$" + formatNumber(parseFloat(summation.final_rate).toFixed(2));

  const finalNetValue =
    "$" + formatNumber(parseFloat(summation.final_net_value).toFixed(2));

  const CmCharge = parseFloat(getCmCharge(newPricedRows, settings)).toFixed(2);
  VALUES = isMobile()
    ? {
        "CT.:": totalCarat,
        "Disc%:": avgDisc,
        "Amount:": netValue,
        "Final Rate:": finalNetRate,
        "Final Disc%:": finalAvgDisc,
        "Final Value:": finalNetValue,
      }
    : [
        { title: "Total Carats", value: totalCarat },
        { title: "Net Value", value: netValue },
        { title: "Avg. Discount", value: avgDisc },
        { title: "Final Avg Discount", value: finalAvgDisc },
        { title: "Final Net Rate", value: finalNetRate },
        {
          title: "Final Net Value",
          value:
            finalNetValue +
            (cm && CmCharge > 0 ? " + $" + CmCharge + " (CM Charges)" : ""),
        },
      ];

  const allEqual = (arr) => arr.every((v) => v === arr[0]);

  useEffect(() => {
    // api - getMemoDaysFun
    let obj = SEARCH_DIAMOND_LIST;

    obj.request = {
      filters: [{ vStnId: props?.checked?.map((e) => e.vStnId) }],
      user: user?.id || "",
      isAppendMasters: true,
      page: 1,
      limit: 250,
      sort: [],
      FinalCalculation: true,
    };

    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === "OK") {
        const list = data?.data[0].diamonds;
        if (list) {
          setMemoDataList(data?.data[0].diamonds);
          setmemoDataListClone(data?.data[0].diamonds);
        }
      }
    });
  }, []);

  useEffect(() => {
    getProjectSetting(
      props.checked[0].isFm === "CERT"
        ? PROJECT_SETTINGS_TYPE.FM_CHARGES
        : PROJECT_SETTINGS_TYPE.CM_CHARGES,
      setSettings,
    );

    getProjectSetting(PROJECT_SETTINGS_TYPE.SLAB_CHARGES, setSlabList);
  }, [props.checked]);

  const getLessValue = (data) => {
    const currentDateTime = moment().toISOString();
    if (
      currentDateTime >= data.blndBidStrTm &&
      currentDateTime <= data.blndBidEndTm
    ) {
      return data?.blndBidBack?.[1]
        ? `${(data?.blndBidBack?.[0] || 0).toFixed(2)} + ${
            data?.blndBidBack?.[1]
          }`
        : `${(data?.blndBidBack?.[0] || 0).toFixed(2)}`;
    } else if (
      currentDateTime >= data.lABidStrTm &&
      currentDateTime <= data.lABidEndTm
    ) {
      return data?.lABidBack?.[1]
        ? `${(data?.lABidBack?.[0] || 0).toFixed(2)} + ${data?.lABidBack?.[1]}`
        : `${(data?.lABidBack?.[0] || 0).toFixed(2)}`;
    } else if (
      currentDateTime >= data.mulBidStrTm &&
      currentDateTime <= data.mulBidEndTm
    ) {
      return data?.mulBidBack?.[1]
        ? `${(data?.mulBidBack?.[0] || 0).toFixed(2)} + ${
            data?.mulBidBack?.[1]
          }`
        : `${(data?.mulBidBack?.[0] || 0).toFixed(2)}`;
    } else return parseFloat(getExtraPer()).toFixed(2);
  };

  useEffect(() => {
    if (slabList && memoDataListClone) {
      getParams(terms);
    }
  }, [slabList, memoDataListClone, VALUES]);

  const getParams = (terms, row) => {
    if (memoDataListClone.length === 0) return;
    if (slabList.length === 0) return;
    let memoDays = 0;

    if (isNotEmpty(memoDataListClone) && row) {
      memoDays = memoDataListClone
        ?.filter((e) => e["vStnId"] === row?.original.vStnId)
        ?.map((el) => {
          return el.MemoDays;
        });
      memoDays = memoDays[0];
    }

    const TermsCount = TERMS.find((d) => d.id === terms)?.charge;
    const dueDays = memoDays < TermsCount ? TermsCount - memoDays : 0;
    const dueDate = moment().add(dueDays, "days").format("MM-DD-YYYY");

    const slabCount = parseInt(memoDays + dueDays);

    let arrListSlab = slabList
      ?.filter((e) => e["terms"] === TermsCount)
      ?.map((e) => {
        return slabCount >= 0 && slabCount <= 1
          ? e["24"]
          : slabCount >= 2 && slabCount <= 6
          ? e["2-6"]
          : slabCount >= 7 && slabCount <= 15
          ? e["7-15"]
          : e["15+"];
      });
    arrListSlab = arrListSlab ?? arrListSlab[0];
    // getPath() === LISTINGPAGES.LIST && setslabCharges(arrListSlab[0])
    setslabCharges(arrListSlab[0]);

    if (row) {
      let index = memoDataListClone.findIndex(
        (d) => d.vStnId === row?.original.vStnId,
      );
      if (memoDataListClone[index]) {
        memoDataListClone[index].SlabLess = arrListSlab[0];
        memoDataListClone[index].DueDays = dueDays;
        memoDataListClone[index].DueDate = dueDate;
        memoDataListClone[index].PayDateAmt =
          dueDays * row?.original.calcAmount; //totalA values
      }
      setMemoDataList(memoDataListClone);
    }

    if (arrListSlab) {
      setslabCharges(arrListSlab[0]);
    }

    if (terms !== "COD") {
      let TotalB = parseFloat(
        VALUES?.filter((e) => e.title === "Final Net Value")
          ?.map((e) => e.value)[0]
          ?.replace(/^\D|,+/g, ""),
      ).toFixed(2);
      let TotalA = 0;
      TotalA = memoDataListClone?.map((e) => {
        return parseFloat((TotalA += e.PayDateAmt)).toFixed(2);
      });
      TotalA = TotalA[TotalA.length - 1];
      setPayDate(
        moment()
          .add(Math.round(TotalA / TotalB), "days")
          .format("MM-DD-YYYY"),
      );
    }
    return { memodays: memoDays, duedays: dueDays, duedate: dueDate };
  };

  const onTermsChange = (id) => {
    setTerms(id);
  };

  const getColumns = React.useCallback(async () => {
    if (slabList.length === 0 || memoDataListClone.length === 0) return;
    const Columns = getColumn();
    let index = findIndex(Columns, { id: "rap" });

    const bidType = await getBidType();

    setCurrentBidType(bidType);
    if (!index) index = 1;

    const columns = [
      ...Columns.slice(0, index + 1).filter(
        (el) => !["back", "amt", "ctPr"].includes(el.id),
      ),
      find(Columns, { id: "back" }),
      find(Columns, { id: "ctPr" }),
      find(Columns, { id: "amt" }),
      props.checked[0].isFm === "CERT" &&
      props.checked[0].lbNm === "FM" &&
      !(getPath() === LISTINGPAGES.BID || getPath() === LISTINGPAGES.MYBID)
        ? {
            Header: "FM Charges",
            accessor: "fmCharge",
            id: "fmCharge",
            Cell: ({ cell }) => <>{parseFloat(cell.value).toFixed(2)}</>,
          }
        : null,
      props.checked[0].isFm !== "CERT" &&
        props.checked[0].lbNm !== "FM" && { // ) && //   getPath() === LISTINGPAGES.MYBID || (getPath() === "compare" && (fsNow === LISTINGPAGES.BID || fsNow === LISTINGPAGES.MYBID)) // (getPath() === LISTINGPAGES.BID ||
          Header: "less",
          accessor: "less",
          id: "less",
          Cell: ({ row }) => (
            <>
              {/* { parseFloat(getExtraPer()).toFixed(2)  + (bidType === DIAMOND_BID.TYPE.BLIND ? ' + 0.5' : '')} */}
              {/* {getLessValue(row.original)} */}
              {memoDataListClone
                ?.filter((e) => e["vStnId"] === row?.original.vStnId)
                ?.map((el) => el.SlabLess)}
            </>
          ),
        },
      {
        Header: "Memo Days",
        accessor: "memoDays",
        id: "memoDays",
        Cell: ({ row }) => <>{getParams(terms, row).memodays ?? 0}</>,
      },
      {
        Header: "Final Disc",
        accessor: "fnBack",
        id: "fnBack",
        Cell: ({ row }) => (
          <span className="themeColor">
            {row.original.calcDiscount
              ? parseFloat(row.original.calcDiscount).toFixed(2)
              : "0.00"}
          </span>
        ),
      },
      {
        Header: "Final Rate",
        accessor: "fnCtpr",
        id: "fnCtpr",
        Cell: ({ row }) => (
          <span className="themeColor">
            {row.original.calcPricePerCarat
              ? parseFloat(row.original.calcPricePerCarat).toFixed(2)
              : "0.00"}
          </span>
        ),
      },
      {
        Header: "Final Value",
        accessor: "fnAmt",
        id: "fnAmt",
        Cell: ({ row }) => (
          <span className="themeColor">
            {row.original.calcAmount
              ? parseFloat(row.original.calcAmount).toFixed(2)
              : "0.00"}
          </span>
        ),
      },
      {
        Header: "Due Days",
        accessor: "dueDays",
        id: "dueDays",
        Cell: ({ row }) => <>{getParams(terms, row).duedays}</>,
      },
      {
        Header: "Due Date",
        accessor: "dueDate",
        id: "dueDate",
        Cell: ({ row }) => <>{getParams(terms, row).duedate}</>,
      },
      ...Columns.slice(index + 1, Columns.length).filter(
        (el) => !["back", "amt", "ctPr"].includes(el.id),
      ),
    ];
    setColumns(columns.filter((el) => el && el));
  }, [fsNow, props.checked, terms, slabList, memoDataListClone]);

  const user = JSON.parse(
    window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)),
  );

  const checkCheck = () => {
    if (!checked.length) {
      OpenNotification({
        type: "error",
        title: "Please select stone(s) to confirm.",
      });
      return false;
    } else return true;
  };

  const submit = () => {
    if (!checkCheck()) return;
    handleConfirmStone(
      checked.map((x) => x.id),
      "",
      moment().toISOString(),
      user && user.account ? user.account.companyName : "",
      (flag) => {
        props.onClose();
        props.clearAll();
        if (flag && props.fetch) props.fetch();
      },
    );
  };

  const getNewData = React.useCallback(async () => {
    let newDataSet = props.checked;

    newDataSet = newDataSet.map((n) => ({
      ...n,
      fmCharge: n.isFm === "CERT" ? getFMCharge(n) : 0,
      //fmCharge: 0,
      isBid:
        getPath() === LISTINGPAGES.BID ||
        getPath() === LISTINGPAGES.MYBID ||
        (getPath() === "compare" &&
          (fsNow === LISTINGPAGES.BID || fsNow === LISTINGPAGES.MYBID)),
    }));

    const bidType = await getBidType();

    newDataSet = newDataSet?.map((x) =>
      newDiamondPriceWithType(
        x,
        x.isFm === "CERT" && x.lbNm === "FM" ? "charge" + fm : false,
        bidType,
        slabCharges,
      ),
    );
    setLoading(false);
    setNewData(newDataSet);
  }, [fm, fsNow, getFMCharge, props.checked, slabCharges]);

  React.useEffect(() => {
    getColumns();
  }, [getColumns]);

  React.useEffect(() => {
    let timer;
    setLoading(true);

    timer = setTimeout(() => {
      setLoading(false);
      getNewData();
    }, 1000);
  }, [slabCharges]); //getNewData  - rendring issue

  return isMobile() ? (
    <FinalCalcMobile
      data={newData}
      VALUES={VALUES}
      currentType={currentType}
      parent={{ submit, checkCheck, onClose: props.onClose }}
      noConfirmButton={props.noConfirmButton}
    />
  ) : (
    <div>
      {columns.length > 0 && !loading ? null : <Loader loading={true} fixed />}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 className="popupInnerTitle">
          <IntlMessages id="app.FinalCalculation" />
        </h2>
        <div
          style={{
            display: "flex",
            width: "220px",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <h2 className="popupInnerTitle">
            <IntlMessages id="Terms" />
          </h2>
          <SelectOption
            className={"finalCalculationDropDownMenu"}
            value={terms}
            defaultValue={terms}
            selectValue={TERMS}
            onChange={(val) => onTermsChange(val)}
          />
        </div>
      </div>
      <div className="searchPopupCommonTable">
        <div className="searchResultTable tabInnerTableScroll">
          <Table
            data={newData}
            columns={columns}
            areAllChecked={true}
            nodots
            noGrp
            currentType={currentType}
            canSort={false}
          />
        </div>
        <div className="mt-10 finalCalDetail">
          {props.checked[0].isFm === "CERT" &&
          props.checked[0].lbNm === "FM" &&
          !(
            getPath() === LISTINGPAGES.BID || getPath() === LISTINGPAGES.MYBID
          ) ? (
            <SelectOption
              className="finalFMSelect"
              selectValue={FMTERMS}
              label="FM Terms*"
              value={fm}
              onChange={(e) => setFm(e)}
            />
          ) : isCmVisible ? (
            <CheckBox
              label="CM Charge"
              checked={cm}
              onChange={() => setCm(!cm)}
            />
          ) : null}
          {props?.checked?.length > 1 &&
            terms !== "COD" &&
            payDate !== moment().format("MM-DD-YYYY") &&
            !allEqual(memoDataListClone.map((e) => e.DueDate)) && (
              <div
                style={{
                  fontSize: "15px",
                  textDecoration: "underline",
                  textAlign: "end",
                  marginRight: "50px",
                  color: "black",
                  fontWeight: "600",
                }}
              >
                You can also pay amount altogether on :
                <span
                  style={{
                    color: "#b69865",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  {`  ${payDate} `}
                </span>
              </div>
            )}
          <div className="DiamondDetailPopup mt-10">
            {VALUES.map((x) => (
              <div className="DiamondDetailPopupItem">
                <span>{x.title}:</span>
                <span>{x.value}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="sideBarPopupButton">
          {/* {!props.noConfirmButton && !confirmButtonDisabled && (
            <a className="commonButton" onClick={submit}>
              <IntlMessages id="app.confirmStoneddd" />
            </a>
          )} */}
          <a className="commonButton" onClick={props.onClose}>
            <IntlMessages id="app.CancelStone" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              props.handleLoader(true);
              handleDownloadExcel(
                {},
                checked.map((x) => (props.orderDiamond ? x.diamondId : x.id)),
                () => {
                  props.handleLoader(false);
                },
                path === "upcoming" ? true : false,
                [],
                props.orderDiamond,
                path,
              );
            }}
          >
            <IntlMessages id="app.ExcelExport" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              if (checked.length) sendEmail("stock");
              else
                OpenNotification({
                  type: "error",
                  title: "Please select any stone(s).",
                });
            }}
          >
            <IntlMessages id="app.EmailStock" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              if (checked.length) sendEmail("img");
              else
                OpenNotification({
                  type: "error",
                  title: "Please select any stone(s).",
                });
            }}
          >
            <IntlMessages id="app.EmailPic" />
          </a>
        </div>
      </div>
      {email !== "" && (
        <SendEmailPopup
          sendEmail={email}
          onCancel={() => sendEmail("")}
          ids={checked.map((x) =>
            window.location.pathname === "/account/confirm-list"
              ? x.diamondId
              : x.id,
          )}
          handleLoader={props.handleLoader}
          img={email === "img"}
          isStock={email === "stock"}
          orderDiamond={
            window.location.pathname === "/account/confirm-list" ? true : false
          }
          finalcalc={true}
          checked={props?.checked}
        />
      )}
    </div>
  );
};

export default FinalCalculations;
