/* eslint-disable */
import React, { memo, useCallback, useMemo } from "react";
import IntlMessages from "../../../util/IntlMessages";
import {
  getPath,
  LISTINGPAGES,
  isMobile,
} from "../../DiamondList/DiamondListFunctions";
import { useSelector, useDispatch } from "react-redux";
import each from "lodash/each";
import _, { isEmpty } from "lodash";

// export function formatNumber(num) {
//   return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : 0;
// }

export function formatNumber(num) {
  if (num) {
    var cur = _.includes(num, "$") ? "$" : "";
    num = num.toString().replace("$", "");
    var afterPoint = "";
    if (num.indexOf(".") > 0) {
      afterPoint = num.substring(num.indexOf("."), num.length);
    }
    num = num.split(".")[0];
    num = num.toString();
    var lastThree = num.substring(num.length - 3);
    var otherNumbers = num.substring(0, num.length - 3);
    if (otherNumbers !== "") {
      lastThree = "," + lastThree;
    }
    if (num.length <= 3) {
      lastThree = "" + lastThree;
    }
    // window.location.pathname.split("?").pop() === "62ea65e6627ac77612cc03a1" &&
    var res =
      cur +
      otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      lastThree +
      afterPoint;
    return res;
  } else {
    return 0;
  }
}

export function calculate(dataList, isBid = false) {
  let sum_total = {
    total_pieces: 0,
    total_carat: 0,
    total_avg_rap: 0,
    final_discount: 0,
    final_price: 0,
    final_value: 0,
    total_rapaport: 0,
    avg_discount: 0,
    final_net_value: 0,
    final_rate: 0,
    final_term_discount: 0,
    avg_term_discount: 0,
    finalAvgVal: 0,
  };
  let total = {
    fancy_total: { ...sum_total },
    non_fancy_total: { ...sum_total },
  };
  total.fancy_total.isFancy = 1;
  total.non_fancy_total.isFancy = 0;

  each(dataList, function (lst) {
    let use_total_key = lst.isFancy ? "fancy_total" : "non_fancy_total";
    total[use_total_key].total_pieces += 1;
    total[use_total_key].total_carat +=
      lst.crt && parseFloat(lst.crt) ? parseFloat(lst.crt) : 0;
    const rap = parseFloat(lst.rap ?? lst.ctPr ?? 0);
    lst.rap_avg = rap * parseFloat(lst.crt || 0) ?? 0;

    total[use_total_key].total_avg_rap += lst.rap_avg;
    total[use_total_key].total_rapaport += lst.rap ? parseFloat(lst.rap) : 0;
    total[use_total_key].final_value += (isBid ? lst.bidAmount : lst.amt) || 0;
    total[use_total_key].final_net_value += lst.calcAmount || 0;

    total[use_total_key].finalAvgVal +=
      (rap + (rap * lst.finalquote) / 100 || 0) * (lst.crt || 0);
  });

  total.fancy_total.final_price =
    total.fancy_total.final_value && total.fancy_total.total_carat
      ? total.fancy_total.final_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_price =
    total.non_fancy_total.final_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_value / total.non_fancy_total.total_carat
      : 0;

  total.fancy_total.final_rate =
    total.fancy_total.final_net_value && total.fancy_total.total_carat
      ? total.fancy_total.final_net_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_rate =
    total.non_fancy_total.final_net_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_net_value /
        total.non_fancy_total.total_carat
      : 0;
  total.fancy_total.total_avg_rap = total.fancy_total.total_avg_rap
    ? total.fancy_total.total_avg_rap / total.fancy_total.total_carat
    : 0;
  total.non_fancy_total.total_avg_rap = total.non_fancy_total.total_avg_rap
    ? total.non_fancy_total.total_avg_rap / total.non_fancy_total.total_carat
    : 0;
  total.fancy_total.final_discount =
    total.fancy_total.final_price && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_price / total.fancy_total.total_avg_rap) *
        -100
      : 0;
  total.non_fancy_total.final_discount =
    total.non_fancy_total.final_price && total.non_fancy_total.total_avg_rap
      ? (1 -
          total.non_fancy_total.final_price /
            total.non_fancy_total.total_avg_rap) *
        -100
      : 0;
  total.fancy_total.final_term_discount =
    total.fancy_total.final_rate && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_rate / total.fancy_total.total_avg_rap) *
        -100
      : 0;
  total.non_fancy_total.final_term_discount =
    total.non_fancy_total.final_rate && total.non_fancy_total.total_avg_rap
      ? (1 -
          total.non_fancy_total.final_rate /
            total.non_fancy_total.total_avg_rap) *
        -100
      : 0;

  const totalCarat =
    total.fancy_total.total_carat + total.non_fancy_total.total_carat;
  const finalAvgVal =
    total.fancy_total.finalAvgVal + total.non_fancy_total.finalAvgVal;
  const finalAvgCtPr = finalAvgVal / totalCarat;
  const totalCtPr =
    total.fancy_total.total_rapaport + total.non_fancy_total.total_rapaport;
  const finalCtPr =
    total.fancy_total.total_avg_rap + total.non_fancy_total.total_avg_rap;

  let allTotal = {
    total_pieces:
      total.fancy_total.total_pieces + total.non_fancy_total.total_pieces,
    total_carat: totalCarat,
    final_rapaport: finalCtPr,
    final_discount: total.fancy_total.final_discount
      ? total.fancy_total.final_discount
      : total.non_fancy_total.final_discount,
    final_value:
      total.fancy_total.final_value + total.non_fancy_total.final_value,
    final_price: totalCarat
      ? (total.fancy_total.final_value + total.non_fancy_total.final_value) /
        totalCarat
      : 0,
    total_rapaport: totalCtPr,
    final_net_value:
      total.fancy_total.final_net_value + total.non_fancy_total.final_net_value,
    final_rate: totalCarat
      ? (total.fancy_total.final_net_value +
          total.non_fancy_total.final_net_value) /
        totalCarat
      : 0,
    final_term_discount: total.fancy_total.final_term_discount
      ? total.fancy_total.final_term_discount
      : total.non_fancy_total.final_term_discount,
    finalAvgVal,
    finalAvgCtPr,
    finalAvgDis: (1 - finalAvgCtPr / finalCtPr) * -100,
  };
  allTotal.avg_discount = allTotal.final_rapaport
    ? allTotal.final_price / allTotal.final_rapaport
    : 0;
  allTotal.avg_term_discount = allTotal.final_rapaport
    ? allTotal.final_rate / allTotal.final_rapaport
    : 0;
  return allTotal;
}

const SelectStone = (props) => {
  let propssum = props.sum || {};

  const checked =
    useSelector((state) => state.diamondData.selectedRows[props.currentType]) ||
    [];
  const dispatch = useDispatch();

  const clearAll = useCallback(() => {
    dispatch({ type: "RESET_SELECTED_ROWS" });
  }, [dispatch]);

  let summation = useMemo(() => calculate(checked), [checked]);
  let objects = useMemo(
    () =>
      [
        {
          label: <IntlMessages id="app.Pieces" />,
          check: summation.total_pieces,
          //   sum: propssum.count || 0,
        },
        {
          label: <IntlMessages id="app.Carats" />,
          check: parseFloat(summation.total_carat).toFixed(2),
          //   sum: parseFloat(propssum.totalCarat || 0).toFixed(2),
        },
        // getPath() !== LISTINGPAGES.UPCOMING
        //     ? {
        //           label: <span>Rap Price</span>,
        //           check: parseFloat(summation.final_rapaport).toFixed(2),
        //           sum: parseFloat(propssum.rapPrice || 0).toFixed(2)
        //       }
        //     : null,
        getPath() !== LISTINGPAGES.UPCOMING
          ? {
              label: <span>Avg. Dis</span>,
              check: parseFloat(summation.final_discount).toFixed(2),
              //   sum: parseFloat(propssum.avgDiscount || 0).toFixed(2),
            }
          : null,
        getPath() !== LISTINGPAGES.UPCOMING
          ? {
              label: <span>Total Pr/Ct</span>,
              check: `$` + summation.final_price?.toFixed(2),
              //   sum: Math.round(propssum.totalPricePerCarat || 0)?.toFixed(2),
            }
          : null,
        getPath() !== LISTINGPAGES.UPCOMING
          ? {
              label: <IntlMessages id="app.Amount" />,
              check: `$` + summation.final_value?.toFixed(2),
              //   sum: Math.round(propssum.totalAmount || 0),
            }
          : null,
        // summation.final_value !== 0 && getPath() !== LISTINGPAGES.COMING_SOON
        //   ? {
        //       label: <span>Coming Soon</span>,
        //       check: `$` + summation.final_value?.toFixed(2),
        //       //   sum: Math.round(propssum.totalAmount || 0),
        //     }
        //   : null,
      ].filter((el) => el && el),
    [summation],
  );

  // if (getPath() === LISTINGPAGES.QUOTE) {
  //       return null
  // }

  return isMobile() ? (
    <div className="mobileStoneSelect">
      {window.shortPath() !== "trending" &&
        objects.map((item, index) => {
          return (
            <div className="mobileSelectStoneItem" key={item.label + index}>
              {checked.length ? (
                <span style={{ color: "red", fontWeight: "600" }}>
                  {formatNumber(item.check)}
                </span>
              ) : null}
              {item.sum ? (
                <span style={{ fontWeight: "600", color: "#000" }}>
                  /{formatNumber(isNaN(item.sum) ? 0 : item.sum)}
                </span>
              ) : (
                ""
              )}
              <span>{item.label}</span>
            </div>
          );
        })}
    </div>
  ) : (
    checked.length > 0 && (
      <div
        className={`selectStoneValueBlock ${
          props.hideClearAll ? "hideclearAll" : props.profilListing
        }`}
        style={props.blockStyle || {}}
      >
        {objects.map((item, i) => {
          return (
            <div className="selectStoneValueItem" key={item.label + i}>
              <span className="selectStoneLabel">{item.label} : </span>
              <span className="selectStopnValue">
                <span className="redColor" style={{ fontWeight: "600" }}>
                  {formatNumber(item.check)}
                </span>
                {item.sum ? `/${formatNumber(item.sum)}` : ""}
              </span>
            </div>
          );
        })}
        {!props.hideClearAll && (
          <div
            className="searchStoneClose"
            onClick={clearAll}
            style={props.clearStyle || {}}
          >
            <IntlMessages id="app.clearAllFilter" />
          </div>
        )}
      </div>
    )
  );
};
export default memo(SelectStone);
