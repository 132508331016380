/* eslint-disable */
import React, { Component } from "react";
import TwentyTwenty from "react-twentytwenty";
import { Slider } from "antd";
import "./xray.less";
import _ from "lodash";
import { DIAMOND_FTC, getMasterInclution } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import {
  XML_MEDIA_XML,
  XML_MEDIA_IMAGE,
  XML_MEDIA_PLOTING,
} from "../../constants/Common";
import downArrowSVG from "../../../src/assets/svg/Header/down-arrow.svg";
import upArrowSVG from "../../../src/assets/svg/Header/up-arrow.svg";
import axios from "axios";

const sortingInclutionParameter = ["CN", "SN", "CW", "SW"];
class XRAY extends Component {
  state = {
    xRayData: {},
    opacity: 1,
    xmlData: [],
    inclusionMaster: [],
    selectedInc: {},
    selectedType: null,
    openMeasurement: true,
    openGrading: true,
    openDetail: true,
    openInclusion: true,
  };

  xRayFile = async (rptNo) => {
    try {
      let res = await axios
        .get(`${XML_MEDIA_XML}${rptNo}.xml`, { "Content-Type": "text/xml" })
        .then((result) => result?.data);

      let parser = new DOMParser();
      let xml = parser.parseFromString(res, "text/xml");

      let htmlTag = xml.getElementsByTagName("Point");
      let arr = [];

      for (let i = 0; i < htmlTag.length; i++) {
        arr.push({
          Point: htmlTag[i].attributes["Point"].value,
          Type: htmlTag[i].attributes["Type"].value,
          Name: htmlTag[i].attributes["Name"].value,
        });
      }
      this.setState({ xmlData: arr });
    } catch (error) {
      console.log("error while fetching xrayFile");
    }
  };

  getMasterInclusion = () => {
    const objData = {
      ...getMasterInclution,
      request: {
        filter: {
          parentCode: [
            "BLACK_INCLUSION_TABLE",
            "BLACK_INCLUSION_SIDE",
            "WHITE_INCLUSION_SIDE",
            "WHITE_INCLUSION_TABLE",
          ],
          isDeleted: false,
        },
      },
    };
    UtilService.callApi(objData, (_, res) => {
      if (res && res.code === "OK") {
        this.setState({ inclusionMaster: res.data });
      }
    });
  };

  handleDetail = () => this.setState({ openDetail: !this.state.openDetail });
  handleGrading = () => this.setState({ openGrading: !this.state.openGrading });
  handleMeasurement = () =>
    this.setState({ openMeasurement: !this.state.openMeasurement });
  handleInclusion = () =>
    this.setState({ openInclusion: !this.state.openInclusion });

  componentDidMount = () => {
    let id = this.props.match.params.stoneId;

    const objData = { ...DIAMOND_FTC, request: { filter: { vStnId: id } } };

    UtilService.callApi(objData, (_, res) => {
      if (res && res.code === "OK") {
        this.setState({ xRayData: res.data ?? {} }, () => {
          this.xRayFile(res.data.rptNo);
          this.getMasterInclusion();
        });
      }
    });
  };

  render() {
    let { xRayData, xmlData, inclusionMaster } = this.state;
    let sortedInclution = [];
    let xmlGrp = _.groupBy(xmlData, "Type");
    inclusionMaster.length &&
      inclusionMaster.map((data) => {
        let f = xmlGrp["Inclusion"]?.filter((res) => res.Name === data.code);
        if (f.length) {
          f[0].description = data.description;
        }
      });
    sortingInclutionParameter.map((data) => {
      let did = _.nth(
        xmlGrp["Inclusion"]?.filter((res) => res.Name.substr(0, 2) === data),
        0,
      );
      if (!_.isEmpty(did)) sortedInclution.push(did);
    });
    xmlGrp["Inclusion"] = sortedInclution;
    return (
      <>
        <div className="xrayLogoHead">
          <img src={require("../../assets/img/logo2.png")} alt="" />
          <h2 className="specificationTitle">
            DIAMOND SPECIFICATIONS - {xRayData.vStnId}
          </h2>
        </div>
        <div className="xrayBoxWrapper">
          <div className="xrayWrapperTop d-flex">
            <div className="xRayWraperLeft">
              <div className="xRayBoxImage d-flex">
                <div className="xRayImageBox">
                  <TwentyTwenty
                    slider={<div className="sliderCursor" />}
                    left={
                      <>
                        <img
                          className="opacityhightImage"
                          style={{ opacity: this.state.opacity }}
                          src={
                            xRayData.rptNo &&
                            `${XML_MEDIA_IMAGE}${xRayData.rptNo}.png`
                          }
                          alt=""
                        />
                        <img
                          className="opacitylowImage"
                          src={
                            xRayData.rptNo &&
                            `${XML_MEDIA_PLOTING}${xRayData.rptNo}.png`
                          }
                          alt=""
                        />
                      </>
                    }
                    right={
                      <img
                        id="diamImg"
                        src={
                          xRayData.rptNo &&
                          `${XML_MEDIA_PLOTING}${xRayData.rptNo}.png`
                        }
                        alt=""
                      />
                    }
                  />
                  {!_.isEmpty(this.state.selectedInc) &&
                    xmlGrp[this.state.selectedType] &&
                    xmlGrp[this.state.selectedType]
                      .filter((item) => item.Name === this.state.selectedInc)
                      .map((key, index) => {
                        const pointArr = key?.Point?.split(",");
                        const xAxis = parseInt(pointArr?.[0] ?? 0);
                        const yAxis = parseInt(pointArr?.[1] ?? 0);

                        const mainWidth = parseInt(
                          document.getElementById("diamImg").width,
                        );
                        const halfWidth = parseInt(mainWidth / 2);

                        // arrow code start
                        return (
                          <div
                            className="cursorpointerBlock"
                            style={
                              xAxis > halfWidth
                                ? {
                                    top: yAxis - 37,
                                    right: 0,
                                  }
                                : {
                                    top: yAxis - 37,
                                    left: 0,
                                  }
                            }
                            key={index}
                          >
                            <span
                              style={
                                xAxis > halfWidth
                                  ? { position: "relative", right: "0px" }
                                  : { position: "relative", left: "0px" }
                              }
                            >
                              {key.Name}
                            </span>
                            {xAxis > halfWidth ? (
                              <div
                                className="pointerArrow right"
                                style={{
                                  right: "0px",
                                  width: mainWidth - (xAxis + 10) + "px",
                                }}
                              ></div>
                            ) : (
                              <>
                                <div
                                  className="pointerArrow left"
                                  style={{
                                    left: "0px",
                                    width: xAxis - 15 + "px",
                                  }}
                                ></div>
                              </>
                            )}
                            {/* <div className="pointerItem">
                            <div className="pointerMarker"></div>
                          </div> */}
                          </div>
                        );
                        // arrow code end
                      })}
                </div>

                <div className="xraySlider">
                  <Slider
                    onChange={(sl) => this.setState({ opacity: sl / 100 })}
                    vertical
                    value={this.state.opacity * 100}
                  />
                </div>
              </div>
              <div className="xRayBoxDetail">
                <div className="xRayBoxSelectOption">
                  {xmlGrp && xmlGrp["Inclusion"] && (
                    <div className="xRayBoxItem">
                      <h2>Inclusion</h2>
                      <ul>
                        {[
                          ...new Set(
                            xmlGrp["Inclusion"]
                              .map((item) => ({
                                ...item,
                                point1: Number(item?.Point?.split(",")?.[0]),
                                name: item?.Name?.[2],
                                description: item?.description || "-",
                              }))
                              // .sort(function (a, b) {

                              //   return a.name - b.name;
                              // })
                              .map((item) => {
                                return [item.Name, item.description];
                              }),
                          ),
                        ].map((i, index) => (
                          <li
                            className={
                              this.state.selectedInc === i[0] ? "active" : null
                            }
                            onClick={() =>
                              this.setState({
                                selectedInc: i[0],
                                selectedType: "Inclusion",
                              })
                            }
                            key={index}
                          >
                            {i[0]} - {i[1]}
                          </li>
                        ))}
                        {/* {xmlGrp["Inclusion"].map((i) => (
                          <li
                            className={this.state.selectedInc === i && "active"}
                            onClick={() => this.setState({ selectedInc: i })}
                          >
                            {i.Name} - Medium Side Black Inclusion
                          </li>
                        ))} */}
                      </ul>
                    </div>
                  )}
                  {xmlGrp && xmlGrp["Key To symbol"] && (
                    <div className="xRayBoxItem">
                      <h2>Key To Symbols (GIA)</h2>
                      <ul>
                        {[
                          ...new Set(
                            xmlGrp["Key To symbol"].map((item) => item.Name),
                          ),
                        ].map((i, index) => (
                          <li
                            className={
                              this.state.selectedInc === i ? "active" : null
                            }
                            onClick={() =>
                              this.setState({
                                selectedInc: i,
                                selectedType: "Key To symbol",
                              })
                            }
                            key={index}
                          >
                            {i}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {xmlGrp && xmlGrp["Open"] && (
                    <div className="xRayBoxItem">
                      <h2>Open</h2>
                      <ul>
                        {[
                          ...new Set(xmlGrp["Open"].map((item) => item.Name)),
                        ].map((i, index) => (
                          <li
                            className={this.state.selectedInc === i ? "active" : ''}
                            onClick={() =>
                              this.setState({
                                selectedInc: i,
                                selectedType: "Open",
                              })
                            }
                            key={index}
                          >
                            {i}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="xRayWrapperRight">
              <div className="xrayDetailInnerItem">
                <h2 className="detailSmallTitle" onClick={this.handleDetail}>
                  Diamond Details{" "}
                  <img
                    src={this.state.openDetail ? downArrowSVG : upArrowSVG}
                    className="arrow-img"
                    alt=""
                  ></img>
                </h2>
                {this.state.openDetail && (
                  <div className="diamondDetailMoreHalf">
                    <div className="detail">
                      <span>Packet No</span>
                      <span>{xRayData.vStnId}</span>
                      <span>Report No</span>
                      <span>{xRayData.WRAP_REPORT_NO}</span>
                    </div>
                    <div className="detail">
                      <span>Lab</span>
                      <span>{xRayData.lbNm}</span>
                      <span>Rap.($)</span>
                      <span>{xRayData.rap}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="xrayDetailInnerItem">
                <h2 className="detailSmallTitle" onClick={this.handleGrading}>
                  Diamond Grading{" "}
                  <img
                    src={this.state.openGrading ? downArrowSVG : upArrowSVG}
                    alt=""
                    className="arrow-img"
                  ></img>
                </h2>
                {this.state.openGrading && (
                  <div className="diamondDetailMoreHalf">
                    <div className="detail">
                      <span>Shape </span>
                      <span>{xRayData.shpNm}</span>
                      <span>Cut</span>
                      <span>{xRayData.cutNm}</span>
                    </div>
                    <div className="detail">
                      <span>Carat </span>
                      <span>{xRayData.crt}</span>
                      <span>Polish</span>
                      <span>{xRayData.polNm}</span>
                    </div>
                    <div className="detail">
                      <span>Color</span>
                      <span>{xRayData.colNm}</span>
                      <span>Symmetry </span>
                      <span>{xRayData.symNm}</span>
                    </div>
                    <div className="detail">
                      <span>Clarity</span>
                      <span>{xRayData.clrNm}</span>
                      <span>Fluorescence </span>
                      <span>{xRayData.fluNm}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="xrayDetailInnerItem">
                <h2
                  className="detailSmallTitle"
                  onClick={this.handleMeasurement}
                >
                  Measurements{" "}
                  <img
                    src={this.state.openMeasurement ? downArrowSVG : upArrowSVG}
                    className="arrow-img"
                    alt=""
                  ></img>
                </h2>
                {this.state.openMeasurement && (
                  <div className="diamondDetailMoreHalf">
                    <div className="detail">
                      <span>Shade </span>
                      <span>{xRayData.shdNm}</span>
                      <span>Crown Angle </span>
                      <span>{xRayData.cAng}</span>
                    </div>
                    <div className="detail">
                      <span>Table % </span>
                      <span>{xRayData.tblPer}</span>
                      <span>Crown Height </span>
                      <span>{xRayData.cHgt}</span>
                    </div>
                    <div className="detail">
                      <span>Depth %</span>
                      <span>{xRayData.depPer}</span>
                      <span>Pav Angle</span>
                      <span>{xRayData.pAng}</span>
                    </div>
                    <div className="detail">
                      <span>Length</span>
                      <span>{xRayData.length}</span>
                      <span>Pav Height</span>
                      <span>{xRayData.pHgt}</span>
                    </div>
                    <div className="detail">
                      <span>Width</span>
                      <span>{xRayData.width}</span>
                      <span>Girdle</span>
                      <span>{xRayData.girdleStr}</span>
                    </div>
                    <div className="detail">
                      <span>Depth</span>
                      <span>{xRayData.height}</span>
                      <span>Culet</span>
                      <span>{xRayData.cultNm}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="xrayDetailInnerItem">
                <h2 className="detailSmallTitle" onClick={this.handleInclusion}>
                  Inclusion Details{" "}
                  <img
                    src={this.state.openInclusion ? downArrowSVG : upArrowSVG}
                    className="arrow-img"
                    alt=""
                  ></img>
                </h2>
                {this.state.openInclusion && (
                  <div className="diamondDetailMoreHalf">
                    <div className="detail">
                      <span>Center Natts</span>
                      <span>{xRayData.blkTblNm}</span>
                      <span>Table Open </span>
                      <span>{xRayData.opTblNm}</span>
                    </div>
                    <div className="detail">
                      <span>Side Natts</span>
                      <span>{xRayData.blkSdNm}</span>
                      <span>Crown Open </span>
                      <span>{xRayData.opCrwnNm}</span>
                    </div>
                    <div className="detail">
                      <span>Center White</span>
                      <span>{xRayData.wTblNm}</span>
                      <span>Pavillion Open </span>
                      <span>{xRayData.opPavNm}</span>
                    </div>
                    <div className="detail">
                      <span>Side White</span>
                      <span>{xRayData.wSdNm}</span>
                      <span>Eye Clean </span>
                      <span>{xRayData.eClnNm}</span>
                    </div>
                    <div className="detail">
                      <span>Laser Ins</span>
                      <span>{xRayData.WRAP_LASER_INC}</span>
                      <span>Brilliancy </span>
                      <span>{xRayData.brlncyNm}</span>
                    </div>
                    <div className="detail">
                      <span>Heart & Arrow</span>
                      <span>{xRayData.hANm}</span>
                      <span className="border-bottom">Ratio </span>
                      <span className="border-bottom">
                        {xRayData.ratio ? xRayData.ratio : ""}
                      </span>
                    </div>
                    <div className="detail">
                      <span>Key To symbol </span>
                      <span className="border-right">{xRayData.kToSStr}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default XRAY;
