/* eslint-disable */
import React from "react";
import "./countBox.less";
import { EventManager } from "../../../services/util";

const CountBox = ({ count: initialCount, ...rest }) => {
  const [count, setCount] = React.useState(initialCount);

  React.useEffect(() => {
    setCount(initialCount);
  }, [initialCount]);

  React.useEffect(() => {
    const unlisten = EventManager.listen(
      "@notifications/unReadCount",
      ({ unReadCount }) => {
        setCount(unReadCount);
      },
    );
    return unlisten;
  }, []);

  return (
    Boolean(count) && (
      <div className={count > 9 ? `countBox1` : `countBox`}>{count}</div>
    )
  );
};
export default CountBox;
