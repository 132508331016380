/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { Form, notification, Drawer } from 'antd';
import _, { upperCase, isBoolean } from 'lodash';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import { LOCAL_STORAGE_VAR, REGISTRATION_TYPES_DATA } from '../../constants/Common';
import UtilService from '../../services/util';
import IntlMessages from '../../util/IntlMessages';
import OpenNotification from '../common/CommonButton/OpenNotification';
import Loader from '../common/Loader';
import 'react-intl-tel-input/dist/main.css';
import Heading from '../common/Heading';
import InputBlock from '../common/InputBlock';
import SelectOption from '../common/SelectOption';
import TermsCondition from '../TermsConditionPopup';
import {
  GET_COUNTRY_LIST,
  FETCH_SELLERS,
  GET_STATE_LIST,
  GET_CITY_LIST,
  SIGNUP_USER_FRONT,
  GET_MASTERS,
  DUPLICATE_EMAIL,
} from '../../constants/apiConstant';
import CheckBox from '../common/CheckBox';
import Logo from '../Header/Logo';
import DownloadBlock from '../common/DownloadBlock';
import { handleMobileNumber } from '../../services/Commonfunction';

class QuickRegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        registrationType: REGISTRATION_TYPES_DATA.INTERNATIONAL,
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
        countryCode: "",
        companyName: "",
        natureOfOrg: [],
        address: "",
        country: {},
        state: {},
        city: {},
        cityText: "",
        pinCode: "",
        fax: "",
        phone1: "",
        phone2: "",
        mobile: "",

        broker: false,
        brokerName: "",
        brokerEmail: "",
        brokerMobile: "",
        brokerPhoneNo: "",
        selectedPersonalDocType: "",
        isTermsCondAgree: false,
        updateRequired: false,
        businessType: "",
        seller: "",
      },
      registrationType: REGISTRATION_TYPES_DATA.INTERNATIONAL,
      tradeRefInfo: [],
      allCountries: [],
      allCities: [],
      countries: [],
      states: [],
      cities: [],
      isDeviceUrl: false,
      visible: false,
      sellers: [],
      allSellers: [],
      errors: {},
      BUSINESS_TYPE: [],
      DOC_TYPE_BUSINESS: [],
      DOC_TYPE_PERSONAL: [],
      NATURE_OF_ORG: [],
    };
  }

  componentDidMount() {
    let urlQuery = Object.fromEntries(
      new URLSearchParams(window.location.search),
    );
    if (!isEmpty(urlQuery))
      this.setState({ formData: { ...this.state.formData, ...urlQuery } });
    let isDeviceUrl = window.location.pathname.includes("/device/quick-signup");
    this.setState({ isDeviceUrl: isDeviceUrl });
    this.fetchCountries();
    this.fetchSellers();
    this.getMasters();
  }

  getMasters = () => {
    let self = this;
    let obj = {
      ...GET_MASTERS,
      request: {
        masters: ["BUSINESS_TYPE", "NATURE_OF_ORG"],
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) throw err;
      else if (data && data.code === "OK") {
        self.setState({
          BUSINESS_TYPE: data.data.BUSINESS_TYPE || [],
          NATURE_OF_ORG: data.data.NATURE_OF_ORG || [],
        });
      }
    });
  };

  fetchCountries() {
    let self = this;

    let obj = {
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        if (
          self.state.formData.registrationType ===
          REGISTRATION_TYPES_DATA.INTERNATIONAL
        ) {
          let countries = data.data;
          self.setState({
            allCountries: countries || [],
            countries: countries || [],
          });
        } else {
          self.setState({
            allCountries: data.data || [],
            countries: data.data || [],
          });
        }
      }
    });
  }

  fetchSellers() {
    let self = this;

    let obj = {
      // method: "post",
      // url: "/front/sellers",
      ...FETCH_SELLERS,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        data.data = data.data.map((d) => {
          d.name = UtilService.getFullName(d);
          return d;
        });
        self.setState({
          //orderBy(users, ['user', 'age'], ['asc', 'desc'])
          sellers: orderBy(data.data, "firstName", "asc") || [],
          allSellers: data.data || [],
        });
      }
    });
  }

  fetchStates(countryId, cb) {
    let self = this;

    let obj = {
      ...GET_STATE_LIST,
      request: {
        country: countryId,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        self.setState({
          states: data.data || [],
        });
        cb();
      }
    });
  }

  fetchCities(stateId, countryId) {
    let self = this;

    let obj = {
      ...GET_CITY_LIST,
      request: {
        state: stateId,
        country: countryId,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        if (stateId) {
          self.setState({
            cities: [...data.data, { name: "Others", id: "-" }] || [],
          });
        } else if (countryId) {
          self.setState({
            cities: [...data.data, { name: "Others", id: "-" }] || [],
          });
        }
      }
    });
  }

  handleOnChange(key, value) {
    let { formData, errors } = this.state;
    formData[key] = value;
    delete errors[key];
    this.setState({ formData, errors });
  }

  handleCheckDuplicate(key, value) {
    let { formData, errors } = this.state;
    formData[key] = value;
    // delete errors[key];
    var err_message = `Please enter valid ${key}`;
    // const validField = ["email", "brokerEmail", "mobile", "brokerMobile"]
    if (key === "username") {
      if (isEmpty(formData["username"])) {
        errors["username"] = true;
      } else {
        const obj = {
          ...DUPLICATE_EMAIL,
          request: { email: formData["username"] },
        };

        UtilService.callApi(obj, (err, res) => {
          if (res?.code === "OK") {
            OpenNotification({ type: "error", title: res?.message });
            this.handleOnChange("username", "");
            errors["username"] = true;
          }
        });
      }
    }
    errors["username"] &&
      OpenNotification({
        type: "error",
        title: err_message,
      });
    this.setState({ errors });
  }

  handleRequiredError(fieldName, value) {
    let { formData, errors } = this.state;
    formData[fieldName] = value;
    const err_message = `Please enter valid ${fieldName}`;

    if (!formData[fieldName] || isEmpty(formData[fieldName])) {
      errors[fieldName] = true;
    }

    if (errors[fieldName]) {
      OpenNotification({
        type: "error",
        title: err_message,
      });
    }
    this.setState({ errors });
  }

  handleErrorForEmailPhone(key, value) {
    let { formData, errors } = this.state;
    formData[key] = value;
    delete errors[key];
    var err_message = "Please enter valid input.";
    // const validField = ["email", "brokerEmail", "mobile", "brokerMobile"]
    if (
      key === "email" ||
      key === "businessEmail" ||
      key === "brokerEmail" ||
      key === "tradeRefEmail"
    ) {
      if (
        !formData[key].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        errors[key] = true; //"Please enter valid Email.";
        err_message = "Please enter valid email id.";
      } else {
        errors[key] = undefined;
      }
    }

    if (
      key === "mobile" ||
      key === "brokerMobile" ||
      key === "businessMobile" ||
      key === "businessPhone1" ||
      key === "tradeRefMobile"
    ) {
      if (!formData[key] || isEmpty(formData[key])) {
        errors[key] = true;
      } else if (formData[key].length > 5) {
        if (
          formData["registrationType"] ===
          REGISTRATION_TYPES_DATA.NORTH_AMERICA &&
          formData[key] &&
          !formData[key].match(/^[0-9]{10}$/)
        ) {
          errors[key] = true;
        } else if (
          formData["registrationType"] !==
          REGISTRATION_TYPES_DATA.NORTH_AMERICA &&
          formData[key] &&
          !formData[key].match(/^[0-9]{6,17}$/)
        ) {
          errors[key] = true;
        } else {
          errors[key] = undefined;
        }
      } else {
        errors[key] = true;
      }
      if (errors[key]) {
        err_message = "Please enter valid mobile number.";
      }
    }
    errors[key] &&
      OpenNotification({
        type: "error",
        title: err_message,
      });
    this.setState({ errors });
  }

  handleCountryChange(
    e,
    countryValue,
    belongsTostate = "state",
    belongsTocity = "city",
  ) {
    let country = this.state.countries.filter((c) => c.id === e)[0];
    if (country) {
      let formData = this.state.formData;
      formData[countryValue] = country ? country : {};
      // if(belongsTostate === 'state' && belongsTocity === 'city'){
      //   formData.state = {};
      //   formData.city = {};
      // }else{
      formData[belongsTostate] = {};
      formData[belongsTocity] = {};
      // }
      this.setState(
        {
          formData: formData,
          states: [],
          cities: [],
          allCities: [],
        },
        () => {
          this.fetchStates(e);
          this.fetchCities(null, e);
        },
      );
    }
  }

  handleStateChange(e, satateValue, belongsTocity = "city") {
    const { errors } = this.state;
    let state = this.state.states.filter((c) => c.id === e)[0];
    if (state) {
      let formData = this.state.formData;
      formData[belongsTocity] = {};
      formData[satateValue] = state ? state : {};
      delete errors[`${satateValue}`];
      this.setState(
        {
          formData: formData,
          cities: [],
          errors,
        },
        () => {
          this.fetchCities(e);
        },
      );
    }
  }

  handleCityChange(e, cityValue) {
    const { errors } = this.state;

    let city = this.state.cities.filter((c) => c.id === e)[0];
    if (city) {
      let formData = this.state.formData;
      formData[cityValue] = city ? city : {};
      delete errors[`${cityValue}`];

      this.setState({ formData: formData, errors });
    }
  }

  handleSignup = (e) => {
    e.preventDefault();
    let self = this;
    self.setState({ isLoading: true });
    let formData = clone(this.state.formData);

    let commonInfo = {
      // registrationType:
      //   this.state?.registrationType ??
      //   this.state.formData?.registrationType ??
      //   formData.registrationType,
      firstName: isEmpty(formData.firstName) ? null : formData.firstName,
      lastName: isEmpty(formData.lastName) ? null : formData.lastName,
      username: isEmpty(formData.username) ? null : formData.username,
      password: isEmpty(formData.password) ? null : formData.password,
      confirmPassword: isEmpty(formData.confirmPassword)
        ? null
        : formData.confirmPassword,
      seller: isEmpty(formData.seller) ? null : formData.seller,
      country: isEmpty(formData.country) ? null : formData.country.id,
      state: isEmpty(formData.state) ? null : formData.state.id,
      city: isEmpty(formData.city) ? null : formData.city.id,
      cityText: isEmpty(formData.cityText) ? undefined : formData.cityText,
      address: isEmpty(formData.address) ? null : formData.address,
      pinCode: isEmpty(formData.pinCode) ? "" : formData.pinCode, //null
      phone1: isEmpty(formData.phone1) ? "" : formData.phone1, //null
      phone2: isEmpty(formData.phone2) ? "" : formData.phone2, //null
      mobile: isEmpty(formData.mobile) ? null : formData.mobile,
      email: isEmpty(formData.email) ? null : formData.email,
      isQuickRegister: true,
    };
    let businessInfo = {
      companyName: isEmpty(formData.companyName)
        ? null
        : formData.companyName,
      natureOfOrg: isEmpty(formData.natureOfOrg) ? null : formData.natureOfOrg,
    };

    let payload = {
      ...commonInfo,
      // registrationType: self.registrationType ?? formData?.registrationType,
      businessInfo: businessInfo,
    };

    let obj = {
      // method: "post",
      // url: "/front/register",
      ...SIGNUP_USER_FRONT,
      request: payload,
    };

    if (self.handleValidation()) {
      UtilService.callApi(obj, function (err, data) {
        self.setState({ isLoading: false });
        if (err && err.data) {
          if (err.data.code === "E_USER_NOT_FOUND") {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
          if (
            [
              "E_DUPLICATE_EMAIL",
              "E_DUPLICATE",
              "E_DUPLICATE_MOBILE",
              "E_DUPLICATE_USERNAME",
            ].includes(err.data.code)
          ) {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
        }
        if (data && data.code === "OK") {
          OpenNotification({
            type: "success",
            title: data.message,
          });
          // self.setState({ registerButton: true });
          // if (self.state.isDeviceUrl) {
          //   window.RegisterCallBack.postMessage(data.message);
          // }
          localStorage.setItem(
            `${LOCAL_STORAGE_VAR}-thank-you`,
            JSON.stringify(true),
          );
          self.props.history.push("/thank-you");
          // OpenNotification({
          //   type: "success",
          //   title: "Registered successfully !",
          // });
        }
      });
    } else {
      self.setState({ isLoading: false });
    }
  };

  handleValidation() {
    let self = this;
    let formData = self.state.formData;
    let errors = self.state.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFields = [];

    if (!formData['firstName'] || isEmpty(formData['firstName'])) {
      blankField.push('First Name');
      formIsValid = false;
      errors['firstName'] = true;
    } else if (formData['firstName']) {
      if (!formData['firstName'].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push('firstName');
        formIsValid = false;
        errors['firstName'] = true; //"Please enter valid First Name.";
      } else {
        errors['firstName'] = undefined;
      }
    }

    if (!formData['lastName'] || isEmpty(formData['lastName'])) {
      blankField.push('Last Name');
      formIsValid = false;
      errors['lastName'] = true;
    } else if (formData['lastName']) {
      if (!formData['lastName'].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push('lastName');
        formIsValid = false;
        errors['lastName'] = true; //"Please enter valid Last Name.";
      } else {
        errors['lastName'] = undefined;
      }
    }

    if (!formData['email'] || isEmpty(formData['email'])) {
      blankField.push('Email Address');
      formIsValid = false;
      errors['email'] = true;
    } else if (formData['email']) {
      if (
        !formData['email'].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        invalidFields.push('Email');
        formIsValid = false;
        errors['email'] = true; //"Please enter valid Email.";
      } else {
        errors['email'] = undefined;
      }
    }

    if (!formData['username'] || isEmpty(formData['username'])) {
      blankField.push('Username');
      formIsValid = false;
      errors['username'] = true;
    }

    if (!formData['password']) {
      blankField.push(' Password');
      formIsValid = false;
      errors['password'] = true;
    } else if (
      formData['password'] &&
      // (!value.match(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/) || value.length > 15 || value.length < 6)
      formData['password'].length < 4
    ) {
      invalidFields.push('Password');
      formIsValid = false;
      errors['password'] = true; //"Password must be 3 digit long.";
    }

    if (!formData['confirmPassword']) {
      blankField.push('Confirm Password');
      formIsValid = false;
      errors['confirmPassword'] = true; //"Please enter confirm password";
    } else if (
      formData &&
      formData['confirmPassword'] &&
      formData['confirmPassword'].length &&
      formData['confirmPassword'] !== formData.password
    ) {
      invalidFields.push('Confirm Password');
      formIsValid = false;
      errors['confirmPassword'] = true; //"Please make sure your passwords match.";
    }

    if (!formData['address'] || isEmpty(formData['address'])) {
      blankField.push('Address');
      formIsValid = false;
      errors['address'] = true;
    }

    if (!formData['country'] || isEmpty(formData['country'])) {
      blankField.push('Country');
      formIsValid = false;
      errors['country'] = true;
    }

    if (!formData['state'] || isEmpty(formData['state'])) {
      blankField.push('State');
      formIsValid = false;
      errors['state'] = true;
    }

    if (!formData['city'] || isEmpty(formData['city'])) {
      blankField.push('City');
      formIsValid = false;
      errors['city'] = true;
    }

    if (!formData['pinCode'] || isEmpty(formData['pinCode'])) {
      blankField.push('Pincode');
      formIsValid = false;
      errors['pinCode'] = true;
    }
    //  else if (
    //   formData['pinCode'] &&
    //   !formData['pinCode'].match(/^[0-9]{4,10}$/)
    // ) {
    //   invalidFields.push('Pincode');
    //   formIsValid = false;
    //   errors['pinCode'] = true;
    // } else if (
    //   formData['pinCode'] &&
    //   !formData['pinCode'].match(/^[0-9]{4,6}$/)
    // ) {
    //   invalidFields.push('Pincode');
    //   formIsValid = false;
    //   errors['pinCode'] = true;
    // }

    if (!formData["mobile"] || isEmpty(formData["mobile"])) {
      blankField.push("Mobile No.");
      formIsValid = false;
      errors["mobile"] = true;
    } else if (
      formData["mobile"] &&
      !formData["mobile"].match(/^[0-9]{6,17}$/)
    ) {
      invalidFields.push("Mobile No.");
      formIsValid = false;
      errors["mobile"] = true;
    } else {
      errors["mobile"] = undefined;
    }

    if (formData['phone1'] && (formData['phone1'].length < 7 || formData['phone1'].length > 15)) {
      invalidFields.push('Phone 1');
      formIsValid = false;
      errors['phone1'] = true;
    }

    if (formData['phone2'] && (formData['phone2'].length < 7 || formData['phone2'].length > 15)) {
      invalidFields.push('Phone 2');
      formIsValid = false;
      errors['phone2'] = true;
    }

    if (!formData.companyName || isEmpty(formData.companyName)) {
      blankField.push('Company Name');
      formIsValid = false;
      errors['companyName'] = true;
    }

    if (isEmpty(formData['natureOfOrg'])) {
      blankField.push("Company's Best");
      formIsValid = false;
      errors['natureOfOrg'] = true;
    }

    if (isEmpty(formData["seller"])) {
      blankField.push("Sales Person");
      formIsValid = false;
      errors["seller"] = true;
    }

    if (formData.city.id === "-") {
      if (!formData["cityText"] || isEmpty(formData["cityText"])) {
        blankField.push("City");
        formIsValid = false;
        errors["cityText"] = true;
      } else if (
        formData["cityText"] &&
        !formData["cityText"].match(/^[a-zA-Z ]+$/)
      ) {
        invalidFields.push("City");
        errors["cityText"] = true;
        formIsValid = false;
      } else {
        errors["cityText"] = undefined;
      }
    }

    if (invalidFields.length > 0) {
      let arr = invalidFields;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    self.setState({ errors: errors });
    return formIsValid;
  }

  handleCancel(e) {
    e.preventDefault();
    this.setState({
      formData: {
        registrationType: REGISTRATION_TYPES_DATA.INTERNATIONAL,
        firstName: "",
        lastName: "",
        username: "",
        password: "",
        email: "",
        confirmPassword: "",
        countryCode: "",
        companyName: "",
        natureOfOrg: [],
        address: "",
        country: {},
        state: {},
        city: {},
        cityText: "",
        pinCode: "",
        fax: "",
        phone1: "",
        phone2: "",
        mobile: "",

        broker: false,
        brokerName: "",
        brokerEmail: "",
        brokerMobile: "",
        brokerPhoneNo: "",
        selectedPersonalDocType: "",
        isTermsCondAgree: false,
        updateRequired: false,
        businessType: "",
        seller: "",

        businessEmail: "",
      },
      tradeRefInfo: [],
      errors: {},
    });
  };

  TermPopup = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { formData, countries, states, cities, errors, sellers } = this.state;

    return (
      <div className={`loginDetail registerForm ${this.state.isLoading ? 'loading-indicator' : ''} `}>
        <div className="registerMainScroll">
          <Loader loading={this.props.loading} />
          <div className="loginHead">
            <div className="loginLogo">
              <Logo />
            </div>
            <h3>
              <IntlMessages id="app.signup.registration" />
            </h3>
          </div>
          <div className="registerMainBlock">
            <Form autoComplete="off" onSubmit={this.handleSignup}>
              <div id="section_1">
                <Heading title="Login Information" className="popupInnerTitle p-0" />
                <div className="quickRegisterFiledBlock">
                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("firstName", e.target.value.trim())
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("firstName", e.target.value)
                    }
                    hasError={errors["firstName"] ? true : false}
                    value={formData.firstName}
                    type="text"
                    label="First Name *"
                    placeholder="First Name"
                  />
                  <InputBlock
                    onChange={(e) =>
                      this.handleOnChange("lastName", e.target.value.trim())
                    }
                    onBlur={(e) =>
                      this.handleRequiredError("lastName", e.target.value)
                    }
                    hasError={errors["lastName"] ? true : false}
                    value={formData.lastName}
                    type="text"
                    label="Last Name *"
                    placeholder="Last Name"
                  />

                  <InputBlock
                    onChange={(e) => this.handleOnChange('email', e.target.value)}
                    onBlur={(e) => this.handleErrorForEmailPhone('email', e.target.value)}
                    hasError={errors['email'] ? true : false}
                    value={formData.email}
                    type="email"
                    label="Email *"
                    placeholder="Email"
                  />

                  <InputBlock
                    onChange={(e) => this.handleOnChange('username', e.target.value.trim())}
                    hasError={errors['username'] ? true : false}
                    value={formData.username}
                    type="text"
                    label="Username *"
                    placeholder="Username"
                  />

                  <InputBlock
                    onChange={(e) => this.handleOnChange('password', e.target.value)}
                    hasError={errors['password'] ? true : false}
                    value={formData.password}
                    type="password"
                    label="Password *"
                    placeholder="Password"
                  />

                  <InputBlock
                    onChange={(e) => this.handleOnChange('confirmPassword', e.target.value)}
                    hasError={errors['confirmPassword'] ? true : false}
                    value={formData.confirmPassword}
                    type="password"
                    label="Confirm Password *"
                    placeholder="Confirm Password"
                  />
                </div>
              </div>
              <div id="section_2">
                <Heading title="Contact Information" className="popupInnerTitle p-0" />
                <div className="quickRegisterFiledBlock">
                  <InputBlock
                    onChange={(e) => this.handleOnChange('address', e.target.value)}
                    hasError={errors['address'] ? true : false}
                    value={formData.address}
                    type="text"
                    label="Address *"
                    placeholder="Address"
                  />
                  <SelectOption
                    defaultValue="Country"
                    capitalize
                    value={
                      !isEmpty(formData.country)
                        ? upperCase(formData.country.name)
                        : "Country"
                    }
                    selectValue={countries}
                    // disabled={formData.registrationType === 2 ? true : false}
                    onChange={(e) => this.handleCountryChange(e, "country")}
                    label="Select Country*"
                    showSearch
                    hasError={errors["country"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="State"
                    capitalize
                    value={
                      !isEmpty(formData.state)
                        ? upperCase(formData.state.name)
                        : "State"
                    }
                    selectValue={states}
                    onChange={(e) => this.handleStateChange(e, "state")}
                    label="Select State *"
                    showSearch
                    hasError={errors["state"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="City"
                    capitalize
                    value={
                      !isEmpty(formData.city)
                        ? upperCase(formData.city.name)
                        : "City"
                    }
                    selectValue={cities}
                    onChange={(e) => this.handleCityChange(e, "city")}
                    showSearch
                    label="Select City*"
                    hasError={errors["city"] ? true : false}
                  />
                  {this.state.formData.city.id === '-' && (
                    <InputBlock
                      onChange={(e) => this.handleOnChange('cityText', e.target.value)}
                      hasError={errors['cityText'] ? true : false}
                      type="text"
                      value={formData.cityText}
                      label="City*"
                      placeholder="City"
                    />
                  )}
                  <InputBlock
                    onChange={(e) => this.handleOnChange('pinCode', e.target.value)}
                    hasError={errors['pinCode'] ? true : false}
                    type="text"
                    value={formData.pinCode}
                    label={'Zip Code / Pin Code *'}
                    placeholder={'Zip Code / Pin Code'}
                  />
                  <InputBlock
                    mobileISD
                    ISDplaceholder="ISD"
                    onChange={(e) => this.handleOnChange('mobile', e.target.value)}
                    onBlur={(e) => this.handleErrorForEmailPhone('mobile', e.target.value)}
                    onKeyPress={handleMobileNumber}
                    value={formData.mobile}
                    hasError={errors['mobile'] ? true : false}
                    type="number"
                    min="6"
                    label="Mobile No *"
                    placeholder="Mobile No "
                  />
                  <InputBlock
                    mobileISD
                    mobileSTD
                    ISDplaceholder="ISD"
                    STDplaceholder="STD"
                    onChange={(e) => this.handleOnChange('phone1', e.target.value)}
                    onKeyPress={handleMobileNumber}
                    value={formData.phone1}
                    hasError={errors['phone1'] ? true : false}
                    type="number"
                    label="Phone No 1"
                    placeholder="Phone No 1"
                  />
                  <InputBlock
                    mobileISD
                    mobileSTD
                    ISDplaceholder="ISD"
                    STDplaceholder="STD"
                    onChange={(e) => this.handleOnChange('phone2', e.target.value)}
                    onKeyPress={handleMobileNumber}
                    value={formData.phone2}
                    hasError={errors['phone2'] ? true : false}
                    type="number"
                    label="Phone No 2"
                    placeholder="Phone No 2"
                  />
                </div>
              </div>
              <div id="section_3">
                <Heading title="Business Information" className="popupInnerTitle p-0" />
                <div className="quickRegisterFiledBlock">
                  <InputBlock
                    type="text"
                    label="Company Name *"
                    placeholder="Company Name"
                    onChange={(e) => this.handleOnChange('companyName', e.target.value)}
                    value={formData.companyName}
                    hasError={errors['companyName'] ? true : false}
                  />
                  <SelectOption
                    placeholder="What Describes Your Company Best?"
                    value={formData.natureOfOrg}
                    selectValue={this.state.NATURE_OF_ORG}
                    onChange={(e) => {
                      this.handleOnChange('natureOfOrg', e);
                    }}
                    label={`What Describes Your Company Best? *`}
                    hasError={errors['natureOfOrg'] ? true : false}
                  />
                </div>
              </div>
              <Heading title="Sales Information" className="popupInnerTitle p-0" />
              <div className="quickRegisterFiledBlock">
                <SelectOption
                  defaultValue="Sales Person"
                  onChange={(e) => this.handleOnChange('seller', e)}
                  value={formData.seller ? formData.seller : 'Sales Person'}
                  selectValue={sellers}
                  showSearch
                  label="Select Sales Person *"
                  hasError={errors['seller'] ? true : false}
                />
              </div>
              <div>
                <CheckBox
                  checked={formData.isTermsCondAgree}
                  onChange={(e) => this.handleOnChange('isTermsCondAgree', e.target.checked)}
                  label={
                    <span>
                      <span>I have read and accept </span>
                      <a className="linkTag" onClick={this.TermPopup}>
                        Terms and Conditions.
                      </a>
                    </span>
                  }
                />
              </div>
              <Drawer
                title={false}
                onClose={this.onClose}
                visible={this.state.visible}
                className="diamondListinSidebar"
              >
                <TermsCondition />
              </Drawer>
              <div className="mt-30 loginButton">
                <button
                  style={!formData.isTermsCondAgree ? { cursor: 'not-allowed' } : {}}
                  onClick={(e) => this.handleSignup(e)}
                  disabled={!formData.isTermsCondAgree}
                  className="commonButton"
                >
                  <IntlMessages id="app.signup.registerButton" />
                </button>
                <button onClick={(e) => this.handleCancel(e)} className="commonOutline">
                  Reset
                </button>
              </div>
            </Form>
            <div className="registerLogLink">
              <IntlMessages id="app.already-have-acc" />
              &nbsp;
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (this.state.isDeviceUrl) {
                    window.LoginCallBack.postMessage('LoginCallBack');
                  } else {
                    window.location.href = '/';
                  }
                }}
              >
                <IntlMessages id="app.sign-In" />
              </a>
            </div>
          </div>
          <DownloadBlock />
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect((props) => {
    return {
      loading: props.commonData.loading,
    };
  }),
)(QuickRegisterForm);