/* eslint-disable */
import React, { Component } from "react";
import TrackStatusModal from "./TrackStatusModal";
import IntlMessages from "../../../../util/IntlMessages";
import { GET_TRACK_SHIPMENTS } from "../../../../constants/apiConstant";
import UtilService from "../../../../services/util";
class TrackList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shipment: false,
      trackid: null,
      shipments: [],
    };
  }
  componentDidMount() {
    this.getShipments();
  }
  modal = () => {
    this.setState({
      shipment: true,
    });
  };
  handleOk = () => {
    if (this.state.trackid) {
      this.setState({
        shipment: false,
      });
      window.open(`/track-diamond?${this.state.trackid}`, "_self");
    } else {
      this.setState({
        trackid: null,
      });
    }
  };
  handleChange = (e) => {
    this.setState({
      trackid: e,
    });
  };
  handleCancel = () => {
    this.setState({
      shipment: false,
    });
  };

  getShipments = () => {
    let self = this;
    let obj = {
      ...GET_TRACK_SHIPMENTS,
      request: { page: 1, limit: 10, sort: [{ createdAt: "DESC" }] },
      //filter: {},
    };

    UtilService.callApi(obj, function (err, data) {
      if (err) {
      } else if (data && data.code === "OK") {
        if (data.data) {
          //
          self.setState({ shipments: data.data.list });
        }
      }
    });
  };
  render() {
    const TrackListCard = (props) => {
      return (
        <div className="savedSearchItem">
          <div className="savedSearchHead">
            <div className="searchTitle">
              {(props.trackingNo && `Inv: ${props.invoiceNo}`) || "Inv: -"}
            </div>
            <div className="savedSearchDetail">
              <div className="detail">
                <span>
                  <IntlMessages id="app.Pieces" />
                </span>
                <span>{props.pieces}</span>
              </div>
              <div className="detail">
                <span>
                  <IntlMessages id="app.Carats" />
                </span>
                <span>{props.carat}</span>
              </div>
              <div className="detail">
                <span>
                  <IntlMessages id="app.Value" />
                </span>
                <span>{props.expAmt}</span>
              </div>
            </div>

            <div className="tracklistButton">
              {/* {props.trackingNo && ( */}
              <button
                className="commonButton"
                onClick={() => {
                  if (props.trackingNo) {
                    window.open(`/track-diamond?${props.trackingNo}`, "_self");
                  } else {
                  }
                }}
              >
                <IntlMessages id="app.Track" />
              </button>
              {/* )} */}
            </div>
          </div>
        </div>
      );
    };
    return (
      <div className="rightBlockItem">
        <div className="sacedSearchBox recentActivityBlock">
          {this.state.shipments.length >= 1
            ? this.state.shipments.map((ship, i) => {
                return (
                  ship.trackingNo >= 1 && (
                    <TrackListCard
                      key={i}
                      expAmt={ship.expAmt}
                      pieces={ship.pieces}
                      carat={ship.carat}
                      trackingNo={ship.trackingNo}
                      invoiceNo={ship.invoiceNo}
                      logisticsName={ship.logisticsName}
                    />
                  )
                );
              })
            : "No Shipments found."}
        </div>
        <TrackStatusModal
          trackVisible={this.state.shipment}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          handleChange={this.handleChange}
          trackid={this.state.trackid}
        />
      </div>
    );
  }
}
export default TrackList;
