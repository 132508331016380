/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Heading from "../../common/Heading";
import InputBlock from "../../common/InputBlock";
import SelectOption from "../../common/SelectOption";
export const userDetail = {
  userType: [
    { value: "Sub User" },
    { value: "Primary User" },
    { value: "Api User" },
  ],
};
class UserPopup extends Component {
  render() {
    return (
      <div>
        <div className="profileHead">
          <Heading title="Add User" className="popupInnerTitle"></Heading>
        </div>
        <div className="profileDetailSpace">
          <InputBlock
            placeholder="First Name"
            value="Vishal"
            label="First Name*"
          />
          <InputBlock
            placeholder="Last Name"
            value="Virani"
            label="Last Name*"
          />
          <InputBlock
            placeholder="Email"
            value="Vishal@demo.com"
            label="Email*"
          />
          <InputBlock
            placeholder="Mobile No"
            value="+91 9099223388"
            label="Mobile No.*"
          />
          <InputBlock
            placeholder="Username"
            value="Vishal Virani"
            label="Username*"
          />
          <SelectOption
            defaultValue="User Type"
            selectValue={userDetail.userType}
            label="Select User Type*"
          />
          <InputBlock
            placeholder="Whatsapp"
            value="+91 9099223388"
            label="Whatsapp"
          />
          <InputBlock placeholder="Wechat" value="Wechat" label="Wechat" />
          <InputBlock placeholder="Skype" value="VishalVirani" label="Skype" />
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton">Update</a>
          <a className="commonButton">Cancel</a>
        </div>
      </div>
    );
  }
}
export default withRouter(UserPopup);
