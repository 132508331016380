/* eslint-disable */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./accounting.less";
import TopFilter from "./TopFilter";
import AccountingTable from "./AccountingTable";
import {
  sendOTPAndVerification,
  getAccountingList,
  getAccountingListColumn,
} from "../../containers/APIFunctions";
import { emptyData, getState } from "../DiamondList";
import CommonModal from "../common/CommonModal";
import OpenNotification from "../common/CommonButton/OpenNotification";
import InputBlock from "../common/InputBlock";

function Accounting(props) {
  const dateFormat = "MMMM DD, YYYY";
  let history = useHistory();
  const [filterFormData, setFilterFormData] = useState({
    accountingType: "On Proforma Only",
    clientType: "International",
    customerName: null,
    invoiceNo: null,
    dateRange: null,
    pendingReconcile: false,
  });
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [count, setTableCount] = useState(null);
  const [tableColumn, setTableColumn] = useState(null);
  const [oTPModel, setOTPModel] = useState({
    visible: true,
    value: "",
    verify: false,
  });
  const [state, setState] = useState(() => _.cloneDeep(getState()));

  const viewButton = {
    noconfirm: true,
    nocart: true,
    noquote: true,
    nowatch: true,
    nofinalcalc: true,
    nooffice: true,
    nonote: true,
    nonocompare: true,
    noshare: true,
    nodownload: true,
    noprint: true,
    nopaginate: false,
  };

  useEffect(() => {
    setLoading(true);
    sendOTP();
  }, []);

  const sendOTP = () => {
    setLoading(true);
    let obj = {
      callType: "send otp",
    };
    sendOTPAndVerification(obj, (cb) => setLoading(cb ? false : true));
  };

  const verifyOTP = () => {
    setLoading(true);
    let obj = {
      otp: oTPModel.value,
      callType: "verify OTP",
    };

    sendOTPAndVerification(obj, (cb) => {
      if (cb === "OTP verified successfully") {
        fetchData(filterFormData);
      } else {
        setLoading(false);
        OpenNotification({ type: "error", title: cb });
      }
    });
  };

  const fetchData = (filter) => {
    setOTPModel({ ...oTPModel, visible: false });
    setFilterFormData(filter);
    if (filter) {
      let obj = {
        onlyOutStanding:
          filter.accountingType === "Outstanding Only" ? true : false,
        proformaOnly:
          filter.accountingType === "On Proforma Only" ? true : false,
        clientType: _.upperCase(filter.clientType), //["LOCAL", "INTERNATIONAL"]
        from: filter.dateRange
          ? filter.dateRange.from
            ? moment(filter.dateRange.from).format("YYYY-MM-DD")
            : null
          : null, // [YYYY-MM-DD]
        to: filter.dateRange
          ? filter.dateRange.to
            ? moment(filter.dateRange.to).format("YYYY-MM-DD")
            : null
          : null, // [YYYY-MM-DD]
        page: filter.page ? filter.page : state.page,
        limit: state.limit,
      };
      getAccountingListColumn((col) => setTableColumn(col));
      getAccountingList(obj, (list) => {
        if (!list) setLoading(false);
        return setTableData(list);
      });
    }
  };

  useEffect(() => {
    setLoading(tableData !== null ? false : true);
    setState({
      ...state,
      count: tableData ? (tableData.length > 0 ? tableData[0].TOT_ROWS : 0) : 0,
    }); //when you get total table count then add ass count, bcs count:15 its as for testing only
  }, [tableData, count]);

  const onFilterSearchAction = (data) => {
    setLoading(true);
    data ? fetchData(data) : fetchData(filterFormData);
  };

  const onPaginationChange = (data) => {
    let paginate_filter = {
      ...filterFormData,
      page: data,
    };
    fetchData(paginate_filter);
  };

  return (
    <div className="Accounting">
      <TopFilter
        filterFormData={filterFormData}
        setFilterFormData={setFilterFormData}
        dateFormat={dateFormat}
        onFilterSearch={(data) => onFilterSearchAction(data)}
      />
      <AccountingTable
        tableData={tableData}
        loading={loading}
        tableColumn={tableColumn}
        filterFormData={filterFormData}
        dateFormat={dateFormat}
        getState={getState}
        setLoading={setLoading}
        state={state}
        setState={(data) => setState(data)}
        {...viewButton}
        fetchData={(data) => onPaginationChange(data)}
      />
      {emptyData(loading, tableData ? tableData.length : 0)}

      <CommonModal
        visible={oTPModel.visible}
        handleCancel={() => history.goBack()}
        title={"OTP Verification"}
      >
        <div className="otpModal">
          <div className="searchPopupCommon">
            <div className="searchPopupTwoValue">
              <InputBlock
                type="number"
                label={"OTP :"}
                placeholder={"Enter OTP here.."}
                value={oTPModel.value}
                onChange={(e) =>
                  setOTPModel({ ...oTPModel, value: e.target.value })
                }
              />
              <div className="commonModalButton">
                <button className="fillButton" onClick={() => verifyOTP()}>
                  Submit
                </button>
                <button
                  className="outLineButton"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </button>
              </div>
              <div className="resend_otp text-center">
                <a onClick={() => sendOTP()}>Resend OTP click here.</a>
              </div>
            </div>
          </div>
        </div>
      </CommonModal>
    </div>
  );
}

export default Accounting;
