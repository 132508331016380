/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import { BASE_URL, DASHBOARD_MEDIA_URL } from "../../../constants/Common";
import Slider from "react-slick";
import { browserName, isSafari } from "react-device-detect";
class OneBannerGif extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    };

    let data = this.props && this.props.bannerData;
    let img = this.props && this.props.bannerData.images;
    let imgPath = `${BASE_URL}${img}`;
    return (
      <>
        <div className="homeContainer mb-0">
          <div className="homeBanner1 lightBg mb-0">
            <div className="homeBanner1Img">
              <Slider {...settings}>
                {img.map((f, index) => {
                  let url = "";
                  let ext =
                    typeof f === "string"
                      ? f?.split(".").pop().toLowerCase()
                      : f?.url?.split(".").pop().toLowerCase();

                  if (typeof f === "object") {
                    url = DASHBOARD_MEDIA_URL + f?.url;

                    if (!f?.url.startsWith("/")) {
                      url = DASHBOARD_MEDIA_URL + "/" + f?.url;
                    }
                  } else {
                    url = DASHBOARD_MEDIA_URL + f;

                    if (!f.startsWith("/")) {
                      url = DASHBOARD_MEDIA_URL + "/" + f;
                    }
                  }
                  return (
                    <div key={index}>
                      {["mp4", "mkv", "wmv"].includes(ext) ? (
                        browserName !== "Firefox" && (
                          <video loop autoPlay={!isSafari ? true : false}>
                            <source src={url}></source>
                          </video>
                        )
                      ) : (
                        <div className="homeBannerImageBlock">
                          <img src={url} alt="banner" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="homeBanner1Conetnt">
              {data && data.eventName && <h2>{data.eventName}</h2>}
              {data && data.description && <p>{data && data.description}</p>}
              {/* <h2>{(data && data.eventName) || "-"}</h2>
              <p>{(data && data.description) || "-"}</p> */}
              {/* <Link to="/"><IntlMessages id="app.showMore" /></Link> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OneBannerGif;
