/* eslint-disable */
import React, { useState } from "react";
import "./track.less";
import InputBlock from "../../components/common/InputBlock";
import { GET_SHIPMENT_DETAILS } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import OpenNotification from "../../components/common/CommonButton/OpenNotification";

const TrackShipment = () => {
  const [trackID, setTrackID] = useState(
    window.location.search.replace("?", ""),
  );
  const [shipmentObj, setShipmentObj] = useState({});
  const [iframe, setIframe] = useState(false);
  const [srcpath, setPath] = useState("");

  const shimentDetail = React.useCallback(() => {
    if (shipmentObj) {
      if (shipmentObj.logisticsName === "FEDEX") {
        window.open(
          `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${shipmentObj.trackingNo}`,
          "_blank",
        );
      }
      if (shipmentObj.logisticsName === "BRINKS") {
        setIframe(true);
        let path = `https://bgs01.brinksglobal.com/bleprod/trackTrace.bi?a=${shipmentObj.trackingNo}`;
        setPath(path);
      }
      if (shipmentObj.logisticsName === "SQL") {
        setIframe(true);
        let path = `https://sequel247.com/track/${shipmentObj.trackingNo}`;
        setPath(path);
      }
      if (shipmentObj.logisticsName === "MALCA-AMIT / JK") {
        setIframe(true);
        let path = `https://tracking.malca-amit.com/${shipmentObj.trackingNo}`;
        setPath(path);
      }
    }
  }, [shipmentObj]);

  React.useEffect(() => {
    shimentDetail();
  }, [shimentDetail]);

  const getShipmentsDetails = () => {
    if (!`${trackID}`.length)
      return OpenNotification({
        type: "error",
        title: "Please Enter Track ID.",
      });

    let obj = {
      method: GET_SHIPMENT_DETAILS.method,
      url: GET_SHIPMENT_DETAILS.url + "/" + trackID,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) {
        OpenNotification({
          type: "error",
          title: "Shipment not found !",
        });
      } else if (data && data.code === "OK" && data.data) {
        setShipmentObj(data.data);
      }
    });
  };

  return (
    <div className="trackShipment">
      <div className="trackShipmentWrapper">
        <div className="trackShipmentBlock">
          <img
            className="trackBg"
            src={require("../../assets/svg/Track/location-01-01.svg")}
            alt=""
          />
          <div className="trackshipmentInenr">
            <div className="trackShipmentTop">
              <img src={require("../../assets/svg/Track/track.svg")} alt="" />
              <span>Track Your Diamond</span>
            </div>
            <div className="trackShipmentInput">
              <InputBlock
                value={trackID}
                autoFocus
                onChange={(e) => {
                  setTrackID(e.target.value);
                }}
                onKeyPress={(evt) => {
                  if (
                    (evt.which < 97 || evt.which > 122) &&
                    (evt.which < 65 || evt.which > 90) &&
                    (evt.which < 48 || evt.which > 57) &&
                    evt.which !== 45
                  ) {
                    evt.preventDefault();
                  }
                }}
                placeholder="Enter Track No"
                hasError={trackID === "" ? true : false}
              />
              <button onClick={() => getShipmentsDetails()}>Track</button>
            </div>
            <p>Enter the Tracking number of the stone you have purchased.</p>
          </div>
          {iframe && (
            <iframe
              src={srcpath && srcpath}
              referrerPolicy="same-origin"
              title="Track Shipment"
              name="Shipment Status"
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrackShipment;
