/* eslint-disable */
import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import "./dateOption.less";
import classNames from "util/classNames";
const { RangePicker } = DatePicker;
const defaultDateFormat = "MM/DD/YYYY";

const DatePickerRange = (props) => {
  const {
    title,
    dateFormat = defaultDateFormat,
    date,
    onChange,
    onDateChange,
    disabledDate,
    ...restProps
  } = props;

  return (
    <div className={classNames(["leftSelectItem", props?.className])}>
      <div className="leftSelectTitle">
        <h2>{props.title}</h2>
        {props.image && (
          <img src={props.image} alt="icon" className="reloadImg" />
        )}
      </div>
      {props.Singledate ? (
        <DatePicker
          dropdownClassName="datePickerDropdown"
          className="SelectDateRange"
          disabledDate={props.disabledDate}
          onChange={onChange}
          format={dateFormat}
          value={props.date ? moment(props.date) : undefined}
        />
      ) : (
        <RangePicker
          dropdownClassName="datePickerDropdown"
          className="SelectDateRange"
          defaultValue={[
            moment(date[0], dateFormat),
            moment(date[1], dateFormat),
          ]}
          format={dateFormat}
          onChange={onChange}
          disabledDate={disabledDate}
        />
      )}
    </div>
  );
};
export default DatePickerRange;
