/* eslint-disable */
import React from "react";
import { LOCAL_STORAGE_VAR } from "../constants/Common";
import { ADMIN_LOGIN } from "../constants/apiConstant";
import UtilService from "../services/util";

function AdminRedirect() {
  const getRedirection = (data) => {
    const urlParams = new URLSearchParams(window.location.search);
    const user = data.data.user;

    const masters = data.data.masters;
    const columns = data.data.columns;
    const userPermissions = data.data.userPermissions;
    const keywords = data.data.keywords;
    const token = urlParams.get("t");
    const terms = data.data.user.accountTerm;

    UtilService.setLocalStorageItem(token, `${LOCAL_STORAGE_VAR}-token`);
    UtilService.setLocalStorageItem(user, `${LOCAL_STORAGE_VAR}-user`);

    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-master`,
      JSON.stringify(masters),
    );
    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-column`,
      JSON.stringify(columns),
    );
    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-keywords`,
      JSON.stringify(keywords),
    );
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-terms`, JSON.stringify(terms));
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-isTrusted`, data.data.isTrusted);

    let setPermit = {};
    userPermissions.data.map((x) => {
      if (x.module === "order" && user.Setting) {
        x.permissions.insert = user.Setting.Is_PlaceOrder
          ? user.Setting.Is_PlaceOrder
          : false;
      }

      if (
        x.module === "pricing" &&
        user.Setting &&
        user.Setting.Is_NoPrice !== true
      ) {
        x.permissions.view = user.Setting.Is_NoPrice !== true ? true : false;
        x.permissions.all = user.Setting.Is_NoPrice !== true ? true : false;
      }

      setPermit[x.module] = x.permissions;
    });

    if (setPermit && !setPermit.pricing) {
      if (user.Setting && user.Setting.Is_NoPrice !== true) {
        setPermit.pricing = {};
        setPermit.pricing.view =
          user.Setting.Is_NoPrice !== true ? true : false;
        setPermit.pricing.all = user.Setting.Is_NoPrice !== true ? true : false;
      }
    }

    UtilService.setLocalStorageItem(
      setPermit,
      `${LOCAL_STORAGE_VAR}-userPermissions`,
    );
    window.location.assign(`/dashboard`);
  };

  const getAdminLogin = React.useCallback((token) => {
    const request = {
      ...ADMIN_LOGIN,
      request: {
        masters: [
          "COLOR",
          "CLARITY",
          "SHAPE",
          "CUT",
          "FLUORESCENCE",
          "SHADE",
          "LAB",
          "POLISH",
          "SYMMETRY",
          "LOCATION",
          "BLACK_INCLUSION",
          "OPEN_INCLUSION_TABLE",
          "MILKEY",
          "WHITE_INCLUSION_CROWN",
          "OPEN_INCLUSION_CROWN",
          "EYECLEAN",
          "OPEN_INCLUSION_PAVILION",
          "ORIGIN",
          "BLACK_INCLUSION_CROWN",
          "H_AND_A",
          "WHITE_INCLUSION_TABLE",
          "FANCY_COLOR",
          "INTENSITY",
          "OVERTONE",
          "KEY_TO_SYMBOLS",
          "GIRDLE",
          "CULET",
          "GIRDLE_COND",
          "CULET_COND",
          "COMPANY_SIZE",
          "BLACK_INCLUSION_TABLE",
          "BLACK_INCLUSION_SIDE",
          "WHITE_INCLUSION_SIDE",
          "BRILLIANCY",
          "EYECLEAN",
          "BUSINESS_TYPE",
          "NATURE_OF_ORG",
          "DOC_TYPE_PERSONAL",
          "DOC_TYPE_BUSINESS",
        ],
      },
      updateToken: token,
    };
    UtilService.callApi(request, (err, data) => {
      if (data?.code === "OK") getRedirection(data);
    });
  }, []);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("t");
    getAdminLogin(token);
  }, [getAdminLogin]);

  return (
    <div
      id="loader"
      className={`main-loader --fixed`}
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        left: "0",
        top: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div id="loader-inner">
        <div>
          <img
            style={{ width: "150px" }}
            src={require("../assets/img/loader.svg")}
            alt="Belgium"
          />
        </div>
      </div>
    </div>
  );
}

export default AdminRedirect;
