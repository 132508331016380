/* eslint-disable */
import React, { useState, useEffect } from "react";
import { find, findIndex, map, isEmpty, join } from "lodash";
import {
  getColumn,
  removeStoneFromRapnet,
} from "../../DiamondList/DiamondListFunctions";
import Table from "../../DiamondList/TableBack";
import IntlMessages from "../../../util/IntlMessages";
import OpenNotification from "../CommonButton/OpenNotification";
import { HeadingCalc } from "./ConfirmStonePopup";
import { useSelector } from "react-redux";
import InputBlock from "../InputBlock";
import TextArea from "../TextArea";
import { Select, Row, Col } from "antd";

/* eslint jsx-a11y/anchor-is-valid: 0 */

const { Option } = Select;
const currentType = "RapnetPopup";

const RapnetPopup = (props) => {
  const [columns, setColumns] = useState([]);
  const [comment, setComment] = useState("");
  const [exp, setExp] = useState(true);
  const [data, setData] = useState(
    props.checked.map((x) => ({ ...x, expBack: x.back - 1 })),
  );
  const checked =
    useSelector((state) => state.diamondData.selectedRows[currentType]) || [];
  const [blockMaster, setBlockMaster] = useState({});
  const [time, setTime] = useState(undefined);

  useEffect(() => {
    let Columns = getColumn();
    let index = findIndex(Columns, { id: "back" });
    if (!index) index = 1;
    let columns = [...Columns.slice(0, index + 1)];
    columns = [
      ...columns,
      ...Columns.filter((el) => !find(columns, { id: el.id })),
    ];
    setColumns(columns);
  }, [exp]);

  const getHoldInput = () => {
    return (
      <Row gutter={16}>
        <Col md={12}>
          <div className="formSelect from-group">
            <InputBlock
              onKeyPress={(event) => {
                var regex = new RegExp("^[0-9]d*(.d+)?$");
                var key = String.fromCharCode(
                  !event.charCode ? event.which : event.charCode,
                );
                if (!regex.test(key) || event.target.value.length > 1) {
                  event.preventDefault();
                  return false;
                }
              }}
              type="number"
              label={"Time Duration (In Hours)"}
              placeholder={"Enter Value"}
              value={time}
              onChange={(e) => setTime(e.target.value)}
            />
          </div>
        </Col>
        <Col md={12}>
          <TextArea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            label="Comment"
            placeholder="Comment"
          />
        </Col>
      </Row>
    );
  };

  const btnAction = () => {
    return (
      <div className="sideBarPopupButton">
        <a
          onClick={() => {
            if (!checked.length) {
              return OpenNotification({
                type: "error",
                title: "Please select stone(s) to remove.",
              });
            }
            removeStoneFromRapnet(
              {
                vStnIds: join(map(checked, "vStnId"), ","),
                types: ["1", "2", "3", "4", "5"],
                durationInHours: 5,
                remark: comment,
              },
              () => {
                props.onClose();
                props.clearAll();
              },
            );
          }}
          className="commonButton"
        >
          Remove from Rapnet
        </a>
        <a className="commonButton" onClick={props.onClose}>
          <IntlMessages id="app.Cancel" />
        </a>
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <h2 className="popupInnerTitle mr-40">Remove stones</h2>
        {HeadingCalc(checked)}
      </div>
      <div className="searchPopupCommonTable">
        <div className="searchResultTable tabInnerTableScroll watchpopup">
          <Table
            data={data}
            columns={columns}
            areAllChecked={true}
            noGrp
            canSort={false}
            currentType={currentType}
          />
        </div>
        <div className="offerBottomBox flex-wrap mt-20">{getHoldInput()}</div>

        {btnAction()}
      </div>
    </div>
  );
};

export default RapnetPopup;
