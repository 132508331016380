/* eslint-disable */
import React from "react";
import CommonModal from "components/common/CommonModal";

const PhotoPopup = (props) => {
  return (
    <>
      <CommonModal
        visible={props.zoomPhoto}
        handleCancel={() => props.closePhoto()}
      >
        <div>
          <img width="100%" height="500px" src={props.imgSrc} alt="profile" />
        </div>
      </CommonModal>
    </>
  );
};

export default PhotoPopup;
