/* eslint-disable */
import React, { useEffect, useState } from "react";
import IntlMessages from "../../../../util/IntlMessages";
import { LOCAL_STORAGE_VAR } from "../../../../constants/Common";
import {
  setDateFormat,
  setTimeFormat,
  checkForMaster,
} from "../../../../services/Commonfunction";

import { groupBy } from "lodash";

const SavedSearchCard = (props) => {
  const [saveDetail, setSaveDetail] = useState({});
  const [searchData, setSearchData] = useState({});
  useEffect(() => {
    setSaveDetail({ ...props.saveDetail });
    performArray(props.saveDetail);
  }, [props.saveDetail]);
  const performArray = (saveDetail) => {
    if (saveDetail && saveDetail.searchData) {
      let Master = JSON.parse(
        localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`),
      );

      let tempArray = [];
      Object.keys(saveDetail.searchData).map((d, index) => {
        if (saveDetail.searchData[d] instanceof Array) {
          saveDetail.searchData[d] &&
            saveDetail.searchData[d].length !== 0 &&
            saveDetail.searchData[d].map((d2, indedx) => {
              let MasterCode = checkForMaster(d);
              if (MasterCode === "CARAT") {
                if (saveDetail.searchData[d][0].crt) {
                  tempArray.push({
                    parentCode: MasterCode,
                    search: `${saveDetail.searchData[d][0].crt[">="]}-${
                      saveDetail.searchData[d][
                        saveDetail.searchData[d].length - 1
                      ].crt["<="]
                    }`,
                  });
                }
              } else {
                if (
                  Master &&
                  MasterCode !== undefined &&
                  Master[MasterCode] &&
                  Master[MasterCode].length !== 0
                ) {
                  Master[MasterCode].map((a) => {
                    if (d2 === a.id[0]) {
                      tempArray.push({
                        parentCode: MasterCode,
                        search: a.name,
                      });
                    }
                  });
                }
              }
            });
        }
      });
      if (tempArray && tempArray.length !== 0) {
        let groupArray = groupBy(tempArray, "parentCode");

        setSearchData({ ...groupArray });
      }
    }
  };

  return (
    <div
      onClick={() => window.open(`/diamond-list?${saveDetail.id}`, "_self")}
      className="savedSearchItem"
    >
      {props.recentSearch ? null : (
        <div className="savedSearchHead">
          <div className="searchTitle">
            <div className="searchTitle">
              {saveDetail.name ? saveDetail.name : "--"}
            </div>
          </div>
          <div className="searchTime">
            <span>
              {saveDetail.createdAt
                ? setDateFormat(saveDetail.createdAt)
                : "--"}
            </span>
            <span>
              {saveDetail.createdAt
                ? setTimeFormat(saveDetail.createdAt)
                : "--"}
            </span>
          </div>
        </div>
      )}
      {!props.recentSearch && (
        <div className="savedSearchDetail">
          <div className="detail">
            {searchData && Object.keys(searchData).length !== 0
              ? Object.keys(searchData).map((obKey) => {
                  return (
                    <div className="detail">
                      <span>{obKey} :</span>
                      {searchData[obKey].length === 1 ? (
                        <span>{searchData[obKey][0].search}</span>
                      ) : searchData[obKey].length > 1 ? (
                        <span>
                          {searchData[obKey][0].search}{" "}
                          {obKey !== "CARAT" && (
                            <>({`+${searchData[obKey].length - 1}`})</>
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              : "All criteria has been selected."}
          </div>
        </div>
      )}
      {props.recentSearch && (
        <div className="savedSearchDetail">
          <div className="detail">
            <span>{saveDetail.name}</span>
          </div>
        </div>
      )}
      {props.recentSearch && (
        <div className="searchTime bottomSection">
          <span>
            {saveDetail.createdAt ? setDateFormat(saveDetail.createdAt) : "--"}
          </span>
          <span>
            {saveDetail.createdAt ? setTimeFormat(saveDetail.createdAt) : "--"}
          </span>
        </div>
      )}
    </div>
  );
};
export default SavedSearchCard;
