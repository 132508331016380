/* eslint-disable */
import React, { useEffect, useState } from "react";
import CommonModal from "../common/CommonModal";
import InputBlock from "../common/InputBlock";
import DatePicker from "../common/DatePicker";
import { handleAddDemand } from "../../containers/APIFunctions";
import moment from "moment";
import _find from "lodash/find";
import _join from "lodash/join";
import _isArray from "lodash/isArray";
import _isObject from "lodash/isObject";
import '../../styles/AddtoggleDemand.less'
import { cloneDeep, isEqual } from "lodash";
import { isEmpty, isNotEmpty } from "util/utils";
import { Tag } from "antd";
import { LOCAL_STORAGE_VAR, diamondParameters } from "../../constants/Common";
const master = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));


const AddDemandPopup = (props) => {
  const [filterData, setFilterData] = useState({})

  useEffect(() => {
    setFilterData(props.selectedMaster)
  }, [props.selectedMaster])

  const handleRemove = (index, key, idIndex) => {
    let filterDataClone = cloneDeep(filterData);
    // Filter keysToCheck  from the current  id  based on index
    const keysToCheck = ["shp", "col", "clr", "cut", "pol", "sym", "flu", "lb", "loc", "brlncy", "hA", "eCln", "sSts", "blkTbl",
      "blkSd", "wTbl", "wSd", "opTbl", "opCrwn", "opPav", "cult", "grdlThn", "wSts", "grdl"];


    const indexToRemove = ["isArtisanCerti", "ftc", "inDt", "type2", "back", "ctPr", "amt", "ratio", "depPer", "tblPer",
      "length", "width", "height", "cAng", "cHgt", "grdlPer", "pAng", "pHgt", "lwrHal", "strLn", "isCm", "isSVS", "isTrcr", "isXray"];


    if (keysToCheck.includes(key?.key)) {
      filterDataClone[key?.key] = filterDataClone?.[key?.key].filter(id => !index.includes(id));

      (isEmpty(filterDataClone[key?.key])) && delete filterDataClone[key?.key];
      isEmpty(filterDataClone[key?.key]) && delete filterDataClone["excludeFilter"];
    }

    // Filter out items from SHADE_GROUP based on idIndex &&  Filter out shd from the current shp id  based on index
    else if ((filterDataClone["SHADE_GROUP"] && key?.key === "shd")) {

      filterDataClone["SHADE_GROUP"] = filterDataClone?.["SHADE_GROUP"].filter(item => !idIndex.includes(item));

      filterDataClone[key?.key] = filterDataClone?.[key?.key].filter(id => !index.includes(id));

      (isEmpty(filterDataClone[key?.key])) && delete filterDataClone[key?.key]
      isEmpty(filterDataClone["SHADE_GROUP"]) && delete filterDataClone["SHADE_GROUP"]
    }

    else if ((idIndex === "NONE" && key.name === "Color Shade")) {

      filterDataClone[key?.key] = filterDataClone?.[key?.key].filter(id => !index.includes(id));

      (isEmpty(filterDataClone[key?.key])) && delete filterDataClone[key?.key]
    }

    // Filter out isFm, isDor, isDiavik, isFpc, cooStatus from the Current index
    else if (["isFm", "isDor", "isDiavik", "isFpc", "cooStatus"].includes(key?.key)) {
      filterDataClone[key?.key] = filterDataClone?.[key?.key].filter(id => !index.includes(id));

      (isEmpty(filterDataClone[key?.key])) && delete filterDataClone[key?.key]

    }

    //Filter out indexToRemove from the current index
    else if (filterDataClone[index] && indexToRemove.includes(index)) {
      delete filterDataClone[index];
    }

    // FilterData kToSArr chcekout  
    else if (!isEmpty(filterDataClone) && key === "kToSArr" && filterDataClone?.[key][idIndex]) {
      filterDataClone[key][idIndex] = filterDataClone[key][idIndex].filter(item => !index.includes(item))
    }

    // Filter out Carat from the current index
    else if (filterDataClone?.["or"] && key === "Carat" &&
      filterDataClone?.["or"]?.findIndex((d) => Object.keys(d)?.includes("crt")) >= 0) {
      filterDataClone["or"] = filterDataClone["or"].filter((d) => {
        if (Object.keys(d)?.includes("crt")) {
          // Parse the index values to numbers
          const [first, last] = index.split(" to ").map(parseFloat);

          // Create the new crt object
          const crtObject = {
            '>=': first,
            '<=': last
          };
          // Convert crt values to numbers for comparison
          const crtValues = {
            '>=': parseFloat(d.crt['>=']),
            '<=': parseFloat(d.crt['<='])
          };
          return !isEqual(crtValues, crtObject);

        }
      });
      isEmpty(filterDataClone["or"]) && delete filterDataClone["or"]
    }

    setFilterData(filterDataClone)
  }

  const handleClosePopup = () => {
    props.handleReverseState("showDemand")
    setTimeout(() => {
      setFilterData(props.selectedMaster)
    }, 1000);
  }


  return (
    <>
      <CommonModal
        visible={props.showDemand}
        handleOk={() =>
          handleAddDemand(
            props.selectedMaster,
            props.demandTitle,
            props.expiryDate,
            (success) => {
              if (success) {
                props.handleReverseState("showDemand");
                props.handleRefresh();
              }
            },
          )
        }
        handleCancel={handleClosePopup}
        title="Sorry, We didn't find any match diamonds for your search. Please Add Demand"
        footerShow
        submitTitle="Submit"
        cancelTitle="Cancel"
      >
        <div className="searchPopupCommon">
          <div className="add-Demand-container">
            <div className="deman-title">
              <h4>Demand Summary</h4>
            </div>
            {Object.keys(filterData).map((filter, ind) => {
              const param = _find(diamondParameters, { key: filter });
              if (param) {
                if (param.key === "or") {
                  const orData = {};

                  if (
                    filterData["or"] &&
                    filterData["or"].findIndex((d) => Object.keys(d).includes("crt")) >= 0
                  ) {
                    const caratData = filterData["or"]
                      ?.map((d) => (Object.keys(d)?.includes("crt") ? d?.crt : ""))
                      ?.filter(isNotEmpty);

                    orData.Carat = caratData.map(
                      (carat) => (carat[">="]) + " to " + (carat["<="]),
                    );
                  }
                  return Object.entries(orData).map(([key, data]) => (
                    <div className="ineer-box">
                      <span className="heading-demand">{key}</span>
                      {data.map((item) =>
                        <Tag className="addedCarat" closable onClose={e => {
                          e.preventDefault();
                          handleRemove(item, key)
                        }}>
                          <span className="listValue">{item}</span>
                        </Tag>
                      )}
                    </div>
                  ));

                } else {
                  if (_isObject(filterData[filter]) && !_isArray(filterData[filter])) {
                    if (filter === "kToSArr") {
                      const arr = Object.values(filterData["kToSArr"])[0];
                      const key = Object.keys(filterData["kToSArr"])[0];
                      const keyList = master?.KEY_TO_SYMBOLS?.filter((item) =>
                        arr?.includes(item.id?.[0]),
                      )?.map(({ webDisplay, id }) => ({ webDisplay, id }));
                      return (
                        <div className="ineer-box">
                          {(keyList.length > 0) && <h5 className="heading-demand">
                            {param.name}
                            <span>{key === "in" ? " Contains " : " Not contains "}</span>
                          </h5>}
                          {
                            keyList.map((item) =>
                              <Tag className="addedCarat" closable onClose={e => {
                                e.preventDefault();
                                handleRemove(item?.id, param?.key, key)
                              }} >
                                <span className="listValue">{item?.webDisplay}</span>
                              </Tag>
                            )
                          }
                        </div>
                      );

                    } else {
                      if (filter === "back") {
                        return (
                          <div className="ineer-box">
                            <span className="heading-demand">{param.name} :</span>
                            <Tag className="listTagList" closable onClose={e => {
                              e.preventDefault();
                              handleRemove(filter)
                            }}>
                              <span>
                                {filterData[filter]["<="] * -1 + "  to  " + filterData[filter][">="] * -1}
                              </span>
                            </Tag>
                          </div>

                        );
                      }
                      else if ([
                        "tblPer",
                        "ctPr",
                        "depPer",
                        "length",
                        "ratio",
                        "width",
                        "cAng",
                        "cHgt",
                        "grdlPer",
                        "height",
                        "lwrHal",
                        "pAng",
                        "pHgt",
                        "strLn",
                        "amt",
                      ].includes(filter)) {
                        return (
                          <div className="ineer-box">
                            <span className="heading-demand">{param.name} :</span>
                            <Tag className="addedCarat" closable onClose={e => {
                              e.preventDefault();
                              handleRemove(filter)
                            }}>
                              <span>
                                {filterData[filter][">="] + "  to  " + filterData[filter]["<="]}
                              </span>
                            </Tag>
                          </div>

                        );
                      } else if (filter === "type2") {
                        return (
                          <div className="ineer-box">
                            <span className="heading-demand">{param.name} :</span>
                            <Tag className="addedCarat" closable onClose={e => {
                              e.preventDefault();
                              handleRemove(param.key)
                            }}>
                              <span>YES</span>
                            </Tag>
                          </div>

                        );
                      } else if (filter === "vStnId") {
                        return (
                          <Tag className="listTagList">
                            <span className="listLabel">{param.name} :</span>
                            <span>{_join(filterData?.vStnId["!="], ", ")}</span>
                          </Tag>
                        );
                      } else if (filter === "pairStkNo") {
                        return;
                      } else {
                        return (
                          <Tag className="listTagList">
                            <span className="listLabel">{param.name} :</span>
                            <span>{filterData[filter][">="] + "  to  " + filterData[filter]["<="]}</span>
                          </Tag>
                        );
                      }
                    }
                  } else if (typeof filterData[param.key] === "boolean") {
                    return (
                      <div className="ineer-box">
                        <span className="heading-demand">{param?.name}</span>
                        <Tag className="addedCarat" closable onClose={e => {
                          e.preventDefault();
                          handleRemove(param.key)
                        }}>
                          <span className="listValue">{filterData[param.key] === true ? "YES" : "NO"}</span>
                        </Tag>
                      </div>

                    );
                  } else {
                    // const disp = filterData[param.key];
                    let disp = param.master ? [] : filterData[param.key];

                    if (param.master && filterData?.[param.key]) {
                      const data = Array.isArray(filterData[param.key])
                        ? filterData[param.key]
                        : [filterData[param.key]];
                      data.forEach((data) => {
                        let masterIndex = master?.[param.master]?.findIndex(

                          (md) => (md?.id?.[0] || md?.id) === data,
                        );
                        if (masterIndex >= 0) {
                          disp.push({
                            id: master?.[param.master]?.[masterIndex]?.id,
                            webDisplay: master?.[param.master]?.[masterIndex]?.webDisplay
                          });
                        }
                      })
                    }
                    const keysList = {
                      CERT: "INVENTORY",
                      ELIG: "ELIGIBLE",
                      INSC: "INSC",
                    };
                    return ((param.key === "isCm" || param.key === "isSVS" || param.key === "isTrcr") ?
                      <div className="ineer-box">
                        <h5 className="heading-demand">Cert :</h5>
                        <Tag className="addedCarat" closable onClose={e => {
                          e.preventDefault();
                          handleRemove(param?.key)
                        }}>
                          <span className="listValue">{param.name}</span>
                        </Tag>
                      </div>
                      :
                      (["isFm", "isDor", "isDiavik", "isFpc", "cooStatus"].includes(param.key)) ?
                        <div className="ineer-box">
                          <h5 className="heading-demand">{param?.name}</h5>
                          {disp.map((item) =>
                            <Tag className="addedCarat" closable onClose={e => {
                              e.preventDefault();
                              handleRemove(item, param)
                            }}>
                              <span className="listValue">{keysList[item]}</span>
                            </Tag>
                          )}
                        </div>
                        :
                        <div className="ineer-box">
                          {isNotEmpty(disp) && <span className="heading-demand">{param.name}</span>}
                          {disp.map((item) =>
                            <Tag className="addedCarat" closable onClose={e => {
                              e.preventDefault();
                              handleRemove((param.key === "wSts") ? item : item?.id, param, item?.webDisplay)
                            }}>
                              <span className="listValue">{(param.key === "wSts") ? item : item?.webDisplay}</span>
                            </Tag>
                          )}
                        </div>
                    );
                  }
                }
              }
            })
            }
          </div>
          <div className="searchPopupTwoValue">
            <InputBlock
              label="Comments *"
              placeholder="Comments: "
              value={props.demandTitle}
              onChange={(e) =>
                props.handleChange("demandTitle", e.target.value)
              }
            />
            <DatePicker
              label="Select Date"
              {...props}
              handleChange={(dt) =>
                props.handleChange("expiryDate", dt ? dt.toISOString() : "")
              }
              disabledDate={(current) => {
                return current && current <= moment().startOf("day");
              }}
            />
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default AddDemandPopup;
