/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButton from "../common/BackButton";
import IntlMessages from "../../util/IntlMessages";
import LangDropdown from "../Header/LangDropdown";
import { otpVerification } from "../../appRedux/actions/Auth";
import { connect } from "react-redux";
import { Radio, Form, Input } from "antd";
import ResendOtp from "../ResendOtp";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import UtilService from "../../services/util";

class OtpVerificationForm extends Component {
  constructor() {
    super();
    this.state = {
      selectedvalue: 0,
      showclass: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.props.form.setFieldsValue({
      trusted: "0",
    });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.otpVerification(values);
      }
    });
  };
  handleChange = (e) => {
    if (e.target.value === "1") {
      this.setState({
        showclass: false,
      });
    } else if (e.target.value === "0") {
      this.setState({
        showclass: true,
      });
    }
    this.setState({
      selectedvalue: e.target.value,
    });
  };
  render() {
    let radioValue = this.props.form.getFieldValue("trusted");
    // let user = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`));
    let user = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

    let email = user && user.email;
    let splitEmail = email.split("@");
    let hiddenEmail = splitEmail[0]
      .substring(0, 2)
      .concat("******")
      .concat(splitEmail[0].slice(-2))
      .concat("@")
      .concat(splitEmail[1]);

    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login-block" id="custom-scroll">
        {/* <div className="d-flex justify-content-between mb-10">
          <div>
            <Link to="/">
              <BackButton />
            </Link>
          </div>
          <LangDropdown />
        </div> */}

        <div className="page-til mb-25 d-flex align-items-center">
          <div>
            <h3>
              <IntlMessages id="app.signin.otpVerification" />
            </h3>
            <p className="subHeadLog">
              Your verification code has been sent to{" "}
              <strong> {hiddenEmail}</strong>
            </p>
          </div>
        </div>
        <Form onSubmit={this.handleSubmit} noValidate>
          <div className="form-control">
            <div>
              <Form.Item>
                {getFieldDecorator("OTP", {
                  rules: [
                    {
                      required: true,
                      message: "OTP is Required!",
                    },
                  ],
                })(<Input type="password" />)}
              </Form.Item>

              <Form.Item
                className={radioValue ? "required-green" : ""}
                label={<IntlMessages id="app.Doyoutrustthisdevice?" />}
              >
                {getFieldDecorator("trusted", {
                  rules: [
                    {
                      required: true,
                      message: "Please select an option",
                    },
                  ],
                })(
                  <Radio.Group
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                  >
                    <Radio value={"1"} className="demandRadio">
                      <IntlMessages id="app.always" />
                    </Radio>
                    <Radio value={"0"} className="demandRadio">
                      <span className={this.state.showclass ? "blinkingtext" : ''}>
                        <IntlMessages id="app.justonce" />
                      </span>
                    </Radio>
                  </Radio.Group>,
                )}
              </Form.Item>
            </div>
            &nbsp;
            <div className="form-control">
              <button
                type="submit"
                className="button primary-fill width-full"
                htmltype="submit"
              >
                <IntlMessages id="app.signin.otpVerification" />
              </button>
              {/* <Radio.Group name="radiogroup" defaultValue={"4"}>
                                    <Radio value={"1"}>A</Radio>
                                    <Radio value={"2"}>B</Radio>
                                    <Radio value={"3"}>C</Radio>
                                    <Radio value={"4"}>D</Radio>
                                  </Radio.Group> */}
            </div>
          </div>
        </Form>
        <div align="right">
          <ResendOtp />
        </div>
      </div>
    );
  }
}

const WrappedOtpVerificationForm = Form.create()(OtpVerificationForm);
const mapDispatchToProps = (dispatch) => {
  return {
    otpVerification: (values) => {
      dispatch(otpVerification({ values }));
    },
  };
};
export default connect(null, mapDispatchToProps)(WrappedOtpVerificationForm);
