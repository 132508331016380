/* eslint-disable */
import React from "react";
import MagnifierImage from "../DiamondDetail/MagnifierImage";
import noImageSvg from "../../assets/svg/noimage.svg";

const DiamondJourney = (props) => {
  const { dnaData, getMediaUrl } = props;
  const roughImgId = dnaData.vStnId && dnaData.vStnId.slice(0, -1) + "0";
  return (
    <div className="diamondJourneyBlock">
      {dnaData.img && (
        <div className="diamondJourneyItem">
          <div className="diamondJourneyTitle">
            <span>Rough Image</span>
          </div>
          <div
            className="diamondJourneyImage"
            onClick={() => {
              window.open(`/viewImage/RoughImages/${dnaData.id}`);
            }}
          >
            <MagnifierImage image={getMediaUrl("roughImage")} />
          </div>
        </div>
      )}
      {dnaData.img && (
        <div className="diamondJourneyItem">
          <div className="diamondJourneyTitle">
            <span>Rough Video</span>
          </div>
          <div className="diamondJourneyImage" style={{ overflowY: "hidden" }}>
            {dnaData.roughVideo ? (
              <iframe
                style={{ width: "83%", height: "100%", minHeight: "241px" }}
                src={getMediaUrl("roughVideo")}
                scrolling="no"
              />
            ) : (
              <img
                {...props}
                src={noImageSvg}
                alt=""
                style={props?.style ?? {}}
              />
            )}
          </div>
        </div>
      )}
      {dnaData.img && (
        <div className="diamondJourneyItem">
          <div className="diamondJourneyTitle">
            <span>3D Image</span>
          </div>
          <div
            className="diamondJourneyImage"
            onClick={() => {
              window.open(`/viewImage/PlanImages/${dnaData.id}`);
            }}
          >
            <MagnifierImage image={getMediaUrl("planImg")} />
          </div>
        </div>
      )}
      {dnaData.img && (
        <div className="diamondJourneyItem">
          <div className="diamondJourneyTitle">
            <span>B2B Image</span>
          </div>
          <div
            className="diamondJourneyImage"
            onClick={() => {
              window.open(`/viewImage/RealImages/${dnaData.id}`);
            }}
          >
            <MagnifierImage image={getMediaUrl("img")} />
          </div>
        </div>
      )}
    </div>
  );
};
export default DiamondJourney;
