/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Heading from "../../common/Heading";
import InputBlock from "../../common/InputBlock";
import SelectOption from "../../common/SelectOption";
import UtilService from "../../../services/util";
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import { notification, Tag } from "antd";
import {
  BASE_URL,
  MIME_TYPES,
  LOCAL_STORAGE_VAR,
} from "../../../constants/Common";
import OpenNotification from "../../common/CommonButton/OpenNotification";
import IntlMessages from "../../../util/IntlMessages";
import UploadImage from "../../common/UploadImage/index";

import {
  GET_COUNTRY_LIST,
  GET_STATE_LIST,
  UPLOAD_FILE,
  UPDATE_USER_PROFILE,
  GET_CITY_LIST,
  GET_USER_PROFILE,
} from "../../../constants/apiConstant";
import Loader from "components/common/Loader";
/* eslint jsx-a11y/anchor-is-valid: 0 */
export const companyDetail = {
  BusinessType: [
    {
      id: "Independent Jeweler",
      name: "Independent Jeweler",
    },
    {
      id: "Online Jewelry Store",
      name: "Online Jewelry Store",
    },
    {
      id: "Diamond Dealer/ Broker",
      name: "Diamond Dealer/ Broker",
    },
    {
      id: "Diamond Manufacturer / Cutter",
      name: "Diamond Manufacturer / Cutter",
    },
    {
      id: "Jewelry Manufacturer",
      name: "Jewelry Manufacturer",
    },
    {
      id: "Jewelry Retail Chain",
      name: "Jewelry Retail Chain",
    },
    {
      id: "Pawn shop",
      name: "Pawn shop",
    },
  ],
};

class CompanyInformation extends Component {
  state = {
    data: {},
    cities: [],
    states: [],
    countries: [],
    errors: [],
    doc: undefined,
    kyc: [],
  };

  businessType = [];
  businessDocs = [];

  componentDidMount() {
    this.getCountries();
    this.fetchAccount();
    const masters = JSON.parse(
      localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`),
    );
    this.businessType = masters?.BUSINESS_TYPE;
    this.businessDocs = masters?.DOC_TYPE_BUSINESS;
  }

  getCountries = () => {
    let self = this;
    let obj = {
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === "OK") {
        self.setState({ countries: data.data });
      }
    });
  };

  getStates = (countryId) => {
    let self = this;
    let obj = {
      ...GET_STATE_LIST,
      request: { country: countryId },
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === "OK") {
        self.setState({ states: data.data });
      }
    });
  };

  getCities = (stateId) => {
    let self = this;
    let obj = {
      ...GET_CITY_LIST,
      request: { state: stateId },
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === "OK") {
        self.setState({
          cities: [...data.data, { name: "Others", id: "-" }] || [],
        });
      }
    });
  };

  fetchAccount() {
    let self = this;
    let obj = {
      ...GET_USER_PROFILE,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        data.rapNetId = data.data.social && data.data.social.rapNetId;
        data.qqId = data.data.social && data.data.social.qqId;
        data.linkedin = data.data.social && data.data.social.linkedin;
        self.setState({
          data: data.data,
          userKyc: data?.data?.kyc?.filter(({ type }) => type === "personal"),
          businessKyc: data?.data?.kyc?.filter(
            ({ type }) => type === "business",
          ),
        });
        self.getStates(data.data.country);
        self.getCities(data.data.state);
      }
    });
  }

  updateAccount() {
    let self = this;
    let reqObj = clone(self.state.data);
    reqObj.kyc = [
      ...(self.state.businessKyc ?? []),
      ...(self.state.userKyc ?? []),
    ];
    reqObj.social = {
      rapNetId: reqObj.rapNetId,
      qqId: reqObj.qqId,
      linkedin: reqObj.linkedin,
    };
    reqObj.city === "-" && delete reqObj["city"];
    let obj = {
      ...UPDATE_USER_PROFILE,
      request: reqObj,
    };
    if (self.handleValidation()) {
      self.setState({ loading: true });
      UtilService.callApi(obj, function (err, data) {
        self.setState({ loading: false });
        if (err && err.data) {
          if (err.data.code === "E_USER_NOT_FOUND") {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
          if (err.data.code === "E_DUPLICATE") {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
        }

        if (data && data.code === "OK") {
          let setObj = UtilService.getLocalStorageItem(
            `${LOCAL_STORAGE_VAR}-user`,
          );
          setObj.account = reqObj;
          UtilService.setLocalStorageItem(setObj, `${LOCAL_STORAGE_VAR}-user`);

          self.props.onClose();
          return OpenNotification({
            type: "success",
            title: "Company details are updated successfully.",
          });
        }
      });
    }
  }

  uploadDocument = (ee, type) => {
    const e = cloneDeep(ee);

    let self = this;
    let errors = clone(this.state.errors);
    let formData = clone(this.state.data);

    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = function (ev) {
      var arr = new Uint8Array(ev.target.result).subarray(0, 4);
      var header = "";
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (
        !MIME_TYPES[blob.type] ||
        (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))
      ) {
        return OpenNotification({
          type: "error",
          title:
            "File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.",
        });
      } else {
        const data = new FormData();
        data.append("folder", "user");
        data.append("file", e.target.files[0]);

        let objData = {
          ...UPLOAD_FILE,
          request: data,
        };
        UtilService.callApi(objData, function (err, data) {
          if (err) throw err;
          if (data && data.code === "OK") {
            errors[type] = undefined;
            // if (type === "kyc") {
            //   kyc.push({
            //     type: self.state.doc,
            //     path: data.data.files[0].absolutePath,
            //   });
            // }
            formData[type] = data.data.files[0].absolutePath;
            self.setState({ data: formData, errors });
          }
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };

  handleInputChange = (key, value) => {
    let { data } = this.state;
    data[key] = value;
    this.setState({ data: data });
  };

  handleValidation = () => {
    let formData = this.state.data;
    let errors = this.state.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFields = [];

    if (!formData["name"]) {
      blankField.push("Company Name");
      formIsValid = false;
    } else {
      errors["name"] = undefined;
    }

    if (!formData["address"]) {
      blankField.push("Address 1");
      formIsValid = false;
    } else {
      errors["address"] = undefined;
    }

    if (!formData["country"] || isEmpty(formData["country"])) {
      blankField.push("Country");
      formIsValid = false;
      errors["country"] = true;
    } else {
      errors["country"] = undefined;
    }

    if (!formData["state"] || isEmpty(formData["state"])) {
      blankField.push("State");
      formIsValid = false;
      errors["state"] = true;
    } else {
      errors["state"] = undefined;
    }

    if (!formData["city"] || isEmpty(formData["city"])) {
      blankField.push("City");
      formIsValid = false;
      errors["city"] = true;
    } else {
      errors["city"] = undefined;
    }

    if (formData.city === "-") {
      if (!formData["cityText"] || isEmpty(formData["cityText"])) {
        blankField.push("City");
        formIsValid = false;
        errors["cityText"] = true;
      } else if (
        formData["cityText"] &&
        !formData["cityText"].match(/^[a-zA-Z ]+$/)
      ) {
        invalidFields.push("City");
        errors["cityText"] = true;
        formIsValid = false;
      } else {
        errors["cityText"] = undefined;
      }
    }

    if (!formData["zipCode"] || isEmpty(formData["zipCode"])) {
      blankField.push("Zipcode");
      formIsValid = false;
      errors["zipCode"] = true;
    } else if (
      formData["zipCode"] &&
      !formData["zipCode"].match(/^[0-9]{4,6}$/)
      // !formData["pinCode"].match(/^(\d{3}|\d{{7})$/)
    ) {
      invalidFields.push("Zipcode");
      formIsValid = false;
      errors["zipCode"] = true;
    }

    if (blankField.length > 0) {
      let arr = blankField;
      var outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    if (invalidFields.length > 0) {
      let arr = invalidFields;
      var outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  removeDocument = (removeIndex) => {
    let { businessKyc } = this.state;

    this.setState({
      businessKyc: businessKyc?.filter((data, index) => index !== removeIndex),
    });
  };

  handleDocument = (value) => {
    const docExist = this.state?.businessKyc?.find(
      ({ docType }) => docType === value,
    );
    if (docExist)
      return OpenNotification({
        type: "error",
        title: "Document already exist",
      });

    this.setState({ doc: value });
  };

  addDocument = () => {
    const { doc, data } = this.state;
    if (isEmpty(data?.photoId) || isEmpty(doc))
      return OpenNotification({
        type: "error",
        title: "Please select Document Type and Image",
      });
    this.setState(
      {
        businessKyc: [
          ...this.state.businessKyc,
          {
            docType: doc,
            path: data?.photoId,
            type: "business",
          },
        ],
      },
      () =>
        this.setState({
          doc: undefined,
          data: { ...this.state.data, photoId: "" },
        }),
    );
  };

  render() {
    let { countries, states, cities, data } = this.state;

    return (
      <div>
        <div
          className={`profileHead ${
            this.state.loading ? "loading-indicator" : ""
          }`}
        >
          <Heading
            title={<IntlMessages id="app.CompanyDetails" />}
            className="popupInnerTitle"
          ></Heading>
          {/* <div className="personalProfileWrapper">
            <div className="editProfileLogo">

              {data.vendorLogo ? (
                <img src={`${BASE_URL}/${data.vendorLogo}`} alt="" />
              ) : (
                <DefaultUser size="lg-size" />
              )}
            </div>
            <div className="profileAction">
              <ProfileUpload
                label={<IntlMessages id="app.EditProfile" />}
                accept="jpg, png,jpeg,PNG,JPEG,JPG"
                onChange={(e) => this.uploadDocument(e, "vendorLogo")}
              />
              <a onClick={() => this.handleInputChange("vendorLogo", "")}>
                <IntlMessages id="app.RemoveProfile" />
              </a>
            </div>
          </div> */}
        </div>
        <div className="profileDetailSpace">
          <IntlMessages id="app.CompanyName">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange("name", e.target.value)}
                placeholder={placeholder}
                value={data.name}
                label={
                  <>
                    <IntlMessages id="app.CompanyName" />*
                  </>
                }
              />
            )}
          </IntlMessages>
          <SelectOption
            defaultValue={<IntlMessages id="app.BusinessType" />}
            value={
              isEmpty(data.businessType)
                ? undefined
                : find(this.businessType, { id: [data.businessType] }).name
            }
            selectValue={this.businessType}
            onChange={(e) => {
              this.handleInputChange("businessType", e[0]);
            }}
            label={<IntlMessages id="app.SelectBusinessType" />}
          />
          <IntlMessages id="app.AddressLine1">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("address", e.target.value)
                }
                placeholder={placeholder}
                value={data.address}
                label={
                  <>
                    <IntlMessages id="app.AddressLine1" />*
                  </>
                }
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.AddressLine2">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("landMark", e.target.value)
                }
                placeholder={placeholder}
                value={data.landMark}
                label={<IntlMessages id="app.AddressLine2" />}
              />
            )}
          </IntlMessages>
          <SelectOption
            showSearch={true}
            //defaultValue={<IntlMessages id="app.Country" />}
            value={data.country}
            selectValue={countries}
            onChange={(value) => {
              let data = clone(this.state.data);
              data["state"] = undefined;
              data["city"] = undefined;

              data["country"] = value;
              this.getStates(value);
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessages id="app.SelectCountry*" />
              </>
            }
          />
          <SelectOption
            showSearch={true}
            defaultValue={<IntlMessages id="app.State" />}
            value={data.state}
            selectValue={states}
            onChange={(value) => {
              let data = clone(this.state.data);
              data["city"] = undefined;

              data["state"] = value;
              this.getCities(value);
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessages id="app.SelectState*" />
              </>
            }
          />
          <SelectOption
            showSearch={true}
            value={data.city}
            selectValue={cities}
            onChange={(value) => {
              let data = clone(this.state.data);
              data["city"] = value;
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessages id="app.SelectCity*" />
              </>
            }
          />
          {data && data.city === "-" && (
            <IntlMessages id="app.City">
              {(placeholder) => (
                <InputBlock
                  onChange={(e) =>
                    this.handleInputChange("cityText", e.target.value)
                  }
                  hasError={this.state.errors["cityText"] ? true : false}
                  type="text"
                  value={data.cityText}
                  label={
                    <>
                      <IntlMessages id="app.CityLabel" />
                    </>
                  }
                  placeholder={placeholder}
                />
              )}
            </IntlMessages>
          )}
          <IntlMessages id="app.ZipCode">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("zipCode", e.target.value)
                }
                type="number"
                placeholder={placeholder}
                value={data.zipCode}
                label={
                  <>
                    <IntlMessages id="app.ZipCode" />*
                  </>
                }
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.CompanyCode">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("vendorCode", e.target.value)
                }
                placeholder={placeholder}
                value={data.vendorCode}
                label={<IntlMessages id="app.CompanyCode" />}
              />
            )}
          </IntlMessages>
          <InputBlock
            onChange={(e) => this.handleInputChange("rapNetId", e.target.value)}
            placeholder={"Rapnet ID"}
            value={data.rapNetId}
            label={"Rapnet ID"}
          />
          <InputBlock
            onChange={(e) => this.handleInputChange("qqId", e.target.value)}
            placeholder={"QQ ID"}
            value={data.qqId}
            label={"QQ ID"}
          />
          <InputBlock
            onChange={(e) => this.handleInputChange("linkedin", e.target.value)}
            placeholder={"LinkedIn ID"}
            value={data.linkedin}
            label={"LinkedIn ID"}
          />
          <div className="inputBlock">
            <label>KYC Documents</label>
            <div className="docTag">
              {!isEmpty(this.state.businessKyc) ? (
                this.state.businessKyc.map((u, index) => (
                  <Tag
                    key={Math.random()}
                    onClose={() => this.removeDocument(index)}
                    closable
                  >
                    {
                      this.businessDocs?.find(({ id }) => id?.[0] === u.docType)
                        ?.name
                    }
                  </Tag>
                ))
              ) : (
                <p>Documents Not Exist</p>
              )}
            </div>
          </div>

          <SelectOption
            value={this.state?.doc ?? undefined}
            placeholder={"Select Business Document"}
            selectValue={this.businessDocs?.map((item) => ({
              ...item,
              id: item?.id?.[0],
            }))}
            onChange={this.handleDocument}
            label="Document Type*"
          />

          <div className="width-50" style={{ display: "-webkit-box" }}>
            <UploadImage
              file={data.photoId}
              disabled={isEmpty(this.state.doc)}
              leftAlign
              noRightBorder
              onUpload={(e) => this.uploadDocument(e, "photoId")}
            />{" "}
            <a className="commonButton" onClick={this.addDocument}>
              Add Document
            </a>
          </div>
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={() => this.updateAccount()}>
            <IntlMessages id="app.SaveCompanyDetail" />
          </a>
          <a className="commonButton" onClick={() => this.fetchAccount()}>
            <IntlMessages id="app.Cancel" />
          </a>
        </div>
      </div>
    );
  }
}
export default withRouter(CompanyInformation);
