/* eslint-disable */
import React, { useState } from "react";
import StaticHeader from "../StaticHeader";
import "./feedback.less";
import "../TermsOfUse/staticContent.less";
import Heading from "../common/Heading";
import TextArea from "../common/TextArea";
import { notification, Radio, Slider } from "antd";
import useStateWithCallback from "../../hooks/useStateWithCallback";
import UtilService from "../../services/util";
import { CREATE_FEEDBACK } from "../../constants/apiConstant";
import OpenNotification from "../common/CommonButton/OpenNotification";
import { FEEDBACK_QUESTIONS } from "../../constants/Common";
import InputBlock from "../common/InputBlock";
import { isEmpty } from "lodash";
import { handleMobileNumber } from "../../services/Commonfunction";

const Feedback = () => {
  const marks = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
  };
  const [value, Setvalue] = useState(1);
  const [value1, Setvalue1] = useState(false);
  const [value2, Setvalue2] = useState(false);
  const [value3, Setvalue3] = useState(false);
  const [value4, Setvalue4] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useStateWithCallback({
    name: "",
    mobile: "",
    email: "",
    QUE_1: "Print Media",
    QUE_2: "No",
    QUE_3: "No",
    QUE_4: "Yes",
    QUE_5: 3,
    QUE_6: 3,
    QUE_7: "",
    QUE_8: "",
    QUE_9: "Better",
    QUE_10: 3,
    QUE_11: "",
    QUE_12: "",
    QUE_13: "",
    errors: {},
  });

  const handleFormReset = () => {
    setFormData({
      ...formData,
      name: "",
      mobile: "",
      email: "",
      QUE_1: "Print Media",
      QUE_2: "No",
      QUE_3: "No",
      QUE_4: "Yes",
      QUE_5: 3,
      QUE_6: 3,
      QUE_7: "",
      QUE_8: "",
      QUE_9: "Better",
      QUE_10: 3,
      QUE_11: "",
      QUE_12: "",
      QUE_13: "",
      errors: {},
    });
  };

  const onChange = (e) => {
    Setvalue(value);
    //   value: e.target.value,
  };
  const onClose = (e) => {
    Setvalue1(false);
    Setvalue2(false);
    Setvalue3(false);
    Setvalue4(false);
  };
  const other = (e) => {
    Setvalue1(true);
  };
  const yes1 = (e) => {
    Setvalue2(true);
  };
  const yes2 = (e) => {
    Setvalue3(true);
  };
  const no = (e) => {
    Setvalue4(true);
  };

  const onInputChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleValidation = () => {
    let errors = formData.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFields = [];

    if (!formData.name || isEmpty(formData.name)) {
      blankField.push("Name");
      formIsValid = false;
      errors.name = true;
    } else if (formData.name) {
      if (!formData.name.match(/^[a-zA-Z ]+$/)) {
        invalidFields.push("Name");
        formIsValid = false;
        errors.name = true;
      } else {
        errors.name = undefined;
      }
    }

    if (!formData.mobile || isEmpty(formData.mobile)) {
      blankField.push("Mobile Number");
      formIsValid = false;
      errors.mobile = true;
    } else if (
      formData.mobile &&
      (formData.mobile.length < 10 ||
        formData.mobile.length > 15 ||
        !formData.mobile.match(/^[0-9]{10,15}$/))
    ) {
      invalidFields.push("Mobile Number");
      formIsValid = false;
      errors.mobile = true;
    } else {
      errors.name = undefined;
    }

    if (!formData.email || isEmpty(formData.email)) {
      blankField.push("Email Address");
      formIsValid = false;
      errors.email = true;
    } else if (formData.email) {
      if (
        !formData.email.match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        invalidFields.push("Email");
        formIsValid = false;
        errors.email = true;
      } else {
        errors.email = undefined;
      }
    }

    if (blankField.length > 0) {
      let arr = blankField;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    if (invalidFields.length > 0) {
      let arr = invalidFields;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    setFormData({ ...formData, errors: errors });
    return formIsValid;
  };

  const onSubmit = () => {
    if (handleValidation()) {
      for (let propName in formData) {
        if (
          formData[propName] === null ||
          formData[propName] === "" ||
          formData[propName] === undefined
        ) {
          delete formData[propName];
        }
      }

      let feedback = [];
      for (let propName in formData) {
        if (!["email", "mobile", "name", "errors"].includes(propName)) {
          feedback.push({
            question: FEEDBACK_QUESTIONS[propName],
            code: propName,
            answer: formData[propName],
          });
        }
      }

      let obj = {
        ...CREATE_FEEDBACK,
        request: {
          name: formData.name,
          email: formData.email,
          mobile: formData.mobile,
          feedback,
        },
      };
      setIsLoading(true);
      UtilService.callApi(obj, function (err, data) {
        if (err && err.data) {
          return OpenNotification({
            type: "error",
            title: err.data.message,
          });
        }

        if (data && data.code === "OK") {
          handleFormReset();
          OpenNotification({
            type: "success",
            title: data.message,
          });
        }
        setIsLoading(false);
      });
    }
  };
  const SmileBlock = (props) => {
    return (
      <div
        className={`feedbackRatingWrapper  ${props.activeClass ? "active" : ''}`}
      >
        {/* <div className="feedbackratingIcon">
                    <img src={props.image} alt=""/>
                </div> */}
        <span className="feedbackRationTitle">{props.number}</span>
      </div>
    );
  };
  return (
    <>
      <StaticHeader />
      <div
        className={`staticBoxMain white-bg ${isLoading ? `loading-indicator` : ''}`}
      >
        <div className="statiContentBox">
          {/* <Heading title="Feedback" className="staticMainTitle text-center mb-50" /> */}
          <div className="d-flex feddbackWrapper">
            <div className="feedbackLeft">
              <div className="feedbackLeftBoxInner">
                <div className="feedbackContent">
                  <h2>Feel free to drop us your Feedback</h2>
                </div>
                <img src={require("../../assets/img/feddback/1.png")} alt="" />
              </div>
            </div>
            <div className="feddbackDetail">
              <div className="staticContentWrapper height-auto">
                <div>
                  <InputBlock
                    label="Name*"
                    placeholder="Name"
                    value={formData.name}
                    onChange={(e) => onInputChange("name", e.target.value)}
                    hasError={formData.errors.name ? true : false}
                  />
                  <InputBlock
                    label="Email *"
                    placeholder="Email "
                    value={formData.email}
                    onChange={(e) => onInputChange("email", e.target.value)}
                    hasError={formData.errors.email ? true : false}
                  />
                  <InputBlock
                    label="Mobile Number*"
                    placeholder="Mobile Number"
                    value={formData.mobile}
                    type="number"
                    onKeyPress={handleMobileNumber}
                    onChange={(e) => onInputChange("mobile", e.target.value)}
                    hasError={formData.errors.mobile ? true : false}
                  />
                  <Heading
                    title={`1. ${FEEDBACK_QUESTIONS.QUE_1}`}
                    className="innerPageSmallTitle"
                  />
                  <Radio.Group
                    value={formData.QUE_1}
                    onChange={(e) => {
                      onChange();
                      onInputChange("QUE_1", e.target.value);
                    }}
                  >
                    <Radio value="Print Media" onClick={onClose}>
                      Print Media
                    </Radio>
                    <Radio value="Social Media" onClick={onClose}>
                      Social Media
                    </Radio>
                    <Radio value="Events/Exhibitions" onClick={onClose}>
                      Events/Exhibitions
                    </Radio>
                    <Radio value={4} onClick={other}>
                      Others
                    </Radio>
                  </Radio.Group>
                  {value1 && (
                    <div className="mt-10">
                      <TextArea
                        placeholder="Enter Your Comment"
                        value={formData.QUE_1}
                        onChange={(e) => onInputChange("QUE_1", e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="staticContentWrapper height-auto ">
                <div>
                  <Heading
                    title={`2. ${FEEDBACK_QUESTIONS.QUE_2}`}
                    className="innerPageSmallTitle"
                  />
                  <Radio.Group
                    value={formData.QUE_2}
                    onChange={(e) => {
                      onChange();
                      onInputChange("QUE_2", e.target.value);
                    }}
                  >
                    <Radio value="No" onClick={onClose}>
                      NO
                    </Radio>
                    <Radio value="Yes" onClick={yes1}>
                      Yes
                    </Radio>
                  </Radio.Group>
                  {value2 && (
                    <div className="mt-10">
                      <TextArea
                        placeholder="Enter Your Comment"
                        value={formData.QUE_2}
                        onChange={(e) => onInputChange("QUE_2", e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title={`3. ${FEEDBACK_QUESTIONS.QUE_3}`}
                    className="innerPageSmallTitle"
                  />
                  <Radio.Group
                    value={formData.QUE_3}
                    onChange={(e) => {
                      onChange();
                      onInputChange("QUE_3", e.target.value);
                    }}
                  >
                    <Radio value="No" onClick={onClose}>
                      NO
                    </Radio>
                    <Radio value="Yes" onClick={yes2}>
                      Yes
                    </Radio>
                  </Radio.Group>
                  {value3 && (
                    <div className="mt-10">
                      <TextArea
                        placeholder="Enter Your Comment"
                        value={formData.QUE_3}
                        onChange={(e) => onInputChange("QUE_3", e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title={`4. ${FEEDBACK_QUESTIONS.QUE_4}`}
                    className="innerPageSmallTitle"
                  />
                  <Radio.Group
                    value={formData.QUE_4}
                    onChange={(e) => {
                      onChange();
                      onInputChange("QUE_4", e.target.value);
                    }}
                  >
                    <Radio value="No" onClick={no}>
                      NO
                    </Radio>
                    <Radio value="Yes" onClick={onClose}>
                      Yes
                    </Radio>
                  </Radio.Group>
                  {value4 && (
                    <div className="mt-10">
                      <TextArea
                        placeholder="Enter Your Comment"
                        value={formData.QUE_4}
                        onChange={(e) => onInputChange("QUE_4", e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title={`5. ${FEEDBACK_QUESTIONS.QUE_5}`}
                    className="innerPageSmallTitle"
                  />
                  <div className="feedbackratingBlock">
                    {/* <SmileBlock  number="1" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="2" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="3" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="4" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="5" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="6" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="7" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="8" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="9" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock activeClass number="10" image={require("../../assets/svg/Feedback/smiley.svg")}/> */}
                    <Slider
                      marks={marks}
                      step={100}
                      min={1}
                      max={10}
                      defaultValue={formData.QUE_5}
                      value={formData.QUE_5}
                      onChange={(value) => onInputChange("QUE_5", value)}
                    />
                  </div>
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title={`6. ${FEEDBACK_QUESTIONS.QUE_6}`}
                    className="innerPageSmallTitle"
                  />
                  <div className="feedbackratingBlock">
                    {/* <SmileBlock number="1" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="2" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="3" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="4" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="5" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="6" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="7" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="8" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="9" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="10" image={require("../../assets/svg/Feedback/smiley.svg")}/> */}
                    <Slider
                      marks={marks}
                      step={100}
                      min={1}
                      max={10}
                      defaultValue={formData.QUE_6}
                      value={formData.QUE_6}
                      onChange={(value) => onInputChange("QUE_6", value)}
                    />
                  </div>
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title={`7. ${FEEDBACK_QUESTIONS.QUE_7}`}
                    className="innerPageSmallTitle"
                  />
                  <TextArea
                    placeholder="Enter Your Comment"
                    value={formData.QUE_7}
                    onChange={(e) => onInputChange("QUE_7", e.target.value)}
                  />
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title={`8. ${FEEDBACK_QUESTIONS.QUE_8}`}
                    className="innerPageSmallTitle"
                  />
                  <TextArea
                    placeholder="Enter Your Comment"
                    value={formData.QUE_8}
                    onChange={(e) => onInputChange("QUE_8", e.target.value)}
                  />
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title={`9. ${FEEDBACK_QUESTIONS.QUE_9}`}
                    className="innerPageSmallTitle"
                  />
                  <Radio.Group
                    value={formData.QUE_9}
                    onChange={(e) => {
                      onChange();
                      onInputChange("QUE_9", e.target.value);
                    }}
                  >
                    <Radio value="Better">Better</Radio>
                    <Radio value="Worse">Worse</Radio>
                    <Radio value="About the Same">About the Same</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title={`10. ${FEEDBACK_QUESTIONS.QUE_10}`}
                    className="innerPageSmallTitle"
                  />
                  <div className="feedbackratingBlock">
                    {/* <SmileBlock number="1" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="2" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="3" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="4" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="5" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="6" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="7" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="8" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="9" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="10" image={require("../../assets/svg/Feedback/smiley.svg")}/> */}
                    <Slider
                      marks={marks}
                      step={100}
                      min={1}
                      max={10}
                      defaultValue={formData.QUE_10}
                      value={formData.QUE_10}
                      onChange={(value) => onInputChange("QUE_10", value)}
                    />
                  </div>
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title={`11. ${FEEDBACK_QUESTIONS.QUE_11}`}
                    className="innerPageSmallTitle"
                  />
                  <TextArea
                    placeholder="Enter Your Comment"
                    value={formData.QUE_11}
                    onChange={(e) => onInputChange("QUE_11", e.target.value)}
                  />
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title={`12. ${FEEDBACK_QUESTIONS.QUE_12}`}
                    className="innerPageSmallTitle"
                  />
                  <TextArea
                    placeholder="Enter Your Comment"
                    value={formData.QUE_12}
                    onChange={(e) => onInputChange("QUE_12", e.target.value)}
                  />
                </div>
              </div>
              <div className="staticContentWrapper height-auto width-100">
                <div>
                  <Heading
                    title={`13. ${FEEDBACK_QUESTIONS.QUE_13}`}
                    className="innerPageSmallTitle"
                  />
                  <TextArea
                    placeholder="Enter Your Comment"
                    value={formData.QUE_13}
                    onChange={(e) => onInputChange("QUE_13", e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-10 text-center feedbackAction">
                <button onClick={onSubmit} className="commonButton">
                  Submit
                </button>
                <button onClick={handleFormReset} className="commonOutline">
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Feedback;
