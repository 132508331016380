/* eslint-disable */
import React, {
  useReducer,
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import invert from "lodash/invert";
import {
  cloneDeep,
  isEmpty,
  isEqual,
  xorWith,
  join,
  split,
  find,
  filter,
  map,
  groupBy,
} from "lodash";
import { Popover } from "antd";
import DiamondListingAction from "../common/DiamondListing/DiamondListingAction";
import SelectStone from "../common/DiamondListing/SelectStone";
import Heading from "../common/Heading";
import ListingTop from "../common/DiamondListing/ListingTop";
import DiamondGridView from "./DiamondGrid";
import Table, { LIMIT } from "./TableBack";
import FilterTop from "../common/DiamondListing/FilterTop";
import {
  getColumn,
  fetchDiamondList,
  fetchDiamondPaginate,
  getPath,
  LISTINGPAGES,
  fetchCart,
  fetchWatch,
  fetchReminder,
  fetchConfirmStone,
  fetchStoneOfDay,
  fetchMatchPair,
  handleExclusiveSearchListId,
  fetchNotes,
  fetchOfficeList,
  isMobile,
  isIpade,
  fetchBlockDiamonds,
  fetchTrendingDiamonds,
  fetchComingsoonDiamonds,
} from "./DiamondListFunctions";
import {
  trackTypeObj,
  DIAMOND_FEATURED_GROUP_TYPE,
  DIAMOND_WEB_STATUS,
  DIAMOND_DISPLAY_TYPE,
  LOCAL_STORAGE_VAR,
  SEARCH_TAGS,
  MULTIPLE_SEARCH,
} from "../../constants/Common";
import NoDataShow from "../common/NoDataShow";
import MobileViewList from "./MobileViewList";
import IntlMessages from "../../util/IntlMessages";
import UtilService from "../../services/util";
import Loader from "../../components/common/Loader";
import MasterTags from "../../components/DiamondList/MasterTags";
import moment from "moment";
import { isArray } from "util/utils";

function getElementByClass(name) {
  if (
    document.getElementsByClassName(name) &&
    document.getElementsByClassName(name)[0]
  )
    return document.getElementsByClassName(name)[0];
  else return null;
}

export function getGridHeight(allClasses, initial) {
  let allContainers = {};
  allClasses.map((classname) => {
    allContainers[classname] = getElementByClass(classname)
      ? getElementByClass(classname)
      : 0;
    return true;
  });
  let totalHeight = window.innerHeight - initial;
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}

export function emptyData(loading, length) {
  if (loading) return <NoDataShow message={<Loader loading={true} fixed />} />;
  else if (length === 0) return <NoDataShow />;
  else return <></>;
}

export function getState() {
  const stateVar = {
    listview: true,
    data: [],
    page: 1,
    limit: LIMIT,
    sum: {},
    columns: [],
    count: 0,
    loading: false,
    inTrackDiamonds: [],
    defaultChecked: [],
    inBlockDiamonds: [],
  };
  return stateVar;
}

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

const DiamondList = (props) => {
  const { location, history } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const currentType = useRef(location.pathname);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useState(() => cloneDeep(getState()));
  const sortRef = useRef([]);
  const [listView, setListView] = useState(true);
  const [stockTab, setStockTab] = useState(0);
  const [ids, setIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gridHeight, setGridHeight] = useState(0);

  let isaccount = useMemo(
    () => location.pathname.split("/")[1] === "account",
    [location],
  );
  const USER_PERMIT = UtilService.getLocalStorageItem(
    `${LOCAL_STORAGE_VAR}-userPermissions`,
  );

  useEffect(() => {
    if (isaccount && !isMobile()) {
      props.onSumChange && props.onSumChange(state.sum, currentType.current);
    }
  }, [state.sum, currentType.current]);

  useEffect(() => {
    forceUpdate();
  }, [props.commonData.strip]);

  useEffect(() => {
    const listener = history.listen((route) => {
      if (props.location.pathname !== route.pathname) {
        //clear selected rows on path changes
        dispatch({ type: "RESET_SELECTED_ROWS" });
        isaccount = route.pathname.split("/")[1] === "account";
        setState(cloneDeep(getState()));
      }
      if (props.location.search !== route.search) {
        setState(cloneDeep(getState()));
      }
    });
    return function cleanup() {
      listener();
    };
  }, []);

  useEffect(() => {
    if (getPath() === LISTINGPAGES.TRENDING) setStockTab(4);
    else if (getPath() === LISTINGPAGES.SUGGESTED_STOCK) setStockTab(0);
  }, [getPath()]);

  useEffect(() => {
    window.addEventListener("resize", forceUpdate);

    const searchParam =
      location.search && location.search.length
        ? location.search.split("?")[1]
        : null;
    setIds(
      split(searchParam, ",").map((id, index) => {
        let obj = { id };
        if (index === 0) {
          obj["isActive"] = true;
        }
        return obj;
      }),
    );

    if (!searchParam && getPath() === LISTINGPAGES.LIST) {
      history.push("/" + LISTINGPAGES.DASHBOARD);
    }

    return () => {
      window.removeEventListener("resize", forceUpdate);
    };
  }, [location]);

  useEffect(() => {
    setState({ ...state, loading: true, data: [] });
    const timeout = setTimeout(() => {
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [state.page, state.limit, stockTab, ids]);

  const setResponse = (res) => {
    let columns = getColumn(state);

    if (getPath() === LISTINGPAGES.TRENDING && stockTab === 4) {
      columns = [
        { id: "srNo", accessor: "id", Header: "No", width: 10 },
        {
          Header: "Size",
          accessor: "SIZE",
        },
        {
          Header: "Shape",
          accessor: "SHAPE",
        },
        {
          Header: "Color",
          accessor: "COLOR",
        },
        {
          Header: "Purity",
          accessor: "PURITY",
        },
        {
          Header: "Cut",
          accessor: "CUT",
        },
        {
          Header: "Polish",
          accessor: "POLISH",
        },
        {
          Header: "Symm",
          accessor: "SYMM",
        },
        {
          Header: "Fls",
          accessor: "FLS",
        },
      ].filter((el) => el && el);
    }

    if (getPath() === LISTINGPAGES.CONFIRM) {
      const src = {
        id: "source",
        accessor: "inquirySource",
        Header: "Source",
        Cell: ({ cell }) => {
          return cell.value ? cell.value : "-";
        },
      };
      columns.splice(4, 0, src);
      columns = columns?.filter((column) => column?.id !== "amt");
    }

    const seachFilterTag = res?.seachFilterTag;
    if (!isEmpty(seachFilterTag)) {
      if (!isEmpty(seachFilterTag?.searchData?.isDor)) {
        const inqSourceColumn = [
          {
            Header: "DOR No",
            accessor: "dorNo",
            Cell: ({ cell, row }) => {
              return row?.original?.dorNo ? row?.original?.dorNo : "-";
            },
          },
        ];
        columns = [...columns, ...inqSourceColumn];
      }

      if (
        seachFilterTag?.name === "coo" ||
        (seachFilterTag?.searchData?.cooStatus &&
          !isEmpty(seachFilterTag?.searchData?.cooStatus))
      ) {
        const inqSourceColumn = [
          {
            Header: "Coo Report Type",
            accessor: "cooRptType",
            Cell: ({ cell, row }) => {
              const data = row?.original?.altCert?.find(
                (t) => t?.lbNm === "DBioD",
              );
              return data ? data?.cooRptType : cell?.value ? cell.value : "-";
            },
          },
          {
            Header: "Coo Inscription No",
            accessor: "cooIncNo",
            Cell: ({ cell, row }) => {
              const data = row?.original?.altCert?.find(
                (t) => t?.lbNm === "DBioD",
              );
              return data ? data?.cooIncNo : cell?.value ? cell.value : "-";
            },
          },
        ];
        let inqSourceCol = columns.splice(6, 0, ...inqSourceColumn);
        columns = [...inqSourceCol, ...columns];
      }
    }
    if (res) {
      let diamondData = res.data || [],
        defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(
          `${LOCAL_STORAGE_VAR}-compare-diamond`,
        )
          ? JSON.parse(
            localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`),
          )
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData.map((d) => {
            if (defaultChecked.includes(d.id)) {
              d["isDefaultChecked"] = true;
            }
            return d;
          });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      diamondData = diamondData.map((dt, index) => ({
        ...dt,
        id: dt?.id ? dt.id : dt?._id ? dt?._id : `${index + 1}`,
        //back: dt.oldBack,
        //ctPr: dt.oldCtPr,
        //amt: dt.oldAmt,
      }));
      // }
      setState({
        ...state,
        ...res,
        data: diamondData,
        defaultChecked,
        columns,
        loading: false,
      });
      // document.body.classList.remove("loading-indicator");
    } else {
      setState({ ...state, loading: false, defaultChecked: [] });
    }
  };

  const handleSort = (currentSort) => {
    if (
      currentSort.length !== sortRef.current.length ||
      JSON.stringify(currentSort) !== JSON.stringify(sortRef.current)
    ) {
      sortRef.current = currentSort;
      fetch();
    }
  };

  const fetch = useCallback(() => {
    setState({ ...state, loading: true, data: [] });
    const currentId = ids.find((id) => !!id.isActive);
    const payload = { ...state, sort: sortRef.current };

    if (getPath() === LISTINGPAGES.LIST || getPath() === LISTINGPAGES.FM_LIST) {
      if (currentId && currentId.id) {
        fetchDiamondList(
          payload,
          [{ diamondSearchId: currentId.id }],
          setResponse,
          true,
        );
      }
    } else if (getPath() === LISTINGPAGES.UPCOMING) {
      fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.UPCOMING, setResponse);
    } else if (getPath() === LISTINGPAGES.CART) {
      fetchCart(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.WATCHLIST) {
      fetchWatch(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.REMINDER) {
      fetchReminder(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.CONFIRM) {
      fetchConfirmStone(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.STONE_OF_DAY) {
      fetchStoneOfDay(
        payload,
        DIAMOND_FEATURED_GROUP_TYPE.STONE_OF_DAY,
        setResponse,
      );
    } else if (getPath() === LISTINGPAGES.NEWARR) {
      fetchDiamondPaginate(
        payload,
        { viewType: DIAMOND_DISPLAY_TYPE.NEW },
        setResponse,
      );
    } else if (getPath() === LISTINGPAGES.MATCHPAIR) {
      fetchMatchPair(
        payload,
        window.location.search.split("?")[1],
        setResponse,
      );
    } else if (getPath() === LISTINGPAGES.EXCLUSIVE) {
      handleExclusiveSearchListId(function (filter) {
        if (filter) {
          fetchDiamondList(payload, [filter], setResponse);
        }
      });
    } else if (getPath() === LISTINGPAGES.NOTE) {
      fetchNotes(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.OFFICE) {
      fetchOfficeList(payload, setResponse);
    } else if (getPath() === LISTINGPAGES.QUOTEDAY) {
      let showId = JSON.parse(
        localStorage.getItem(`${LOCAL_STORAGE_VAR}-quoteDay`),
      ).id;
      fetchDiamondList(payload, [{ showId }], setResponse);
      // fetchQuotedayDiamonds(setResponse);
    } else if (getPath() === LISTINGPAGES.HOLD) {
      fetchBlockDiamonds(1, setResponse);
    } else if (getPath() === LISTINGPAGES.MEMO) {
      fetchBlockDiamonds(2, setResponse);
    } else if (getPath() === LISTINGPAGES.SUGGESTED_STOCK) {
      fetchDiamondList(
        payload,
        [
          stockTab === 1
            ? { sectionType: 14, suggestedStockType: "suggested" }
            : { sectionType: 14 },
        ],
        setResponse,
      );
    } else if (
      getPath() === LISTINGPAGES.TRENDING &&
      (stockTab === 2 || stockTab === 3 || stockTab === 4)
    ) {
      let filter = {};
      if (stockTab === 2) {
        filter = { type: 1, shp: 1 };
      } else if (stockTab === 3) {
        filter = { type: 1, shp: 2 };
      } else if (stockTab === 4) {
        filter = { type: 0 };
      }
      fetchTrendingDiamonds(filter, setResponse);
    } else if (getPath() === LISTINGPAGES.COMING_SOON) {
      const user = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
      fetchComingsoonDiamonds(
        { gridColumnName: "GRID_COMING_SOON_DIAMOND_LIST", user: user },
        setResponse,
      );
    }
  }, [state.page, state.limit, sortRef.current, state.sum, stockTab, ids]);

  const clearAllSelectedRows = useCallback(() => {
    let tblRows = document.getElementsByTagName("table")?.[0]?.rows;
    if (tblRows) {
      for (let row of tblRows) {
        row.classList.remove("selectTr");
      }
    }
    dispatch({ type: "RESET_SELECTED_ROWS" });
  }, [dispatch]);

  const getSelection = () => {
    if (
      (isaccount && !isMobile()) ||
      localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`)
    )
      return null;
    return (
      <SelectStone
        sum={state.sum}
        profilListing={
          isaccount
            ? "profileStonSelect"
            : getPath() === LISTINGPAGES.QUOTEDAY
              ? "listingSelected"
              : "diamondListStone"
        }
        currentType={currentType.current}
      />
    );
  };

  const getListTop = () => {
    return (
      <ListingTop
        listGridIcon={
          location.pathname.split("/")[1] === "account" ||
            getPath() === LISTINGPAGES.QUOTEDAY
            ? null
            : true
        }
        timer={getPath() === LISTINGPAGES.QUOTEDAY ? true : false}
        Cart={
          getPath() === LISTINGPAGES.CART ||
            getPath() === LISTINGPAGES.WATCHLIST
            ? true
            : null
        }
        onClick={() => setListView((v) => !v)}
        image={
          listView
            ? require("../../assets/svg/DiamondList/grid.svg")
            : require("../../assets/svg/DiamondList/list.svg")
        }
      />
    );
  };

  const onPaginationChange = useCallback(
    (page, limit) => {
      setState({ ...state, page, limit });
    },
    [state.sum],
  );

  const onFilterChange = (_id) => {
    setIds(
      [...ids].map((i) => {
        i.isActive = i.id === _id;
        return i;
      }),
    );
    setState({ ...state, page: 1 });
  };

  const handleRemoveFilter = (_id) => {
    let __ids = filter(ids, function (val) {
      return val.id !== _id;
    });
    history.push(`/${LISTINGPAGES.LIST}?${join(map(__ids, "id"), ",")}`);
  };

  const handleLoader = (loading) => {
    setState({ ...state, loading });
  };

  const getAction = () => {
    let id = window.location.search.split("?")[1];
    return (
      getPath !== LISTINGPAGES.TRENDING &&
      stockTab !== 4 && (
        <DiamondListingAction
          // cheque={getPath() === LISTINGPAGES.CONFIRM}
          handleLoader={handleLoader}
          modify={
            getPath() === LISTINGPAGES.LIST ||
            getPath() === LISTINGPAGES.UPCOMING ||
            getPath() === LISTINGPAGES.MATCHPAIR ||
            getPath() === LISTINGPAGES.EXCLUSIVE
          }
          onModify={
            () => {
              getPath() === LISTINGPAGES.UPCOMING
                ? id
                  ? props.history.push(`/${LISTINGPAGES.SEARCH}/${id}`)
                  : //: props.history.push(`/${LISTINGPAGES.SEARCH}`);
                  props.history.push({
                    pathname: `/${LISTINGPAGES.SEARCH}`,
                    state: { upcoming: true },
                  })
                : history.push(
                  `/${LISTINGPAGES.SEARCH}/${find(ids, { isActive: true }).id
                  }`,
                  {
                    autoShp: history.location?.state?.autoShp,
                  },
                );
            }
            //history.push("/" + LISTINGPAGES.SEARCH + (searchId.current ? "/" + searchId.current : ""))
          }
          trackType={trackTypeObj[invert(LISTINGPAGES)[getPath()]]}
          {...state}
          {...props}
          currentType={currentType.current}
          nonocompare={
            (USER_PERMIT && !USER_PERMIT?.COMPARE?.insert) ||
            getPath() === LISTINGPAGES.QUOTEDAY
          }
          noDnaCompare={true}
          upcomingComp={getPath() === LISTINGPAGES.UPCOMING ? true : undefined}
          nowatch={
            (USER_PERMIT && !USER_PERMIT?.WATCHLIST?.insert) ||
            getPath() === LISTINGPAGES.WATCHLIST ||
            getPath() === LISTINGPAGES.CONFIRM ||
            getPath() === LISTINGPAGES.UPCOMING ||
            getPath() === LISTINGPAGES.QUOTEDAY
          }
          nocart={
            (USER_PERMIT && !USER_PERMIT?.CART?.insert) ||
            getPath() === LISTINGPAGES.CART ||
            getPath() === LISTINGPAGES.CONFIRM ||
            getPath() === LISTINGPAGES.UPCOMING
            // getPath() === LISTINGPAGES.SUGGESTED_STOCK && stockTab === 1
          }
          noreminder={
            getPath() === LISTINGPAGES.REMINDER ||
            getPath() === LISTINGPAGES.CONFIRM ||
            getPath() === LISTINGPAGES.UPCOMING ||
            getPath() === LISTINGPAGES.QUOTEDAY
          }
          delete={
            (USER_PERMIT &&
              USER_PERMIT?.CART?.delete &&
              getPath() === LISTINGPAGES.CART) ||
            (USER_PERMIT &&
              USER_PERMIT?.WATCHLIST?.delete &&
              getPath() === LISTINGPAGES.WATCHLIST) ||
            (USER_PERMIT &&
              USER_PERMIT?.NOTES?.delete &&
              getPath() === LISTINGPAGES.NOTE) ||
            (USER_PERMIT &&
              USER_PERMIT?.OFFICE?.delete &&
              getPath() === LISTINGPAGES.OFFICE)

            //getPath() === LISTINGPAGES.CART ||
            // getPath() === LISTINGPAGES.WATCHLIST ||
            // getPath() === LISTINGPAGES.REMINDER ||
            // getPath() === LISTINGPAGES.NOTE ||
            // getPath() === LISTINGPAGES.OFFICE
          }
          noconfirm={
            (USER_PERMIT && !USER_PERMIT?.CONFIRM_STONE?.insert) ||
            getPath() === LISTINGPAGES.CONFIRM ||
            // getPath() === LISTINGPAGES.UPCOMING ||
            getPath() === LISTINGPAGES.QUOTEDAY
          }
          nooffice
          nonote={
            (USER_PERMIT && !USER_PERMIT?.NOTES?.insert) ||
            getPath() === LISTINGPAGES.NOTE ||
            getPath() === LISTINGPAGES.CONFIRM ||
            getPath() === LISTINGPAGES.UPCOMING ||
            getPath() === LISTINGPAGES.QUOTEDAY
          }
          noquote
          update={
            (USER_PERMIT &&
              !USER_PERMIT?.NOTES?.update &&
              getPath() === LISTINGPAGES.NOTE) ||
            getPath() === LISTINGPAGES.REMINDER
          }
          nofinalcalc={
            (USER_PERMIT && !USER_PERMIT?.FINAL_CALCULATIONS?.insert) ||
            getPath() === LISTINGPAGES.UPCOMING ||
            getPath() === LISTINGPAGES.CONFIRM
          }
          noshare={
            USER_PERMIT &&
            !USER_PERMIT?.SHARE_VIA_MAIL?.insert &&
            !USER_PERMIT?.SHARE_VIA_WHATSAPP?.insert &&
            !USER_PERMIT?.SHARE_VIA_SKYPE?.insert
          }
          noHold={getPath() === LISTINGPAGES.UPCOMING ? false : true}
          nohold={getPath() === LISTINGPAGES.HOLD}
          nomemo={getPath() === LISTINGPAGES.MEMO}
          nodownload={USER_PERMIT && !USER_PERMIT?.DOWNLOAD?.insert}
          noprint={USER_PERMIT && !USER_PERMIT?.PRINT?.insert}
          norelease={getPath() !== LISTINGPAGES.HOLD}
          nopaginate={getPath() === LISTINGPAGES.TRENDING}
          onPaginationChange={onPaginationChange}
          clearAll={clearAllSelectedRows}
          fetch={fetch}
          loading={loading}
          setLoading={setLoading}
        />
      )
    );
  };

  useEffect(() => {
    let gridHeight = isaccount
      ? isIpade
        ? getGridHeight(
          [
            "headerTopMenu",
            "accountTopBlock",
            "accountTabBox",
            "filterTopMainBlock",
            "diamondListingAction",
            "covidStrip",
            "HeaderStripBlock",
            "smallHeaderWrapper",
          ],
          20,
        )
        : getGridHeight(
          [
            "headerTopMenu",
            "accountTopBlock",
            "accountTabBox",
            "filterTopMainBlock",
            "diamondListingAction",
            "covidStrip",
            "HeaderStripBlock",
            "smallHeaderWrapper",
          ],
          22,
        )
      : getGridHeight(
        [
          "diamondListingAction",
          "headerTopMenu",
          "selectStonHeader",
          "covidStrip",
          "HeaderStripBlock",
        ],
        12,
      );
    setGridHeight(gridHeight);
  }, [state, listView, stockTab, ids, loading, window.innerHeight]);

  const getGridView = () => {
    return (
      <>
        <DiamondGridView
          data={state.data}
          loading={state.loading}
          currentType={currentType.current}
        />
        {emptyData(state.loading, state.data.length)}
      </>
    );
  };

  const INTLTITLE = {
    "diamond-list": "app.diamond-list",
    notes: "app.my-comment",
    "office-list": "app.office-list",
    "confirm-list": "app.confirm-list",
    upcoming: "app.Upcoming",
    "new-arrival-bid-it": "app.newArrival",
    "stone-of-the-day": "app.stoneOfTheDay",
    "new-arrivals": "app.NewArrivals",
    "match-pair": "app.match-pair",
    "my-bid": "app.myBid",
    "belgium-exclusive": "app.belgium-exclusive",
    "quote-day": "app.QuoteDay",
    "suggested-stock": "app.suggestedStock",
    "fm-diamond-list": "app.diamond-list",
    watchlist: "app.WatchList",
    cart: "app.Cart",
    trending: "app.trendingarticle",
    round: "app.Round",
    fancy: "app.fancy",
    "coming-soon": "app.comingsoon",
  };
  // const getTitle = () => {
  //   return INTLTITLE[getPath()] + " (" + formatNumber(state.count) + ")";
  // };

  const getTitle = () => {
    let title = getPath();
    let comp =
      title === "trending" ? (
        <>
          <span
            style={{ fontSize: "small", paddingLeft: "5px" }}
            onClick={() => {
              setStockTab(4);
            }}
          >
            <a className={stockTab === 4 ?  "react-tabs__tab--selected" : null}>
              <IntlMessages id="app.trendingarticle" />
            </a>
          </span>
          <span
            style={{ fontSize: "small", paddingLeft: "5px" }}
            onClick={() => {
              setStockTab(2);
            }}
          >
            <a className={stockTab === 2 ?  "react-tabs__tab--selected" : null}>
              {" "}
              <IntlMessages id="app.Round" />{" "}
            </a>
          </span>
          <span
            style={{ fontSize: "small", paddingLeft: "5px" }}
            onClick={() => {
              setStockTab(3);
            }}
          >
            <a className={stockTab === 3 ?  "react-tabs__tab--selected" : null}>
              {" "}
              <IntlMessages id="app.fancy" />{" "}
            </a>
          </span>
        </>
      ) : (
        <IntlMessages id={`${INTLTITLE[title]}`} />
      );
    return comp;
  };

  return (
    <>
      {isMobile() ? (
        <MobileViewList
          {...props}
          {...state}
          listView={listView}
          currentType={currentType.current}
          parent={{
            getTitle,
            getListTop,
            getSelection,
            getGridView,
            getAction,
          }}
        />
      ) : isaccount ? (
        <div className="searchResultListWrapper profilePage">
          <div className="searchInnerResult">
            <div className="position-relative">{getSelection()}</div>
            {/* {(getPath() === LISTINGPAGES.CART || getPath() === LISTINGPAGES.WATCHLIST) &&
                            getListTop()} */}
            <div
              className="searchResultTable profilePageTable"
              style={{ height: gridHeight, overflow: "auto" }}
            >
              <Table
                data={state.data}
                columns={state.columns}
                loading={state.loading}
                ref={tableRef}
                handleSort={handleSort}
                currentType={currentType.current}
                defaultChecked={state.defaultChecked}
                nostatus={getPath() === LISTINGPAGES.CONFIRM}
              />
              {emptyData(state.loading, state.data.length)}
            </div>
            {getAction()}
          </div>
        </div>
      ) : (
        <div className="searchResultListWrapper">
          <div className="selectStonHeader">
            <div className="d-flex justify-content-between align-items-center width-100 listingTopBlock">
              <div className="d-flex align-items-center  flex-wrap">
                {getPath() === LISTINGPAGES.SUGGESTED_STOCK ||
                  getPath() === LISTINGPAGES.TRENDING ? (
                  <div className="customerSubTab diamondlistTab">
                    <ul className="accountTabBox">
                      {getPath() === LISTINGPAGES.SUGGESTED_STOCK &&
                        getPath() !== LISTINGPAGES.TRENDING && (
                          <>
                            <li
                              onClick={() => {
                                setStockTab(0);
                              }}
                              className={
                                stockTab === 0 ?  "react-tabs__tab--selected" : null
                              }
                            >
                              <a>
                                {" "}
                                <IntlMessages id="app.accurateStock" />{" "}
                              </a>
                            </li>
                            <li
                              onClick={() => {
                                setStockTab(1);
                              }}
                              className={
                                stockTab === 1 ?  "react-tabs__tab--selected" : null
                              }
                            >
                              <a>
                                {" "}
                                <IntlMessages id="app.suggestedStock" />{" "}
                              </a>
                            </li>
                          </>
                        )}
                      {getPath() !== LISTINGPAGES.SUGGESTED_STOCK &&
                        getPath() === LISTINGPAGES.TRENDING && (
                          <>
                            <li
                              onClick={() => {
                                setStockTab(4);
                              }}
                              className={
                                stockTab === 4 ?  "react-tabs__tab--selected" : null
                              }
                            >
                              <a>
                                <IntlMessages id="app.trendingarticle" />
                              </a>
                            </li>
                            <li
                              onClick={() => {
                                setStockTab(2);
                              }}
                              className={
                                stockTab === 2 ?  "react-tabs__tab--selected" : null
                              }
                            >
                              <a>
                                {" "}
                                <IntlMessages id="app.Round" />{" "}
                              </a>
                            </li>
                            <li
                              onClick={() => {
                                setStockTab(3);
                              }}
                              className={
                                stockTab === 3 ?  "react-tabs__tab--selected" : null
                              }
                            >
                              <a>
                                {" "}
                                <IntlMessages id="app.fancy" />{" "}
                              </a>
                            </li>
                          </>
                        )}
                      {/* <div className="info_marquee">
                        <p>
                          Note: 1) The prices mentioned over here are fixed and
                          hence not negotiable. | 2) The grading, patterns &
                          parameters mentioned on our website beyond GIA's
                          grading is solely our perspective based on
                          examinations conducted by our grading department and
                          we do not hold ourself responsible for any conflicts
                          in this regard.
                        </p>
                      </div> */}
                    </ul>
                  </div>
                ) : (
                  <>
                    <div className="d-flex item-centre justify-content-between w100">
                      <Heading title={getTitle()} count={state.count} />
                      <div className="diamondlistInfo">
                        {/* <div className="info_marquee">
                          {state?.seachFilterTag?.searchData?.isCm ? (
                            <p>
                              Please contact your respective sales person if you
                              require CanadaMark card for any CanadaMark stone.
                            </p>
                          ) : (
                            <p>
                              Note: 1) The prices mentioned over here are fixed
                              and hence not negotiable. | 2) The grading,
                              patterns & parameters mentioned on our website
                              beyond GIA's grading is solely our perspective
                              based on examinations conducted by our grading
                              department and we do not hold ourself responsible
                              for any conflicts in this regard.
                            </p>
                          )}
                        </div> */}
                        {/* <Popover
                          overlayClassName="infoPopover"
                          placement="bottomLeft"
                          content={
                            <>
                              <p>
                                <b>Note:</b>
                                1) The prices mentioned over here are fixed and
                                hence not negotiable.
                              </p>
                              <p>
                                {" "}
                                2) The grading, patterns & parameters mentioned
                                on our website beyond GIA's grading is solely
                                our perspective based on examinations conducted
                                by our grading department and we do not hold
                                ourself responsible for any conflicts in this
                                regard.
                              </p>
                            </>
                          }
                          title={false}
                        >
                          <img
                            src={require("../../assets/svg/info.svg")}
                            alt=""
                          />
                        </Popover> */}
                      </div>
                    </div>

                    {SEARCH_TAGS && state?.seachFilterTag?.displayData && (
                      <div className="d-flex width-100 listTag flex-wrap">
                        {state.seachFilterTag && (
                          <MasterTags
                            filterData={state.seachFilterTag.displayData}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              {getSelection()}
              <div className="d-flex align-items-center">
                <div>
                  {MULTIPLE_SEARCH && ids.length > 1 && (
                    <FilterTop
                      handleRemoveFilter={handleRemoveFilter}
                      handleFilterChange={onFilterChange}
                      ids={ids}
                    />
                  )}
                </div>
                {getPath() === LISTINGPAGES.TRENDING && stockTab === 4
                  ? null
                  : getListTop()}
              </div>
            </div>
          </div>
          <div className="searchInnerResult">
            {listView ? (
              <div
                className="searchResultTable"
                style={{ height: gridHeight, overflow: "auto" }}
              >
                <Table
                  ref={tableRef}
                  data={state?.data}
                  columns={state.columns}
                  loading={state.loading}
                  handleSort={handleSort}
                  currentType={currentType.current}
                  defaultChecked={state.defaultChecked}
                  canSort={!(getPath() === LISTINGPAGES.TRENDING)}
                  nocheck={
                    getPath() === LISTINGPAGES.TRENDING && stockTab === 4
                  }
                // page={state.page}
                />
                {emptyData(state.loading, state.data.length)}
              </div>
            ) : (
              <div
                className="searchResultGridView"
                style={{ height: gridHeight, overflow: "auto" }}
              >
                {getGridView()}
              </div>
            )}
            {!(getPath() === LISTINGPAGES.TRENDING && stockTab === 4) &&
              getAction()}
          </div>
        </div>
      )}
    </>
  );
};

export default connect((props) => {
  return props;
}, {})(React.memo(withRouter(DiamondList)));
