/* eslint-disable */
import React from "react";
import HeaderAccountList from ".";
import { GET_DASHBOARD_DATA } from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
import { objectDeepGet } from "../../../util/Api";

export default function HeaderAccountDetails(props) {
  const { headerLinks = [], isHovered, ...rest } = props;

  const [details, setDetails] = React.useState({});

  React.useEffect(() => {
    if (!!isHovered) {
      let obj = {
        savedSearch: true,
        recentSearch: true,
        track: true,
        orderData: true,
        seller: true,
        account: true,
        shippingCount: true,
        dashboardCount: true,
      };
      let api = GET_DASHBOARD_DATA.url;
      let objData = {
        method: GET_DASHBOARD_DATA.method,
        url: api,
        request: obj,
      };
      UtilService.callApi(objData, async function (err, data) {
        if (err) {
        }
        if (data && data.code === "OK") {
          setDetails(data.data);
        }
      });
    }
  }, [isHovered]);

  return (
    <>
      {headerLinks.map((header) => {
        return (
          header.isActive && (
            <HeaderAccountList
              key={header.link}
              {...header}
              {...rest}
              details={objectDeepGet(details, header.detailKey || "")}
            />
          )
        );
      })}
    </>
  );
}
