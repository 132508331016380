/* eslint-disable */
import React, { Component } from "react";
import {
  setTimeFormat,
  setDateFormat,
} from "../../../../services/Commonfunction";

const acts = {
  cart: "Stone(s) are added in cart by you.",
  comment: "Notes are created for stone(s) by you.",
  "confirm-stone": "Stone(s) are confirmed by you.",
  "diamond-search": "Diamond search has been done by you.",
  watchlist: "Stone(s) are added in watchlist by you.",
  offer: "Stone(s) are added in offer by you.",
  appointment: "Stone(s) are booked by you for the office.",
};

class RecentActivity extends Component {
  render() {
    let { activity, loading } = this.props;
    return (
      <div className="rightBlockItem">
        <div
          className={`sacedSearchBox recentActivityBlock ${
            loading ? "loading-indicator-footer" : ""
          }`}
        >
          {activity && activity.length !== 0
            ? activity.map((ac, i) => {
                return (
                  <div key={i} className="savedSearchItem">
                    <div className="savedSearchHead">
                      <div className="searchTitle">{acts[ac.page]}</div>
                      <div className="searchTime">
                        <span>
                          {ac.createdAt ? setDateFormat(ac.createdAt) : "--"}
                        </span>
                        <span>
                          {ac.createdAt ? setTimeFormat(ac.createdAt) : "--"}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            : "No Recent Activity Found"}
        </div>
      </div>
    );
  }
}
export default RecentActivity;
