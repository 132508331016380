/* eslint-disable */
import React from "react";
import { TimePicker } from "antd";
import "./timerpicker.less";
import moment from "moment";

const Timerpicker = (props) => {
  let groupClass = "timerPicker from-group";
  if (props.hasError) {
    groupClass = groupClass + " error-message";
  }
  return (
    <div className={groupClass}>
      <label>{props.label}</label>
      <TimePicker
        onChange={props.onChange}
        value={props.value}
        {...props}
        defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
      />
    </div>
  );
};
export default Timerpicker;
