/* eslint-disable */
import React from "react";

const HeaderRightSidebar = (props) => {
  return (
    <div className={`HeaderRightSidebar ${props.activeClass}`}>
      <div
        className="headerRightOverlay"
        onMouseEnter={props.handleOut}
        onMouseOver={props.handleOut}
        onKeyDown={props.handleOut}
        onClick={props.onClick}
      ></div>
      <div className="headerRightSidebarTop"></div>
      <div className="dropdownRightSide">{props.children}</div>
    </div>
  );
};
export default HeaderRightSidebar;
