/* eslint-disable */
import React from "react";
import Heading from "../common/Heading";
const TermsConditionPopup = () => {
  return (
    <div>
      <Heading
        title="Terms & Conditions"
        className=" popupInnerTitle p-0"
      ></Heading>
      <p>
        To enable buying facility we request you to take out some time to
        understand few key points on the terms, under which sale is affected.
        Some of the terms are in the nature of systems, as per the nature
        defined therein and form part of terms of sale.
      </p>
      <Heading className="p-0 mt-20" title="Basic Terms"></Heading>
      <p>
        Once a diamond is confirmed by a customer for purchase, the same is not
        cancelable.
      </p>
      <p>
        Goods will be invoiced only in the name in which goods are Confirmed.
      </p>
      <p>Goods will be shipped upon receipt of payment by our bank.</p>
      <Heading className="p-0 mt-20" title="Grading disclaimer"></Heading>
      <p>
        sells every diamond with the GIA,IGI and HRD Grading Report and there is
        no guarantee that the grading results of the diamond sold would
        necessarily match with those of any of the laboratory.
      </p>
      <Heading className="p-0 mt-20" title="Disclaimer"></Heading>
      <p>
        The PGS information provided does not factor in, the polish and symmetry
        grades. Please review the polish and symmetry grade to be sure that the
        diamond fits your precise requirements.
      </p>
    </div>
  );
};

export default TermsConditionPopup;
