/* eslint-disable */
import React from "react";
import "./aboutus.css";

const BusinessModel = () => {
  return (
    <>
      <div
        style={{
          textAlign: "left",
          width: "100%",
          borderRadius: "10px",
          padding: "20px",
          backgroundColor: "#fff !important",
          boxShadow: "0 0 9px 0px rgb(0 0 0 / 9%)",
          border: "1px solid #efefef",
          position: "relative",
          marginBottom: "15px",
        }}
      >
        <p>
          Belgium has been synonymous with the finest quality of diamonds
          symbolizing brilliance, beauty and craftsmanship. Belgium has been
          committed to producing beautiful diamonds that pay homage to its
          namesake.
        </p>
        <p>
          Over decades, Belgium continues to be a legacy in the diamond industry
          offering the world’s finest diamonds along with innovative services.
          Our commitment to quality, cutting-edge technology and impeccable
          workmanship continues to result in world-class distinction.
        </p>
        <p>
          We at Belgium ensure that our diamonds are sourced with transparency
          through ethical business practices that create trust in our product.
        </p>
        <p>
          Our passionate commitment to ethical standards, environment and
          customer satisfaction truly must be experienced to be believed, we
          sincerely welcome you to join our family.
        </p>
        <p>
          At Belgium, ethics, transparency, consistency and commitment to the
          highest level of buyer satisfaction are what sets us apart and drives
          everything we do. We have an unrivalled reputation for integrity,
          technical innovation and expertise that we are proud to have achieved
          over the years.
        </p>
        <p>
          Our goal is to source and market high-quality diamonds that excite and
          attract consumers. We believe the best way of creating value is
          through a fully integrated business model, benefiting from efficiency
          gains and better product control.
        </p>
        <p>
          We provide products that consumers value for their inspirational and
          uncompromising quality. We are committed to improving processes,
          continuously investing in developing an expert workforce. This means
          we can source the highest quality polished diamonds whilst providing
          the best customer experience with our customer service team using
          their deep understanding of local consumer needs.
        </p>
        <h3>Distribution</h3>
        <p>
          We distribute our loose polished diamonds and diamond jewellery
          products to all the major global consumer markets. Having a global
          balance of sales reduces our exposure to any one market, and gives us
          the flexibility to capture opportunities as demand changes.
        </p>
        <h3>Vision & Mission</h3>
        <ul>
          <li>
            To conduct business strictly by ethical and value-based means.
          </li>
          <li>
            To remain relevant in an industry undergoing a transformation.
          </li>
          <li>
            Pioneer a model of excellence and efficiency in the business of
            natural diamonds.
          </li>
          <li>
            We are evolving with continuous innovation and commitment to deliver
            our valuable product with personalized care and attention.
          </li>
          <li>
            Our goal is to make our high-end products and services accessible to
            diamond lovers across the globe.
          </li>
          <li>
            Sustaining an environment which optimizes employees' contribution to
            the achievement of company goals and objectives.
          </li>
        </ul>
      </div>
    </>
  );
};

export default BusinessModel;
