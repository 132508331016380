/* eslint-disable */
import React, { useState, useEffect } from "react";
import StaticHeader from "../StaticHeader";
import "../TermsOfUse/staticContent.less";
import { Link } from "react-router-dom";
import Heading from "../common/Heading";
import "./career.less";
import { FETCH_CAREER_DETAILS } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import OpenNotification from "../common/CommonButton/OpenNotification";
import { BASE_URL } from "../../constants/Common";
import Image from "../../components/common/Image";

const CareerItem = (props) => {
  let ext = props.image && props.image.split(".").pop();

  return (
    <Link className="careerBoxItems" to={`/career-detail?id=${props.id}`}>
      <div className="careerImgBlock">
        {["jpg", "jpeg", "png", "gif", "svg"].includes(
          `${ext}`.toLowerCase(),
        ) && <img src={props.image} alt="Not Found" />}
        {["mkv", "wmv", "mp4"].includes(`${ext}`.toLowerCase()) && (
          <video width="250" height="150" controls>
            <source src={props.image} type="video/mp4" />
          </video>
        )}
      </div>
      <div className="careerDetailBlock">
        <h2>{props.title}</h2>
        {props.location && (
          <div className="detail">
            <span className="careerdetailLabel">Location:</span>
            <span className="careerDetailValue">{props.location}</span>
          </div>
        )}
        {(props.salary || props.salaryCount) && (
          <div className="detail">
            <span className="careerdetailLabel">Salary:</span>
            {props.salary && (
              <span className="careerDetailValue">{props.salary}</span>
            )}
            {props.salaryCount && (
              <span className="careerDetailValue">{props.salaryCount}</span>
            )}
          </div>
        )}
        {props.experience && (
          <div className="detail">
            <span className="careerdetailLabel">Experience:</span>
            <span className="careerDetailValue">{props.experience}</span>
          </div>
        )}
        {props.workingDays && (
          <div className="detail">
            <span className="careerdetailLabel">Working Days:</span>
            <span className="careerDetailValue">{props.workingDays}</span>
          </div>
        )}
        {props.positionNm && (
          <div className="detail">
            <span className="careerdetailLabel">Position:</span>
            <span className="careerDetailValue">{props.positionNm}</span>
          </div>
        )}
        {props.language && (
          <div className="detail">
            <span className="careerdetailLabel">Language:</span>
            <span className="careerDetailValue">{props.language}</span>
          </div>
        )}
        {props.termsOfEmployment && (
          <div className="detail">
            <span className="careerdetailLabel">Terms Of Employement:</span>
            <span className="careerDetailValue">{props.termsOfEmployment}</span>
          </div>
        )}
        {props.benefits && (
          <div className="detail">
            <span className="careerdetailLabel">Benifits:</span>
            <span className="careerDetailValue">{props.benefits}</span>
          </div>
        )}
        {props.contact && (
          <div className="detail">
            <span className="careerdetailLabel">Contacts:</span>
            <span className="careerDetailValue">{props.contact}</span>
          </div>
        )}
        {props.dutiesAndRequirements && (
          <div className="detail">
            <span className="careerdetailLabel">Duties and Requirements:</span>
            <span className="careerDetailValue">
              {props.dutiesAndRequirements}
            </span>
          </div>
        )}
      </div>
    </Link>
  );
};

const Career = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    let obj = {
      ...FETCH_CAREER_DETAILS,
      request: {
        page: 1,
        limit: 15,
        sort: [{ createdAt: "ASC" }],
        filter: {
          isDeleted: false,
          isActive: true,
        },
      },
    };

    UtilService.callApi(obj, function (err, data) {
      if (err && err.data) {
        setData(undefined);
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      }

      if (data && data.code === "OK") {
        setData(data.data.list);
      }
    });
  }, []);
  return (
    <>
      <StaticHeader />
      <div className="staticBoxMain">
        <div className="statiContentBox">
          <Heading
            title="Career"
            className="staticMainTitle text-center mb-50"
          />
          <div className="careerBoxWrapepr d-flex flex-wrap">
            {data && data.length > 0 ? (
              data.map((record, i) => (
                <CareerItem
                  key={i}
                  id={record.id}
                  title={record.title}
                  salaryCount={record.salaryCount}
                  salary={record.salary}
                  location={record.jobLocation}
                  position={record.position}
                  experience={record.experience}
                  workingDays={record.workingDays}
                  image={
                    record.img
                      ? `${BASE_URL}/${record.img}`
                      : require("../../assets/img/career/defaultIMG.svg")
                  }
                />
              ))
            ) : (
              <div style={{ alignSelf: "center" }}>
                Keep Visiting this page for updates !
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Career;
