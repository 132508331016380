/* eslint-disable */
import React from "react";
import "./fileUpload.less";

const ProfileUpload = (props) => {
  return (
    <div className="profileUploadBlock">
      <label className="commonLabel">{props.label}</label>
      <div className="fileUploadBlock">
        <input
          type="file"
          crossOrigin="anonymous"
          required={props.required ? true : false}
          accept={props.accept ? props.accept : null}
          onChange={props.onChange}
        />
        <img src={require("./upload.svg")} alt="" />
        <span>{props.title}</span>
      </div>
      <p>{props.dec}</p>
    </div>
  );
};
export default ProfileUpload;
