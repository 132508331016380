/* eslint-disable */
import React, {
  useReducer,
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
  memo,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DiamondListingAction from "../common/DiamondListing/DiamondListingAction";
import Countdown from "./Timer";
import { trackTypeObj, LOCAL_STORAGE_VAR } from "../../constants/Common";
import Table, { LIMIT } from "../DiamondList/TableBack";
import { getGridHeight, emptyData, getState } from "../DiamondList";
import {
  getStoneInfo,
  fetchOffer,
  handleTrackDelete,
  isMobile,
  TITLE,
  getPath,
  LISTINGPAGES,
} from "../DiamondList/DiamondListFunctions";
import MobileViewList from "../DiamondList/MobileViewList";
import { formatNumber } from "../common/DiamondListing/SelectStone";
import cloneDeep from "lodash/cloneDeep";
import IntlMessages from "../../util/IntlMessages";
import UtilService from "../../services/util";
import SelectStone from "../common/DiamondListing/SelectStone";

export const OFFERSTATUS = {
  1: "Pending",
  2: "Accepted",
  3: "Rejected",
};

const Offer = (props) => {
  const { location, history } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const currentType = useRef(location.pathname);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useState(() => cloneDeep(getState(props)));
  const [loading, setLoading] = useState(false);

  const USER_PERMIT = UtilService.getLocalStorageItem(
    `${LOCAL_STORAGE_VAR}-userPermissions`,
  );

  useEffect(() => {
    if (!isMobile()) {
      props.onSumChange && props.onSumChange(state.sum, currentType.current);
    }
  }, [state.sum, currentType.current]);

  useEffect(() => {
    forceUpdate();
  }, [props.commonData.strip]);

  useEffect(() => {
    const listener = history.listen((route) => {
      if (props.location.pathname !== route.pathname) {
        dispatch({ type: "RESET_SELECTED_ROWS" });
        setState(cloneDeep(getState(props)));
      }
      if (props.location.search !== route.search) {
        setState(cloneDeep(getState(props)));
      }
    });
    return function cleanup() {
      listener();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
    fetch();
    return () => {
      window.removeEventListener("resize", forceUpdate);
    };
  }, [location]);

  useEffect(() => {
    fetch();
  }, [state.page, state.limit]);

  const setResponse = (res) => {
    if (res) {
      let diamondData = res.data || [],
        defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(
          `${LOCAL_STORAGE_VAR}-compare-diamond`,
        )
          ? JSON.parse(
              localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`),
            )
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData.map((d) => {
            if (defaultChecked.includes(d.id)) {
              d["isDefaultChecked"] = true;
            }
            return d;
          });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      diamondData = diamondData.map((dt) => ({
        ...dt,
        back: dt.oldBack,
        ctPr: dt.oldCtPr,
        amt: dt.oldAmt,
      }));
      setState({
        ...state,
        ...res,
        data: diamondData,
        defaultChecked,
        loading: false,
      });
    } else {
      setState({ ...state, loading: false, defaultChecked: [] });
    }
  };

  const fetch = useCallback(() => {
    setState({ ...state, editOffer: null, loading: true, data: [] });
    fetchOffer(state, setResponse);
  }, [state.page, state.limit, state.sum]);

  const clearAllSelectedRows = useCallback(() => {
    let tblRows = document.getElementsByTagName("table")[0]?.rows;
    if (tblRows)
      for (let row of tblRows) {
        row.classList.remove("selectTr");
      }
    dispatch({ type: "RESET_SELECTED_ROWS" });
  }, [dispatch]);

  const fetchcolumn = useMemo(() => {
    let columns = [
      {
        Header: "No",
        accessor: "no",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: "Days",
        accessor: "days",
        id: "days",
        Cell: ({ row }) => (
          <span>
            {row.original.offerStatus === 2 ? (
              <span>Offer Accepted</span>
            ) : row.original.offerStatus === 3 ? (
              <span>Offer Rejected</span>
            ) : moment(row.original.offerValidDate) - moment() < 0 ? (
              <span>Offer Expired</span>
            ) : (
              <Countdown
                date={
                  row.original.offerStatus === 1
                    ? moment(row.original.offerValidDate)
                    : moment()
                }
              />
            )}
          </span>
        ),
      },
      {
        Header: "DNA",
        accessor: "dna",
        id: "dna",
      },
      {
        Header: "Stone Info",
        accessor: "stoneinfo",
        Cell: ({ row }) => <span>{getStoneInfo(row.original).join(" ")}</span>,
      },
      {
        Header: "Disc % / Price/Ct /Active",
        accessor: "dispricCt",
        Cell: ({ row }) => (
          <span>
            {(row.original.trackDiscount
              ? parseFloat(row.original.trackDiscount).toFixed(2) + " / "
              : "") + parseFloat(row.original.trackPricePerCarat).toFixed(0)}
          </span>
        ),
      },
      {
        Header: "Quote % / Price/Ct",
        accessor: "quotepricect",
        Cell: ({ row }) => (
          <span>
            {(row.original.newDiscount
              ? parseFloat(row.original.newDiscount).toFixed(2) + " / "
              : "") + parseFloat(row.original.newPricePerCarat).toFixed(0)}
          </span>
        ),
      },
      {
        Header: "Comment",
        accessor: "remarks",
        Cell: ({ cell }) => <>{cell.value || "-"}</>,
      },
      {
        Header: "Seller",
        accessor: "Seller",
        Cell: ({ cell }) => <>{cell.value || "-"}</>,
      },
      {
        Header: "Status",
        accessor: "active",
        Cell: ({ row }) => (
          <div
            className={`${
              row.original.offerStatus === 3 ? "red" : null
            } activeStatus pending`}
          >
            <span>{OFFERSTATUS[row.original.offerStatus]}</span>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "Action",
        Cell: ({ row }) => (
          <div className="actionButton">
            {row.original.offerStatus === 1 &&
            moment(row.original.offerValidDate) - moment() > 0 ? (
              <>
                <div
                  className="actionAction"
                  onClick={() =>
                    setState({ ...state, editOffer: [row.original] })
                  }
                >
                  <img
                    src={require("../../assets/svg/Account/edit.svg")}
                    alt=""
                  />
                </div>
                <div
                  className="actionAction"
                  onClick={() => {
                    handleTrackDelete(
                      trackTypeObj.QUOTE,
                      [row.original.id],
                      state.inTrackDiamonds,
                      () => {
                        clearAllSelectedRows();
                        fetch();
                      },
                    );
                  }}
                >
                  <img
                    src={require("../../assets/svg/Account/delettable.svg")}
                    alt=""
                  />
                </div>
              </>
            ) : null}
          </div>
        ),
      },
    ].map((c) => ({ ...c, id: c.accessor }));
    return columns;
  }, [state]);

  const onPaginationChange = useCallback(
    (page, limit) => {
      setState({ ...state, page, limit });
    },
    [state.sum],
  );

  const INTLTITLE = {
    "diamond-list": "app.diamond-list",
    "quote-list": "app.quote-list",
    upcoming: "app.Upcoming",
    "new-arrival-bid-it": "app.newArrival",
    "stone-of-the-day": "app.stoneOfTheDay",
    "new-arrivals": "app.NewArrivals",
    "match-pair": "app.match-pair",
    "my-bid": "app.myBid",
    "belgium-exclusive": "app.belgium-exclusive",
    "quote-day": "app.QuoteDay",
    "suggested-stock": "app.suggestedStock",
  };
  // const getTitle = () => {
  //   return INTLTITLE[getPath()] + " (" + formatNumber(state.count) + ")";
  // };

  const getTitle = () => {
    let title = getPath();
    let comp = <IntlMessages id={`${INTLTITLE[title]}`} />;
    return comp;
  };

  let isaccount = useMemo(
    () => location.pathname.split("/")[1] === "account",
    [location],
  );

  const getAction = () => {
    // let id = window.location.search.split("?")[1];
    return (
      <DiamondListingAction
        trackType={trackTypeObj.QUOTE}
        {...state}
        {...props}
        currentType={currentType.current}
        delete={USER_PERMIT && USER_PERMIT?.QUOTE?.delete}
        nofinalcalc={USER_PERMIT && !USER_PERMIT?.FINAL_CALCULATIONS?.insert}
        nonocompare={USER_PERMIT && !USER_PERMIT?.COMPARE?.insert}
        noconfirm
        nocart={USER_PERMIT && !USER_PERMIT?.CART?.insert}
        nowatch={USER_PERMIT && !USER_PERMIT?.WATCHLIST?.insert}
        nooffice={USER_PERMIT && !USER_PERMIT?.OFFICE?.insert}
        nonote={USER_PERMIT && !USER_PERMIT?.NOTES?.insert}
        noquote
        norelease
        noshare={
          USER_PERMIT &&
          !USER_PERMIT?.SHARE_VIA_MAIL?.insert &&
          !USER_PERMIT?.SHARE_VIA_WHATSAPP?.insert &&
          !USER_PERMIT?.SHARE_VIA_SKYPE?.insert
        }
        nodownload={USER_PERMIT && !USER_PERMIT?.DOWNLOAD?.insert}
        noprint={USER_PERMIT && !USER_PERMIT?.PRINT?.insert}
        onPaginationChange={onPaginationChange}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        setLoading={(val) => setLoading(val)}
        loading={loading}
      />
    );
  };

  let gridHeight = getGridHeight(
    [
      "headerTopMenu",
      "accountTopBlock",
      "accountTabBox",
      "filterTopMainBlock",
      "diamondListingAction",
      "covidStrip",
      "HeaderStripBlock",
    ],
    10,
  );

  const getSelection = () => {
    if (
      (isaccount && !isMobile()) ||
      localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`)
    )
      return null;

    return (
      <SelectStone
        sum={state.sum}
        profilListing={
          isaccount
            ? "profileStonSelect"
            : getPath() === LISTINGPAGES.QUOTEDAY
            ? "listingSelected"
            : "diamondListStone"
        }
        currentType={currentType.current}
      />
    );
  };

  return isMobile() ? (
    <MobileViewList
      listView={true}
      {...props}
      {...state}
      currentType={currentType.current}
      parent={{ getTitle, getAction, getSelection }}
    />
  ) : (
    <>
      <div className="position-relative">{getSelection()}</div>
      <div className="searchResultListWrapper profilePage">
        <div className="searchInnerResult">
          <div
            className="searchResultTable profilePageTable"
            style={{
              height: `gridHeight + ${state.sum && "70px"} `,
              overflow: "auto",
            }}
          >
            <Table
              data={state.data}
              columns={fetchcolumn}
              loading={state.loading}
              ref={tableRef}
              nostatus={getPath() === LISTINGPAGES.QUOTE}
              canSort={false}
              currentType={currentType.current}
              defaultChecked={state.defaultChecked}
              FilterOption={false}
            />
            {emptyData(state.loading, state.data.length)}
          </div>
          {getAction()}
        </div>
      </div>
    </>
  );
};

export default connect((props) => {
  return props;
}, {})(withRouter(Offer));
