/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Heading from "../../common/Heading";
import InputBlock from "../../common/InputBlock";
import SelectOption from "../../common/SelectOption";
export const companyDetail = {
  Country: [
    { value: "Afghanistan " },
    { value: "Albania " },
    { value: "Algeria " },
    { value: "American Samoa" },
    { value: "Andorra" },
    { value: "Angola" },
    { value: "India" },
  ],
  State: [
    { value: "Maharashtra " },
    { value: "Andhra Pradesh" },
    { value: "Arunachal Pradesh" },
    { value: "Assam " },
    { value: "Bihar" },
    { value: "Dadra and Nagar Haveli " },
    { value: "Daman and Diu" },
  ],
  City: [
    { value: "Achalpur" },
    { value: "Ahmadnagar" },
    { value: "Akola" },
    { value: "Amravati" },
    { value: "AURANGABAD" },
    { value: "Miraj" },
    { value: "MUMBAI" },
  ],
};
class AddressForm extends Component {
  render() {
    return (
      <div>
        <div className="profileHead">
          <Heading title="Add Address" className="popupInnerTitle"></Heading>
        </div>
        <div className="profileDetailSpace">
          <InputBlock placeholder="Name" label="Name" />
          <InputBlock placeholder="Address Line 1" label="Address Line 1" />
          <InputBlock placeholder="Address Line 2" label="Address Line 2" />
          <SelectOption
            defaultValue="Country"
            selectValue={companyDetail.Country}
            label="Select Country*"
          />
          <SelectOption
            defaultValue="State"
            selectValue={companyDetail.State}
            label="Select State*"
          />
          <SelectOption
            defaultValue="City"
            selectValue={companyDetail.City}
            label="Select City*"
          />
          <InputBlock placeholder="Zip Code*" label="Zip Code" />
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton">Save Address</a>
          <a className="commonButton">Cancel</a>
        </div>
      </div>
    );
  }
}
export default withRouter(AddressForm);
