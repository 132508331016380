/* eslint-disable */
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

const useRowSelect = (currentType) => {
  const dispatch = useDispatch();
  const selectedRowIds =
    useSelector((state) => state.diamondData.selectedRowIds[currentType]) || [];
  const toggleRowSelection = (row, setValue = true) => {
    if (!row.id) return;
    dispatch({
      type: setValue ? "SELECT_ROW" : "UNSELECT_ROW",
      payload: row,
      currentType,
    });
    const tableRows = document.querySelectorAll(
      `[table-row="${currentType}${row.id}"]`,
    );
    for (let row of tableRows) {
      row.classList[setValue ? "add" : "remove"]("selectTr");
    }
  };

  const clearAllRows = () => {
    dispatch({ type: "RESET_SELECTED_ROWS" });
  };

  const toggleAllRowSelection = useCallback(
    (displayedRows) => {
      const areAllChecked = displayedRows.every((r) =>
        selectedRowIds.includes(r.id),
      );
      const bulkRows = [];

      // if not all rows are checked, then it will select, otherwise unselect
      displayedRows.map((r) =>
        bulkRows.push({ row: r, status: !areAllChecked }),
      );
      dispatch({
        type: "BULK_SELECT_ROW",
        payload: bulkRows,
        currentType,
      });
    },
    [selectedRowIds],
  );

  const isHeaderChecked = (displayedRows) =>
    Boolean(
      displayedRows.length > 0 &&
        displayedRows
          .map((r) => r.id)
          .every((id) => selectedRowIds.includes(id)),
    );

  const isRowChecked = (rowId) => selectedRowIds.includes(rowId);

  return {
    isRowChecked,
    isHeaderChecked,
    toggleRowSelection,
    toggleAllRowSelection,
    clearAllRows,
  };
};
export default useRowSelect;
