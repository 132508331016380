/* eslint-disable */
import React, { Component } from "react";
import LoginForm from "./Auth/LoginForm";
import { LOCAL_STORAGE_VAR } from "../constants/Common";
import AuthPoster from "./Auth/AuthPoster";
import PopupBanner from "./PopupBanner";
import UtilService from "../services/util";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: true,
      lastLocation: null,
    };
  }

  componentDidMount() {
    let showInfo = JSON.parse(
      localStorage.getItem(`${LOCAL_STORAGE_VAR}-cookie-policy`),
    );
    let getLastLocation = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-last`,
    );
    if (getLastLocation) {
      this.setState({ lastLocation: getLastLocation });
    } else {
      this.setState({ lastLocation: null });
    }
    if (showInfo) {
      this.setState({ showInfo: false });
    }
  }
  setCookie = () => {
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-cookie-policy`, true);
    this.setState({ showInfo: false });
  };
  render() {
    return (
      <>
        <PopupBanner />
        <div className="main-screen">
          <div className="loginBasicList">
            <ul>
              <li>
                <a href="news">News & Event</a>
              </li>
              {/* <li>
                <a href="/bidding-procedure">Bidding Procedure</a>
              </li> */}
              <li>
                {/* <a href="/assets/img/KYCForm/KYC_FORM.pdf" download="KYC_FORM.pdf"> */}
                <a
                  tag="a"
                  target="blank"
                  rel="noreferrer noopener"
                  href="/KYC_FORM.pdf"
                >
                  Download KYC Form
                </a>
              </li>
              <li>
                <a href="/book-appointment">Take Appointment</a>
              </li>
              <li>
                <a href="/terms-condition">Terms</a>
              </li>
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
              <li>
                <a href="/career">Career</a>
              </li>
              {/* <li>
                <a href="/feedback">Feedback</a>
              </li> */}
              {/* <li>
                <a href="/kyc-form">Online KYC</a>
              </li> */}
              <li>
                <a href="/about-us">About Us</a>
              </li>
            </ul>
          </div>
          <div
            // className={
            //   this.state.showInfo
            //     ? "loginMainBlocks loginBottomSpace loginFormBlock"
            //     : "loginMainBlocks loginFormBlock"
            // }
            className="loginMainWrapper"
          >
            <div
              className="d-flex"
              style={{ width: "100%", height: "100%", alignItems: "flex-end" }}
            >
              <AuthPoster />
              <div className="LoginRightBlock">
                <LoginForm
                  history={this.props.history}
                  lastLocation={this.state.lastLocation}
                />
              </div>
            </div>
            {/* {this.state.showInfo ? (
            <div className="fixBoxSticky">
              <div className="fixBoxInner">
                <p>
                  By continuing to use our site you are consenting to Belgium using cookies in
                  accordance with our <a href="/privacy-policy">Cookie Policy.</a>{" "}
                </p>
                <button className="button button-outline" onClick={this.setCookie}>
                  Got it
                </button>
              </div>
            </div>
          ) : null} */}
          </div>
        </div>
      </>
    );
  }
}

export default SignIn;
