/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { LOCAL_STORAGE_VAR } from "../../../constants/Common";
import IntlMessages from "../../../util/IntlMessages";
import { isMobile } from "components/DiamondList/DiamondListFunctions";

const Footer = () => {
  const token = localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`);

  return (
    <>
      <div className="footerSection">
        <div className="homeContainer">
          <div className="footerWrapper">
            <ul>
              <li>
                <IntlMessages id="app.copyrightText" />
                {`${new Date().getFullYear().toString()}. `}
                <IntlMessages id="app.Allrightsreserved" />
              </li>
            </ul>
            <ul>
              <li>
                <a href="/about-us">
                  <IntlMessages id="app.AboutUs" />
                </a>
              </li>
              <li>
                <a
                  href={
                    isMobile() ? "/device/terms-condition" : "/terms-condition"
                  }
                >
                  <IntlMessages id="app.termsOfUse" />
                </a>
              </li>
              <li>
                <a href="/privacy-notice">
                  <IntlMessages id="app.privacyNotice" />
                </a>
              </li>
              <li>
                <a href="/contact-us">
                  <IntlMessages id="app.ContactUs" />
                </a>
              </li>
              <li>
                <a href="/career">
                  <IntlMessages id="app.Career" />
                </a>
              </li>
              {token && (
                <li>
                  <a href="/feedback">
                    <IntlMessages id="app.Feedback" />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
