/* eslint-disable */
import React from "react";
import { Tooltip } from "antd";

const DiamondDetailAction = (props) => {
  return (
    <Tooltip
      title={props.tooltip}
      overlayClassName="tooltipShow"
      placement={props.placement ? props.placement : null}
    >
      <div className="diamondDetailActionItem" onClick={props.onClick}>
        <img src={props.image} alt="" />
      </div>
    </Tooltip>
  );
};
export default DiamondDetailAction;
