/* eslint-disable */
import React from "react";
import { Select } from "antd";
import SelectCurrency from "./SelectCurrency";
import { isArray, isEmpty } from "util/utils";
import "./selectOption1.less";

function SelectOption({ orderDetail, required, ...props }) {
  const {
    label,
    selectValue,
    defaultValue,
    sortValue,
    options,
    nameKey,
    valueKey = "key",
    className,
    error,
    ...rest
  } = props;

  const optionList = React.useMemo(() => {
    const list = isArray(options) ? options : selectValue;
    if (!isArray(list)) return [];
    return list?.map((option) => ({
      key: (isEmpty(option[valueKey])
        ? `${option.key}`
        : `${option[valueKey]}`
      ).toString(),
      value: isEmpty(option[nameKey])
        ? option.value
        : option[nameKey] ?? option.webDisplay,
    }));
  }, [nameKey, options, selectValue, valueKey]);

  const value = React.useMemo(() => {
    if (rest?.mode === "multiple") {
      const filterList = optionList?.filter((option) =>
        rest?.value?.includes(option.key),
      );
      const list = filterList?.map(({ key }) => key);

      return isArray(optionList) && filterList.length > 0
        ? isArray(rest?.value)
          ? rest?.value?.filter((item) => list.includes(item))
          : rest.value
        : undefined;
    } else {
      if (!props.stageList) {
        return isArray(optionList) &&
          optionList?.filter((option) => option.key === rest?.value).length
          ? String(rest?.value) ?? defaultValue
          : defaultValue ?? undefined;
      } else {
        return rest?.value;
      }
    }
  }, [defaultValue, optionList, props.stageList, rest.mode, rest.value]);

  if (orderDetail) return <SelectCurrency {...props} />;

  function orderListByKey(data, key, order) {
    const compareValues =
      (key, order = "asc") =>
      (elemA, elemB) => {
        if (!elemA.hasOwnProperty(key) || !elemB.hasOwnProperty(key)) return 0;
        const comparison = elemA[key].localeCompare(elemB[key]);
        return order === "desc" ? comparison * -1 : comparison;
      };
    return data.sort(compareValues(key, order));
  }

  return (
    <div
      className={
        `formSelect from-group ${className}` + (error ? " error-message" : "")
      }
    >
      <label>
        {label} {required && <span className="required-ast">*</span>}
      </label>
      <Select
        getPopupContainer={(trigger) => trigger}
        filterOption={(input, option) =>
          option.props.children?.toLowerCase()?.indexOf(input.toLowerCase()) >=
          0
        }
        allowClear
        showArrow
        showSearch
        {...rest}
        value={value}
      >
        {sortValue
          ? isArray(optionList) &&
            sortValue &&
            orderListByKey(optionList, "value").map((option, key) => (
              <Select.Option key={key} value={option.key}>
                {option.value}
              </Select.Option>
            ))
          : isArray(optionList) &&
            optionList?.map((option, key) => (
              <Select.Option key={key} value={option.key}>
                {option.value}
              </Select.Option>
            ))}
        {props.stageList &&
          props.stageList?.map((option) => (
            <Select.Option key={option?.id} value={option?.id}>
              {option?.webDisplay}
            </Select.Option>
          ))}
      </Select>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default SelectOption;
