/* eslint-disable */
import React from "react";
import { formatNumber } from "../DiamondListing/SelectStone";
import "./title.less";

const Heading = (props) => {
  return (
    <h2
      className={`mainHeadingTitle ${props.className ? props.className : ""}`}
    >
      {props.title}{" "}
      {window.shortPath() !== "trending" && props.count
        ? `(${formatNumber(props.count)})`
        : ""}
    </h2>
  );
};
export default Heading;
