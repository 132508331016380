import { logoutAPICall } from "services/util";

export const BASE_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://api.belgiumny.com"
    : "https://demoapi.belgiumny.com";

export const DOWNLOAD_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://download.belgiumny.com"
    : "https://demoapi.belgiumny.com";

export const DASHBOARD_MEDIA_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://api.belgiumny.com"
    : "https://demoapi.belgiumny.com";

export const BASE_DOWNLOAD_URL = `${DOWNLOAD_URL}/data`;
export const PRINT_URL = `${BASE_URL}/data`;

export const BASE_URL_ATTACH = BASE_URL;
export const LOCAL_STORAGE_VAR = "fs-local";
export const MEDIA_URL = "https://s3.ap-south-1.amazonaws.com/finestargroup";
export const VIEW_DNA_URL = "http://views.diadna.com";

export const RANGE_WISE_MASTER_SELECTION = false;
export const SEARCH_TAGS = true;
export const MULTIPLE_SEARCH = false;
export const IS_QUICK_SEARCH = false;
export const PRICE_CALCULATOR = false;
export const WHITE_LISTED_ROUTES = ["/admin-redirect"];

export const LAB_LINKS = {
  IGI: "https://api.igi.org/viewpdf.php?r=***",
  GSI: "http://wg.gemscience.net/vr/veri.aspx",
  GIA: "https://www.gia.edu/report-check?reportno=***",
  HRD: "https://my.hrdantwerp.com/?record_number=***",
  TRCR: "https://search.finestar.tracr.com/search/***",
  XALL: "https://www.gia.edu/report-check?reportno=***",
};

export const ARTISIAN_CERT_URL =
  "https://diamondmedia.s3.us-east-1.amazonaws.com/ARTISAN/***.pdf";

export const FILE_URLS = {
  img: "http://diamondmedia.s3.amazonaws.com/B2B/imaged/***/still.jpg",
  certFile:
    "https://s3.ap-south-1.amazonaws.com/finestargroup/CertiImages/***.pdf",
  videoFile: "https://s3.ap-south-1.amazonaws.com/finestargroup/Mov/***.mp4",
  hAFile:
    "https://s3.ap-south-1.amazonaws.com/finestargroup/HeartImages/***.jpg",
  arrowFile:
    "https://s3.ap-south-1.amazonaws.com/finestargroup/ArrowImages/***.jpg",
  assetFile:
    "https://s3.ap-south-1.amazonaws.com/finestargroup/AssetScopeImages/***.jpg",
};

export const XML_MEDIA_IMAGE =
  "https://diamondmedia.s3.us-east-1.amazonaws.com/XRAY/IMAGE/";

export const XML_MEDIA_XML =
  "https://diamondmedia.s3.us-east-1.amazonaws.com/XRAY/XML/";
export const XML_MEDIA_PLOTING =
  "https://diamondmedia.s3.us-east-1.amazonaws.com/XRAY/PLOTTING/";

export const CONTECT_US_LINK = {
  MAIL_TO: "info@belgiumgroup.net",
  RAP_NET: "https://trade.rapnet.com/#/memberprofile/61666",
  WHATS_APP: "https://wa.me/13477010415",
  PHONE_No: "+12129378715",
};

export const bussinessTypeArray = {
  "Independent Jeweler": "Independent Jeweler",
  "Online Jewelry Store": "Online Jewelry Store",
  "Diamond Dealer/ Broker": "Diamond Dealer/ Broker",
  "Diamond Manufacturer / Cutter": "Diamond Manufacturer / Cutter",
  "Jewelry Manufacturer": "Jewelry Manufacturer",
  "Jewelry Retail Chain": "Jewelry Retail Chain",
  "Pawn shop": "Pawn shop",
  Appraiser: "Appraiser",
  Designer: "Designer",
  "Not in the Diamond / Jewelry Trade": "Not in the Diamond / Jewelry Trade",
  "Gold Buyer": "Gold Buyer",
  "Diamond Mining": "Diamond Mining",
  Auctioneer: "Auctioneer",
  "Trade Association": "Trade Association",
  "Watch Dealer": "Watch Dealer",
  "Finance / Banking": "Finance / Banking",
  Investor: "Investor",
  "Jewelry Appraiser": "Jewelry Appraiser",
  "Jewelry Retailer": "Jewelry Retailer",
  "Diamond and Pearl Dealer / Broker": "Diamond and Pearl Dealer / Broker",
  "Colored Stone Dealer / Broker": "Colored Stone Dealer / Broker",
  "Estate Jewelry Dealer / Broker": "Estate Jewelry Dealer / Broker",
  Other: "Other",
};

export const ADDRESS_TYPES = [
  "HOME",
  "ACCOUNT",
  "BUYER",
  "SHIPPING",
  "BILLING",
  "OTHER",
];

export const page = { path: "/search-result", component: "DiamondSearchGrid" };

export const commonLogout = () => {
  let rememberMe = localStorage.getItem(`${LOCAL_STORAGE_VAR}-rememberMe`);
  let rememberUser = localStorage.getItem(`${LOCAL_STORAGE_VAR}-remember-user`);
  let token = localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`);
  logoutAPICall(token);

  localStorage.clear();
  if (rememberMe) {
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-rememberMe`, rememberMe);
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-remember-user`, rememberUser);
  }
  window.location.href = window.location.origin + "/signin";
};

export const REGISTRATION_TYPES_DATA = { INTERNATIONAL: 1, NORTH_AMERICA: 2 };

export const reference = [
  "Select Reference",
  "Sales Person",
  "Third Party Reference",
  "RapNet",
  "VDB App",
  "LinkedIn",
  "WhatsApp",
  "Facebook",
  "Instagram",
  "Google",
  "WeChat",
  "Twitter",
  "QQ",
  "News",
  "Visit",
  "Walk In",
  "Other",
];
export const DIAMOND_BLOCK = {
  TYPE: { HOLD: 1, MEMO: 2 },
};
export const authorizedPages = [
  "diamond-search",
  "fancy-search",
  "quick-search",
  "search-result",
  "match-pair",
  "parcel-search",
  "parcel-list",
  "upcoming-diamonds,",
  "compare",
  "selected-stones",
  "dashboard",
  "my-cart",
  "my-tracked-diamonds",
  "my-reminder",
  "my-comment",
  "my-enquiry",
  "price-calculator",
  "my-account",
  "appointment-history",
  "my-demand",
  "my-saved-search",
  "change-password",
  "settings",
  "company-details",
  "address-book",
  "change-email-address",
  "invoice",
  "best-of-finestar",
  "new-diamonds",
  "customer-care/:name",
  "matchpair-settings",
  "my-order",
  "recommended",
  "customer-care/appointment",
  "upload-diamonds",
  "upload-images",
  "upload-certificates",
  "diamond-history",
  "people",
  "manage-diamonds",
  "uploaded-sheet-details",
  "feedback",
  "thankyou-for-feedback",
];

export const myAccount = {
  personalDetails: {
    key: "my-account",
    label: "Personal Details",
    logo: "personal-detail.svg",
  },
  companyDetails: {
    key: "company-details",
    label: "Company Details",
    hideType: [3],
    logo: "company-detail.svg",
  },
  userDetails: {
    key: "user-details",
    label: "User Details",
    hideType: [3],
    logo: "user-detail.svg",
  },
  settings: { key: "settings", label: "Settings", logo: "setting.svg" },
  changePswd: {
    key: "change-password",
    label: "Change Password",
    logo: "change-password.svg",
  },
  otherDetails: {
    key: "other-details",
    label: "Other Details",
    hideType: [3],
    logo: "otherDetail.svg",
  },
  manufacturingDetails: {
    key: "manufacturing-details",
    label: "Manufacturing Facility Details",
    hideType: [3],
    logo: "manufacturing.svg",
  },
};

export const diamondParameters = [
  { key: "shp", name: "Shape", master: "SHAPE" },
  { key: "col", name: "Color", master: "COLOR" },
  { key: "clr", name: "Clarity", master: "CLARITY" },
  { key: "flu", name: "Fluorescence", master: "FLUORESCENCE" },
  { key: "shd", name: "Color Shade", master: "SHADE" },
  { key: "lb", name: "Lab", master: "LAB" },
  { key: "cut", name: "Cut", master: "CUT" },
  { key: "pol", name: "Polish", master: "POLISH" },
  { key: "sym", name: "Symmetry", master: "SYMMETRY" },
  { key: "loc", name: "Location", master: "LOCATION" },
  { key: "inten", name: "Intensity", master: "INTENSITY" },
  { key: "ovrtn", name: "Overtone", master: "OVERTONE" },
  { key: "blkInc", name: "Black Table", master: "BLACK_INCLUSION" },
  { key: "eCln", name: "Eye Clean", master: "EYECLEAN" },
  { key: "mlk", name: "Milky", master: "MILKEY" },
  { key: "hA", name: "Heart and Arrow", master: "H_AND_A" },
  { key: "rap", name: "Rap Discount" },
  { key: "ctPr", name: "Price/Carat" },
  { key: "amt", name: "Amount" },
  { key: "depPer", name: "Depth%" },
  { key: "tblPer", name: "Table%" },
  { key: "grdl", name: "Girdle" },
  { key: "pAng", name: "Pavilion Angle" },
  { key: "cAng", name: "Crown Angle" },
  { key: "cHgt", name: "Crown Height" },
  { key: "pHgt", name: "Pavilian Depth" },
  { key: "dmtrMn", name: "Diameter Min" },
  { key: "dmtrMxn", name: "Diameter Max" },
  { key: "or", name: "Carat", master: "CARAT" },
  { key: "ratio", name: "Ratio" },
  { key: "showSrNo", name: "Show ID" },
  { key: "kToSArr", name: "Key To Symbol", master: "KEY_TO_SYMBOLS" },
  { key: "fcCol", name: "Fancy Color" },
  { key: "grdlCond", name: "Girdle Condition" },
  { key: "cultCond", name: "Culet Condition" },
  { key: "cult", name: "Culet" },
  { key: "stoneId", name: "Stone ID/ Report Number" },
  { key: "vnd", name: "Seller Name" },
  { key: "vStnId", name: "Seller Stone Number" },
  { key: "length", name: "Length" },
  { key: "width", name: "Width" },
  { key: "height", name: "Depth" },
  { key: "pairStkNo", name: "Pair No" },
  { key: "isSeal", name: "Seal" },
  { key: "brlncy", name: "Brilliance" },
  { key: "isDor", name: "DOR" },
  { key: "isFm", name: "FM" },
  { key: "blkTbl", name: "Cenetr Natts" },
  { key: "blkSd", name: "Side Natts" },
  { key: "wTbl", name: "Center" },
  { key: "wSd", name: "Side" },
  { key: "lwr", name: "Lower Half" },
  { key: "strLn", name: "Star Length" },
  { key: "isXray", name: "Xray" },
  { key: "type2", name: "Type2" },
  { name: "Disc %", key: "back" },
];

export const trackTypeObj = {
  CART: 1,
  WATCHLIST: 2,
  REQUEST: 3,
  QUOTE: 4,
  REMINDER: 5,
  ENQUIRY: 6,
  COMMENT: 99,
  SHIPMENT: 7,
  SHOWSELECT: 8,
};

export const fields = {
  firstName: "First Name",
  lastName: "Last Name",
  name: "Name",
  email: "Email",
  emails: "Email Address",
  mobile: "Mobile No.",
  mobiles: "Mobile No.",
  countryCode: "Countrycode",
  username: "Username",
  password: "Password",
  cpassword: "Confirm Password",
  dob: "Birth Date",
  phone: "Telephone No.",
  companyName: "Company Name",
  designation: "Designation",
  BusinessType: "Business Type",
  address1: "Address Line1",
  address2: "Address Line2",
  country: "Country",
  state: "State",
  city: "City",
  pinCode: "Zip Code",
  fax: "Fax",
  howKnow: "How do you know us?",
  photoId: "Photo Identity Proof",
  anniversary: "Anniversary Date",
  businessId: "Business identity Proof",
  isTermsCondAgree: "Terms & Conditions",
};

export const requiredFields = [
  "firstName",
  "lastName",
  "email",
  "username",
  "password",
  "cpassword",
  "mobile",
  "companyName",
  "BusinessType",
  "address1",
  "country",
  "state",
  "city",
  "pinCode",
  "photoId",
  "businessId",
  "isTermsCondAgree",
];

export const requiredCompanyFields = [
  "companyName",
  "address",
  "landMark",
  "zipCode",
  "businessType",
  "designation",
  "howKnow",
  "country",
  "state",
  "city",
  "faxes",
];

export const requiredaddressField = [
  "address",
  "zipCode",
  "country",
  "state",
  "city",
];

export const requiredContactFields = ["name", "email", "mobile", "messege"];

export const contactFields = {
  name: "Name",
  email: "Email",
  mobile: "Mobile",
  messege: "Message",
};

export const companyFields = {
  companyName: "Company Name",
  address: "Address",
  landMark: "LandMark",
  zipCode: "ZipCode",
  businessType: "BusinessType",
  designation: "Designation",
  howKnow: "HowKnow",
  country: "Country",
  state: "State",
  city: "City",
  faxes: "Faxes",
};

export const addressField = {
  address: "Address",
  zipCode: "ZipCode",
  country: "Country",
  state: "State",
  city: "City",
};

export const changePswdFields = {
  currentPassword: "Current Password",
  newPassword: "New Password",
  repeatPassword: "Repeat Password",
};

export const requiredChangePswdFields = [
  "currentPassword",
  "newPassword",
  "repeatPassword",
];

export const requiredResetPswdFields = ["confirmPassword", "newPassword"];

export const resetPswdFields = ["confirmPassword", "newPassword"];

export const fieldsrequiredGuest = ["name", "companyName", "mobile", "email"];

export const fieldsGuest = {
  name: "Name",
  companyName: "Company Name",
  countryCode: "Countrycode",
  mobile: "Contact No.",
  email: "Email",
};

export const BANNERS_TYPES = {
  CLIENT_PAGE_BANNER: 1,
  MOBILE_APP: 2,
  CLIENT_PAGE_POPUP_1: 3,
  CLIENT_PAGE_POPUP_2: 4,
  CLIENT_PAGE_POPUP_3: 5,
  CLIENT_PAGE_POPUP_4: 6,
  ADMIN_LOGIN_BANNER: 28,
};

export const BANNER_TYPES = {
  LOGIN_PAGE: 1,
  REGISTER_PAGE: 2,
  FORGOT_PASSWORD_PAGE: 3,
  GUEST_LOGIN: 4,
  CALENDAR: 5,
  DASHBOARD: 6,
};

export const MonthName = {
  JAN: "JAN",
  FEB: "FEB",
  MAR: "MAR",
  APR: "APR",
  MAY: "MAY",
  JUNE: "JUNE",
  JULY: "JULY",
  AUG: "AUG",
  SEP: "SEP",
  OCT: "OCT",
  NOV: "NOV",
  DEC: "DEC",
  INFO: "INFO",
  SHOW: "SHOW",
};

export const RapPer = [-99, -80, -60, -40, -20, 0, 20, 40, 60, 80, 99];

const labMaster = localStorage.getItem("fs-master");
const labGIA =
  labMaster &&
  JSON.parse(labMaster) &&
  JSON.parse(labMaster).LAB.find((x) => x.code === "GIA");

export const Inventory = [
  { carat: { ">=": 0.18, "<=": 0.96 }, lab: labGIA && labGIA.id },
  { carat: { ">=": 0.96, "<=": 2 }, lab: labGIA && labGIA.id },
];

export const FILE_TYPES = {
  "image/png": ["89504e47"],
  "image/jpeg": ["ffd8ffe0", "ffd8ffe3", "ffd8ffe2", "ffd8dde0"],
  "image/jpg": ["ffd8ffe0", "ffd8ffe8", "ffd8ffe1"],
};

export const FILE_TYPES_PDF = {
  "image/png": ["89504e47"],
  "image/jpeg": ["ffd8ffe0", "ffd8ffe3", "ffd8ffe2", "ffd8dde0"],
  "image/jpg": ["ffd8ffe0", "ffd8ffe8", "ffd8ffe1"],
  "application/pdf": ["25504446"],
};

export const CALENDER_FIELD = [
  { action: "JANUARY", label: "JAN" },
  { action: "FEBRUARY", label: "FEB" },
  { action: "MARCH", label: "MAR" },
  { action: "APRIL", label: "APR" },
  { action: "MAY", label: "MAY" },
  { action: "JUNE", label: "JUN" },
  { action: "JULY", label: "JUL" },
  { action: "AUGUST", label: "AUG" },
  { action: "SEPTEMBER", label: "SEP" },
  { action: "OCTOBER", label: "OCT" },
  { action: "NOVEMBER", label: "NOV" },
  { action: "DECEMBER", label: "DEC" },
  { action: "SHOW", label: "SHOW" },
  { action: "INFO", label: "INFO" },
];

export const DiamondAllotingDate = "April 5,2018";

export const DiamondAssesingDate = "April 4,2018";

export const DiamondGradingDate = "May 22,2018";

export const DiamondOriginDate = "March 21,2018";

export const DiamondPlanningDate = "April 10,2018";

export const DiamondPolishingDate = "April 27,2018";

export const DiamondQualityDate = "May 3,2018";

export const DiamondLaserDate = "April 10,2018";

export const storeSessionUUID = () => {
  const getSessionUUID = localStorage.getItem("fs-uuid");

  if (!getSessionUUID || !getSessionUUID.length) {
    localStorage.setItem("fs-uuid", new Date().getTime());
  }
};
storeSessionUUID();

export const DASHBOARD_TOOLTIP = [
  { element: ".user", intro: "My Portal" },
  { element: ".lang-steps", intro: "Language DropDown" },
  { element: ".calculator-tooltip", intro: "Calculator" },
  { element: ".notification-tooltip", intro: "View Notification Here" },
  { element: ".cart-tooltip", intro: "Your Cart" },
  { element: ".message-tooltip", intro: "Chat with us" },
  { element: ".sidebar-steps", intro: "Discover Menu" },
  { element: ".search-voice ", intro: "Search diamonds directly" },
];

export const DETAIL_PAGE_TOOLTIP_STEPS = [
  { element: ".packetNo-tooltip", intro: "Diamond Packet-No" },
  { element: ".verify-tooltip", intro: "Verify Certificate" },
  { element: ".x-ray-tooltip", intro: "Share Diamond X-ray" },
  { element: ".whatsapp-tooltip", intro: "Share On Whatsapp" },
  { element: ".send-x-ray-tooltip", intro: "Copy Detail Link" },
  { element: ".download-zip-tooltip", intro: "Download Zip File" },
];

export const SEARCH_RESULT_STEPS = [
  { element: ".add-to-cart", intro: "Add to cart" },
  { element: ".add-watchList", intro: "Add to Tracklist" },
  { element: ".compare", intro: "Compare Diamonds" },
  { element: ".showSelect", intro: "Show Selected Diamonds" },
  { element: ".comment", intro: "Note" },
  { element: ".reminder", intro: "Reminder" },
  {
    element: ".export-tooltip",
    intro: "you can Print,Download and Email listed diamond excel",
  },
  { element: ".filter-tooltip", intro: "You can Apply Filter" },
  { element: ".setting-tooltip", intro: "You can Change Column Settings" },
];

export const DIAMOND_SEARCH_STEPS = [
  { element: ".all-shape", intro: "Select All Criteria" },
  { element: ".advance-tooltip", intro: "Advance Search" },
  { element: ".search-tooltip", intro: "Search Diamond" },
  { element: ".add-more-tooltip", intro: "Add more Search" },
  { element: ".save-tooltip", intro: "You Can Save Your Search" },
  {
    element: ".demand-tooltip",
    intro: "You Can add Your demand Diamond Search",
  },
  { element: ".match-pair-tooltip", intro: "Match Pair" },
  { element: ".match-pair-tooltip", intro: "Match Pair" },
  { element: ".create-excel-tooltip", intro: "You can Create Excel" },
  { element: ".import-excel-tooltip", intro: "You can Import Excel" },
  { element: ".sample-excel-tooltip", intro: "Download Sample" },
  { element: ".reset-filter-tooltip", intro: "Reset Your Filter" },
];

export const SIZEEX = [
  { name: "sizeEx", value: "3EX" },
  { name: "sizeEx", value: "2EX" },
  { name: "sizeEx", value: "2EX" },
  { name: "sizeEx", value: "NO BGM" },
];

export const MOBILE_VIEW_ACTION_BTN = [
  { intelMsg: "add-track", icon: "add-track" },
  { intelMsg: "compare", icon: "compare" },
  { intelMsg: "comment", icon: "comment" },
  { intelMsg: "showSelect", icon: "showSelect" },
  { intelMsg: "print", icon: "print" },
  { intelMsg: "downloadExcel", icon: "downloadExcel" },
];

export const uploadDiamond = {
  actions: { ReplaceAll: 2, AddUpdate: 1 },
  uploadStatus: {
    PENDING: 1,
    PROCESSING: 2,
    COMPLETED: 3,
    FAILED: 4,
    UPLOADING: 5,
    UPLOADING_COMPLETED: 6,
    UPLOADING_FAILED: 7,
    REPROCESSING: 8,
  },
  uploadType: { IMAGE: 1, CERTIFICATE: 2 },
};

export const gridColumns = {
  GRID_MY_DIAMOND_PAGE_LIST: "GRID_MY_DIAMOND_PAGE_LIST",
};

export const ACTIVITIES = {
  "sheet-upload-V1": "Diamond Sheet Upload",
  "sheet-upload-V2": "Diamond Sheet Reload",
  enquiry: "Enquiry Added",
  user: "User Added",
  track: "Diamond Added in Track",
  comment: "Note added on Diamond",
};

export const ACTIVITY_REDIRECTS = {
  "sheet-upload-V1": "/diamond-history",
  "sheet-upload-V2": "/diamond-history",
  enquiry: "/my-enquiry",
  user: "/user-details",
  track: "/my-tracked-diamonds",
};

export const TIME_ZONE_OPTIONS = [
  { name: "Mumbai", timeZone: "IST", offset: 330 },
  { name: "New York", timeZone: "PST", offset: -240 },
  { name: "Antwerp", timeZone: "CEST", offset: 120 },
  { name: "Tel Aviv", timeZone: "IDT", offset: 180 },
  { name: "Hong Kong", timeZone: "HKT", offset: 480 },
  { name: "Shanghai", timeZone: "CST", offset: 480.1 },
  { name: "Dubai", timeZone: "GST", offset: 240 },
  { name: "Tokyo", timeZone: "JST", offset: 540 },
];

export const EVENT_TYPES = {
  EVENT: "1",
  NEWS: "2",
  SHOW: "3",
  OTHER: "4",
  CSR: "5",
};

export const ADD_REQUEST = "/add-request";

export const DIAMOND_SEARCH = "/diamond-search";

export const MIME_TYPES = {
  "image/png": ["89504e47"],
  "image/jpeg": ["ffd8ffe0", "ffd8ffe1", "ffd8ffee", "ffd8ffdb"],
  "image/jpg": ["ffd8ffe0", "ffd8ffe1", "ffd8ffee", "ffd8ffdb"],
  "application/pdf": ["25504446"],
};

export const RESUME_MIME_TYPES = {
  "application/pdf": ["25504446"],
  "application/msword": ["D0CF11E0A1B11AE1", "504B030414000600"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    "504B030414000600",
    "504B0304",
  ],
};

export const DIAMOND_BID = {
  TYPE: { BLIND: 1, OPEN: 2, MULTIPLE: 3 },
  STATUS: { PENDING: 1, WIN: 2, LOSS: 3 },
};

export const DIAMOND_FEATURED_GROUP_TYPE = {
  PAIR: "pair",
  STONE: "stone",
  EXCLUSIVE: "exclusive",
  BEST: "best",
  STONE_OF_DAY: "stone_of_day",
};

export const DIAMOND_WEB_STATUS = {
  A: "A",
  M: "M",
  NA: "NA",
  UPCOMING: "U",
  BID: "B",
  SHOW: "S",
  OFFICE: "O",
};

export const DIAMOND_AVAILABLE_WEB_STATUS = ["A", "M"];

export const PURCHASED_STATUS = {
  PENDIND: 1,
  APPROVED: 2,
  REJECT: 3,
  RELEASE: 4,
};

export const DIAMOND_DISPLAY_TYPE = {
  BUSINESS: 1,
  NEW: 2,
  AVAILABLE: 3,
  INLAB: 4,
  EXCLUSIVE: 11,
  BEST: 12,
  STONE_OF_DAY: 13,
  LUCKY: 14,
};

export const PROJECT_SETTINGS_TYPE = {
  CM_CHARGES: 1,
  BANK_RATES: 2,
  SYNC_JOB_SETTINGS: 3,
  FM_CHARGES: 4,
  STOCK_STATUS: 5,
  MATCH_PAIR_COUNT: 6,
  CLIENT_EMAILS: 7,
  OFFICE_VIEW_ALLOWED: 8,
  NEW_ARRIVAL: 9,
  SP_ERROR_MESSAGES: 10,
  API_CALL_BLOCK_SETTING: 11,
  MATCH_PAIR_SIZE_GROUP: 12,
  QUOTE_VALIDATION: 13,
  BLOCK_VALIDATION: 14,
  CONFIRM_REQ_VALIDATION: 15,
  POP_UP_VALIDATION_MESSAGES: 16,
  DYNAMIC_FILENAME: 17,
  USER_BEHAVIOUR_EMAILS: 18,
  HOVER_TOOLTIP: 19,
  DIAMOND_STATUS_DESCRIPTION: 20,
  EXCLUDE_USER_EMAILS: 21,
  APPOINTMENT_EMAILS: 22,
  MEMO_EMAILS: 23,
  MEDIA_PATH: 24,
  VERSION_SETTING: 25,
  MEMO_SETTING_COUNTRY_WISE: 26,
  CART_ALLOW_STATUS: 27,
  WATCH_LIST_ALLOW_STATUS: 28,
  NOTE_ALLOW_STATUS: 29,
  BID_TIME_END_VALIDATION: 30,
  SLAB_CHARGES: 37,
};

export const FEEDBACK_QUESTIONS = {
  QUE_1: "How did you hear about us?",
  QUE_2: "Are you having trouble finding anything?",
  QUE_3: "Is there anything missing on this page?",
  QUE_4: "Is our pricing better?",
  QUE_5:
    "How likely are you to recommend our website to a friend or other company?",
  QUE_6: "How did your experience compare to your expectations?",
  QUE_7: "Which of our competitors did you consider before choosing us?",
  QUE_8: "What are the features you wish our website had?",
  QUE_9:
    "Compared to our competitors, is our product quality better, worse, or about the same?",
  QUE_10: "How easy was it to use our website? Did you have any problems?",
  QUE_11: "What is/are the most useful feature(s) on our website?",
  QUE_12: "How can we help you complete your purchase?",
  QUE_13: "If you could suggest changes about our website, what would it be?",
};

export const DOWNLOAD_OPTIONS = {
  Images: {
    label: "Image",
    key: "img",
    subLevel: [
      { label: "Real Image 1", key: "img" },
      { label: "Real Image 2", key: "b2cRealImage" },
      { label: "Plotting", key: "pltFile" },
      { label: "Heart & Arrow", key: "hAFile" },
      { label: "Asset Scope Image", key: "assetFile" },
      { label: "Arrow Image", key: "arrowFile" },
      { label: "Face up Image", key: "faceUp" },
      { label: "Dark Field Image", key: "darkFieldImage" },
      { label: "Ideal Scope", key: "idealWhiteImage" },
      { label: "Flouresence Image", key: "flsImage" },
    ],
  },
  Videos: {
    label: "Video",
    key: "videoFile",
    subLevel: [
      { label: "Video 1", key: "videoFile" },
      { label: "Video 2", key: "mp4Video" },
    ],
  },
  Certificates: {
    label: "Certificate",
    key: "isCert",
    subLevel: [
      { label: "Certificate", key: "isCert" },
      { label: "Type IIA", key: "type2" },
    ],
  },
  Roughs: {
    label: "Rough",
    key: "roughImage",
    subLevel: [
      { label: "Rough Scope", key: "roughImage" },
      { label: "Rough Video", key: "roughVideo" },
      { label: "3D Image", key: "planImg" },
    ],
  },
  Excels: { label: "Excel", key: "isExcel" },
};

export const SHARE_OPTIONS = {
  Images: {
    label: "Image",
    key: "img",
    subLevel: [
      { label: "Real Image 1", key: "img" },
      { label: "Real Image 2", key: "b2cRealImage" },
      { label: "Plotting", key: "pltFile" },
      { label: "Heart & Arrow", key: "hAFile" },
      { label: "Asset Scope Image", key: "assetFile" },
      { label: "Arrow Image", key: "arrowFile" },
      { label: "Face up Image", key: "faceUp" },
      { label: "Dark Field Image", key: "darkFieldImage" },
      { label: "Ideal Scope", key: "idealWhiteImage" },
      { label: "Flouresence Image", key: "flsImage" },
    ],
  },
  Videos: {
    label: "Video",
    key: "videoFile",
    subLevel: [
      { label: "Video 1", key: "videoFile" },
      { label: "Video 2", key: "mp4Video" },
    ],
  },
  Certificates: {
    label: "Certificate",
    key: "isCert",
    subLevel: [
      { label: "Certificate", key: "isCert" },
      { label: "Type IIA", key: "type2" },
    ],
  },
  Roughs: {
    label: "Rough",
    key: "roughImage",
    subLevel: [
      { label: "Rough Scope", key: "roughImage" },
      { label: "Rough Video", key: "roughVideo" },
      { label: "3D Image", key: "planImg" },
    ],
  },
  Excels: { label: "Excel", key: "isExcel" },
};

export const TERMS = [
  {
    id: "COD",
    name: "COD",
    charge: 0,
  },
  ...new Array(10).fill("Days").map((el, i) => {
    return { id: `${i + 1} ${el}`, name: `${i + 1} ${el}`, charge: i + 1 };
  }),
  { id: "14 Days", name: "14 Days", charge: 14 },
  { id: "15 Days", name: "15 Days", charge: 15 },
  { id: "20 Days", name: "20 Days", charge: 20 },
  { id: "21 Days", name: "21 Days", charge: 21 },
  { id: "30 Days", name: "30 Days", charge: 30 },
];
