/* eslint-disable */
import React from "react";
import Heading from "../common/Heading";

const PrivacyNotice = () => {
  return (
    <div>
      <div className="staticBoxMain">
        <div className="statiContentBox">
          <Heading
            title="Privacy Policy"
            className="staticMainTitle text-center mb-50"
          />
          <div className="staticContentWrapper">
            <div className="staticList">
              <p>
                We are Belgium Jewellery & Diamonds. We are committed to
                protecting and respecting your privacy. This policy sets out the
                basis on which any personal data we collect will be processed by
                us. Please read this privacy policy carefully to understand our
                views and practices and your rights regarding your personal
                data. By visiting our websites or by using our services your
                personal information will be processed as described in this
                policy. 
              </p>
              <ul>
                <li>What information do we collect from you?</li>
                <li>Social Media</li>
                <li>Cookies </li>
                <li>Why do we collect this information?</li>
                <li>How long do we keep hold of your information?</li>
                <li>Who might we share your information with?</li>
                <li>How is your data stored and kept secure? </li>
                <li>What are your rights?</li>
                <li>Other websites </li>
                <li>Changes to our Privacy Policy</li>
                <li>Contact Us</li>
              </ul>
            </div>
          </div>
          <div className="staticContentWrapper">
            <Heading
              title="What information do we collect from you?"
              className="popupInnerTitle"
            />
            <div className="staticList">
              <p>
                <b>We will collect and process the following data about you:</b>
              </p>
              <ul>
                <li>
                  Information that you provide by filling in forms on our sites.
                  This includes information provided at the time of registering
                  to use our site, subscribing to our service, posting material
                  or requesting further services including, for example, name,
                  home and/or business address, email address, telephone number,
                  demographic information such as age and/or other information
                  that may identify you as an individual, username and password
                  if you create an account on our site and such other
                  information as may be necessary for the purposes identified in
                  this Policy. We may also ask you for information when you
                  enter a competition or promotion sponsored by us, sign-up for
                  e-mail newsletters and when you report a problem with our
                  site.
                </li>
                <li>
                  If you contact us, whether through the “Contact Us” section of
                  our website, through Live Chat (where this service is
                  available to you), or via telephone, we will collect your
                  name, email address, telephone number and/or postal address
                  (depending on the nature of your query), your Belgium
                  Jewellery & Diamonds account details, as well as information
                  about the nature of your query. We will also keep a record of
                  your correspondence with us.
                </li>
                <li>
                  We may also ask you to complete surveys that we use for
                  research purposes, although you do not have to respond to
                  them.
                </li>
                <li>
                  Details of (if applicable) transactions and appointment
                  bookings you carry out through our site and of the fulfilment
                  of your orders.
                </li>
                <li className="sub-title">
                  <p>
                    <b>
                      Any other data you may provide to us in connection with
                      our services and/or our site, including but not limited
                      to:
                    </b>
                  </p>
                  <ul>
                    <li>
                      Information you provide when you compile wish lists or
                      other gift registries.
                    </li>
                    <li>
                      Information you provide when you use interactive areas of
                      our site.
                    </li>
                    <li>
                      Information you provide when using the Account features,
                      including the Preference Centre, Newsletter subscriptions
                      and Register My Diamond features.
                    </li>
                    <li>
                      Email addresses of your friends and other that you give us
                      in relation to a service.
                    </li>
                    <li>
                      Pictures, videos and messages which you submit to us
                      either via our social media sites (including by use of
                      hashtags associated with us) or our site.
                    </li>
                  </ul>
                </li>
                <li className="sub-title">
                  <p>
                    <b>
                      Information collected from your use of our website or
                      other digital services:
                    </b>
                  </p>
                  <ul>
                    <li>
                      Details of your visits to our site and information
                      generated in the course of the use our site (including the
                      timing, frequency and pattern of service use) including,
                      but not limited to, traffic data, location data, weblogs
                      and other communication data, whether this is required for
                      our own billing purposes or otherwise as set out in this
                      policy, and the resources that you access.
                    </li>
                    <li>
                      We will collect information about how you interact with
                      our adverts and newsletters, including whether you open or
                      click links in any correspondence. If you do not interact
                      with our communications for a period of time we will use
                      this information to assess whether to continue to send you
                      communications.
                    </li>
                    <li>
                      We may collect information about the device you use to
                      access our website or digital services, including where
                      available your IP address, operating system and browser
                      type, for system administration and to report aggregate
                      information to our advertisers. This is statistical data
                      about our users' browsing actions and patterns. We use
                      this information for internal system administration, to
                      help diagnose problems with our server, and to administer
                      our site. Such information may also be used to gather
                      broad demographic information, such as country of origin
                      and internet service provider. 
                    </li>
                  </ul>
                  <p>
                    Any or all of activities with regard to our site usage
                    information may be performed on our behalf by our service
                    providers (see the Service Providers section below). 
                  </p>
                </li>
              </ul>
            </div>
          </div>
          {/* gimme break */}
          <div className="staticContentWrapper">
            <Heading title="Social Media" className="popupInnerTitle" />
            <div className="staticList">
              <p>
                We may also use personal data we obtain from social media (for
                example, Facebook, Instagram, Twitter, YouTube and WeChat to
                display on our site, our social media pages and for our (and our
                businesses’) research and analytics purposes. This is usually
                personal data that you submit to us (e.g. on our site or our
                social media pages) or you bring to our attention using hashtags
                or tagging relating to us, our business, our industry and our
                interests.
              </p>
            </div>
          </div>
          {/* gimme break */}
          <div className="staticContentWrapper">
            <Heading title="Cookies" className="popupInnerTitle" />
            <div className="staticList">
              <p>
                We use cookies and similar technologies as set out in
                our Cookies Policy.
              </p>
              <h3>Why do we collect this information?</h3>
              <p>
                We process your personal information for the following reasons:
              </p>
              <p>
                <b>
                  <strong> To fulfil our promise to you</strong> where we agree
                  to provide you with our products and services in order to:
                </b>
              </p>
              <ul>
                <li>
                  Process information at your request to take steps to enter
                  into a contract for sale or for services;
                </li>
                <li>
                  Provide you with our products and services, including the
                  Register My Diamond service;
                </li>
                <li>Process payments;</li>
                <li>Make deliveries;</li>
                <li>Maintain business and service continuity;</li>
                <li>
                  Send service communications so that you receive a full and
                  functional service and so we can perform our obligations to
                  you. These may be sent by email or post or, if the
                  circumstances require it, we may contact you by phone. These
                  will include notifications about changes to our service; and
                </li>
                <li>
                  Record information to facilitate your rights under guarantee.
                </li>
              </ul>
              <p>
                <b>
                  On the basis of your <strong>consent</strong>:
                </b>
              </p>
              <ul>
                <li>
                  Where we rely on your consent for processing this will be
                  brought to your attention when the information is collected
                  from you or be a result of a request by you, such as you
                  choosing to share your wish lists or gift registry;
                </li>
                <li>
                  To display your messages/captions, photos and/or videos (which
                  may include 'likes', comments or other reactions to such
                  messages/captions, photos and/or videos) on our site
                </li>
                <li>
                  We will contact you with direct marketing communications if
                  you consent to us doing so and you have the right to withdraw
                  consent at any time. See the what are your rights? Section
                  below for more information.
                </li>
              </ul>

              <p>
                <b>
                  To provide you with the best service and improve and grow our
                  business (our legitimate interests) we will process
                  information in order to:
                </b>
              </p>
              <ul>
                <li>
                  Respond to any queries and complaints that you may have; 
                </li>
                <li>Provide you with a personalised service;</li>
                <li>
                  Respond to your queries and to provide guidance about our
                  products and stockists;
                </li>
                <li>Improve our products and services;</li>
                <li>Keep our site and systems safe and secure;</li>
                <li>Understand our customer base and purchasing trends;</li>
                <li>Tailor advertising to you;</li>
                <li>
                  Create custom audiences on social media sites based on your
                  information and engagement with us so we can target our
                  advertising to similar audiences;
                </li>
                <li>
                  Cefend against or exercise legal claims and investigate
                  complaints; and
                </li>
                <li> Understand the effectiveness of our marketing.</li>
              </ul>
              <p>
                We will also carry out analytics to improve our products and
                services as set out above.
              </p>
              <p>
                You have the right to object to processing carried out for our
                legitimate interests.
              </p>
              <p>
                See the <strong> what are your rights?</strong> Section below
                for more information.
              </p>
              <ul>
                <p>
                  <b>To comply with legal requirements relating to:</b>
                </p>
                <li>The provision of products and services;</li>
                <li>Data protection;</li>
                <li>Health and safety;</li>
                <li>Anti-money laundering;</li>
                <li>Fraud investigations;</li>
                <li>Assisting law enforcement; and</li>
                <li>
                  Any other legal obligations placed on us from time to time
                </li>
              </ul>
              <p>
                <b>How long do we keep hold of your information?</b>
              </p>
              <ul>
                <li>
                  We will keep information about you for a maximum of 3 years
                  after the end of our relationship with you unless obligations
                  to our regulators require otherwise or we are required remove
                  such data from our records.
                </li>
              </ul>
              <h3>Who might we share your information with?</h3>
              <p>
                <b>
                  For the purposes set out in the ‘Why do we collect this
                  information?’ section above, we will share your personal
                  information with:
                </b>
              </p>
              <ul>
                <li>
                  The following categories of third parties, some of whom we
                  appoint to provide services, including:
                  <ul>
                    <li>
                      Business partners, subsidiaries, suppliers and
                      sub-contractors for the performance of any contract we
                      enter into with you.
                    </li>
                    <li>
                      Analytics and search engine providers that assist us in
                      the improvement and optimisation of our site.
                    </li>
                    <li>
                      Customer survey providers in order to receive feedback and
                      improve our services.
                    </li>
                  </ul>
                </li>
                <li>Any member of our group of companies</li>
                <li>
                  Marketing agencies, media agencies and analytics providers in
                  the form of aggregate statistics regarding user behaviour as a
                  measure of interest in, and use of, our site and marketing
                  e-mails in the form of aggregate data, such as overall
                  patterns or demographic reports. These third parties will not
                  be able to relate this data to identifiable individuals.
                </li>
              </ul>
              <p>
                <b>
                  Additionally, we will disclose your personal information to
                  the relevant third party:
                </b>
              </p>
              <ul>
                <li>
                  In the event that we sell or buy any business or assets, in
                  which case we will disclose your personal data to the
                  prospective seller or buyer of such business or assets.
                </li>
                <li>
                  If we are acquired by a third party, in which case personal
                  data held by us about our customers will be one of the
                  transferred assets.
                </li>
                <li>
                  If we are under a duty to disclose or share your personal data
                  in order to comply with any legal obligation, or in order to
                  enforce or apply our terms of use and other agreements; or to
                  protect the rights, property, or safety of our customers, our
                  regulator, or others. This may in some circumstances include
                  exchanging information with other companies and organisations
                  for the purposes of fraud protection and prevention of money
                  laundering and credit risk reduction.
                </li>
              </ul>
              <h3>How your data is stored and kept secure?</h3>
              <p>
                At Belgium Jewellery & Diamonds, we take your safety and
                security very seriously and we are committed to protecting your
                personal and financial information. All information kept by us
                is stored on our secure servers. Where we have given you (or
                where you have chosen) a password that enables you to access
                certain parts of our service, you are responsible for keeping
                this password confidential. We ask you not to share a password
                with anyone and we recommend that you change your password every
                three months. While We have implemented reasonable technical and
                organisational precautions to protect the security and integrity
                of personal data provided to Our Site, due to the inherent
                nature of the internet as an open global communications vehicle,
                We cannot guarantee that information, during transmission
                through the internet or while stored on Our system or otherwise
                in Our care, will be absolutely safe from intrusion by others,
                such as hackers. We do however maintain physical, electronic and
                procedural safeguards to protect your Personal Data.
              </p>
              <p>
                We may transfer and store your data outside the European
                Economic Area ("EEA") or your country. We will only do so if
                adequate protection measures are in place in compliance with
                data protection legislation. It may also be processed by staff
                operating outside the EEA or your country who work for us or for
                one of our service providers for the purposes outlined in this
                Policy. Such staff may be engaged in, among other things, the
                fulfilment of your order, the processing of your payment details
                and the provision of support services.
              </p>
              <p>
                We have put in place European Commission approved standard
                contractual clauses to protect this data. You have a right to
                ask us for a copy of these clauses (by contacting us as set out
                below).
              </p>
              <p>
                We also transfer information to suppliers who are outside the
                EEA and use standard contractual clauses or other authorised
                transfer methods, such as relying on a supplier's Privacy Shield
                certification. For example, we use Salesforce Marketing Cloud to
                process data for customer relationship management purposes.
                Their servers are hosted in the US and they are certified under
                Privacy Shield. You have a right to ask us for a copy of the
                standard contractual clauses or a copy of the other methods used
                (by contacting us as set out below).
              </p>
              <p>
                Once we have received your information, we will use strict
                procedures and security features to try to prevent unauthorised
                access.
              </p>
              <h3>What are your rights?</h3>
              <p>
                Where processing of your personal data is based on consent, you
                can withdraw that consent at any time.
              </p>
              <p>
                You have the following rights.  You can exercise these rights at
                any time by contacting us at support@Belgium Jewellery &
                Diamonds.com or Belgium Jewellery & Diamonds Customer Services,
                20 Carlton House Terrace, London, SW1Y 5AN.  You have the right:
              </p>
              <ul>
                <li>
                  To ask us not to process your personal data for marketing
                  purposes. We will inform you (before collecting your data) if
                  we intend to use your data for such purposes or if we intend
                  to disclose your information to any third party for such
                  purposes;
                </li>
                <li>
                  To ask us not to process your personal data where it is
                  processed on the basis of legitimate interests provided that
                  there are no compelling reasons for that processing;
                </li>
                <li>
                  To ask us not to process your personal data for scientific or
                  historical research purposes, where relevant, unless the
                  processing is necessary in the public interest.
                </li>
                <li>
                  To request from us access to personal information held about
                  you;
                </li>
                <li>
                  To ask for the information we hold about you to be rectified
                  if it is inaccurate or incomplete;
                </li>
                <li>
                  To ask for data to be erased provided that the personal data
                  is no longer necessary for the purposes for which it was
                  collected, you withdraw consent (if the legal basis for
                  processing is consent), you exercise your right to object, set
                  out below, and there are no overriding legitimate ground for
                  processing, the data is unlawfully processed, the data needs
                  to be erased to comply with a legal obligation or the data is
                  children’s data and was collected in relation to an offer of
                  information society services;
                </li>
                <li>
                  To ask for the processing of that information to be restricted
                  if the accuracy of that data is contested, the processing is
                  unlawful, the personal data is no longer necessary for the
                  purposes for which it was collected or you exercise your right
                  to object (pending verification of whether there are
                  legitimate grounds for processing);
                </li>
                <li>
                  To ask for data portability if the processing is carried out
                  by automated means and the legal basis for processing is
                  consent or contract.
                </li>
              </ul>
              <p>
                Should you have any issues, concerns or problems in relation to
                your data, or wish to notify us of data which is inaccurate,
                please let us know by contacting us using the contact details
                above.
              </p>
              <h3>Other websites</h3>
              <p>
                Our site may, from time to time, contain links to and from the
                websites of our partner networks, advertisers and affiliates. If
                you follow a link to any of these websites, please note that
                these websites have their own privacy policies and that we do
                not accept any responsibility or liability for these policies or
                content contained on those sites. Please check these policies
                before you submit any personal data to these websites.
              </p>
              <h3>Changes to our Privacy Policy</h3>
              <p>
                This policy may be updated from time to time. Please check back
                frequently to see any updates or changes to our privacy policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyNotice;
