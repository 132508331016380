/* eslint-disable */
import React from "react";
const APILoader = () => (
  <div
    id="loader"
    className={`APILoader main-loader --fixed`}
    style={{
      width: "100%",
    }}
  >
    <div id="loader-inner">
      <div>
        <img src={require("../../assets/img/loader.svg")} alt="Belgium" />
        <h3 className="showProgress display-none">
          <span className="after"></span>
        </h3>
      </div>
    </div>
  </div>
);
export default APILoader;
