/* eslint-disable */
import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import MagnifierImage from "./MagnifierImage";
import Slider from "react-slick";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import IntlMessages from "../../util/IntlMessages";
import Image from "components/common/Image";
const DiamondDetailLeft = ({ getMediaUrl, ...props }) => {
  const [activeTab, setActiveTab] = useState(
    props.shared.find((c) => c === "isVideo=true")
      ? "1"
      : props.shared.find((c) => c === "isImg=true")
      ? "2"
      : props.shared.find((c) => c === "isHa=true")
      ? "3"
      : props.shared.find((c) => c === "isCert=true")
      ? "4"
      : props.shared.find((c) => c === "isArrow=true")
      ? "5"
      : props.shared.find((c) => c === "isAsset=true")
      ? "6"
      : props.item.videoFile
      ? "1"
      : props.item.img
      ? "2"
      : props.item.hAFile
      ? "3"
      : props.item.certFile
      ? "4"
      : props.item.arrowFile
      ? "5"
      : props.item.assetFile
      ? "6"
      : "",
  );
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  if (!activeTab) return <></>;

  return (
    <>
      {localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
      props.shared.length > 1 ? (
        <Nav tabs className="customerMaintab diamondDetailTopTab">
          {props.item.videoFile &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
              props.shared.find((c) => c === "isVideo=true")) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <IntlMessages id="app.Video" />
                </NavLink>
              </NavItem>
            )}
          {props.item.img &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
              props.shared.find((c) => c === "isImg=true")) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <IntlMessages id="app.Image" />
                </NavLink>
              </NavItem>
            )}
          {props.item.hAFile &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
              props.shared.find((c) => c === "isHa=true")) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  H&A
                </NavLink>
              </NavItem>
            )}
          {props.item.certFile &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
              props.shared.find((c) => c === "isCert=true")) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  <IntlMessages id="app.Certificate" />
                </NavLink>
              </NavItem>
            )}
          {props.item.arrowFile &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
              props.shared.find((c) => c === "isArrow=true")) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "5" })}
                  onClick={() => {
                    toggle("5");
                  }}
                >
                  <IntlMessages id="app.Arrow" />
                </NavLink>
              </NavItem>
            )}
          {props.item.assetFile &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
              props.shared.find((c) => c === "isAsset=true")) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "6" })}
                  onClick={() => {
                    toggle("6");
                  }}
                >
                  <IntlMessages id="app.Asset" />
                </NavLink>
              </NavItem>
            )}
        </Nav>
      ) : (
        ""
      )}
      {localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
      props.shared.length > 1 ? (
        <TabContent activeTab={activeTab} style={props.style}>
          {props.item.videoFile &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
              props.shared.find((c) => c === "isVideo=true")) && (
              <TabPane tabId="1">
                <Slider
                  // asNavFor={nav2}
                  infinite={false}
                  arrows={false}
                  // ref={(slider1) => setNav1(slider1)}
                >
                  <div className="diamondDetailImageBox htmlViewImage">
                    <iframe src={getMediaUrl("videoFile")} title="vFile" />
                  </div>
                </Slider>
                {/* <Slider
            asNavFor={nav1}
            ref={(slider2) => setNav2(slider2)}
            slidesToShow={7}
            swipeToSlide={true}
            centerMode={true}
            focusOnSelect={true}
            infinite={false}
            className="smallSliderBlock"
          >
            <div>
                  <div>
                  <div className="smallSliderImage">
                      <img src={require("../../assets/svg/DiamondDetail/video.svg")} alt=""/>
                  </div>
                <span className="smallimgTitle">Html</span>
                </div>
                <div>
                  <div className="smallSliderImage">
                      <img src={require("../../assets/svg/DiamondDetail/video.svg")} alt=""/>
                  </div>
                <span className="smallimgTitle">Impurities</span>
                </div>
                <div>
                  <div className="smallSliderImage">
                      <img src={require("../../assets/svg/DiamondDetail/video.svg")} alt=""/>
                  </div>
                <span className="smallimgTitle">Html</span>
                </div>
                <div>
                  <div className="smallSliderImage">
                      <img src={require("../../assets/svg/DiamondDetail/video.svg")} alt=""/>
                  </div>
                <span className="smallimgTitle">Html</span>
                </div>
            </div>
          </Slider> */}
              </TabPane>
            )}
          {props.item.img &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
              props.shared.find((c) => c === "isImg=true")) && (
              <TabPane tabId="2">
                <Slider
                  //  asNavFor={nav4} ref={slider3 => setNav3(slider3)}
                  arrows={false}
                >
                  <div className="diamondDetailImageBox">
                    <MagnifierImage image={getMediaUrl("img")} />
                  </div>
                  {/* <div className='diamondDetailImageBox'>
                            <MagnifierImage image={`${MEDIA_URL}/AssetScopeImages/${props.pktNo}.jpg`} />
                        </div>
                        <div className='diamondDetailImageBox'>
                            <MagnifierImage image={`${MEDIA_URL}/Dark_Field_White_BG/${props.pktNo}.jpg`} />
                        </div>
                        <div className='diamondDetailImageBox'>
                            <MagnifierImage image={`${MEDIA_URL}/FlsImages/${props.pktNo}.jpg`} />
                        </div>
                        <div className='diamondDetailImageBox'>
                            <MagnifierImage image={`${MEDIA_URL}/IDEAL_White_BG/${props.pktNo}.jpg`} />
                        </div> */}
                </Slider>
                {/* <Slider
                        asNavFor={nav3}
                        ref={slider4 => setNav4(slider4)}
                        slidesToShow={7}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        infinite={false}
                        className='smallSliderBlock smallSliderBlockimage'
                    >
                        <div className='smallSliderImage'>
                            <img src={`${MEDIA_URL}/RealImages/${props.pktNo}.jpg`} alt='' />
                        </div>
                        <div className='smallSliderImage'>
                            <img src={`${MEDIA_URL}/AssetScopeImages/${props.pktNo}.jpg`} alt='' />
                        </div>
                        <div className='smallSliderImage'>
                            <img src={`${MEDIA_URL}/Dark_Field_White_BG/${props.pktNo}.jpg`} alt='' />
                        </div>
                        <div className='smallSliderImage'>
                            <img src={`${MEDIA_URL}/FlsImages/${props.pktNo}.jpg`} alt='' />
                        </div>
                        <div className='smallSliderImage'>
                            <img src={`${MEDIA_URL}/IDEAL_White_BG/${props.pktNo}.jpg`} alt='' />
                        </div>
                    </Slider> */}
              </TabPane>
            )}
          {props.item.hAFile &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
              props.shared.find((c) => c === "isHa=true")) && (
              <TabPane tabId="3">
                <Slider
                  // asNavFor={nav6} infinite={false}
                  arrows={false}
                  //  ref={slider5 => setNav5(slider5)}
                >
                  <div className="diamondDetailImageBox">
                    <MagnifierImage image={getMediaUrl("hAFile")} />
                  </div>
                  {/* <div className='diamondDetailImageBox'>
                            <MagnifierImage image={`${MEDIA_URL}/ArrowImages/${props.pktNo}.jpg`} />
                        </div> */}
                </Slider>
                {/* <Slider
                        asNavFor={nav5}
                        ref={slider6 => setNav6(slider6)}
                        slidesToShow={7}
                        swipeToSlide={true}
                        centerMode={true}
                        focusOnSelect={true}
                        infinite={false}
                        className='smallSliderBlock'
                    >
                        <div className='smallSliderImage'>
                            <img src={`${MEDIA_URL}/HeartImages/${props.pktNo}.jpg`} alt='' />
                        </div>
                        <div className='smallSliderImage'>
                            <img src={`${MEDIA_URL}/ArrowImages/${props.pktNo}.jpg`} alt='' />
                        </div>
                    </Slider> */}
              </TabPane>
            )}
          {props.item.certFile &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
              props.shared.find((c) => c === "isCert=true")) && (
              <TabPane tabId="4">
                <div className="diamondDetailImageBox">
                  <Image src={getMediaUrl("certFile")} alt="" />
                </div>
              </TabPane>
            )}
          {props.item.arrowFile &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
              props.shared.find((c) => c === "isArrow=true")) && (
              <TabPane tabId="5">
                <div className="diamondDetailImageBox">
                  <MagnifierImage image={getMediaUrl("arrowFile")} />
                </div>
              </TabPane>
            )}
          {props.item.assetFile &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
              props.shared.find((c) => c === "isAsset=true")) && (
              <TabPane tabId="6">
                <div className="diamondDetailImageBox">
                  <MagnifierImage image={getMediaUrl("assetFile")} />
                </div>
              </TabPane>
            )}
        </TabContent>
      ) : (
        ""
      )}
    </>
  );
};

export default DiamondDetailLeft;
