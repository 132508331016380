/* eslint-disable */
import React from "react";
import "./uploadImageSquare.less";

const UploadImageSquare = (props) => {
  return (
    <div
      // style={{ marginLeft: !props.right && "20px", marginRight: props.right && '150px' }}
      className={`uploadeImageBlock ${props.leftAlign ? "leftAlign" : ''}`}
    >
      {/* <div className="uploadeBlock">
        {props.file ? (
          props.file
            .split("/")
            .slice(-1)[0]
            .match(/.(pdf)$/i) ? (
            <img
              src={require("../../common/UploadImageSquare/pdf.svg")}
              alt=""
            />
          ) : (
            <img src={`${BASE_URL_ATTACH}/${props.file}`} alt="" />
          )
        ) : (
          <img src={require("../../../assets/svg/plus-thin.svg")} alt="" />
        )}
      </div> */}
      <div className="uploadTextButton">
        <input
          disabled={props.disabled}
          type="file"
          onChange={props.onUpload}
        />
        <button className="commonButton">Upload Document</button>
      </div>
      <span className="title">
        {props.title ? props.title : <>Upload ID Proof</>}
      </span>
    </div>
  );
};

export default UploadImageSquare;
