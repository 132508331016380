/* eslint-disable */
import React, { Component } from "react";
import Heading from "../../common/Heading";
import AddressItem from "./AdderssItem";
import { AddressData } from "../../../constants/Address";

class ManageAddressList extends Component {
  render() {
    return (
      <>
        <div className="editProfilePage">
          {/* <div className="d-flex justify-content-between align-items-center userManageTop">
                <Heading title="Address" className="p-0 m-0"/>
                <button className="commonButton" onClick={this.props.onClick}>Add Address</button>
            </div> */}
          <div className="addressList">
            <div className="addressBlock">
              {AddressData.map((d, index) => (
                <AddressItem
                  key={index}
                  onClick={this.props.onClick}
                  name={d.name}
                  address1={d.address1}
                  address2={d.address2}
                  city={d.city}
                  state={d.state}
                  zipcode={d.zipcode}
                  country={d.country}
                />
              ))}
              {/* <div className="addressItem noAddressFound">
                        <div className="notFoundTitle">No Data Found</div>
                    </div> */}
            </div>
          </div>
          <div className="botoomStickyBar diamondListingAction justify-content-center">
            <button className="bottomStickyButton" onClick={this.props.onClick}>
              <span>Add Address</span>
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default ManageAddressList;
