/* eslint-disable */
import React, { memo, useCallback } from "react";
import {
  floatkeys,
  roundkeys,
  FILTER_COLUMNS,
  DISPLAY_TOTAL,
} from "./TableBack";
import { formatNumber } from "../common/DiamondListing/SelectStone";
import OpenNotification from "../common/CommonButton/OpenNotification";
import UtilService from "../../services/util";
import { LIMIT } from "./TableBack";
import {
  BASE_DOWNLOAD_URL,
  BASE_URL,
  trackTypeObj,
  LOCAL_STORAGE_VAR,
  DIAMOND_WEB_STATUS,
  DIAMOND_BID,
  VIEW_DNA_URL,
  DOWNLOAD_URL,
  PRINT_URL,
} from "../../constants/Common";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import {
  SEARCH_DIAMOND_LIST,
  FETCH_CONFIRM_STONE,
  FETCH_DIAMOND_TRACK,
  CREATE_TRACK,
  UPDATE_TRACK,
  PRINT_PDF,
  EXPORT_EXCEL,
  CONFIRM_STONE,
  DELETE_TRACK,
  CREATE_BID,
  SEND_EMAIL_XRAY,
  FEATURE_STONE,
  CREATE_MATCH_PAIR,
  BID_PAGINATE,
  BID_DELETE,
  UPDATE_BID,
  SEARCH_LIST,
  DOWNLOAD_ZIP,
  DOWNLOAD_FILE,
  NOTE_UPSERT,
  FETCH_NOTES,
  NOTE_DELETE,
  PROJECT_SETTING,
  FETCH_OFFICE_DIAMOND,
  DELETE_OFFICE_DIAMOND,
  QUOTE_DAY_LIST,
  BLOCK_MASTERS,
  ADD_HOLD,
  GET_BLOCK_DIAMONDS,
  BLOCK_REALESE,
  REMOVE_STONE_RAPNET,
  PROJECT_SETTING_PAGINATE,
  GET_TRENDING_DIAMONDS,
  CREATE_SEENSTONE,
  COMING_SOON_DIAMONDS,
  BLOCK_MASTERS_TYPE,
} from "../../constants/apiConstant";
import { Tooltip } from "antd";
import { findIndex, isArray } from "lodash";
import reuploadedPNG from "assets/img/fs-reuploaded.png";
import Image from "components/common/Image";
// import { ArrowDownOutlined, ArrowUpOutlined, MinusOutlined } from "@ant-design/icons";
const _ = require("lodash");

// const FLAGICON = {
//     INDIA: require('../../assets/img/Flags/india.svg'),
//     HONGKONG: require('../../assets/img/Flags/hongkong.png'),
//     NEWYORK: require('../../assets/img/Flags/usa.jpg'),
//     DUBAI: require('../../assets/img/Flags/dubai.png'),
//     BELGIUM: require('../../assets/img/Flags/belgium.png')
// }
const LOCATION = {
  india: "IND",
  beligium: "BEL",
  newyork: "NY",
  hongkong: "HK",
  dubai: "DXB",
};
const NOTUPCOMING = [];

export const LISTINGPAGES = {
  LIST: "diamond-list",
  UPCOMING: "upcoming",
  COMING_SOON: "coming-soon",
  DASHBOARD: "dashboard",
  SEARCH: "diamond-search",
  FANCY_SEARCH: "fancy-search",
  CART: "cart",
  WATCHLIST: "watchlist",
  REMINDER: "reminder",
  CONFIRM: "confirm-list",
  BID: "new-arrival-bid-it",
  QUOTE: "quote-list",
  STONE_OF_DAY: "stone-of-the-day",
  NEWARR: "new-arrivals",
  MATCHPAIR: "match-pair",
  MYBID: "my-bid",
  EXCLUSIVE: "belgium-exclusive",
  NOTE: "notes",
  OFFICE: "office-list",
  DETAIL: "diamond-detail",
  DETAILS: "diamond-details",
  QUOTEDAY: "quote-day",
  SUGGESTED_STOCK: "suggested-stock",
  TRENDING: "trending",
  HOLD: "hold",
  MEMO: "memo",
  FM_LIST: "fm-diamond-list",
  ACCOUNTING_LIST: "accounting",
};

export const TITLE = {
  [LISTINGPAGES.LIST]: "Diamond List",
  [LISTINGPAGES.UPCOMING]: "Upcoming",
  BID: "New Arrival 'Just Bid It'",
  [LISTINGPAGES.STONE_OF_DAY]: "Stone Of The Day",
  [LISTINGPAGES.NEWARR]: "New Arrivals",
  [LISTINGPAGES.MATCHPAIR]: "Match Pair",
  [LISTINGPAGES.MYBID]: "My Bid",
  [LISTINGPAGES.EXCLUSIVE]: "Exclusive Diamonds",
  [LISTINGPAGES.CART]: "My Cart",
  [LISTINGPAGES.WATCHLIST]: "My Watchlist",
  [LISTINGPAGES.REMINDER]: "My Reminder",
  [LISTINGPAGES.CONFIRM]: "My Confirm List",
  [LISTINGPAGES.QUOTE]: "My Quote List",
  [LISTINGPAGES.NOTE]: "My Notes",
  [LISTINGPAGES.OFFICE]: "My Office List",
  [LISTINGPAGES.QUOTEDAY]: "Quote Day",
  [LISTINGPAGES.SUGGESTED_STOCK]: "Suggested Stock",
  [LISTINGPAGES.HOLD]: "My Hold Diamonds",
  [LISTINGPAGES.MEMO]: "My Memo Diamonds",
  [LISTINGPAGES.FM_LIST]: "Diamond List",
};

export function getPath() {
  return _.last(window.location.pathname.split("/"));
}

const ColumnFilter = memo((props) => {
  const {
    column: { filterValue = [], setFilter, preFilteredRows, id, isSorted },
  } = props;
  const availableOptions = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (!filterValue.includes(row.values[id])) {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows, filterValue]);

  const toggleFilter = useCallback(
    (id) => {
      const selectedFilters = [...filterValue];
      if (selectedFilters.includes(id)) {
        selectedFilters.splice(
          selectedFilters.findIndex((x) => x === id),
          1,
        );
      } else {
        selectedFilters.push(id);
      }
      setFilter(selectedFilters);
    },
    [setFilter, filterValue],
  );

  return (
    <div
      className="llfilterMenuDropdown"
      style={isSorted ? { borderTop: "1px solid #e9ebf0" } : {}}
    >
      {filterValue && filterValue.length > 0 && (
        <ul className="llfilterSelect">
          <div className="llSortingTitle">Selected Filter</div>
          {filterValue.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`}>
                {x}
                <div className="closeFilter" onClick={() => toggleFilter(x)}>
                  <img
                    src={require("../../assets/svg/DiamondList/close-black.svg")}
                    alt="icon"
                  />
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {!!availableOptions.length ? (
        <ul>
          <div className="llSortingTitle">Select Filter</div>
          {availableOptions.map((x) => {
            return (
              <li
                key={`columnFilter_${id}${x}`}
                onClick={() => toggleFilter(x)}
              >
                {x}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
});

// Define a custom filter filter function!
function filterArrayIncludes(rows, id, filterValue = []) {
  if (filterValue.length === 0) return rows;
  return rows.filter((row) => filterValue.includes(row.values[id]));
}
filterArrayIncludes.autoRemove = (val) => !val || !val.length;
export function isMobile() {
  return window.innerWidth <= 767 ? true : false;
}
export function isIpade() {
  return window.innerWidth <= 991 ? true : false;
}

export function getNewStatus(row) {
  if (!row.fpDt || row.crt > 2.999) return false;
  let diff = moment() - moment(row.fpDt);
  return diff > 7 * 24 * 60 * 60 * 1000 || row.wSts === "B" || row.wSts === "U"
    ? false
    : true;
}

export function getColumn(state) {
  const hoverMsg =
    "This diamonds is either Re-certified, Re-graded or Re-Uploaded";
  let columnList = null;
  let Columns = [];
  let master = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
  if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-column`) || columnList) {
    let getColumnList = columnList
      ? columnList
      : JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-column`));

    Columns.push({
      Header: "Sr No.",
      accessor: "srNo",
      id: "srNo",
      Cell: (data) => {
        const clientPagination =
          document.getElementsByClassName("clientPagination")[0];
        const activePage =
          clientPagination &&
          clientPagination.getElementsByClassName("active")[0];
        const currentPage = activePage ? +activePage.textContent : 1;
        let srNo =
          currentPage > 1
            ? data.row.index +
            1 +
            (currentPage - 1) * ((state && state?.limit) || LIMIT)
            : data.row.index + 1;
        return srNo;
      },
    });
    getColumnList.map((dataa) => {
      let data = {};
      Object.keys(dataa).map((x) => (data[x] = dataa[x]));

      if (data.isActive && data.field) {
        data.field = data.field.trim();
        data.title = data.title ? data.title.trim() : data.title;
        data.key = data.key ? data.key.trim() : data.key;
        if (_.find(Columns, { id: data.field })) return;
        if (
          data.field === "reminderDate" &&
          getPath() !== LISTINGPAGES.REMINDER
        )
          return false;
        if (data.field === "remarks" && getPath() !== LISTINGPAGES.NOTE)
          return false;
        if (data.field === "remarks" && getPath() === LISTINGPAGES.NOTE)
          data.title = "Notes";
        if (
          NOTUPCOMING.includes(data.field) &&
          getPath() === LISTINGPAGES.UPCOMING
        )
          return false;
        // Open for upcomming 
        // if (
        //   (data.field === "back" || data.field === "amt") &&
        //   getPath() === LISTINGPAGES.UPCOMING
        // )
        //   return false;
        // if (data.field === "ctPr" && getPath() === LISTINGPAGES.UPCOMING)
        //   return false;

        const canFilter = FILTER_COLUMNS.includes(data.field);
        if (data.field === "back" && getPath() === LISTINGPAGES.WATCHLIST)
          data.title = "Today's Disc%";

        Columns.push({
          Header: data.title,
          accessor: data.field,
          id: data.field,
          desc: data.desc,
          link: data.link,
          sort: data.sort,
          Cell: ({ row, cell }) => {
            // className certificateNo redColor greenColor
            if (data.field === "dna") cell.value = row.original.vStnId;
            const newInvent =
              data.field === "vStnId" &&
              getPath() !== LISTINGPAGES.BID &&
              getNewStatus(row.original);

            const fullName =
              cell.value &&
                master &&
                data.key &&
                master[data.key] &&
                _.find(master[data.key], { code: cell.value }) &&
                _.find(master[data.key], { code: cell.value }).description
                ? _.find(master[data.key], { code: cell.value }).description
                : cell.value &&
                  master &&
                  data.key &&
                  master[data.key] &&
                  _.find(master[data.key], { name: cell.value }) &&
                  _.find(master[data.key], { name: cell.value }).description
                  ? _.find(master[data.key], { name: cell.value }).description
                  : null;
            return (
              <div
                className={
                  data.field === "dna" // data.field === 'stoneId' || data.field === 'vStnId'
                    ? "underline"
                    : floatkeys.includes(data.field) ||
                      roundkeys.includes(data.field)
                      ? ""
                      : null
                }
              >
                {data.isFlag && cell.value ? (
                  typeof data.sLabel === "string" ? (
                    data.sLabel
                  ) : (
                    "Yes"
                  )
                ) : data.isFlag && !cell.value ? (
                  typeof data.nLabel === "string" ? (
                    data.nLabel
                  ) : (
                    "-"
                  )
                ) : cell.value === true ? (
                  "Y"
                ) : !cell.value ? (
                  "-"
                ) : data.field === "vStnId" ? (
                  <div
                    className={`position-relative ${cell?.row.original.isReUploaded
                      ? "packet-continer-no-pad"
                      : "packet-continer"
                      }`}
                  >
                    <span className="line-height">
                      {cell.value}{" "}
                      {cell?.row.original.isReUploaded && (
                        <Tooltip title={hoverMsg}>
                          <Image src={reuploadedPNG} height="20" width="20" />
                        </Tooltip>
                      )}
                    </span>

                    {newInvent && <span className="newdiamond">New</span>}
                  </div>
                ) : fullName ? (
                  <Tooltip
                    placement="bottom"
                    key={cell.row.id + cell.column.id}
                    title={fullName}
                  >
                    {cell.value}
                  </Tooltip>
                ) : data.field === "reminderDate" ? (
                  moment(row.original.reminderDate).format("ll")
                ) : row.original.isFcCol && data.field === "colNm" ? (
                  <div className="normalTableDropdown">
                    <span className="tableDropdownTitle">
                      {row.original.fcColNm}
                    </span>
                    <div className="tableDropdown">
                      {[
                        row.original.intenNm,
                        row.original.ovrtnNm,
                        row.original.fcColNm,
                      ]
                        .filter(function (el) {
                          return el && el;
                        })
                        .map((d) => {
                          return <span>{d}</span>;
                        })}
                    </div>
                  </div>
                ) : data.field === "locNm" ? (
                  <>
                    {/* <Tooltip title={cell.value.toUpperCase()}>
                                            {FLAGICON[cell.value.toUpperCase()] ? (
                                                <img
                                                    width='30px'
                                                    src={FLAGICON[cell.value.toUpperCase()]}
                                                    alt={row.original[data.field]}
                                                />
                                            ) : (
                                                cell.value
                                            )}
                                        </Tooltip> */}
                    {LOCATION[cell.value.trim().toLowerCase()] || cell.value}
                  </>
                ) : data.field === "back" &&
                  parseFloat(cell.value).toFixed(2) > 0 ? (
                  "+" + formatNumber(parseFloat(cell.value).toFixed(2))
                ) : floatkeys.includes(data.field) ? (
                  formatNumber(parseFloat(cell.value).toFixed(2))
                ) : roundkeys.includes(data.field) ? (
                  formatNumber(Math.round(cell.value))
                ) : (
                  cell.value
                )}
              </div>
            );
          },
          Filter: canFilter ? ColumnFilter : <></>,
          filter: filterArrayIncludes,
        });
        if (data.field === "dna") {
          Columns.push({
            Header: "Video",
            accessor: "video",
            id: "video",
            Cell: ({ row, cell }) =>
              row.original.crt < 1.0 ? (
                row.original.videoFile ? (
                  <div className="tableSmallImage">
                    <Tooltip title="B2B Video">
                      <img src={require("../../assets/svg/video.svg")} alt="" />
                    </Tooltip>
                  </div>
                ) : row.original?.b2cJsonVideo ? (
                  <div className="tableSmallImage">
                    <Tooltip title="B2B Video">
                      <img src={require("../../assets/svg/video.svg")} alt="" />
                    </Tooltip>
                  </div>
                ) : (
                  "-"
                )
              ) : row.original?.videoFile ? (
                <div className="tableSmallImage">
                  <Tooltip title="B2B Video">
                    <img src={require("../../assets/svg/video.svg")} alt="" />
                  </Tooltip>
                </div>
              ) : (
                "-"
              ),
          });
        }
        if (data.field === "back" && getPath() === LISTINGPAGES.WATCHLIST) {
          Columns.push({
            Header: "Comment",
            accessor: "remarks",
            id: "remarks",
            Cell: ({ cell }) => (cell.value ? cell.value : "-"),
          });
          Columns.push({
            Header: "Watchlist Disc%",
            accessor: "trackDiscount",
            id: "trackDiscount",
            Cell: ({ cell }) =>
              cell.value ? parseFloat(cell.value).toFixed(2) : "-",
          });

          Columns.push({
            Header: "Expected Disc%",
            accessor: "newDiscount",
            id: "newDiscount",
            Cell: ({ cell }) =>
              cell.value ? parseFloat(cell.value).toFixed(2) : "-",
          });
          Columns.push({
            accessor: "**",
            id: "**",
            Header: (
              <>
                <img
                  width="12px"
                  height="12px"
                  src={require("../../assets/svg/up-arrow-dis.svg")}
                  alt="Up Arrow"
                />
                /
                <img
                  width="12px"
                  height="12px"
                  src={require("../../assets/svg/downa-rrow-dis.svg")}
                  alt="Down Arrow"
                />
              </>
            ),
            Cell: ({ row }) => {
              let discUpDown = "-";
              if (
                row.original.trackDiscount !== 0 &&
                row.original.trackDiscount < row.original.back
              ) {
                discUpDown = (
                  <img
                    width="12px"
                    height="12px"
                    src={require("../../assets/svg/up-arrow-dis.svg")}
                    alt="Up Arrow"
                  />
                );
              } else if (
                row.original.trackDiscount !== 0 &&
                row.original.trackDiscount > row.original.back
              ) {
                discUpDown = (
                  <img
                    width="12px"
                    height="12px"
                    src={require("../../assets/svg/downa-rrow-dis.svg")}
                    alt="Down Arrow"
                  />
                );
              }
              return discUpDown;
            },
          });
        }
        if (data.field === "vStnId" && getPath() === LISTINGPAGES.FM_LIST) {
          Columns.push({
            Header: "FM Report No.",
            accessor: "fmNo",
            id: "fmNo",
          });
        }
      }
    });
  }

  if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`)) {
    return Columns.filter((el) => !["back", "amt", "ctPr"].includes(el.id));
  } else if (getPath() === LISTINGPAGES.FM_LIST) {
    return Columns.filter((el) => !["rptNo"].includes(el.id));
  } else {
    const findSrNoIndex = findIndex(Columns, { id: "srNo" });
    const findVstnIdIndex = findIndex(Columns, { id: "vStnId" });
    const columns = Columns.splice(
      findVstnIdIndex,
      0,
      Columns.splice(findSrNoIndex, 1)[0],
    );

    if (getPath() === LISTINGPAGES.UPCOMING) {
      Columns = Columns.filter((item) => !["back", "amt", "ctPr"].includes(item.id));
    }
    return Columns;
  }
}

export function handleAlter(array, item, key) {
  let checked = _.cloneDeep(array) || [];
  if (_.find(checked, { [key]: item[key] }))
    checked = checked.filter((el) => el[key] !== item[key]);
  else checked.push(item);
  return checked;
}

export function getParams(props_row) {
  let params = [
    // inside ? null : props_row.shpNm,
    // inside ? null : props_row.lbNm,
    parseFloat(props_row.crt).toFixed(2),
    props_row.isFcCol ? props_row.fcColNm : props_row.colNm,
    props_row.clrNm,
    props_row.cutNm,
    props_row.polNm,
    props_row.symNm,
    props_row.fluNm,
    // inside ? null : props_row.fluNm
  ].filter(function (el) {
    return el && el;
  });
  return params;
}

export function getStoneInfo(props_row) {
  let params = [
    props_row.vStnId || props_row.stoneId,
    props_row.shpNm,
    parseFloat(props_row.crt).toFixed(2),
    props_row.isFcCol ? props_row.fcColNm : props_row.colNm,
    props_row.clrNm,
    props_row.cutNm,
    props_row.polNm,
    props_row.symNm,
    props_row.fluNm,
  ].filter(function (el) {
    return el && el;
  });
  return params;
}

export function getStoneTitle(obj) {
  let params = obj.map((p) => ({
    shp: p.shpNm,
    crt: parseFloat(p.crt).toFixed(2),
    col: p.isFcCol ? p.fcColNm : p.colNm,
    clr: p.clrNm,
    cut: p.cutNm,
    pol: p.polNm,
    sym: p.symNm,
  }));
  let display = [];
  let keyArr = ["shp", "crt", "col", "clr", "cut", "pol", "sym"];
  keyArr.map((key) => {
    let word = _.groupBy(params, key);
    if (_.size(word) === 1) display.push(params[0][key]);
  });
  display = display.filter((el) => el && el).join(" ");
  return display;
}

export function compareStones(list) {
  if (list.length === 0) {
    OpenNotification({
      type: "error",
      title: "Please select stone(s) to compare.",
    });
    return;
  }
  if (list.length > 15) {
    OpenNotification({
      type: "error",
      title: "Maximum 15 stones can be compared.",
    });
    return;
  }
  let data =
    //localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
    //? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
    // :
    [];
  list.map((x) =>
    data.push(
      window.location.pathname === "/account/confirm-list" ? x.diamondId : x.id,
    ),
  );
  //
  data = _.uniq(data);
  //
  if (data.length === 1) {
    OpenNotification({
      type: "error",
      title: "Please select minimum two stones to compare.",
    });
    return;
  }
  if (data.length > 15) {
    data = data.slice(data.length - 15, data.length);
  }
  localStorage.setItem(
    `${LOCAL_STORAGE_VAR}-compare-diamond`,
    JSON.stringify(data),
  );
  if (window.location.pathname === "/account/confirm-list") {
    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-confirm-compare`,
      JSON.stringify(true),
    );
  } else {
    localStorage.setItem(
      `${LOCAL_STORAGE_VAR}-confirm-compare`,
      JSON.stringify(false),
    );
  }
  localStorage.setItem(
    `${LOCAL_STORAGE_VAR}-now`,
    JSON.stringify(window.location.pathname + window.location.search),
  );
  window.open("/compare");
}

export function dnaCompareStones(list) {
  if (list.length !== 2) {
    const title = `Please select ${list.length === 0 ? "" : list.length === 1 ? "minimum two" : "only two"} stone(s) to be compared.`;
    return OpenNotification({
      type: "error",
      title,
    });
  }

  const data = [];
  list.map((x) => data.push(x.vStnId));

  const redirectUrl = `${VIEW_DNA_URL}/dna/compare-dna?id=${data.join()}`;
  window.open(redirectUrl);
}

export function fetchDiamondList(state, filters, cb, term) {
  const queryString = window.location.href.split("/").pop().toString();

  let extraTerms = localStorage.getItem(`${LOCAL_STORAGE_VAR}-extraTerms`)
    ? JSON.parse(
      window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-extraTerms`)),
    )
    : {};
  let obj = SEARCH_DIAMOND_LIST;
  obj.request = {
    filters,
    isAppendMasters:
      queryString !== "diamond-list?64da032f1bd7b821a2c96263" ? true : false,
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    isSkipSave: true,
  };
  if (term && !isEmpty(extraTerms)) {
    obj.request = {
      ...obj.request,
      termValue: extraTerms.termValue,
      termType: extraTerms.termType,
    };
  }
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let inBlockDiamonds = [];
    let filterArray = {};
    if (data && data.code === "OK") {
      seachFilterTag = data.data[0].filter ? data.data[0].filter : {};
      rows = data.data[0].diamonds ? data.data[0].diamonds : [];
      sum = data.data[0].total ? data.data[0].total[0] : {};
      count = data.data[0].count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data[0].inTrackDiamonds
        ? data.data[0].inTrackDiamonds
        : [];
      inBlockDiamonds = data.data[0].inBlockDiamonds
        ? data.data[0].inBlockDiamonds
        : [];
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
      inBlockDiamonds,
    });
  });
}

export function fetchStoneOfDay(state, type, cb) {
  let obj = FEATURE_STONE;
  obj.request = {
    type,
    page: state.page,
    limit: state.limit,
    sort: state.sort,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let filterArray = {};
    if (data && data.code === "OK") {
      rows = data.data.list;
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function handleExclusiveSearchListId(cb) {
  let searchAPI = SEARCH_DIAMOND_LIST;
  let searchAPIObj = {
    filters: [
      {
        or: [
          {
            crt: { ">=": "5.00", "<=": "5.99" },
          },
          {
            crt: { ">=": "6.00", "<=": "9.99" },
          },
          {
            crt: { ">=": "10.00", "<=": "19.99" },
          },
          {
            crt: { ">=": "20.000", "<=": "100" },
          },
        ],
      },
    ],
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };
  let objData = {
    ...searchAPI,
    request: searchAPIObj,
  };
  UtilService.callApi(objData, function (err, data) {
    if (data && data.code === "OK") {
      let obj = SEARCH_LIST;
      obj.request = {
        id: data.data[0].filter.id,
        isAppendMasters: true,
      };
      UtilService.callApi(obj, function (err, data) {
        cb(data && data.code === "OK" ? data.data.list[0].searchData : null);
      });
    } else cb(null);
  });
}

export function fetchOffer(state, cb) {
  let obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.QUOTE,
    sort: [{ createdAt: "DESC" }],
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      let url = `${VIEW_DNA_URL}/dna/`;
      rows = data.data.list.map((x) => {
        return {
          remarks: x?.remarks || "",
          newDiscount: x?.newDiscount || "",
          newAmount: x?.newAmount || "",
          newPricePerCarat: x?.newPricePerCarat || 0,
          offerStatus: x?.offerStatus || "",
          updatedAt: x?.updatedAt || "",
          trackId: x?.id || "",
          offerValidDate: x?.offerValidDate || "",
          grpBy: x?.offerValidDate?.split("T")[0] || "",
          trackPricePerCarat: x?.trackPricePerCarat,
          trackDiscount: x?.trackDiscount,
          Seller: x?.seller?.name || "",
          createdAt: x?.createdAt,
          dna: (
            <a
              onClick={() => HitCounter(x?.diamond?.id, x?.diamond?.vStnId)}
              href={`${url}${x?.diamond?.vStnId}`}
              target="_blank"
              rel="noreferrer"
            >
              <u>DNA</u>
            </a>
          ),
          ...x?.diamond,
          id: x?.id,
          diamond: x?.diamond.id,
        };
      });
      let grp = _.groupBy(rows, "grpBy");
      rows = [];
      Object.keys(grp).map((key) => {
        grp[key][0].isOfferHeader = true;
        rows = [...rows, ...grp[key]];
      });
      sum = data.data.total[0];
      count = data.data.count;
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      sum,
      count,
      inTrackDiamonds,
    });
  });
}

export function fetchBid(state, type, status, cb) {
  let obj = BID_PAGINATE;
  obj.request = {
    // bidType: type,
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    status: status,
    from:
      type.length === 2 && state.date.from
        ? state.date.from.toISOString()
        : undefined,
    to:
      type.length === 2 && state.date.to
        ? state.date.to.toISOString()
        : undefined,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let filterArray = {};
    if (data && data.code === "OK") {
      rows = data.data.list.map((x) => ({
        extraValue: x,
        ...x?.diamond,
        clientRank: x?.clientRank,
        trackId: x?.id,
        bidAmount: x?.bidAmount,
        bidDiscount: x?.bidDiscount,
        bidPricePerCarat: x?.bidPricePerCarat,
        newDiscount: x?.newDiscount,
        newPricePerCarat: x?.newPricePerCarat,
        newAmount: x?.newAmount,
        bidType: x?.bidType,
        bidTType: x?.bidType,
        status: x?.status,
        updatedAt: x?.updatedAt,
        createdAt: x?.createdAt,
        grpBy: x?.createdAt?.split("T")[0],
        winAmount: x?.winAmount,
        winDiscount: x?.winDiscount,
        winPricePerCarat: x?.winPricePerCarat,
      }));
      if (type.length === 2) {
        let grp = _.groupBy(rows, "grpBy");
        rows = [];
        Object.keys(grp).map((grpNo) => {
          let val = grp[grpNo];
          val[0].isBidHeader = true;
          val[0].groupingTitle = moment(val[0].createdAt).format("DD/MM/YYYY");
          rows = [...rows, ...val];
        });
      }
      sum = data.data.total[0];
      count = data.data.count;
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchMatchPair(state, diamondSearchId, cb) {
  let obj = CREATE_MATCH_PAIR;
  obj.request = {
    filter: { diamondSearchId },
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    isSkipSave: true,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let filterArray = {};
    if (data && data.code === "OK") {
      let grp = _.groupBy(data.data.list, "groupNo");
      Object.keys(grp).map((grpNo) => {
        let val = grp[grpNo];
        val[0].isMatchHeader = true;
        val[0].groupingTitle = getStoneTitle(val);
        val[0].totalDiamonds = val.length;
        val[val.length - 1].groupData = {};
        val[val.length - 1].isMatchFooter = true;
        DISPLAY_TOTAL.map((i) => {
          val[val.length - 1].groupData[i] = [];
          val.map((x) => {
            if (x[i]) val[val.length - 1].groupData[i].push(x[i]);
            return true;
          });
          return true;
        });
        rows = [...rows, ...val];
      });
      sum = data.data.total[0];
      count = data.data.count;
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchMatchPairCount(state, diamondSearchId, cb) {
  let obj = CREATE_MATCH_PAIR;
  obj.request = {
    filter: { diamondSearchId },
    isOnlyCountReturn: true,
  };
  UtilService.callApi(obj, function (err, data) {
    let count = 0;

    if (data && data.code === "OK") {
      count = data.data.count;
    }
    cb({
      count,
    });
  });
}

export function fetchConfirmStone(state, cb) {
  let obj = FETCH_CONFIRM_STONE;
  obj.request = { page: state.page, limit: state.limit };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let filterArray = {};
    let orderDetails = {};

    if (data && data.code === "OK") {
      // let grp = _.groupBy(data.data.list, "invoiceDate");

      data.data.list.map((data) => {
        orderDetails[data?.invoiceDate] = data?.memoDetails;
      });

      orderDetails = Object.keys(orderDetails)
        .sort((a, b) => new Date(b) - new Date(a))
        .reduce(
          (_sortedObj, key) => ({
            ..._sortedObj,
            [key]: orderDetails[key],
          }),
          {},
        );
      Object.keys(orderDetails).map((grpNo) => {
        let val = orderDetails[grpNo];
        val[0].isConfirmHeader = true;
        val[0].groupingTitle = grpNo ? moment(grpNo).format("DD/MM/YYYY") : "-";
        val[0].totalDiamonds = val.length;
        rows = [...rows, ...val];
      });

      count = data?.data?.count;
      seachFilterTag = data?.data?.filter;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchCart(state, cb) {
  let obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.CART,
    sort: state.sort,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      rows = data.data.list.map((x) => x.diamond);
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchOfficeList(state, cb) {
  let obj = FETCH_OFFICE_DIAMOND;
  obj.request = {
    page: state.page,
    limit: state.limit,
    sort: [{ date: "ASC" }],
    //type: 1,
    filter: { date: { ">=": moment().startOf("day").toISOString() } },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      if (!isEmpty(data.data)) {
        let grp = data.data.list.filter((x) => x.diamonds && x.diamonds.length);
        _.each(grp, (x) => {
          let val = x.diamonds;
          val[0] = {
            ...val[0],
            isOfficeHeader: true,
            cabinSlot: x.cabinSlot,
            date: x.date,
            // slottId: x.id,
          };
          _.each(val, (all) => {
            all.slottId = x.id;
          });
          rows = [...rows, ...val];
        });

        // rows = _.uniqBy(rows, "vStnId");
        count = data.data.count;
        sum = data.data.total;
        FILTER_COLUMNS.map((x) => {
          filterArray[x] = _.uniq(rows.map((r) => r[x]));
        });
        inTrackDiamonds = data.data.inTrackDiamonds;
      }
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchWatch(state, cb) {
  let obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.WATCHLIST,
    sort: state.sort,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        newDiscount: x.newDiscount,
        trackDiscount: x.trackDiscount,
        remarks: x.remarks,
      }));
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchNotes(state, cb) {
  let obj = FETCH_NOTES;
  obj.request = {
    page: state.page,
    limit: state.limit,
    isAppendDiamond: 1,
    sort: state.sort,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        remarks: x.remarks,
        trackId: x.id,
      }));
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchReminder(state, cb) {
  let obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.REMINDER,
    sort: state.sort,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        reminderDate: x.reminderDate,
      }));
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchDiamondPaginate(state, type, cb, filtr = {}) {
  let obj = SEARCH_DIAMOND_LIST;
  obj.request = {
    page: state.page,
    limit: state.limit,
    filters: !_.isEmpty(filtr) //DO NOT CHANGE
      ? [filtr]
      : [
        type.viewType
          ? {}
          : type === DIAMOND_WEB_STATUS.UPCOMING
            ? { prcStg: "UP" }
            : { wSts: type, ...filtr },
      ],
    sort: state.sort,
    viewType: type.viewType,
  };

  if (type === DIAMOND_WEB_STATUS.BID) {
    obj.request = {
      ...obj.request,
      analytics: {
        page: "search",
        section: "newArrival",
        action: "completed",
      },
    };
  }
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      seachFilterTag = data.data[0].filter;
      rows = data.data[0].diamonds;
      if (type === DIAMOND_WEB_STATUS.UPCOMING) {
        let grp = _.groupBy(rows, "inDt");
        rows = [];
        Object.keys(grp).forEach((x) => {
          //grp[x][0].isUpcomingHeader = true;
          grp[x][0].totalDiamonds = grp[x].length;
          rows = [...rows, ...grp[x]];
        });
      }
      sum = data.data[0].total[0] || {};
      count = data.data[0].count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export const handleInsertTrack = (trackType, selected, cb, date, remarks) => {
  let diamonds = [];
  selected.map((s) =>
    diamonds.push({
      diamond: s?.diamond ? s.diamond : s.id,
      // diamond: s.id,
      trackPricePerCarat: s.ctPr,
      trackAmount: s.amt,
      newDiscount: trackType === trackTypeObj.WATCHLIST ? s.expBack : null,
      trackDiscount: trackType === trackTypeObj.WATCHLIST ? s.back : null,
    }),
  );
  let obj = {
    reminderDate: date,
    trackType,
    diamonds,
    remarks,
  };
  let objData = {
    ...CREATE_TRACK,
    request: obj,
  };
  document.body.classList.add("loading-indicator");
  UtilService.callApi(objData, function (err, data) {
    let flag =
      trackType !== trackTypeObj.REMINDER
        ? true
        : data &&
          data.data &&
          data.data.created &&
          data.data.created.length === 0 &&
          data.data.exists &&
          data.data.exists.length !== 0
          ? false
          : true;
    document.body.classList.remove("loading-indicator");
    if (data && data.code === "OK" && flag) {
      cb(true);
      return OpenNotification({
        type: "success",
        title: data.message,
      });
    } else {
      OpenNotification({
        type: "error",
        title: data ? data.message : err && err.data ? err.data.message : "",
      });
      cb(false);
    }
  });
};

export function handleNoteUpsert(selected, cb) {
  let obj = NOTE_UPSERT;
  obj.request = {
    diamonds: selected.map((d) => ({ diamond: d.id, remarks: d.note.trim() })),
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      OpenNotification({
        type: "success",
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        title: data ? data.message : err && err.data ? err.data.message : "",
      });
      cb(false);
    }
  });
}

export const handleUpsertTrack = (
  trackType,
  selected,
  inTrackDiamonds,
  cb,
  date,
) => {
  let ids = selected.map((x) => x.id);
  let id = [];
  let diamonds = _.find(inTrackDiamonds, { _id: trackType })
    ? _.find(inTrackDiamonds, { _id: trackType }).diamonds
    : [];
  if (diamonds) {
    _.each(ids, (diamondId) => {
      let trackId = _.find(diamonds, { id: diamondId });
      if (trackId) id.push(_.find(diamonds, { id: diamondId }).trackId);
    });
  }
  let obj = {
    reminderDate: date,
    trackType,
    id,
  };
  let objData = {
    ...UPDATE_TRACK,
    request: obj,
  };
  UtilService.callApi(objData, function (err, data) {
    if (data && data.code === "OK") {
      cb(true);
      return OpenNotification({
        type: "success",
        title: data.message,
      });
    } else {
      OpenNotification({
        type: "error",
        title: data ? data.message : err && err.data ? err.data.message : "",
      });
      cb(false);
    }
  });
};

export const handleMemoPrint = (filter, orderDiamond, cb) => {
  let obj = {
    orderDiamond,
  };
  if (_.isEmpty(orderDiamond)) {
    obj = { filter };
  }
  let objData = {
    ...PRINT_PDF,
    request: obj,
  };
  UtilService.callApi(objData, async function (err, data) {
    if (err) throw err;
    if (data && data.code === "OK") {
      let server = PRINT_URL;
      let file_path = server + data.data;
      window.open(file_path, "Download");
    }
  });
};

export const handlePrint = (filter, id, isUpcoming) => {
  let obj = {
    id,
  };
  if (_.isEmpty(id)) {
    obj = { filter };
  }
  if (isUpcoming) {
    obj.isUpcoming = true;
  }
  let objData = {
    ...PRINT_PDF,
    request: obj,
  };
  UtilService.callApi(objData, async function (err, data) {
    if (err) throw err;
    if (data && data.code === "OK") {
      let server = PRINT_URL;
      let file_path = server + data.data;
      window.open(file_path, "Download");
    }
  });
};

export const handleDownloadExcel = (
  filter = {},
  id = [],
  cb,
  isUpcoming,
  quoteApplied,
  orderDiamond = false,
  path = null,
) => {
  let request = _.isEmpty(filter) ? { id } : { filter };
  if (!isEmpty(quoteApplied)) request.quoteDiamonds = quoteApplied;
  if (isUpcoming) request.filter = { prcStg: "UP" };   //request.isUpcoming = true;
  if (orderDiamond) request.orderDiamond = true;
  if (
    (path === "new-arrival-bid-it" || path === "diamond-list" || path === "compare") &&
    _.isEmpty(filter)
  )
    request.finalCalc = true;
  let objData = {
    ...EXPORT_EXCEL,
    request,
  };

  UtilService.callApi(objData, async function (err, data) {
    if (err) {
      cb(false);
      return OpenNotification({
        type: "error",
        title: err.data.message,
      });
    }
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      cb(true);
      window.open(file_path, "Download");
    }
  });
};

export function handleConfirmStone(ids, comment, date, company, cb) {
  let obj = {
    ...CONFIRM_STONE,
    request: {
      diamonds: ids,
      comment,
      company,
      date,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      OpenNotification({
        type: "success",
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        title: data ? data.message : err && err.data ? err.data.message : "",
      });
      cb(false);
    }
  });
}

export function handleNoteDelete(id, cb) {
  let obj = NOTE_DELETE;
  obj.request = { id };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      OpenNotification({
        type: "success",
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        title: data ? data.message : err.data.message,
      });
      cb(null);
    }
  });
}

export function handleTrackDelete(trackType, ids, inTrackDiamonds, cb) {
  let id = [];
  let diamonds = _.find(inTrackDiamonds, { _id: trackType })
    ? _.find(inTrackDiamonds, { _id: trackType }).diamonds
    : [];
  if (diamonds) {
    if (trackType === 4 || trackType === "4") {
      _.each(ids, (diamondId) => {
        let trackId = _.find(diamonds, { trackId: diamondId });
        if (trackId) id.push(_.find(diamonds, { trackId: diamondId }).trackId);
      });
    } else {
      _.each(ids, (diamondId) => {
        let trackId = _.find(diamonds, { id: diamondId });
        if (trackId) id.push(_.find(diamonds, { id: diamondId }).trackId);
      });
    }
  }
  let obj = {
    ...DELETE_TRACK,
    request: {
      trackType,
      id,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      OpenNotification({
        type: "success",
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        title: data ? data.message : err && err.data ? err.data.message : "",
      });
      cb(false);
    }
  });
}

export function handleBidDelete(id, cb) {
  let obj = BID_DELETE;
  obj.request = { id };
  document.body.classList.add("loading-indicator");
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      OpenNotification({
        type: "success",
        title: data.message,
      });
      document.body.classList.remove("loading-indicator");
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        title: data ? data.message : err && err.data ? err.data.message : "",
      });
      document.body.classList.remove("loading-indicator");
      cb(false);
    }
  });
}

export const handleSendEmail = (apiObj, cb) => {
  let obj = {
    ...EXPORT_EXCEL,
    request: apiObj,
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      cb(true);
      return OpenNotification({
        type: "success",
        title: data.message,
      });
    } else {
      cb(false);
      return OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
  });
};

export function handleBidUpsert(rows, type, cb) {
  let obj = getPath() === LISTINGPAGES.MYBID ? UPDATE_BID : CREATE_BID;

  obj.request =
    getPath() === LISTINGPAGES.MYBID
      ? {
        diamonds: rows.map((x) => ({
          id: x.trackId,
          // bidType: x.bidType,

          bidPricePerCarat: x.bidPricePerCarat,
          bidDiscount: x.bidDiscount,
          bidAmount: x.bidAmount,
          newPricePerCarat: x.calcPricePerCarat,
          newAmount: x.calcAmount,
          newDiscount: x.calcDiscount,
          // bidPricePerCarat: x.bidPricePerCarat,
          // bidDiscount: x.bidDiscount,
          // bidAmount: x.bidAmount,
          // trackPricePerCarat: x.ctPr,
          // trackAmount: x.amt,
          // trackDiscount: x.back,
          deviceType: 1,
          status: x.status,
        })),
      }
      : {
        // bidType: type,
        diamonds: rows.map((x) => ({
          diamond: x.id,
          bidPricePerCarat: x.bidPricePerCarat,
          bidDiscount: x.bidDiscount,
          bidAmount: x.bidAmount,
          newPricePerCarat: x.ctPr,
          newAmount: x.amt,
          newDiscount: x.back,
          // bidPricePerCarat: x.bidPricePerCarat,
          // bidAmount: x.bidAmount,
          // bidDiscount: x.bidDiscount,
          // trackPricePerCarat: x.ctPr,
          // trackAmount: x.amt,
          // trackDiscount: x.back,
          vnd: x.vnd,
          vStnId: x.vStnId,
        })),
      };

  obj.request = {
    ...obj.request,
    analytics: {
      page: "search",
      section: "bid",
      action: "completed",
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      OpenNotification({
        type: "success",
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        title: err && err.data ? err.data.message : data.message,
      });
      cb(false);
    }
  });
}

function offerObject(item) {
  return {
    diamond: item.trackId,
    trackPricePerCarat: item.ctPr,
    trackAmount: item.amt,
    trackDiscount: item.back,
    vStnId: item.vStnId,
    newAmount: item.calcAmount,
    newPricePerCarat: item.calcPricePerCarat,
    //newAmount: (item.rap - item.rap * item.finalquote) * item.crt,
    //newPricePerCarat: item.rap - item.rap * item.finalquote,
    newDiscount: item.finalquote,
    offerValidDate: moment().add(item.hours, "hours"),
    offerPer: item.quote,
    quoteHr: item.hours,
  };
}

export function getProjectSettingList(type, cb) {
  let obj = PROJECT_SETTING_PAGINATE;
  obj.request = {
    filter: {
      type,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") cb(data);
    else cb([]);
  });
}

export function getProjectSetting(type, cb) {
  let obj = PROJECT_SETTING;
  obj.request = {
    filter: {
      type,
    },
  };
  return UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK" && type === 6) {
      cb(data.data);
    }
    if (data && data.code === "OK" && type === 37) {
      cb(data.data.data?.DISCOUNT_CALCULATION);
    } else if (data && data.code === "OK") {
      cb(data.data.data);
    } else cb([]);
  });
}

export function handleSaveQuote(selectedItems, comment, company, cb, update) {
  let obj = update ? UPDATE_TRACK : CREATE_TRACK;
  let item = selectedItems[0];
  obj.request = update
    ? {
      trackType: trackTypeObj.QUOTE,
      remarks: comment,
      company,
      id: item.trackId,
      ...offerObject(item),
    }
    : {
      trackType: trackTypeObj.QUOTE,
      remarks: comment,
      company,
      diamonds: selectedItems.map((x) => ({
        ...offerObject(x),
        diamond: x.id,
      })),
    };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      if (update) {
        OpenNotification({
          type: "success",
          title: data.message,
        });
        cb(true);
        return;
      } else {
        OpenNotification({
          type: "success",
          title: data.message,
        });
        cb(true);
        // if (data.data.created.length > 0) cb(true);
        // else cb(false);
      }
    } else {
      OpenNotification({
        type: "error",
        title: err && err.data ? err.data.message : data.message,
      });
      cb(false);
    }
  });
}

export const handleSendXRayEmail = (apiObj, path, cb) => {
  apiObj.message = `${apiObj.message} <a href=${path}> View File</a>`;
  let obj = {
    ...SEND_EMAIL_XRAY,
    request: apiObj,
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === "OK") {
      cb(true);
      return OpenNotification({
        type: "success",
        title: data.message,
      });
    } else {
      cb(false);
      return OpenNotification({
        type: "error",
        title: data.message,
      });
    }
  });
};

export function allSortObject(columns) {
  let sort = {};
  _.each(columns, (col) => {
    // if (col.id !== 'Details') sort[col.id] = col.Header
    if (col.sort) sort[col.sort] = col.Header;
  });
  return sort;
}

export const downloadZip = (dt, cb) => {
  let obj = {
    ...DOWNLOAD_ZIP,
    request: dt,
  };
  document.body.classList.add("loading-indicator");
  UtilService.callApi(obj, function (err, data) {
    document.body.classList.remove("loading-indicator");
    if (data && data.code === "OK") {
      let server = DOWNLOAD_URL;
      const path = dt.zip ? data.data : data.data[0];
      let file_path = server + "/" + path;
      if (dt) {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", file_path, true);
        xhr.responseType = "blob";
        // window.open(file_path, "Download");
        const fileName = _.last(path.split("/"));
        xhr.onload = function () {
          let urlCreator = window.URL || window.webkitURL;
          let imageUrl = urlCreator.createObjectURL(this.response);
          let tag = document.createElement("a");
          tag.href = imageUrl;
          tag.download = fileName;
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
        };
        xhr.onerror = (err) => {
          document.body.classList.remove("loading-indicator");
          return OpenNotification({
            type: "error",
            title: "This file is not available !",
          });
        };

        setTimeout(function () {
          xhr.send();
          document.body.classList.remove("loading-indicator");
        }, 5000);
      } else {
        if (dt?.isExcel || dt?.certFile || dt?.type2)
          window.open(file_path, "Download");
      }
      cb(true);
    } else if (data && data.code === "E_NOT_FOUND") {
      OpenNotification({
        type: "error",
        title: data.message,
      });
      cb(false);
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
      cb(false);
    }
  });
};

export const downloadFile = (apiObj) => {
  let obj = {
    ...DOWNLOAD_FILE,
    request: apiObj,
  };
  document.body.classList.add("loading-indicator");
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.path;
      // window.open(file_path,"download")
      let xhr = new XMLHttpRequest();
      xhr.open("GET", file_path, true);
      xhr.responseType = "blob";

      xhr.onload = function () {
        let urlCreator = window.URL || window.webkitURL;
        let imageUrl = urlCreator.createObjectURL(this.response);
        let tag = document.createElement("a");
        tag.href = imageUrl;
        tag.download = apiObj.fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };

      xhr.onerror = (err) => {
        document.body.classList.remove("loading-indicator");
        return OpenNotification({
          type: "error",
          title: "This file is not available !",
        });
      };

      setTimeout(function () {
        xhr.send();
        document.body.classList.remove("loading-indicator");
      }, 5000);
    } else {
      return OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
  });
};

export const downloadVideo = (apiObj) => {
  let xhr = new XMLHttpRequest();
  xhr.open("GET", apiObj.path, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    let urlCreator = window.URL || window.webkitURL;
    let imageUrl = urlCreator.createObjectURL(this.response);
    let tag = document.createElement("a");
    tag.href = imageUrl;
    tag.target = "_blank";
    tag.download = apiObj.fileName + apiObj.ext;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.onerror = (err) => {
    document.body.classList.remove("loading-indicator");
    return OpenNotification({
      type: "error",
      title: "This file is not available !",
    });
  };
  setTimeout(function () {
    xhr.send();
    document.body.classList.remove("loading-indicator");
  }, 5000);
};

export const handleDeleteOffice = (filter, cb) => {
  let self = this;

  let objData = {
    ...DELETE_OFFICE_DIAMOND,
    request: filter,
  };
  UtilService.callApi(objData, function (err, data) {
    if (err) throw err;
    if (data && data.code === "OK") {
      cb(true);
    }
  });
};

export const fetchQuotedayDiamonds = (cb) => {
  let obj = QUOTE_DAY_LIST;
  obj.request = {
    type: 2,
    isDeleted: false,
    isActive: true,
  };

  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      rows = _.map(data.data.list, "diamond");
      count = rows.length;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
};

export const myAccountExcelTrack = (trackType) => {
  let obj = {
    ...FETCH_DIAMOND_TRACK,
    request: {
      isExcel: true,
      page: 1,
      limit: 100000,
      trackType,
      sort: {},
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const myAccountExcelMemo = (filter) => {
  let obj = {
    ...FETCH_CONFIRM_STONE,
    request: {
      isExcel: true,
      page: 1,
      limit: 100000,
      filter,
      sort: [{ memoNo: "DESC" }],
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};
export const myAccountExcelOffice = () => {
  let obj = {
    ...FETCH_OFFICE_DIAMOND,
    request: {
      isExcel: true,
      page: 1,
      limit: 100000,
      sort: [{ date: "ASC" }],
      filter: { date: { ">=": moment().startOf("day").toISOString() } },
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export function myAccountNotesExcel() {
  let obj = {
    ...FETCH_NOTES,
    request: {
      isExcel: true,
      page: 1,
      isAppendDiamond: 1,
      limit: 100000,
      sort: [],
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

export function myBidExcel() {
  let obj = {
    ...BID_PAGINATE,
    request: {
      isExcel: true,
      page: 1,
      status: [1],
      bidType: [1],
      limit: 100000,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

export function upcomingExcel(filter) {
  let obj = {
    ...SEARCH_DIAMOND_LIST,
    request: {
      isExcel: true,
      page: 1,
      filter,
      limit: 100000,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

///new pdf

export const myAccountPDFTrack = (trackType) => {
  let obj = {
    ...FETCH_DIAMOND_TRACK,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      trackType,
      sort: {},
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const myAccountPDFMemo = (filter) => {
  let obj = {
    ...FETCH_CONFIRM_STONE,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      filter,
      sort: [{ memoNo: "DESC" }],
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};
export const myAccountPDFOffice = () => {
  let obj = {
    ...FETCH_OFFICE_DIAMOND,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      sort: [{ date: "ASC" }],
      filter: { date: { ">=": moment().startOf("day").toISOString() } },
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export function myAccountNotesPDF() {
  let obj = {
    ...FETCH_NOTES,
    request: {
      isPdf: true,
      page: 1,
      isAppendDiamond: 1,
      limit: 100000,
      sort: [],
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

export function myBidPDF() {
  let obj = {
    ...BID_PAGINATE,
    request: {
      isPdf: true,
      page: 1,
      status: [1],
      bidType: [1],
      limit: 100000,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

export const upcomingPDF = (filter) => {
  let obj = {
    ...SEARCH_DIAMOND_LIST,
    request: {
      isPdf: true,
      isUpcoming: true,
      page: 1,
      limit: 100000,
      sort: {},
      filter,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export function mySuggestedExcel() {
  let obj = {
    ...EXPORT_EXCEL,
    request: {
      moduleType: "suggestedStock",
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

export function myExclusiveExcel() {
  let obj = {
    ...EXPORT_EXCEL,
    request: {
      page: 1,
      limit: 250,
      sort: [],
      filter: {
        or: [
          {
            crt: {
              ">=": "5.00",
              "<=": "5.99",
            },
          },
          {
            crt: {
              ">=": "6.00",
              "<=": "9.99",
            },
          },
          {
            crt: {
              ">=": "10.00",
              "<=": "19.99",
            },
          },
          {
            crt: {
              ">=": "20.000",
              "<=": "100",
            },
          },
        ],
      },
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

export function mySuggestedPDF() {
  let obj = {
    ...PRINT_PDF,
    request: {
      moduleType: "suggestedStock",
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

export function myExclusivePDF() {
  let obj = {
    ...PRINT_PDF,
    request: {
      page: 1,
      limit: 250,
      sort: [],
      filter: {
        or: [
          {
            crt: {
              ">=": "5.00",
              "<=": "5.99",
            },
          },
          {
            crt: {
              ">=": "6.00",
              "<=": "9.99",
            },
          },
          {
            crt: {
              ">=": "10.00",
              "<=": "19.99",
            },
          },
          {
            crt: {
              ">=": "20.000",
              "<=": "100",
            },
          },
        ],
      },
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

export function shareExcel(checked, cb) {
  let obj = {
    ...EXPORT_EXCEL,
    request: {
      id: checked,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      // let server = BASE_DOWNLOAD_URL;
      // let file_path = server + data.data;
      cb(data.data.data);
      //window.open(file_path, "Download");
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
  });
}

export const getDiamondBlockMaster = (code, cb) => {
  let obj = {
    ...BLOCK_MASTERS,
    request: {
      filter: { blockCode: code },
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      cb(data.data[0]);
    } else {
      return OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
  });
};
export const getDiamondBlockMasterHold = (code, cb) => {
  let obj = {
    ...BLOCK_MASTERS_TYPE,
    request: code,
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      cb(data);
    } else {
      return OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
  });
};

export const handleAddBlock = (request, block, checked, time, comment, cb) => {
  let user = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

  let obj = {
    ...ADD_HOLD,
    request: {
      blockType: block,
      diamonds: checked,
      user: user.id,
      userAccount: user.account.id,
      seller: user.seller,
      comment,
    },
  };
  if (block === 1) obj.request = { ...obj.request, validTillDate: time };
  if (block === 2)
    obj.request = {
      ...obj.request,
      assistantSeller: user.seller,
      shippingCountry: user.country,
      shippingCity: user.city,
      stage: checked[0].blockSetting,
      broker: user.account.broker,
      shipMode: "Ship To Direct",
    };

  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      cb(true);
      OpenNotification({
        type: "success",
        title: data.message,
      });
    } else {
      return OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
  });
};

export const handleHold = (request, cb) => {
  let obj = { ...ADD_HOLD, request };
  document.body.classList.add("loading-indicator");
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      cb(true);
      OpenNotification({
        type: "success",
        title: data.message,
      });
    } else {
      cb(false);
      document.body.classList.remove("loading-indicator");
      return OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    document.body.classList.remove("loading-indicator");
  });
};

export const fetchBlockDiamonds = (blockType, cb) => {
  let obj = {
    ...GET_BLOCK_DIAMONDS,
    request: { blockType },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let inBlockDiamonds = [];
    let filterArray = {};
    if (data && data.code === "OK") {
      seachFilterTag = data.data.filter ? data.data.filter : {};
      data.data.list &&
        data.data.list.map((l) => {
          rows.push({ ...l.diamond, id: l.id });
        });
      sum = data.data.total ? data.data.total[0] : {};
      count = data.data.count;
      // FILTER_COLUMNS.map((x) => {
      //   filterArray[x] = _.uniq(rows.map((r) => r[x]));
      // });
      inTrackDiamonds = data.data.inTrackDiamonds
        ? data.data.inTrackDiamonds
        : [];
      inBlockDiamonds = data.data.inBlockDiamonds
        ? data.data.inBlockDiamonds
        : [];
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
      inBlockDiamonds,
    });
  });
};

export const releaseBlockedDiamonds = (ids, cb) => {
  let obj = {
    ...BLOCK_REALESE,
    request: { diamondBlocks: ids, status: 4 },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      cb(true);
      OpenNotification({
        type: "success",
        title: data.message,
      });
    } else {
      return OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
  });
};

export const removeStoneFromRapnet = (req, cb) => {
  let obj = {
    ...REMOVE_STONE_RAPNET,
    request: req,
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      cb(true);
      OpenNotification({
        type: "success",
        title: data.message,
      });
    } else {
      return OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
  });
};

export const fetchTrendingDiamonds = (type, cb) => {
  let obj = {
    ...GET_TRENDING_DIAMONDS,
    request: { ...type },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let inBlockDiamonds = [];
    let filterArray = {};
    if (data && data.code === "OK") {
      rows = data?.data?.list;
      sum = {};
      count = data?.data?.count;
      // if (type?.type === 0) {
      //   rows = data?.data?.list;
      //   sum = {};
      //   count = data?.data?.count;
      // } else {
      //   rows = data?.data?.list;
      //   sum = {};
      //   count = data?.data?.count;
      // }
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
      inBlockDiamonds,
    });
  });
};

export const fetchComingsoonDiamonds = (req, cb) => {
  let obj = {
    ...COMING_SOON_DIAMONDS,
    request: req,
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === "OK") {
      let rows = [];
      let count = 0;
      let sum = {};
      let seachFilterTag = {};
      let inTrackDiamonds = [];
      let inBlockDiamonds = [];
      let filterArray = {};
      if (data && data.code === "OK") {
        rows = data?.data?.list;
        sum = {};
        count = data?.data?.count;
      }
      cb({
        data: rows,
        seachFilterTag,
        sum,
        count,
        filterArray,
        selectedFilterArray: {},
        inTrackDiamonds,
        inBlockDiamonds,
      });
    }
  });
};

const HitCounter = (diamondId, vStnId) => {
  let obj = CREATE_SEENSTONE;

  obj.request = {
    diamondId: diamondId,
    vStnId: vStnId,
  };

  UtilService.callApi(obj);
};
