var React = require("react");
var CanvasJS = require("./canvasjs.min");
CanvasJS = CanvasJS.Chart ? CanvasJS : window.CanvasJS;

class CanvasJSChart extends React.Component {
  static _cjsContainerId = 0;
  constructor(props) {
    super(props);
    this.options = props.options ? props.options : {};
    this.containerProps = props.containerProps
      ? props.containerProps
      : { width: "100%", position: "relative" };
    this.containerProps.height =
      props.containerProps && props.containerProps.height
        ? props.containerProps.height
        : this.options.height
        ? this.options.height + "px"
        : "400px";
    this.chartContainerId =
      "canvasjs-react-chart-container-" + CanvasJSChart._cjsContainerId++;
  }
  componentDidMount() {
    this.chart = new CanvasJS.Chart(this.chartContainerId, this.options);
    this.chart.render();
    this.functionHide();
    if (this.props.onRef) this.props.onRef(this.chart);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return !(nextProps.options === this.options);
  }
  componentDidUpdate() {
    this.functionHide();
    this.chart.options = this.props.options;
    this.chart.render();
  }
  componentWillUnmount() {
    this.chart.destroy();
    if (this.props.onRef) this.props.onRef(undefined);
  }

  functionHide = () => {
    let trial = document.getElementsByClassName("trial-text");
    if (trial && trial.length) {
      for (var tt = 0; tt < trial.length; tt++) {
        let text = trial[tt].getElementsByClassName("trial-hide");
        if (!text || (text && !text.length)) {
          let hide = document.createElement("div");
          hide.className = "trial-hide";
          trial[tt].appendChild(hide);
        }
      }
    }
  };

  render() {
    return (
      <div
        className={"trial-text " + this.props.className}
        id={this.chartContainerId}
        style={this.containerProps}
      />
    );
  }
}

var CanvasJSReact = {
  CanvasJSChart: CanvasJSChart,
  CanvasJS: CanvasJS,
};

export default CanvasJSReact;
