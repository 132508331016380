/* eslint-disable */
import React from "react";
import { Select } from "antd";
import _ from "lodash";
import CanvasJSReact from "./canvasjs.react";
import uniq from "lodash/uniq";
import moment from "moment";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const MONTHS = [
  "",
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const Chart = ({ data, update }) => {
  let currentMonth = moment().month() + 1;
  let monthsOrder = [];
  let j = currentMonth;
  for (let i = 0; i < 12; i++) {
    if (j === 0) j = 12;
    monthsOrder.push(j);
    j--;
  }
  let displayMonths = [];
  let searchData =
    data?.search &&
    data?.search
      .sort(
        (a, b) => monthsOrder.indexOf(b.month) - monthsOrder.indexOf(a.month),
      )
      .map((m) => ({ y: m.count, label: MONTHS[m.month] }));
  searchData && searchData.forEach((val) => displayMonths.push(val.label));
  let enquiryData =
    data?.enquiry &&
    data?.enquiry
      .sort(
        (a, b) => monthsOrder.indexOf(b.month) - monthsOrder.indexOf(a.month),
      )
      .map((m) => ({ y: m.count, label: MONTHS[m.month] }));
  enquiryData && enquiryData.forEach((val) => displayMonths.push(val.label));
  let confirmOrderData =
    data?.confirmOrder &&
    data?.confirmOrder
      .sort(
        (a, b) => monthsOrder.indexOf(b.month) - monthsOrder.indexOf(a.month),
      )
      .map((m) => ({ y: m.count, label: MONTHS[m.month] }));
  confirmOrderData &&
    confirmOrderData.forEach((val) => displayMonths.push(val.label));

  displayMonths = uniq(displayMonths).sort(
    (a, b) =>
      monthsOrder.indexOf(MONTHS.indexOf(b.label)) -
      monthsOrder.indexOf(MONTHS.indexOf(a.label)),
  );

  let chartOptions = {
    exportEnabled: true,
    animationEnabled: true,
    theme: "light2",
    axisX: {
      title: "Months",
    },
    axisY: {
      title: "Count - Units",
      titleFontColor: "#6d78ad",
      lineColor: "#6d78ad",
      labelFontColor: "#6d78ad",
      tickColor: "#6d78ad",
      includeZero: true,
    },
    toolTip: {
      shared: true,
    },
    // axisX: { labelWrap: true, labelFontSize: 11 },

    toolTip: { content: "{title}" },

    data: [
      {
        type: "column",
        name: "Confirm Order",
        legendText: "Confirm Order",
        showInLegend: true,
        dataPoints: data?.confirmOrder?.map((x, i) => ({
          //x: i + 1,
          y: x?.count ? x.count : 0,
          label: x?.month ? MONTHS[x?.month] : 0,
          title:
            `Month : ${MONTHS[x?.month]}` +
            `<br>Count : ${x.count}` +
            `<br>Value : $ ${x.value}`,
        })),
        // color: '#6d78ad',
      },
      // {
      //   type: 'column',
      //   name: 'Search',
      //   legendText: 'search',
      //   // axisYType: 'secondary',
      //   showInLegend: true,
      //   dataPoints: data?.search?.map((x, i) => ({
      //     //x: i + 1,
      //     y: x?.count ? x.count : 0,
      //     label: x?.month ? MONTHS[x?.month] : 0,
      //     title: `Month : ${MONTHS[x?.month]}` + `<br>Count : ${x.count}`,
      //   })),
      //   // color: '#6d78ad',
      // },
      {
        type: "column",
        name: "Enquiry",
        legendText: "Enquiry",
        // axisYType: 'secondary',
        showInLegend: true,
        dataPoints: data?.enquiry?.map((x, i) => ({
          //x: i + 1,
          y: x?.count ? x.count : 0,
          label: x?.month ? MONTHS[x?.month] : 0,
        })),
      },
    ],
  };

  const { Option } = Select;

  return (
    <>
      <div className="homeContainer">
        <div className="chartHead">
          <h2>Purchase Report</h2>
          <Select
            // placeholder="Quarterly"
            style={{ width: 200 }}
            defaultValue="1"
            onChange={(e) => update(e)}
          >
            <Option value="1">Quarterly</Option>
            <Option value="2">6 Month</Option>
            <Option value="3">Yearly</Option>
          </Select>
        </div>
        <CanvasJSChart options={chartOptions} />
      </div>
    </>
  );
};

export default Chart;
