/* eslint-disable */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import BackButton from "../common/BackButton";
import Logo from "../common/Logo";
import UtilService from "../../services/util";
import LangDropdown from "../Header/LangDropdown";
import size from "lodash/size";
import clone from "lodash/clone";
import isEqual from "lodash/isEqual";
import IntlMessages from "../../util/IntlMessages";
import { notification } from "antd";
import OpenNotification from "../common/CommonButton/OpenNotification";
import { PWD_RESET } from "../../constants/apiConstant";
import AuthPoster from "./AuthPoster";
import InputBlock from "../common/InputBlock";

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      passwordChange: {
        confirmpassword: "",
        newPassword: "",
      },
    };
  }

  handleResetPassword = () => {
    let self = this;
    let { errors } = this.state;
    let blankField = [];
    if (!this.state.passwordChange.newPassword) {
      blankField.push("New Password");
    }
    if (!this.state.passwordChange.confirmpassword) {
      blankField.push("Confirm Password");
    }
    if (blankField.length > 0) {
      let validateFields = blankField.map((f, index) => {
        return (
          <span>{`${f}${index !== blankField.length - 1 ? ", " : ""}`}</span>
        );
      });
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: validateFields,
      });

      return;
    }

    if ((this.state.passwordChange.newPassword && this.state.passwordChange.confirmpassword) && this.state.passwordChange.newPassword !== this.state.passwordChange.confirmpassword) {

      notification.error({
        message: "Password Mismatch",
        placement: "bottomLeft",
        description: `New Password & Confirm Password does not match.`,
      });

      return;
    }

    if (this.state.passwordChange.newPassword.length < 4 || this.state.passwordChange.confirmpassword.length < 4) {
      notification.error({
        message: "Password Length Insufficient",
        placement: "bottomLeft",
        description: `New Password's & Confirm Password's length should not be less than 4 characters.`,
      });

      return;
    }

    Object.keys(errors).forEach(
      (key) => errors[key] === undefined && delete errors[key],
    );
    if (size(errors)) {
      this.setState({ errors });
    } else {
      let obj = {
        // method: "post",
        // url: "/auth/reset-password",
        ...PWD_RESET,
        request: {
          token: this.props.match.params.token,
          newPassword: this.state.passwordChange.newPassword,
        },
      };

      UtilService.callApi(obj, function (err, data) {
        if (err) {
          return OpenNotification({
            type: "error",
            title: err.data.message,
          });
        }
        if (data && data.code === "OK") {
          self.props.history.push("/signin");
          OpenNotification({
            type: "success",
            title: "Password is reset successfully.",
          });
        } else {
          OpenNotification({
            type: "error",
            title: data.message,
          });
        }
      });
    }
  };

  handleOnChange = (e) => {
    let obj = clone(this.state.passwordChange);
    obj[e.target.id] = e.target.value;
    this.setState({ passwordChange: obj });
  };

  render() {
    const { passwordChange } = this.state;
    return (
      <>
        <div className="loginMainWrapper">
          <AuthPoster />
          <div className="LoginRightBlock">
            <div className="loginDetail">
              <div className="loginHead">
                <div className="loginLogo">
                  <Logo />
                </div>
                <h3>
                  <IntlMessages id="app.resetpassword" />
                </h3>
                {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,
                </p> */}
              </div>
              <div className="loginBox">
                {/* <form onSubmit={this.sendEmail}> */}
                <InputBlock
                  onChange={this.handleOnChange}
                  label={<IntlMessages id="app.newpassword" />}
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="Enter New Password"
                  value={passwordChange.newPassword}
                />
                <InputBlock
                  onChange={this.handleOnChange}
                  label={<IntlMessages id="app.confirmpassword" />}
                  type="password"
                  id="confirmpassword"
                  name="confirmpassword"
                  placeholder="Enter Confirm Password"
                  value={passwordChange.confirmpassword}
                />
                <div className="loginButton">
                  <button
                    type="submit"
                    className="commonButton"
                    onClick={this.handleResetPassword}
                  >
                    <IntlMessages id="app.resetpasswordbutton" />
                  </button>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ResetPassword);
