/* eslint-disable */
import React, { useState, useEffect } from "react";
import "../TermsOfUse/staticContent.less";
import Heading from "../common/Heading";
import InputBlock from "../common/InputBlock";
import SelectOption from "../common/SelectOption";
import DatePicker from "../common/DatePicker";
import TextArea from "../common/TextArea";
import StaticHeader from "../StaticHeader";
import TimerPicker from "../common/TimerPicker";
import "./appointmentbook.less";
import OpenNotification from "../common/CommonButton/OpenNotification";
import {
  GET_COUNTRY_LIST,
  FETCH_SELLERS,
  GET_STATE_LIST,
  GET_CITY_LIST,
  TAKE_APPOINTMENT,
  GET_MASTERS,
} from "../../constants/apiConstant";
import UtilService from "../../services/util";
import { isEmpty, clone } from "lodash";
import useStateWithCallback from "../../hooks/useStateWithCallback";
import moment from "moment";
import { Form, notification, Radio } from "antd";
import { handleMobileNumber } from "../../services/Commonfunction";

import { LOCAL_STORAGE_VAR } from "../../constants/Common";

const APPOINTMENT_LIST = [
  {
    key: 1,
    value: "Trade Show",
  },
  {
    key: 2,
    value: "Office Visit",
  },
];

export const companyDetail = {
  Country: [
    { value: "Afghanistan " },
    { value: "Albania " },
    { value: "Algeria " },
    { value: "American Samoa" },
    { value: "Andorra" },
    { value: "Angola" },
    { value: "India" },
  ],
  Appointcountry: [
    { name: "Hong Kong", value: "Hong Kong", id: "Hong Kong" }, //"5cf0a19de6799a22f0cd81dc"
    { name: "Israel", value: "Israel ", id: "Israel" }, //"5cf0a19de6799a22f0cd828b"
    { name: "Mumbai", value: "Mumbai", id: "Mumbai" }, //"5cf0a19de6799a22f0cd825a"
    { name: "New York", value: "New York ", id: "New York" }, //"5cf0a19de6799a22f0cd8226"
  ],
  State: [
    { value: "Maharashtra " },
    { value: "Andhra Pradesh" },
    { value: "Arunachal Pradesh" },
    { value: "Assam " },
    { value: "Bihar" },
    { value: "Dadra and Nagar Haveli " },
    { value: "Daman and Diu" },
  ],
  City: [
    { value: "Achalpur" },
    { value: "Ahmadnagar" },
    { value: "Akola" },
    { value: "Amravati" },
    { value: "AURANGABAD" },
    { value: "Miraj" },
    { value: "MUMBAI" },
  ],
  seller: [{ value: "ABHISHEK KARNAVAT" }, { value: "AKSHIT BHALAWAT" }],
};

const BookAppointment = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [sellers, setSeller] = React.useState([]);
  const [country, setCountry] = React.useState([]);
  const [showType, setShowType] = React.useState([]);

  /************************if user login (Amit) */
  const userDetails = UtilService.getLocalStorageItem(
    `${LOCAL_STORAGE_VAR}-user`,
  );
  /******************************************** */

  const [formData, setFormData] = React.useState({
    companyName: "",
    name: "",
    mobileNumber: "",
    email: "",
    country: {},
    state: {},
    city: {},
    cityText: "",
    address: "",
    date: new Date().toISOString(),
    time: undefined,
    timeString: "",
    appointmentFor: "",
    comments: "",
    seller: "",
    errors: {},
    show: "",
    isAppointmentFor: APPOINTMENT_LIST[1],
  });

  //user details fill when the user login
  useEffect(() => {
    if (userDetails?.name) {
      setFormData((preve) => {
        return {
          ...preve,
          companyName: userDetails?.companyName,
          name: userDetails?.name,
          mobileNumber: userDetails?.mobile,
          email: userDetails?.email,
        };
      });
    }
  }, []);

  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const fetchCountries = React.useCallback(() => {
    let obj = {
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, function (err, data) {
      if (data?.code !== "OK") return;
      if (err && err.data?.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data?.code === "OK") {
        setCountry(data?.data);
      }
    });
  }, []);

  const fetchSellers = React.useCallback(() => {
    let obj = {
      ...FETCH_SELLERS,
    };
    UtilService.callApi(obj, function (err, data) {
      if (data?.code !== "OK") return;

      if (err && err.data?.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data?.code === "OK") {
        data.data = data.data.map((d) => {
          d.name = UtilService.getFullName(d);
          return d;
        });
        setSeller(data.data);
      }
    });
  }, []);

  const fetchShows = React.useCallback(() => {
    let obj = {
      ...GET_MASTERS,
      request: { masters: ["SHOW_TYPE"] },
    };
    UtilService.callApi(obj, function (err, data) {
      if (data?.code !== "OK") return;
      if (err && err.data?.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data?.code === "OK") {
        setShowType(data.data?.SHOW_TYPE);
        // formData.shows = data.data.SHOW_TYPE || [];
      }
    });
  }, []);

  const onTimeChange = (time, timeString) => {
    setFormData({
      ...formData,
      time: time && time,
      timeString:
        timeString &&
        formData.date.split("T")[0] +
          "T" +
          moment(time).toISOString().split("T")[1],
    });
  };

  // const fetchStates = (countryId) => {
  //   let obj = {
  //     ...GET_STATE_LIST,
  //     request: {
  //       country: countryId,
  //     },
  //   };
  //   UtilService.callApi(obj, function (err, data) {
  //     if (err && err.data?.code === "E_BAD_REQUEST") {
  //       return OpenNotification({
  //         type: "error",
  //         title: err.data.message,
  //       });
  //     } else if (data && data?.code === "OK") {
  //       setFormData({
  //         ...formData,
  //         states: data.data || [],
  //       });
  //     }
  //   });
  // };

  // const fetchCities = (stateId, countryId) => {
  //   let obj = {
  //     ...GET_CITY_LIST,
  //     request: {
  //       state: stateId,
  //       country: countryId,
  //     },
  //   };
  //   UtilService.callApi(obj, function (err, data) {
  //     if (err && err.data?.code === "E_BAD_REQUEST") {
  //       return OpenNotification({
  //         type: "error",
  //         title: err.data.message,
  //       });
  //     } else if (data && data?.code === "OK") {
  //       if (stateId) {
  //         setFormData({
  //           ...formData,
  //           cities: [...data.data, { name: "Others", id: "-" }] || [],
  //         });
  //       } else if (countryId) {
  //         setFormData({
  //           ...formData,
  //           cities: [...data.data, { name: "Others", id: "-" }] || [],
  //         });
  //       }
  //     }
  //   });
  // };

  const handleCountryChange = (e) => {
    let countryId = country?.filter?.((c) => c.id === e)[0];

    if (country) {
      setFormData(
        {
          ...formData,
          country: countryId ? countryId : {},
        },
        () => {
          // fetchStates(e);
          // fetchCities(null, e);
        },
      );
    }
  };

  // const handleStateChange = (e) => {
  //   let state = formData.states.filter((c) => c.id === e)[0];
  //   if (state) {
  //     formData.city = "";
  //     formData.state = state ? state : {};
  //     setFormData(
  //       {
  //         ...formData,
  //         cities: [],
  //       },
  //       () => {
  //         fetchCities(e);
  //       }
  //     );
  //   }
  // };

  // const handleCityChange = (e) => {
  //   let city = formData.cities.filter((c) => c.id === e)[0];
  //   if (city) {
  //     formData.city = city ? city : {};
  //     setFormData({ ...formData });
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    let reqObj = clone(formData);

    delete reqObj["cities"];
    delete reqObj["countries"];
    delete reqObj["errors"];
    delete reqObj["sellers"];
    delete reqObj["states"];
    delete reqObj["time"];

    reqObj.city = isEmpty(reqObj.city) ? null : reqObj.city.id;
    reqObj.state = isEmpty(reqObj.state) ? null : reqObj.state.id;
    reqObj.country = isEmpty(reqObj.country) ? null : reqObj.country.id;
    reqObj.email = isEmpty(reqObj.email) ? null : [reqObj.email];
    reqObj.mobileNumber = isEmpty(reqObj.mobileNumber)
      ? null
      : [reqObj.mobileNumber];

    reqObj.city === "-" && delete reqObj["city"];
    let obj = {
      ...TAKE_APPOINTMENT,
      request: {
        officeType: reqObj?.isAppointmentFor?.key,
        date: reqObj.timeString,
        mobile: reqObj.mobileNumber ? reqObj.mobileNumber.toString() : "",
        address: reqObj.address,
        city: reqObj.city,
        cityText: reqObj.cityText,
        state: reqObj.state,
        country: reqObj.country,
        email: reqObj.email ? reqObj.email.toString() : "",
        fName: reqObj.name,
        compName: reqObj.companyName,
        in: reqObj.date,
        compAddress: isEmpty(reqObj.appointmentFor)
          ? undefined
          : reqObj.appointmentFor,
        showAddress: isEmpty(reqObj.show) ? undefined : reqObj.show,
        comments: reqObj.comments,
        seller: reqObj.seller,
      },
    };

    if (handleValidation()) {
      setIsLoading(true);
      UtilService.callApi(obj, function (err, data) {
        setIsLoading(false);
        if (err && err.data) {
          if (err) {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
          if (
            [
              "E_DUPLICATE_EMAIL",
              "E_DUPLICATE",
              "E_DUPLICATE_MOBILE",
              "E_DUPLICATE_USERNAME",
            ].includes(err.data?.code)
          ) {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
        }

        if (data && data?.code === "OK") {
          OpenNotification({
            type: "success",
            title: data.message,
          });
          handleCancel(e);
        }
      });
    }
  };

  const handleValidation = () => {
    let errors = formData.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFields = [];
    if (!formData["name"] || isEmpty(formData["name"])) {
      blankField.push("Name");
      formIsValid = false;
      errors["name"] = true;
    }
    if (formData["name"]) {
      if (!formData["name"].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push("name");
        formIsValid = false;
        errors["name"] = true;
      } else {
        errors["name"] = undefined;
      }
    }
    if (!formData["companyName"] || isEmpty(formData["companyName"])) {
      blankField.push("Company Name");
      formIsValid = false;
      errors["companyName"] = true;
    }
    if (formData["companyName"]) {
      if (!formData["companyName"].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push("Company Name");
        formIsValid = false;
        errors["companyName"] = true;
      } else {
        errors["companyName"] = undefined;
      }
    }
    if (!formData["email"] || isEmpty(formData["email"])) {
      blankField.push("Email Address");
      formIsValid = false;
      errors["email"] = true;
    }
    if (formData["email"]) {
      if (
        !formData["email"].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        invalidFields.push("Email");
        formIsValid = false;
        errors["email"] = true;
      } else {
        errors["email"] = undefined;
      }
    }
    // if (!formData["address"] || isEmpty(formData["address"])) {
    //   blankField.push("Address");
    //   formIsValid = false;
    //   errors["address"] = true;
    // }
    // if (formData["address"]) {
    //   if (
    //     !formData["address"].match(/^[a-zA-Z0-9!,@#\$%\^\&*\)\(+=._-]{6,}$/g)
    //   ) {
    //     invalidFields.push("address");
    //     formIsValid = false;
    //     errors["address"] = true; //"Please enter valid Address .";
    //   } else {
    //     errors["address"] = undefined;
    //   }
    // }

    // if (!formData["demand"] || isEmpty(formData["demand"])) {
    //   blankField.push("Demand");
    //   formIsValid = false;
    //   errors["demand"] = true;
    // }

    // if (!formData["country"] || isEmpty(formData["country"])) {
    //   blankField.push("Country");
    //   formIsValid = false;
    //   errors["country"] = true;
    // }
    // if (!formData["state"] || isEmpty(formData["state"])) {
    //   blankField.push("State");
    //   formIsValid = false;
    // }
    // if (!formData["city"] || isEmpty(formData["city"])) {
    //   blankField.push("City");
    //   formIsValid = false;
    //   errors["city"] = true;
    // }
    if (!formData["date"] || isEmpty(formData["date"])) {
      blankField.push("Date");
      formIsValid = false;
      errors["date"] = true;
    }
    if (!formData["time"] || isEmpty(formData["time"])) {
      blankField.push("Time");
      formIsValid = false;
      errors["time"] = true;
    }
    if (!formData.mobileNumber || isEmpty(formData.mobileNumber)) {
      blankField.push("Mobile Number");
      formIsValid = false;
      errors["mobileNumber"] = true;
    }
    if (formData.city.id === "-") {
      if (!formData["cityText"] || isEmpty(formData["cityText"])) {
        blankField.push("City Name");
        formIsValid = false;
        errors["cityText"] = true;
      } else if (
        formData["cityText"] &&
        !formData["cityText"].match(/^[a-zA-Z ]+$/)
      ) {
        invalidFields.push("Invalid City Name");
        errors["cityText"] = true;
        formIsValid = false;
      } else {
        errors["cityText"] = undefined;
      }
    }

    if (
      formData.mobileNumber &&
      (formData.mobileNumber.length < 10 || formData.mobileNumber.length > 15)
    ) {
      invalidFields.push("Mobile Number");
      formIsValid = false;
      errors["mobileNumber"] = true;
    }
    // if (isEmpty(formData["seller"])) {
    //   blankField.push("Sales Person");
    //   formIsValid = false;
    //   errors["seller"] = true;
    // }
    if (formData.isAppointmentFor.key === APPOINTMENT_LIST[0].key) {
      if (isEmpty(formData["show"])) {
        blankField.push("Show");
        formIsValid = false;
        errors["show"] = true;
      } else {
        errors["show"] = undefined;
      }
    } else {
      if (isEmpty(formData["appointmentFor"])) {
        blankField.push("Country For Appointment");
        formIsValid = false;
        errors["appointmentFor"] = true;
      } else {
        errors["appointmentFor"] = undefined;
      }
    }

    if (isEmpty(formData["seller"])) {
      blankField.push("Sales Person");
      formIsValid = false;
      errors["seller"] = true;
    } else {
      errors["seller"] = undefined;
    }

    if (isEmpty(formData["country"])) {
      blankField.push("Country");
      formIsValid = false;
      errors["country"] = true;
    } else {
      errors["country"] = undefined;
    }

    // if (isEmpty(formData["state"])) {
    //   blankField.push("State");
    //   formIsValid = false;
    //   errors["state"] = true;
    // } else {
    //   errors["state"] = undefined;
    // }

    // if (isEmpty(formData["city"])) {
    //   blankField.push("City");
    //   formIsValid = false;
    //   errors["city"] = true;
    // } else {
    //   errors["city"] = undefined;
    // }

    if (blankField.length > 0) {
      let arr = blankField;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    if (invalidFields.length > 0) {
      let arr = invalidFields;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    setFormData({ ...formData, errors: errors });
    return formIsValid;
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormData({
      companyName: "",
      name: "",
      mobileNumber: "",
      email: "",
      country: {},
      state: {},
      city: {},
      cityText: "",
      address: "",
      date: new Date().toISOString(),
      time: undefined,
      timeString: "",
      appointmentFor: "",
      comments: "",
      seller: "",
      errors: {},
      show: "",
      isAppointmentFor: APPOINTMENT_LIST[1],
    });

    // fetchCountries();
    // fetchSellers();
    // fetchShows();
  };

  React.useEffect(() => {
    fetchCountries();
    fetchSellers();
    fetchShows();
  }, [fetchCountries, fetchSellers, fetchShows]);

  const getDisabledHours = () => {
    let hours = [];
    for (let i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const getDisabledMinutes = (selectedHour) => {
    let minutes = [];
    if (selectedHour === moment().hour()) {
      for (let i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  // const getDisabledSeconds = (selectedMinute) => {
  //   let seconds = [];
  //   if (selectedMinute === moment().minute()) {
  //     for (let i = 0; i < moment().second(); i++) {
  //       seconds.push(i);
  //     }
  //   }
  //   return seconds;
  // };

  return (
    <>
      <StaticHeader />
      <div className={`staticBoxMain ${isLoading ? `loading-indicator` : ''}`}>
        <div className="d-flex">
          <label
            className="commonLabel"
            style={{ width: "50%", textAlign: "end", paddingRight: "5px" }}
          >
            Appointment For
          </label>
          <Radio.Group
            value={formData.isAppointmentFor}
            defaultValue={true}
            onChange={(e) => handleChange("isAppointmentFor", e.target.value)}
            className="d-flex"
          >
            {APPOINTMENT_LIST.map((appointment) => {
              return <Radio value={appointment}>{appointment.value}</Radio>;
            })}
          </Radio.Group>
        </div>
        <div className="statiContentBox">
          <Heading
            title={`Appointment For ${formData.isAppointmentFor.value}`}
            className="staticMainTitle text-center"
          />
          <div>
            <div className="appointmentBookForm">
              <Heading
                title="For New Clients"
                className="popupInnerTitle mb-50 text-center"
              />
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <div className="d-flex flex-wrap">
                  <InputBlock
                    label="Company Name*"
                    placeholder="Company Name"
                    value={formData.companyName}
                    onChange={(e) =>
                      handleChange("companyName", e.target.value)
                    }
                    hasError={formData.errors["companyName"] ? true : false}
                  />
                  <InputBlock
                    label="Name*"
                    placeholder="Name"
                    value={formData.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    hasError={formData.errors["name"] ? true : false}
                  />
                  <InputBlock
                    label="Mobile Number*"
                    placeholder="Mobile Number"
                    type="number"
                    value={formData.mobileNumber}
                    onChange={(e) =>
                      handleChange("mobileNumber", e.target.value)
                    }
                    onKeyPress={handleMobileNumber}
                    hasError={formData.errors["mobileNumber"] ? true : false}
                  />
                  <InputBlock
                    label="Email*"
                    placeholder="Email"
                    value={formData.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    hasError={formData.errors["email"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="Country"
                    value={
                      !isEmpty(formData.country)
                        ? formData.country.name
                        : "Country"
                    }
                    selectValue={country}
                    onChange={(e) => handleCountryChange(e)}
                    label="Select Country*"
                    showSearch
                    hasError={formData.errors["country"] ? true : false}
                  />
                  {/* <SelectOption
                    defaultValue="State"
                    value={
                      !isEmpty(formData.state) ? formData.state.name : "State"
                    }
                    selectValue={formData.states}
                    onChange={(e) => handleStateChange(e)}
                    label="Select State*"
                    showSearch
                    hasError={formData.errors["state"] ? true : false}
                  /> */}
                  {/* <SelectOption
                    defaultValue="City"
                    value={
                      !isEmpty(formData.city) ? formData.city.name : "City"
                    }
                    selectValue={formData.cities}
                    onChange={(e) => handleCityChange(e)}
                    showSearch
                    label="Select City*"
                    hasError={formData.errors["city"] ? true : false}
                  />
                  {formData.city.id === "-" && (
                    <InputBlock
                      // type="text"
                      label="City*"
                      placeholder="City"
                      value={formData.cityText}
                      onChange={(e) => handleChange("cityText", e.target.value)}
                      hasError={formData.errors["cityText"] ? true : false}
                    />
                  )} */}
                  {/* <InputBlock
                    label="Address*"
                    placeholder="Address"
                    value={formData.address}
                    onChange={(e) => handleChange("address", e.target.value)}
                    hasError={formData.errors["address"] ? true : false}
                  /> */}
                  {formData.isAppointmentFor.key === APPOINTMENT_LIST[0].key ? (
                    <SelectOption
                      defaultValue="Show"
                      onChange={(e) => {
                        handleChange("show", e);
                      }}
                      value={formData.show ? formData.show : "Show"}
                      selectValue={showType}
                      showSearch
                      label="Select Show*"
                      hasError={formData.errors["show"] ? true : false}
                    />
                  ) : (
                    <SelectOption
                      selectValue={companyDetail.Appointcountry}
                      label="Country For Appointment*"
                      onChange={(e) => handleChange("appointmentFor", e)}
                      value={
                        formData.appointmentFor
                          ? formData.appointmentFor
                          : "Country For Appointment"
                      }
                      showSearch
                      hasError={
                        formData.errors["appointmentFor"] ? true : false
                      }
                    />
                  )}

                  <DatePicker
                    label="Preferred Date*"
                    disabledDate={(current) => {
                      return current && current <= moment().startOf("day");
                    }}
                    expiryDate={formData.date}
                    placeholder="Date"
                    format="ll"
                    handleChange={(date) =>
                      setFormData({
                        ...formData,
                        date: date && date.toISOString(),
                      })
                    }
                    hasError={formData.errors["date"] ? true : false}
                  />
                  <TimerPicker
                    label="Preferred Time*"
                    value={formData.time}
                    disabledHours={() => getDisabledHours()}
                    disabledMinutes={(e) => getDisabledMinutes(e)}
                    // disabledSeconds={(e) => getDisabledSeconds(e)}
                    onChange={onTimeChange}
                    format={"HH:mm A"}
                    hasError={formData.errors["time"] ? true : false}
                  />
                  <SelectOption
                    defaultValue="Sales Person"
                    onChange={(e) => {
                      handleChange("seller", e);
                    }}
                    value={formData.seller ? formData.seller : "Sales Person"}
                    selectValue={sellers}
                    showSearch
                    label="Select Sales Person*"
                    hasError={formData.errors["seller"] ? true : false}
                  />
                  <TextArea
                    className="width-66"
                    label="Comments"
                    placeholder="Comments"
                    value={formData.comments}
                    onChange={(e) => handleChange("comments", e.target.value)}
                    hasError={formData.errors["comments"] ? true : false}
                  />
                </div>

                <div className="d-flex appointmentButton mt-50">
                  <button
                    onClick={(e) => handleSubmit(e)}
                    className="commonButton"
                  >
                    Save
                  </button>
                  <button onClick={handleCancel} className="commonOutline">
                    Reset
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookAppointment;
