/* eslint-disable */
import React from "react";
import MainFilterTop from "./FilterItem";
import { Menu, Dropdown } from "antd";
const menu = (
  <Menu>
    <Menu.Item key="0">
      <span>0.500-0.589</span>
      {/* <span className="filterClose">
        <img src={require("../../../assets/svg/DiamondList/close.svg")} alt="" />
      </span> */}
    </Menu.Item>
    <Menu.Item key="1">
      <span>0.500-0.589</span>
      <span className="filterClose">
        <img
          src={require("../../../assets/svg/DiamondList/close.svg")}
          alt=""
        />
      </span>
    </Menu.Item>
    <Menu.Item key="1">
      <span>0.500-0.589</span>
      <span className="filterClose">
        <img
          src={require("../../../assets/svg/DiamondList/close.svg")}
          alt=""
        />
      </span>
    </Menu.Item>
    <Menu.Item key="1">
      <span>0.500-0.589</span>
      <span className="filterClose">
        <img
          src={require("../../../assets/svg/DiamondList/close.svg")}
          alt=""
        />
      </span>
    </Menu.Item>
    <Menu.Item key="1">
      <span>IF</span>
      <span className="filterClose">
        <img
          src={require("../../../assets/svg/DiamondList/close.svg")}
          alt=""
        />
      </span>
    </Menu.Item>
    <Menu.Item key="1">
      <span>M</span>
      <span className="filterClose">
        <img
          src={require("../../../assets/svg/DiamondList/close.svg")}
          alt=""
        />
      </span>
    </Menu.Item>
  </Menu>
);

const FilterTop = (props) => {
  const { ids } = props;
  return (
    <>
      <div className="searchInnerFilterMain">
        {ids.map((i, ind) => {
          return (
            // <Dropdown trigger={["click"]} overlay={menu} overlayClassName="filterMainDropdown">
            <div onClick={() => props.handleFilterChange(i.id)}>
              <MainFilterTop
                handleRemoveFilter={props.handleRemoveFilter}
                id={i.id}
                title={`Filter ${ind + 1}`}
                activeClass={i.isActive ? `active` : ""}
              />
            </div>
            // </Dropdown>
          );
        })}
      </div>
    </>
  );
};
export default FilterTop;
