/* eslint-disable */
import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useTable } from "react-table";
import { Drawer, Tooltip } from "antd";
import DiamondDetail from "../DiamondDetail";
import DiamondListingHead from "./ListTableHeaderBack";
import useRowSelect from "./../../util/useRowSelect";
import useFilters from "./../../util/useFilters";
import useSortBy from "./../../util/useSortBy";
import CheckBox from "../common/CheckBox";
import Status from "../common/DiamondListing/Status";
import { getPath, LISTINGPAGES, getColumn } from "./DiamondListFunctions";
import TableGrouping from "../common/DiamondListing/TableGrouping";
import {
  LAB_LINKS,
  FILE_URLS,
  MEDIA_URL,
  DIAMOND_BID,
  VIEW_DNA_URL,
} from "../../constants/Common";
import moment from "moment";
import { formatNumber } from "../common/DiamondListing/SelectStone";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import { catchError, isArray } from "util/utils";
import { isNotEmpty } from "util/utils";
import { isNumber } from "util/utils";
import { CREATE_SEENSTONE } from "../../constants/apiConstant";
import UtilService from "../../services/util";

export const canSortCommon = {
  stoneId: "Stone No",
  shpNm: "Shape",
  crt: "Carat",
  colNm: "Color",
  clrNm: "Clarity",
};
export const LIMIT = 250;
export const FILTER_COLUMNS = [
  "colNm",
  "fluNm",
  "shpNm",
  "lbNm",
  "clrNm",
  "locNm",
  "shdNm",
  "cutNm",
  "polNm",
  "symNm",
  "brlncyNm",
  "blkTblNm",
  "blkSdNm",
  "wSdNm",
  "wTblNm",
  "opCrwnNm",
  "opPavNm",
  "opTblNm",
  "eClnNm",
  "girdleStr",
  "cultNm",
  "hANm",
  "isSVS",
];
export const floatkeys = [
  "amt",
  "depPer",
  "ratio",
  "crt",
  "rapAvg",
  "pAng",
  "pHgt",
  "cHgt",
  "back",
  "cAng",
  "fnDis",
  "height",
  "width",
  "length",
  "grdlPer",
  "strLn",
  "tblPer",
  "lwrHal",
  "ctPr",
];
const NOCHECKIDS = [
  "Details",
  "Action",
  "quote",
  "expBack",
  "hours",
  "bidPricePerCarat",
  "note",
  "finalquote",
  "hndVideo"
];
export const roundkeys = ["amt", "rap"];
export const DISPLAY_TOTAL = ["ctPr", "amt", "rap", "crt", "fnCtpr", "fnAmt"];
const ALT_KEYS = [
  "lbNm",
  "rptNo",
  "crt",
  "colNm",
  "clrNm",
  "cutNm",
  "tblPer",
  "depPer",
  "length",
  "width",
  "height",
  "cAng",
  "cHgt",
  "pAng",
  "pHgt",
  "grdlPer",
  "polNm",
  "symNm",
  "back",
  "rap",
  "fluNm",
  "isSVS",
];
const makeSticky = (table, options = {}) => {
  const stickyIndex = options.index;
  const rows = table?.querySelectorAll?.("tr");
  const cellSlice = Array.prototype.slice.call(
    rows[0].children,
    0,
    stickyIndex,
  );
  const widthList = Array.prototype.map.call(
    cellSlice,
    (cell) => cell?.getBoundingClientRect()?.width,
  );
  const leftOffsetList = widthList.map((_, index) => {
    let output = options.offset ?? -0;
    for (let i = 0; i < index; i++) output = output + widthList[i];
    return output;
  });

  table.style["border-collapse"] = "separate";
  table.style["border-spacing"] = 0;
  Array.prototype.forEach.call(rows, (row) => {
    const cellList = Array.prototype.slice.call(row.children, 0, stickyIndex);
    Array.prototype.forEach.call(cellList, (cell, index) => {
      if (cell.classList.contains("groupingTableAmount", "activebg")) return;

      cell["data-orig-z-index"] =
        cell["data-orig-z-index"] ?? cell.style["z-index"] ?? 0;
      const zIndex = isNumber(Number(cell["data-orig-z-index"]))
        ? Number(cell["data-orig-z-index"])
        : 0;

      cell.style["position"] = "sticky";
      cell.style["left"] = `${leftOffsetList[index]}px`;
      cell.style["z-index"] = cell.tagName === "TD" ? zIndex + 1 : zIndex + 101;
      cell.style["background"] = cell.tagName === "TD" ? "#fff" : "";
      // cell.classList.add('cell-sticky');

      if (index + 1 === cellList?.length)
        cell.classList.add("cell-shadow-left");
    });
  });
};

const headerProps = (props, { column }) => getStyles(props, column, "header");
const cellProps = (props, { cell }) => getStyles(props, cell.column, "cell");
const getStyles = (props, item, type) => [
  props,
  {
    style: {
      textAlign: item.cellClass
        ? item.cellClass.replace("text-", "")
        : "center",
      width: (item.width || "100") + "px",
      fontWeight:
        type === "cell" &&
          (item.id === "stoneId" ||
            item.id === "vStnId" ||
            item.id === "colNm" ||
            item.id === "shpNm" ||
            item.id === "crt" ||
            item.id === "clrNm" ||
            item.id === "shdNm" ||
            item.id === "rap" ||
            item.id === "back" ||
            item.id === "ctPr" ||
            item.id === "cutNm" ||
            item.id === "polNm" ||
            item.id === "symNm" ||
            item.id === "brlncyNm" ||
            item.id === "fluNm" ||
            item.id === "lbNm" ||
            item.id === "srNo" ||
            item.id === "rptNo" ||
            item.id === "locNm" ||
            item.id === "dna" ||
            item.id === "amt")
          ? "600"
          : "",
      color:
        type === "cell" &&
          (item.id === "lbNm" ||
            item.id === "dna" ||
            item.id === "isSVS" ||
            item.id === "isTrcr" ||
            item.id === "isXray")
          ? "#008cba"
          : "",
    },
  },
];
const HitCounter = (diamondId, vStnId, key, value = undefined) => {
  let obj = CREATE_SEENSTONE;

  obj.request = {
    diamondId: diamondId,
    vStnId: vStnId,
  };
  obj.request[key] = value;

  UtilService.callApi(obj);
};
const Table = React.memo((props) => {
  let {
    data = [],
    columns = false,
    handleSort = () => { },
    nocheck = false,
    nostatus = false,
    noCheckBox = false,
    loading = false,
    nodots = false,
    noGrp,
    currentType,
    currentBidType,
    areAllChecked = false,
    defaultChecked = [],
    FilterOption = true,
    canSort = true,
    pairStkNo = true,
  } = props;
  // data = data.map(dt=>({...dt,back:dt.oldBack,ctPr:dt.oldCtPr,amt:dt.oldAmt}))

  const [visible, setVisible] = useState(false);
  const [detail, setDetail] = useState(null);
  const [copyes, setCopyes] = useState("Copy Packet No.");
  const tableRef = React.useRef();

  if (isArray(data)) {
    data = data.sort((a, b) => {
      return a.pairStkNo - b.pairStkNo;
    });
    data.map((rp, i) => {
      // rp.pairStkNo='1'
      if (rp.pairStkNo) {
        if (data[i - 1] && data[i - 1].pairStkNo === rp.pairStkNo) {
          rp.pairStkNo = undefined;
          rp.pairStkNoList = data[i - 1].pairStkNo;
        }
      }
    });
  }

  const finalColumns = useMemo(() => {
    let primaryColumns = getColumn() || [];
    if (getPath() !== LISTINGPAGES.MATCHPAIR)
      primaryColumns.map((col) => ({ ...col, canSort: !!col.sort }));
    const allColumns = columns || primaryColumns;
    if (getPath() === LISTINGPAGES.ACCOUNTING_LIST) {
      return allColumns;
    } else {
      return nocheck
        ? allColumns
        : [
          {
            id: "selection",
            Header: ({ data: displayedRows, ...rest }) => {
              return (
                <CheckboxCustom
                  displayedRows={rest.filteredRows.map((r) => r.original)}
                  isHeader={true}
                  nostatus={nostatus}
                />
              );
            },
            Cell: ({ row, ...rest }) => (
              <CheckboxCustom
                pairStkNo={pairStkNo}
                nostatus={nostatus}
                row={row.original}
                isHeader={false}
                currentBidType={currentBidType}
                disabled={"groupNo" in row.original}
                displayedRows={rest.filteredRows.map((r) => r.original)}
              />
            ),
          },
          ...allColumns,
        ];
    }
  }, [columns, nocheck, nostatus, pairStkNo, currentBidType]);

  const isMultiSortEvent = useCallback(() => true, []);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state: { sortBy, filters },
    headerGroups,
    rows,
    setSortBy,
    toggleSortBy,
    filteredRows,
  } = useTable(
    {
      columns: finalColumns,
      data,
      manualSortBy: true,
      isMultiSortEvent,
      disableSortBy: !canSort,
    },
    useFilters,
    useSortBy,
  );

  const CheckboxCustom = React.memo(
    ({
      isHeader,
      row = {},
      displayedRows,
      disabled,
      nostatus,
      pairStkNo,
      currentBidType,
    }) => {
      const {
        isRowChecked,
        isHeaderChecked,
        toggleRowSelection,
        toggleAllRowSelection,
      } = useRowSelect(currentType);

      useEffect(() => {
        if (areAllChecked || row.isDefaultChecked) {
          toggleRowSelection(row, true);
        }
      }, [row]); //toggleRowSelection - Bug continues rendring

      const isChecked = useMemo(() => {
        if (!isHeader) {
          return isRowChecked(row.id);
        } else {
          return isHeaderChecked(displayedRows);
        }
      }, [isRowChecked, isHeader, isHeaderChecked, displayedRows, row.id]);

      const handleChange = () => {
        if (row.pairStkNo) {
          let toggleIt = displayedRows.filter(
            (el) =>
              el.pairStkNo === row.pairStkNo ||
              el.pairStkNoList === row.pairStkNo,
          );
          let pass = !isChecked;
          toggleIt.forEach((val) => {
            toggleRowSelection(val, pass);
          });
          return;
        }
        // if (row.grpBy ) {
        //   let toggleIt = displayedRows.filter(
        //     (el) => el.grpBy === row.grpBy
        //   );
        //   let pass = !isChecked;
        //   toggleIt.forEach((val) => {
        //     toggleRowSelection(val, pass);
        //   });
        //   return;
        // }
        if (!isHeader) {
          toggleRowSelection(row, !isChecked);
        } else {
          toggleAllRowSelection(displayedRows);
        }
      };

      const getBidType = () => {
        const currentDateTime = moment().toISOString();
        if (currentType === "/my-bid") {
          if (
            currentDateTime >= row?.extraValue?.blndBidStrTm &&
            currentDateTime <= row?.extraValue?.blndBidEndTm
          ) {
            return DIAMOND_BID.TYPE.BLIND;
          } else if (
            row?.bidTType === 3 &&
            currentDateTime >= row?.extraValue?.mulBidStrTm &&
            currentDateTime <= row?.extraValue?.mulBidEndTm
          ) {
            return DIAMOND_BID.TYPE.MULTIPLE;
          } else if (
            row?.bidTType === 2 &&
            currentDateTime >= row?.extraValue?.lABidStrTm &&
            currentDateTime <= row?.extraValue?.lABidEndTm
          ) {
            return DIAMOND_BID.TYPE.OPEN;
          }
        } else {
          if (
            currentDateTime >= row?.blndBidStrTm &&
            currentDateTime <= row?.blndBidEndTm
          ) {
            return DIAMOND_BID.TYPE.BLIND;
          } else if (
            currentDateTime >= row?.lABidStrTm &&
            currentDateTime <= row?.lABidEndTm
          ) {
            return DIAMOND_BID.TYPE.OPEN;
          } else if (
            currentDateTime >= row?.mulBidStrTm &&
            currentDateTime <= row?.mulBidEndTm
          ) {
            return DIAMOND_BID.TYPE.MULTIPLE;
          }
        }
      };

      return (
        <div className="selectActionIcon">
          {!nostatus && (
            <Status
              currentBidType={
                currentType === "/new-arrival-bid-it" ||
                  currentType === "bidStones" ||
                  currentType === "FinalCalculation" ||
                  currentType === "/my-bid"
                  ? getBidType()
                  : currentBidType
              }
              status={isHeader ? "all" : row.wSts}
              winloss={getPath() === LISTINGPAGES.MYBID ? row.status : null}
            />
          )}
          {!noCheckBox && (!row || (row && !row.pairStkNoList)) && (
            <div
              className={
                "selectActionIconWrapper" +
                (pairStkNo && row.pairStkNo ? " pairStkNo" : "") +
                (row.altCert ? " doubleRow" : "")
              }
              style={
                pairStkNo && row.pairStkNo
                  ? { bottom: row.altCert ? "-31px" : "-14px" }
                  : {}
              }
            >
              <CheckBox
                disabled={
                  data[0]?.bidType === 0 && window.shortPath() === "my-bid"
                    ? true
                    : disabled
                }
                key={`selection_${isHeader ? "header" : row.id}`}
                checked={isChecked}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      );
    },
  );

  const freezeColumns = React.useCallback(() => {
    // await new Promise((r) => setTimeout(r, 500));
    void catchError(() => {
      const index = columns.findIndex((col) => col.id === "vStnId");
      if (index > -1) {
        makeSticky(tableRef.current, { index: index + 2 });
      }
    });
  }, [columns]);

  React.useLayoutEffect(() => {
    freezeColumns();
  });
  //handle sorting operation
  useEffect(() => {
    let sortArr = sortBy.map((sort) => ({
      [sort.id]: sort.desc ? "DESC" : "ASC",
    }));
    handleSort(sortArr);
  }, [handleSort, sortBy]);

  //common functions
  const clickHandler = useCallback(
    (e, row, cell) => {
      if (cell.column.id === "selection") return;
      if (cell.column.link && cell.value) {
        let field = cell.column.link.slice(
          cell.column.link.indexOf("$") + 1,
          cell.column.link.length,
        );
        field = field.slice(0, field.indexOf("$"));
        let link = cell.column.link.replace(
          "$" + field + "$",
          row.original[field],
        );
        window.open(link);
      } else if (
        cell.column.id === "dna" &&
        getPath() === LISTINGPAGES.CONFIRM
      ) {
        window.open(`/dna/${row.original.vStnId}`);
      } else if (cell.column.id === "dna") {
        HitCounter(row.original.id, row.original.vStnId, 'dna', true);
        window.open(`${VIEW_DNA_URL}/dna/${row.original.vStnId}`);
        //setVisible(row.original)
      }
      //  else if (
      //   cell.column.id === "lbNm" &&
      //   row.original.lbNm &&
      //   row.original.rptNo
      // ) {
      //   window.open(
      //     LAB_LINKS[row.original.lbNm.toUpperCase()].replace(
      //       "***",
      //       row.original.rptNo,
      //     ),
      //   );
      // } else if (
      //   cell?.column?.id === "isSVS" &&
      //   row?.original?.isSVS &&
      //   row?.original?.rptNo
      // ) {
      //   window.open(
      //     LAB_LINKS[row?.original?.lbNm.toUpperCase()].replace(
      //       "***",
      //       row?.original?.rptNo,
      //     ),
      //   );
      // } 
      else if (cell?.column?.id === "isTrcr" && row?.original?.isTrcr) {
        if (isNotEmpty(cell?.row?.original)) {
          if (isNotEmpty(cell?.row?.original?.trcrNo)) {
            window.open(
              LAB_LINKS["TRCR"]?.replace("***", cell?.row?.original?.trcrNo),
            );
          } else {
            if (isNotEmpty(cell?.row?.original?.altCert)) {
              window.open(
                LAB_LINKS["TRCR"]?.replace(
                  "***",
                  cell?.row?.original?.altCert[0]?.trcrNo,
                ),
              );
            }
          }
        }
      } 
      // else if (cell.column.id === "type2" && !isEmpty(row.original.type2)) {
      //   window.open(`${MEDIA_URL}/TYPE_IIA/${row.original.rptNo}.pdf`);
      // } 
      else if ((cell.column.id === 'video' || cell.column.id === 'hndVideo') && getPath() === LISTINGPAGES.CONFIRM) {
        if (e.target.src?.includes('video')) window.open(`${VIEW_DNA_URL}/diamondfullview/${row.original.vStnId}`);
        else if (e.target.src?.includes('hand')) window.open(`${VIEW_DNA_URL}/diamondVideoview/${row.original.vStnId}`);
      } else if ((cell.column.id === 'video' || cell.column.id === 'hndVideo') && e.target.src) {
        if (e.target.src.includes('video')) {
          window.open(`${VIEW_DNA_URL}/diamondfullview/${row.original.vStnId}`);
          HitCounter(row?.original?.id, row?.original?.vStnId, 'b2bvideo', true);
        }
        else if (e.target.src.includes('hand')) {
          window.open(`${VIEW_DNA_URL}/diamondVideoview/${row.original.vStnId}`);
          HitCounter(row?.original?.id, row?.original?.vStnId, 'nakedeye', true);
        }
      } else if (!NOCHECKIDS.includes(cell.column.id)) {
        if (props.nocheck || props.noCheckBox) return;
        let parentNode = e.currentTarget.parentNode;
        if (parentNode) {
          let checkboxRef = parentNode.getElementsByTagName("input");
          if (checkboxRef && checkboxRef.length > 0) {
            checkboxRef[0].click();
          }
        }
      }
    },
    [props.noCheckBox, props.nocheck],
  );

  const finalFilteredRows = React.useMemo(
    () => filteredRows.map((r) => r.original),
    [filteredRows],
  );

  return (
    <>
      <table
        ref={tableRef}
        {...getTableProps({ className: `${currentType}-table` })}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(headerProps)}
                  style={{ width: (column.width || 100) + "px" }}
                >
                  {column.id === "selection"
                    ? column.render("Header")
                    : props.cheque
                      ? column.render("Header")
                      : column.id !== "action" &&
                      (getPath() === LISTINGPAGES.ACCOUNTING_LIST ||
                        getPath() === LISTINGPAGES.COMING_SOON ? (
                        <DiamondListingHead column={column} />
                      ) : (
                        <DiamondListingHead
                          toggleSortBy={toggleSortBy}
                          setSortBy={setSortBy}
                          sortBy={sortBy}
                          column={column}
                          filters={filters}
                          nodots={nodots}
                          FilterOption={FilterOption}
                        />
                      ))}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading
            ? null
            : !!rows.length &&
            rows.map((row, index) => {
              !row.getRowProps && prepareRow(row);
              return (
                <>
                  {!noGrp && (
                    <>
                      {row.original.isMatchHeader && (
                        <>
                          <TableGrouping
                            displayTotal={DISPLAY_TOTAL.filter((el) =>
                              finalColumns.find((c) => c.id === el),
                            )}
                            multipleCheckBox
                            row={row.original}
                          >
                            <CheckboxCustom
                              nostatus={true}
                              displayedRows={
                                groupBy(finalFilteredRows, "groupNo")[
                                row.original.groupNo
                                ]
                              }
                              isHeader={true}
                            />
                          </TableGrouping>
                        </>
                      )}
                      {(row.original.isConfirmHeader ||
                        row.original.isUpcomingHeader ||
                        row.original.isBidHeader ||
                        row.original.isOfferHeader) && (
                          <TableGrouping row={row.original} />
                        )}
                      {row.original.isOfficeHeader && (
                        <TableGrouping row={row.original} />
                      )}
                    </>
                  )}

                  <tr
                    className={
                      row.original.lbNm !== "GIA"
                        ? row.original.lbNm
                        : "table-main-row"
                    }
                    {...row.getRowProps({
                      "table-row": currentType + row.original.id,
                    })}
                    style={{
                      backgroundColor: `${row?.original?.PaymentDue === true &&
                        window.location.pathname.includes("/account/memo")
                        ? "#f18eaa40"
                        : ""
                        }`,
                    }}
                  >
                    {row.cells.map((cell) => (
                      <MemoizedCell
                        key={cell.column.id + "_cell"}
                        cell={cell}
                        clickHandler={clickHandler}
                        row={row}
                        setDetail={setDetail}
                        copyes={copyes}
                        setCopyes={copyes}
                      />
                    ))}
                  </tr>
                  {!noGrp && (
                    <>
                      {row.original.isMatchFooter && (
                        <TableGrouping
                          // isfooterdisplay
                          displayTotal={DISPLAY_TOTAL.filter((el) =>
                            finalColumns.find((c) => c.id === el),
                          )}
                          columns={finalColumns}
                          row={row.original}
                        />
                      )}
                      {row.original.isMemoFooter &&
                        rows.length - 1 === index && (
                          <TableGrouping
                            // isfooterdisplay
                            displayTotal={DISPLAY_TOTAL.filter((el) =>
                              finalColumns.find((c) => c.id === el),
                            )}
                            columns={finalColumns}
                            row={row.original}
                          />
                        )}
                    </>
                  )}
                </>
              );
            })}
        </tbody>
      </table>
      {visible && (
        <Drawer
          title={false}
          onClose={() => setVisible(false)}
          visible={true}
          className="diamondDetailPopup"
        >
          <DiamondDetail
            diamondPopup={true}
            data={visible}
            onClose={() => setVisible(false)}
          />
        </Drawer>
      )}
      {detail && (
        <Drawer
          title={false}
          onClose={() => setDetail(null)}
          visible={detail}
          className="diamondDetailPopup"
        >
          {detail.i && (
            <img src={FILE_URLS.img.replace("***", detail.i.vStnId)} alt="" />
          )}
          {detail.v && (
            <iframe
              src={FILE_URLS.videoFile.replace("***", detail.v.vStnId)}
              height="100%"
              width="100%"
            />
          )}
          {detail.c && (
            <iframe
              src={FILE_URLS.certFile.replace("***", detail.c.rptNo)}
              height="100%"
              width="100%"
            />
          )}
        </Drawer>
      )}
    </>
  );
});

function getMap(x, key) {
  return !x
    ? "-"
    : floatkeys.includes(key)
      ? formatNumber(parseFloat(x).toFixed(2))
      : roundkeys.includes(key)
        ? formatNumber(Math.round(x))
        : x;
}

const MemoizedCell = React.memo(
  ({ cell, clickHandler, row, setDetail, copyes, setCopyes }) => {
    // if (cell.column.id === "back" || cell.column.id === "trackDiscount") {
    //   return !row.original.isFcCol && isNumber(cell.value) ? (
    //     <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
    //       {row.original.ctPr < row.original.rap
    //         ? `-${parseFloat(Math.abs(cell.value)).toFixed(2)}`
    //         : cell.value === 0
    //           ? parseFloat(0.0).toFixed(2)
    //           : `+${parseFloat(Math.abs(cell.value)).toFixed(2)}`}
    //     </td>
    //   ) : (
    //     <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
    //       -
    //     </td>
    //   );
    // }
    // if (cell.column.id === "newDiscount") {
    //   return (
    //     <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
    //       {cell.value < 0
    //         ? `-${parseFloat(Math.abs(cell.value)).toFixed(2)}`
    //         : cell.value === 0
    //           ? Math.abs(cell.value)
    //           : `+${parseFloat(Math.abs(cell.value)).toFixed(2)}`}
    //     </td>
    //   );
    // }

    // if(cell.column.id === 'vStnId'){

    //   return (
    //     <td
    //       onClick={(e) => clickHandler(e, row, cell)}
    //     >
    //      {cell.value}
    //       <CopyPacketNo cell={cell} text="Copy Packet No."/>
    //     </td>
    //   );
    // }

    if (
      cell.column.id === "back" &&
      window.location.pathname.includes("/account/memo")
    ) {
      return (
        <td style={{ fontWeight: "600" }}>
          {cell?.value ? (cell?.value).toFixed(2) : "-"}
        </td>
      );
    }

    return (
      <td
        {...cell.getCellProps(cellProps)}
        onClick={(e) => clickHandler(e, row, cell)}
      >
        {
          <div>
            {ALT_KEYS.includes(cell.column.id) &&
              cell.column.id !== "Details" ? (
              <span
                style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
              >
                <span style={{ width: "100%" }}>{cell.render("Cell")}</span>
                {isNotEmpty(row.original.altCert) &&
                  isArray(row.original.altCert) &&
                  row.original.altCert.map((labs) =>
                    cell.column.id === "lbNm" ? (
                      <span
                        onClick={(e) => {
                          window.open(
                            LAB_LINKS[labs.lbNm.toUpperCase()].replace(
                              "***",
                              labs.rptNo,
                            ),
                          );
                          e.stopPropagation();
                        }}
                        style={{ width: "100%" }}
                        className={
                          floatkeys.includes(cell.column.id) ||
                            roundkeys.includes(cell.column.id)
                            ? ""
                            : null
                        }
                      >
                        {getMap(labs[cell.column.id], cell.column.id)}
                      </span>
                    ) : (
                      <span
                        style={{ width: "100%" }}
                        className={
                          floatkeys.includes(cell.column.id) ||
                            roundkeys.includes(cell.column.id)
                            ? ""
                            : null
                        }
                      >
                        {getMap(labs[cell.column.id], cell.column.id)}
                      </span>
                    ),
                  )}
              </span>
            ) : ["hndVideo"].includes(cell.column.id) ?
              (
                <div style={{ display: "flex", justifyContent: 'center' }}>
                  {row.original?.videoFile ? (row.original?.hndVideo ? (
                    <Tooltip title="Naked Eye Image and Videos">
                      <a
                        onClick={() => HitCounter(row?.original?.id, row?.original?.vStnId, 'nakedeye', true)}
                        href={`${VIEW_DNA_URL}/diamondVideoview/${row.original.vStnId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <u style={{ color: "rgb(0, 140, 186)", fontWeight: "600", textDecoration: "none" }}>Naked Eye</u>
                      </a>
                    </Tooltip>
                  ) : ('-')) : ("-")}
                </div>
              )
              :

              (cell.render("Cell"))

            }
          </div>
        }
        {cell.column.id === "Details" && (
          <Resource original={row.original} setDetail={setDetail} />
        )}
      </td>
    );
  },
);

const Resource = React.memo(({ original, setDetail }) => {
  const { img, videoFile, certFile } = original;

  let imageSource = "",
    detailKey = "";
  if (img) {
    imageSource = require("../../assets/svg/camera.svg");
    detailKey = "i";
  } else if (videoFile) {
    imageSource = require("../../assets/svg/video.svg");
    detailKey = "v";
  } else if (certFile) {
    imageSource = require("../../assets/svg/certi.svg");
    detailKey = "c";
  }

  const clickHandler = useCallback(() => {
    setDetail({
      detail: { [detailKey]: original },
    });
  }, [original, setDetail, detailKey]);

  return (
    <img
      style={{ marginRight: "5px" }}
      src={imageSource}
      width="15px"
      alt=""
      onClick={clickHandler}
    />
  );
});

export default Table;
