/* eslint-disable */
import React, { useState, useEffect } from "react";
import StaticHeader from "../StaticHeader";
import FileUpload from "../common/UploadImage/index";
import InputBlock from "../common/InputBlock";
import "../TermsOfUse/staticContent.less";
import Heading from "../common/Heading";
import "./career.less";
import OpenNotification from "../common/CommonButton/OpenNotification";
import {
  FETCH_CAREER_DETAILS,
  CREATE_CAREER,
  UPLOAD_FILE,
} from "../../constants/apiConstant";
import UtilService from "../../services/util";
import useStateWithCallback from "../../hooks/useStateWithCallback";
import { BASE_URL, RESUME_MIME_TYPES } from "../../constants/Common";
import { cloneDeep, isEmpty } from "lodash";
import { notification } from "antd";
import { handleMobileNumber } from "../../services/Commonfunction";

const PDFICON = require("../../../src/assets/svg/pdf-icon.svg");
const DOCICON = require("../../../src/assets/svg/document.svg");

const CareerDetail = () => {
  const [data, setData] = useState(null);
  const [formData, setFormData] = useStateWithCallback({
    name: "",
    email: "",
    applyDate: new Date().toISOString(),
    mobile: "",
    file: "",
    fileName: "",
    errors: {},
  });
  useEffect(() => {
    let obj = {
      ...FETCH_CAREER_DETAILS,
      request: {
        page: 1,
        limit: 1,
        sort: [{ createdAt: "ASC" }],
        filter: {
          //   isDeleted: false,
          //   isActive: true,
          id: window.location.search.split("=")[1],
        },
      },
    };

    UtilService.callApi(obj, function (err, data) {
      if (err && err.data) {
        setData(undefined);
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      }

      if (data && data.code === "OK") {
        setData(data.data.list[0]);
      }
    });
  }, []);

  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleFormReset = () => {
    setFormData({
      name: "",
      email: "",
      applyDate: new Date().toISOString(),
      mobile: "",
      file: "",
      fileName: "",
      errors: {},
    });
  };
  const handleValidation = () => {
    let errors = formData.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFields = [];
    if (!formData["name"] || isEmpty(formData["name"])) {
      blankField.push("Name");
      formIsValid = false;
      errors["name"] = true;
    }
    if (formData["name"]) {
      if (!formData["name"].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push("Name");
        formIsValid = false;
        errors["name"] = true;
      } else {
        errors["name"] = undefined;
      }
    }
    if (!formData["email"] || isEmpty(formData["email"])) {
      blankField.push("Email Address");
      formIsValid = false;
      errors["email"] = true;
    }
    if (formData["email"]) {
      if (
        !formData["email"].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        invalidFields.push("Email");
        formIsValid = false;
        errors["email"] = true;
      } else {
        errors["email"] = undefined;
      }
    }

    if (!formData.file || isEmpty(formData.file)) {
      blankField.push("Resume");
      formIsValid = false;
      errors["file"] = true;
    } else {
      errors["file"] = undefined;
    }

    if (!formData.mobile || isEmpty(formData.mobile)) {
      blankField.push("Mobile Number");
      formIsValid = false;
      errors["mobile"] = true;
    } else if (
      formData.mobile &&
      (formData.mobile.length < 10 || formData.mobile.length > 15)
    ) {
      invalidFields.push("Mobile Number");
      formIsValid = false;
      errors["mobile"] = true;
    } else {
      errors["mobile"] = undefined;
    }

    if (blankField.length > 0) {
      let arr = blankField;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    if (invalidFields.length > 0) {
      let arr = invalidFields;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    setFormData({ ...formData, errors: errors });
    return formIsValid;
  };

  const uploadDoc = (type, e) => {
    const ee = cloneDeep(e);

    var blob = ee.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = function (ev) {
      var arr = new Uint8Array(ev.target.result).subarray(0, 4);
      var header = "";
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (
        !RESUME_MIME_TYPES[blob.type] ||
        (!RESUME_MIME_TYPES[blob.type] &&
          !RESUME_MIME_TYPES[blob.type].includes(header))
      ) {
        return OpenNotification({
          type: "error",
          title:
            "File format is not supported. Please upload .doc, .docx or .pdf file.",
        });
      } else {
        const data = new FormData();
        data.append("folder", "user");
        data.append("file", ee.target.files[0]);
        // let userToken = self.state.userData.data.token.jwt;
        let objData = {
          ...UPLOAD_FILE,
          //   auth: userToken,
          request: data,
        };
        UtilService.callApi(objData, function (err, data) {
          if (err) {
            OpenNotification({
              type: "error",
              title: "Please Try Again !",
            });
          }
          if (data && data.code === "OK") {
            if (data.data && data.data.files && data.data.files.length !== 0) {
              OpenNotification({
                type: "success",
                title: "Resume Uploaded !",
              });
              setFormData({
                ...formData,
                [type]: data.data.files[0].absolutePath,
                fileName: data.data.files[0].name,
              });
            }
          }
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };

  const onSubmit = (e) => {
    if (handleValidation()) {
      delete formData.errors;
      e.preventDefault();

      let obj = {
        ...CREATE_CAREER,
        request: {
          ...formData,
          post: data.position,
          careerMaster: window.location.search.split("=")[1],
        },
      };

      UtilService.callApi(obj, function (err, data) {
        if (err && err.data) {
          return OpenNotification({
            type: "error",
            title: err.data.message,
          });
        }
        if (data && data.code === "OK") {
          handleFormReset();
          OpenNotification({
            type: "success",
            title: data.message,
          });
        }
      });
    }
  };

  return (
    <>
      <StaticHeader />
      {data && (
        <div className="staticBoxMain">
          <div className="statiContentBox">
            <Heading
              title={data.title}
              className="staticMainTitle text-center mb-50"
            />
            <div>
              <div className="width-100 d-flex j-space-between mb-20">
                <div className="width-49">
                  <div className="staticContentWrapper">
                    {data.jobLocation && (
                      <div className="detailList">
                        <span className="staticDetailLabel">
                          Job Loaction :
                        </span>
                        <span className="statisDetailvalue">
                          {data.jobLocation}
                        </span>
                      </div>
                    )}
                    {data.position && (
                      <div className="detailList">
                        <span className="staticDetailLabel">Position :</span>
                        <span className="statisDetailvalue">
                          {data.position}
                        </span>
                      </div>
                    )}
                    {(data.salary || data.salaryCount) && (
                      <div className="detailList">
                        <span className="staticDetailLabel">Salary :</span>
                        {data.salary && (
                          <span className="statisDetailvalue">
                            {data.salary}
                          </span>
                        )}
                        {data.salaryCount && (
                          <span className="statisDetailvalue">
                            {data.salaryCount}
                          </span>
                        )}
                      </div>
                    )}
                    {data.experience && (
                      <div className="detailList">
                        <span className="staticDetailLabel">Experience :</span>
                        <span className="statisDetailvalue">
                          {data.experience}
                        </span>
                      </div>
                    )}
                    {data.workingDays && (
                      <div className="detailList">
                        <span className="staticDetailLabel">
                          Working Days :
                        </span>
                        <span className="statisDetailvalue">
                          {data.workingDays}
                        </span>
                      </div>
                    )}
                    {data.language && (
                      <div className="detailList">
                        <span className="staticDetailLabel">Language :</span>
                        <span className="statisDetailvalue">
                          {data.language}
                        </span>
                      </div>
                    )}
                    {data.benifits && (
                      <div className="detailList">
                        <span className="staticDetailLabel">Benifits :</span>
                        <span className="statisDetailvalue">
                          {data.benifits}
                        </span>
                      </div>
                    )}
                    {data.contact && (
                      <div className="detailList">
                        <span className="staticDetailLabel">Contact :</span>
                        <span className="statisDetailvalue">
                          {data.contact}
                        </span>
                      </div>
                    )}
                    {data.dutiesAndRequirements && (
                      <div className="detailList">
                        <span className="staticDetailLabel">
                          Duties And Requirements :
                        </span>
                        <span className="statisDetailvalue">
                          {data.dutiesAndRequirements}
                        </span>
                      </div>
                    )}
                    {data.termsOfEmployment && (
                      <div className="detailList">
                        <span className="staticDetailLabel">
                          Terms Of Employment :
                        </span>
                        <span className="statisDetailvalue">
                          {data.termsOfEmployment}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="width-49">
                  <div className="staticContentWrapper">
                    <div className="careerForm">
                      <div className="careerFromInner j-space-between d-flex flex-wrap">
                        <InputBlock
                          label="Name"
                          placeholder="Name"
                          value={formData.name}
                          hasError={formData.errors["name"] ? true : false}
                          onChange={(e) => handleChange("name", e.target.value)}
                        />
                        <InputBlock
                          label="Email Address"
                          hasError={formData.errors["email"] ? true : false}
                          value={formData.email}
                          onChange={(e) =>
                            handleChange("email", e.target.value)
                          }
                          placeholder="Email Address "
                        />
                        <InputBlock
                          label="Mobile"
                          placeholder="Mobile"
                          value={formData.mobile}
                          type="number"
                          onKeyPress={handleMobileNumber}
                          hasError={formData.errors["mobile"] ? true : false}
                          onChange={(e) =>
                            handleChange("mobile", e.target.value)
                          }
                        />
                        <FileUpload
                          title="Upload Resume"
                          onUpload={(e) => uploadDoc("file", e)}
                        />
                        ( Only .doc, .docx & .pdf are allowed !)
                        <div className="upd_file">
                          {formData.file && (
                            <img
                              crossOrigin="anonymous"
                              height="100px"
                              width="100px"
                              src={
                                formData.file.includes(".pdf")
                                  ? PDFICON
                                  : formData.file.includes(".doc")
                                  ? DOCICON
                                  : formData.file.includes(".docx")
                                  ? DOCICON
                                  : BASE_URL + "/" + formData.file
                              }
                              alt=""
                            />
                          )}
                          {formData.fileName && <b>{`${formData.fileName}`}</b>}
                        </div>
                      </div>
                      <div className="text-center">
                        <button onClick={onSubmit} className="commonButton">
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {data.description && (
                <div className="staticContentWrapper">
                  <div className="staticList">
                    <Heading
                      title="Job Description"
                      className="popupInnerTitle"
                    />
                    {data.description}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CareerDetail;
