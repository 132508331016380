/* eslint-disable */
import React from "react";

export default function Loader({ loading = true, fixed }) {
  const isDNA =
    window.location?.href?.split("/")[2] === "view.diadna.com" ? true : false;
  if (loading) {
    return (
      <div
        id="loader"
        className={`${isDNA ? "loading-dna-indicator" : "loading-indicator"}`}
        // className={`main-loader ${fixed ? "--fixed" : ""}`}
        // style={{
        //   width: fixed ? "calc(100% + 30px)" : "100%",
        // }}
      >
        <div id={`${isDNA ? "dna-loader-inner" : "loader-inner"}`}>
          {!isDNA && (
            <img src={require("../../assets/img/fn-logo.png")} alt="Belgium" />
          )}
          {/* <input type="hidden" id="progress_width" value="0" /> */}
        </div>
      </div>
    );
  }
  return null;
}
