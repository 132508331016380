/* eslint-disable */
import React, { useState } from "react";
import { Tag } from "antd";
import IntlMessages from "../../util/IntlMessages";
import OpenNotification from "../common/CommonButton/OpenNotification";
import { includes, head, last, split, groupBy, isEmpty } from "lodash";

const Carat = (props) => {
  const { or, masterIndex, data } = props;
  const [From, setFrom] = useState(undefined);
  const [To, setTo] = useState(undefined);
  const [caratSizeGroup, setCaratSizeGroup] = useState({});

  React.useEffect(() => {
    let groupData = groupBy(data, "group");

    setCaratSizeGroup(groupData);
  }, [data]);

  const handleRefresh = React.useCallback(() => {
    setFrom("");
    setTo("");
  }, []);

  React.useEffect(() => {
    props.isRefresh && handleRefresh();
  }, [handleRefresh, props.isRefresh]);

  const handleAddCarat = React.useCallback(() => {
    let or = props.or && props.or.customeCarat ? props.or.customeCarat : [];
    if (
      From &&
      To &&
      parseInt(From) <= parseInt(To) &&
      !includes(or.customeCarat, { crt: { ">=": From, "<=": To } })
    ) {
      let tags = or.concat({ crt: { ">=": From, "<=": To } });
      props.handleAddCustomCarat(tags);
    } else if (
      From &&
      To &&
      includes(or.customeCarat, { crt: { ">=": From, "<=": To } })
    ) {
      OpenNotification({
        type: "success",
        title: "Carat range is already added.",
      });
    } else if (From && To && parseInt(From) > parseInt(To)) {
      OpenNotification({
        type: "error",
        title: "To must be greater than from.",
      });
    }
    setFrom("");
    setTo("");
  }, [From, To, props]);

  const onTagClose = (tagInput) => {
    let or = props.or && props.or.customeCarat ? props.or.customeCarat : [];
    let tHead = head(split(tagInput, "-"));
    let tLast = last(split(tagInput, "-"));
    let tags = [];
    or.map((x) => {
      let head = x.crt[">="] !== tHead;
      let last = x.crt["<="] !== tLast;
      if (head && last) tags.push(x);
    });
    props.handleAddCustomCarat(tags);
  };

  const changeCarat = (action) => (e) => {
    e = e || window.event;

    if (e.key === "Enter" && action === "To") {
      return handleAddCarat();
    }

    const value = e.target.value;
    const increment = 0.01;

    if (e.keyCode === 38) {
      if ((value > 0 || !value) && action === "From")
        setFrom((Number(e.target.value) + Number(increment)).toFixed(2));
      else if (value > 0 || !value)
        setTo((Number(e.target.value) + Number(increment)).toFixed(2));
    } else if (e.keyCode === 40) {
      if (value > 0 && value > From && action === "From")
        setFrom((Number(e.target.value) - Number(increment)).toFixed(2));
      else if (value > 0 && value > From)
        setTo((Number(e.target.value) - Number(increment)).toFixed(2));
    }
  };

  const handleLimit = () => setTo(From);

  return (
    <div className="diamondSearchItem">
      <div className="diamondSearchLabel">
        <label>
          <IntlMessages id="app.carat-range" />
        </label>
      </div>
      <div className="diamondSearchDetail">
        <div className=" d-flex align-items-center flex-wrap">
          <div className="diamondSearchCaratRange">
            <div className="fromToValue">
              <div className={`fromToValueInput`}>
                <label>
                  <IntlMessages id="app.from" />
                </label>
                <input
                  value={From}
                  onChange={(e) => setFrom(e.target.value)}
                  onKeyDown={changeCarat("From")}
                  onKeyPress={(evt) => {
                    if (
                      (evt.which < 48 || evt.which > 57) &&
                      evt.which !== 46
                    ) {
                      evt.preventDefault();
                    }
                  }}
                  tabindex="1"
                  onBlur={handleLimit}
                />
              </div>
              <div className="fromToValueInput">
                <label>
                  <IntlMessages id="app.to" />
                </label>
                <input
                  value={To}
                  onChange={(e) => setTo(e.target.value)}
                  onBlur={handleAddCarat}
                  onKeyDown={changeCarat("To")}
                  tabindex="2"
                  onKeyPress={(evt) => {
                    if (
                      (evt.which < 48 || evt.which > 57) &&
                      evt.which !== 46
                    ) {
                      evt.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="commonButton addCaratButton"
            tabindex="3"
            onClick={handleAddCarat}
          >
            <IntlMessages id="app.AddCarat" />
          </div>
          <div className="caratTagButton ">
            {or &&
              !isEmpty(or.customeCarat) &&
              or.customeCarat.map((t) => {
                let tagInput = `${t.crt[">="]}-${t.crt["<="]}`;
                return (
                  <Tag
                    className="addedCarat"
                    key={tagInput}
                    onClose={() => onTagClose(tagInput)}
                    closable
                  >
                    {tagInput}
                  </Tag>
                );
              })}
          </div>
        </div>
        <ul className="diamondSearchList caratBoxSize mt-10 carat-container">
          {Object.entries(caratSizeGroup).map((carat, index) => {
            return (
              <li
                onClick={() => props.handleCaratSelection(index)}
                className={includes(masterIndex, index) ? "active" : ""}
              >
                {carat[1][0]["webDisplay"]}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default Carat;
