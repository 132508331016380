/* eslint-disable */
import React, { Component } from "react";
import OtpVerificationForm from "./Auth/OtpVerificationForm";
import Logo from "./common/Logo";
import IntlMessages from "../util/IntlMessages";
import { Redirect } from "react-router-dom";
import { LOCAL_STORAGE_VAR } from "../constants/Common";
import UtilService from "../services/util";

export default class VerifyOtp extends Component {
  render() {
    let isTrusted = localStorage.getItem(`${LOCAL_STORAGE_VAR}-isTrusted`);
    // const isLoggedInUser = localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`);
    const isLoggedInUser = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-token`,
    );

    if (!isLoggedInUser || !isLoggedInUser.length || isTrusted === "true") {
      return <Redirect to="/signin" />;
    }
    return (
      <div>
        <div className="loginMainBlocks">
          <div className="loginLeft">
            <div className="pTrackLogo">
              <div className="text-center mb-20">
                {/* <img src={require("../assets/img/logo-black.png")} /> */}
              </div>
            </div>
            <div className="pForm">
              <OtpVerificationForm />
            </div>
          </div>
          <div className="loginRight">
            <div className="loginCont">
              <div>
                <h3 style={{ marginBottom: "20px" }}>New on Belgium</h3>
                <p>
                  Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                  used in laying out print, graphic or web designs. The passage
                  is attributed.
                </p>
                <ul className="loginPoints">
                  <li>
                    <div className="imgSc">
                      <img
                        src={require("../assets/svg/fm-diamond-bullet.svg")}
                        alt=""
                      />
                    </div>
                    <div className="contentSc">
                      <h3>Lipsum as it is sometimes</h3>
                      <p>
                        Lorem ipsum, or lipsum as it is sometimes known, is
                        dummy text used in laying out print, graphic or web
                        designs
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="imgSc">
                      <img
                        src={require("../assets/svg/fm-diamond-bullet.svg")}
                        alt=""
                      />
                    </div>
                    <div className="contentSc">
                      <h3>Lipsum as it is sometimes</h3>
                      <p>
                        Lorem ipsum, or lipsum as it is sometimes known, is
                        dummy text used in laying out print, graphic or web
                        designs
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="imgSc">
                      <img
                        src={require("../assets/svg/fm-diamond-bullet.svg")}
                        alt=""
                      />
                    </div>
                    <div className="contentSc">
                      <h3>Lipsum as it is sometimes</h3>
                      <p>
                        Lorem ipsum, or lipsum as it is sometimes known, is
                        dummy text used in laying out print, graphic or web
                        designs
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
