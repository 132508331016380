/* eslint-disable */
import React, { Component } from "react";
import moment from "moment";
import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";
import findIndex from "lodash/findIndex";
import invert from "lodash/invert";
import InputBlock from "../InputBlock";
import Heading from "../Heading";
import {
  getColumn,
  handleSaveQuote,
  handleDownloadExcel,
  isMobile,
} from "../../DiamondList/DiamondListFunctions";
import Table from "../../DiamondList/TableBack";
import { calculate, formatNumber } from "./SelectStone";
import { newDiamondPriceWithType, getExtraPer } from "./FinalCalculations";
import OpenNotification from "../CommonButton/OpenNotification";
import SendEmailPopup from "./SendEmail";
import { LOCAL_STORAGE_VAR } from "../../../constants/Common";
import { connect } from "react-redux";
import MobileOfferPopup from "./MobileOfferPopup";
import IntlMessages from "../../../util/IntlMessages";
import _isEmpty from "lodash/isEmpty";

/* eslint jsx-a11y/anchor-is-valid: 0 */

export const QUOTE = [0.5, 1];
export const HOURS = [2, 4, 8, 10, 24, 48];
const currentType = "QuotePopup";

class QuotePopup extends Component {
  constructor(props) {
    super(props);
    let user = JSON.parse(
      window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)),
    );
    this.state = {
      columns: [],
      data: [],
      comment: this.props.editOffer ? this.props.editOffer[0].remarks : "",
      checked: [],
      company: user && user.account ? user.account.companyName : "",
      email: "",
      quoteApplied: [],
      isButtonDisabled: false,
    };
  }

  onQuoteChange = (e, row) => {
    let line = cloneDeep(this.state.data);
    let index = findIndex(line, { stoneId: row?.stoneId }) || 0;
    index = index < 0 ? 0 : index;
    line[index].quote = parseFloat(e);
    line[index].finalquote = parseFloat(
      (line[index].quote || 0) * -1 + (line[index].back || 0),
    );
    line[index] = newDiamondPriceWithType(line[index], true);

    this.setState({ data: line }, () => {
      let checked = this.state.data.filter((el) =>
        find(this.state.checked, { id: el.id }),
      );
      this.setState({ checked });
    });
  };

  componentDidMount() {
    let timeLine = this.props.editOffer ? this.props.editOffer[0] : null;
    let diff = timeLine
      ? moment.duration(
          moment(timeLine.offerValidDate).diff(moment(timeLine.updatedAt)),
        )._data
      : null;
    if (diff && diff.days) diff.hours += diff.days * 24;
    let data = (
      this.props.editOffer ? this.props.editOffer : this.props.checked
    )
      .map((x) => ({
        ...x,
        quote: this.props.editOffer ? (x.newDiscount - (x.back || 0)) * -1 : "",
        hours:
          this.props.editOffer && HOURS.includes(diff.hours + 1)
            ? diff.hours + 1
            : "",
      }))
      .map((x) => ({
        ...x,
        finalquote: parseFloat((x.quote || 0) * -1 + (x.back || 0)),
      }))
      .map((x) => newDiamondPriceWithType(x, true));
    let Columns = getColumn();

    let columns = [
      find(Columns, { id: "srNo" }),
      find(Columns, { id: "rap" }),
      find(Columns, { id: "back" }),
      find(Columns, { id: "ctPr" }),
      find(Columns, { id: "amt" }),
      {
        Header: "Quote",
        accessor: "quote",
        id: "quote",
        Cell: ({ row, cell }) => (
          <span className="tableInput">
            <select
              value={cell.value}
              onChange={(e) => this.onQuoteChange(e.target.value, row.original)}
            >
              <option value="">Select</option>
              {QUOTE.map((x, i) => (
                <option key={i} value={x}>
                  {x}
                </option>
              ))}
            </select>
          </span>
        ),
      },
      this.props.quoteDay
        ? null
        : {
            Header: "Hours",
            accessor: "hours",
            id: "hours",
            Cell: ({ row, cell }) => {
              const dt = new Date();
              const hoursList =
                (dt.getDay() === 6 && dt.getHours() >= 15) ||
                dt.getDay() === 0 ||
                (dt.getDay() === 1 && dt.getHours() < 10)
                  ? [48]
                  : dt.getHours() >= 15
                  ? [24, 48]
                  : HOURS;
              return (
                <span className="tableInput">
                  <select
                    value={cell.value}
                    onChange={(e) =>
                      this.onHourChange(e.target.value, row.original)
                    }
                  >
                    <option value="">Select</option>
                    {hoursList.map((x, i) => (
                      <option key={i} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </span>
              );
            },
          },
      {
        Header: "Final Quote",
        accessor: "finalquote",
        id: "finalquote",
        Cell: ({ cell }) => (
          <div className="tableInput">
            <input
              style={{ fontWeight: "600" }}
              value={parseFloat(cell.value).toFixed(2)}
              disabled
            />
          </div>
        ),
      },
      {
        Header: "Final Disc(" + parseFloat(getExtraPer()).toFixed(2) + "%)",
        accessor: "fnBack",
        id: "fnBack",
        Cell: ({ row }) => (
          <div style={{ fontWeight: "600" }}>
            {parseFloat(row.original.calcDiscount).toFixed(2)}
          </div>
        ),
      },
      {
        Header: "Final Rate",
        accessor: "fnCtpr",
        id: "fnCtpr",
        Cell: ({ row }) => (
          <div style={{ fontWeight: "600" }}>
            {parseFloat(row.original.calcPricePerCarat).toFixed(2)}
          </div>
        ),
      },
      {
        Header: "Final Value",
        accessor: "fnAmt",
        id: "fnAmt",
        Cell: ({ row }) => (
          <div style={{ fontWeight: "600" }}>
            {parseFloat(row.original.calcAmount).toFixed(2)}
          </div>
        ),
      },
    ].filter((el) => el && el);
    columns = [
      ...columns,
      ...Columns.filter((el) => !find(columns, { id: el.id })),
    ];
    this.setState({ columns, data, checked: data });
  }

  onHourChange = (e, row) => {
    let line = cloneDeep(this.state.data);
    let index = findIndex(line, { stoneId: row?.stoneId }) || 0;
    index = index < 0 ? 0 : index;
    line[index].hours = e;
    this.setState({ data: line }, () => {
      let checked = this.state.data.filter((el) =>
        find(this.state.checked, { id: el.id }),
      );
      this.setState({ checked });
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.checkedData.length !== this.state.checked.length) {
      this.setState({
        checked: this.state.data.filter((x) =>
          find(nextProps.checkedData, { id: x.id }) ? true : false,
        ),
      });
    }
  }

  offerHeading = () => {
    if (isMobile() && !this.state.checked.length) return null;
    let sum = calculate(this.state.checked);

    let obj = {
      "CT. :": parseFloat(sum.total_carat).toFixed(2),
      "Disc% :": parseFloat(sum.final_discount).toFixed(2),
      "Price/Ct :": formatNumber(parseFloat(sum.final_price).toFixed(2)),
      "Discount Different :": parseFloat(
        sum.final_term_discount - sum.final_discount,
      ).toFixed(2),
      "Quote% :": parseFloat(sum.final_term_discount).toFixed(2),
      "Quote Value :": formatNumber(parseFloat(sum.final_net_value).toFixed(2)),
      "Avg. Discount :": formatNumber(parseFloat(sum.finalAvgDis).toFixed(2)),
      "Net Value :": formatNumber(parseFloat(sum.finalAvgVal).toFixed(2)),
    };
    if (isMobile()) obj = invert(obj);
    return (
      <div className="DiamondDetailPopup">
        {Object.keys(obj).map((x, i) => {
          return (
            <div key={i} className="DiamondDetailPopupItem">
              <span>{x}</span>
              <span style={{ color: "red" }}>
                {isMobile() ? obj[x].slice(0, obj[x].length - 1) : obj[x]}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  getOfferInput = () => {
    return (
      <div className="offerInputBox">
        <IntlMessages id="app.CompanyName*">
          {(placeholder) => (
            <InputBlock
              value={this.state.company}
              onChange={(e) => this.setState({ company: e.target.value })}
              disabled
              label={placeholder}
              placeholder={placeholder}
            />
          )}
        </IntlMessages>
        <IntlMessages id="app.comment">
          {(placeholder) => (
            <InputBlock
              value={this.state.comment}
              onChange={(e) => this.setState({ comment: e.target.value })}
              label={placeholder}
              placeholder={placeholder}
            />
          )}
        </IntlMessages>
      </div>
    );
  };

  checkCheck = () => {
    if (!this.state.checked.length) {
      OpenNotification({
        type: "error",
        title: "Please select stone(s) to apply quote.",
      });
      return false;
    } else if (this.state.checked.length) {
      const validate = this.state.checked?.filter(({ hours, quote }) => {
        return !hours || !quote;
      });

      validate?.length &&
        OpenNotification({
          type: "error",
          title: "Please select quote and hours",
        });
      return validate?.length ? false : true;
    } else return true;
  };

  submit = () => {
    if (!this.checkCheck()) return;
    if (!this.state.company.trim()) {
      OpenNotification({ type: "error", title: "Please enter company name." });
      return;
    }

    this.setState({ isButtonDisabled: true });
    this.props.handleLoader(true);
    handleSaveQuote(
      this.state.checked,
      this.state.comment,
      this.state.company,
      () => {
        this.props.onClose();
        this.props.clearAll();
        this.props.handleLoader(false);
      },
      this.props.editOffer ? true : false,
    );
  };

  render() {
    return isMobile() ? (
      <MobileOfferPopup {...this.state} parent={this} />
    ) : (
      <div className="offerWrapper">
        <div className="d-flex align-items-center  offerTopBlock">
          <div className="d-flex align-items-center mr-40">
            <Heading
              className="popupInnerTitle"
              title={<IntlMessages id="app.ApplyQuote" />}
            />
          </div>
          {this.offerHeading()}
        </div>
        <div className="searchPopupCommonTable">
          <div className="searchResultTable tabInnerTableScroll">
            <Table
              {...this.state}
              areAllChecked={true}
              currentType={currentType}
              FilterOption={false}
              canSort={false}
              nodots
              noGrp
            />
          </div>
          <div className="d-flex justify-content-between offerBottomBox flex-wrap">
            {this.getOfferInput()}
            <p className="offerNote mt-20">
              <b>
                <IntlMessages id="app.Note" />:
              </b>
              <IntlMessages id="app.quoteNote" />
            </p>
          </div>
        </div>
        <div className="sideBarPopupButton">
          <a
            className="commonButton"
            onClick={this.submit}
            disabled={this.state.isButtonDisabled}
          >
            <IntlMessages id="app.SaveQuote" />
          </a>
          <a className="commonButton" onClick={this.props.onClose}>
            <IntlMessages id="app.CancelQuote" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              let { data } = this.state;
              let quoteApplied = [];
              data.map((d) =>
                quoteApplied.push({
                  diamond: d.id,
                  newAmount: d.calcAmount,
                  newPricePerCarat: d.calcPricePerCarat,
                  newDiscount: d.calcDiscount,
                }),
              );

              handleDownloadExcel(
                {},
                this.state.checked.map((x) => x.id),
                () => {},
                false,
                quoteApplied,
              );
            }}
          >
            <IntlMessages id="app.ExcelExport" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              let { data } = this.state;
              let quoteApplied = [];
              data.map((d) =>
                quoteApplied.push({
                  diamond: d.id,
                  newAmount: d.calcAmount,
                  newPricePerCarat: d.calcPricePerCarat,
                  newDiscount: d.calcDiscount,
                }),
              );
              if (this.state.checked.length)
                this.setState({ email: "stock", quoteApplied });
              else
                OpenNotification({
                  type: "error",
                  title: "Please select any stone(s).",
                });
            }}
          >
            <IntlMessages id="app.EmailStock" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              if (this.state.checked.length) this.setState({ email: "img" });
              else
                OpenNotification({
                  type: "error",
                  title: "Please select any stone(s).",
                });
            }}
          >
            <IntlMessages id="app.EmailPic" />
          </a>
          <SendEmailPopup
            sendEmail={this.state.email}
            onCancel={() => this.setState({ email: "" })}
            ids={this.state.checked.map((x) => x.id)}
            img={this.state.email === "img"}
            isStock={this.state.email === "stock"}
            quoteApplied={this.state.quoteApplied}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ diamondData }) => {
  return { checkedData: diamondData.selectedRows[currentType] || [] };
};

export default connect(mapStateToProps, null)(QuotePopup);
