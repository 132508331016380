/* eslint-disable */
import React, { memo } from "react";
import { getParams, LISTINGPAGES } from "../DiamondListFunctions";
import { formatNumber } from "../../common/DiamondListing/SelectStone";
import { STATUS, STATUS_COLOR } from "../../common/DiamondListing/Status";
import { LAB_LINKS, FILE_URLS } from "../../../constants/Common";
import CheckBox from "../../common/CheckBox";
import useRowSelect from "../../../util/useRowSelect";
import { DIAMOND_BID } from "../../../constants/Common";
import moment from "moment";
import Image from "components/common/Image";
import noImageSvg from "assets/svg/noimage.svg";

const DiamondSearchGrid = (props) => {
  const { isRowChecked, toggleRowSelection } = useRowSelect(props.currentType);
  const BID_STATUS = {
    1: "Blind Bid",
    2: "Look & Bid",
  };
  const getHref = (block) => {
    if (block && block?.lbNm && block?.rptNo) {
      return LAB_LINKS?.[block?.lbNm?.toUpperCase()]?.replace(
        "***",
        block?.rptNo,
      );
    } else {
      return "";
    }
  };
  function getBidStartTime(bidType) {
    if (bidType === DIAMOND_BID.TYPE.OPEN)
      return moment().startOf("day").add(11, "hours");
    else return moment().startOf("day").add(15, "hours");
  }

  function getBidEndTime(bidType) {
    if (bidType === DIAMOND_BID.TYPE.OPEN)
      return moment().startOf("day").add(14, "hours").add(59, "minutes");
    else return moment().startOf("day").add(10, "hours").add(59, "minutes");
  }

  function getBidType() {
    let bidType = 0;
    if (props?.currentBidType) {
      bidType = props?.currentBidType;
    } else if (
      moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) &&
      moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)
    ) {
      bidType = DIAMOND_BID.TYPE.OPEN;
    } else if (
      moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) ||
      moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)
    ) {
      bidType = DIAMOND_BID.TYPE.BLIND;
    }
    return BID_STATUS[bidType];
  }

  return (
    <div className="diamondGridView">
      {!props.loading &&
        props.data.length > 0 &&
        props.data.map((block) => {
          const isChecked = isRowChecked(block.id);
          return (
            <div key={`grid_${block.id}`} className="daimondGridViewItem">
              <div className="DiamondGridViewImg">
                <div className="diamondGrdiCheckbox">
                  <CheckBox
                    checked={isChecked}
                    onChange={() => toggleRowSelection(block, !isChecked)}
                  />
                </div>
                {block?.img ? (
                  <Image
                    src={FILE_URLS.img.replace("***", block.vStnId)}
                    alt=""
                  />
                ) : (
                  <Image src={noImageSvg} />
                )}
              </div>
              <div className="gridViewContent">
                <div className="gridLeftContent">
                  <h2>{block.shpNm}</h2>
                  {/* <a
                    href={`/${LISTINGPAGES.DETAIL}/${block.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  > */}
                  <b>{block.vStnId}</b>
                  {/* </a> */}
                  <p>{getParams(block).join(" ")}</p>
                </div>
                <div className="gridRightContent">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={getHref(block)}
                    // onClick={() => {
                    //   if (block.lbNm && block.rptNo) {
                    //     window.open(
                    //       `${LAB_LINKS[block.lbNm.toUpperCase()].replace(
                    //         "",
                    //         block.rptNo
                    //       )}`
                    //     );
                    //   }
                    // }}
                  >
                    {block.lbNm}
                  </a>
                  {props.currentType !== "/upcoming" && (
                    <span>${formatNumber(Math.round(block.amt))}</span>
                  )}
                  <span className="statusShow">
                    <span>
                      {block.wSts === "B"
                        ? props.currentType === "/new-arrival-bid-it"
                          ? BID_STATUS[block?.bidType]
                          : getBidType()
                        : STATUS[block.wSts]}
                    </span>
                    <span
                      className={"statusIcon " + STATUS_COLOR[block.wSts]}
                    ></span>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default memo(DiamondSearchGrid);
