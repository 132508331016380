import antdKo from "antd/lib/locale-provider/ko_KR";
import appLocaleData from "react-intl/locale-data/ko";
import enMessages from "../locales/ko_KR.json";

const allMessages = { ...enMessages };

const KoLang = {
  messages: {
    ...allMessages,
  },
  antd: antdKo,
  locale: "ko-KR",
  data: appLocaleData,
};
export default KoLang;
