/* eslint-disable */
import React, { useState } from "react";
import "../TermsOfUse/staticContent.less";
import Heading from "../common/Heading";
import StaticHeader from "../StaticHeader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./contact.less";
import BusinessPolicy from "./BusinessPolicy";
import BusinessModel from "./BusinessModel";

const AboutUs = (props) => {
  const [defaultTab, setDefaultTab] = useState(0);
  return (
    <>
      {!props.isDevice && <StaticHeader />}

      <div className="staticBoxMain white-bg">
        <div className="statiContentBox">
          <div className="text-center mb-50">
            <Heading title="About Us" className="staticMainTitle" />
            <Tabs selectedIndex={defaultTab}>
              <TabList className="customerMaintab">
                <Tab onClick={() => setDefaultTab(0)}>About Us</Tab>
                <Tab onClick={() => setDefaultTab(1)}>Business Model</Tab>
                {/* <Tab onClick={() => setDefaultTab(2)}>Business Policy</Tab> */}
                <hr />
              </TabList>
              <TabPanel>
                <div
                  style={{
                    textAlign: "justify",
                    width: "100%",
                    borderRadius: "10px",
                    backgroundColor: "#fff !important",
                    boxShadow: "0 0 9px 0px rgb(0 0 0 / 9%)",
                    border: "1px solid #efefef",
                    position: "relative",
                    marginBottom: "15px",
                  }}
                >
                  <p>Welcome to Belgium New York LLC!</p>
                  <p>
                    Belgium NY LLC is a leading and trusted natural diamonds
                    supplier located in the heart of the Diamond district of New
                    York, led by Mr. Ashish Karnavat. We’ve been in the business
                    for over 25 years and are an active member of JBT, DDC, RJC
                    & CBG. In the last 25 years, our group has expanded around
                    the globe into New York, India, Hong Kong, Los Angeles,
                    Tokyo, Dubai, and Antwerp catering to the global market and
                    take pride in being a leader of Solitaire Diamonds.
                  </p>
                  <p>
                    At Belgium New York, we have an inventory of approximately
                    over 3000 stones, and have close to over 10,000 stones if we
                    consolidate our global inventory. Our merchandise is of a
                    higher quality in which our cuts are more refined with
                    improved fire and luster. We have evolved ourselves to focus
                    on the transparency of the diamond pipeline through
                    provenance, authenticity and traceability by integrating
                    programs like{" "}
                    <strong>TRACR, COO, Forevermark, & many more</strong>.<br />
                    We understand and emphasize the importance of traceability
                    and origin of any diamond sold which relinquishes the
                    tarnish of “blood diamonds” and to ensure a seamless
                    experience for our customers to sell our diamonds, along
                    with GIA certificates, we additionally provide{" "}
                    <strong>
                      ’Know your artisan’, ‘Traceability’ certificates and
                      ‘Diamond Journey’ videos
                    </strong>
                    .
                  </p>
                  <p>
                    Under the leadership of Mr. Ashish Karnavat, Belgium New
                    York determines to provide the best and a very long-term
                    partnership to all our valued customers and hence, our team
                    is highly skilled to cater to our clients’ diamond
                    requirements.{" "}
                  </p>
                  <p>
                    At Belgium New York, we have an equal responsibility towards
                    providing the best experience to our clients across North
                    America but also to uplift the industry as a whole and lead
                    the evolution towards a fully ethical, conflict free, and
                    compliant with the United Nations Resolutions, Anti Money
                    Laundering Program and USA Patriot Act. Each and every one
                    of our rough diamonds are conflict free with the Kimberly
                    Process authentication. Our manufacturing facilities in
                    Surat, Botswana, & Namibia are all audited twice a year by
                    various third parties to validate each and every polished
                    stone manufactured for our client’s reassurance.{" "}
                  </p>
                </div>
              </TabPanel>
              <TabPanel>
                <BusinessModel />
              </TabPanel>
              {/* <TabPanel>
                <BusinessPolicy />
              </TabPanel> */}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;

// export default GoogleApiWrapper((props) => ({
//   apiKey: "AIzaSyBHHa9BSaf_EdABnUBhtKXKfW6RWAASERE",
// }))(ContactUs);
