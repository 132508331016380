/* eslint-disable */
import React, { useState } from "react";
import LangDropdown from "../Header/LangDropdown";
import { Link } from "react-router-dom";
import Logo from "../Header/Logo";
import "./statisHeader.less";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import IntlMessages from "util/IntlMessages";

const StaticHeader = () => {
  const [menu, SetMenus] = useState(false);
  const sidebarHandle = () => {
    SetMenus(true);
  };
  const drawerClose = () => {
    SetMenus(false);
  };
  const isMobile = window.innerWidth <= 767;
  const token = localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`);
  return (
    <div className="staticHeaderWrapper d-flex j-space-between align-items-center">
      <div className="staticHeaderLogo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className={`staticMenus ${menu ? "active" : null}`}>
        {isMobile && (
          <div className="sidebarOverLay" onClick={drawerClose}></div>
        )}
        <ul className="d-flex">
          <li>
            <Link to="/">
              <IntlMessages id="app.home" />
            </Link>
          </li>
          <li>
            <Link to="/news">
              <IntlMessages id="app.Home.NewsNEvent" />
            </Link>
          </li>
          {/* <li>
            <Link to="/bidding-procedure"><IntlMessages id="app.Home.BidProce" /></Link>
          </li> */}
          <li>
            <Link to="/book-appointment">
              <IntlMessages id="app.Home.TakeApntmt" />
            </Link>
          </li>
          <li>
            <Link to="/terms-condition">
              <IntlMessages id="app.Home.Terms" />
            </Link>
          </li>
          <li>
            <Link to="/contact">
              <IntlMessages id="app.ContactUs" />{" "}
            </Link>
          </li>
          <li>
            <Link to="/career">
              <IntlMessages id="app.Career" />{" "}
            </Link>
          </li>
          {token && (
            <li>
              <Link to="/feedback">
                <IntlMessages id="app.Feedback" />
              </Link>
            </li>
          )}
          <li>
            <a
              tag="a"
              target="blank"
              rel="noreferrer noopener"
              href="/BusinessPolicy.pdf"
            >
              Business Policy
            </a>
          </li>
          <li>
            <Link to="/about-us">
              <IntlMessages id="app.AboutUs" />
            </Link>
          </li>
        </ul>
      </div>
      <div className="d-flex staticHeaderRight">
        <LangDropdown />
        {!token && (
          <div className="headerIcon">
            <img
              className="defulticon"
              src={require("../../assets/svg/Header/user.svg")}
              alt="icon"
            />
            <img
              className="hoverIcon"
              src={require("../../assets/svg/Header/user-white.svg")}
              alt="icon"
            />

            <>
              {isMobile ? null : (
                <Link className="headerUserName" to="/">
                  Login/Register
                </Link>
              )}
            </>
          </div>
        )}
      </div>
      {isMobile && (
        <div className="mobileMenu" onClick={sidebarHandle}>
          <img
            src={require("../../assets/svg/MobileHeader/menu.svg")}
            alt="menuIcon"
          />
        </div>
      )}
    </div>
  );
};
export default StaticHeader;
