/* eslint-disable */
import React from "react";
import { Dropdown, Tooltip } from "antd";
import SortingClose from "./SortingClose";
import SortingRound from "./SortingRound";

const DiamondListingHead = (props) => {
  return (
    <>
      {props.sortingFalse === true ? (
        <>{props.header}</>
      ) : (
        <Dropdown
          overlayClassName="tabelDropdownClass"
          overlay={props.ColorMenuConst}
          trigger={["click"]}
        >
          <div className="tableHeaderMain" onClick={(e) => e.preventDefault()}>
            <span className="listIcon">
              <img
                src={require("../../../assets/svg/DiamondList/sorting.svg")}
                alt="icon"
              />
            </span>
            <Tooltip title={props.Header}>
              <span className="taleHeadTitle">{props.header}</span>
            </Tooltip>

            {props.shortingSelect === true ? (
              <div className="selectShortingMainBlock">
                <div className="selectShorting">
                  <span className="selectShortingCount">
                    {props.shortingCount}
                  </span>
                  <span className="selectShortingArrow">
                    <img
                      src={require("../../../assets/svg/DiamondList/shortinglistdown-white.svg")}
                    />
                  </span>
                </div>
                <SortingClose />
              </div>
            ) : (
              <SortingRound />
            )}
          </div>
        </Dropdown>
      )}
    </>
  );
};
export default DiamondListingHead;
