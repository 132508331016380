/* eslint-disable */
import React from "react";

import { DateTime } from "luxon";

import { catchError } from "util/utils";

export const Clock = React.memo((props) => {
  const {
    zone = DateTime.local().zoneName,
    fmt = "hh:mm:ss a",
    children,
    ...forwardProps
  } = props;

  const spanRef = React.useRef();

  const setDateTime = React.useCallback(() => {
    catchError(() => {
      spanRef.current.innerHTML = DateTime.local().setZone(zone).toFormat(fmt);
    });
  }, [fmt, zone]);

  React.useEffect(() => {
    setDateTime();
    const intrvalId = setInterval(() => {
      setDateTime();
    }, 1000);

    return () => {
      clearInterval(intrvalId);
    };
  }, [setDateTime]);

  return (
    <span ref={spanRef} {...forwardProps}>
      {children}
    </span>
  );
});

Clock.displayName = "Clock";
