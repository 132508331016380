/* eslint-disable */
import React from "react";

const SortingRound = () => {
  return (
    <div className="shortingRound">
      <div className="shortingRoundInner">
        <img
          src={require("../../../assets/svg/DiamondList/shortinglistup.svg")}
          alt="icon"
        />
        <img
          src={require("../../../assets/svg/DiamondList/shortinglistdown.svg")}
          alt="icon"
        />
      </div>
    </div>
  );
};
export default SortingRound;
