/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DiamondDetailAction from "./DiamondDetailAction";
import EnquiryDetail from "./EnquiryDetail";
import DiamondDetailLeft from "./LeftSection";
import {
  handleInsertTrack,
  downloadZip,
  LISTINGPAGES,
  isMobile,
  getProjectSetting,
  isIpade,
} from "../../components/DiamondList/DiamondListFunctions";
import { PROJECT_SETTINGS_TYPE, trackTypeObj } from "../../constants/Common";
import last from "lodash/last";
import size from "lodash/size";
import groupBy from "lodash/groupBy";
import UtilService from "../../services/util";
import SendEmail from "../common/DiamondListing/SendEmail";
import DetailView from "../../constants/DiamondDetail";
import { VIEW_DIAMOND, _HIT_COUNTER } from "../../constants/apiConstant";
import ConfirmStonePopup from "../common/DiamondListing/ConfirmStonePopup";
import { floatkeys, roundkeys } from "../DiamondList/TableBack";
import { getGridHeight } from "../DiamondList";
import MobileDiamondDetail from "./MobileDiamondDetail";
import IntlMessages from "../../util/IntlMessages";
import { GuestUserPopup } from "../GuestUserPopup";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import { isEmpty } from "util/utils";

class DiamondDetail extends Component {
  state = {
    visible: false,
    show: false,
    detail: true,
    data: {},
    sendEmail: false,
    isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`),
    showGuest: false,
    shared: [],
    mediaUrl: UtilService.getLocalStorageItem("mediaPathPattern"),
  };
  componentDidUpdate(prevProps) {
    if (this.props.commonData.strip !== prevProps.commonData.strip)
      this.forceUpdate();
  }

  placeOrderDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      this.setState({
        visible: true,
        detail: false,
        show: false,
      });
    }
  };

  EnquiryDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      this.setState({
        show: true,
        detail: false,
        visible: false,
      });
    }
  };

  popupClose = () => {
    this.setState({
      detail: true,
      visible: false,
      show: false,
    });
  };

  handleHitCounter = (id) => {
    let objData = {
      ..._HIT_COUNTER,
      request: {
        isHitCounter: true,
        id,
      },
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === "OK") {
      }
    });
  };

  getMediaUrl = (type) => {
    const url = this.state.mediaUrl?.[type]?.FILE_PATH;

    return url?.PATH?.replaceAll("***", this.state.data?.[url?.KEY]);
  };

  getDiamondDetail = () => {
    let id = last(window.location.pathname.split("/"));
    if (id !== LISTINGPAGES.DETAILS && id !== LISTINGPAGES.DETAIL) {
      let self = this;
      let api = `${VIEW_DIAMOND.url}/${id}`;
      let objData = {
        method: VIEW_DIAMOND.method,
        url: api,
        showLoader: true,
      };
      UtilService.callApi(objData, async function (err, data) {
        if (err) throw err;
        if (data && data.code === "OK") {
          self.setState({ data: data.data[0] });
          self.handleHitCounter(data.data[0].id);
        }
      });
    }
  };

  componentDidMount = () => {
    let re = /[?&]/;
    this.setState({
      shared: window.location.search.split(re),
    });
    if (this.props.diamondPopup) return;

    //get diamond detail if media setting exist
    if (!isEmpty(this.state.mediaUrl)) return this.getDiamondDetail();

    getProjectSetting(PROJECT_SETTINGS_TYPE.MEDIA_PATH, (data) => {
      this.setState({ mediaUrl: data?.MEDIA_PATH_PATTERN ?? {} }, () => {
        UtilService.setLocalStorageItem(
          data?.MEDIA_PATH_PATTERN,
          "mediaPathPattern",
        );
        this.getDiamondDetail();
      });
    });
  };

  verifyCerti = (data) => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      window.open(`https://gia.edu/report-check?reportno=${data.rptNo}`);
    }
  };

  downloadZip = (data) => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      downloadZip(
        {
          filter: {
            vStnId: [data.vStnId],
          },
          isExcel: true,
          img: true,
          certFile: true,
          videoFile: true,
          hAFile: true,
          arrowFile: true,
          assetFile: true,
          pltFile: true,
          mp4Video: true,
          type2: true,
          roughVideo: true,
          darkFieldImage: true,
          flsImage: true,
          idealWhiteImage: true,
          roughImage: true,
          planImg: true,
          faceUp: true,
          b2cRealImage: true,
        },
        () => {},
      );
    }
  };

  sendMail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      this.setState({ sendEmail: true });
    }
  };

  actionButtons = (data) => {
    return !data || data.wSts === "U" ? null : (
      <div className="diamondDetailBottomBox">
        <div className="diamondDetailBottomAction">
          <div className="d-flex">
            {!localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) &&
            window.location.pathname.split("/")[1] === LISTINGPAGES.DETAILS ? (
              <>
                <div className="botoomStickyBar diamondSearchSticky">
                  <div className="d-flex align-items-center">
                    <a className="bottomStickyButton" href="/signup">
                      <span>Create An Account</span>
                    </a>
                  </div>
                  <div className="d-flex align-items-center">
                    <a
                      className="bottomStickyButton"
                      onClick={() => {
                        window.open(
                          `/signin?redirectUrl=${window.location.pathname}`,
                          "_self",
                        );
                      }}
                    >
                      <span>Log In</span>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <>
                {data.wSts !== "B" && (
                  <DiamondDetailAction
                    image={require("../../assets/svg/DiamondDetail/cart.svg")}
                    tooltip={<IntlMessages id="app.confirmStone" />}
                    placement="bottomLeft"
                    onClick={this.placeOrderDetail}
                  />
                )}
                {/* <DiamondDetailAction
                            image={require('../../assets/svg/DiamondDetail/enquiry.svg')}
                            tooltip='Enquiry'
                            placement='bottomLeft'
                            onClick={this.EnquiryDetail}
                        /> */}
                {data.wSts !== "B" && (
                  <DiamondDetailAction
                    image={require("../../assets/svg/DiamondDetail/add-cart.svg")}
                    tooltip={<IntlMessages id="app.AddToCart" />}
                    placement="bottomLeft"
                    onClick={() => {
                      if (this.state.isGuest) {
                        this.setState({ showGuest: true });
                      } else {
                        handleInsertTrack(trackTypeObj.CART, [data], () => {});
                      }
                    }}
                  />
                )}
                <DiamondDetailAction
                  onClick={() => {
                    if (this.state.isGuest) {
                      this.setState({ showGuest: true });
                    } else {
                      this.verifyCerti(data);
                    }
                  }}
                  image={require("../../assets/svg/DiamondDetail/certificate.svg")}
                  tooltip={<IntlMessages id="app.VerifyCertificate" />}
                  placement="bottomLeft"
                />
                <DiamondDetailAction
                  onClick={() => {
                    if (this.state.isGuest) {
                      this.setState({ showGuest: true });
                    } else {
                      this.sendMail();
                    }
                  }}
                  image={require("../../assets/svg/DiamondDetail/x-ray.svg")}
                  tooltip={<IntlMessages id="app.ShareXRay" />}
                  placement="bottomLeft"
                />
                <DiamondDetailAction
                  image={require("../../assets/svg/DiamondDetail/download-zip.svg")}
                  tooltip={<IntlMessages id="app.downloadZip" />}
                  placement="bottomLeft"
                  onClick={() => {
                    if (this.state.isGuest) {
                      this.setState({ showGuest: true });
                    } else {
                      this.downloadZip(data);
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  diamondDetail = (data, key) => {
    let grouped = groupBy(data, key);
    let first = grouped[1] || [];
    let second = grouped[2] || [];
    if ((!first || !first.length) && (!second || !second.length)) {
      first = Object.values(grouped)[0];
    }
    let maxIndex = Math.max(first.length, second.length),
      arr = [];
    for (let i = 0; i < maxIndex; i++) {
      first[i] && arr.push(first[i]);
      second[i] && arr.push(second[i]);
    }
    return arr;
  };

  leftPart = (data) => {
    let gridHeight1 = isIpade()
      ? getGridHeight(
          [
            "headerTopMenu",
            "covidStrip",
            "HeaderStripBlock",
            "diamondDetailTopTab",
          ],
          60,
        )
      : getGridHeight(
          [
            "headerTopMenu",
            "covidStrip",
            "HeaderStripBlock",
            "diamondDetailTopTab",
          ],
          70,
        );
    return (
      <div className="diamondDetailInnerDetail">
        {data && size(data) ? (
          <DiamondDetailLeft
            item={data}
            shared={this.state.shared}
            rptNo={data.rptNo}
            pktNo={data.vStnId}
            getMediaUrl={this.getMediaUrl}
            style={
              isMobile()
                ? { height: "400px" }
                : !this.props.diamondPopup
                ? { height: gridHeight1, overflow: "auto" }
                : { height: "" }
            }
          />
        ) : null}
      </div>
    );
  };

  getEmailModal = (data) => {
    return (
      <SendEmail
        path={`${window.location.origin}/${LISTINGPAGES.DETAILS}/${data.id}`}
        xRay={true}
        sendEmail={this.state.sendEmail}
        onCancel={() => this.setState({ sendEmail: false })}
        onDetailClose={() => this.setState({ sendEmail: false })}
      />
    );
  };

  render() {
    let data = this.props.diamondPopup ? this.props.data : this.state.data;
    let gridHeight = getGridHeight(
      [
        "headerTopMenu",
        "covidStrip",
        "HeaderStripBlock",
        "diamondDetailBottomBox",
      ],
      15,
    );

    let params = Object.keys(DetailView).map((k) => (
      <div className="diamondDetailMore" key={DetailView[k]["name"]}>
        <h2 className="detailSmallTitle">{DetailView[k]["name"]}</h2>
        <div className="diamondDetailMoreHalf">
          {this.diamondDetail(DetailView[k]["data"], "part").map((item) => {
            if (data.wSts === "U" && ["back", "ctPr", "amt"].includes(item.key))
              return false;
            return (
              <ul key={item.key}>
                <li>
                  {!item.hide && (
                    <>
                      <span>{item.label}</span>
                      <span>
                        {!data[item["key"]]
                          ? "-"
                          : floatkeys.includes(item.key)
                          ? parseFloat(data[item["key"]]).toFixed(2)
                          : roundkeys.includes(item.key)
                          ? Math.round(data[item["key"]])
                          : data[item["key"]]}
                      </span>
                    </>
                  )}
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    ));

    if (isMobile())
      return (
        <MobileDiamondDetail params={params} {...this.state} parent={this} />
      );

    return (
      <div className="diamondDetailWrapper">
        <div className="diamondDetailInner">
          {this.props.diamondPopup ? this.actionButtons(data) : null}
          <div className="diamondDetailMainTab">
            {this.props.diamondPopup && (
              <>
                <div className="detailHalfWhite"></div>
                <div className="detailHalfGray"></div>
              </>
            )}
            <div className="diamondDetailLeft">{this.leftPart(data)}</div>
            <div
              className={`diamondDetailRight ${
                !this.props.diamondPopup ? "diamondDetailPage" : null
              }`}
            >
              {!this.props.diamondPopup ? this.actionButtons(data) : null}
              {this.state.show && (
                <EnquiryDetail
                  style={
                    !this.props.diamondPopup
                      ? { height: gridHeight, overflow: "auto" }
                      : { height: "auto" }
                  }
                  checked={[data]}
                  onCancel={() => this.popupClose()}
                  onClose={() => this.popupClose()}
                />
              )}
              {this.state.visible && (
                <div className="p-10">
                  <ConfirmStonePopup
                    style={
                      !this.props.diamondPopup
                        ? { height: gridHeight, overflow: "auto" }
                        : { height: "" }
                    }
                    popupClass={
                      !this.props.diamondPopup ? null : "halfBlockPopup"
                    }
                    detail
                    checked={[data]}
                    onClose={this.popupClose}
                  />
                </div>
              )}
              {this.state.detail && (
                <div
                  className="mt-10"
                  style={
                    !this.props.diamondPopup
                      ? { height: gridHeight, overflow: "auto" }
                      : { height: "auto" }
                  }
                >
                  {params}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.getEmailModal(data)}
        {this.state.showGuest && (
          <GuestUserPopup
            visible={this.state.showGuest}
            register={() => {
              window.open("/signup", "_self");
              window.localStorage.clear();
              window.sessionStorage.clear();
            }}
            handleCancel={() => {
              this.setState({ showGuest: false });
            }}
          />
        )}
      </div>
    );
  }
}
export default connect((props) => {
  return props;
}, {})(withRouter(DiamondDetail));
