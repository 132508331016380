/* eslint-disable */
import React, { Component } from "react";
import OfficeSlot from "./OfficeSlot";
import SelectOption from "../../SelectOption";
import TextArea from "../../TextArea";
import LeftDatePicker from "../../LeftMultipleSelect/LeftDatePicker";
import { timeSlot } from "../../../../constants/slot";
import Heading from "../../Heading";
import IntlMessages from "../../../../util/IntlMessages";
import moment from "moment";
import SlotItem from "./SlotItem";
import UtilService from "../../../../services/util";
import head from "lodash/head";
import last from "lodash/last";
import split from "lodash/split";
import map from "lodash/map";
import {
  CREATE_SCHEDULE,
  GET_SLOTS,
  FETCH_OFFICE_DIAMOND,
} from "../../../../constants/apiConstant";
import OpenNotification from "../../CommonButton/OpenNotification";
import { LOCAL_STORAGE_VAR } from "../../../../constants/Common";
import { Drawer } from "antd";

export const Appointment = {
  location: [
    { name: "India" },
    { name: "Spain" },
    { name: "Hong Kong" },
    { name: "Dubai" },
  ],
  virtualType: [
    { name: "Phone Call", id: 1 },
    { name: "Web Conference", id: 2 },
    { name: "In Person", id: 3 },
  ],
};
export const OfficeFill = () => {
  return (
    <div>
      <div className="offerInputBox">
        <SelectOption
          selectValue={Appointment.virtualType}
          placeholder={<IntlMessages id="app.SelectVirtualType" />}
          label={<IntlMessages id="app.SelectVirtualType" />}
        ></SelectOption>
        <TextArea label="Comment" placeholder="Comment"></TextArea>
      </div>
      <div className="sideBarPopupButton">
        <a className="commonButton">Request Appointment</a>
        <a className="commonOutline">Cancel</a>
      </div>
    </div>
  );
};
class OfficeSidebar extends Component {
  constructor() {
    super();
    this.state = {
      date: moment().toISOString(),
      slot: "",
      comment: "",
      slots: [],
      apiSlot: {},
      meetingType: 3,
      bidSlots: [],
      office: false,
      disabledSlots: [],
      bookedSlots: [],
    };
  }
  onClose = () => {
    this.setState({
      office: false,
    });
  };

  officeView = () => {
    this.setState({
      office: true,
    });
  };

  componentDidMount = () => {
    this.getBookedSlots();

    let self = this;
    let objData = {
      ...GET_SLOTS,
      request: { sort: [{ end: "ASC" }] },
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === "OK") {
        let bidSlots = [];
        data.data.list.map((ls) => {
          let start = moment(ls.start).format("hh:mm A");
          let end = moment(ls.end).format("hh:mm A");
          let bidTime = start < "11:00 AM" && end > "03:00 PM";
          if (!bidTime) {
            bidSlots.push(ls);
          }
        });

        self.setState({ slots: data.data.list, bidSlots });
      }
    });
  };

  getBookedSlots = () => {
    let obj = FETCH_OFFICE_DIAMOND;
    obj.request = {
      page: 1,
      limit: 250,
      sort: [{ date: "ASC" }],
      filter: { date: { ">=": moment().startOf("day").toISOString() } },
    };
    UtilService.callApi(obj, (err, data) => {
      if (err) throw err;
      if (data && data.code === "OK") {
        this.setState({ bookedSlots: data.data.list });
      }
    });
  };

  handleSave = () => {
    let self = this;
    let { comment, slot, date, apiSlot, meetingType } = this.state;

    if (!slot)
      return OpenNotification({
        type: "error",
        title: "Please select slot.",
      });

    if (!date)
      return OpenNotification({
        type: "error",
        title: "Please select date.",
      });

    let start = moment(
      `${date} ${head(split(slot, " - "))}`,
      "YYYY-MM-DD HH:mm:ss A",
    ).toISOString();
    let end = moment(
      `${date} ${last(split(slot, " - "))}`,
      "YYYY-MM-DD HH:mm:ss A",
    ).toISOString();
    let obj = {
      purpose: comment,
      date: moment(date).toISOString(),
      type: 2,
      start: start,
      end: end,
      cabinSlot: [apiSlot],
      diamonds: this.props.checked.map((data) =>
        data.diamond ? data.diamond : data.id,
      ),
      meetingType: meetingType,
    };

    let objData = {
      ...CREATE_SCHEDULE,
      request: obj,
    };
    self.props.setLoading(true);

    UtilService.callApi(objData, function (err, data) {
      self.props.setLoading(false);
      if (err) {
        return OpenNotification({
          type: "success",
          title: err.data.message,
        });
      }
      if (data && data.code === "OK") {
        OpenNotification({
          type: "success",
          title: data.message,
        });
        self.props.onClose();
        self.props.clearAll();
      }
    });
  };

  getWorkingDays = (workingDays) => {
    let workingDates = [];
    let date = moment(new Date());
    while (workingDays > 0) {
      date = date.add(1, "days");
      if (date.isoWeekday() !== 7) {
        workingDays -= 1;
        workingDates.push(moment(date).toISOString());
      }
    }
    return workingDates;
  };

  _SlotItem = () => {
    let { disabledSlots, bidSlots, slots, slot, date, bookedSlots } =
      this.state;
    let finalSlots = this.props.bid ? bidSlots : slots;
    return (
      date &&
      finalSlots.map((d, index) => {
        let start = moment(d.start).format("hh:mm A");
        let end = moment(d.end).format("hh:mm A");
        let display = `${start} - ${end}`;
        let disabled =
          moment(`${moment(date).format("YYYY-MM-DD")} ${end}`) < moment();
        if (disabled) disabledSlots.push(display);
        // if (disabledSlots.length === finalSlots.length) {
        //   this.setState({ date: moment().startOf("day").add(1, "days") });
        // }

        const isBooked = bookedSlots.find(
          (s) =>
            moment(date).format("YYYY-MM-DD") ===
              moment(s?.date).format("YYYY-MM-DD") &&
            moment(d.start).format("hh:mm A") ===
              moment(s?.start).format("hh:mm A") &&
            moment(d.end).format("hh:mm A") ===
              moment(s?.end).format("hh:mm A"),
        );
        const bookedSlot = isBooked
          ? `${moment(isBooked?.start).format("hh:mm A")} - ${moment(
              isBooked?.end,
            ).format("hh:mm A")}`
          : null;

        return (
          <SlotItem
            onClick={() => this.setState({ slot: display, apiSlot: d })}
            time={display}
            className={
              slot === display || bookedSlot === display
                ? `soltBook`
                : disabled
                ? `allReadyBook`
                : ""
            }
            tooltip={
              bookedSlot === display ? (
                <IntlMessages id="app.customerCare.BookedAppointment" />
              ) : (
                <IntlMessages id="app.customerCare.BookAppointment" />
              )
            }
          />
        );
      })
    );
  };

  render() {
    const isMobile = window.innerWidth <= 767;
    let { companyName } = JSON.parse(
      window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)),
    );

    let workingDays = this.props.quoteDay ? 3 : 7;

    return (
      <>
        <div className="appoinmentOfficeRightTop">
          <Heading
            className="popupInnerTitle"
            title={<IntlMessages id="app.Appointment" />}
          ></Heading>
          <span className="companyName">({companyName})</span>
          {this.state.date && (
            <span className="appointmentDate">
              {moment(this.state.date).format("ddd, MMM Do YYYY")}
            </span>
          )}
        </div>
        <div className="officeBookWrapper">
          {!this.props.bid && (
            <div className="officeBookLeft">
              <div className="officeDateListing">
                <div className="officeTopLeft">
                  <LeftDatePicker
                    date={this.state.date ? this.state.date : ""}
                    Singledate
                    title={<IntlMessages id="app.SelectMonth" />}
                    onDateChange={(dt) => {
                      this.setState({ date: moment(dt).toISOString() });
                    }}
                    disabledDate={(current) => {
                      return (
                        current <= moment().startOf("day") ||
                        moment(current).isoWeekday() === 7
                      );
                    }}
                  />
                </div>
                <div className="cardListinOffice">
                  {this.getWorkingDays(workingDays).map((date) => (
                    <div
                      onClick={() => this.setState({ date })}
                      className={`cardListOfficeItem ${
                        moment(this.state.date).format("DD/MM/YYYY") ===
                          moment(date).format("DD/MM/YYYY") && `active`
                      }`}
                    >
                      <div className="cardListOfficeTop">
                        <div className="cardListOfficeDate">
                          {moment(date).format("ddd, MMM Do YYYY")}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="officeBookRight">
            <div className="soltWrapper">
              <div className="soltBookLeft">
                <div className="officeBookSolt smallScroll grayScroll">
                  <div className="officeBookSoltItem">
                    <div className="officeBookSoltTitle">
                      <IntlMessages id="app.SelectTimeSlot" />
                    </div>
                    <div className="officeBookSoltInner">
                      {this._SlotItem()}
                    </div>
                  </div>
                </div>
                {isMobile ? (
                  <div className="sideBarPopupButton">
                    <a className="commonButton" onClick={this.officeView}>
                      <IntlMessages id="app.RequestAppointment" />
                    </a>
                  </div>
                ) : (
                  <div className="selectVirtualAction">
                    <div className="commonTextArea from-group">
                      <label>
                        <IntlMessages id="app.comment" />
                      </label>
                      <IntlMessages id="app.comment">
                        {(placeholder) => (
                          <textarea
                            placeholder={placeholder}
                            onChange={(e) =>
                              this.setState({ comment: e.target.value })
                            }
                          ></textarea>
                        )}
                      </IntlMessages>
                    </div>
                    <div className="d-flex align-items-center j-space-between mt-20">
                      <SelectOption
                        value={this.state.meetingType}
                        placeholder={
                          <IntlMessages id="app.SelectVirtualType" />
                        }
                        //defaultValue={<IntlMessages id="app.SelectVirtualType" />}
                        selectValue={Appointment.virtualType}
                        label={<IntlMessages id="app.AvailableRoom" />}
                        onChange={(value) =>
                          this.setState({ meetingType: value })
                        }
                      />
                      <button onClick={() => this.handleSave()}>
                        <IntlMessages id="app.RequestAppointment" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.office && (
          <Drawer
            title={false}
            onClose={this.onClose}
            visible={this.state.office}
            className="diamondListinSidebar smallSidebarMobilePopup"
          >
            <OfficeFill />
          </Drawer>
        )}
      </>
    );
  }
}

export default OfficeSidebar;
