/* eslint-disable */
import React, { useState } from "react";
import InputBlock from "../common/InputBlock";
import "../TermsOfUse/staticContent.less";
import Heading from "../common/Heading";
import StaticHeader from "../StaticHeader";
import "./contact.less";
import isEmpty from "lodash/isEmpty";
import OpenNotification from "../common/CommonButton/OpenNotification";
import { CONTACT_US } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import { handleMobileNumber } from "../../services/Commonfunction";
import { CONTECT_US_LINK } from "../../constants/Common";
//import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const ContactUs = (props) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getMessage = (arr) => {
    var outStr = "";
    if (arr.length === 1) {
      outStr = arr[0];
    } else if (arr.length === 2) {
      outStr = arr.join(" and ");
    } else if (arr.length > 2) {
      outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
    }
    return outStr;
  };

  const validate = () => {
    let errFields = [];
    let required = [
      { key: "name", value: "Name" },
      { key: "company", value: "Company Name" },
      { key: "email", value: "Email" },
      { key: "messege", value: "Comment" },
    ];

    required.map((k) => {
      if (isEmpty(data[k.key]) || !data[k.key]) {
        errFields.push(k.value);
      }
    });
    let msg = getMessage(errFields);

    if (!isEmpty(errFields)) {
      OpenNotification({
        type: "error",
        title: `Please enter ${msg}`,
      });
      return false;
    } else if (
      data["email"] &&
      !data["email"].match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      OpenNotification({
        type: "error",
        title: `Please enter valid email address.`,
      });
      return false;
    } else return true;
  };

  const handleSubmit = () => {
    //
    if (validate()) {
      setIsLoading(true);
      let objData = {
        ...CONTACT_US,
        request: data,
      };
      UtilService.callApi(objData, async (err, data) => {
        if (err) throw err;
        if (data && data.code === "CREATED") {
          setData({
            name: "",
            company: "",
            address: "",
            mobile: "",
            email: "",
            messege: "",
          });
          OpenNotification({
            type: "success",
            title:
              "Your Valuable Comments, Suggestions are added successfully.",
          });
        }
        setIsLoading(false);
      });
    }
  };
  const mapStyles = {
    maxWidth: "1140px",
    height: "100%",
  };

  return (
    <>
      {!props.isDevice && <StaticHeader />}

      <div
        className={`staticBoxMain white-bg ${isLoading ? `loading-indicator` : ''}`}
      >
        <div className="statiContentBox">
          <div className="text-center mb-50">
            <Heading title="Contact Us" className="staticMainTitle" />
            <p>
              Your Valuable Comments, Suggestions, Inputs, Queries, Inquries and
              / or Concerns are of Value to us.
            </p>
          </div>
          <div className="d-flex j-space-between contactMainWrapper">
            <div className="contactLeft">
              <div className="contactImg">
                <img src={require("../../assets/img/logo1.png")} alt="" />
              </div>
              <div className="contactBasicDetail d-flex flex-wrap">
                <div className="contactBasicDetailItem">
                  <h2>Online Service</h2>
                  <div className="detail">
                    <div className="detailIcon">
                      {/* <img src={require("../../assets/svg/Contact/mail.svg")} alt=""/> */}
                      Mail :
                    </div>
                    <a
                      href={`mailto:${CONTECT_US_LINK.MAIL_TO}`}
                      rel="noopener noreferrer"
                    >
                      <div className="detailTitle">info@belgiumgroup.net</div>
                    </a>
                  </div>
                  <div className="detail">
                    <div className="detailIcon">
                      {/* <img src={require("../../assets/svg/email.svg")} alt=""/> */}
                      Rapnet :
                    </div>
                    <a
                      href={CONTECT_US_LINK.RAP_NET}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="detailTitle">61666</div>
                    </a>
                  </div>
                  <div className="detail">
                    <div className="detailIcon">
                      Idex :
                      {/* <img src={require("../../assets/svg/email.svg")} alt=""/> */}
                    </div>
                    <div className="detailTitle">Belgium</div>
                  </div>
                  <div className="detail">
                    <div className="detailIcon">
                      Polygon :
                      {/* <img src={require("../../assets/svg/email.svg")} alt=""/> */}
                    </div>
                    <div className="detailTitle">71185</div>
                  </div>
                  <div className="detail">
                    <div className="detailIcon">
                      VDB ID :
                      {/* <img src={require("../../assets/svg/email.svg")} alt=""/> */}
                    </div>
                    <div className="detailTitle">Belgium NY LLC</div>
                  </div>
                </div>
                <div className="contactBasicDetailItem">
                  <h2>Phone</h2>
                  <div className="detail">
                    <div className="detailIcon">
                      Phone No :
                      {/* <img src={require("../../assets/svg/phonecall.svg")} alt=""/> */}
                    </div>
                    <a
                      href={`tel:${CONTECT_US_LINK.PHONE_No}`}
                      className="btn btn-success"
                    >
                      <div className="detailTitle">+1 (212) 937-8715</div>
                    </a>
                  </div>
                  <div className="detail">
                    <div className="detailIcon">
                      WhatsApp No :
                      {/*<img src={require("../../assets/svg/phonecall.svg")} alt="" />*/}
                    </div>
                    <a
                      href={CONTECT_US_LINK.WHATS_APP}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="detailTitle">+1 (347) 701-0415</div>
                    </a>
                  </div>
                  <div className="detail">
                    <div className="detailIcon">
                      Fax No :
                      {/* <img src={require("../../assets/svg/phonecall.svg")} alt=""/> */}
                    </div>
                    <div className="detailTitle">+1 (646) 843-4966</div>
                  </div>
                </div>
                <div className="contactBasicDetailItem width-100">
                  <h2>Address</h2>
                  <div className="detail">
                    <div className="detailIcon">
                      <img
                        src={require("../../assets/svg/Contact/location.svg")}
                        alt=""
                      />
                    </div>
                    <div className="detailTitle">
                      20 W 47th St Suite 500, New York, NY 10036, USA
                    </div>
                  </div>
                  <a
                    href="https://goo.gl/maps/rg2xUYRyPgw95qu47"
                    target="_blank"
                    style={{ marginLeft: "1.4rem", fontWeight: 500 }}
                    rel="noreferrer"
                  >
                    Find us on map
                  </a>
                </div>
              </div>
            </div>
            <div className="contactRight">
              <div className="contactform">
                {/* <form> */}
                <InputBlock
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  label="Person Name*"
                  placeholder="Person Name"
                />
                <InputBlock
                  value={data.company}
                  onChange={(e) =>
                    setData({ ...data, company: e.target.value })
                  }
                  label="Company Name*"
                  placeholder="Company Name"
                />
                <InputBlock
                  value={data.address}
                  onChange={(e) =>
                    setData({ ...data, address: e.target.value })
                  }
                  label="Address "
                  placeholder="Address"
                />
                <InputBlock
                  type="number"
                  value={data.mobile}
                  label="Contact No "
                  onKeyPress={handleMobileNumber}
                  placeholder="Contact No"
                  onChange={(e) => setData({ ...data, mobile: e.target.value })}
                />
                <InputBlock
                  value={data.email}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                  label="Email*"
                  placeholder="Email"
                />
                <InputBlock
                  value={data.messege}
                  onChange={(e) =>
                    setData({ ...data, messege: e.target.value })
                  }
                  label="Comments*"
                  placeholder="Comments"
                />
                <button onClick={handleSubmit} className="commonButton">
                  Submit
                </button>
                {/* </form> */}
              </div>
            </div>
          </div>
          {/* <div className="mapContainer">
            <Map
              width="100%"
              google={props.google}
              zoom={14}
              style={mapStyles}
              initialCenter={{
                lat: 19.06626370281091,
                lng: 72.8652481371938,
              }}
            >
              <Marker name={"Kenyatta International Convention Centre"} />
            </Map>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ContactUs;

// export default GoogleApiWrapper((props) => ({
//   apiKey: "AIzaSyBHHa9BSaf_EdABnUBhtKXKfW6RWAASERE",
// }))(ContactUs);
