/* eslint-disable */
import React from "react";
import { Dropdown, Tooltip } from "antd";
import SortingFilterDropdown from "./OverlayDropdownBack";
import { FILTER_COLUMNS } from "./TableBack";

const TableHeaderBack = ({
  column,
  FilterOption,
  nodots,
  //sort filter
  sortBy = [],
  setSortBy,
  toggleSortBy,
}) => {
  let sortedColumnIndex = column.sortedIndex;
  let asc = column.isSortedDesc === false;

  return (
    <Dropdown
      overlayClassName="tabelDropdownClass"
      key={`filterOverlayDropdown${column.id}`}
      overlay={
        column.isSorted ||
        (FilterOption !== false && FILTER_COLUMNS.includes(column.id)) ? (
          <SortingFilterDropdown
            //sorting props
            setSortBy={setSortBy}
            sortBy={sortBy}
            toggleSortBy={toggleSortBy}
            //common props
            column={column}
          />
        ) : (
          <></>
        )
      }
      trigger={["click"]}
    >
      <div
        className={`tableHeaderMain ${nodots && "squeceChange"} ${
          !column.isSorted && "shortingBoxShow"
        }`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {!nodots && (
          <span className="listIcon">
            <img
              src={require("../../assets/svg/DiamondList/sorting.svg")}
              alt="icon"
            />
          </span>
        )}
        {column.desc ? (
          <Tooltip
            placement="bottom"
            key={`headerDesc${column.desc}`}
            title={column.desc}
          >
            <span className="taleHeadTitle">{column.render("Header")}</span>
          </Tooltip>
        ) : (
          <span className="taleHeadTitle">{column.render("Header")}</span>
        )}
        {column.isSorted ? (
          <div className="selectShortingMainBlock">
            <div className="selectShorting" {...column.getSortByToggleProps()}>
              <span
                className="selectShortingCount"
                style={!column.isSorted ? { margin: 0 } : {}}
              >
                {sortedColumnIndex + 1}
              </span>
              {column.isSorted && (
                <span className="selectShortingArrow">
                  <img
                    src={
                      asc
                        ? require("../../assets/svg/DiamondList/shortinglistup-white.svg")
                        : require("../../assets/svg/DiamondList/shortinglistdown-white.svg")
                    }
                    alt=""
                  />
                </span>
              )}
            </div>
            <div
              className="shortingClose"
              onClick={(e) => {
                e.stopPropagation();
                column.clearSortBy();
              }}
            >
              <img
                src={require("../../assets/svg/DiamondList/close.svg")}
                alt="icon"
              />
            </div>
          </div>
        ) : null}
        {column.canSort && !column.isSorted && (
          <div className="shortingRound" {...column.getSortByToggleProps()}>
            <div className="shortingRoundInner">
              <img
                src={require("../../assets/svg/DiamondList/shortinglistup.svg")}
                alt="icon"
              />
              <img
                src={require("../../assets/svg/DiamondList/shortinglistdown.svg")}
                alt="icon"
              />
            </div>
          </div>
        )}
      </div>
    </Dropdown>
  );
};
export default React.memo(TableHeaderBack);
