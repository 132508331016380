/* eslint-disable */
import React, { Component } from "react";
import IntlMessages from "../util/IntlMessages";
import UtilService from "../services/util";
import OpenNotification from "./common/CommonButton/OpenNotification";
import Heading from "./common/Heading";
import {
  includes,
  concat,
  each,
  head,
  last,
  groupBy,
  filter,
  isArray,
  capitalize,
  map,
} from "lodash";
import { LOCAL_STORAGE_VAR } from "../constants/Common";
import { QUICK_SEARCH, GET_DIAMOND_DATA } from "../constants/apiConstant";
import { getGridHeight } from "./DiamondList";
import { LISTINGPAGES } from "./DiamondList/DiamondListFunctions";
import { svgFiles } from "./common/Svg";

const quickSearch = [
  {
    colors: [
      {
        id: "D",
        name: "DEF",
      },
      {
        id: "E",
        name: "DEF",
      },
      {
        id: "F",
        name: "DEF",
      },
      {
        id: "G",
        name: "GHI",
      },
      {
        id: "H",
        name: "GHI",
      },
      {
        id: "I",
        name: "GHI",
      },
      {
        id: "J",
        name: "J-",
      },
      {
        id: "K",
        name: "J-",
      },
      {
        id: "L",
        name: "J-",
      },
      {
        id: "M",
        name: "J-",
      },
      {
        id: "N",
        name: "J-",
      },
      {
        id: "O",
        name: "J-",
      },
      {
        id: "P",
        name: "J-",
      },
      {
        id: "Q-R",
        name: "J-",
      },
      {
        id: "S-T",
        name: "J-",
      },
      {
        id: "U-V",
        name: "J-",
      },
      {
        id: "W-X",
        name: "J-",
      },
      {
        id: "Y-Z",
        name: "J-",
      },
    ],
    clarity: [
      {
        id: "LC",
        name: "LC",
      },
      {
        id: "FL",
        name: "FL-IF",
      },
      {
        id: "IF",
        name: "FL-IF",
      },
      {
        id: "VVS1",
        name: "VVS",
      },
      {
        id: "VVS2",
        name: "VVS",
      },
      {
        id: "VS1",
        name: "VS",
      },
      {
        id: "VS1-",
        name: "VS",
      },
      {
        id: "VS1+",
        name: "VS",
      },
      {
        id: "VS2",
        name: "VS",
      },
      {
        id: "VS2-",
        name: "VS",
      },
      {
        id: "VS2+",
        name: "VS",
      },
      {
        id: "SI1",
        name: "SI",
      },
      {
        id: "SI1-",
        name: "SI",
      },
      {
        id: "SI1+",
        name: "SI",
      },
      {
        id: "SI2",
        name: "SI",
      },
      {
        id: "SI2+",
        name: "SI",
      },
      {
        id: "SI2-",
        name: "SI",
      },
      {
        id: "SI3",
        name: "SI",
      },
      {
        id: "SI3-",
        name: "SI",
      },
      {
        id: "SI3+",
        name: "SI",
      },
      {
        id: "I1",
        name: "I",
      },
      {
        id: "I1-",
        name: "I",
      },
      {
        id: "I1+",
        name: "I",
      },
      {
        id: "I2",
        name: "I",
      },
      {
        id: "I2-",
        name: "I",
      },
      {
        id: "I2+",
        name: "I",
      },
      {
        id: "I3",
        name: "I",
      },
      {
        id: "I3-",
        name: "I",
      },
      {
        id: "I3+",
        name: "I",
      },
    ],
  },
];

class QuickSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shape: [],
      caratSizeGroup: {},
      finalResult: [],
      selectedShapes: [],
      searched0: "",
      searched1: "",
      searched2: "",
      isLoader: false,
    };
  }

  getData() {
    const masterData = JSON.parse(
      localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`),
    );
    if (masterData) {
      this.setState(
        {
          shape: masterData.SHAPE,
          caratSizeGroup: groupBy(masterData.SIZE, "group"),
        },
        () => {
          this.selectedMaster([]);
        },
      );

      let colorGroup = groupBy(quickSearch[0].colors, "name");
      let clarityGroup = groupBy(quickSearch[0].clarity, "name");
      let carats = groupBy(masterData.SIZE, "group");
      let colorIds = [];
      let clarityIds = [];
      let finalResult = [];

      each(colorGroup, (val1) => {
        let arr = [];
        each(val1, (val2) => {
          each(masterData.COLOR, (clr) => {
            if (val2.id === clr.webDisplay) {
              arr = concat(arr, clr.id);
            }
          });
        });
        colorIds.push({ group: val1[0].name, Ids: arr });
      });

      each(clarityGroup, (val1) => {
        let arr = [];
        each(val1, (val2) => {
          each(masterData.CLARITY, (clar) => {
            if (val2.id === clar.webDisplay) {
              arr = concat(arr, clar.id);
            }
          });
        });
        clarityIds.push({ group: val1[0].name, Ids: arr });
      });

      each(colorIds, (val1) => {
        let clarity = [];
        each(clarityIds, (val2) => {
          let carat = [];
          each(carats, (val3) => {
            carat.push({
              caratName: val3[0].group,
              from: head(val3).fromCarat,
              to: last(val3).toCarat,
              count: 0,
            });
          });
          clarity.push({
            clarityName: val2.group,
            clarityIds: val2.Ids,
            carat: carat,
          });
        });
        finalResult.push({
          colorName: val1.group,
          colorIds: val1.Ids,
          clarity: clarity,
        });
      });
      this.setState({ finalResult: finalResult });
    }
  }

  componentDidMount() {
    let self = this;
    window.addEventListener("resize", function () {
      self.forceUpdate();
    });
    this.getData();
  }

  selectedMaster = (selectedArray) => {
    let sizes = [];
    let self = this;
    self.setState({ selectedShapes: selectedArray, isLoader: true });

    each(this.state.caratSizeGroup, (size) => {
      sizes.push({
        id: size[0].group,
        from: head(size).fromCarat,
        to: last(size).toCarat,
      });
    });

    let apiObj = {
      range: sizes,
      shp: selectedArray,
    };

    let objData = {
      ...QUICK_SEARCH,
      request: apiObj,
    };

    let finalResult = self.state.finalResult;
    each(finalResult, (val1) => {
      each(val1.clarity, (val2) => {
        each(val2.carat, (val3) => {
          val3.count = 0;
        });
      });
    });

    UtilService.callApi(objData, function (err, data) {
      if (err) throw err;
      if (!data) return;
      let result = data.data.list;
      result = groupBy(result, "color");
      each(result, (colorVal, colorKey) => {
        result[colorKey] = groupBy(colorVal, "clarity");
        each(result[colorKey], (clarityVal, clarityKey) => {
          result[colorKey][clarityKey] = groupBy(clarityVal, "pointer");
          each(result[colorKey][clarityKey], (pointerVal, pointerKey) => {
            result[colorKey][clarityKey][pointerKey] = { count: 0 };
            each(pointerVal, (pointerVal) => {
              result[colorKey][clarityKey][pointerKey].count +=
                pointerVal.count || 0;
            });
          });
        });
      });

      // eslint-disable-next-line array-callback-return
      Object.keys(result).map((colorKey) => {
        each(finalResult, (val1) => {
          each(val1.colorIds, (clrId) => {
            if (clrId === colorKey) {
              each(val1.clarity, (clar) => {
                each(clar.clarityIds, (clarId) => {
                  // eslint-disable-next-line array-callback-return
                  Object.keys(result[colorKey]).map((clarKey) => {
                    if (clarKey === clarId) {
                      each(clar.carat, (car) => {
                        // eslint-disable-next-line array-callback-return
                        Object.keys(result[colorKey][clarKey]).map(
                          (caratKey) => {
                            if (caratKey === car.caratName) {
                              car.count +=
                                result[colorKey][clarKey][caratKey].count;
                            }
                          },
                        );
                      });
                    }
                  });
                });
              });
            }
          });
        });
      });

      self.setState({
        finalResult: finalResult,
        searched0: "",
        searched1: "",
        searched2: "",
        isLoader: false,
      });
    });
  };

  handleSearch(caratFrom, caratTo, clar, color, index0, index1, index2) {
    let self = this;
    let apiObj = {
      shp: self.state.selectedShapes,
      col: color,
      or: [{ crt: { ">=": caratFrom, "<=": caratTo } }],
      clr: clar,
    };

    each(apiObj, (val, key) => {
      if (isArray(val) && val.length === 0) delete apiObj[key];
      else if (isArray(val)) apiObj[key] = val;
    });

    let obj = {
      filters: [apiObj],
      isNotReturnTotal: true,
      isReturnCountOnly: true,
    };

    let objData = {
      ...GET_DIAMOND_DATA,
      request: obj,
    };

    UtilService.callApi(objData, function (err, data) {
      if (err) {
        if (err.data && err.data.message) {
          self.setState({
            searched1: index1,
            searched2: index2,
            searched0: index0,
          });
          return OpenNotification({
            type: "error",
            title: err.data.message,
          });
        }
      }
      if (data && data.code === "OK") {
        self.setState({
          searched1: index1,
          searched2: index2,
          searched0: index0,
        });
        window.open(`/${LISTINGPAGES.LIST}?${data.data[0].filter.id}`);
      }
    });
  }

  render() {
    const carats = Object.keys(this.state.caratSizeGroup);
    const { finalResult, shape, selectedShapes, isLoader } = this.state;
    return (
      <React.Fragment>
        <div
          className={`quickSearchMainWrapper ${
            isLoader ? `loading-indicator` : ''
          }`}
        >
          <div className="d-flex justify-content-between quickSearchTopHeader">
            <Heading title="Quick Search" />
          </div>

          <div className="diamondSearchDetail width-100">
            <div className="diamondSearch">
              <div className="diamondSearchWrapper">
                <div className="diamondSearchItem">
                  <div className="diamondSearchDetail width-100">
                    <ul
                      className={`diamondSearchShape ${
                        this.state.shapeExtend ? "active" : ''
                      }`}
                    >
                      {shape &&
                        shape.map((s) => {
                          s.checked = false;
                          each(s.id, function (id) {
                            if (selectedShapes && selectedShapes.includes(id)) {
                              s.checked = true;
                            }
                          });
                          return (
                            <li
                              className={s.checked ? `active` : ''}
                              onClick={
                                (e) => {
                                  let sel = selectedShapes;
                                  if (includes(sel, s.id[0])) {
                                    sel = filter(sel, (val) => val !== s.id[0]);
                                  } else {
                                    sel = [...sel, s.id[0]];
                                  }
                                  this.selectedMaster(sel);
                                }

                                //this.props.handleSelection(s.id, !s.checked, "shp", "shape")
                              }
                            >
                              <span>
                                {svgFiles.find((svg) => svg.name === s.code)
                                  ? svgFiles.find((svg) => svg.name === s.code)
                                      .file
                                  : ""}
                              </span>
                              <span>{capitalize(s.webDisplay)}</span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="quickSearchRight">
            <div className="quickSearchTable">
              <div className="settingLeftScroll">
                <table>
                  <tbody>
                    <tr key="tr0">
                      <th
                        scope="col"
                        rowSpan={2}
                        valign="middle"
                        style={{ textAlign: "center" }}
                      >
                        <IntlMessages id="app.color" />
                      </th>
                      <th
                        scope="col"
                        rowSpan={2}
                        valign="middle"
                        style={{ textAlign: "center" }}
                      >
                        <IntlMessages id="app.clarity" />
                      </th>
                      <th
                        colSpan={carats.length}
                        scope="col"
                        style={{ textAlign: "center" }}
                      >
                        <IntlMessages id="app.carat" />
                      </th>
                    </tr>
                    <tr className="rowback" key="tr1">
                      {map(carats, (val) => {
                        return (
                          <td key={val} style={{ textAlign: "center" }}>
                            {val}
                          </td>
                        );
                      })}
                    </tr>
                    {Object.keys(finalResult).map((key, index0) => {
                      return finalResult[key].clarity.map((clar, index1) => {
                        return (
                          <tr
                            key={index1 + "intr"}
                            className="quick-search-title-cell"
                            style={{ textAlign: "center" }}
                          >
                            {index1 === 0 && (
                              <td
                                className="quick-search-title-cell"
                                style={{ textAlign: "center" }}
                                rowSpan={finalResult[key].clarity.length}
                              >
                                {finalResult[key].colorName}
                              </td>
                            )}
                            <td
                              className="quick-search-title-cell"
                              style={{ textAlign: "center" }}
                            >
                              {clar.clarityName}
                            </td>

                            {clar.carat.map((carat, index2) => {
                              carat.selected = false;

                              if (
                                this.state.searched2 === index2 &&
                                this.state.searched1 === index1 &&
                                this.state.searched0 === index0
                              )
                                carat.selected = true;
                              return (
                                <td
                                  key={carat + index2}
                                  onClick={this.handleSearch.bind(
                                    this,
                                    carat.from,
                                    carat.to,
                                    clar.clarityIds,
                                    finalResult[key].colorIds,
                                    index0,
                                    index1,
                                    index2,
                                  )}
                                  className={
                                    carat.count === 0
                                      ? "pointer-events-none"
                                      : carat.selected
                                      ? "active"
                                      : ""
                                  }
                                  style={{ textAlign: "center" }}
                                >
                                  {carat.count === 0 ? "-" : carat.count}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      });
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default QuickSearch;
