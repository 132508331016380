/* eslint-disable */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import getData from "../common/svgSidebar";
import _ from "lodash";
import { connect } from "react-redux";
import "react-tippy/dist/tippy.css";
import IntlMessages from "../../util/IntlMessages";
import HeaderStrip from "../common/Header/HeaderStrip";
import { commonLogout, LOCAL_STORAGE_VAR } from "../../constants/Common";
import { handleSearch } from "../../containers/APIFunctions";
import { GET_DASHBOARD_DATA } from "../../constants/apiConstant";
import UtilService from "../../services/util";
class MobileSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      visible1: false,
      visible2: false,
      visible3: false,
      rates: {},
    };
  }

  getSidebarCounts = () => {
    let self = this;
    let obj = {
      savedSearch: false,
      recentSearch: false,
      track: false,
      orderData: false,
      dashboardCount: false,
      shippingCount: false,
      seller: false,
      account: false,
      rates: true,
    };
    let api = GET_DASHBOARD_DATA.url;
    let objData = {
      method: GET_DASHBOARD_DATA.method,
      url: api,
      request: obj,
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === "OK") {
        self.setState({
          // details: data.data.tracks,
          rates: data.data.rateObj,
          // counts: _.omit(data.data, "tracks"),
        });
      }
    });
  };

  componentDidMount() {
    this.getSidebarCounts();
  }

  submenu = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };
  submenu1 = () => {
    this.setState({
      visible1: !this.state.visible1,
    });
  };
  submenu2 = () => {
    this.setState({
      visible2: !this.state.visible2,
    });
  };
  submenu3 = () => {
    this.setState({
      visible3: !this.state.visible3,
    });
  };
  render() {
    const crnt_url = window.location.pathname;
    let permissionData = this.props?.commonData?.pagePermission();
    let sidebarSvgFiles = getData(permissionData);
    let cuIndex = _.findIndex(sidebarSvgFiles, { url: crnt_url });
    return (
      <div className="headerMenuBox">
        <div className="headerMeniInner">
          <HeaderStrip
            // className={btnClass || btnClass1 || btnClass2}
            // onClick={this.HeaderStripCick}
            rates={this.state.rates}
          />
          <ul id="links">
            <li>
              <Link
                className={`menuItem ${crnt_url === `/dashboard` ? `active` : ''}`}
                to="/dashboard"
                onClick={() => this.props?.onDrawerClick()}
              >
                <IntlMessages id="app.home" />
              </Link>
            </li>
            {sidebarSvgFiles.map((dataSvg, index) => {
              return (
                <li key={index} id={`icon_click${index}`}>
                  <div>
                    {!this.state.isGuest ||
                    dataSvg?.url === "/diamond-search" ? (
                      <Link
                        className={`menuItem ${index === cuIndex ? `active` : ''}`}
                        to={dataSvg?.url}
                        onClick={() => this.props?.onDrawerClick()}
                      >
                        {dataSvg?.name}
                      </Link>
                    ) : (
                      <Link
                        to=""
                        onClick={() => {
                          this.setState({
                            showGuest: true,
                          });
                        }}
                        className={`menuItem ${index === cuIndex ? `active` : ''}`}
                      >
                        {dataSvg?.name}
                      </Link>
                    )}
                  </div>
                </li>
              );
            })}
            <li
              id={`icon_click${sidebarSvgFiles.length + 1}`}
              className={"d-flex align-items-center"}
              onClick={commonLogout}
            >
              <a className="menuItem">Log Out</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

// export default withRouter(Sidebar);
const mapStateToProps = function (props) {
  return props;
};

export default connect(mapStateToProps)(withRouter(MobileSidebar));
