/* eslint-disable */
import React from "react";
import { BASE_URL_ATTACH } from "constants/Common";
import "./salesPerson.less";

const SalesPerson = (props) => {
  return (
    <>
      <div className="salesPeronInnerDetail">
        {props.salesData?.photoId ? (
          <div className="leftSalesPeronDetail">
            <img
              className="profilePicImg"
              src={`${BASE_URL_ATTACH}/${props.salesData?.photoId}`}
              alt="profile"
            />
          </div>
        ) : null}

        <div>
          {props.email === "--" ? null : (
            <div className="salesPersonInnerItem">
              <div
                onClick={() => {
                  window.open(`mailto:${props?.email}`, "_blank");
                }}
                className="salesPersonIcon"
              >
                <img
                  src={require("../../../assets/svg/emailgreen.png")}
                  alt="icon"
                />
              </div>
              <div className="salespersonInnerDetail">{props?.email}</div>
            </div>
          )}
          {props.phone === "--" ? null : (
            <div className="salesPersonInnerItem">
              <div
                className="salesPersonIcon"
                onClick={() => {
                  window.open(`tel:${props.phone}`, "_blank");
                }}
              >
                <img
                  src={require("../../../assets/svg/phonecall2.svg")}
                  alt="icon"
                />
              </div>
              <div className="salespersonInnerDetail">{props.phone}</div>
            </div>
          )}
          <div className="salesPersonInnerItem">
            {props.phone === "--" ? null : (
              <div
                onClick={() => {
                  window.open(
                    `https://wa.me/${props.phone}?text=I need help with `,
                    "_blank",
                  );
                }}
                className="salesPersonIcon mr-5"
              >
                <img
                  src={require("../../../assets/svg/Dashboard/whatsapp.svg")}
                  alt="icon"
                />
              </div>
            )}

            {/* {props.phone === "--" || !isMobile() ? null : (
            <div onClick={() => { window.open(`tel:${props.phone}`, "_blank"); }} className="salesPersonIcon " >
              <img src={require("../../../assets/svg/Dashboard/chat.svg")} alt="icon" />
            </div>
          )} */}
            {props.phone === "--" ? null : (
              <div
                onClick={() => {
                  window.open(`weixin://dl/chat?${props.phone}`, "_blank");
                }}
                className="salesPersonIcon "
              >
                <img
                  src={require("../../../assets/svg/Dashboard/wechat.svg")}
                  alt="icon"
                />
              </div>
            )}
            {props.phone === "--" ? null : (
              <div
                onClick={() => {
                  window.open("https://web.skype.com/share?", "_blank");
                }}
                className="salesPersonIcon mr-5"
              >
                <img
                  src={require("../../../assets/svg/Dashboard/skype.svg")}
                  alt="icon"
                />
              </div>
            )}
            {props.phone === "--" ? null : (
              <div
                onClick={() => {
                  window.open(`https://meet.google.com/`, "_blank");
                }}
                className="salesPersonIcon "
              >
                <img
                  src={require("../../../assets/svg/Dashboard/gmeet.svg")}
                  alt="icon"
                />
              </div>
            )}
            {/* <div className="salespersonInnerDetail">{props.phone}</div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default SalesPerson;
