/* eslint-disable */
import React from "react";
import SortingFilterDropdownItem from "./SortingFilterDropdownItem";

const SortingFilterDropdown = (props) => {
  return (
    <div className="menuInnerFilterOption">
      <div className="menuInnerFilterInner">
        <div className="llSortingTitle">Sorting Order</div>
        {props.OtherTable ? (
          <div className="llSortingTitleList">
            {props.data.map((d, index) => (
              <SortingFilterDropdownItem title={d.title} />
            ))}
          </div>
        ) : (
          <div className="llSortingTitleList">
            <SortingFilterDropdownItem title="Packet No" />
            <SortingFilterDropdownItem title="Shape" />
            <SortingFilterDropdownItem title="Carat" />
            <SortingFilterDropdownItem title="Clarity" />
            <SortingFilterDropdownItem title="Color" />
            <SortingFilterDropdownItem title="Discount" />
            <SortingFilterDropdownItem title="Amount" />
          </div>
        )}
        {props.FilterOption === false ? null : (
          <div className="llfilterMenuDropdown">
            <ul className="llfilterSelect">
              <div className="llSortingTitle">Selected Filter</div>
              <li>
                D
                <div className="closeFilter">
                  <img
                    src={require("../../../assets/svg/DiamondList/close-black.svg")}
                    alt="icon"
                  />
                </div>
              </li>
              <li>
                E
                <div className="closeFilter">
                  <img
                    src={require("../../../assets/svg/DiamondList/close-black.svg")}
                    alt="icon"
                  />
                </div>
              </li>
            </ul>
            <ul>
              <div className="llSortingTitle">Select Filter</div>
              <li>F</li>
              <li>G</li>
              <li>H</li>
              <li>I</li>
              <li>J</li>
              <li>K</li>
              <li>L</li>
              <li>M</li>
              <li>N</li>
              <li>o-p</li>
              <li>s-T</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
export default SortingFilterDropdown;
