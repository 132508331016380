/* eslint-disable */
import React, { Component } from "react";
import DiamondListingAction from "../../common/DiamondListing/DiamondListingAction";
import { LISTINGPAGES } from "../../../components/DiamondList/DiamondListFunctions";
class MobileDiamondDetail extends Component {
  render() {
    let data = this.props.data;
    let parent = this.props.parent;

    return (
      <div className="mobilediamondDetail">
        <div className="mobilediamond diamondDetailLeft">
          {parent.leftPart(data)}
        </div>
        <div className="diamondDetailRight mobileRightDetail">
          {this.props.params}
        </div>
        {window.location.pathname.split("/")[1] !== LISTINGPAGES.DETAILS && (
          <DiamondListingAction
            nowatch
            noquote
            nofinalcalc
            nooffice
            nonote
            nonocompare
            noshare
            noreminder
            nodownload
            noprint
            nopaginate
            checked={data ? [data] : []}
            viewcerti
            downloadzip
            sharexray
            downloadZip={() => parent.downloadZip(data)}
            verifyCerti={() => parent.verifyCerti(data)}
            shareXray={parent.sendMail}
          />
        )}
        {parent.getEmailModal(data)}
      </div>
    );
  }
}
export default MobileDiamondDetail;
