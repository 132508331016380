/* eslint-disable */
import React from "react";
import IntlMessages from "../../util/IntlMessages";
import { LOCAL_STORAGE_VAR, IS_QUICK_SEARCH } from "../../constants/Common";
const isMobile = window.innerWidth <= 767;

export default function getData(USER_PERMIT) {
  let sidebarArr = [];

  if (USER_PERMIT && USER_PERMIT?.SEARCH_DIAMOND?.view) {
    sidebarArr.push({
      name: <IntlMessages id="app.Search" />,
      url: "/diamond-search",
      subMenu: false,
    });
  }
  if (USER_PERMIT && USER_PERMIT?.NEW_ARRIVAL_BID_IT?.view) {
    sidebarArr.push({
      name: <IntlMessages id="app.newArrival" />,
      url: "/new-arrival-bid-it",
      subMenu3: false,
    });
  }
  // if (USER_PERMIT && USER_PERMIT?.EXCLUSIVE?.view) {
  //   sidebarArr.push({
  //     name: <IntlMessages id="app.FinestarExclusive" />,
  //     url: "/finestar-exclusive",
  //   });
  // }
  if (USER_PERMIT && USER_PERMIT?.UPCOMING?.view) {
    sidebarArr.push({
      name: <IntlMessages id="app.Upcoming" />,
      url: "/upcoming",
      subMenu4: false,
    });
  }
  // if (USER_PERMIT && USER_PERMIT?.COMING_SOON?.view) {
  //   sidebarArr.push({
  //     name: 'Coming Soon',
  //     url: "/coming-soon",
  //     subMenu4: false,
  //   });
  // }
  // if(USER_PERMIT && USER_PERMIT?.TRANSACTIONS?.view){
  //   sidebarArr.push({
  //     name: <IntlMessages id="app.transactions"/>,
  //     url: isMobile ? "/account" : "/account/watchlist",
  //     subMenu: false,
  //   });
  // }
  if (USER_PERMIT && USER_PERMIT?.QUOTE_DAY?.view) {
    if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-quoteDay`)) {
      sidebarArr.push({
        name: <IntlMessages id="app.QuoteDay" />,
        url: "/quote-day",
      });
    }
  }
  if (USER_PERMIT && USER_PERMIT?.SUGGESTED_STOCK?.view) {
    sidebarArr.push({
      name: <IntlMessages id="app.suggestedStock" />,
      url: "/suggested-stock",
    });
  }
  // if (USER_PERMIT && USER_PERMIT?.TRENDING?.view) {
  //   sidebarArr.push({
  //      name: <IntlMessages id="app.trending" />,
  //      url: "/trending",
  //   });
  // }
  if (USER_PERMIT && USER_PERMIT?.MADE_IN_BOTSWANA?.view) {
    sidebarArr.push({
      name: <IntlMessages id="app.meettheartisan" />,
      url: "/meet-the-artisan",
    });
  }
  if (IS_QUICK_SEARCH && USER_PERMIT && USER_PERMIT?.QUICK_SEARCH?.view) {
    sidebarArr.push({
      name: <IntlMessages id="app.quick-search" />,
      url: "/quick-search",
    });
  }

  return sidebarArr;
}
