/* eslint-disable */
import React, { useState } from "react";
import {
  handleConfirmStone,
  handleDownloadExcel,
  isMobile,
} from "../../DiamondList/DiamondListFunctions";
import invert from "lodash/invert";
import size from "lodash/size";
import Table from "../../DiamondList/TableBack";
import SelectOption from "../SelectOption";
import Heading from "../Heading";
import InputBlock from "../InputBlock";
import OpenNotification from "../CommonButton/OpenNotification";
import { useSelector } from "react-redux";
import { formatNumber, calculate } from "./SelectStone";
import SendEmailPopup from "./SendEmail";
import { LOCAL_STORAGE_VAR } from "../../../constants/Common";
import MobileConfirmStone from "./MobileComfirmStone";
import TextArea from "../TextArea";
import IntlMessages from "../../../util/IntlMessages";

/* eslint jsx-a11y/anchor-is-valid: 0 */

export const savedSearch = {
  saleman: [{ value: "John Doe" }, { value: "John Doe" }],
  invoicDate: [
    { id: "1", name: "Today" },
    { id: "2", name: "Tommorrow" },
    { id: "3", name: "Later" },
  ],
  country: [{ value: "India" }, { value: "Hong Kong" }, { value: "US" }],
  city: [{ value: "Gujarat" }, { value: "baroda" }, { value: "Vapi" }],
  billType: [{ value: "Dollar Bill" }],
  couriername: [{ value: "Any" }, { value: "Malca Amit4" }],
  sale: [{ value: "Idex Ok" }, { value: "Blue Nile Ok" }],
  terms: [{ value: "7 Days" }, { value: "Advance" }, { value: "COD" }],
  assi: [
    { value: "Vishal Virani" },
    { value: "Vishal Virani" },
    { value: "Vishal Virani" },
  ],
};
const currentType = "ConfirmStonePopup";

export function HeadingCalc(checked, VALUES = null) {
  if (isMobile() && !checked.length && !VALUES) return null;
  let obj = {};
  if (VALUES) obj = VALUES;
  else {
    let sum = calculate(checked);
    obj = {
      "CT. :": parseFloat(sum.total_carat).toFixed(2),
      "Disc% :": parseFloat(sum.final_discount).toFixed(2),
      "Price/Ct :": formatNumber(parseFloat(sum.final_price).toFixed(2)),
      "Total Value :": formatNumber(parseFloat(sum.final_value).toFixed(2)),
    };
  }
  if (isMobile()) obj = invert(obj);
  if (size(obj) < 4) return null;
  return (
    <div className="DiamondDetailPopup">
      {Object.keys(obj).map((x) => {
        return (
          <div className="DiamondDetailPopupItem" style={VALUES ? checked : {}}>
            <span>{x}</span>
            <span>
              {isMobile() ? obj[x].slice(0, obj[x].length - 1) : obj[x]}
            </span>
          </div>
        );
      })}
    </div>
  );
}

const ConfirmStonePopup = (props) => {
  let user = JSON.parse(
    window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)),
  );
  const [comment, setComment] = useState("");
  const [company, setCompany] = useState(
    user && user.account ? user.account.companyName : "",
  );
  const [date, setDate] = useState("1");
  const checked =
    useSelector((state) => state.diamondData.selectedRows[currentType]) || [];
  const [isLoading, setIsLoading] = useState(false);

  const [email, sendEmail] = useState("");

  const getcompany = () => {
    return (
      <IntlMessages id="app.CompanyName*">
        {(placeholder) => (
          <InputBlock
            label={<IntlMessages id="app.CompanyName*" />}
            placeholder={placeholder}
            value={company}
            disabled
            // onChange={e => setCompany(e.target.value)}
          />
        )}
      </IntlMessages>
    );
  };
  const getinvoicedate = () => {
    return (
      <div className="invoice-date">
        <SelectOption
          selectValue={savedSearch.invoicDate}
          placeholder="Invoice Date"
          label="Select Invoice Date*"
          value={date}
          onChange={(e) => setDate(e)}
        />
      </div>
    );
  };
  const checkCheck = () => {
    if (!checked.length) {
      OpenNotification({
        type: "error",
        title: "Please select stone(s) to confirm.",
      });
      return false;
    } else return true;
  };
  const submit = () => {
    if (!date) {
      OpenNotification({ type: "error", title: "Please select invoice date." });
      return;
    }
    if (!company) {
      OpenNotification({ type: "error", title: "Please enter company name." });
      return;
    }

    if (!checkCheck()) return;
    setIsLoading(true);
    handleConfirmStone(
      checked.map((x) => x.id),
      comment,
      date,
      company,
      (flag) => {
        props.onClose();
        if (props.clearAll) props.clearAll();
        if (flag && props.fetch) props.fetch();
        setIsLoading(false);
      },
    );
  };
  const cancel = () => {
    props.onClose();
    if (props.clearAll) props.clearAll();
  };
  const gettextarea = () => {
    return isMobile() ? (
      <IntlMessages id="app.comment">
        {(placeholder) => (
          <TextArea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            label={<IntlMessages id="app.comment" />}
            placeholder={placeholder}
          />
        )}
      </IntlMessages>
    ) : (
      <textarea value={comment} onChange={(e) => setComment(e.target.value)} />
    );
  };

  if (isMobile())
    return (
      <MobileConfirmStone
        data={props.checked}
        currentType={currentType}
        parent={{
          getcompany,
          getinvoicedate,
          submit,
          cancel,
          gettextarea,
          checkCheck,
        }}
      />
    );
  return (
    <div className={`confirmStonePopUp ${isLoading ? `loading-indicator` : ''}`}>
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <Heading
          className="popupInnerTitle mr-40"
          title={<IntlMessages id="app.confirmStone" />}
        ></Heading>
        {HeadingCalc(checked)}
      </div>
      <div
        className={`searchPopupCommonTable ${
          props.popupClass ? props.popupClass : null
        }`}
        style={props.style}
      >
        <div
          className="searchResultTable tabInnerTableScroll"
          style={props.detail ? { height: "auto" } : {}}
        >
          <Table
            data={props.checked}
            nodots
            areAllChecked={true}
            currentType={currentType}
            canSort={false}
            // nocheck={props.detail}
            noGrp
          />
        </div>
        <div
          className="mt-10 finalCalDetail confirmListOption"
          style={props.detail ? { padding: "5px" } : {}}
        >
          <div className="DiamondDetailPopup width-100">
            {getcompany()}
            {getinvoicedate()}
            <div className="commonTextArea from-group">
              <label>
                <IntlMessages id="app.comment" />
              </label>
              {gettextarea()}
            </div>
            {!props.detail && (
              <p className="offerNote">
                <b>
                  <IntlMessages id="app.Note" />:
                </b>{" "}
                <IntlMessages id="app.confirmStoneNote" />
              </p>
            )}
          </div>
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={submit}>
            <IntlMessages id="app.confirmStone" />
          </a>
          <a className="commonButton" onClick={cancel}>
            <IntlMessages id="app.Cancel" />
          </a>
          {!props.detail && (
            <>
              <a
                className="commonButton"
                onClick={() => {
                  props.handleLoader(true);
                  handleDownloadExcel(
                    {},
                    checked.map((x) => x.id),
                    () => {
                      props.handleLoader(false);
                    },
                  );
                }}
              >
                <IntlMessages id="app.ExcelExport" />
              </a>
              <a
                className="commonButton"
                onClick={() => {
                  if (checked.length) sendEmail("stock");
                  else
                    OpenNotification({
                      type: "error",
                      title: "Please select any stone(s).",
                    });
                }}
              >
                <IntlMessages id="app.EmailStock" />
              </a>
              <a
                className="commonButton"
                onClick={() => {
                  if (checked.length) sendEmail("img");
                  else
                    OpenNotification({
                      type: "error",
                      title: "Please select any stone(s).",
                    });
                }}
              >
                <IntlMessages id="app.EmailPic" />
              </a>
              <SendEmailPopup
                sendEmail={email}
                handleLoader={props.handleLoader}
                onCancel={() => sendEmail("")}
                ids={checked.map((x) => x.id)}
                img={email === "img"}
                isStock={email === "stock"}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmStonePopup;
