/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Select, Input, DatePicker, Checkbox, Button } from "antd";
import _ from "lodash";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function TopFilter(props) {
  const { onFilterSearch, dateFormat, filterFormData, setFilterFormData } =
    props;
  const [dateRange, setDateRange] = useState(null);

  const onChanageField = (name, e) => {
    const value =
      name === "dateRange"
        ? {
            from: moment(e[0]).format("YYYY-MM-DD"),
            to: moment(e[1]).format("YYYY-MM-DD"),
          }
        : e;
    if (name === "accountingType") {
      setDateRange(e !== "Received Payment" ? null : dateRange);
      setFilterFormData({
        ...filterFormData,
        [name]: value,
        dateRange: e !== "Received Payment" ? null : filterFormData.dateRange,
        pendingReconcile:
          e === "Last Six Month Sale" || e === "Received Payment"
            ? false
            : filterFormData.pendingReconcile,
      });
    } else {
      setFilterFormData({
        ...filterFormData,
        [name]: value,
      });
      setDateRange(name === "dateRange" ? e : dateRange);
    }
  };

  const onSearch = () => {
    onFilterSearch(filterFormData);
  };

  return (
    <div className="AccountingFilter">
      <div className="AccountingFilterField">
        <div className="AccountingFilterItem">
          <label for="accounting_type">Type: </label>
          <Select
            size="small"
            defaultValue={filterFormData.accountingType}
            onChange={(e) => onChanageField("accountingType", e)}
          >
            <Option value="On Proforma Only">On Proforma Only</Option>
            <Option value="Outstanding Only">Outstanding Only</Option>
            <Option value="Received Payment">Received Payment</Option>
            <Option value="Last Six Month Sale">Last Six Month Sale</Option>
          </Select>
        </div>

        <div className="AccountingFilterItem">
          <label for="client_type">Client Type: </label>
          <Select
            size="small"
            defaultValue={filterFormData.clientType}
            onChange={(e) => onChanageField("clientType", e)}
          >
            <Option value="Local">Local</Option>
            <Option value="International">International</Option>
          </Select>
        </div>

        {/* <div className="AccountingFilterItem">
          <Input
            placeholder="Customer Name"
            className="form-control"
            size="small"
            onChange={(e) => onChanageField("customerName", e.target.value)}
          />
        </div> */}

        <div className="AccountingFilterItem">
          <Input
            placeholder="Invoice No"
            className="form-control"
            size="small"
            onChange={(e) => onChanageField("invoiceNo", e.target.value)}
          />
        </div>

        {filterFormData.accountingType === "Received Payment" && (
          <div className="AccountingFilterItem">
            <RangePicker
              className="form-control"
              format={dateFormat}
              size="small"
              value={dateRange ? dateRange : null}
              onChange={(dates) => onChanageField("dateRange", dates)}
            />
          </div>
        )}

        {filterFormData.accountingType !== "Received Payment" &&
          filterFormData.accountingType !== "Last Six Month Sale" && (
            <div className="AccountingFilterItem">
              <Checkbox
                checked={filterFormData.pendingReconcile}
                onChange={(e) =>
                  onChanageField("pendingReconcile", e.target.checked)
                }
              >
                Pending Reconcile
              </Checkbox>
            </div>
          )}
      </div>

      <div className="AccountingFilterButton">
        <Button size="small" className="btn-search" onClick={onSearch}>
          Search
        </Button>
      </div>
    </div>
  );
}
