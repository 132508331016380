/* eslint-disable */
import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import IntlMessages from "../../util/IntlMessages";
import { browserName, isSafari } from "react-device-detect";
class MadeInBotswana extends Component {
  render() {
    return (
      <div>
        <div className="cl-hero--homepage">
          <ScrollAnimation animateIn="slideInDown" animateOut="slideOutUp">
            <div className="TopImageBlock">
              <div className="topImageBox">
                <img src={require("../../assets/img/download.jpg")} alt="" />
              </div>
              <div className="topBlockContent">
                <h2>
                  <IntlMessages id="app.madeinbotswana" />
                </h2>
                <p>
                  BELGIUM NY LLC has taken an initiative to support the
                  development of Botswana by investing in its economy & its
                  people. We are working towards empowering local artisans by a
                  transfer of skill sets.
                </p>
                {/* <p>Your diamond has been through the hands of several skilled artisans, each as unique as the diamonds they conscientiously hand craft. We are proud to share their stories with you, and show you how many lives you add a sparkle to when you buy your diamond.</p> */}
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="pulse" animateOut="pulse">
            <div className="cl-wrapper">
              <figure className="cl-hero__media">
                {browserName !== "Firefox" && (
                  <div className="cl-hero__video">
                    <video muted controls loop autoPlay>
                      <source
                        src={require("../../assets/img/madeinbotswana/video1.mp4")}
                      />
                    </video>
                  </div>
                )}
              </figure>
              <div className="cl-hero__contents">
                <div className="clContentBox">
                  <div className="userImageArties">
                    <img
                      src={require("../../assets/img/madeinbotswana/arties1.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="clContentRight">
                    <h1 className="cl-hero__title">GLORIA MAIPELO POLISHER</h1>
                    <p>
                      Hello, my name is Gloria Maipelo Malebye. I am from the
                      village Mochudi and I am the only breadwinner in my family
                      of 5. 
                      <br />
                      Working at Belgium makes me happy because it helps me
                      financially to give my children a better life and that is
                      most important to me. I like coming to the factory because
                      it gives me a sense of \independence.
                      <br />
                      My dream is to work hard and study further so that someday
                      I can own a big company like Belgium Botswana and help
                      many more like me.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="pulse">
            <div className="cl-wrapper">
              <figure className="cl-hero__media">
                {browserName !== "Firefox" && (
                  <div className="cl-hero__video">
                    <video muted controls loop autoPlay>
                      <source
                        src={require("../../assets/img/madeinbotswana/video2.mp4")}
                      />
                    </video>
                  </div>
                )}
                {/*<a
                  href="javascript:;"
                  className="play-btn cl-hero__play-btn"
                  data-videoid="aNur6UM3UiA"
                  data-video-context="modal"
                  data-modal="videoModal"
                ></a>*/}
              </figure>
              <div className="cl-hero__contents">
                <div className="clContentBox">
                  <div className="userImageArties">
                    <img
                      src={require("../../assets/img/madeinbotswana/arties2.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="clContentRight">
                    <h1 className="cl-hero__title">
                      TSAMAISO JACKLAS POLISHER
                    </h1>
                    <p>
                      Hello, my name is Tsamaiso Jacklas. I am from Paje and
                      live with husband and children. <br /> Working at Belgium
                      as a Diamond Polisher is helping me in many ways. It helps
                      me financially to take care of my family. I am also
                      learning so much from experts so that I can upgrade my
                      skills and become successful and buy a house. It is my
                      dream to have my own company and I am working hard and
                      hoping for the best.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="pulse">
            <div className="cl-wrapper">
              <figure className="cl-hero__media">
                {browserName !== "Firefox" && (
                  <div className="cl-hero__video">
                    <video
                      muted
                      controls
                      loop
                      autoPlay={!isSafari ? true : false}
                    >
                      <source
                        src={require("../../assets/img/madeinbotswana/video3.mp4")}
                      />
                    </video>
                  </div>
                )}
                {/*<a
                  href="javascript:;"
                  className="play-btn cl-hero__play-btn"
                  data-videoid="aNur6UM3UiA"
                  data-video-context="modal"
                  data-modal="videoModal"
                ></a>*/}
              </figure>
              <div className="cl-hero__contents">
                <div className="clContentBox">
                  <div className="userImageArties">
                    <img
                      src={require("../../assets/img/madeinbotswana/arties3.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="clContentRight">
                    <h1 className="cl-hero__title">LESEGO TLHOWE POLISHER</h1>
                    <p>
                      Hello, I am Lesogo Tlhowe. I come from Gabane and have a
                      big family. I have the responsibility of my son, my
                      mother, my father, 3 sisters, 1 brother and 1 niece. My
                      family is most important to me so I want to do everything
                      I can to give them a better and healthy life. At Belgium,
                      I am learning to improve my skills so I want to work here
                      for many years. <br /> I believe that by working at
                      Belgium I can become independent and make it possible to
                      achieve my dream of buying my own house and leading a good
                      life.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}

export default MadeInBotswana;
