/* eslint-disable */
import React, { useEffect, useState } from "react";
import { VIEW_DIAMOND } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import _ from "lodash";

const SimilarStone = ({ getMediaUrl, ...props }) => {
  const [similar, setSimilar] = useState([]);

  useEffect(() => {
    let obj = {
      showSimilar: true,
    };

    let objData = {
      ...{
        ...VIEW_DIAMOND,
        url: VIEW_DIAMOND.url + "/" + window.location.pathname.split("/").pop(),
      },
      request: obj,
    };

    UtilService.callApi(objData, function (err, data) {
      if (err) throw err;
      else if (data && data.code === "OK") {
        setSimilar(data.data.similarStones);
      }
    });
  }, []);

  return (
    <div className="similarStoneBlock">
      {_.isArray(similar) && similar.length ? (
        <>
          {" "}
          {similar.map((s) => (
            <div className="similarStoneBlockItem">
              <a target="_blank" rel="noreferrer" href={`/dna/${s.vStnId}`}>
                <img src={getMediaUrl("b2cRealImage")} alt="" />
                <span>
                  {s.shpNm} {s.crt} {s.colNm} {s.clrNm} {s.cutNm} {s.polNm}{" "}
                  {s.symNm} {s.fluNm}
                </span>
              </a>
            </div>
          ))}
        </>
      ) : (
        <p className="no-msg">No similar stones found.</p>
      )}
    </div>
  );
};
export default SimilarStone;
