/* eslint-disable */
import React from "react";
import CardView from "../Profile/CardView";
import { LOCAL_STORAGE_VAR } from "../../../constants/Common";

const PersonalCardView = (props) => {
  let dt = JSON.parse(
    window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)),
  );

  return (
    <CardView
      personalDetails
      pDetails={dt}
      onClose={props.onClose}
      onClick={props.onClick}
    />
  );
};
export default PersonalCardView;
