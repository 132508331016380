/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./diamondvideoview.less";
import split from "lodash/split";
import { VIEW_DIAMOND } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import { isEmpty } from "util/utils";
import { getProjectSetting } from "components/DiamondList/DiamondListFunctions";
import { PROJECT_SETTINGS_TYPE } from "constants/Common";
import MagnifierImage from "../DiamondDetail/MagnifierImage";
import { LAB_LINKS } from "../../constants/Common";
const DiamondVideoView = (props) => {
  const [lbNm, setLbNm] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(
    UtilService.getLocalStorageItem("mediaPathPattern") ?? {},
  );
  const [data, setData] = useState({});

  const toggle = () =>
    window.open(
      LAB_LINKS[data?.lbNm.toUpperCase()].replace("***", data?.rptNo),
    );

  const getDiamondDetail = React.useCallback((id) => {
    const objData = {
      method: VIEW_DIAMOND.method,
      url: `${VIEW_DIAMOND.url}/${id}`,
      showLoader: true,
    };

    UtilService.callApi(objData, async function (err, res) {
      if (err) throw err;
      if (res && res.code === "OK") {
        setData(Array.isArray(res.data) ? res.data?.[0] : res.data);
      }
    });
  }, []);

  useEffect(() => {
    const id = split(window.location.pathname, "/")?.pop();
    id !== "diamondVideoview" && getDiamondDetail(id);
  }, [getDiamondDetail]);

  useEffect(() => {
    if (!isEmpty(mediaUrl)) return;

    getProjectSetting(PROJECT_SETTINGS_TYPE.MEDIA_PATH, (data) => {
      setMediaUrl(data?.MEDIA_PATH_PATTERN ?? {});
      UtilService.setLocalStorageItem(
        data?.MEDIA_PATH_PATTERN,
        "mediaPathPattern",
      );
    });
  }, [mediaUrl]);

  const getMediaUrl = (type) => {
    const url = mediaUrl?.[type]?.FILE_PATH;
    return data?.[url?.KEY]
      ? url?.PATH?.replaceAll("***", data?.[url?.KEY])
      : null;
  };

  const handleDNA = () => window.open(`/dna/${data.vStnId}`);

  return (
    <div className="viewdiamond">
      <div className="container videos">
        <div className="row">
          <div className="img-div">
            {data?.hndImg && (
              <div className="box-shadow">
                {/* <img src={getMediaUrl('hndImg')} /> */}
                <div className="imageSliderFullBlock">
                  <MagnifierImage image={getMediaUrl("hndImg")}>
                    <div className="no-msg">Image Coming Soon</div>;
                  </MagnifierImage>
                </div>
              </div>
            )}
          </div>
          <div className="video-div">
            {data?.shdCd && (
              <div className="box-shadow">
                <video controls autoPlay loop muted name="media">
                  <source
                    id="vidoshadecard"
                    type="video/mp4"
                    src={getMediaUrl("shdCd")}
                  />
                </video>
              </div>
            )}
          </div>
          <div className="video-div">
            {data?.hndVideo && (
              <div className="box-shadow">
                <video controls autoPlay loop muted name="media">
                  <source
                    id="vidoshadecard"
                    type="video/mp4"
                    src={getMediaUrl("hndVideo")}
                  />
                </video>
              </div>
            )}
          </div>
          <div className="video-div">
            {data?.isTwz && (
              <div className="box-shadow">
                <video controls autoPlay loop muted name="media">
                  <source
                    id="vidoshadecard"
                    type="video/mp4"
                    src={getMediaUrl("isTwz")}
                  />
                </video>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="dnaDetailBlock">
        <table className="table">
          <thead>
            <tr>
              <th className="dnaImageTop" colSpan="13">
                <span>Report No : {data.rptNo}</span>
              </th>
            </tr>
            <tr>
              <th>Lab</th>
              <th>Shape</th>
              <th>Carat</th>
              <th>Clarity</th>
              <th>Color</th>
              <th>Cut</th>
              <th>Pol</th>
              <th>Symm</th>
              <th>Fls</th>
              <th>Depth%</th>
              <th>Table%</th>
              <th>LWD</th>
              <th>DNA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="viewCerti" onClick={toggle}>
                  {" "}
                  {data.lbNm}{" "}
                </span>
              </td>
              <td>{data.shpNm}</td>
              <td>{data.crt}</td>
              <td>{data.clrNm}</td>
              <td>{data.colNm}</td>
              <td>{data.cutNm}</td>
              <td>{data.polNm}</td>
              <td>{data.symNm}</td>
              <td>{data.fluNm}</td>
              <td>{data.depPer}</td>
              <td>{data.tblPer}</td>
              <td>{data.msrmnt}</td>
              <td>
                <span className="viewCerti" onClick={handleDNA}>
                  {" "}
                  DNA{" "}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default DiamondVideoView;
