/* eslint-disable */
import React, { Component } from "react";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import UtilService from "../../services/util";

class Logo extends Component {
  imageClick = () => {
    // let redictToPage =
    //   localStorage[`${LOCAL_STORAGE_VAR}-token`] &&
    //   localStorage[`${LOCAL_STORAGE_VAR}-token`].length
    //     ? "/dashboard"
    //     : "/";
    let redictToPage =
      UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`) &&
      UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`).length
        ? "/dashboard"
        : "/";

    return window.open(redictToPage);
  };

  render() {
    return (
      <img
        className="imgHover"
        //src={require("../../assets/svg/logo-white.svg")}
        alt=""
        onClick={() => this.imageClick()}
      />
    );
  }
}

export default Logo;
