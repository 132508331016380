const languageData = [
  {
    languageId: "english",
    id: "en",
    locale: "en",
    name: "English",
    icon: "us",
  },
  {
    languageId: "french",
    id: "fr",
    locale: "fr",
    name: "French",
    icon: "fr",
  },
  {
    languageId: "china",
    id: "zh",
    locale: "zh",
    name: "Chinese",
    icon: "zh",
    country_code: "CN",
  },
  {
    languageId: "japan",
    id: "ja",
    locale: "ja",
    name: "Japanese",
    icon: "ja",
  },
  {
    languageId: "italian",
    id: "it",
    locale: "it",
    name: "Italian",
    icon: "it",
  },
  {
    languageId: "spanish",
    id: "es",
    locale: "es",
    name: "Spanish",
    icon: "es",
  },
  {
    languageId: "germany",
    id: "de",
    locale: "de",
    name: "German",
    icon: "de",
  },
  {
    languageId: "hebrew",
    id: "he",
    locale: "he",
    name: "Hebrew",
    icon: "he",
  },
  {
    languageId: "Arabic",
    id: "ar",
    locale: "ar",
    name: "Arabic",
    icon: "ar",
  },
  {
    languageId: "korean",
    id: "ko",
    locale: "ko",
    name: "Korean",
    icon: "ko",
  },
  {
    languageId: "vietnam",
    id: "vi",
    locale: "vi",
    name: "Vietnamese",
    icon: "vi",
  },
  {
    languageId: "thai",
    id: "th",
    locale: "th",
    name: "Thai",
    icon: "th",
  },
  {
    languageId: "russian",
    id: "ru",
    locale: "ru",
    name: "Russian",
    icon: "ru",
  },
];
export default languageData;
