/* eslint-disable */
import React, {
  useReducer,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { cloneDeep } from "lodash";
import { Drawer, Modal, Tooltip } from "antd";
import DiamondListingAction from "../common/DiamondListing/DiamondListingAction";
import SelectStone from "../common/DiamondListing/SelectStone";
import Heading from "../common/Heading";
import TermsConditions from "./TermsConditions";
import Countdown from "../Offer/Timer";
import DiamondGridView from "../DiamondList/DiamondGrid/index";
import CommonModal from "../common/CommonModal";
import ListingTop from "../common/DiamondListing/ListingTop";
import {
  getGridHeight,
  emptyData,
  getState,
  isArrayEqual,
} from "../DiamondList";
import Table from "../DiamondList/TableBack";
import {
  getColumn,
  fetchDiamondPaginate,
  isMobile,
  LISTINGPAGES,
  getPath,
} from "../DiamondList/DiamondListFunctions";
import {
  DIAMOND_BID,
  DIAMOND_WEB_STATUS,
  LOCAL_STORAGE_VAR,
} from "../../constants/Common";
import IntlMessages from "../../util/IntlMessages";
import NewArrivalBidMobile from "./NewArrivalBidMobile";
import _ from "lodash";
import { getCurrentTime, getNextBidData } from "../../services/util";
import UtilService from "../../services/util";
import { useBoolean } from "util/hooks";
import { isEmpty } from "util/utils";

function getBidStartTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN)
    return moment().startOf("day").add(11, "hours");
  else return moment().startOf("day").add(15, "hours");
}

function getBidEndTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN)
    return moment().startOf("day").add(14, "hours").add(59, "minutes");
  else return moment().startOf("day").add(10, "hours").add(59, "minutes");
}

export function getBidEndCounter(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN)
    return moment().startOf("day").add(14, "hours").add(54, "minutes");
  else if (moment() < getBidStartTime(DIAMOND_BID.TYPE.OPEN))
    return moment().startOf("day").add(10, "hours").add(54, "minutes");
  else
    return moment()
      .startOf("day")
      .add(1, "days")
      .add(10, "hours")
      .add(54, "minutes");
}

export async function getBidType() {
  let bidType = 0;

  let nextBid = await getNextBidData();

  if (!_.isEmpty(nextBid)) {
    bidType = nextBid.currentBidType;
  } else if (
    moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) &&
    moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)
  ) {
    bidType = DIAMOND_BID.TYPE.OPEN;
  } else if (
    moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) ||
    moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)
  ) {
    bidType = DIAMOND_BID.TYPE.BLIND;
  }

  return bidType;
}

const NewArrivalBidIt = (props) => {
  const { location } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const currentType = useRef(location.pathname);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useState(() => cloneDeep(getState()));
  const sortRef = useRef([]);
  const [termsDrawer, setTermsDrawer] = useState(true);
  const [currTime, setCurrTime] = useState("");
  // const [nextBidData, setNextBidData] = useState({});
  const [setMount, setMountData] = useState(true);
  const [getBid, setGetBid] = useState(false);
  const [currentBidType, setCurrentBidType] = useState(0);
  // const [allow, setAllow] = useBoolean(false);
  const [bidTimeEndStatus, setBidTimeEndStatus] = useState(false);
  const [bidModalHandle, setBidModalHandle] = useState(false);
  const [listview, setListView] = useState(true);

  const USER_PERMIT = UtilService.getLocalStorageItem(
    `${LOCAL_STORAGE_VAR}-userPermissions`,
  );

  useEffect(() => {
    forceUpdate();
  }, [props.commonData.strip]);

  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
    return () => {
      window.removeEventListener("resize", forceUpdate);
    };
  }, [location]);

  useEffect(() => {
    async function setTimers() {
      setState({ ...state, loading: true});
      let time = await getCurrentTime();
      let nextBid = await getNextBidData();
      setCurrTime(time.iso);
      // setNextBidData(nextBid);
      setGetBid(true);
      setCurrentBidType(nextBid.currentBidType);
      setState({ ...state, loading: false});
      nextBid && fetch();
    }
    setTimers();
  }, []);

  useEffect(() => {
    getBid && fetch();
  }, [state.page, state.limit]);

  const getDiamondWiseBidType = (data) => {
    const currentDateTime = moment().toISOString();
    if (
      currentDateTime >= data?.blndBidStrTm &&
      currentDateTime <= data?.blndBidEndTm
    ) {
      return DIAMOND_BID.TYPE.BLIND;
    } else if (
      currentDateTime >= data?.lABidStrTm &&
      currentDateTime <= data?.lABidEndTm
    ) {
      return DIAMOND_BID.TYPE.OPEN;
    } else if (
      currentDateTime >= data?.mulBidStrTm &&
      currentDateTime <= data?.mulBidEndTm
    ) {
      return DIAMOND_BID.TYPE.MULTIPLE;
    } else return 0;
  };

  const setResponse = React.useCallback(async (res) => {
    // let nextBid = await getNextBidData();
    const currentDateTime = moment().toISOString();
    let getcolumns = getColumn();
    const col = [
      {
        Header: "Release Time",
        // accessor: 'blndBidEndTm',
        Cell: ({ row, cell }) => {
          if (
            currentDateTime >= row.original.blndBidStrTm &&
            currentDateTime <= row.original.blndBidEndTm
          ) {
            return (
              <>
                <Tooltip placement="bottom" title="Blind Bid">
                  <img
                    src={require("assets/svg/bid.svg")}
                    style={{ marginRight: "5px" }}
                  />
                </Tooltip>
                {moment(row.original.blndBidEndTm).format("DD-MM-YYYY hh:mm A")}
              </>
            );
          } else if (
            currentDateTime >= row.original.lABidStrTm &&
            currentDateTime <= row.original.lABidEndTm
          ) {
            return (
              <>
                <Tooltip placement="bottom" title="Look and Bid">
                  <img
                    src={require("assets/svg/blind-bid.svg")}
                    style={{ marginRight: "5px", marginTop: "-1px" }}
                  />
                </Tooltip>
                <span>
                  {moment(row.original.lABidEndTm).format("DD-MM-YYYY hh:mm A")}
                </span>
              </>
            );
          } else if (
            currentDateTime >= row.original.mulBidStrTm &&
            currentDateTime <= row.original.mulBidEndTm
          ) {
            return (
              <>
                <Tooltip placement="bottom" title="Multiple Bids">
                  <img
                    src={require("assets/img/bidding.png")}
                    style={{
                      marginRight: "5px",
                      marginTop: "-1px",
                      height: 14,
                      width: 14,
                    }}
                  />
                </Tooltip>
                <span>
                  {moment(row.original.mulBidEndTm).format(
                    "DD-MM-YYYY hh:mm A",
                  )}
                </span>
              </>
            );
          } else return "-";
        },

        // nextBid.nextBidType === DIAMOND_BID.TYPE.BLIND ? (
        //     row.original.lABidEndTm ? (
        //       <>
        //         <Tooltip placement="bottom" title="Look and Bid">
        //           <img src={require('assets/svg/bid.svg')} style={{ marginRight: '5px', marginTop: '-1px' }} />
        //         </Tooltip>
        //         <span>{moment(row.original.lABidEndTm).format('DD-MM-YYYY hh:mm A')}</span>
        //       </>
        //     ) : (
        //       '-'
        //     )
        //   ) : row.original.blndBidEndTm ? (
        //     <>
        //       <Tooltip placement="bottom" title="Blind Bid">
        //         <img src={require('assets/svg/blind-bid.svg')} style={{ marginRight: '5px' }} />
        //       </Tooltip>
        //       {moment(row.original.blndBidEndTm).format('DD-MM-YYYY hh:mm A')}
        //     </>
        //   ) : (
        //     '-'
        //   ),
      },
    ];
    const column = getcolumns.splice(2, 0, ...col);
    const columns = [...column, ...getcolumns];

    if (res) {
      let diamondData = res.data || [],
        defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(
          `${LOCAL_STORAGE_VAR}-compare-diamond`,
        )
          ? JSON.parse(
              localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`),
            )
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData.map((d) => {
            if (defaultChecked.includes(d.id)) {
              d["isDefaultChecked"] = true;
            }
            return d;
          });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      diamondData.map((d) => {
        d["bidType"] = getDiamondWiseBidType(d);
        return d;
      });
      setState({
        ...state,
        ...res,
        data: diamondData,
        defaultChecked,
        columns,
        loading: false,
      });
    } else {
      setState({ ...state, loading: false, defaultChecked: [] });
    }
  }, []);

  const handleSort = useCallback(
    (currentSort) => {
      if (
        currentSort.length !== sortRef.current.length ||
        !isArrayEqual(currentSort, sortRef.current)
      ) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [sortRef.current],
  );

  const fetch = useCallback(() => {
    setTermsDrawer(false);
    setState({ ...state, loading: true, data: [] });
    const payload = { ...state, sort: sortRef.current };
    const stoneIds = props?.location?.state?.stoneIds ?? [];

    fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.BID, setResponse, {
      prcStg: "NEW",
      // wSts: "N",
      // sSts: {
      //   nin: ["P", "D", "C4", "C5", "PI"],
      // },
      diamondSearchId: window.location.search.split("?")[1],
      ...(!isEmpty(stoneIds) ? { id: stoneIds } : {}),
    });
  }, [state, props.location?.state?.stoneIds, setResponse]);

  const clearAllSelectedRows = useCallback(() => {
    let tblRows = document.getElementsByTagName("table")[0]?.rows;
    if (tblRows)
      for (let row of tblRows) {
        row.classList.remove("selectTr");
      }
    dispatch({ type: "RESET_SELECTED_ROWS" });
  }, [dispatch]);

  const onPaginationChange = useCallback(
    (page, limit) => {
      setState({ ...state, page, limit });
    },
    [state.sum],
  );

  let gridHeight = getGridHeight(
    [
      "diamondListingAction",
      "headerTopMenu",
      "selectStonHeader",
      "covidStrip",
      "HeaderStripBlock",
    ],
    12,
  );

  // const getTitle = () => {
  //   return TITLE.BID + " (" + state.count + ")";
  // };

  const getTitle = () => {
    let comp = <IntlMessages id="app.newArrival" />;
    return comp;
  };

  const getSelection = () => {
    return (
      <SelectStone
        sum={state.sum}
        profilListing={currentBidType ? "hideclearAll" : "diamondListStone"}
        currentType={currentType.current}
      />
    );
  };

  const bidModal = (visible) => {
    const blockSettings = UtilService.getLocalStorageItem(
      "bidPopUpValidationMsg",
    );
    let CONFIRM =
      currentBidType === DIAMOND_BID.TYPE.BLIND
        ? blockSettings?.["BLIND_BID"]
        : currentBidType === DIAMOND_BID.TYPE.OPEN
        ? blockSettings?.["LOOK_AND_BID"]
        : blockSettings?.["DEFAULT"];
    const handleOk = () => {
      setBidModalHandle(false);
    };
    return (
      <CommonModal
        title="Bid"
        submitTitle="OK"
        visible={visible || bidModalHandle}
        closable={false}
        handleOk={handleOk}
        handleCancel={false}
        footerShow
      >
        <div className="detailShow d-flex flex-wrap">
          <div className="detail w-100">
            <span>
              <p dangerouslySetInnerHTML={{ __html: CONFIRM }}></p>
            </span>
          </div>
        </div>
      </CommonModal>
    );
  };

  const bidEndTime = async (visible) => {
    if (setMount) {
      setBidTimeEndStatus(true);
      setBidModalHandle(true);
      let nextBid = await getNextBidData()
        .then()
        .catch((err) => {
          if (err && err.data && err.data.code === "E_NOT_FOUND") {
            setState({ ...state, loading: false, data: [] });
          }
        });
      // const data = nextBid && nextBid.sub_types && nextBid.sub_types.ruleFile;
      // setTermsDrawer(data ? true : false);
      // setNextBidData(nextBid ? nextBid : []);
      getSelection();
      setMount && nextBid && fetch();
      setMountData(false);
    }
  };

  // const getBidBlock = () => {
  //   return currentBidType ? (
  //     nextBidData.length !== 0 ? (
  //       <div
  //         className={
  //           "bidEndBlock" + (!isMobile() ? " d-flex align-items-center" : "")
  //         }
  //       >
  //         <span className="bidEndLabel">
  //           <IntlMessages id="app.BidEndIn" /> :
  //         </span>
  //         <Countdown
  //           currTime={currTime}
  //           date={
  //             nextBidData.nextDate &&
  //             moment(nextBidData.nextDate)
  //           }
  //           fetch={fetch}
  //           bidEndTime={bidEndTime}
  //           nextBidData={nextBidData.nextDate && nextBidData.nextDate}
  //         />
  //       </div>
  //     ) : (
  //       ""
  //     )
  //   ) : null;
  // };

  const getAction = () => {
    return (
      <DiamondListingAction
        {...state}
        {...props}
        handleLoader={handleLoader}
        currentType={currentType.current}
        onPaginationChange={onPaginationChange}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        // noHold
        // bidStone={USER_PERMIT && USER_PERMIT?.NEW_ARRIVAL_BID_IT?.insert}
        nofinalcalc={USER_PERMIT && !USER_PERMIT?.FINAL_CALCULATIONS?.insert}
        nonocompare={USER_PERMIT && !USER_PERMIT?.COMPARE?.insert}
        noDnaCompare={true}
        nocart
        nowatch
        noreminder
        noquote
        // noconfirm
        nonote
        norelease
        nooffice={
          (USER_PERMIT && !USER_PERMIT?.OFFICE?.insert) ||
          currentBidType === DIAMOND_BID.TYPE.BLIND
        }
        noshare={
          USER_PERMIT &&
          !USER_PERMIT?.SHARE_VIA_MAIL?.insert &&
          !USER_PERMIT?.SHARE_VIA_WHATSAPP?.insert &&
          !USER_PERMIT?.SHARE_VIA_SKYPE?.insert
        }
        nodownload={USER_PERMIT && !USER_PERMIT?.DOWNLOAD?.insert}
        noprint={USER_PERMIT && !USER_PERMIT?.PRINT?.insert}
        // modify
        // onModify={() => {
        //   let id = window.location.search.split("?")[1];
        //   id
        //     ? props.history.push(`/${LISTINGPAGES.SEARCH}/${id}`)
        //     : //: props.history.push(`/${LISTINGPAGES.SEARCH}`);
        //     props.history.push({
        //       pathname: `/${LISTINGPAGES.SEARCH}`,
        //       state: { newArr: true },
        //     });
        // }}
        bidTimeEndStatus={!bidTimeEndStatus}
        bidModal={bidModal}
        setLoading={(val) => setState({ ...state, loading: val })}
      />
    );
  };

  // const handleClose = React.useCallback(() => {
  //   setAllow.false();
  //   setTermsDrawer(false);
  //   props.history.push("/");
  // }, [props.history, setAllow]);

  // const getDrawer = () => {
  //   return termsDrawer ? (
  //     <Drawer
  //       title={false}
  //       closable={false}
  //       onClose={handleClose}
  //       visible={termsDrawer}
  //       className="diamondListinSidebar sm-size termsPopup"
  //     >
  //       <TermsConditions onClose={handleClose} fetch={setAllow.true} />
  //     </Drawer>
  //   ) : null;
  // };

  const handleLoader = (loading) => {
    setState({ ...state, loading });
  };

  if (isMobile()) {
    return (
      <NewArrivalBidMobile
        {...props}
        {...state}
        currentType={currentType.current}
        parent={{ getTitle, getSelection, getAction }}
      />
    );
  }
  const getGridView = () => {
    return (
      <>
        <DiamondGridView
          data={state.data}
          loading={state.loading}
          currentType={currentType.current}
          currentBidType={currentBidType}
        />
        {emptyData(state.loading, state.data.length)}
      </>
    );
  };

  const getListTop = () => {
    return (
      <ListingTop
        listGridIcon={
          location.pathname.split("/")[1] === "account" ||
          getPath() === LISTINGPAGES.QUOTEDAY
            ? null
            : true
        }
        timer={getPath() === LISTINGPAGES.QUOTEDAY ? true : false}
        Cart={
          getPath() === LISTINGPAGES.CART ||
          getPath() === LISTINGPAGES.WATCHLIST
            ? true
            : null
        }
        onClick={() => setListView((v) => !v)}
        image={
          listview
            ? require("../../assets/svg/DiamondList/grid.svg")
            : require("../../assets/svg/DiamondList/list.svg")
        }
      />
    );
  };

  return (
    <div className="searchResultListWrapper">
      <div className="selectStonHeader">
        <div className="d-flex justify-content-between align-items-center width-100 listingTopBlock">
          <Heading title={getTitle()} count={state.count} />
          <div className="info_marquee">
            <p>
              Note: 1) The prices mentioned over here are fixed and hence not
              negotiable. | 2) The grading, patterns & parameters mentioned on
              our website beyond GIA's grading is solely our perspective based
              on examinations conducted by our grading department and we do not
              hold ourself responsible for any conflicts in this regard.
            </p>
          </div>
          {getSelection()}
          <div className="d-flex align-items-center">
            {/* <button
              className="commonButton smallButton mr-10"
              onClick={() => {
                let id = window.location.search.split("?")[1];

                id
                  ? props.history.push(`/${LISTINGPAGES.SEARCH}/${id}`)
                  : props.history.push(`/${LISTINGPAGES.SEARCH}`);
              }}
            >
              <IntlMessages id="app.modify" />
            </button> */}
            {/* {getBidBlock()} */}
            {getListTop()}
          </div>
        </div>
      </div>
      <div className="searchInnerResult">
        {listview ? (
          <div
            className="searchResultTable"
            style={{ height: gridHeight, overflow: "auto" }}
          >
            <Table
              data={state.data}
              loading={state.loading}
              ref={tableRef}
              handleSort={handleSort}
              currentType={currentType.current}
              currentBidType={currentBidType}
              defaultChecked={state.defaultChecked}
              columns={state.columns}
            />
            {emptyData(state.loading, state.data.length)}
          </div>
        ) : (
          <div
            className="searchResultGridView"
            style={{ height: gridHeight, overflow: "auto" }}
          >
            {getGridView()}
          </div>
        )}
        {getAction()}
      </div>
      {/* {getDrawer()} */}
      {bidModal()}
    </div>
  );
};

export default connect((props) => {
  return props;
}, {})(React.memo(withRouter(NewArrivalBidIt)));
