/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./dna.less";
import CommonModal from "../common/CommonModal";
import ShareDropdown from "../common/DiamondListing/ShareDropdown";
import DownloadDropdown from "../common/DiamondListing/DownloadDropdown";
import ImageSlider from "./ImageSlider";
import VideoBlock from "./VideoBlock";
import ImageBlock from "./ImageBlock";
import SimilarStone from "./SimilarStone";
import DiamondJourney from "./DiamondJourney";
import DNADetail from "./DNADetail";
import {
  VIEW_DIAMOND,
  _HIT_COUNTER,
  CRETAE_ARTISAN_CERT,
} from "../../constants/apiConstant";
import _, { isArray, isEmpty } from "lodash";
import UtilService from "../../services/util";
// import QRCode from "qrcode.react";
import {
  getProjectSetting,
  isMobile,
} from "../DiamondList/DiamondListFunctions";
import MobileShareOption from "../../components/common/DiamondListing/MobileShareOption";
import MobileDownloadOption from "../../components/common/DiamondListing/MobileDownloadOption";
import {
  LOCAL_STORAGE_VAR,
  PROJECT_SETTINGS_TYPE,
  ARTISIAN_CERT_URL,
  BASE_DOWNLOAD_URL,
} from "../../constants/Common";
import Image from "../common/Image";
import Loader from "../common/Loader";
import { isNotEmpty } from "util/utils";
const DNA = () => {
  const [share, setShare] = useState(false);
  const [share1, setShare1] = useState(false);
  const [dnaData, setDnaData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [actualData, setActualData] = useState({});
  const [tabFlag, setTabFlag] = useState(-1);
  const [artisanCert, setArtisanCert] = useState("");
  const [mediaUrl, setMediaUrl] = useState(
    UtilService.getLocalStorageItem("mediaPathPattern") ?? {},
  );

  let isMobileView = isMobile();
  const [currentIndex, setCurrentIndex] = useState(0);

  const shareClick = () => {
    setShare(true);
  };
  const shareClick1 = () => {
    setShare1(true);
  };
  const onClose = () => {
    setShare(false);
    setShare1(false);
  };

  const handleHitCounter = (id) => {
    let objData = {
      ..._HIT_COUNTER,
      request: {
        isHitCounter: true,
        id,
      },
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === "OK") {
      }
    });
  };

  const getArtisanCert = React.useCallback(async () => {
    let id = _.last(_.split(window.location.pathname, "/"));

    if (id !== "dna") {
      let api = `${CRETAE_ARTISAN_CERT.url}`;
      let objData = {
        method: CRETAE_ARTISAN_CERT.method,
        url: api,
        showLoader: true,
        request: { vStnId: id },
      };
      setIsLoading(true);
      UtilService.callApi(objData, async function (err, data) {
        if (err) throw err;
        if (data && data.code === "OK") {
          setArtisanCert(data?.data?.data);
        }
        setIsLoading(false);
      });
    }
  }, []);

  const getDiamondDetail = React.useCallback(async () => {
    let id = _.last(_.split(window.location.pathname, "/"));

    if (id !== "dna") {
      let api = `${VIEW_DIAMOND.url}/${id}`;
      let objData = {
        method: VIEW_DIAMOND.method,
        url: api,
        showLoader: true,
      };
      setIsLoading(true);
      UtilService.callApi(objData, async function (err, data) {
        if (err) throw err;
        if (data && data.code === "OK") {
          setActualData(Array.isArray(data.data) ? data?.data?.[0] : data.data);
          setDnaData(Array.isArray(data.data) ? data?.data?.[0] : data.data);
          handleHitCounter(
            Array.isArray(data.data) ? data?.data?.[0]?.id : data?.data?.id,
          );
        }
        setIsLoading(false);
      });
    }
  }, []);

  const handleLoader = (loading) => {
    loading
      ? document.body.classList.add("loading-indicator")
      : document.body.classList.remove("loading-indicator");
  };
  useEffect(() => {
    //get diamond detail if media setting exist
    getArtisanCert();
    if (!isEmpty(mediaUrl)) return getDiamondDetail();

    getProjectSetting(PROJECT_SETTINGS_TYPE.MEDIA_PATH, (data) => {
      setMediaUrl(data?.MEDIA_PATH_PATTERN ?? {});
      UtilService.setLocalStorageItem(
        data?.MEDIA_PATH_PATTERN,
        "mediaPathPattern",
      );
      getDiamondDetail();
    });
  }, [getArtisanCert, getDiamondDetail, mediaUrl]);

  const getMediaUrl = React.useCallback(
    (type) => {
      const url = mediaUrl?.[type]?.FILE_PATH;

      return url?.PATH?.replaceAll("***", dnaData?.[url?.KEY]);
    },
    [dnaData, mediaUrl],
  );

  let USER_PERMIT = UtilService.getLocalStorageItem(
    `${LOCAL_STORAGE_VAR}-userPermissions`,
  );
  let isGuest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);

  return isLoading ? (
    <Loader loading={isLoading} fixed />
  ) : (
    <>
      <div className="qrCodeOption">
        {/* <QRCode size={50} value={window.location.href} level={"L"} /> */}
        <span>QR Code</span>
      </div>
      <div className="dnaImageTop mainBox d-flex">
        {
          <span>
            {dnaData.length
              ? [
                  dnaData.vStnId,
                  dnaData.shpNm,
                  dnaData.crt,
                  dnaData.colNm,
                  dnaData.clrNm,
                  dnaData.cutNm,
                  dnaData.polNm,
                  dnaData.symNm,
                  dnaData.fluNm,
                ]
                  ?.filter(isNotEmpty)
                  ?.join(" ")
              : "-"}
          </span>
        }

        {!isGuest && (
          <div className="dnaDetailShareOption">
            <div className="dnaShareOption" onClick={shareClick}>
              <Image
                src={require("../../assets/svg/DNA/share-theme.svg")}
                alt=""
              />
              <span>Share</span>
            </div>
            <div className="dnaShareOption" onClick={shareClick1}>
              <Image
                src={require("../../assets/svg/DNA/download.svg")}
                alt=""
              />
              <span>Download</span>
            </div>
          </div>
        )}
      </div>
      <div className={`dnaContainerBox  ${isLoading ? `loading-indicator` : ''}`}>
        {actualData.altCert && (
          <div className="customerSubTab diamondlistTab">
            <ul className="accountTabBox">
              <li className={tabFlag === -1 ?  "react-tabs__tab--selected" : null}>
                <a
                  onClick={() => {
                    setDnaData(actualData);
                    setTabFlag(-1);
                  }}
                >
                  <span>{actualData.lbNm}</span>
                </a>
              </li>
              {isNotEmpty(actualData.altCert) &&
                isArray(actualData.altCert) &&
                actualData.altCert.map((values, index) => (
                  <li
                    className={tabFlag === index ?  "react-tabs__tab--selected" : null}
                  >
                    <a
                      onClick={() => {
                        setDnaData({ ...dnaData, ...values });
                        setTabFlag(index);
                      }}
                    >
                      <span>{values.lbNm}</span>
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        )}
        <div className="dnaWrapper d-flex width-100">
          <div className="dnaWrapperBox threeBlock1">
            <div className="dnaImageBlock Video">
              <div className="dnaImageTop ">
                <span>Video</span>
              </div>
              <div className="dnaImageSwipe video">
                <VideoBlock
                  className="video-m"
                  dnaData={dnaData}
                  currentIndex={currentIndex}
                  getMediaUrl={getMediaUrl}
                  setCurrentIndex={setCurrentIndex}
                />
              </div>
            </div>
          </div>
          <div className="dnaWrapperBox threeBlock1">
            <div className="dnaImageBlock">
              <div className="dnaImageTop">
                <span>Image</span>
              </div>
              <div className="dnaImageSwipe image">
                <ImageBlock
                  className="img-m"
                  dnaData={dnaData}
                  currentIndex={currentIndex}
                  getMediaUrl={getMediaUrl}
                  setCurrentIndex={setCurrentIndex}
                />
              </div>
            </div>
          </div>
          <div className="dnaWrapperBox threeBlock2">
            <div className="dnaImageBlock">
              <div className="dnaImageTop">
                <span>Similar Stone</span>
              </div>
              <div className="dnaImageSwipe similarStoneScroll">
                <SimilarStone getMediaUrl={getMediaUrl} />
              </div>
            </div>
          </div>
        </div>
        <div className="dnaWrapperBox">
          <div className="dnaImageTop">
            <span>Diamond Details</span>
          </div>
          <div className="dnaLeftRightDetil d-flex flex-wrap">
            <DNADetail dnaData={dnaData} />
          </div>
        </div>
        <div className="dnaWrapper d-flex">
          <div className="dnaWrapperBox">
            <div className="dnaImageBlock">
              <div className="dnaImageSwipe">
                <ImageSlider dnaData={dnaData} getMediaUrl={getMediaUrl} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="dnaJourenySticky diamond-journey">
            <div className="dnaImageTop">
              <span>Diamond Journey</span>
            </div>
            <DiamondJourney dnaData={dnaData} getMediaUrl={getMediaUrl} />
          </div>
        </div>
        {dnaData.isJrny && (
          <div>
            <div className="dnaJourenySticky video-m">
              <div className="dnaImageTop">
                <span>Journey</span>
              </div>
              <video
                width="100%"
                height="624px;"
                // style="width: 100%; height: 624px; border: 0px solid #815d4a; border-radius: 3px; margin-bottom: 10PX;"
                id="vid"
                controls
                loop
                autoPlay
                muted
              >
                <source src={getMediaUrl("isJrny")} type="video/mp4" />
              </video>
            </div>
          </div>
        )}
        <div
          className={`dnaContainerBox main-box ${
            isLoading ? `loading-indicator` : ''
          }`}
        >
          {actualData.altCert && (
            <div className="customerSubTab diamondlistTab">
              <ul className="accountTabBox">
                <li className={tabFlag === -1 ?  "react-tabs__tab--selected" : null}>
                  <a
                    onClick={() => {
                      setDnaData(actualData);
                      setTabFlag(-1);
                    }}
                  >
                    <span>{actualData.lbNm}</span>
                  </a>
                </li>
                {isNotEmpty(actualData.altCert) &&
                  isArray(actualData.altCert) &&
                  actualData.altCert.map((values, index) => (
                    <li
                      className={
                        tabFlag === index ? "react-tabs__tab--selected" : null
                      }
                    >
                      <a
                        onClick={() => {
                          setDnaData({ ...dnaData, ...values });
                          setTabFlag(index);
                        }}
                      >
                        <span>{values.lbNm}</span>
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>

        {dnaData.certFile && (
          <div>
            <div className="dnaJourenySticky">
              <div className="dnaImageTop">
                <span>Certificate</span>
              </div>
              <iframe
                src={getMediaUrl("certFile")}
                alt=""
                style={{ height: "900px", width: "100%" }}
              />
            </div>
          </div>
        )}
        {/* {dnaData.certFile && (
          <div>
            <div className="dnaJourenySticky">
              <div className="dnaImageTop">
                <span>Botswana</span>
              </div>
              <iframe
                title=""
                width="100%"
                height="900px"
                src={getMediaUrl("type2")}
              />
            </div>
          </div>
        )} */}
        {dnaData.type2 && !isEmpty(dnaData.type2) && (
          <div>
            <div className="dnaJourenySticky">
              <div className="dnaImageTop">
                <span>Certificate</span>
              </div>
              <iframe
                title=""
                width="100%"
                height="900px"
                src={getMediaUrl("type2")}
              />
            </div>
          </div>
        )}
        {["NAMIBIA", "BOTSWANA"].includes(dnaData?.mfgLocation) &&
        ["NAMIBIA", "BOTSWANA"].includes(dnaData?.artCtry) ? (
          <div>
            <div className="dnaJourenySticky">
              <div className="dnaImageTop">
                <span>The Artisan certificate</span>
              </div>
              <iframe
                src={ARTISIAN_CERT_URL?.replaceAll("***", dnaData?.isBots)}
                alt=""
                style={{ height: "900px", width: "50%" }}
              />
              <iframe
                src={`${BASE_DOWNLOAD_URL}${artisanCert}`}
                alt=""
                style={{ height: "900px", width: "50%" }}
              />
            </div>
          </div>
        ) : null}
      </div>
      {share && (
        <CommonModal
          modalSize="ListingModal"
          title="Share Stone"
          handleCancel={onClose}
          visible={share}
         
        >
          {!isMobileView ? (
            <ShareDropdown checked={[dnaData]} onCloseSharePopup={onClose} />
          ) : (
            <MobileShareOption checked={[dnaData]} onClose={onClose} />
          )}
        </CommonModal>
      )}
      {share1 && (
        <CommonModal
          modalSize="ListingModal"
          title="Download Stone"
          handleCancel={onClose}
          visible={share1}
         
        >
          {!isMobileView ? (
            <DownloadDropdown
              checked={[dnaData]}
              onCloseDownloadPopup={onClose}
              getMediaUrl={getMediaUrl}
              handleLoader={(loading) => handleLoader(loading)}
            />
          ) : (
            <MobileDownloadOption
              checked={[dnaData]}
              onClose={onClose}
              getMediaUrl={getMediaUrl}
            />
          )}
        </CommonModal>
      )}
    </>
  );
};
export default DNA;
