import axios from "axios";

import * as _ from "lodash";
import { message } from "antd";
import { BASE_URL } from "../constants/Common";
import Fingerprint from "fingerprintjs";

const getsessionUUID = () => {
  var fingerprint = new Fingerprint().get();
  return fingerprint;
};
export default axios.create({
  baseURL: BASE_URL,
  timeout: 1000 * 60 * 10,
  headers: {
    "Content-Type": "application/json",
    uuid: getsessionUUID(),
  },
});

export const CrudService = {
  removeDocument: function (options, cb) {
    axios
      .post("admin/remove-record", options)
      .then(({ data }) => {
        if (data.code === "OK") {
          message.success(`${data.message}`);
          cb("success");
        } else {
          message.error(`${data.message}`);
          cb("failed");
        }
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          message.error(`${error.response.data.message}`);
        }
        cb("failed");
      });
  },
  removeFile: function (file) {
    let filePath = [];
    if (_.isString(file)) {
      filePath = [file];
    } else if (_.isArray(file)) {
      //do nothing
    } else if (_.isObject(file) && file.response) {
      filePath = [file.response.data.files[0].absolutePath];
    } else if (_.isObject(file) && file.url) {
      filePath = [file.url];
    }
    return axios
      .post("delete-file", { paths: filePath })
      .then(({ data }) => {})
      .catch(function (error) {
        message.error(`${error.message}`);
      });
  },
};

export function objectDeepGet(obj, path) {
  var paths = Array.isArray(path) ? path : path.split("."),
    current = obj,
    i;

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }
  return current;
}
