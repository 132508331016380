/* eslint-disable */
import React, { Component } from "react";
import CommonModal from "../common/CommonModal";
import InputBlock from "../common/InputBlock";

class DeletePopup extends Component {
  render() {
    return (
      <>
        <CommonModal
          visible={this.props.showModal}
          handleOk={this.props.handleDelete}
          handleCancel={() => this.props.handleCancel()}
          title="Delete Confirmation"
          footerShow
          submitTitle="Yes"
          cancelTitle="No"
        >
          <div className="searchPopupCommon">
            <div className="searchPopupTwoValue">
              <p className="text-center deleteMessage">
                Are you sure you want to delete this?
              </p>
            </div>
          </div>
        </CommonModal>
      </>
    );
  }
}

export default DeletePopup;
