/* eslint-disable */
import React from "react";
import "./footerButton.less";

const FooterActionButton = (props) => {
  return (
    <span className="footerStickyItem" onClick={props.onClick}>
      {props.title}
      {props.img && (
        <>
          {props.active ? (
            <>
              <img
                className="buttonArrow defultImg"
                src={require("../../../assets/svg/Dashboard/up-arrow.svg")}
                alt=""
              />
              <img
                className="buttonArrow hoverImg"
                src={require("../../../assets/svg/Dashboard/up-arrow-black.svg")}
                alt=""
              />
            </>
          ) : (
            <>
              <img
                className="buttonArrow defultImg"
                src={require("../../../assets/svg/Dashboard/down-arrow-white.svg")}
                alt=""
              />
              <img
                className="buttonArrow hoverImg"
                src={require("../../../assets/svg/Dashboard/down-arrow-theme.svg")}
                alt=""
              />
            </>
          )}
        </>
      )}
    </span>
  );
};
export default FooterActionButton;
