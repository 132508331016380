/* eslint-disable */
import React, { useState, useEffect } from "react";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import {
  handleInsertTrack,
  getColumn,
  isMobile,
} from "../../DiamondList/DiamondListFunctions";
import cloneDeep from "lodash/cloneDeep";
import UtilService from "../../../services/util";

import Table from "../../DiamondList/TableBack";
import IntlMessages from "../../../util/IntlMessages";
import { trackTypeObj, MIME_TYPES } from "../../../constants/Common";
import OpenNotification from "../CommonButton/OpenNotification";
import { HeadingCalc } from "./ConfirmStonePopup";
import { useSelector } from "react-redux";
import WatchPopupMobile from "./WatchPopupMobile";
import InputBlock from "../InputBlock";
import TextArea from "../TextArea";
import UploadImage from "../../common/UploadImage/index";
import {
  UPLOAD_FILE,
  CRETAE_CHEQUE_DETAILS,
} from "../../../constants/apiConstant";
import { Row, Col } from "antd";
/* eslint jsx-a11y/anchor-is-valid: 0 */

const currentType = "WatchlistPopup";

const ChequePopup = (props) => {
  const [columns, setColumns] = useState([]);
  const [comment, setComment] = useState("");
  const [exp, setExp] = useState(true);
  const [chequePath, setChequePath] = useState("");

  const [data, setData] = useState(
    props.checked.map((x) => ({ ...x, expBack: x.back - 1 })),
  );
  const checked =
    useSelector((state) => state.diamondData.selectedRows[currentType]) || [];

  const getBackSelect = (row) => {
    return (
      <select
        dropdownClassName="popupZindex"
        value={row.expBack}
        onChange={(e) => {
          let val = e.target.value;
          let line = data;
          let ind = findIndex(line, { id: row.id });
          line[ind].expBack = val;
          setData(line);
          setExp(!exp);
        }}
        style={{ width: 120 }}
      >
        <option value={row.back - 1}>{(row.back - 1).toFixed(2)}</option>
        <option value={row.back - 2}>{(row.back - 2).toFixed(2)}</option>
        <option value={row.back - 3}>{(row.back - 3).toFixed(2)}</option>
      </select>
    );
  };

  useEffect(() => {
    let Columns = getColumn();
    let index = findIndex(Columns, { id: "back" });
    if (!index) index = 1;
    let columns = [
      ...Columns.slice(0, index + 1),
      {
        Header: "Exp Disc%",
        accessor: "expBack",
        id: "expBack",
        Cell: ({ row }) => getBackSelect(row.original),
      },
    ];
    columns = [
      ...columns,
      ...Columns.filter((el) => !find(columns, { id: el.id })),
    ];
    setColumns(columns);
  }, [exp]);

  const getWatchInput = () => {
    return (
      <>
        {checked && checked.length !== 0 && (
          <h3>
            Stone Id:
            <span>
              {checked.map((a, ind) => {
                return (
                  <>
                    <b>{a.vStnId}</b>,{ind % 3 === 0}
                  </>
                );
              })}
            </span>
          </h3>
        )}
        <Row className="updateProfileItems mt-20 d-flex">
          <Col md={4}>
            <UploadImage
              file={chequePath}
              // disabled={isEmpty(this.state.doc)}
              // leftAlign
              title="Upload Shipping Label"
              onUpload={(e) => uploadDocument(e, "userKyc")}
            />
          </Col>
          <Col md={20}>
            <TextArea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              label="Remarks"
              placeholder="Remarks"
            />
          </Col>
        </Row>
      </>
    );
  };

  const btnAction = () => {
    return (
      <div className="sideBarPopupButton">
        <a
          onClick={() => {
            if (!checked.length) {
              OpenNotification({
                type: "error",
                title: "Please select stone(s) to upload label.",
              });
              return;
            }
            submitChequeDetails();
          }}
          className="commonButton"
        >
          <IntlMessages id="app.signup.Submit" />
        </a>
        <a className="commonButton" onClick={props.onClose}>
          <IntlMessages id="app.Cancel" />
        </a>
      </div>
    );
  };
  const uploadDocument = (ee, type) => {
    const e = cloneDeep(ee);
    if (e.target.files && e.target.files.length) {
      var blob = e.target.files[0];
      var fileReader = new FileReader();
      fileReader.onloadend = function (ev) {
        var arr = new Uint8Array(ev.target.result).subarray(0, 4);
        var header = "";
        for (var i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        if (
          !MIME_TYPES[blob.type] ||
          (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))
        ) {
          return OpenNotification({
            type: "error",
            title:
              "File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.",
          });
        } else {
          const data = new FormData();
          data.append("folder", "user");
          data.append("file", e.target.files[0]);

          let objData = {
            ...UPLOAD_FILE,
            request: data,
          };
          UtilService.callApi(objData, function (err, data) {
            if (err) throw err;
            if (data && data.code === "OK") {
              if (type === "userKyc") {
                setChequePath(data.data.files[0].absolutePath);
              }
            }
          });
        }
      };
      fileReader.readAsArrayBuffer(blob);
    }
  };
  const submitChequeDetails = () => {
    if (!chequePath) {
      OpenNotification({
        type: "error",
        title: "Please upload shipping label.",
      });
      return;
    }
    if (!comment) {
      OpenNotification({ type: "error", title: "Please write remark" });
      return;
    }
    let memoId = checked.map((a) => a.vStnId);
    let objData = {
      ...CRETAE_CHEQUE_DETAILS,
      request: {
        filePaths: [chequePath],
        type: 3,
        remarks: comment,
        memos: [...memoId],
      },
    };
    UtilService.callApi(objData, function (err, data) {
      if (err) throw err;
      if (data && data.code === "CREATED") {
        setChequePath("");
        setComment("");
        OpenNotification({
          type: "success",
          title: data.message ? data.message : "New Record added successfully",
        });

        props.onClose();
        props.clearAll();
      }
    });
  };
  return isMobile() ? (
    <WatchPopupMobile
      data={data}
      currentType={currentType}
      parent={{ btnAction, getBackSelect }}
    />
  ) : (
    <div>
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <h2 className="popupInnerTitle mr-40">
          {/* <IntlMessages id="app.WatchList" /> */}
          Upload Label
        </h2>
        {HeadingCalc(checked)}
      </div>
      <div className="searchPopupCommonTable">
        <div className="searchResultTable tabInnerTableScroll watchpopup">
          <Table
            data={data}
            columns={columns}
            areAllChecked={true}
            noGrp
            canSort={false}
            currentType={currentType}
          />
        </div>
        <div className="offerBottomBox mt-20">
          {/* <p className="offerNote">
                            <b>
                                <IntlMessages id="app.Note" />:
            </b>{" "}
                            <IntlMessages id="app.offerNote" />
                        </p> */}
          {getWatchInput()}
        </div>

        {btnAction()}
      </div>
    </div>
  );
};

export default ChequePopup;
