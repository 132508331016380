/* eslint-disable */
import React from "react";
import plusSvg from "assets/svg/plus.svg";
import closeSvg from "assets/svg/close.svg";
import classNames from "util/classNames";
import { isFunction } from "util/utils";
import "./input.less";

function Buttons({ onAdd, onRemove }) {
  return (
    (isFunction(onAdd) || isFunction(onRemove)) && (
      <div className="plusInputRemove">
        {isFunction(onAdd) && (
          <div className="plusInputItem">
            <img src={plusSvg} alt="" className="plusInput" />
          </div>
        )}
        {isFunction(onRemove) && (
          <div className="closeInoutItem">
            <img src={closeSvg} alt="" className="closeInput" />
          </div>
        )}
      </div>
    )
  );
}

export const InputField = React.memo(function InputField({
  label,
  required,
  error,
  onAdd,
  onRemove,
  ...rest
}) {
  return (
    <div className={`from-group inputBlock ${rest?.classNameHead}`}>
      <label>
        {label ?? rest?.holdLabel}{" "}
        {required && <span className="required-ast">*</span>}
      </label>
      <div
        className={classNames([
          "inputWrapper",
          error && "error-message",
          rest?.className,
        ])}
      >
        <input {...rest} />
        <Buttons {...{ onAdd, onRemove }} />
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
});

const InputBlock = (props) => {
  let groupClass = "from-group inputBlock";
  if (props.hasError) {
    groupClass = groupClass + " error-message";
  }
  return (
    <div className={groupClass}>
      {props.holdLabel && <span>{props.holdLabel}</span>}
      {props.label ? <label>{props.label}</label> : null}
      <div className="inputWrapper">
        <input
          placeholder={props.placeholder}
          value={props.value ? props.value : ""}
          type={props.type ? props.type : "text"}
          onChange={props.onChange ? props.onChange : null}
          {...props}
          onKeyDown={(e) => {
            if (
              e.keyCode === 37 ||
              e.keyCode === 38 ||
              e.keyCode === 39 ||
              e.keyCode === 40
            ) {
              props.type === "number" && e.preventDefault();
            }
            if (e.keyCode === 13) {
              props.loginKeyDown && props.loginKeyDown();
            }
          }}
        />
        {/* {props.InputAdd ?
                <div className="plusInputRemove">
                    <div className="plusInputItem">
                        <img src={require("../../assets/svg/plus.svg")} alt="" className="plusInput"/>
                    </div>
                    <div className="closeInoutItem">
                        <img src={require("../../assets/svg/close.svg")} alt="" className="closeInput"/>
                    </div>
                </div>
                : null } */}
      </div>
    </div>
  );
};
export default InputBlock;
