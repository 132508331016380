/* eslint-disable */
import React, { Component } from "react";
import CommonModal from "../CommonModal";
import InputBlock from "../InputBlock";
import {
  handleSendEmail,
  handleSendXRayEmail,
} from "../../DiamondList/DiamondListFunctions";
import { LOCAL_STORAGE_VAR } from "constants/Common";

class SendEmailPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: props.xRay ? "X-Ray Detail" : "Diamond Details",
      cc: "",
      email: "",
      message: props.xRay
        ? "Dear Sir / Madam \nGreeting of the day from Belgium Team.\n Please have a look at below Xray detail."
        : "Dear Sir / Madam \nGreeting of the day from Belgium Team.\n Please have a look at below stock file.",
      subjectInvalid: false,
      emailInvalid: false,
      ccInvalid: false,
    };
  }

  componentDidMount() {
    let { email } = localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)
      ? JSON.parse(
          window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)),
        )
      : "";
    this.setState({ email: email || "" });
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
    let regEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let result = regEx.test(String(e.target.value).toLowerCase());
    if (result) {
      this.setState({ emailInvalid: false });
    } else {
      this.setState({ emailInvalid: true });
    }
  }

  handleSubjectChange(e) {
    this.setState({ subject: e.target.value });
    if (e.target.value.trim() === "") {
      this.setState({ subjectInvalid: true });
    } else {
      this.setState({ subjectInvalid: false });
    }
  }

  handleCCChange(e) {
    this.setState({ cc: e.target.value });
    let regEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let result = regEx.test(String(e.target.value).toLowerCase());
    if (result || e.target.value.trim() === "") {
      this.setState({ ccInvalid: false });
    } else {
      this.setState({ ccInvalid: true });
    }
  }

  handleOk() {
    if (this.state.email.trim() === "") {
      this.setState({
        emailInvalid: true,
      });
    } else {
      if (
        this.state.emailInvalid === false &&
        this.state.subjectInvalid === false
      ) {
        let obj = {
          // ...this.state,
          email: {
            //"name": "raj",
            email: this.state.email,
            subject: this.state.subject,
            cc: this.state.cc,
            message: this.state.message,
            certFile: this.props.certFile,
            isHa: this.props.isHa,
            img: this.props.img,
            pltFile: this.props.isPlt,
            videoFile: this.props.videoFile,
            assetFile: this.props.assetFile,
            isExcel: this.props.isExcel,
            arrowFile: this.props.arrowFile,
            mp4Video: this.props.mp4Video,
            type2: this.props.type2,
            roughVideo: this.props.roughVideo,
            darkFieldImage: this.props.darkFieldImage,
            flsImage: this.props.flsImage,
            idealWhiteImage: this.props.idealWhiteImage,
            roughImage: this.props.roughImage,
            planImg: this.props.planImg,
            faceUp: this.props.faceUp,
            b2cRealImage: this.props.b2cRealImage,
            ...(this.props.orderDiamond
              ? { orderDiamond: this.props.ids }
              : { id: this.props.ids }),
          },
          quoteDiamonds: this.props.quoteApplied,
          sendEmail: true,
          finalCalc: this.props?.finalcalc ? true : undefined,
          isUpcoming: window.shortPath() === "upcoming" ? true : undefined,
          filter: {
            vStnId: this.props?.checked?.map((d) => d.vStnId),
            ...(!this.props?.finalCalc && { isFcCol: false }),
          },
          // ...(this.props.orderDiamond && { orderDiamond: true }),
        };
        for (var key in obj) {
          if (obj[key] === false) {
            delete obj[key];
          }
        }
        this.props?.handleLoader && this.props.handleLoader(true);
        this.props.xRay
          ? handleSendXRayEmail(
              { ...this.state },
              this.props.path,
              (status) => {
                if (status) this.props.onDetailClose();
                this.props?.handleLoader && this.props.handleLoader(false);
              },
            )
          : handleSendEmail(obj, (status) => {
              //if (status)
              this.props?.handleLoader && this.props.handleLoader(false);
              this.props.onCancel();
            });
      }
    }
  }
  render() {
    return (
      <>
        <CommonModal
          visible={this.props.sendEmail}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.props.onCancel()}
          title="Send Email"
          footerShow
          submitTitle="Send Email"
          cancelTitle="Cancel"
        >
          <div className="searchPopupCommon">
            <div className="searchPopupTwoValue">
              <InputBlock
                label="Subject *"
                placeholder="Subject"
                value={this.state.subject}
                //onChange={(e) => this.setState({ subject: e.target.value })}
                onChange={(e) => this.handleSubjectChange(e)}
                hasError={this.state.subjectInvalid ? true : false}
              />
              {this.state.subjectInvalid === true ? (
                <span className="required" style={{ color: "red" }}>
                  Please enter email subject.
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="searchPopupTwoValue">
              <InputBlock
                label="Email *"
                placeholder="Email ID"
                autoFocus
                value={this.state.email}
                //onChange={(e) => this.setState({ email: e.target.value })}
                onChange={(e) => this.handleEmailChange(e)}
                hasError={this.state.emailInvalid ? true : false}
              />
              {this.state.emailInvalid === true ? (
                <span className="required" style={{ color: "red" }}>
                  Please enter valid email address.
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="searchPopupTwoValue">
              <InputBlock
                label="Mail CC"
                placeholder="Mail CC"
                value={this.state.cc}
                onChange={(e) => this.handleCCChange(e)}
                //onChange={(e) => this.setState({ cc: e.target.value })}
                hasError={this.state.ccInvalid ? true : false}
              />
              {/* {this.state.ccInvalid === true ? (
                <span className="required" style={{ color: "red" }}>
                  Invalid CC !
                </span>
              ) : (
                ""
              )} */}
            </div>
            <div className="searchPopupTwoValue">
              <InputBlock
                label="Message"
                placeholder="Message"
                value={this.state.message}
                onChange={(e) => this.setState({ message: e.target.value })}
              />
            </div>
          </div>
        </CommonModal>
      </>
    );
  }
}

export default SendEmailPopup;
