/* eslint-disable */
import React from "react";
import "./fileUploaded.less";

const FileUploaded = (props) => {
  return (
    <div className={`fileUploadedBlockItem ${props.className}`}>
      <div className={`fileUplodImg ${props.sizeSmall && "smallSize"}`}>
        <img src={props.file} alt="fileAdd" />
      </div>
      <div className="fileUplodContent">
        <p>{props.title}</p>
      </div>
    </div>
  );
};
export default FileUploaded;
