/* eslint-disable */
import React, { useState } from "react";
import Slider from "react-slick";
import MagnifierImage from "../DiamondDetail/MagnifierImage";
import DNADetail from "./DNADetail";
import Image from "../common/Image";

const ImageSlider = (props) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const { dnaData, getMediaUrl } = props;

  const setting = [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: [
          dnaData.img ? 4 : 0,
          dnaData.hAFile ? 1 : 0,
          dnaData.arrowFile ? 1 : 0,
          dnaData.assetFile ? 1 : 0,
          dnaData.fluNm && dnaData.fluNm !== "NON" ? 1 : 0,
        ].reduce((a, b) => a + b, 0),
        slidesToScroll: 8,
        infinite: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
  ];

  return (
    <>
      <div className="dnaLeftBox dnaLeftBox-m-h">
        <div className="dnaLeftBoxImage diamond-Image">
          <div className="dnaImageTop">
            <span>Diamond Image</span>
          </div>
          <Slider
            className="sliderMainImage"
            asNavFor={nav2}
            arrows={true}
            adaptiveHeight={true}
            ref={(slider) => setNav1(slider)}
          >
            {
              dnaData.img && (
                <div
                  className="imageSliderFullBlock"
                  onClick={() => {
                    window.open(
                      `/viewImage/Office_Light_Black_BG/${dnaData.id}`,
                    );
                  }}
                >
                  <MagnifierImage image={getMediaUrl("img")} />
                </div>
              )
              // : (
              //   <div className="imageSliderFullBlock no-msg">
              //     No Faceup Image available.
              //   </div>
              // )
            }
            {/* {dnaData.hAFile ? ( */}
            {
              dnaData.hAFile && (
                <div
                  className="imageSliderFullBlock"
                  onClick={() => {
                    window.open(`/viewImage/HeartImages/${dnaData.id}`);
                  }}
                >
                  <MagnifierImage image={getMediaUrl("hAFile")} />
                </div>
              )
              // : (
              //   <div className="imageSliderFullBlock no-msg">
              //     No Heart Image available.
              //   </div>
              // )
            }
            {/* {dnaData.arrowFile ? ( */}
            {
              dnaData.arrowFile && (
                <div
                  className="imageSliderFullBlock"
                  onClick={() => {
                    window.open(`/viewImage/ArrowImages/${dnaData.id}`);
                  }}
                >
                  <MagnifierImage image={getMediaUrl("arrowFile")} />
                </div>
              )
              // : (
              //   <div className="imageSliderFullBlock no-msg">
              //     No Arrow Image available.
              //   </div>
              // )
            }
            {/* AssetScopeImages */}
            {/* {dnaData.assetFile ? ( */}
            {
              dnaData.assetFile && (
                <div
                  className="imageSliderFullBlock"
                  onClick={() => {
                    window.open(`/viewImage/AssetScopeImages/${dnaData.id}`);
                  }}
                >
                  <MagnifierImage image={getMediaUrl("assetFile")} />
                </div>
              )
              // : (
              //   <div className="imageSliderFullBlock no-msg">
              //     No Asset Image available.
              //   </div>
              // )
            }
            {/* {dnaData.pltFile ? ( */}
            {
              dnaData.img && (
                <div
                  className="imageSliderFullBlock"
                  onClick={() => {
                    window.open(`/viewImage/PlottingImages/${dnaData.id}`);
                  }}
                >
                  <MagnifierImage image={getMediaUrl("pltFile")} />
                </div>
              )
              // : (
              //   <div className="imageSliderFullBlock no-msg">
              //     No Plot Image available.
              //   </div>
              // )
            }

            {
              dnaData.img && (
                <div
                  className="imageSliderFullBlock"
                  onClick={() => {
                    window.open(`/viewImage/Dark_Field_White_BG/${dnaData.id}`);
                  }}
                >
                  <MagnifierImage image={getMediaUrl("darkFieldImage")} />
                </div>
              )
              // : (
              //   <div className="imageSliderFullBlock no-msg">
              //     No Dark Field White Image available.
              //   </div>
              // )
            }
            {
              dnaData.img && (
                <div
                  className="imageSliderFullBlock"
                  onClick={() => {
                    window.open(`/viewImage/IDEAL_White_BG/${dnaData.id}`);
                  }}
                >
                  <MagnifierImage image={getMediaUrl("idealWhiteImage")} />
                </div>
              )
              // : (
              //   <div className="imageSliderFullBlock no-msg">
              //     No Ideal White Image available.
              //   </div>
              // )
            }
            {
              dnaData.fluNm && dnaData.fluNm !== "NON" && (
                <div
                  className="imageSliderFullBlock"
                  onClick={() => {
                    window.open(`/viewImage/FlsImages/${dnaData.id}`);
                  }}
                >
                  <MagnifierImage image={getMediaUrl("flsImage")} />
                </div>
              )
              // : (
              //   <div className="imageSliderFullBlock no-msg">
              //     No Fluorscence Image available.
              //   </div>
              // )
            }
          </Slider>
          <Slider
            className="sliderSmallImage"
            asNavFor={nav1}
            ref={(slider) => setNav2(slider)}
            slidesToShow={6}
            infinite={true}
            swipeToSlide={true}
            focusOnSelect={true}
            responsive={setting}
            arrows={false}
          >
            {dnaData.img && (
              <div className="imageSliderSmallBlock">
                <div className="imageSliderSmallBox">
                  <Image src={getMediaUrl("img")} />
                </div>
                <span className="imageVideoTitle">Face-Up</span>
              </div>
            )}

            {dnaData.hAFile && (
              <div className="imageSliderSmallBlock">
                <div className="imageSliderSmallBox">
                  <Image src={getMediaUrl("hAFile")} />
                </div>
                <span className="imageVideoTitle">Heart</span>
              </div>
            )}

            {dnaData.arrowFile && (
              <div className="imageSliderSmallBlock">
                <div className="imageSliderSmallBox">
                  <Image src={getMediaUrl("arrowFile")} />
                </div>
                <span className="imageVideoTitle">Arrow</span>
              </div>
            )}

            {dnaData.assetFile && (
              <div className="imageSliderSmallBlock">
                <div className="imageSliderSmallBox">
                  <Image src={getMediaUrl("assetFile")} />
                </div>
                <span className="imageVideoTitle">Asset</span>
              </div>
            )}

            {dnaData.img && (
              <div className="imageSliderSmallBlock">
                <div className="imageSliderSmallBox">
                  <Image src={getMediaUrl("pltFile")} />
                </div>
                <span className="imageVideoTitle">Plotting</span>
              </div>
            )}

            {dnaData.img && (
              <div className="imageSliderSmallBlock">
                <div className="imageSliderSmallBox">
                  <Image src={getMediaUrl("darkFieldImage")} />
                </div>
                <span className="imageVideoTitle">Dark-Field</span>
              </div>
            )}

            {dnaData.img && (
              <div className="imageSliderSmallBlock">
                <div className="imageSliderSmallBox">
                  <Image src={getMediaUrl("idealWhiteImage")} />
                </div>
                <span className="imageVideoTitle">Ideal Scope</span>
              </div>
            )}
            {dnaData.fluNm && dnaData.fluNm !== "NON" && (
              <div className="imageSliderSmallBlock">
                <div className="imageSliderSmallBox">
                  <Image src={getMediaUrl("flsImage")} />
                </div>
                <span className="imageVideoTitle">Fluorescence </span>
              </div>
            )}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default ImageSlider;
