/* eslint-disable */
import React, { Component } from "react";
import Heading from "../../common/Heading";
import HomeSavedSearch from "./SavedSearch";
import RecentSearch from "./RecentSearch";
import RecentActivity from "./RecentActivity";
import TrackList from "./TrackList";
import FooterActionButton from "../../common/FooterActionButton";
import IntlMessages from "../../../util/IntlMessages";
import { GuestUserPopup } from "../../GuestUserPopup";
import UtilService from "../../../services/util";
import { LOCAL_STORAGE_VAR } from "../../../constants/Common";

class FooterSticky extends Component {
  state = {
    saved: false,
    recent: false,
    activity: false,
    calculator: false,
    tracklist: false,
    salesOption: false,
    dashData: undefined,
    isGuest: this.props.isGuest,
    showGuest: false,
  };

  user = null;

  componentDidUpdate(prevProps) {
    if (prevProps.dashData !== this.props.dashData) {
      this.setState({ dashData: this.props.dashData });
    }
  }
  componentDidMount() {
    this.user = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
  }

  savedSearch = () => {
    if (!this.state.isGuest) {
      !this.state.saved && this.props.getDashBoardData("savedSearch");
      this.setState({
        saved: !this.state.saved,
        recent: false,
        activity: false,
        calculator: false,
        tracklist: false,
        salesOption: false,
      });
    } else {
      this.setState({
        showGuest: true,
      });
    }
  };
  recentSearch = () => {
    if (!this.state.isGuest) {
      !this.state.recent && this.props.getDashBoardData("recentSearch");
      this.setState({
        recent: !this.state.recent,
        saved: false,
        activity: false,
        calculator: false,
        tracklist: false,
        salesOption: false,
      });
    } else {
      this.setState({
        showGuest: true,
      });
    }
  };
  recentActivity = () => {
    if (!this.state.isGuest) {
      !this.state.activity && this.props.getDashBoardData("recentActivity");
      this.setState({
        activity: !this.state.activity,
        recent: false,
        saved: false,
        calculator: false,
        tracklist: false,
        salesOption: false,
      });
    } else {
      this.setState({
        showGuest: true,
      });
    }
  };
  calculatorClick = () => {
    if (!this.state.isGuest) {
      this.setState({
        calculator: !this.state.calculator,
        recent: false,
        saved: false,
        activity: false,
        tracklist: false,
        salesOption: false,
      });
    } else {
      this.setState({
        showGuest: true,
      });
    }
  };
  tracklistDropdown = () => {
    if (!this.state.isGuest) {
      this.setState({
        tracklist: !this.state.tracklist,
        calculator: false,
        recent: false,
        saved: false,
        activity: false,
        salesOption: false,
      });
    } else {
      this.setState({
        showGuest: true,
      });
    }
  };
  salesDropdown = () => {
    if (!this.state.isGuest) {
      this.setState({
        salesOption: !this.state.salesOption,
        calculator: false,
        recent: false,
        saved: false,
        activity: false,
      });
    } else {
      this.setState({
        showGuest: true,
      });
    }
  };
  close = () => {
    this.setState({
      activity: false,
      recent: false,
      saved: false,
      calculator: false,
      tracklist: false,
      salesOption: false,
    });
  };
  render() {
    let { dashData } = this.state;
    const USER_PERMIT = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-userPermissions`,
    );

    return (
      <>
        <div className="footerStickyIcon">
          <div className="footerStickyLabel">
            <span>
              <IntlMessages id="app.myFinestar" />
            </span>
          </div>
          <div className="d-flex">
            <div className="footerStickyButton d-flex flex-wrap align-items-center">
              {USER_PERMIT && USER_PERMIT?.SAVE_SEARCH?.view && (
                <FooterActionButton
                  active={this.state.saved && true}
                  onClick={this.savedSearch}
                  title={<IntlMessages id="app.savedsearchtitle" />}
                  img
                />
              )}

              <div
                className={`footerDropdownBlock ${
                  this.state.saved ? "active" : null
                }`}
              >
                <div className="d-flex j-space-between mb-10 align-items-center">
                  <Heading
                    title={<IntlMessages id="app.savedsearchtitle" />}
                    className="mb-0 p-0"
                  />
                  <div>
                    <img
                      onClick={this.close}
                      className="buttonArrow"
                      src={require("../../../assets/svg/Dashboard/down-arrow.svg")}
                      alt=""
                    />
                  </div>
                </div>
                {this.state.saved && (
                  <HomeSavedSearch
                    save={dashData?.savedSearch?.list ?? []}
                    loading={this.props.footerloading}
                  />
                )}
              </div>
              <FooterActionButton
                active={this.state.recent && true}
                onClick={this.recentSearch}
                title={<IntlMessages id="app.recentsearchtitle" />}
                img
              />
              <div
                className={`footerDropdownBlock ${
                  this.state.recent ? "active" : null
                }`}
              >
                <div className="d-flex j-space-between mb-10 align-items-center">
                  <Heading
                    title={<IntlMessages id="app.recentsearchtitle" />}
                    className="mb-0 p-0"
                  />
                  <div>
                    <img
                      onClick={this.close}
                      className="buttonArrow"
                      src={require("../../../assets/svg/Dashboard/down-arrow.svg")}
                      alt=""
                    />
                  </div>
                </div>
                {this.state.recent && (
                  <RecentSearch
                    recent={dashData?.recentSearch?.list ?? []}
                    loading={this.props.footerloading}
                  />
                )}
              </div>
              {this.user && this.user.type !== 4 && (
                <FooterActionButton
                  active={this.state.activity && true}
                  onClick={this.recentActivity}
                  title={<IntlMessages id="app.RecentActivity" />}
                  img
                />
              )}
              <div
                className={`footerDropdownBlock ${
                  this.state.activity ? "active" : null
                }`}
              >
                <div className="d-flex j-space-between mb-10 align-items-center">
                  <Heading
                    title={<IntlMessages id="app.RecentActivity" />}
                    className="mb-0 p-0"
                  />
                  <div>
                    <img
                      onClick={this.close}
                      className="buttonArrow"
                      src={require("../../../assets/svg/Dashboard/down-arrow.svg")}
                      alt=""
                    />
                  </div>
                </div>
                <RecentActivity
                  activity={dashData?.recentActivity}
                  loading={this.props.footerloading}
                />
              </div>
              {USER_PERMIT &&
                USER_PERMIT.SHIPMENT.view &&
                this.user &&
                this.user.type !== 4 && (
                  <FooterActionButton
                    active={this.state.tracklist && true}
                    onClick={this.tracklistDropdown}
                    title={<IntlMessages id="app.inshipmenttitle" />}
                    img
                  />
                )}

              <div
                className={`footerDropdownBlock ${
                  this.state.tracklist ? "active" : null
                }`}
              >
                <div className="d-flex j-space-between mb-10 align-items-center">
                  <Heading
                    title={<IntlMessages id="app.TrackYourShipment" />}
                    className="mb-0 p-0"
                  />
                  <div>
                    <img
                      onClick={this.close}
                      className="buttonArrow"
                      src={require("../../../assets/svg/Dashboard/down-arrow.svg")}
                      alt=""
                    />
                  </div>
                </div>
                <TrackList />
              </div>
            </div>
          </div>
        </div>
        {this.state.showGuest && (
          <GuestUserPopup
            visible={this.state.showGuest}
            register={() => {
              window.open("/signup", "_self");
              window.localStorage.clear();
              window.sessionStorage.clear();
            }}
            handleCancel={() => {
              this.setState({ showGuest: false });
            }}
          />
        )}
      </>
    );
  }
}

export default FooterSticky;
