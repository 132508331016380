/* eslint-disable */
import React, { useState, useEffect } from "react";
import CommonModal from "../common/CommonModal";
import "./popup.less";
import { GET_BANNERS } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import { BASE_URL } from "../../constants/Common";
import Slider from "react-slick";

const PopupBanner = () => {
  const [visible, setVisible] = useState(true);
  const [PopupPageData, setPopupPageData] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    adaptiveHeight: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
  };

  const getLandingPageNewsData = () => {
    let obj = {
      ...GET_BANNERS,
      request: {
        page: 1,
        limit: 100,
        filter: { bannerType: 2, isActive: true },
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) {
        setPopupPageData(undefined);
      } else if (data && data.code === "OK") {
        if (data.data) {
          let sorted = data.data.list
            .map((x) => (x.type === "8" && x.isActive ? x.images : []))
            .flat();
          setPopupPageData(sorted);
        }
      } else {
        setPopupPageData(undefined);
      }
    });
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    getLandingPageNewsData();
  }, []);

  return PopupPageData && PopupPageData.length ? (
    <CommonModal
      modalSize="fixSize popupBanner"
      visible={visible}
      handleCancel={onClose}
    >
      <div className="bannerpopupImg">
        <Slider {...settings}>
          {PopupPageData.map((f, index) => {
            let url = "";
            if (typeof f === "string") {
              if (!f?.startsWith("/")) {
                url = BASE_URL + "/" + f;
              }
              else {
                url = BASE_URL + f;
              }
            }

            return (
              <div key={index} className="popupImageSlider">
                {["mp4", "mkv", "wmv"].includes(
                  f.split(".").pop().toLowerCase(),
                ) ? (
                  <div className="topbannerImage">
                    <video autoPlay loop>
                      <source src={url}></source>
                    </video>
                  </div>
                ) : (
                  <div className="topbannerImage">
                    <img src={url} alt="banner" />
                  </div>
                )}
              </div>
            );
          })}
        </Slider>
      </div>
      {/* <div className="bannerPopupCount"><CountdownTimer/></div> */}
    </CommonModal>
  ) : (
    ""
  );
};
export default PopupBanner;
