/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Heading from "../common/Heading";
import { svgFiles } from "../common/Svg";
import { Select, Dropdown } from "antd";
import { Input } from "antd";
import downArrow from "../../assets/svg/Search/down-arrow.svg";
import upArrow from "../../assets/svg/Search/up-arrow.svg";
import MobileBottomAction from "./MobileBottomAction";
import SavedSearchPopup from "./SavedSearchPopup";
import _ from "lodash";
import { notification } from "antd";
import InputRange from "./InputRange";
import Carat from "./Carat";
import KeyToSymbol from "./KeyToSymbol";
import OpenNotification from "components/common/CommonButton/OpenNotification";
import { handleSelectSearch } from "../../containers/APIFunctions";
import AddDemandPopup from "./AddDemandPopup";
import IntlMessages from "../../util/IntlMessages";
import {
  DIAMOND_WEB_STATUS,
  RANGE_WISE_MASTER_SELECTION,
  MULTIPLE_SEARCH,
} from "../../constants/Common";
import CheckBox from "../common/CheckBox";
import TooltipBox from "./TooltipBox";
import { getGridHeight } from "../DiamondList";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import { GuestUserPopup } from "../../components/GuestUserPopup";
import UtilService from "../../services/util";
import { includes } from "lodash";
import { isNotEmpty } from "util/utils";
/* eslint jsx-a11y/anchor-is-valid: 0 */

const { Option } = Select;
const tooltipMasters = [
  "cut",
  "pol",
  "sym",
  "flu",
  "hA",
  "brlncy",
  "shd",
  "cult",
  "grdl",
];
const masterRangeSelection = ["col", "clr", "shd"];
class DiamondSearch extends Component {
  inputRef = React.createRef();
  state = {
    shapeExtend: false,
    showParameter: false,
    showOtherParams: false,
    showKeyToSymbol: false,
    incPatterns: false,
    isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`),
    showGuest: false,
    value: 1,
    userAccess: {},
  };

  componentWillReceiveProps(nextProps) {
    let userAccess = JSON.parse(
      window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)),
    );
    this.setState({ userAccess });
    if (
      nextProps.commonData.modifySearch &&
      this.props.commonData.modifySearch !== nextProps.commonData.modifySearch
    ) {
      let toggles = ["isSeal", "opPav", "opTbl", "opCrwn"];
      toggles.map((tgl) => {
        if (nextProps.commonData.modifySearch[tgl]) {
          let val = null;
          if (tgl === "isSeal")
            val =
              nextProps.commonData.modifySearch[tgl] === true
                ? "YES"
                : nextProps.commonData.modifySearch[tgl] === false
                  ? "NO"
                  : null;
          else {
            let yesno = nextProps.commonData.modifySearch[tgl][0];
            let arr = this.props.diamParams[tgl].filter((el) =>
              el.id.includes(yesno),
            );
            val = arr[0] ? arr[0].code : null;
          }
          if (val) {
            this.props.handleToggle(tgl, val);
            this.props.handleChange(tgl, val);
          }
        }
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", () => this.forceUpdate());
  }

  componentDidUpdate(prevProps) {
    if (this.props.commonData.strip !== prevProps.commonData.strip)
      this.forceUpdate();
  }

  moreLess = () => {
    this.setState({
      shapeExtend: !this.state.shapeExtend,
    });
  };

  handleReportNo = () => {
    notification.destroy();
    OpenNotification({
      type: "error",
      title:
        "Sorry, the stone id(s) you have entered is not found. Please try again.",
    });
  };

  getMaster = (key) => {
    const { diamParams, selectedMaster } = this.props;
    if (
      key === "lb" &&
      ![1, 2].includes(this.state?.userAccess?.type) &&
      !this.state?.userAccess?.isFm &&
      diamParams?.[key]
    ) {
      diamParams[key] = diamParams[key]?.filter(({ code }) => code !== "FM");
    }

    return (
      <>
        <ul
          className="diamondSearchList"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {diamParams[key] &&
            diamParams[key].map((d, index) => {
              d.checked = false;
              _.each(d.id, function (id) {
                if (selectedMaster[key] && selectedMaster[key].includes(id)) {
                  d.checked = true;
                }
              });
              return includes(tooltipMasters, key) ? (
                <TooltipBox title={d.description}>
                  <li
                    onClick={(e) =>
                      RANGE_WISE_MASTER_SELECTION &&
                        includes(masterRangeSelection, key)
                        ? this.props.handleMasterRangeSelection(index, key)
                        : this.props.handleSelection(d.id, !d.checked, key)
                    }
                    className={d.checked ? `active` : null}
                  >
                    {d.webDisplay}
                  </li>
                </TooltipBox>
              ) : (
                <li
                  key={index}
                  onClick={(e) =>
                    RANGE_WISE_MASTER_SELECTION &&
                      includes(masterRangeSelection, key)
                      ? this.props.handleMasterRangeSelection(index, key)
                      : this.props.handleSelection(d.id, !d.checked, key)
                  }
                  className={d.checked ? `active` : null}
                >
                  {d.webDisplay}
                </li>
              );
            })}
        </ul>
      </>
    );
  };

  getToggle = (key) => {
    return (
      <ul className="diamondSearchList">
        <li
          className={this.props[key] === "YES" ? `active` : null}
          onClick={() => {
            if (this.props[key] === "YES") {
              this.props.handleToggle(key, "");
              this.props.handleChange(key, "");
            } else {
              this.props.handleToggle(key, "YES");
              this.props.handleChange(key, "YES");
            }
          }}
        >
          YES
        </li>
        <li
          className={this.props[key] === "NO" ? `active` : null}
          onClick={() => {
            if (this.props[key] === "NO") {
              this.props.handleToggle(key, "");
              this.props.handleChange(key, "");
            } else {
              this.props.handleToggle(key, "NO");
              this.props.handleChange(key, "NO");
            }
          }}
        >
          NO
        </li>
      </ul>
    );
  };
  handleFocus = () => {
    this.inputRef.current.blur();
  };
  render() {
    let gridHeight = getGridHeight(["HeaderSticky"], 15);
    let {
      diamParams = {},
      selectedMaster = {},
      searchList = [],
      recentSearchList = [],
      isFancy,
    } = this.props;

    const USER_PERMIT = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-userPermissions`,
    );
    return (
      <div
        className="diamondSearch"
        style={{ height: gridHeight, overflow: "auto" }}
      >
        <div className="d-flex justify-space-between searchTopBox align-items-center">
          <Heading title={<IntlMessages id="app.search-diamond" />} />
          <div className="searchTopInputValue d-flex align-items-center">
            <div className="searchTopBlock">
              <IntlMessages id="app.StnIDRprtNo" defaultMessage="search">
                {(placeholder) => (
                  <Input
                    ref={this.inputRef}
                    type="text"
                    placeholder={placeholder}
                    autoComplete="off"
                    value={this.props.stoneIds}
                    onChange={(e) => {
                      this.props.handleChange("stoneIds", e.target.value);
                    }}
                    onBlur={() => {
                      // this.handleFocus();
                      this.props.handleSetStoneIds("blurEvent");
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        this.handleFocus();
                        this.props.handleSetStoneIds();
                        this.props.handleSearch();
                      }
                    }}
                  />
                )}
              </IntlMessages>
            </div>
          </div>
        </div>
        {/* {this.props.packetNo && this.props.handleSearch()} */}
        <div className="diamondSearchWrapper">
          <div className="diamondSearchItem">
            <div className="colorTab">
              <a
                className={this.props.autoShp === "R" ? "active" : null}
                onClick={() => this.props.handleAutoShapeSelection("R")}
              >
                Round
              </a>
              <a
                className={this.props.autoShp === "F" ? "active" : null}
                onClick={() => this.props.handleAutoShapeSelection("F")}
              >
                Fancy
              </a>
            </div>
            <div className="diamondSearchDetail width-100">
              <ul
                className={`diamondSearchShape ${this.state.shapeExtend ? "active" : null
                  }`}
                style={{
                  display: "flex",
                  flexWrap: `${window.innerWidth <= 767 ? "wrap" : "nowrap"}`,
                }}
              >
                {diamParams.shp &&
                  diamParams.shp.map((s, index) => {
                    if (s.code === "AS") s.code = "ASSCHER";
                    else if (s.code === "EC") s.code = "CML";

                    s.checked = false;
                    _.each(s.id, function (id) {
                      if (
                        selectedMaster.shp &&
                        selectedMaster.shp.includes(id)
                      ) {
                        s.checked = true;
                      }
                    });
                    return (
                      <TooltipBox title={s?.fullName} key={index}>
                        <li
                          className={s.checked ? `active` : null}
                          onClick={(e) =>
                            this.props.handleSelection(
                              s.id,
                              !s.checked,
                              "shp",
                              "shape",
                            )
                          }
                        >
                          <span>
                            {svgFiles.find((svg) => svg.name === s.code)
                              ? svgFiles.find((svg) => svg.name === s.code).file
                              : ""}
                          </span>

                          {/* <span>{_.capitalize(s.webDisplay)}</span> */}
                          <span>{(s.code === "CEB")
                            ? "Cushion EB"
                            : _.capitalize(s.webDisplay)}</span>
                        </li>
                      </TooltipBox>
                    );
                  })}
              </ul>
            </div>
          </div>

          <Carat
            data={diamParams.carat}
            or={this.props.or}
            masterIndex={
              this.props.masterIndex && this.props.masterIndex.crt
                ? this.props.masterIndex.crt
                : []
            }
            handleCaratSelection={this.props.handleCaratSelection}
            handleAddCustomCarat={this.props.handleAddCustomCarat}
          />
          {isFancy && (
            <div className="diamondSearchItem colorClarityBox">
              <div className="diamondSearchLabel">
                <label>
                  <IntlMessages id="app.compare.color" />
                </label>
              </div>
              <div className="diamondSearchDetail">
                {this.getMaster("fcCol")}
              </div>
            </div>
          )}
          {!isFancy && (
            <div className="diamondSearchItem colorClarityBox">
              <div className="diamondSearchLabel">
                <label>
                  <IntlMessages id="app.compare.color" />
                </label>
                <div className="colorTab">
                  <a
                    className={`${!this.props.clrSwitch ? "active" : null}`}
                    onClick={() => this.props.handleClrSwitch()}
                  >
                    <IntlMessages id="app.COLOR GROUP" />
                  </a>
                  <a
                    className={`${this.props.clrSwitch ? "active" : null}`}
                    onClick={() => this.props.handleClrSwitch()}
                  >
                    <IntlMessages id="app.compare.color" />
                  </a>
                </div>
              </div>
              <div className="diamondSearchDetail">
                {!this.props.clrSwitch && (
                  <ul className="diamondSearchList">
                    {diamParams.clrGrp.map((d, index) => {
                      return (
                        <li
                          className={
                            _.includes(this.props.selectedClrGrps, d.grp) ?
                              `active` : null
                          }
                          onClick={() => {
                            this.props.handleClrGrpSearch(d.grp);
                          }}
                        >
                          {d.grp}
                        </li>
                      );
                    })}
                  </ul>
                )}
                {this.props.clrSwitch && this.getMaster("col")}
              </div>
            </div>
          )}
          <div className="diamondSearchItem colorClarityBox">
            <div className="diamondSearchLabel">
              <label>
                <IntlMessages id="app.Clarity" />
              </label>
              <div className="colorTab">
                <a
                  className={`${!this.props.claritySwitch ? "active" : null}`}
                  onClick={() => this.props.handleClarSwitch()}
                >
                  <IntlMessages id="app.CLARITY GROUP" />
                </a>
                <a
                  className={`${this.props.claritySwitch ? "active" : null}`}
                  onClick={() => this.props.handleClarSwitch()}
                >
                  <IntlMessages id="app.Clarity" />
                </a>
              </div>
            </div>
            <div className="diamondSearchDetail">
              {!this.props.claritySwitch && (
                <ul className="diamondSearchList">
                  {diamParams.clarGrp.map((d, index) => (
                    <li
                      className={
                        _.includes(this.props.selectedClarGrps, d.grp) ?
                          `active` : null
                      }
                      onClick={() => {
                        this.props.handleClarityGrpSearch(d.grp);
                      }}
                    >
                      {d.grp}
                    </li>
                  ))}
                </ul>
              )}
              {this.props.claritySwitch && this.getMaster("clr")}
            </div>
          </div>
          <div className="diamondSearchItem colorClarityBox">
            <div className="diamondSearchLabel">
              <label>
                <IntlMessages id="app.diamonddetails.Additionalinfo.coloeshade" />
              </label>
            </div>
            <div className="diamondSearchDetail">{this.getMaster("shd")}</div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="diamondSearchItem makeGroup">
              <div className="d-flex align-items-center flex-wrap">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessages id="app.make" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  <ul className="diamondSearchList">
                    <li
                      className={this.props.threeEX ? `active` : null}
                      onClick={() => {
                        this.props.handleCombineSearch(
                          "threeEX",
                          !this.props.threeEX,
                          false,
                        );
                      }}
                    >
                      3EX
                    </li>
                    <li
                      className={this.props.threeEXNon ? `active` : null}
                      onClick={() => {
                        this.props.handleCombineSearch(
                          "threeEXNon",
                          !this.props.threeEXNon,
                          false,
                        );
                      }}
                    >
                      3EX-NON
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessages id="app.fancyMake" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  <ul className="diamondSearchList">
                    <li
                      className={this.props.fancyThreeEX ? `active` : null}
                      onClick={() => {
                        this.props.handleCombineSearch(
                          "fancyThreeEX",
                          !this.props.fancyThreeEX,
                          false,
                        );
                      }}
                    >
                      2EX
                    </li>
                    <li
                      className={this.props.fancyThreeEXNon ? `active` : null}
                      onClick={() => {
                        this.props.handleCombineSearch(
                          "fancyThreeEXNon",
                          !this.props.fancyThreeEXNon,
                          false,
                        );
                      }}
                    >
                      2EX-NON
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessages id="app.compare.cut" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  {this.getMaster("cut")}
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessages id="app.compare.polish" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  {this.getMaster("pol")}
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessages id="app.symm" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  {this.getMaster("sym")}
                </div>
              </div>
            </div>
            <div className="diamondSearchItem floBlock">
              <div>
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessages id="app.compare.flouresence" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  {this.getMaster("flu")}
                </div>
              </div>
            </div>
            <div className="diamondSearchItem labBlock">
              <div>
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessages id="app.compare.lab" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  {this.getMaster("lb")}
                </div>
              </div>
            </div>
            <div className="diamondSearchItem floBlock">
              <div>
                <div className="diamondSearchLabel">
                  <label>
                    <span>
                      <IntlMessages id="app.diamonddetails.Additionalinfo.Location" />
                    </span>
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  {this.getMaster("loc")}
                </div>
              </div>
            </div>
          </div>
          <div className="diamondSearchItem d-flex j-space-between diamondsearchleftRight">
            <div className="diamondsearchleft">
              <div className="d-flex align-items-center flex-wrap">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessages id="app.hna" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  {this.getMaster("hA")}
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessages id="app.BRILLIANCE" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  {this.getMaster("brlncy")}
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessages id="app.Stage" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  <ul className="diamondSearchList">
                    <li
                      className={
                        _.includes(this.props.wSts, DIAMOND_WEB_STATUS.A) ?
                          `active` : null
                      }
                      onClick={() =>
                        this.props.handleWebStatus(DIAMOND_WEB_STATUS.A)
                      }
                    >
                      AVAILABLE
                    </li>
                    {/* <li
                      className={
                        _.includes(
                          this.props.wSts,
                          DIAMOND_WEB_STATUS.OFFICE
                        ) ?  `active` : null
                      }
                      onClick={() =>
                        this.props.handleWebStatus(DIAMOND_WEB_STATUS.OFFICE)
                      }
                    >
                      OFFICE
                    </li> */}
                    <li
                      className={
                        _.includes(this.props.wSts, DIAMOND_WEB_STATUS.M) ?
                          `active` : null
                      }
                      onClick={() =>
                        this.props.handleWebStatus(DIAMOND_WEB_STATUS.M)
                      }
                    >
                      MEMO
                    </li>

                    <li
                      className={
                        _.includes(this.props.wSts, DIAMOND_WEB_STATUS.SHOW) ?
                          `active` : null
                      }
                      onClick={() =>
                        this.props.handleWebStatus(DIAMOND_WEB_STATUS.SHOW)
                      }
                    >
                      SHOW
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="diamondSearchDetail width-auto diamondSearchSingle">
              <ul className="diamondSearchList">
                <li
                  className={this.props.newArr ? `active` : null}
                  onClick={() => {
                    this.props.handleReverseState("newArr");
                    this.props.handleNewArrival();
                  }}
                >
                  NEW ARRIVAL
                </li>
                <li
                  className={this.props.upcoming ? `active` : null}
                  onClick={() => {
                    this.props.handleReverseState("upcoming");
                    this.props.handleUpcoming();
                  }}
                >
                  UPCOMING
                </li>
                <li
                  className={this.props.noBGM ? `active` : null}
                  onClick={() =>
                    this.props.handleCombineSearch(
                      "noBGM",
                      !this.props.noBGM,
                      true,
                    )
                  }
                >
                  NO BGM
                </li>
                <li
                  className={this.props.eyeCln ? `active` : null}
                  onClick={() =>
                    this.props.handleCombineSearch(
                      "eyeCln",
                      !this.props.eyeCln,
                      true,
                    )
                  }
                >
                  EYE CLEAN
                </li>
                <li
                  className={this.props.isArtisanCerti && `active`}
                  onClick={() => {
                    this.props.handleReverseState('isArtisanCerti');
                    this.props.handleMta();
                  }}
                >
                  MEET THE ARTISAN (MTA)
                </li>

              </ul>
            </div>
          </div>
          {!this.state.isGuest && (
            <div className="diamondSearchItem">
              <div className="diamondSearchLabel">
                <label>
                  <IntlMessages id="app.PriceNDisc" />
                </label>
              </div>

              <div className="diamondSearchDetail">
                <div className="d-flex align-items-center flex-wrap searchSelectValue">
                  <InputRange
                    title={<IntlMessages id="app.Disc%" />}
                    allowSame
                    Min={-100}
                    Max={100}
                    master={diamParams["back"]}
                    selectedRange={this.props.selectedRange}
                    apiKey="back"
                    allowAny
                  />
                  <InputRange
                    allowSame
                    Min={0}
                    Max={99999999}
                    master={diamParams["ctPr"]}
                    title={<IntlMessages id="app.PricePerCarat" />}
                    selectedRange={this.props.selectedRange}
                    apiKey="ctPr"
                    allowAny
                  />
                  <InputRange
                    allowSame
                    Min={0}
                    Max={99999999}
                    master={diamParams["amt"]}
                    title={<IntlMessages id="app.Amount" />}
                    selectedRange={this.props.selectedAmount}
                    apiKey="amt"
                    noNegative={true}
                    allowAny
                  />
                </div>
              </div>
            </div>
          )}
          <div className="diamondSearchItem">
            <div className="diamondSearchDetail diffrentBlockWrapper d-flex width-100 flex-wrap">
              <div className="threeBlockPart">
                <ul className="diamondSearchList">
                  {/* //CM// */}
                  <li
                    className={this.props.canadamark ? `active` : null}
                    onClick={() => {
                      this.props.handleReverseState("canadamark");
                      this.props.handleCanadamark();
                    }}
                  >
                    CANADAMARK
                  </li>
                  {/* //SVS// */}
                  <li
                    className={this.props?.isSVS ? `active` : null}
                    onClick={() => {
                      this.props.handleReverseState("isSVS");
                      this.props.handlesvsStatus();
                    }}
                  >
                    SVS (GIA)
                  </li>
                  {/* //TRA// */}
                  <li
                    className={this.props?.isTrcr ? `active` : null}
                    onClick={() => {
                      this.props.handleReverseState("isTrcr");
                      this.props.handleTraStatus();
                    }}
                  >
                    TRACR (DTC)
                  </li>
                  {/* //DVK// */}
                  <Dropdown
                    overlayClassName="selectOptionDropdown"
                    overlay={
                      <div>
                        <CheckBox
                          checked={_.includes(
                            this.props?.selectedMaster?.isDiavik,
                            "CERT",
                          )}
                          onChange={(e) =>
                            this.props?.handleLab(
                              e.target?.checked,
                              "isDiavik",
                              "CERT",
                            )
                          }
                          label="INVENTORY"
                        />
                        <CheckBox
                          checked={_.includes(
                            this.props?.selectedMaster?.isDiavik,
                            "ELIG",
                          )}
                          onChange={(e) =>
                            this.props?.handleLab(
                              e.target?.checked,
                              "isDiavik",
                              "ELIG",
                            )
                          }
                          label="ELIGIBLE"
                        />
                      </div>
                    }
                    trigger={["click"]}
                  >
                    <li
                      className={`ant-dropdown-link multiSelectDropdown ${isNotEmpty(this.props.dvkKeys) ? `active` : null
                        }`}
                      onClick={(e) => e.preventDefault()}
                    >
                      DIAVIK (GIA)
                      <img src={downArrow} alt="" />
                    </li>
                  </Dropdown>

                  {!(
                    ![1, 2].includes(this.state?.userAccess?.type) &&
                    !this.state?.userAccess?.isFm
                  ) && (
                      <Dropdown
                        overlayClassName="selectOptionDropdown"
                        overlay={
                          <div>
                            <CheckBox
                              checked={_.includes(
                                this.props.selectedMaster.isFm,
                                "CERT",
                              )}
                              onChange={(e) =>
                                this.props.handleLab(
                                  e.target.checked,
                                  "isFm",
                                  "CERT",
                                )
                              }
                              label="INVENTORY"
                            />
                            <CheckBox
                              checked={_.includes(
                                this.props.selectedMaster.isFm,
                                "ELIG",
                              )}
                              onChange={(e) =>
                                this.props.handleLab(
                                  e.target.checked,
                                  "isFm",
                                  "ELIG",
                                )
                              }
                              label="ELIGIBLE"
                            />
                          </div>
                        }
                        trigger={["click"]}
                      >
                        <li
                          className={`ant-dropdown-link multiSelectDropdown ${isNotEmpty(this.props.fmKeys) ? `active` : null
                            }`}
                          onClick={(e) => e.preventDefault()}
                        >
                          FM
                          <img src={downArrow} alt="" />
                        </li>
                      </Dropdown>
                    )}
                  <li
                    className={this?.props?.cooStatus ? `active` : null}
                    onClick={() => {
                      this.props.handleReverseState("cooStatus");
                      this.props.handleCooStatus();
                    }}
                  >
                    COO
                  </li>
                  <Dropdown
                    overlayClassName="selectOptionDropdown"
                    overlay={
                      <div>
                        <CheckBox
                          checked={_.includes(
                            this.props?.selectedMaster?.isDor,
                            "CERT",
                          )}
                          onChange={(e) =>
                            this.props?.handleLab(
                              e.target?.checked,
                              "isDor",
                              "CERT",
                            )
                          }
                          label="INVENTORY"
                        />
                        <CheckBox
                          checked={_.includes(
                            this.props?.selectedMaster?.isDor,
                            "ELIG",
                          )}
                          onChange={(e) =>
                            this.props?.handleLab(
                              e.target?.checked,
                              "isDor",
                              "ELIG",
                            )
                          }
                          label="ELIGIBLE"
                        />
                      </div>
                    }
                    trigger={["click"]}
                  >
                    <li
                      className={`ant-dropdown-link multiSelectDropdown ${isNotEmpty(this.props.dorKeys) ? `active` : null
                        }`}
                      onClick={(e) => e.preventDefault()}
                    >
                      DOR
                      <img src={downArrow} alt="" />
                    </li>
                  </Dropdown>
                  <li
                    className={this.props.type2 ? `active` : null}
                    onClick={() => {
                      this.props.handleReverseState("type2");
                      this.props.handleType2();
                    }}
                  >
                    TYPE IIA
                  </li>

                  <li
                    className={this.props.xRay ? `active` : null}
                    onClick={() => {
                      this.props.handleReverseState("xRay");
                      this.props.handleXray();
                    }}
                  >
                    XRAY
                  </li>
                </ul>
              </div>

              <div className="searchSelectValue threeBlockPart">
                <div className="searchSelectItem singleInput threeInnerBlock">
                  <div>
                    <label className="smallLabel">
                      <span>
                        <IntlMessages id="app.pair-no" />
                      </span>
                    </label>
                    <IntlMessages id="app.pair-no">
                      {(placeholder) => (
                        <input
                          placeholder={placeholder}
                          value={this.props.pairNo}
                          onChange={(e) => {
                            this.props.handleChange("pairNo", e.target.value);
                          }}
                          onBlur={() => {
                            this.props.handleSetPairs();
                          }}
                        />
                      )}
                    </IntlMessages>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="hideShowBlock"
            onClick={() =>
              this.setState({
                incPatterns: !this.state.incPatterns,
              })
            }
          >
            <span>
              <IntlMessages id="app.InclusionPatterns" />
            </span>
            {this.state.incPatterns ? (
              <img src={upArrow} alt="" />
            ) : (
              <img src={downArrow} alt="" />
            )}
          </div>
          {this.state.incPatterns && (
            <div className="fullBlockInnerBlock d-flex flex-wrap inclusiveBlock">
              <div className="width-50  m-width-100">
                <label class="smallLabelInner">
                  <IntlMessages id="app.BlackInclusion" />
                </label>
                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.CenterNatts" />
                    </label>
                  </div>
                  <div className="diamondSearchDetail">
                    {this.getMaster("blkTbl")}
                  </div>
                </div>
                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.SideNatts" />
                    </label>
                  </div>
                  <div className="diamondSearchDetail">
                    {this.getMaster("blkSd")}
                  </div>
                </div>
              </div>
              <div className="width-50  m-width-100">
                <label class="smallLabelInner">
                  <IntlMessages id="app.WhiteInclusion" />
                </label>
                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.Center" />
                    </label>
                  </div>
                  <div className="diamondSearchDetail">
                    {this.getMaster("wTbl")}
                  </div>
                </div>
                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.Side" />
                    </label>
                  </div>
                  <div className="diamondSearchDetail">
                    {this.getMaster("wSd")}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="hideShowBlock"
            onClick={() =>
              this.setState({
                showParameter: !this.state.showParameter,
              })
            }
          >
            <span>
              <IntlMessages id="app.param" />
            </span>
            {this.state.showParameter ? (
              <img src={upArrow} alt="" />
            ) : (
              <img src={downArrow} alt="" />
            )}
          </div>
          {this.state.showParameter && (
            <div className="fullBlockInnerBlock">
              <div className="diamondSearchItem b-b-0">
                <div className="diamondSearchDetail fullWidthBlock">
                  <div className="d-flex align-items-center flex-wrap">
                    <InputRange
                      title={
                        <IntlMessages id="app.diamonddetails.Additionalinfo.Tableper" />
                      }
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams["tblPer"]}
                      selectedRange={this.props.selectedRange}
                      apiKey="tblPer"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.total-depth" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams["depPer"]}
                      selectedRange={this.props.selectedRange}
                      apiKey="depPer"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.ratio" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams["ratio"]}
                      selectedRange={this.props.selectedRange}
                      apiKey="ratio"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.length" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams["length"]}
                      selectedRange={this.props.selectedRange}
                      apiKey="length"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.width" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams["width"]}
                      selectedRange={this.props.selectedRange}
                      apiKey="width"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.depth" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams["height"]}
                      selectedRange={this.props.selectedRange}
                      apiKey="height"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.crAn" />}
                      allowSame
                      Min={0}
                      Max={360}
                      master={diamParams["cAng"]}
                      selectedRange={this.props.selectedRange}
                      apiKey="cAng"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.crown-height%" />}
                      allowSame
                      Min={0}
                      Max={99999999}
                      master={diamParams["cHgt"]}
                      selectedRange={this.props.selectedRange}
                      apiKey="cHgt"
                      noNegative={true}
                    />
                    <InputRange
                      title={
                        <IntlMessages id="app.diamonddetails.Additionalinfo.Girdle" />
                      }
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams["grdlPer"]}
                      selectedRange={this.props.selectedRange}
                      apiKey="grdlPer"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.pavAn" />}
                      allowSame
                      Min={0}
                      Max={360}
                      master={diamParams["pAng"]}
                      selectedRange={this.props.selectedRange}
                      apiKey="pAng"
                      noNegative={true}
                    />

                    <InputRange
                      title={<IntlMessages id="app.pavHt" />}
                      allowSame
                      Min={0}
                      Max={99999999}
                      master={diamParams["pHgt"]}
                      selectedRange={this.props.selectedRange}
                      apiKey="pHgt"
                      noNegative={true}
                    />
                    <InputRange
                      title={
                        <IntlMessages id="app.diamonddetails.Additionalinfo.LowerHalf" />
                      }
                      allowSame
                      Min={0}
                      Max={99999999}
                      master={diamParams["lwr"]}
                      selectedRange={this.props.selectedRange}
                      apiKey="lwr"
                      noNegative={true}
                    />

                    <InputRange
                      title={
                        <IntlMessages id="app.diamonddetails.Additionalinfo.StarLength" />
                      }
                      allowSame
                      Min={0}
                      Max={99999999}
                      master={diamParams["strLn"]}
                      selectedRange={this.props.selectedRange}
                      apiKey="strLn"
                      noNegative={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="hideShowBlock"
            onClick={() =>
              this.setState({
                showOtherParams: !this.state.showOtherParams,
              })
            }
          >
            <span>
              <IntlMessages id="app.OtherParameters" />
            </span>
            {this.state.showOtherParams ? (
              <img src={upArrow} alt="" />
            ) : (
              <img src={downArrow} alt="" />
            )}
          </div>
          {this.state.showOtherParams && (
            <div className="fullBlockInnerBlock d-flex flex-wrap">
              <div className="width-50 m-width-100">
                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.OpenOnCrown" />
                    </label>
                  </div>
                  <div className="diamondSearchDetail">
                    {this.getToggle("opCrwn")}
                  </div>
                </div>

                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.OpenOnTable" />
                    </label>
                  </div>
                  <div className="diamondSearchDetail">
                    {this.getToggle("opTbl")}
                  </div>
                </div>
                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.OpenOnPavillion" />
                    </label>
                  </div>
                  <div className="diamondSearchDetail">
                    {this.getToggle("opPav")}
                  </div>
                </div>
              </div>
              <div className="width-50 m-width-100">
                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.Culet" />
                    </label>
                  </div>
                  <div className="diamondSearchDetail">
                    {this.getMaster("cult")}
                  </div>
                </div>

                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.Girdle" />
                    </label>
                  </div>
                  <div className="diamondSearchDetail">
                    {this.getMaster("grdl")}
                  </div>
                </div>
                {/* <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.Seal" />
                    </label>
                  </div>
                  <div className="diamondSearchDetail">{this.getToggle("isSeal")}</div>
                </div> */}
              </div>
            </div>
          )}
          {this.state.showWhiteInclusion && (
            <div className="fullBlockInnerBlock"></div>
          )}
          <div
            className="hideShowBlock"
            onClick={() =>
              this.setState({
                showKeyToSymbol: !this.state.showKeyToSymbol,
              })
            }
          >
            <span>
              <IntlMessages id="app.KeyToSymbolReportComments" />
            </span>

            {this.state.showKeyToSymbol ? (
              <img src={upArrow} alt="" />
            ) : (
              <img src={downArrow} alt="" />
            )}
          </div>
          {this.state.showKeyToSymbol && (
            <KeyToSymbol
              data={diamParams["keyToSymbol"]}
              selectedKeyToSymbol={this.props.selectedKeyToSymbol}
            />
          )}
        </div>
        <MobileBottomAction
          {...this.props}
          saveSearchPopup={() =>
            this.props.handleReverseState("showSavedSearch")
          }
        />
        <div className="botoomStickyBar diamondSearchSticky">
          <div className="d-flex align-items-center">
            {USER_PERMIT && USER_PERMIT?.SEARCH_DIAMOND?.insert && (
              <>
                <a
                  className={`bottomStickyButton`}
                  onClick={() =>
                    this.props.multipleSearch.length !== 0
                      ? this.props.handleMultipleSearchApiCall()
                      : this.props.reportNo && this.props.count === 0
                        ? this.handleReportNo()
                        : this.props.handleSearch()
                  }
                >
                  <span>
                    <IntlMessages id="app.search-btn-footer" />
                    {/* {`(${this.props.count || 0})`} */}
                  </span>
                </a>
                {MULTIPLE_SEARCH && (
                  <a
                    className={
                      this.props.count === 0
                        ? `bottomStickyButton disable`
                        : `bottomStickyButton`
                    }
                    onClick={() => this.props.handleMutipleSearch()}
                  >
                    <span>
                      <IntlMessages id="app.add-more-search" />
                      {`${this.props.multipleSearch.length
                        ? `(${this.props.multipleSearch.length})`
                        : ""
                        }`}
                    </span>
                  </a>
                )}
              </>
            )}

            {USER_PERMIT && USER_PERMIT?.SAVE_SEARCH?.insert && (
              <a
                className="bottomStickyButton"
                onClick={() => {
                  if (!this.state.isGuest) {
                    this.props.handleReverseState("showSavedSearch");
                  } else {
                    this.setState({
                      showGuest: true,
                    });
                  }
                }}
              >
                <span>
                  {this.props.searchId ? (
                    <IntlMessages id="app.update-search" />
                  ) : (
                    <IntlMessages id="app.AddSaveNSearch" />
                  )}
                </span>
              </a>
            )}
            {USER_PERMIT && USER_PERMIT?.MATCH_PAIR?.insert && (
              <a
                className="bottomStickyButton"
                onClick={() => {
                  if (!this.state.isGuest) {
                    this.props.handleMatchPair();
                  } else {
                    this.setState({
                      showGuest: true,
                    });
                  }
                }}
              >
                <span>
                  <IntlMessages id="app.matchpairDashboard" />
                </span>
              </a>
            )}

            {USER_PERMIT &&
              USER_PERMIT?.DEMAND?.insert &&
              !_.isEmpty(this.props.selectedMaster) && (
                <a
                  className="bottomStickyButton"
                  onClick={() => {
                    this.props.handleReverseState("showDemand");
                  }}
                >
                  <span>Add Demand</span>
                </a>
              )}

            <a
              className="bottomStickyButton"
              onClick={() => {
                this.props.handleRefresh();
              }}
            >
              <span>
                <IntlMessages id="app.refresh" />
              </span>
            </a>
            {USER_PERMIT && USER_PERMIT?.SAVE_SEARCH?.view && (
              <div className="select-diamonds">
                <Select
                  allowClear={true}
                  value={this.props.searchTitle}
                  placeholder={<IntlMessages id="app.select-saved-search" />}
                  style={{ width: 200 }}
                  onChange={(value) => {
                    if (value) {
                      handleSelectSearch(value, (searchData, name) => {
                        if (!_.isEmpty(searchData)) {
                          this.props.handleChange("searchTitle", name);
                          this.props.handleChange("searchId", value);
                          this.props.handleAfterSelectSearch(searchData);
                        }
                      });
                    } else {
                      this.props.handleRefresh();
                    }
                  }}
                >
                  {searchList.map((s) => (
                    <Option value={s.id}>{s.name}</Option>
                  ))}
                </Select>
              </div>
            )}
            {USER_PERMIT && USER_PERMIT?.SAVE_SEARCH?.view && (
              <div className="select-diamonds">
                <Select
                  allowClear={true}
                  value={this.props.recentSearchId}
                  placeholder={<IntlMessages id="app.select-recent-search" />}
                  style={{ width: 200 }}
                  onChange={(value) => {
                    if (value) {
                      handleSelectSearch(value, (searchData, name) => {
                        if (!_.isEmpty(searchData)) {
                          this.props.handleChange("recentSearchTitle", name);
                          this.props.handleChange("recentSearchId", value);
                          this.props.handleAfterSelectSearch(searchData);
                        }
                      });
                    } else {
                      this.props.handleChange("recentSearchTitle", undefined);
                      this.props.handleChange("recentSearchId", undefined);
                      this.props.handleRefresh();
                    }
                  }}
                >
                  {recentSearchList.map((s) => (
                    <Option value={s.id}>
                      {s.name !== "" ? s.name : "test"}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          </div>
        </div>
        <SavedSearchPopup {...this.props} />
        <AddDemandPopup {...this.props} />
        {this.state.showGuest && (
          <GuestUserPopup
            visible={this.state.showGuest}
            register={() => {
              window.open("/signup", "_self");
              window.localStorage.clear();
              window.sessionStorage.clear();
            }}
            handleCancel={() => {
              this.setState({ showGuest: false });
            }}
          />
        )}
      </div>
    );
  }
}

export default connect((props) => {
  return props;
}, {})(withRouter(DiamondSearch));
