/* eslint-disable */
import React from "react";
import { DIAMOND_BID } from "../../../constants/Common";
import moment from "moment";

export const STATUS = {
  A: "Available",
  A1: "Available",
  A2: "Available",
  A3: "Available",
  H: "On Memo", //"A1",
  L: "On Memo", //"M",
  M: "On Memo",
  M1: "On Memo", //"M1",
  M2: "On Memo", //"M2",
  M3: "On Memo", //"M3",
  N: "Available", //"A",
  R: "On Memo", //"M",
  S: "Show",
  T: "Transit", //"A",
  U: "Available", //"A",
  V: "Available", //"A",
};
export const HOVER_STATUS = {
  A: "Available",
  A1: "On Hold",
  A2: "Available",
  A3: "Available",
  H: "Hold in office",
  L: "Lab",
  M: "Memo to Client",
  M1: "Memo to Client & Second Hold",
  M2: "Memo to Saleperson & Second Hold",
  M3: "M3",
  N: "New Arrival",
  R: "Recut",
  S: "Show",
  T: "In Transit",
  U: "Upcoming",
  V: "Photo & Video",
};

export const STATUS_COLOR = {
  A: "green",
  A1: "green",
  A2: "green",
  A3: "green",
  H: "blue",
  L: "lightBlue",
  M: "red",
  M1: "red",
  M2: "red",
  M3: "red",
  N: "tenOrange",
  R: "tenOrange",
  S: "peach",
  T: "purple",
  U: "pink",
  V: "lightRed",
};

const BID_STATUS = {
  1: "Blind Bid",
  2: "Look & Bid",
  3: "Multiple Bid",
};

const Status = (props) => {
  let status = !props.winloss
    ? props.status
    : props.winloss === DIAMOND_BID.STATUS.WIN
    ? "W"
    : props.winloss === DIAMOND_BID.STATUS.LOSS
    ? "L"
    : props.status;

  function getBidStartTime(bidType) {
    if (bidType === DIAMOND_BID.TYPE.OPEN)
      return moment().startOf("day").add(11, "hours");
    else return moment().startOf("day").add(15, "hours");
  }

  function getBidEndTime(bidType) {
    if (bidType === DIAMOND_BID.TYPE.OPEN)
      return moment().startOf("day").add(14, "hours").add(59, "minutes");
    else return moment().startOf("day").add(10, "hours").add(59, "minutes");
  }

  function getBidType() {
    let bidType = 0;
    if (props?.currentBidType) {
      bidType = props?.currentBidType;
    } else if (
      moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) &&
      moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)
    ) {
      bidType = DIAMOND_BID.TYPE.OPEN;
    } else if (
      moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) ||
      moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)
    ) {
      bidType = DIAMOND_BID.TYPE.BLIND;
    }
    return BID_STATUS[bidType];
  }

  return (
    <div className="showSatusBlock" title={HOVER_STATUS[props.status]}>
      {props.status !== "all" && (
        <div className={"SelectcolorWiseList " + STATUS_COLOR[status]}></div>
      )}
      <span style={{ fontWeight: 600 }}>
        {props.status === "B" ? getBidType() : STATUS[props.status]}
      </span>
    </div>
  );
};

export default Status;
