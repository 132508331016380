/* eslint-disable */
import React, { Component } from "react";
import CommonModal from "../../../common/CommonModal";
import IntlMessages from "../../../../util/IntlMessages";
import InputBlock from "../../../common/InputBlock";

const TrackStatusModal = (props) => {
  //
  return (
    <>
      <CommonModal
        visible={props.trackVisible}
        handleOk={props.handleOk}
        handleCancel={props.handleCancel}
        title={"Track Your Shipment"}
        footerShow
        submitTitle="Track"
        cancelTitle="Cancel"
      >
        <div className="searchPopupCommon">
          <div className="searchPopupTwoValue">
            <InputBlock
              type="number"
              label={"Search By Track No :"}
              placeholder={"Track No"}
              value={props.trackid}
              onChange={(e) => props.handleChange(e.target.value)}
            />
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default TrackStatusModal;
