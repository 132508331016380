/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import IntlMessages from "../../util/IntlMessages";
import { switchLanguage } from "../../appRedux/actions/Common";

var image = {
    width: 85,
    height: 85,
  },
  zoom = 0.2,
  view = {
    width: image.width * zoom,
    height: image.height * zoom,
  };

class BackButton extends Component {
  render() {
    return (
      <div className="j-space-between" style={{ display: "flex" }}>
        <div className="back-btn">
          <button
            type="button"
            className="button button-outline primary-outline d-flex align-items-center"
          >
            <img
              src={require("../../assets/svg/left-arrow-blue-back.svg")}
              width={view.width}
              height={view.height}
              space="fill"
              alt=""
              style={{ marginBottom: 3 }}
            />
            <IntlMessages id="app.signin.back" />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (props) => {
  return props;
};
export default connect(mapStateToProps, { switchLanguage })(BackButton);
