/* eslint-disable */
import React, { Component } from "react";
import UtilService from "../../services/util";
import clone from "lodash/clone";
import OpenNotification from "../common/CommonButton/OpenNotification";
import InputBlock from "../common/InputBlock";
import { FORGOT_PASSWORD } from "../../constants/apiConstant";
import Logo from "../Header/Logo";
import AuthPoster from "./AuthPoster";
import DownloadBlock from "../common/DownloadBlock";

/* eslint jsx-a11y/anchor-is-valid: 0 */

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
    };
    this.sendEmail = this.sendEmail.bind(this);
  }
  sendEmail = (e) => {
    e.preventDefault();

    let self = this;
    let reqObj = clone(self.state.fields);
    let obj = {
      // method: "post",
      // url: "/auth/forgot-password",
      ...FORGOT_PASSWORD,
      request: {
        ...reqObj,
        isFront: true,
      },
    };
    if (self.validate()) {
      UtilService.callApi(obj, function (err, data) {
        if (err) {
          OpenNotification({
            type: "error",
            title: err.data.message,
          });
          return err;
        } else if (data && data.code === "OK") {
          reqObj.username = "";
          self.setState({ fields: reqObj });
          OpenNotification({
            type: "success",
            title: data.message,
          });
        }
      });
    }
  };

  validate() {
    let fieldName = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fieldName["username"]) {
      formIsValid = false;
      errors["username"] = "Please enter email address";
      OpenNotification({
        type: "error",
        title: "Please enter email address.",
      });
    }

    if (
      fieldName["username"] &&
      !fieldName["username"].match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      formIsValid = false;
      errors["username"] = "Please enter valid Email.";
    }

    // if (typeof fieldName["username"] !== "undefined") {
    //   let lastAtPos = fieldName["username"].lastIndexOf("@");
    //   let lastDotPos = fieldName["username"].lastIndexOf(".");

    //   if (
    //     !(
    //       lastAtPos < lastDotPos &&
    //       lastAtPos > 0 &&
    //       fieldName["username"].indexOf("@@") === -1 &&
    //       lastDotPos > 2 &&
    //       fieldName["username"].length - lastDotPos > 2
    //     )
    //   ) {
    //     formIsValid = false;
    //     errors["username"] = "Please enter valid Email.";
    //   }
    // }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleOnChange = (field, e) => {
    e.preventDefault();
    let errors = null;

    if (this.state.errors) {
      errors = this.state.errors;
      if (errors.username === "Please enter email address") {
        delete errors["username"];
      }
      if (errors.username === "Please enter valid Email.") {
        let val = e.target.value;
        if (typeof val !== "undefined") {
          let lastAtPos = val.lastIndexOf("@");
          let lastDotPos = val.lastIndexOf(".");

          if (
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            val.indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            val.length - lastDotPos > 2
          ) {
            delete errors["username"];
          }
        }
      }
    }
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  render() {
    let fieldsName = this.state.fields;
    return (
      <>
        <div className="loginMainWrapper forget-vh">
          <AuthPoster />
          <div className="LoginRightBlock">
            <div className="loginDetail">
              <div className="loginHead">
                <div className="loginLogo">
                  <Logo />
                </div>
                <div className="wel_msg">
                  <h3>Forgot Password ?</h3>
                </div>
              </div>
              <div className="loginBox">
                <form onSubmit={this.sendEmail}>
                  <InputBlock
                    onChange={this.handleOnChange.bind(this, "username")}
                    label="Email"
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Email"
                    value={fieldsName.username}
                    autoComplete="off"
                  />
                  {/* <div className="form-control">
                <label htmlFor="Name" className="d-block">
                  <IntlMessages id="app.my-account.email" />

                  {fieldsName.username && fieldsName.username !== "" ? (
                    <span className="required" style={{ color: "green" }}>
                      *
                    </span>
                  ) : (
                    <span className="required">*</span>
                  )}
                </label>
                <input
                  className={this.state.errors["username"] ? "input-error" : ""}
                  onChange={this.handleOnChange.bind(this, "username")}
                  type="text"
                  id="username"
                  name="username"
                  value={fieldsName.username}
                  autoComplete="off"
                />
                <span style={{ color: "red" }}>{this.state.errors["username"]}</span>
              </div> */}

                  <div className="loginButton">
                    <button type="submit" className="commonButton">
                      Submit
                    </button>
                    <div class="registerLogLink">
                      Back To <a href="/">Log In</a>
                    </div>
                  </div>
                  {/* <hr class="login-shapret"></hr> */}
                </form>
              </div>
              <DownloadBlock />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ForgotPassword;
